import { find } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import SearchableDropdown from "./SearchableDropdown";
import { filterAndSort } from "../utils";

export interface DropdownItem {
  id: string | number;
  description: string;
}

interface EditableSearchableDropdownProps {
  value: string | string[] | number;
  options: DropdownItem[];
  onValueChanged: (value: string | null) => void;
  searchable?: boolean;
  disabled?: boolean;
  multiSelect?: boolean;
  error?: boolean;
  maxResults?: number;
  inputWidth?: "small" | "smallest" | "full" | number;
  nonNullable?: boolean;
  disabledValueIds?: string[];
  scrollingDisabled?: boolean;
  onLoadMore?: () => void;
  morePagesAvailable?: boolean;
  controlledTextInput?: string;
  setControlledTextInput?: (input: string) => void;
  dataError?: boolean;
  loading?: boolean;
}

function EditableSearchableDropdown(props: EditableSearchableDropdownProps) {
  const {
    value,
    onValueChanged,
    options,
    searchable,
    disabled,
    multiSelect,
    error,
    maxResults,
    inputWidth,
    nonNullable,
    disabledValueIds,
    scrollingDisabled,
    onLoadMore,
    morePagesAvailable,
    controlledTextInput,
    setControlledTextInput,
    dataError,
    loading,
  } = props;
  const [localTextInput, setLocalTextInput] = useState("");

  const usedTextInput = controlledTextInput ? controlledTextInput : localTextInput;
  const setUsedTextInput = setControlledTextInput ? setControlledTextInput : setLocalTextInput;

  const selectedOption = useMemo(
    () => (value !== undefined ? find(options, option => option.id.toString() === value.toString()) : undefined),
    [options, value]
  );
  const updateInput = useCallback(
    (newValue: string | number | string[]) => {
      if (selectedOption) {
        setUsedTextInput(selectedOption.description);
      } else if (Array.isArray(newValue)) {
        setUsedTextInput(newValue.toString());
      } else if (!controlledTextInput) {
        setUsedTextInput(newValue.toString());
      }
    },
    [selectedOption, controlledTextInput, setUsedTextInput]
  );

  useEffect(() => updateInput(value), [value, options]);

  let selectedOptionDescription = "";
  if (selectedOption) {
    selectedOptionDescription = selectedOption.description;
  } else if (Array.isArray(value)) {
    selectedOptionDescription = value.toString();
  }

  const opts = useMemo(() => {
    return searchable && controlledTextInput === undefined ? filterAndSort(usedTextInput, options) : options;
  }, [searchable, controlledTextInput, usedTextInput, options]);
  // VPOP-988: Only pass displayed options to avoid browser freezing.
  const searchForMore: boolean = maxResults !== undefined && maxResults > 0 && maxResults < opts.length;
  const displayOpts = searchForMore ? opts.slice(0, maxResults) : opts;

  return (
    <Container>
      <SearchableDropdown
        onValueSelected={value => {
          onValueChanged(value);
          if (value === null) {
            setUsedTextInput("");
          } else {
            const selectedItem = opts.find(item => item.id === value);
            setUsedTextInput(selectedItem?.description || "");
          }
        }}
        values={displayOpts}
        singleSelection={!multiSelect}
        textValue={usedTextInput}
        setTextInput={input => setUsedTextInput(input)}
        searchable={searchable}
        selections={Array.isArray(value) ? value : value === undefined ? [] : value.toString()}
        selectedOptionDescription={selectedOptionDescription}
        disabled={disabled}
        error={error}
        maxResults={maxResults}
        inputWidth={inputWidth}
        nonNullable={nonNullable}
        disabledValueIds={disabledValueIds}
        scrollingDisabled={scrollingDisabled}
        onLoadMore={onLoadMore}
        morePagesAvailable={morePagesAvailable}
        dataError={dataError}
        loading={loading}
      />
    </Container>
  );
}

export default EditableSearchableDropdown;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
  width: 100%;
`;
