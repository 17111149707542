import { gql } from "@apollo/client";
import { FieldPropertiesFields } from "../queries";

export const GET_PROJECT_DATES_EDIT_INFORMATION = gql`
  query GetProjectDatesEditInformation($projectId: ProjectId!) {
    projectDetailsEditInformation(projectId: $projectId) {
      projectDates {
        OBLPeriodProperties {
          ...FieldPropertiesFields
        }
        asSoldPeriodProperties {
          ...FieldPropertiesFields
        }
        budgetPeriodProperties {
          ...FieldPropertiesFields
        }
        recPlanStartPeriodProperties {
          ...FieldPropertiesFields
        }
        recPlanEndPeriod {
          effective
          cached
        }
        recPlanEndPeriodProperties {
          ...FieldPropertiesFields
        }
        recCompletionPeriodProperties {
          ...FieldPropertiesFields
        }
        startUpPeriodProperties {
          ...FieldPropertiesFields
        }
        projectClosingPeriodInERPProperties {
          ...FieldPropertiesFields
        }
        warrantyStartPeriodProperties {
          ...FieldPropertiesFields
        }
        warrantyEndPeriodProperties {
          ...FieldPropertiesFields
        }
        contractEffectivePeriodProperties {
          ...FieldPropertiesFields
        }
        contractExpiryPeriodProperties {
          ...FieldPropertiesFields
        }
        migrationPeriodProperties {
          ...FieldPropertiesFields
        }
        financeClosingPeriodProperties {
          ...FieldPropertiesFields
        }
      }
    }
  }
  ${FieldPropertiesFields}
`;

export const SAVE_PROJECT_DATES = gql`
  mutation SaveProjectDates($projectId: ProjectId!, $dates: ProjectDatesInput!) {
    saveProjectDates(projectId: $projectId, dates: $dates) {
      result {
        OBLPeriod
        asSoldPeriod
        budgetPeriod
        recPlanStartPeriod
        recPlanEndPeriod
        recCompletionPeriod
        startUpPeriod
        projectClosingPeriodInERP
        warrantyStartPeriod
        warrantyEndPeriod
        migrationPeriod
        financeClosingPeriod
      }
      errors
      applicationModifiedDateTime
    }
  }
`;
