import React from "react";
import CreateProjectSection from "../CreateProjectSection";
import EditDetails from "./EditDetails";
import { ProjectRolesVisibility, ProjectId, ProjectRoles } from "../../../../../common/types";
import styled from "styled-components";
import { defaultGrey } from "../../../../../common/colors";

export interface RolesCreateProjectSectionProps {
  projectId: ProjectId;
  sectionDetails: ProjectRoles;
  sectionVisibility: ProjectRolesVisibility;
}

function RolesCreateProjectSection(props: RolesCreateProjectSectionProps): React.ReactElement {
  const { projectId, sectionDetails, sectionVisibility } = props;
  return (
    <CreateProjectSection header="Roles">
      <Container>
        <EditDetails projectId={projectId} roles={sectionDetails} rolesVisibility={sectionVisibility} />
      </Container>
    </CreateProjectSection>
  );
}

export default RolesCreateProjectSection;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-bottom: 0px;
  color: ${defaultGrey};
`;
