import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { valmetGreyLight } from "../../../../common/colors";
import { ActionButton } from "../../../../common/components";

interface FilterSectionProps {
  onButtonClick: () => void;
  show: boolean;
  id?: string;
}

function ResetButton(props: FilterSectionProps): React.ReactElement {
  const { onButtonClick, show, id } = props;

  return (
    <ButtonWrapper>
      {show && (
        <ActionButton onClick={() => onButtonClick()} title={"Reset project filter"} id={id}>
          <FontAwesomeIcon icon={faTimes} size="1x" color={valmetGreyLight} />
        </ActionButton>
      )}
    </ButtonWrapper>
  );
}

export default ResetButton;

const ButtonWrapper = styled.div`
  min-width: 13px;
  display: flex;
  align-items: flex-end;
`;
