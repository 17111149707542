import React, { useCallback, useEffect, useState, useRef, useMemo } from "react";
import styled from "styled-components";
import { useApolloClient, useQuery } from "@apollo/client/react/hooks";
import { ApolloClient, ApolloQueryResult } from "@apollo/client";
import ProjectsTable from "./ProjectsTable";
import {
  AppState,
  ProjectListData,
  FilterValues,
  ProjectType,
  ListingNode,
  Currency,
  ProjectData,
  NodeId,
  ProjectId,
  ListingProjectCell,
  FilterInput,
  OrganisationKey,
} from "../../../../common/types";
import {
  ListingRecognitionsQueryData,
  ListingRecognitionsResult,
  ListingRecognitionsData,
  ListingRecognitionsItemData,
} from "../../Project/ProjectRecognitions/types";
import { ColumnSet, ColumnId } from "../../../../common/columnsTypes";
import { connect } from "react-redux";
import LoadingView from "../../../LoadingView";
import { GET_LISTING_RECOGNITIONS, GET_PROJECTS_WITH_HIERARCHY } from "./queries";
import ErrorBox from "../../../ErrorBox/ErrorBox";
import { ActionButton } from "../../../../common/components";

const PAGE_SIZE = 100;

interface ProjectListProps {
  filterInput: FilterInput;
  columnSet: ColumnSet;
  onProjectsFound: (projects: number | undefined) => void;
  getHierarchy?: boolean;
  selectedCurrency?: Currency;
  projectTypes: ProjectType[];
}

const mapStateToProps = (state: AppState) => {
  const filterInput = state.filtersState.filters;
  const columnSet = state.columnSetState.columnSet;
  const getHierarchy = false; //filterValues && filterValues["hierarchy"] && filterValues["hierarchy"].length > 0;
  const selectedCurrency = state.settingsState.selectedCurrency;

  return {
    filterInput,
    columnSet,
    getHierarchy,
    selectedCurrency,
  };
};

const collectProjectIds = (nodes: ListingNode[]): ProjectData[] => {
  return nodes.flatMap(n => n.childProjects.concat(collectProjectIds(n.childNodes)));
};

const constructRecData = (recQueryResult: ListingRecognitionsResult): ListingRecognitionsData => {
  const createColumnData = (columns: ListingProjectCell[]): ListingRecognitionsItemData => {
    const columnsMap = new Map<ColumnId, ListingProjectCell>();
    columns.forEach(c => columnsMap.set(c.columnId, c));
    return {
      columns: columnsMap,
    };
  };

  const nodes = new Map<NodeId, ListingRecognitionsItemData>();
  recQueryResult.nodes.forEach(n => nodes.set(n.nodeId, createColumnData(n.columns)));

  const projects = new Map<ProjectId, ListingRecognitionsItemData>();
  recQueryResult.projects.forEach(p => projects.set(p.projectId, createColumnData(p.columns)));

  return {
    nodes,
    projects,
  };
};

const fetchRecognitions = async (
  client: ApolloClient<Record<string, unknown>>,
  selectedCurrency: Currency,
  filterValues: FilterInput,
  columns: ColumnId[],
  projectIds: ProjectId[]
): Promise<ApolloQueryResult<any>> => {
  return await client.query<ListingRecognitionsQueryData>({
    query: GET_LISTING_RECOGNITIONS,
    variables: {
      projectIds: projectIds,
      filters: filterValues,
      columns: columns,
      currencyId: selectedCurrency && selectedCurrency.id,
    },
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  });
};

function ProjectsList(props: ProjectListProps): React.ReactElement {
  let availableResults: number | undefined = undefined;
  const prevProjectsUpdate = useRef<number | undefined>();
  const [page, setPage] = useState(1);
  const { filterInput, columnSet, onProjectsFound, projectTypes, selectedCurrency } = props;
  const selectedDataTypes = columnSet.columns;

  const [projects, setProjects] = useState<ListingNode[]>([]);
  const [recognitionsData, setRecognitionsData] = useState<ListingRecognitionsData>({
    nodes: new Map(),
    projects: new Map(),
  });
  const [recLoading, setRecLoading] = useState<boolean>(false);
  const [recError, setRecError] = useState<Error | undefined>();

  useEffect(() => {
    prevProjectsUpdate.current = new Date().getTime();
  }, [columnSet]);

  const updateProjects = useCallback((newProjects: ListingNode[]) => {
    prevProjectsUpdate.current = new Date().getTime();
    setProjects(newProjects);
  }, []);

  const { loading, data, error } = useQuery<ProjectListData>(GET_PROJECTS_WITH_HIERARCHY, {
    variables: {
      num: PAGE_SIZE,
      filters: filterInput,
      //columns: ["p_id", "type", "org"],
      columns: ["p_id", "type", "desc", "org"],
      offset: (page - 1) * PAGE_SIZE,
      currencyId: selectedCurrency ? selectedCurrency.id : undefined,
    },
    fetchPolicy: "no-cache",
    skip: !selectedCurrency,
    onCompleted: data => {
      if (data) {
        const projects = data.projects.nodes;
        updateProjects(projects);
      }
    },
  });

  const client = useApolloClient() as ApolloClient<Record<string, unknown>>;
  const loadRecognitions = useCallback(
    (projectIds: ProjectId[]) => {
      if (!selectedCurrency || !filterInput || projectIds.length === 0 || prevProjectsUpdate.current === undefined)
        return;
      setRecLoading(true);
      prevProjectsUpdate.current = undefined;

      const columns = columnSet.columns.map(x => x.id) as ColumnId[];
      fetchRecognitions(client, selectedCurrency, filterInput, columns, projectIds)
        .then(({ data, errors }) => {
          if (data) setRecognitionsData(constructRecData(data.listingRecognitions));
          if (errors && errors.length > 0) setRecError(errors[0]);
          setRecLoading(false);
        })
        .catch(e => {
          setRecError(e);
          setRecLoading(false);
        });
    },
    [client, selectedCurrency, columnSet, filterInput]
  );

  useEffect(() => {
    const allProjectIds = collectProjectIds(projects).map(p => p.projectId);
    loadRecognitions(allProjectIds);
  }, [projects, loadRecognitions]);

  //To fix a new warning introduced in React 16.13.0
  //https://legacy.reactjs.org/blog/2020/02/26/react-v16.13.0.html#new-warnings
  useEffect(() => {
    if (loading) {
      availableResults = undefined;
      onProjectsFound(undefined);
    } else if (data) {
      availableResults = data.projects.availableResults;
      onProjectsFound(data.projects.availableResults);
    }
  }, [loading, data])

  return (
    <Container>
      {error && (
        <ErrorContainer>
          <ErrorBox caption="Error loading projects" apolloError={error} />
        </ErrorContainer>
      )}
      {recError && (
        <ErrorContainer>
          <ErrorBox caption="Error loading recognitions" errorText={recError.message} />
        </ErrorContainer>
      )}

      {loading || !selectedCurrency ? (
        <LoadingView />
      ) : (
        projects && (
          <ProjectsTable
            dataTypes={selectedDataTypes.filter(column => column.id !== "desc")}
            projects={projects}
            projectTypes={projectTypes}
            recLoading={recLoading}
            recognitionsData={recognitionsData}
          />
        )
      )}

      {!loading && !error && availableResults !== undefined && (
        <PaginationContainer>
          <PageButtonContainer>
            {page > 1 && (
              <ActionButton
                onClick={() => {
                  window.scrollTo(0, 0);
                  setPage(page - 1);
                }}
              >
                {"< Previous page"}
              </ActionButton>
            )}
          </PageButtonContainer>

          {`Page ${page} of ${Math.ceil(availableResults / PAGE_SIZE)}`}
          <PageButtonContainer>
            {availableResults > page * PAGE_SIZE && (
              <ActionButton
                onClick={() => {
                  window.scrollTo(0, 0);
                  setPage(page + 1);
                }}
              >
                {"Next page >"}
              </ActionButton>
            )}
          </PageButtonContainer>
        </PaginationContainer>
      )}
    </Container>
  );
}

export default connect(mapStateToProps)(ProjectsList);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  scroll-behavior: smooth;
`;

const PaginationContainer = styled.div`
  display: flex;
  padding: 20px;
  justify-content: center;
  font-size: 14px;
`;

const PageButtonContainer = styled.div`
  width: 120px;
  margin-right: 20px;
  margin-left: 20px;
`;

const ErrorContainer = styled.div`
  align-self: center;
`;
