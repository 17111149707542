import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";

import { defaultGrey, projectDetailsYellow, valmetGreyBorder, valmetGreyLightTable } from "../../../../common/colors";
import { IconButton } from "../../../../common/components";
import SectionEditingButtonContainer from "./SectionEditingButtonContainer";

interface SectionProps {
  header: string;
  disabled: boolean;
  editMode: boolean;
  editable: boolean;
  saving: boolean;
  saveEnabled: boolean;
  errors: string[] | undefined;
  onEditClicked: () => void;
  onCancelEdit: () => void;
  onSaveClicked: () => void;
  additionalHeaderButtons?: React.ReactElement;
  stickyHeader?: boolean;
}

function Section(props: React.PropsWithChildren<SectionProps>) {
  const {
    children,
    header,
    disabled,
    editMode,
    editable,
    saving,
    saveEnabled,
    errors,
    onEditClicked,
    onCancelEdit,
    onSaveClicked,
    additionalHeaderButtons,
    stickyHeader,
  } = props;

  return (
    <ContainerContainer>
      <Container disabled={disabled} isEditable={editMode}>
        <HeaderContainer sticky={stickyHeader}>
          {header}
          <ButtonsContainer>
            {additionalHeaderButtons}
            {!editMode && editable && (
              <IconButton onClick={onEditClicked}>
                <FontAwesomeIcon icon={faEdit} size="lg" color={defaultGrey} />
              </IconButton>
            )}
            {editMode && (
              <SectionEditingButtonContainer
                onSave={onSaveClicked}
                onCancelEdit={onCancelEdit}
                saving={saving}
                saveEnabled={saveEnabled}
              />
            )}
          </ButtonsContainer>
        </HeaderContainer>
        {editMode && errors && (
          <LoadingContainer>
            <ErrorTitleText>{`Error saving data${errors && errors.length ? ":" : "."}`}</ErrorTitleText>
            {errors.map((error, i) => (
              <div key={i}>{error.toString()}</div>
            ))}
          </LoadingContainer>
        )}
        {!editMode && errors && (
          <LoadingContainer>
            <ErrorTitleText>{`Error loading data${errors && errors.length ? ":" : "."}`}</ErrorTitleText>
            {errors.map((error, i) => (
              <div key={i}>{error.toString()}</div>
            ))}
          </LoadingContainer>
        )}
        <ContentContainer>{children}</ContentContainer>
      </Container>
    </ContainerContainer>
  );
}

export default Section;

const ContainerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Container = styled.div<{ disabled: boolean; isEditable?: boolean }>`
  display: flex;
  flex-direction: column;
  border: solid 1px ${valmetGreyBorder};
  -webkit-column-break-inside: avoid;
  -moz-column-break-inside: avoid;
  -moz-page-break-inside: avoid;
  /*page-break-inside: avoid;*/
  break-inside: avoid-column;
  ${({ disabled }) => (disabled ? `pointer-events: none; opacity: 0.5; background: ${valmetGreyLightTable};` : "")};
  margin-bottom: 20px;
`;

const HeaderContainer = styled.div<{ sticky?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${projectDetailsYellow};
  padding: 10px;
  color: ${defaultGrey};
  text-transform: uppercase;
  height: 24px;
  ${({ sticky }) => sticky && "position: sticky;"}
  top: 45px;
  box-shadow: 0 5px 10px -2px #aaaa;
  z-index: 100;
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
  text-transform: initial;
`;

const ContentContainer = styled.div``;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

const ErrorTitleText = styled.div`
  font-weight: bold;
`;
