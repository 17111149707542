import React from "react";
import styled from "styled-components";
import { cancelRed, disabledGrey, settingGreen, valmetGreyLight } from "../../common/colors";
import { ActionButton } from "../../common/components";

export interface UpdateNotificationProps {
  reloadPage: () => void;
  dismiss: () => void;
}

function UpdateNotificationDialog(props: UpdateNotificationProps): React.ReactElement {
  return (
    <Wrapper>
      <ContentContainer>
        <Title>Update available</Title>
        <Info>Please refresh your browser to update application.</Info>
        <ButtonContainer>
          <ActionButton onClick={props.reloadPage}>Update</ActionButton>
          <ActionButton onClick={props.dismiss}>Dismiss</ActionButton>
        </ButtonContainer>
      </ContentContainer>
    </Wrapper>
  );
}

export default UpdateNotificationDialog;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  width: 100%;
  background-color: ${disabledGrey};
  z-index: 1200;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 25px;
  padding-right: 25px;
  color: ${valmetGreyLight};
  width: 450px;
  align-items: flex-start;
`;

const Title = styled.div`
  font-size: 24px;
`;

const Info = styled.div`
  font-size: 16px;
  margin-top: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  button {
    font-size: 16px;
    font-weight: bold;
  }
  > button:first-child {
    color: ${settingGreen};
  }
  > button:last-child {
    color: ${cancelRed};
  }
`;
