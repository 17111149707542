import * as React from "react";
import onClickOutside, { HandleClickOutside, InjectedOnClickOutProps } from "react-onclickoutside";
import styled from "styled-components";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { valmetGreyBorder, filterGreen, defaultGrey } from "../../../../../../common/colors";
import { EstimateCode } from "../../../../../../common/types";
import { CollapseButton, ValueButton } from "../../../../../../common/components";

type Props = {
  selections: EstimateCode[];
  value?: EstimateCode;
  onValueSelected: (estimateCode: EstimateCode) => void;
};

type State = {
  isOpen: boolean;
};

class EstimateCodeDropdown extends React.Component<
  Props & InjectedOnClickOutProps & HandleClickOutside<React.MouseEventHandler>,
  State
> {
  state: State = { isOpen: false };

  toggle = (): void => {
    if (this.state.isOpen) {
      this.props.disableOnClickOutside();
    } else {
      this.props.enableOnClickOutside();
    }

    this.setState(state => ({ isOpen: !state.isOpen }));
  };

  handleClickOutside = (): void => {
    this.props.disableOnClickOutside();
    this.setState({ isOpen: false });
  };

  render(): React.ReactElement {
    const { selections, value, onValueSelected } = this.props;
    return (
      <Wrapper>
        <ContentContainer onClick={this.toggle}>
          <EstimateCodeTextContainer>{value ? value.description : "Not selected"}</EstimateCodeTextContainer>
          <CollapseButton title={"Select estimate code"} fontSize={"12px"}>
            <FontAwesomeIcon icon={faChevronDown} size="1x" color={defaultGrey} />
          </CollapseButton>
        </ContentContainer>
        {this.state.isOpen && (
          <DropdownContent onClick={this.handleClickOutside} hasContent={selections.length > 0}>
            {selections.map(selection => (
              <ValueButton key={selection.id} onClick={() => onValueSelected(selection)}>
                {`${selection.description}`}
              </ValueButton>
            ))}
          </DropdownContent>
        )}
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  cursor: context-menu;
  font-size: 12px;
`;

const EstimateCodeTextContainer = styled.div`
  text-align: right;
  width: 85px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-style: italic;
  padding-right: 1px;
`;

const DropdownContent = styled.div<{ hasContent: boolean }>`
  background: white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  position: absolute;
  display: flex;
  flex-direction: column;
  min-width: 85px;
  ${({ hasContent }) => {
    if (hasContent) {
      return `border: 1px solid ${valmetGreyBorder}`;
    }
  }};
  z-index: 500;
  button:hover {
    background: ${filterGreen};
  }
  margin-top: 18px;
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default onClickOutside<any, Props>(EstimateCodeDropdown);
