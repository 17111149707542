import { filter, find, findIndex, reject, sortBy } from "lodash";
import { MAX_NODE_NAME_LENGTH, MIN_NODE_NAME_LENGTH } from "../../../../../common/constants";
import {
  EntityTypeId,
  HierarchyItem,
  HierarchyItemEnum,
  HierarchyState,
  HierarchyTreeItem,
  NodeId,
  NodeMainProjectPairing,
  ProjectId,
} from "../../../../../common/types";
import {
  HierarchyProjectsFilters,
  inactiveHierarchyProjectsFilters,
  isHierarchyItemVisible,
} from "../../../../../common/hierarchyUtils";

export const getChildItems = (
  childItems: HierarchyItem[],
  foundItems: HierarchyItem[],
  itemType?: HierarchyItemEnum
) => {
  for (let i = 0; i < childItems.length; i++) {
    if (!itemType) {
      foundItems.push(childItems[i]);
    } else if (itemType && childItems[i].itemType === itemType) {
      foundItems.push(childItems[i]);
    }
    if (childItems[i].childItems.length) getChildItems(childItems[i].childItems, foundItems, itemType);
  }
};

export const findChildProject = (
  projectId: ProjectId | undefined,
  childItems: HierarchyItem[],
  foundItems: HierarchyItem[]
) => {
  for (let i = 0; i < childItems.length; i++) {
    if (childItems[i].id === projectId && childItems[i].itemType === HierarchyItemEnum.Project) {
      foundItems.push(childItems[i]);
    } else {
      findChildProject(projectId, childItems[i].childItems, foundItems);
    }
  }
};
export const findChildNode = (nodeId: NodeId | undefined, childItems: HierarchyItem[], foundItems: HierarchyItem[]) => {
  for (let i = 0; i < childItems.length; i++) {
    if (childItems[i].id === nodeId && childItems[i].itemType === HierarchyItemEnum.Node) {
      foundItems.push(childItems[i]);
    } else {
      findChildNode(nodeId, childItems[i].childItems, foundItems);
    }
  }
};

export const findRelatedProjects = (itemId: ProjectId, childItems: HierarchyItem[], foundProjects: HierarchyItem[]) => {
  for (let i = 0; i < childItems.length; i++) {
    if (childItems[i].relatingProjectId === itemId && childItems[i].itemType === HierarchyItemEnum.Project) {
      foundProjects.push(childItems[i]);
    } else {
      findRelatedProjects(itemId, childItems[i].childItems, foundProjects);
    }
  }
};

export const isChildNode = (targetId: NodeId | undefined, itemId: NodeId, hierarchy: HierarchyItem) => {
  const foundTargetItems: HierarchyItem[] = [];
  const foundChildItems: HierarchyItem[] = [];
  findChildNode(itemId, hierarchy.childItems, foundTargetItems);
  if (foundTargetItems.length) {
    findChildNode(targetId, foundTargetItems[0].childItems, foundChildItems);
  }
  return foundChildItems.length > 0;
};

export const addItemIntoTree = (
  newRenderedItems: HierarchyTreeItem[],
  selectedParentNodeId: NodeId,
  item: HierarchyTreeItem
) => {
  const extraConnections: number[] = [];
  const originalInsertIndex = findIndex(newRenderedItems, item => item.id === selectedParentNodeId);

  if (originalInsertIndex >= 0) {
    let insertIndex = originalInsertIndex + 1;

    if (newRenderedItems[insertIndex - 1]) {
      const currentChildLevel = newRenderedItems[insertIndex - 1].childLevel;
      while (newRenderedItems[insertIndex] && newRenderedItems[insertIndex].childLevel >= currentChildLevel + 1) {
        if (newRenderedItems[insertIndex].childLevel !== currentChildLevel + 1) {
          newRenderedItems[insertIndex].extraConnections.push(currentChildLevel + 1);
        } else {
          newRenderedItems[insertIndex].connectionDown = true;
        }
        insertIndex++;
      }

      if (newRenderedItems[insertIndex]) {
        newRenderedItems[originalInsertIndex].extraConnections.forEach(extraConnection => {
          extraConnections.push(extraConnection);
        });

        if (
          newRenderedItems[insertIndex] &&
          newRenderedItems[originalInsertIndex].childLevel === newRenderedItems[insertIndex].childLevel
        ) {
          extraConnections.push(newRenderedItems[originalInsertIndex].childLevel);
        }
      }

      item.childLevel = currentChildLevel + 1;
      item.extraConnections = extraConnections;

      newRenderedItems.splice(insertIndex, 0, item);
      if (newRenderedItems[insertIndex - 1].childLevel === item.childLevel) {
        newRenderedItems[insertIndex - 1].connectionDown = true;
      }

      if (newRenderedItems[insertIndex + 1] && newRenderedItems[insertIndex + 1].childLevel === item.childLevel) {
        newRenderedItems[insertIndex].connectionDown = true;
      }
    }
  }
  return newRenderedItems;
};

const sortProjectsByType = (
  projectItems: HierarchyItem[],
  mainProjectId: number | null,
  options: HierarchyDrawOptions
) => {
  let sortedProjectItems: HierarchyItem[] = [];
  const mainProject = find(projectItems, item => item.id === mainProjectId);
  if (mainProject) sortedProjectItems.push(mainProject);
  sortedProjectItems = sortedProjectItems.concat(
    filter(
      projectItems,
      item =>
        item.id !== mainProjectId &&
        item.projectTechnicalTypeId !== null &&
        [
          EntityTypeId.ProjectWithRelations,
          EntityTypeId.ProjectWithoutRelations,
          EntityTypeId.ServiceProject,
          EntityTypeId.AggregatedOrders,
        ].some(type => type === item.projectTechnicalTypeId)
    )
  );
  sortedProjectItems = sortedProjectItems.concat(
    filter(
      projectItems,
      item => item.id !== mainProjectId && item.projectTechnicalTypeId === EntityTypeId.ManualAdjustment
    )
  );
  if (!options.groupAdjustments) {
    sortedProjectItems = sortedProjectItems.concat(
      filter(
        projectItems,
        item => item.id !== mainProjectId && item.projectTechnicalTypeId === EntityTypeId.AutomaticAdjustment
      )
    );
  }
  sortedProjectItems = sortedProjectItems.concat(
    filter(
      projectItems,
      item => item.id !== mainProjectId && item.projectTechnicalTypeId === EntityTypeId.ProjectAdjustment
    )
  );

  if (options.groupAdjustments) {
    // Add automatic adjustments last, interleaving them with their related project
    const automaticAdjustments = projectItems.filter(
      item => item.projectTechnicalTypeId === EntityTypeId.AutomaticAdjustment
    );
    sortedProjectItems = sortedProjectItems.flatMap(item => {
      const adjustments = automaticAdjustments.filter(a => a.relatingProjectId === item.id);
      return [item].concat(adjustments);
    });
  }

  return sortedProjectItems;
};

const sortNodes = (nodeItems: HierarchyItem[], parentNodeMainProjectId: ProjectId | undefined) => {
  const nodeWithParentMainProject = find(
    nodeItems,
    item => parentNodeMainProjectId !== undefined && item.mainProjectId === parentNodeMainProjectId
  );
  const sortedNodes = reject(
    sortBy(nodeItems, n => n.id),
    node => node.id === nodeWithParentMainProject?.id
  );
  return nodeWithParentMainProject ? [nodeWithParentMainProject].concat(sortedNodes) : sortedNodes;
};

const addChildItemsToTree = (
  childItems: HierarchyItem[],
  hierarchyTree: HierarchyTreeItem[],
  parentId: number,
  childLevel: number,
  nodeMainProjectPairings: NodeMainProjectPairing[],
  mainProjectId: number | null,
  active: boolean,
  options: HierarchyDrawOptions
) => {
  const activeChildItems = filter(childItems, item => item.active === active);
  const filteredItems = !options.hierarchyFilters.filterActive
    ? activeChildItems
    : filter(activeChildItems, item => isHierarchyItemVisible(item, options.hierarchyFilters));
  const projectItems = filter(filteredItems, item => {
    return item.itemType !== HierarchyItemEnum.Node; // && !item.hiddenInHierarchyView;
  });
  const nodeItems = filter(filteredItems, item => item.itemType === HierarchyItemEnum.Node);
  const parentNodeMainProjectId = find(nodeMainProjectPairings, pairing => pairing.nodeId === parentId)?.mainProjectId;

  sortProjectsByType(projectItems, mainProjectId, options)
    .concat(sortNodes(nodeItems, parentNodeMainProjectId))
    .forEach(item => {
      const treeItem: HierarchyTreeItem = {
        id: item.id,
        description: item.description,
        childLevel: childLevel,
        isProject: item.itemType !== HierarchyItemEnum.Node,
        isActive: item.active,
        itemType: item.itemType,
        extraConnections: [],
        connectionUp: true,
        hierarchyItem: item,
        userAdded: item.userAdded,
        hasChildren: item.childItems.find(child => child.active) !== undefined,
        parentId,
        projectTechnicalTypeId: item.projectTechnicalTypeId,
        projectTypeId: item.projectTypeId,
      };
      if (treeItem.isProject) {
        if (nodeMainProjectPairings.map(pairing => pairing.mainProjectId).includes(treeItem.id)) {
          const nodePairings = filter(nodeMainProjectPairings, pairing => pairing.mainProjectId === treeItem.id);
          nodePairings.forEach(pairing => {
            if (treeItem.mainToNode) {
              treeItem.mainToNode.push({ id: pairing.nodeId, parentDesc: pairing.nodeDesc });
            } else {
              treeItem.mainToNode = [{ id: pairing.nodeId, parentDesc: pairing.nodeDesc }];
            }
          });
        } else {
          treeItem.mainToNode = undefined;
        }
      } else if (item.mainProjectId) {
        nodeMainProjectPairings.push({
          nodeId: item.id,
          nodeDesc: item.description,
          mainProjectId: item.mainProjectId,
        });
      }
      addItemIntoTree(hierarchyTree, parentId, treeItem);
      addChildItemsToTree(
        item.childItems,
        hierarchyTree,
        item.id,
        childLevel + 1,
        nodeMainProjectPairings,
        mainProjectId,
        active,
        options
      );
    });
};

export type HierarchyDrawOptions = {
  groupAdjustments: boolean;
  hierarchyFilters: HierarchyProjectsFilters;
};

export const buildHierarchyTree = (
  hierarchy: HierarchyItem,
  active = true,
  options: HierarchyDrawOptions = { groupAdjustments: false, hierarchyFilters: inactiveHierarchyProjectsFilters }
) => {
  const hierarchyTree: HierarchyTreeItem[] = [];
  const nodeMainProjectPairings: NodeMainProjectPairing[] = [];
  if (hierarchy.mainProjectId)
    nodeMainProjectPairings.push({
      nodeId: hierarchy.id,
      nodeDesc: hierarchy.description,
      mainProjectId: hierarchy.mainProjectId,
    });
  hierarchyTree.push({
    id: hierarchy.id,
    description: hierarchy.description,
    childLevel: 0,
    isProject: hierarchy.itemType !== HierarchyItemEnum.Node,
    isActive: hierarchy.active,
    itemType: hierarchy.itemType,
    extraConnections: [],
    hasChildren: hierarchy.childItems.length !== 0,
    hierarchyItem: hierarchy,
    projectTechnicalTypeId: hierarchy.projectTechnicalTypeId,
    projectTypeId: hierarchy.projectTypeId,
  });
  addChildItemsToTree(
    hierarchy.childItems,
    hierarchyTree,
    hierarchy.id,
    1,
    nodeMainProjectPairings,
    hierarchy.mainProjectId,
    active,
    options
  );

  return hierarchyTree;
};

export const validateHierarchyTree = (editedProjectHierarchy: HierarchyState) => {
  if (editedProjectHierarchy.modifiedHierarchy) {
    const hierarchyTree: HierarchyTreeItem[] = buildHierarchyTree(editedProjectHierarchy.modifiedHierarchy);

    const mainProjectInvalidNodes: HierarchyTreeItem[] = [];
    const nameTooLongNodes: HierarchyTreeItem[] = [];
    const nameTooShortNodes: HierarchyTreeItem[] = [];
    hierarchyTree.forEach(item => {
      //Check for nodes without main project
      if (item.itemType === HierarchyItemEnum.Node && item.hierarchyItem && item.hierarchyItem.mainProjectId === null) {
        if (item.hasChildren) {
          const hasMainProjectCandidate =
            item.hierarchyItem &&
            item.hierarchyItem.childItems.some(
              item => item.itemType === HierarchyItemEnum.Project || item.mainProjectId !== null
            );
          if (hasMainProjectCandidate) mainProjectInvalidNodes.push(item);
        }
      }

      //Check for nodes with too long names
      if (item.itemType === HierarchyItemEnum.Node && item.description.length > MAX_NODE_NAME_LENGTH) {
        nameTooLongNodes.push(item);
      }

      //Check for nodes with too short names
      if (item.itemType === HierarchyItemEnum.Node && item.description.length < MIN_NODE_NAME_LENGTH) {
        nameTooShortNodes.push(item);
      }
    });

    const errorMessages: string[] = [];
    if (mainProjectInvalidNodes.length) {
      let mainProjectMessage = "Following nodes are missing their main projects:";
      mainProjectInvalidNodes.forEach((node, i) => {
        if (node.hierarchyItem)
          mainProjectMessage += ` ${
            node.hierarchyItem.description.length ? node.hierarchyItem.description : "Unnamed node"
          }${i !== mainProjectInvalidNodes.length - 1 ? "," : ""}`;
      });
      mainProjectMessage += ".";
      errorMessages.push(mainProjectMessage);
    }

    if (nameTooLongNodes.length) {
      let nameTooLongNodesMessage = "Following nodes have too long descriptions:";
      nameTooLongNodes.forEach((node, i) => {
        nameTooLongNodesMessage += ` ${node.description}${i !== nameTooLongNodes.length - 1 ? "," : ""}`;
      });
      nameTooLongNodesMessage += ".";
      errorMessages.push(nameTooLongNodesMessage);
    }

    if (nameTooShortNodes.length) {
      errorMessages.push("Some node descriptions are missing.");
    }

    return errorMessages.length ? errorMessages : undefined;
  }

  return undefined;
};
