import { produce } from "immer";
import { FilterAction, FiltersState, FilterType, NumberRange } from "../common/types";
import {
  SET_FILTER,
  RESET_FILTERS,
  RESET_FILTER,
  SET_SELECTED_FILTERS,
  RESET_SELECTED_FILTERS,
} from "../actions/filterActions";
import { includes, remove } from "lodash";
import { PROJECT_FILTERS_MAP } from "../common/constants";

const currentDate = new Date();
const stringifiedCurrentDate = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}`;

export const initState: FiltersState = {
  selectedFiltersId: undefined,
  selectedFiltersName: "Filters",
  filters: {
    hierarchy: undefined,
    projectPhase: undefined,
    technicalType: undefined,
    projectType: [1], // Default selection "Actual" which has ID 1.
    projectStatus: 1, // Default selection is "Active" which has ID 1.
    businessGroup: undefined,
    businessType: undefined,
    person: undefined,
    projectPeriod: stringifiedCurrentDate,
    oblPeriod: undefined,
    project: undefined,
    deliveryOrg: undefined,
    endDestCountry: undefined,
    legalEntity: undefined,
    extLegalEntity: undefined,
    estSalesPrice: undefined,
    customer: undefined,
    financingMethod: undefined,
  },
  filtersPristine: true,
};

export default function filtersStateReducer(
  state: Readonly<FiltersState> = initState,
  action: FilterAction
): FiltersState {
  switch (action.type) {
    case SET_FILTER:
      if (action.data) {
        const { id, value } = action.data;
        return produce(state, newState => {
          const filter = PROJECT_FILTERS_MAP.get(id);
          if (filter === undefined) throw new Error(`No filter found with ID ${id}`);

          if (value === undefined) {
            if (id === "projectPeriod") {
              newState.filters[id] = stringifiedCurrentDate;
            } else {
              newState.filters[id] = undefined;
            }
          } else {
            switch (filter.type) {
              case FilterType.NUMBER_ARRAY: {
                const filterValue = value as number;
                if (newState.filters[id]) {
                  if (!includes(newState.filters[id] as number[], filterValue)) {
                    (newState.filters[id] as number[]).push(filterValue);
                  } else {
                    if ((newState.filters[id] as number[]).length === 1) {
                      newState.filters[id] = undefined;
                    } else {
                      remove(newState.filters[id] as number[], val => val === filterValue);
                    }
                  }
                } else {
                  (newState.filters[id] as number[]) = [filterValue];
                }
                break;
              }
              case FilterType.NUMBER: {
                const numberValue = value as number;
                if (newState.filters[id] === numberValue) {
                  newState.filters[id] = undefined;
                } else {
                  (newState.filters[id] as number) = numberValue;
                }
                break;
              }
              case FilterType.STRING: {
                const stringValue = value as string;
                if (stringValue.length && stringValue !== newState.filters[id]) {
                  (newState.filters[id] as string) = stringValue;
                } else {
                  newState.filters[id] = undefined;
                }
                break;
              }
              case FilterType.NUMBER_RANGE: {
                const numberRange = value as NumberRange;
                if (numberRange.min === undefined && numberRange.max === undefined) newState.filters[id] = undefined;
                else (newState.filters[id] as NumberRange) = numberRange;
                break;
              }
            }
          }
          newState.filtersPristine = false;
        });
      }
      return state;
    case RESET_FILTERS:
      return produce(state, newState => {
        newState.filters = initState.filters;
        newState.filtersPristine = true;
      });
    case RESET_FILTER:
      return produce(state, newState => {
        if (action.data) {
          const id = action.data.id;
          if (id === "projectType") {
            // Initial state has the default Actual project type selected, but we want to actually clear the filter.
            newState.filters.projectType = undefined;
          } else {
            const initialValue = initState.filters[id];
            (newState.filters[id] as typeof initialValue) = initialValue;
          }
        }
      });
    case SET_SELECTED_FILTERS:
      return produce(state, newState => {
        if (action.selectedFilters !== undefined) {
          newState.selectedFiltersId = action.selectedFilters.id;
          newState.selectedFiltersName = action.selectedFilters.name;
          newState.filters = action.selectedFilters.filters;
          newState.filtersPristine = true;
        }
      });
    case RESET_SELECTED_FILTERS:
      return produce(state, newState => {
        newState.selectedFiltersId = initState.selectedFiltersId;
        newState.selectedFiltersName = initState.selectedFiltersName;
        newState.filters = initState.filters;
        newState.filtersPristine = true;
      });
    default:
      return state;
  }
}
