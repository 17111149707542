import { entries, filter, find, isEqual } from "lodash";
import React, { useCallback, useRef, useState } from "react";
import styled from "styled-components";
import { faArrowDown, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  projectsYellowTransparentOnWhite,
  rateCurrencyPairsHighlight,
  recPeriodTagBackgroundGrey,
  selectedRowColor,
  settingGreen,
  valmetGreyHeader,
  valmetGreyId,
  valmetGreyLight,
  valmetGreyREC,
} from "../../common/colors";
import { Comment, CommentType, ListingProjectCell } from "../../common/types";
import { Format } from "../../common/utils";
import {
  EditableRecognitionCell,
  FreezingDisabledResult,
  FreezingDisabledType,
  RateCurrencyPair,
  RecognitionEditType,
  RecognitionsEditStateChange,
} from "../sites/Project/ProjectRecognitions/types";
import {
  ColumnFieldSpec,
  ColumnFieldSpecType,
  ColumnId,
  EditableColumnId,
  EditableProjectColumn,
  StringValue,
} from "../../common/columnsTypes";
import { ALL_COLUMNS, RECOGNITION_EDIT_COLUMN_SETS } from "../../common/columns";
import { RECOGNITION_EDIT_COLUMNS } from "../sites/Project/ProjectRecognitions/recognitionsEditingSetting";
import CommentView from "./../CommentView";
import { IconButton } from "../../common/components";
import ArithmeticInput from "./ArithmeticInput";
import Tooltip from "../Tooltip";

export const valueForDisplay = (field: ColumnFieldSpec, v: number): number =>
  field.type === ColumnFieldSpecType.PERCENTAGE || field.type === ColumnFieldSpecType.RATE ? v : v / 1000;
export const valueToSave = (field: ColumnFieldSpec, v: number): number =>
  field.type === ColumnFieldSpecType.PERCENTAGE || field.type === ColumnFieldSpecType.RATE ? v : v * 1000;

type HighlightColor = {
  color: string;
  background: string;
};

export type AdditionalRateData = {
  kind: "rate_data";
  currencyPairs?: RateCurrencyPair[];
};

export type AdditionalData = {
  [key: string]: AdditionalRateData | undefined;
  erRate?: AdditionalRateData;
  irRate?: AdditionalRateData;
};

interface DataCellProps {
  dataEntity: ListingProjectCell | EditableRecognitionCell;
  additionalData?: AdditionalData;
  icon?: IconDefinition;
  borders?: boolean;
  hilight?: boolean;
  isFuturePeriod: boolean;
  editType: RecognitionEditType;
  onIconClick?: () => void;
  onValueChange?: (field: string, value: number) => void;
  onCopyCurrent?: (field: string, value: number) => void;
  onFocus?: (field: string) => void;
  onBlur?: (field: string) => void;
  disabled?: boolean | string[]; // true if cell is disabled or string of disabled fields
  freezingDisabled?: FreezingDisabledResult;
  highlightField?: (columnId: ColumnId | EditableColumnId, fieldId: string) => HighlightColor | undefined;
  fieldTooltip?: (columnId: ColumnId | EditableColumnId, fieldId: string) => string | undefined;
  onAddFreezing?: (field: string, value: number, comment: string) => void;
  onRemoveFreezing?: (field: string) => void;
  onAddComment?: (field: string, type: CommentType, value: number, content: string) => Promise<Comment>;
  onDeleteComment?: (field: string, type: string, value: number, content: string) => Promise<void>;
  onHasError?: (id: string, hasError: boolean) => void;
  changes?: RecognitionsEditStateChange[];
  commentTypes: CommentType[];
  isSelectedRow?: boolean;
  tabIndex?: number;
}

interface HeaderCellProps {
  columnId: string;
  value: string;
  borders?: boolean;
  hilight?: boolean;
  tags?: (string | null)[];
  tagTitles?: (string | null)[];
  extraPadding?: boolean;
  icon?: IconDefinition;
  onIconClick?: () => void;
}

const renderExtraItem = (id: string, isSelectedRow?: boolean) => {
  switch (id) {
    case "rec":
    case "est_rec_percent_edit":
    case "est_rec_percentage":
      return <ExtraItem isSelectedRow={isSelectedRow}>%</ExtraItem>;
    default:
      return null;
  }
};

const renderValuePrefix = (
  id: ColumnId | EditableColumnId,
  fieldId: string,
  value: number,
  decimals: number,
  isSelectedRow?: boolean
) => {
  switch (id) {
    case "rec":
    case "rec_edit":
    case "est_rec_percentage":
    case "est_rec_percent_edit": {
      if (fieldId != "pmPercent" && value < 100.0) {
        const rounded = value.toFixed(decimals);
        if (rounded.startsWith("100")) {
          return <ValuePrefix isSelectedRow={isSelectedRow}>~</ValuePrefix>;
        }
      }
      return null;
    }
    default:
      return null;
  }
};

const isEditableColumn = (columnId: string | EditableColumnId): columnId is EditableColumnId => {
  return columnId in EditableProjectColumn;
};

const rightArrow = "\u2192";

const renderItem = (
  dataEntity: ListingProjectCell | EditableRecognitionCell,
  additionalData: AdditionalData | undefined,
  editType: RecognitionEditType,
  isFuturePeriod: boolean,
  disabled: boolean | string[] | undefined,
  commentTypes: CommentType[],
  freezingDisabledOpt?: FreezingDisabledResult,
  highlightField?: (columnId: ColumnId | EditableColumnId, fieldId: string) => HighlightColor | undefined,
  fieldTooltip?: (columnId: ColumnId | EditableColumnId, fieldId: string) => string | undefined,
  onValueChange?: (field: string, value: number) => void,
  onCopyCurrent?: (field: string, value: number) => void,
  onFocus?: (field: string) => void,
  onBlur?: (field: string) => void,
  onAddFreezing?: (field: string, value: number, comment: string) => void,
  onRemoveFreezing?: (field: string) => void,
  onAddComment?: (field: string, type: CommentType, value: number, content: string) => Promise<Comment>,
  onDeleteComment?: (field: string, type: CommentType, value: number, content: string) => Promise<void>,
  onHasError?: (id: string, hasError: boolean) => void,
  changes?: RecognitionsEditStateChange[],
  isSelectedRow?: boolean,
  tabIndex?: number
) => {
  const { value, columnId, comments, freezings } = dataEntity;

  const [isFocused, setIsFocused] = useState<string | undefined>();
  const timeoutHandle = useRef<number>();

  const setFocused = useCallback(
    (fieldId: string, focused: boolean) => {
      clearTimeout(timeoutHandle.current);
      if (focused) {
        setIsFocused(fieldId);
      } else {
        timeoutHandle.current = setTimeout(() => {
          setIsFocused(undefined);
        }, 200);
        return () => clearTimeout(timeoutHandle.current);
      }
    },
    [isFocused, setIsFocused]
  );

  const isInputDisabled = (fieldId: string) => (disabled instanceof Array ? disabled.includes(fieldId) : disabled);

  const freezingDisabled = (fieldId: string): boolean =>
    freezingDisabledOpt === undefined
      ? false
      : freezingDisabledOpt.type === FreezingDisabledType.AllDisabled
      ? true
      : freezingDisabledOpt.type === FreezingDisabledType.PartiallyDisabled
      ? freezingDisabledOpt.fields.some(f => f.fieldName === fieldId)
      : false;

  const freezingDisabledReasons = (fieldId: string): string[] | undefined =>
    freezingDisabledOpt === undefined
      ? undefined
      : freezingDisabledOpt.type === FreezingDisabledType.AllDisabled
      ? freezingDisabledOpt.reasons
      : freezingDisabledOpt.type === FreezingDisabledType.PartiallyDisabled
      ? freezingDisabledOpt.fields.find(f => f.fieldName === fieldId)?.reasons
      : undefined;

  if (isEditableColumn(columnId)) {
    const fieldValues = value as { [key: string]: string | number };
    const columnSpec = RECOGNITION_EDIT_COLUMN_SETS[columnId];

    if (!columnSpec) {
      console.log("Error: Column spec not found for", columnId);
      throw new Error("Column spec not found for " + columnId);
    }

    const isEditableColumn = RECOGNITION_EDIT_COLUMNS.isEditableColumn(columnId);
    const isFreezableColumn = columnId !== "est_rec_percent_edit" && isEditableColumn;

    return entries(columnSpec.fields).map(([fieldId, field]) => {
      const key = `${columnId}_${fieldId}`;
      if (editType !== RecognitionEditType.Manual && isFuturePeriod && field.hideForFuturePeriods === true) {
        return <DataItem key={key} />;
      }

      if (fieldValues[fieldId] === undefined) {
        console.log("Error: no field with ID", fieldId, "in field values", fieldValues);
      }
      const fieldValue = fieldValues[fieldId].toString();

      const change: RecognitionsEditStateChange | null = changes
        ? changes.find(ch => ch.columnId === columnId && ch.field === fieldId) || null
        : null;

      const filteredComment = comments && find(comments, comment => comment.fieldName === fieldId);
      const filteredComments = filteredComment ? filteredComment.comments : [];
      const filteredFreezings = filter(freezings, freezing => freezing.fieldName === fieldId);

      const showCommentView =
        isFreezableColumn || onAddComment || filteredComments.length > 0 || filteredFreezings.length > 0;

      const copyMode = RECOGNITION_EDIT_COLUMNS.getCopyModeForField(editType, columnId, fieldId);

      const floatValue = parseFloat(fieldValue);
      const value = isNaN(floatValue) ? 0 : floatValue;
      const formattedValue = valueForDisplay(field, value).toFixed(field.decimals).toString();

      const highlightColor: HighlightColor | undefined = highlightField && highlightField(columnId, fieldId);
      const tooltip = fieldTooltip && fieldTooltip(columnId, fieldId);

      const addit = additionalData && additionalData[fieldId];

      const getFreezingTitle = (columnId: EditableColumnId) => {
        if (columnId === "rates_edit" && addit && addit.kind === "rate_data") {
          if (addit.currencyPairs === undefined || addit.currencyPairs.length === 0) {
            return [
              "Freeze rate between top currency pair (currency pair not available)",
              "Rate between top currency pair (currency pair not available) frozen",
            ];
          } else {
            const { from, to } = addit.currencyPairs[addit.currencyPairs.length - 1];
            return [
              `Freeze rate between top currency pair (${from}${rightArrow}${to})`,
              `Rate between top currency pair (${from}${rightArrow}${to}) frozen`,
            ];
          }
        }
        return [undefined, undefined];
      };

      const [freezingTitle, frozenTitle] = getFreezingTitle(columnId);

      const dataTitle = () => {
        if (addit) {
          switch (addit.kind) {
            case "rate_data":
              return (
                <Tooltip component={CurrencyPairs(addit.currencyPairs)} offset={{ x: -80, y: 0 }}>
                  <DataTitle
                    id={columnId}
                    extraWide={false}
                    highlightColor={{ color: "#000", background: rateCurrencyPairsHighlight }}
                    title={tooltip}
                    changed={change !== null}
                  >
                    {field.title}
                  </DataTitle>
                </Tooltip>
              );
          }
        } else {
          return (
            <DataTitle
              id={columnId}
              extraWide={false}
              highlightColor={highlightColor}
              title={tooltip}
              changed={change !== null}
            >
              {field.title}
            </DataTitle>
          );
        }
      };

      return (
        <DataItem key={key} changed={change !== null}>
          {dataTitle()}
          <DataValue isSelectedRow={isSelectedRow} id={columnId} extraPadding={!showCommentView}>
            {renderValuePrefix(columnId, fieldId, value, field.decimals || 0, isSelectedRow)}
            {isEditableColumn && !isInputDisabled(fieldId) ? (
              <ArithmeticInput
                value={valueForDisplay(field, value)}
                formattedValue={formattedValue}
                decimals={field.decimals}
                tabIndex={tabIndex}
                valueCommit={value => onValueChange && onValueChange(fieldId, valueToSave(field, value))} // Save changed value to redux states
                isFocused={isFocused == fieldId}
                onFocus={() => setFocused(fieldId, true)}
                onBlur={() => {
                  setFocused(fieldId, false);
                  onBlur && onBlur(fieldId); // Trigger RECOGNITIONS_EDIT_CALCULATE action
                }}
                copyMode={copyMode}
                onCopyCurrent={value => (onCopyCurrent ? onCopyCurrent(fieldId, valueToSave(field, value)) : undefined)}
              />
            ) : (
              <TableNumber id={columnId}>{formattedValue}</TableNumber>
            )}
            {renderExtraItem(columnId, isSelectedRow)}
            {showCommentView && (
              <StyledCommentView
                field={field}
                comments={filteredComments}
                freezingTitle={freezingTitle}
                frozenTitle={frozenTitle}
                freezings={filteredFreezings}
                excludeScrollbar={true}
                initialFreezeValue={isFreezableColumn ? value : undefined}
                freezingDisabledReason={freezingDisabledReasons(fieldId)}
                onAddFreezing={
                  isFreezableColumn && !freezingDisabled(fieldId)
                    ? (value: number, comment: string) =>
                        onAddFreezing && onAddFreezing(fieldId, valueToSave(field, value), comment)
                    : undefined
                }
                onRemoveFreezing={
                  isFreezableColumn && !freezingDisabled(fieldId)
                    ? () => onRemoveFreezing && onRemoveFreezing(fieldId)
                    : undefined
                }
                onAddComment={
                  onAddComment ? (type, value, content) => onAddComment(fieldId, type, value, content) : undefined
                }
                onDeleteComment={
                  onDeleteComment ? (type, value, content) => onDeleteComment(fieldId, type, value, content) : undefined
                }
                hasContent={filteredComments.length > 0 || filteredFreezings.length > 0}
                commentTypes={commentTypes}
              />
            )}
          </DataValue>
        </DataItem>
      );
    });
  } else {
    if (ALL_COLUMNS[columnId].isStringValue) {
      return (
        <DataItem key={columnId}>
          <DataValue isSelectedRow={isSelectedRow} id={columnId}>
            {(value as StringValue).value}
          </DataValue>
        </DataItem>
      );
    } else {
      const fieldValues = value as { [key: string]: string | number };
      const columnSpec = ALL_COLUMNS[columnId];

      if (!columnSpec) console.log("Column spec not found for", columnId);

      return entries(columnSpec.fields).map(([fieldId, field]) => {
        const key = `${columnId}_${fieldId}`;
        if (editType !== RecognitionEditType.Manual && isFuturePeriod && field.hideForFuturePeriods === true) {
          return <DataItem key={key} />;
        }

        const fv = fieldValues[fieldId];
        if (fv === undefined) console.log("no value for column", columnId, "field", fieldId, "in", fieldValues, value);
        const fieldValue = fv ? fv.toString() : "undefined";

        const filteredComment = comments && find(comments, comment => comment.fieldName === fieldId);
        const filteredComments = filteredComment ? filteredComment.comments : [];
        const filteredFreezings = filter(freezings, freezing => freezing.fieldName === fieldId);

        const showCommentView = onAddComment || filteredComments.length > 0 || filteredFreezings.length > 0;

        const highlightColor = highlightField && highlightField(columnId, fieldId);
        const tooltip = fieldTooltip && fieldTooltip(columnId, fieldId);

        const floatValue = parseFloat(fieldValue);
        const sanitisedValue = isNaN(floatValue) ? 0 : floatValue;
        const formattedValue = Format.withThousandSeparator(valueForDisplay(field, sanitisedValue), field.decimals);

        const addit = additionalData && additionalData[fieldId];

        if (additionalData) console.log("Additional data: ", additionalData);

        const dataTitle = () => {
          if (addit) {
            switch (addit.kind) {
              case "rate_data":
                return (
                  <Tooltip component={CurrencyPairs(addit.currencyPairs)} offset={{ x: -80, y: 0 }}>
                    <DataTitle
                      id={columnId}
                      extraWide={columnId === "cost_var_del" || columnId === "cost_var_warr"}
                      highlightColor={{ color: "#000", background: rateCurrencyPairsHighlight }}
                      title={tooltip}
                    >
                      {field.title}
                    </DataTitle>
                  </Tooltip>
                );
            }
          } else {
            return (
              <DataTitle
                id={columnId}
                extraWide={columnId === "cost_var_del" || columnId === "cost_var_warr"}
                highlightColor={highlightColor}
                title={tooltip}
              >
                {field.title}
              </DataTitle>
            );
          }
        };

        return (
          fieldValue && (
            <DataItem key={`${columnId}_${fieldId}`}>
              {dataTitle()}
              <DataValue isSelectedRow={isSelectedRow} id={columnId} extraPadding={!showCommentView}>
                {renderValuePrefix(columnId, fieldId, floatValue, field.decimals)}
                <TableNumber id={columnId}>{formattedValue}</TableNumber>
                {renderExtraItem(columnId, isSelectedRow)}
                {showCommentView && (
                  <StyledCommentView
                    field={field}
                    comments={filteredComments}
                    freezings={filteredFreezings}
                    excludeScrollbar={true}
                    onAddComment={
                      onAddComment ? (type, value, content) => onAddComment(fieldId, type, value, content) : undefined
                    }
                    onDeleteComment={
                      onDeleteComment
                        ? (type, value, content) => onDeleteComment(fieldId, type, value, content)
                        : undefined
                    }
                    hasContent={filteredComments.length > 0 || filteredFreezings.length > 0}
                    commentTypes={commentTypes}
                  />
                )}
              </DataValue>
            </DataItem>
          )
        );
      });
    }
  }
};

// eslint-disable-next-line react/display-name
export const TableHeaderCell = React.memo(
  (props: HeaderCellProps): React.ReactElement => {
    const { columnId, value, hilight, tags, tagTitles, borders, extraPadding, icon, onIconClick } = props;
    return (
      <Container key={columnId} borders={borders} hilight={hilight} disabled={false}>
        {tags &&
          tags.map(
            (tag, index) =>
              tag && (
                <PeriodTag key={`${tag}-${index}`} title={tagTitles ? tagTitles[index] || "" : ""}>
                  {tag}
                </PeriodTag>
              )
          )}
        <HierarchyCellContainer extraPadding={extraPadding}>
          {icon !== undefined && (
            <IconButton onClick={() => onIconClick && onIconClick()} padding={"10px"}>
              <FontAwesomeIcon icon={icon} size="lg" color={settingGreen} />
            </IconButton>
          )}
          <HeaderValue id={columnId}>{value}</HeaderValue>
        </HierarchyCellContainer>
      </Container>
    );
  }
);

// eslint-disable-next-line react/display-name
export const TableDataCell = React.memo(
  (props: DataCellProps): React.ReactElement => {
    const {
      dataEntity,
      additionalData,
      editType,
      onValueChange,
      onCopyCurrent,
      onFocus,
      onBlur,
      onAddFreezing,
      onRemoveFreezing,
      onAddComment,
      onDeleteComment,
      borders,
      hilight,
      isFuturePeriod,
      disabled,
      freezingDisabled,
      highlightField,
      fieldTooltip,
      onHasError,
      changes,
      commentTypes,
      isSelectedRow,
      tabIndex,
    } = props;
    return (
      <Container
        key={dataEntity.columnId}
        borders={borders}
        hilight={hilight}
        disabled={disabled === true}
        isSelectedRow={isSelectedRow}
      >
        {renderItem(
          dataEntity,
          additionalData,
          editType,
          isFuturePeriod,
          disabled,
          commentTypes,
          freezingDisabled,
          highlightField,
          fieldTooltip,
          onValueChange,
          onCopyCurrent,
          onFocus,
          onBlur,
          onAddFreezing,
          onRemoveFreezing,
          onAddComment,
          onDeleteComment,
          onHasError,
          changes,
          isSelectedRow,
          tabIndex
        )}
      </Container>
    );
  },
  (prev, next) => {
    let additionalDataSame: boolean = next.additionalData === prev.additionalData;
    if (!additionalDataSame && next.additionalData !== undefined) {
      additionalDataSame = isEqual(prev.additionalData, next.additionalData);
    }
    return (
      next.dataEntity === prev.dataEntity &&
      next.fieldTooltip === prev.fieldTooltip &&
      next.highlightField === prev.highlightField &&
      next.isSelectedRow === prev.isSelectedRow &&
      next.disabled === prev.disabled &&
      additionalDataSame
    );
  }
);

const StyledCommentView = styled(CommentView)<{ hasContent: boolean }>`
  ${({ hasContent }) => `visibility: ${hasContent ? "visible" : "hidden"};`}
  width: 24px;
`;

const Container = styled.td<{ borders?: boolean; hilight?: boolean; disabled: boolean; isSelectedRow?: boolean }>`
  color: ${valmetGreyLight};
  font-size: 10px;
  padding-left: 5px;
  padding-right: 5px;
  min-height: 48px;
  ${({ disabled, isSelectedRow }) => {
    return disabled && !isSelectedRow && "background-color: #f8f7f7b2 !important";
  }}
  ${({ borders }) => {
    return borders && `border-right: 1px solid ${valmetGreyREC};`;
  }};
  ${({ hilight }) => hilight && `background-color: ${projectsYellowTransparentOnWhite} !important;`};
`;

const HierarchyCellContainer = styled.div<{ extraPadding?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-left: ${({ extraPadding }) => (extraPadding ? "32px" : "0px")};
`;

const PeriodTag = styled.span`
  border-radius: 4px;
  background-color: ${recPeriodTagBackgroundGrey};
  padding: 4px;
  margin-right: 4px;
`;

const DataItem = styled.div<{ changed?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: baseline;
  width: 100%;
  justify-content: space-between;
  &:hover ${StyledCommentView} {
    visibility: visible;
  }
  background-color: ${({ changed }) => (changed ? "#ffeeff" : "transparent")};
  font-weight: ${({ changed }) => (changed ? "bold" : "normal")};
`;

const DataTitle = styled.div<{
  id?: ColumnId | EditableColumnId;
  extraWide?: boolean;
  highlightColor?: HighlightColor;
  changed?: boolean;
}>`
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  min-width: ${({ extraWide }) => (extraWide ? "85px" : "auto")};
  color: ${({ highlightColor }) => (highlightColor ? highlightColor.color : valmetGreyHeader)};
  ${({ highlightColor }) => (highlightColor ? `background-color: ${highlightColor.background};` : "")}
  padding-left: 3px;
  padding-right: 3px;
  border-radius: 4px;
  ${({ id, highlightColor }) => {
    switch (id) {
      case "est_rec_percent_edit":
      case "rec":
      case "est_rec_percentage":
        if (highlightColor !== undefined) {
          return `color: ${highlightColor.color}; font-size: 10px;`;
        } else {
          return `color: ${valmetGreyLight}; font-size: 10px;`;
        }

      case "as_sold_edit":
      case "budget_edit":
      case "revised_edit":
      case "est_edit":
      case "est_rec_edit":
      case "rec_edit":
      case "invoicing_edit":
      case "comm_costs_edit":
      case "comm_costs_warr_edit":
      case "ptd_variances_edit":
      case "orders_received_edit":
        if (highlightColor !== undefined) {
          return `color: ${highlightColor.color}; font-size: 10px; line-height: 16px;`;
        } else {
          return `color: ${valmetGreyLight}; font-size: 10px; line-height: 16px;`;
        }
      default:
        return undefined;
    }
  }}
  font-weight: ${({ changed }) => (changed ? "bold" : "normal")};
`;

const HeaderValue = styled.div<{ id: string }>`
  white-space: nowrap;
  display: inline-block;
  padding-top: 10px;
  padding-bottom: 10px;
  color: ${({ id }) => (id === "hierarchy" ? valmetGreyId : valmetGreyLight)};
  font-size: 14px;
  font-weight: ${({ id }) => (id === "hierarchy" ? "600" : "normal")};
  ${({ id }) => id === "hierarchy" && "min-width: 280px"};
`;

const DataValue = styled.div<{ id?: ColumnId | EditableColumnId; extraPadding?: boolean; isSelectedRow?: boolean }>`
  ${({ id, isSelectedRow }) => {
    switch (id) {
      case "desc":
        return `color: ${valmetGreyLight}; font-size: 10px; font-weight: normal;`;
      case "org":
        return `color: ${valmetGreyLight}; font-size: 10px; font-weight: normal; max-width: 120px; white-space: normal;`;
      case "p_id":
        return `color: ${valmetGreyId}; font-size: 14px; font-weight: 600;`;
      case "type":
        return `color: ${valmetGreyLight}; font-size: 11px; font-weight: normal;`;
      case "rates":
        return `color: ${valmetGreyHeader}; font-size: 12px; font-weight: 600; min-width: 45px; justify-content:space-between;`;
      case "currency":
        return `color: ${valmetGreyLight}; font-size: 12px; font-weight: lighter;`;
      case "rec":
      case "est_rec_percentage":
      case "est_rec_percent_edit":
        return `color: ${
          isSelectedRow ? selectedRowColor : valmetGreyREC
        }; font-size: 30px; padding-left: 0px; font-weight: 800;
        justify-content:flex-end; div:not(:first-child) { padding-bottom:6px; };`;
      default:
        return `color: ${valmetGreyHeader}; font-size: 12px; font-weight: 600; min-width: 45px; justify-content:flex-end;`;
    }
  }};
  ${({ extraPadding }) => extraPadding && "padding-right: 24px; line-height: 1.2;"};
  ${({ id }) => {
    if (id === "org") return "min-width: 180px";
    else return "white-space: nowrap; display: flex; flex-direction: row; align-items: baseline;";
  }};
  margin-left: 15px;
  /*
  ${({ id }) => {
    switch (id) {
      case "rec":
      case "est_rec_percent_edit":
      case "est_rec_percentage":
        return `min-width: 6rem; max-width: 12rem;`;
      case "as_sold_edit":
      case "budget_edit":
      case "revised_edit":
      case "est_edit":
      case "est_rec_edit":
      case "rec_edit":
      case "invoicing_edit":
      case "comm_costs_edit":
      case "comm_costs_warr_edit":
      case "ptd_variances_edit":
      case "orders_received_edit":
        return `min-width: 6rem; max-width: 8rem`;
      default:
        return `min-width: 3rem; max-width: 6rem`;
    }
  }}
  */
`;

const ExtraItem = styled.div<{ isSelectedRow?: boolean }>`
  color: ${({ isSelectedRow }) => (isSelectedRow ? selectedRowColor : valmetGreyREC)};
  font-size: 18px;
  font-weight: bold;
  margin-left: 4px;
`;

const ValuePrefix = styled.div<{ isSelectedRow?: boolean }>`
  color: ${({ isSelectedRow }) => (isSelectedRow ? selectedRowColor : valmetGreyREC)};
  font-size: 26px;
  font-weight: bold;
  margin-left: 8px;
`;

const TableNumberInput = styled.input<{ columnId?: string; disabled?: boolean }>`
  font-weight: inherit;
  text-align: right;
  margin-left: 5px;
  color: inherit;
  font-size: inherit;
  background-color: inherit;
  border: ${({ disabled }) => {
    return disabled ? `none` : `solid 1px ${settingGreen}`;
  }};
  ${({ columnId }) => {
    switch (columnId) {
      case "est_rec_percent_edit":
        return `width: 4em;`;
      default:
        return `width: 8em;`;
    }
  }};
`;

const TableNumber = styled.div<{ id?: ColumnId | EditableColumnId }>`
  text-align: right;
  width: auto;
`;

const CopyButton = styled.button`
  position: absolute;
  right: 0;
  margin-bottom: 20px;

  display: flex;
  flex-direction: row;
  column-gap: 5px;
`;

const CurrencyPairsTooltip = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 180px;
  gap: 5px;
  font-size: 12px;
`;
const CurrencyPairsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  font-size: 13px;
`;

const CurrencyPairDiv = styled.div<{
  highlight?: boolean;
}>`
  ${({ highlight }) => highlight && "color: #48d;"}
`;

const CurrencyPair = ({ from, to }: RateCurrencyPair, last: boolean) => (
  <CurrencyPairDiv key={from + "." + to} highlight={last}>
    <b>{from}</b>
    {"\u2192"}
    <b>{to}</b>
    {!last ? "," : ""}
  </CurrencyPairDiv>
);

const CurrencyPairs = (pairs: RateCurrencyPair[] | undefined) => {
  return (
    <CurrencyPairsTooltip>
      Currency pairs used to calculate the rate:
      <CurrencyPairsContainer>
        {pairs === undefined
          ? "-"
          : pairs.map((pair, index) => {
              const last = index === pairs.length - 1;
              return CurrencyPair(pair, last);
            })}
      </CurrencyPairsContainer>
    </CurrencyPairsTooltip>
  );
};
