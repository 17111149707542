import { ApolloClient, gql } from "@apollo/client";

const FRONTEND_LOG = gql`
  mutation Logging($logLevel: FrontendLogLevel!, $message: String!, $properties: [PropertyTuple!]) {
    frontendLog(logLevel: $logLevel, message: $message, properties: $properties)
  }
`;

enum FrontendLogLevel {
  Debug = "Debug",
  Info = "Info",
  Warn = "Warn",
  Error = "Error",
}

function translateMessage(...params: unknown[]): string {
  class Ctx {
    items: unknown[];

    constructor(items: unknown[]) {
      this.items = items;
    }

    next(): unknown {
      return this.items.shift();
    }

    hasItems(): boolean {
      return this.items.length > 0;
    }
  }

  // https://developer.mozilla.org/en-US/docs/Web/API/console#outputting_text_to_the_console
  // Using string substitutions
  function handleSubstitution(ctx: Ctx, s: string, index: number): [string, number] {
    if (index >= s.length) return ["", index];
    switch (s[index]) {
      case "s": {
        const item = ctx.next();
        if (typeof item === "string") {
          return [item, index + 1];
        } else {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          return [(item as any).toString(), index + 1];
        }
      }
      case "d":
      case "i": {
        const item = ctx.next();
        if (typeof item === "number") {
          return [item.toFixed(0).toString(), index + 1];
        } else {
          return ["0", index + 1];
        }
      }
      case "f": {
        const item = ctx.next();
        if (typeof item === "number") {
          return [item.toString(), index + 1];
        } else {
          return ["0", index + 1];
        }
      }
      case "o":
      case "O": {
        return [JSON.stringify(ctx.next()), index + 1];
      }
      case ".": {
        index++;
        const zero = "0".charCodeAt(0);
        let n = 0;
        while (index < s.length && "0" <= s[index] && s[index] <= "9") {
          const digit = s.charCodeAt(index) - zero;
          n *= 10;
          n += digit;
          index++;
        }
        switch (s[index]) {
          case "d":
          case "i": {
            const item = ctx.next();
            if (typeof item === "number") {
              return [item.toFixed(0).padStart(n, "0"), index + 1];
            } else {
              return ["".padStart(n, "0"), index + 1];
            }
          }
          case "f": {
            const item = ctx.next();
            if (typeof item === "number") {
              return [item.toFixed(n), index + 1];
            } else {
              return [(0).toFixed(n), index + 1];
            }
          }
          default: {
            return ["<invalid substitution %." + n + s[index] + ">", index + 1];
          }
        }
      }
      default:
        return ["<invalid substitution %" + s[index] + ">", index + 1];
    }
  }

  function handleStringItem(ctx: Ctx, item: string): string {
    let result = "";
    let index = 0;
    while (index < item.length) {
      if (item[index] === "%") {
        index++;
        const [s, newIndex] = handleSubstitution(ctx, item, index);
        result += s;
        index = newIndex;
      } else {
        result += item[index];
        index++;
      }
    }
    return result;
  }

  let message = "";
  const ctx = new Ctx(params);
  let first = true;
  while (ctx.hasItems()) {
    if (!first) message += " ";
    first = false;

    const item = ctx.next();
    if (typeof item === "string") {
      message += handleStringItem(ctx, item);
    } else {
      message += JSON.stringify(item);
    }
  }
  return message;
}

let globalClient: ApolloClient<Record<string, unknown>>;

export function initLogger(moduleId: string) {
  function frontendLog(logLevel: FrontendLogLevel, ...params: unknown[]): void {
    const location = window.location.toString();
    const properties = [
      { key: "browserLocation", value: location },
      { key: "moduleId", value: moduleId },
    ];

    const message = translateMessage(...params);
    //originalConsoleLog("[Message]", message);

    globalClient
      .mutate({
        mutation: FRONTEND_LOG,
        variables: {
          logLevel,
          message,
          properties,
        },
      })
      .catch(reason => {
        console.log("Failed to send frontend logs", reason);
      });
  }

  return {
    debug(...params: unknown[]) {
      frontendLog(FrontendLogLevel.Debug, ...params);
      console.debug(...params);
    },
    info(...params: unknown[]) {
      frontendLog(FrontendLogLevel.Info, ...params);
      console.info(...params);
    },
    log(...params: unknown[]) {
      frontendLog(FrontendLogLevel.Info, ...params);
      console.log(...params);
    },
    warn(...params: unknown[]) {
      frontendLog(FrontendLogLevel.Warn, ...params);
      console.warn(...params);
    },
    error(...params: unknown[]) {
      frontendLog(FrontendLogLevel.Error, ...params);
      console.error(...params);
    },
  };
}

export function initLogging(client: ApolloClient<Record<string, unknown>>) {
  globalClient = client;

  /*
  const originalConsoleLog = console.log;
  const originalConsoleDebug = console.debug || originalConsoleLog;
  const originalConsoleWarn = console.warn || originalConsoleLog;
  const originalConsoleError = console.error || originalConsoleLog;

  function frontendLog(logLevel: FrontendLogLevel, ...params: unknown[]): void {
    const message = translateMessage(...params);
    //originalConsoleLog("[Message]", message);
    client
      .mutate({
        mutation: FRONTEND_LOG,
        variables: {
          logLevel,
          message,
        },
      })
      .catch(reason => {
        originalConsoleLog("Failed to send frontend logs", reason);
      });
  }

  console.debug = function (...params: unknown[]): void {
    frontendLog(FrontendLogLevel.Debug, ...params);
    originalConsoleDebug(...params);
  };
  console.log = function (...params: unknown[]): void {
    frontendLog(FrontendLogLevel.Info, ...params);
    originalConsoleLog(...params);
  };
  console.warn = function (...params: unknown[]): void {
    frontendLog(FrontendLogLevel.Warn, ...params);
    originalConsoleWarn(...params);
  };
  console.error = function (...params: unknown[]): void {
    frontendLog(FrontendLogLevel.Error, ...params);
    originalConsoleError(...params);
  };
   */
}
