import React from "react";
import ErrorBox from "../ErrorBox";
import { HeaderActions, HeaderActionsRefs } from "../sites/Project/ProjectHeaderActions";

export interface CreateProjectErrorsBoxProps {
  errors: string[];
}

function CreateProjectErrorsBox(props: CreateProjectErrorsBoxProps) {
  const { errors } = props;
  const errorCaption = `Error creating new project ${errors.length > 0 ? ":" : "."}`;
  return (
    <HeaderActions refName={HeaderActionsRefs.errorsBoxRef}>
      <ErrorBox
        caption={errorCaption}
        errorContent={errors && errors.slice(0, 2).map((error, i) => <div key={i}>{error}</div>)}
      />
    </HeaderActions>
  );
}

export default CreateProjectErrorsBox;
