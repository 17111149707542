import React from "react";
import styled from "styled-components";
import { disabledGrey, valmetGreyLight, valmetGreyBorder } from "../../../../common/colors";
import { EditableRecognitionRow } from "./types";
import { Period, periodToString } from "../../../../common/period";
import { ActionButton, ButtonContainer } from "../../../../common/components";
import { RecPercentEdit } from "../../../../common/columnsTypes";

export interface SaveCopyConfirmDialogProps {
  data: EditableRecognitionRow[];
  onCopyAndSave: () => void;
  onSave: () => void;
  onCancel: () => void;
}

function Example(props: { period: Period; nsPercent: number; cogsPercent: number }) {
  const { period, nsPercent, cogsPercent } = props;
  return (
    <ExampleContainer>
      <Cell>{periodToString(period)}</Cell>
      <Divider />
      <Cell>
        <MeasureRow border={true}>
          <MeasureTitle>NS%</MeasureTitle>
          <MeasureValue>{nsPercent.toFixed(2)}</MeasureValue>
          <Percent>%</Percent>
        </MeasureRow>
        <ArrowContainer>
          <Arrow color={valmetGreyBorder} />
        </ArrowContainer>
        <MeasureRow>
          <MeasureTitle>Cogs%</MeasureTitle>
          <MeasureValue>{cogsPercent.toFixed(2)}</MeasureValue>
          <Percent>%</Percent>
        </MeasureRow>
      </Cell>
    </ExampleContainer>
  );
}

function SaveCopyConfirmDialog(props: SaveCopyConfirmDialogProps): React.ReactElement | null {
  const { data, onCopyAndSave, onSave, onCancel } = props;

  const exampleData = data[0];
  const exampleCell = exampleData.findCell("est_rec_percent_edit");
  const exampleValue = exampleCell && exampleCell.value;
  const example = exampleValue && {
    period: exampleData.period,
    nsPercent: exampleValue.nsPercent,
    cogsPercent: exampleValue.cogsPercent,
  };

  return (
    <Wrapper>
      <ContentContainer>
        <Title>Copy EST REC NS% to Cogs%</Title>
        <Info>
          <p>
            For <b>{data.length > 1 ? `${data.length} edited periods` : "one edited period"}</b>{" "}
            <Measure>EST REC Cogs%</Measure> differs from the corresponding <Measure>EST REC NS%</Measure> value.
          </p>
          <p>
            Should the <Measure>Cogs%</Measure> values be overwritten with the corresponding <Measure>NS%</Measure>{" "}
            values?
          </p>
        </Info>

        {example && <Example period={example.period} nsPercent={example.nsPercent} cogsPercent={example.cogsPercent} />}

        <ButtonContainer>
          <ActionButton onClick={() => onCopyAndSave()}>Copy & Save</ActionButton>
          <ActionButton onClick={() => onSave()}>Save</ActionButton>
          <ActionButton onClick={() => onCancel()}>Cancel</ActionButton>
        </ButtonContainer>
      </ContentContainer>
    </Wrapper>
  );
}

export default SaveCopyConfirmDialog;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  width: 100%;
  background-color: ${disabledGrey};
  z-index: 1200;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 25px;
  padding-right: 25px;
  color: ${valmetGreyLight};
  width: 450px;
  align-items: flex-start;
`;

const Title = styled.div`
  font-size: 24px;
`;

const Info = styled.div`
  font-size: 16px;
  margin-top: 20px;
  p {
    margin-top: 0.7em;
    margin-bottom: 0.7em;
  }
`;

const Measure = styled.span`
  font-size: 12px;
  font-weight: 600;
  padding-left: 0.5em;
  padding-right: 0.5em;
`;

const ExampleContainer = styled.div`
  margin-right: auto;
  margin-left: auto;
  margin-top: -10px;
  margin-bottom: -10px;
  display: flex;
  flex-direction: row;
  border: 2px dashed #f0f0f0;
  padding: 5px;
  transform: scale(0.85);
`;

const Cell = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  margin-right: 10px;
  margin-left: 10px;
`;

const Divider = styled.div`
  width: 0;
  border-right: 1px solid ${valmetGreyBorder};
`;

const Arrow = styled.div<{ color?: string }>`
  position: relative;
  height: 0px;
  width: 0px;
  border: 12px solid;
  ${({ color }) => `border-color: ${color} ${color} transparent transparent`};
  transform: rotate(135deg);
  /*&:before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    display: block;
    height: 12px;
    width: 16px;
    ${({ color }) => `background-color: ${color}`};
    transform: rotate(-45deg) translate(2px, 1px);
  }*/
`;

const ArrowContainer = styled.div`
  position: relative;
  margin-right: auto;
  margin-left: auto;
  top: -12px;
  height: 8px;
`;

const MeasureRow = styled.div<{ border?: boolean }>`
  display: flex;
  flex-direction: row;
  padding: 5px;
  border: ${({ border }) => (border ? `1px solid ${valmetGreyBorder}` : "0 none")};
`;

const MeasureTitle = styled.div`
  min-width: 30px;
  color: #6f6f6f;
  font-size: 10px;
  font-weight: normal;
  align-self: center;
`;

const MeasureValue = styled.div`
  display: inline-block;
  width: 106px;
  text-align: right;
  color: #d8d8d8;
  font-size: 30px;
  font-weight: 800;
  align-self: end;
  line-height: 1.2;
`;

const Percent = styled.span`
  color: #d8d8d8;
  font-size: 18px;
  font-weight: bold;
  margin-left: 4px;
  align-self: end;
`;
