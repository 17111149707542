import { ProfitPITProgressId, ProfitStatus, ProfitStatusValue } from "./types";

export const ProfitStatusValues: ProfitStatusValue[] = [
  { id: ProfitStatus.Draft, description: "Draft" },
  { id: ProfitStatus.Active, description: "Active" },
  { id: ProfitStatus.InClosing, description: "In Closing" },
  { id: ProfitStatus.Closed, description: "Closed" },
];

// Mapping from current status to selectable statuses
export const SelectableProfitStatuses: { [key in ProfitStatus]: ProfitStatus[] } = {
  [ProfitStatus.Draft]: [ProfitStatus.Active],
  [ProfitStatus.Active]: [ProfitStatus.InClosing],
  [ProfitStatus.InClosing]: [ProfitStatus.Active],
  [ProfitStatus.Closed]: [ProfitStatus.Closed],
};

type ProfitPITProgressValue = {
  id: ProfitPITProgressId;
  description: string;
};

export enum ProfitPITProgress {
  Empty = 1,
  Partial = 2,
  Full = 3,
}

export const ProfitPITProgressValues: ProfitPITProgressValue[] = [
  { id: ProfitPITProgress.Empty, description: "Empty" },
  { id: ProfitPITProgress.Partial, description: "Partial recognition" },
  { id: ProfitPITProgress.Full, description: "Full recognition" },
];
