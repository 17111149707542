import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { cloneDeep, remove } from "lodash";
import { valmetGreyLight } from "../../../../../common/colors";
import { PROJECT_COST_PRODUCTION_COLUMNS, PROJECT_COST_PRODUCTION_COLUMNS_SER_ORDER } from "./constants";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import { CollapseButton } from "../../../../../common/components";
import {
  ProjectProductionOrderSubTotalLevel,
  ProjectCostProductionItem,
  ProjectCostProductionOrderItem,
  ProjectCostProductionOrderLineLaborItem,
  ProjectCostProductionOrderLineMaterialItem,
  ProjectCostProductionOrderLineOtherItem,
  ProjectCostProductionOrderLinePurchaseItem,
  ProjectCostProductionOrderLineSurchargeItem,
  ProjectCostProductionTableItem,
  SerOrderProductionOrderSubTotalLevel,
  SerOrderCostProductionItem,
  SerOrderCostProductionOrderItem,
  SerOrderCostProductionOrderLineLaborItem,
  SerOrderCostProductionOrderLinePurchaseItem,
  ProjectCostProductionOrderLineItem,
  SerOrderCostProductionOrderLineItem,
  ProjectCostProductionAggregatedValues,
  SerOrderCostProductionAggregatedValues,
  SerOrderCostProductionOrderLineMaterialItem,
  SerOrderCostProductionOrderLineOtherItem,
  SerOrderCostProductionOrderLineSurchargeItem,
} from "./types";
import { ProjectVirtualType } from "../../../../../common/types";
import * as datefns from "date-fns";
import { justifyToRight } from "./column";
import { CostDetailsProductionItem } from "../types";
import {
  DataCell,
  DataContainer,
  DataRow,
  DetailsTable,
  HeaderCell,
  HeaderRow,
  TableBody,
  TypedDataCell,
} from "./components";

export interface ProductionTableProps {
  productionItems: CostDetailsProductionItem[];
  projectVirtualType: ProjectVirtualType;
  popupWindow: boolean;
}

type TableChildItemType =
  | ProjectCostProductionItem
  | ProjectCostProductionOrderItem
  | ProjectProductionOrderSubTotalLevel
  | ProjectCostProductionOrderLineItem
  | SerOrderCostProductionItem
  | SerOrderCostProductionOrderItem
  | SerOrderProductionOrderSubTotalLevel
  | SerOrderCostProductionOrderLineItem;

const orUndefined = <T extends unknown>(v: T | null): T | undefined => (v === null ? undefined : v);

const transformProductionItem = (item: ProjectCostProductionItem): ProjectCostProductionTableItem => {
  return {
    id: item.subProject,
    childLevel: 0,
    description: item.subProject,
    actualAmount: item.aggregatedValues.actualAmount,
    estimateAmount: item.aggregatedValues.estimateAmount,
    softCommitments: item.aggregatedValues.softCommitments,
    hardCommitments: item.aggregatedValues.hardCommitments,
    total: item.aggregatedValues.total,
    childItems: item.childItems,
  };
};

const transformSerOrderProductionItem = (item: SerOrderCostProductionItem): ProjectCostProductionTableItem => {
  return {
    id: item.subProject,
    childLevel: 0,
    description: item.subProject,
    actuals: item.aggregatedValues.actuals,
    poLineAmount: item.aggregatedValues.poLineAmount,
    poLinePrice: item.aggregatedValues.poLinePrice,
    childItems: item.childItems,
  };
};

type Option<T> = T | null | undefined;
const sumOptions = (a: Option<number>, b: Option<number>): Option<number> => {
  if (a == undefined || a == null) return b;
  if (b == undefined || b == null) return a;
  return a + b;
};

const transformProductionOrder = (
  item: ProjectCostProductionOrderItem,
  parentId: string
): ProjectCostProductionTableItem => {
  const makeSubTotal = (
    description: string,
    aggregatedValues: ProjectCostProductionAggregatedValues,
    //childItems: ProjectCostProductionOrderLineItem[]
    childItems:
      | ProjectCostProductionOrderLineLaborItem[]
      | ProjectCostProductionOrderLineMaterialItem[]
      | ProjectCostProductionOrderLineOtherItem[]
      | ProjectCostProductionOrderLineSurchargeItem[]
      | ProjectCostProductionOrderLinePurchaseItem[]
  ): ProjectProductionOrderSubTotalLevel => ({
    __typename: "ProjectProductionOrderSubTotalLevel",
    description,
    childItems,
    aggregatedValues,
  });
  const laborTotal: ProjectProductionOrderSubTotalLevel = makeSubTotal("Labor total", item.laborTotal, item.labor);
  const materialsTotal: ProjectProductionOrderSubTotalLevel = makeSubTotal(
    "Materials total",
    item.materialsTotal,
    item.materials
  );
  const purchasesTotal: ProjectProductionOrderSubTotalLevel[] = item.purchases
    ? [makeSubTotal("Purchases total", item.purchasesTotal, item.purchases)]
    : [];
  const surchargesTotal: ProjectProductionOrderSubTotalLevel = makeSubTotal(
    "Surcharges total",
    item.surchargesTotal,
    item.surcharges
  );
  const othersTotal: ProjectProductionOrderSubTotalLevel[] = item.others
    ? [makeSubTotal("Others total", item.othersTotal, item.others)]
    : [];
  const childItems = [laborTotal, materialsTotal, ...purchasesTotal, surchargesTotal, ...othersTotal];
  return {
    id: parentId + item.order,
    childLevel: 1,
    description: item.order,
    actualAmount: item.aggregatedValues.actualAmount,
    estimateAmount: item.aggregatedValues.estimateAmount,
    softCommitments: item.aggregatedValues.softCommitments,
    hardCommitments: item.aggregatedValues.hardCommitments,
    total: item.aggregatedValues.total,
    childItems: childItems,
  };
};

const transformSerOrderProductionOrder = (
  item: SerOrderCostProductionOrderItem,
  parentId: string
): ProjectCostProductionTableItem => {
  const makeSubTotal = (
    description: string,
    aggregatedValues: SerOrderCostProductionAggregatedValues,
    //childItems: SerOrderCostProductionOrderLineItem[]
    childItems:
      | SerOrderCostProductionOrderLineLaborItem[]
      | SerOrderCostProductionOrderLineMaterialItem[]
      | SerOrderCostProductionOrderLineOtherItem[]
      | SerOrderCostProductionOrderLineSurchargeItem[]
      | SerOrderCostProductionOrderLinePurchaseItem[]
  ): SerOrderProductionOrderSubTotalLevel => ({
    __typename: "SerOrderProductionOrderSubTotalLevel",
    description,
    childItems,
    aggregatedValues,
  });
  const laborTotal: SerOrderProductionOrderSubTotalLevel = makeSubTotal("Labor total", item.laborTotal, item.labor);
  const materialsTotal: SerOrderProductionOrderSubTotalLevel = makeSubTotal(
    "Materials total",
    item.materialsTotal,
    item.materials
  );
  const purchasesTotal: SerOrderProductionOrderSubTotalLevel[] = item.purchases
    ? [makeSubTotal("Purchases total", item.purchasesTotal, item.purchases)]
    : [];
  const surchargesTotal: SerOrderProductionOrderSubTotalLevel = makeSubTotal(
    "Surcharges total",
    item.surchargesTotal,
    item.surcharges
  );
  const othersTotal: SerOrderProductionOrderSubTotalLevel[] = item.others
    ? [makeSubTotal("Others total", item.othersTotal, item.others)]
    : [];
  const childItems = [laborTotal, materialsTotal, ...purchasesTotal, surchargesTotal, ...othersTotal];
  return {
    id: parentId + item.order,
    childLevel: 1,
    description: item.order,
    actuals: item.aggregatedValues.actuals,
    poLineAmount: item.aggregatedValues.poLineAmount,
    poLinePrice: item.aggregatedValues.poLinePrice,
    childItems: childItems,
  };
};

const transformProductionOrderSubTotal = (
  item: ProjectProductionOrderSubTotalLevel,
  parentId: string
): ProjectCostProductionTableItem => {
  return {
    id: parentId + item.description,
    childLevel: 2,
    description: item.description,
    actualAmount: item.aggregatedValues.actualAmount,
    estimateAmount: item.aggregatedValues.estimateAmount,
    softCommitments: item.aggregatedValues.softCommitments,
    hardCommitments: item.aggregatedValues.hardCommitments,
    total: item.aggregatedValues.total,
    childItems: item.childItems,
  };
};

const transformSerOrderProductionOrderSubTotal = (
  item: SerOrderProductionOrderSubTotalLevel,
  parentId: string
): ProjectCostProductionTableItem => {
  return {
    id: parentId + item.description,
    childLevel: 2,
    description: item.description,
    actuals: item.aggregatedValues.actuals,
    poLineAmount: item.aggregatedValues.poLineAmount,
    poLinePrice: item.aggregatedValues.poLinePrice,
    estimateAmount: item.aggregatedValues.estimatedAmount,
    childItems: item.childItems,
  };
};

const dateOrUndefined = (d: string | null): string | undefined =>
  d === null || d === "" ? undefined : datefns.format(new Date(d), "yyyy-MM-dd");

const employeeOrDash = (
  item: ProjectCostProductionOrderLineLaborItem | SerOrderCostProductionOrderLineLaborItem
): string =>
  (item.employee != null && item.employee.length > 0) || (item.employeeName != null && item.employeeName.length > 0)
    ? `${item.employee} ${item.employeeName}`
    : "-";

const taskString = (item: ProjectCostProductionOrderLineLaborItem | SerOrderCostProductionOrderLineLaborItem): string =>
  (item.taskCode != null && item.taskCode.length > 0) || (item.taskName != null && item.taskName.length > 0)
    ? `${item.taskName} - ${item.taskName}`
    : "-";

const transformProductionOrderLabor = (
  item: ProjectCostProductionOrderLineLaborItem,
  parentId: string,
  index: number
): ProjectCostProductionTableItem => {
  return {
    id: parentId + item.description + "/" + item.line + index,
    childLevel: 3,
    description: item.description,
    line: item.line,
    item: taskString(item),
    actualAmount: orUndefined(item.actualAmount),
    actualQuantity: orUndefined(item.actualQuantity),
    estimateAmount: orUndefined(item.estimateAmount),
    estimateQuantity: orUndefined(item.estimateQuantity),
    total: orUndefined(item.actualAmount),
    machineQuantity: orUndefined(item.machineQuantity),
    transactionDate: dateOrUndefined(item.transactionDate),
    reportingDate: dateOrUndefined(item.reportingDate),
    executionDate: dateOrUndefined(item.executionDate),
    employee: employeeOrDash(item),
    employeeCostCenter: orUndefined(item.employeeCostCenter),
    laborRateCode: orUndefined(item.laborRateCode),
  };
};

const transformSerOrderProductionOrderLabor = (
  item: SerOrderCostProductionOrderLineLaborItem,
  parentId: string,
  index: number
): ProjectCostProductionTableItem => {
  return {
    id: parentId + item.description + "/" + item.line + index,
    childLevel: 3,
    description: item.description,
    line: item.line,
    item: taskString(item),
    actuals: orUndefined(item.actualAmount),
    actualQuantity: orUndefined(item.actualQuantity),
    estimateAmount: orUndefined(item.estimateAmount),
    estimateQuantity: orUndefined(item.estimateQuantity),
    machineQuantity: orUndefined(item.machineQuantity),
    transactionDate: dateOrUndefined(item.transactionDate),
    reportingDate: dateOrUndefined(item.reportingDate),
    executionDate: dateOrUndefined(item.executionDate),
    employee: employeeOrDash(item),
    employeeCostCenter: orUndefined(item.employeeCostCenter),
    laborRateCode: orUndefined(item.laborRateCode),
  };
};

const transformProductionOrderMaterial = (
  item: ProjectCostProductionOrderLineMaterialItem,
  parentId: string,
  index: number
): ProjectCostProductionTableItem => {
  return {
    id: parentId + item.description + "/" + item.line + index,
    childLevel: 3,
    description: item.description,
    line: item.line,
    item: item.item,
    actualAmount: orUndefined(item.actualAmount),
    actualQuantity: orUndefined(item.actualQuantity),
    estimateAmount: orUndefined(item.estimateAmount),
    estimateQuantity: orUndefined(item.estimateQuantity),
    total: orUndefined(item.actualAmount),
    supplier: orUndefined(item.supplier),
    transactionDate: dateOrUndefined(item.transactionDate),
    invoicePictureURL: orUndefined(item.invoicePictureURL),
    orderPictureURL: orUndefined(item.orderPictureURL),
  };
};

const transformSerOrderProductionOrderMaterial = (
  item: SerOrderCostProductionOrderLineMaterialItem,
  parentId: string,
  index: number
): ProjectCostProductionTableItem => {
  return {
    id: parentId + item.description + "/" + item.line + index,
    childLevel: 3,
    description: item.description,
    line: item.line,
    item: item.item,
    actuals: orUndefined(item.actualAmount),
    actualQuantity: orUndefined(item.actualQuantity),
    estimateAmount: orUndefined(item.estimateAmount),
    estimateQuantity: orUndefined(item.estimateQuantity),
    total: orUndefined(item.actualAmount),
    supplier: orUndefined(item.supplier),
    transactionDate: dateOrUndefined(item.transactionDate),
    invoicePictureURL: orUndefined(item.invoicePictureURL),
    orderPictureURL: orUndefined(item.orderPictureURL),
  };
};

const transformProductionOrderOther = (
  item: ProjectCostProductionOrderLineOtherItem,
  parentId: string,
  index: number
): ProjectCostProductionTableItem => {
  return {
    id: parentId + item.description + "/" + item.line + index,
    childLevel: 3,
    description: item.description,
    line: item.line,
    item: item.item,
    actualAmount: orUndefined(item.actualAmount),
    actualQuantity: orUndefined(item.actualQuantity),
    estimateAmount: orUndefined(item.estimateAmount),
    estimateQuantity: orUndefined(item.estimateQuantity),
    total: orUndefined(item.actualAmount),
    supplier: orUndefined(item.supplier),
    transactionDate: dateOrUndefined(item.transactionDate),
    invoicePictureURL: orUndefined(item.invoicePictureURL),
    orderPictureURL: orUndefined(item.orderPictureURL),
  };
};

const transformSerOrderProductionOrderOther = (
  item: SerOrderCostProductionOrderLineOtherItem,
  parentId: string,
  index: number
): ProjectCostProductionTableItem => {
  return {
    id: parentId + item.description + "/" + item.line + index,
    childLevel: 3,
    description: item.description,
    line: item.line,
    item: item.item,
    actuals: orUndefined(item.actualAmount),
    actualQuantity: orUndefined(item.actualQuantity),
    estimateAmount: orUndefined(item.estimateAmount),
    estimateQuantity: orUndefined(item.estimateQuantity),
    total: orUndefined(item.actualAmount),
    supplier: orUndefined(item.supplier),
    transactionDate: dateOrUndefined(item.transactionDate),
    invoicePictureURL: orUndefined(item.invoicePictureURL),
    orderPictureURL: orUndefined(item.orderPictureURL),
  };
};

const transformProductionOrderPurchase = (
  item: ProjectCostProductionOrderLinePurchaseItem,
  parentId: string,
  index: number
): ProjectCostProductionTableItem => {
  return {
    id: parentId + item.description + "/" + item.line + index,
    childLevel: 3,
    description: item.description,
    line: item.line,
    item: item.item,
    supplier: orUndefined(item.supplier),
    estimateQuantity: orUndefined(item.purchasedQuantity),
    actualQuantity: orUndefined(item.invoicedQuantity),
    actualAmount: orUndefined(item.aggregatedValues.actualAmount),
    estimateAmount: orUndefined(item.aggregatedValues.estimateAmount),
    softCommitments: orUndefined(item.aggregatedValues.softCommitments),
    hardCommitments: orUndefined(item.aggregatedValues.hardCommitments),
    total: orUndefined(item.aggregatedValues.total),
    transactionDate: dateOrUndefined(item.transactionDate),
    invoicePictureURL: orUndefined(item.invoicePictureURL),
    orderPictureURL: orUndefined(item.orderPictureURL),
  };
};

const transformSerOrderProductionOrderPurchase = (
  item: SerOrderCostProductionOrderLinePurchaseItem,
  parentId: string,
  index: number
): ProjectCostProductionTableItem => {
  return {
    id: parentId + item.description + "/" + item.line + index,
    childLevel: 3,
    description: item.description,
    line: item.line,
    item: item.item,
    supplier: orUndefined(item.supplier),
    estimateQuantity: orUndefined(item.purchasedQuantity),
    actualQuantity: orUndefined(item.invoicedQuantity),
    actuals: orUndefined(item.aggregatedValues.actuals),
    poLineAmount: orUndefined(item.aggregatedValues.poLineAmount),
    poLinePrice: orUndefined(item.aggregatedValues.poLinePrice),
    transactionDate: dateOrUndefined(item.transactionDate),
    invoicePictureURL: orUndefined(item.invoicePictureURL),
    orderPictureURL: orUndefined(item.orderPictureURL),
  };
};

const transformProductionOrderSurcharge = (
  item: ProjectCostProductionOrderLineSurchargeItem,
  parentId: string,
  index: number
): ProjectCostProductionTableItem => {
  return {
    id: parentId + item.description + "/" + item.line + index,
    childLevel: 3,
    description: item.description,
    line: item.line,
    item: item.item,
    actualAmount: orUndefined(item.actualAmount),
    actualQuantity: orUndefined(item.actualQuantity),
    estimateAmount: orUndefined(item.estimateAmount),
    estimateQuantity: orUndefined(item.estimateQuantity),
    total: orUndefined(item.actualAmount),
    supplier: orUndefined(item.supplier),
    transactionDate: dateOrUndefined(item.transactionDate),
    invoicePictureURL: orUndefined(item.invoicePictureURL),
    orderPictureURL: orUndefined(item.orderPictureURL),
  };
};

const transformSerOrderProductionOrderSurcharge = (
  item: SerOrderCostProductionOrderLineSurchargeItem,
  parentId: string,
  index: number
): ProjectCostProductionTableItem => {
  return {
    id: parentId + item.description + "/" + item.line + index,
    childLevel: 3,
    description: item.description,
    line: item.line,
    item: item.item,
    actuals: orUndefined(item.actualAmount),
    actualQuantity: orUndefined(item.actualQuantity),
    estimateAmount: orUndefined(item.estimateAmount),
    estimateQuantity: orUndefined(item.estimateQuantity),
    total: orUndefined(item.actualAmount),
    supplier: orUndefined(item.supplier),
    transactionDate: dateOrUndefined(item.transactionDate),
    invoicePictureURL: orUndefined(item.invoicePictureURL),
    orderPictureURL: orUndefined(item.orderPictureURL),
  };
};

const transformItem = (
  item:
    | ProjectCostProductionItem
    | ProjectCostProductionOrderItem
    | ProjectProductionOrderSubTotalLevel
    | ProjectCostProductionOrderLineLaborItem
    | ProjectCostProductionOrderLineMaterialItem
    | ProjectCostProductionOrderLineOtherItem
    | ProjectCostProductionOrderLinePurchaseItem
    | ProjectCostProductionOrderLineSurchargeItem
    | SerOrderCostProductionItem
    | SerOrderCostProductionOrderItem
    | SerOrderProductionOrderSubTotalLevel
    | SerOrderCostProductionOrderLineLaborItem
    | SerOrderCostProductionOrderLineMaterialItem
    | SerOrderCostProductionOrderLineOtherItem
    | SerOrderCostProductionOrderLinePurchaseItem
    | SerOrderCostProductionOrderLineSurchargeItem,
  parentId?: string,
  index?: number
) => {
  switch (item.__typename) {
    case "ProjectCostProductionItem":
      return transformProductionItem(item);
    case "ProjectCostProductionOrderItem":
      return transformProductionOrder(item, parentId || "-");
    case "ProjectProductionOrderSubTotalLevel":
      return transformProductionOrderSubTotal(item, parentId || "-");
    case "ProjectCostProductionOrderLineLaborItem":
      return transformProductionOrderLabor(item, parentId || "-", index || 0);
    case "ProjectCostProductionOrderLineMaterialItem":
      return transformProductionOrderMaterial(item, parentId || "-", index || 0);
    case "ProjectCostProductionOrderLineOtherItem":
      return transformProductionOrderOther(item, parentId || "-", index || 0);
    case "ProjectCostProductionOrderLinePurchaseItem":
      return transformProductionOrderPurchase(item, parentId || "-", index || 0);
    case "ProjectCostProductionOrderLineSurchargeItem":
      return transformProductionOrderSurcharge(item, parentId || "-", index || 0);
    case "SerOrderCostProductionItem":
      return transformSerOrderProductionItem(item);
    case "SerOrderCostProductionOrderItem":
      return transformSerOrderProductionOrder(item, parentId || "-");
    case "SerOrderProductionOrderSubTotalLevel":
      return transformSerOrderProductionOrderSubTotal(item, parentId || "-");
    case "SerOrderCostProductionOrderLineLaborItem":
      return transformSerOrderProductionOrderLabor(
        item as SerOrderCostProductionOrderLineLaborItem,
        parentId || "-",
        index || 0
      );
    case "SerOrderCostProductionOrderLineMaterialItem":
      return transformSerOrderProductionOrderMaterial(item, parentId || "-", index || 0);
    case "SerOrderCostProductionOrderLineSurchargeItem":
      return transformSerOrderProductionOrderSurcharge(item, parentId || "-", index || 0);
    case "SerOrderCostProductionOrderLineOtherItem":
      return transformSerOrderProductionOrderOther(item, parentId || "-", index || 0);
    case "SerOrderCostProductionOrderLinePurchaseItem":
      return transformSerOrderProductionOrderPurchase(
        item as SerOrderCostProductionOrderLinePurchaseItem,
        parentId || "-",
        index || 0
      );
  }
};

const getChildrenItems = (
  item: ProjectCostProductionTableItem,
  list: ProjectCostProductionTableItem[],
  parent?: boolean
) => {
  if (!parent) {
    list.push(item);
  }

  if (item.childItems && item.childItems.length > 0) {
    item.childItems.forEach((childItem: TableChildItemType, index: number) =>
      getChildrenItems(transformItem(childItem, item.id, index), list)
    );
  }
};

const setInitialChildrenVisible = (
  items: ProjectCostProductionTableItem[],
  setRenderedRows: React.Dispatch<React.SetStateAction<ProjectCostProductionTableItem[]>>,
  setOpenProductionItems: React.Dispatch<React.SetStateAction<string[]>>
) => {
  const renderedRows: ProjectCostProductionTableItem[] = [];
  const newOpenProductionItems: string[] = [];
  items.forEach(item => {
    renderedRows.push(item);
    newOpenProductionItems.push(item.id);
    if (item.childItems) {
      item.childItems.forEach((childItem: TableChildItemType, index: number) => {
        renderedRows.push(transformItem(childItem, item.id, index));
      });
    }
  });
  setRenderedRows(renderedRows);
  setOpenProductionItems(newOpenProductionItems);
};

const closeProductionItemHierarchy = (
  productionItem: ProjectCostProductionTableItem,
  setRenderedRows: React.Dispatch<React.SetStateAction<ProjectCostProductionTableItem[]>>,
  renderedRows: ProjectCostProductionTableItem[],
  openProductionItems: string[],
  setOpenProductionItems: React.Dispatch<React.SetStateAction<string[]>>
) => {
  const childItems: ProjectCostProductionTableItem[] = [];
  getChildrenItems(productionItem, childItems, true);

  const newItems = cloneDeep(renderedRows);
  remove(newItems, openItem => childItems.map(item => item.id).includes(openItem.id));
  setRenderedRows(newItems);

  childItems.push(productionItem);
  const newOpenProductionItems = cloneDeep(openProductionItems);
  remove(newOpenProductionItems, openItem => childItems.map(item => item.id).includes(openItem));
  setOpenProductionItems(newOpenProductionItems);
};

const openProductionItemHierarchy = (
  productionItem: ProjectCostProductionTableItem,
  setRenderedRows: React.Dispatch<React.SetStateAction<ProjectCostProductionTableItem[]>>,
  renderedRows: ProjectCostProductionTableItem[],
  openProductionItems: string[],
  setOpenProductionItems: React.Dispatch<React.SetStateAction<string[]>>,
  index: number
) => {
  const newItems = cloneDeep(renderedRows);
  const newOpenProductionItems = cloneDeep(openProductionItems);
  if (productionItem.childItems) {
    productionItem.childItems.forEach((childItem: TableChildItemType, i: number) => {
      const transformedItem = transformItem(childItem, productionItem.id, i);
      newItems.splice(index + 1 + i, 0, transformedItem);
    });
    newOpenProductionItems.push(productionItem.id);
  }

  setOpenProductionItems(newOpenProductionItems);
  setRenderedRows(newItems);
};

const expandAll = (
  openProductionItems: string[],
  setOpenProductionItems: React.Dispatch<React.SetStateAction<string[]>>,
  item: ProjectCostProductionTableItem,
  setRenderedRows: React.Dispatch<React.SetStateAction<ProjectCostProductionTableItem[]>>,
  renderedRows: ProjectCostProductionTableItem[],
  index: number
) => {
  const newProductions = cloneDeep(renderedRows);
  const newOpenProductions = cloneDeep(openProductionItems);

  const childItems: ProjectCostProductionTableItem[] = [];
  getChildrenItems(item, childItems, true);
  remove(newProductions, production => childItems.map(item => item.id).includes(production.id));

  childItems.push(item);
  remove(newOpenProductions, production => childItems.map(item => item.id).includes(production));
  childItems.pop();

  newOpenProductions.push(item.id);
  childItems.forEach(childItem => {
    if (childItem.childItems) {
      newOpenProductions.push(childItem.id);
    }
  });

  newProductions.splice(index + 1, 0, ...childItems);

  setOpenProductionItems(newOpenProductions);
  setRenderedRows(newProductions);
};

function ProductionTable(props: ProductionTableProps): React.ReactElement {
  const { productionItems, projectVirtualType, popupWindow } = props;
  const [openProductionItems, setOpenProductionItems] = useState<string[]>([]);
  const [renderedRows, setRenderedRows] = useState<ProjectCostProductionTableItem[]>([]);

  useEffect(() => {
    if (productionItems) {
      setInitialChildrenVisible(
        productionItems.map(item => transformItem(item)),
        setRenderedRows,
        setOpenProductionItems
      );
    }
  }, [productionItems]);

  const columns =
    projectVirtualType === ProjectVirtualType.LnServiceOrder
      ? PROJECT_COST_PRODUCTION_COLUMNS_SER_ORDER
      : PROJECT_COST_PRODUCTION_COLUMNS;

  return (
    <DetailsTable>
      <TableBody>
        <HeaderRow>
          <HeaderCell right={false} popupWindow={popupWindow}>
            Description
          </HeaderCell>
          {columns.map((column, i) => (
            <HeaderCell key={i} right={justifyToRight(column)} title={column.tooltip} popupWindow={popupWindow}>
              {column.description}
            </HeaderCell>
          ))}
        </HeaderRow>
        {renderedRows.map((item, index) => (
          <DataRow
            key={item.id}
            childLevel={item.childLevel}
            extraPadding={!item.childItems || item.childItems.length === 0}
          >
            <DataCell>
              <DataContainer>
                {item.childItems && item.childItems.length > 0 && (
                  <ContextMenuTrigger id={`${item.id}-context-menu`}>
                    <CollapseButton
                      onClick={e => {
                        e.stopPropagation();
                        if (item.childItems && item.childItems.length > 0) {
                          if (openProductionItems.includes(item.id)) {
                            closeProductionItemHierarchy(
                              item,
                              setRenderedRows,
                              renderedRows,
                              openProductionItems,
                              setOpenProductionItems
                            );
                          } else {
                            openProductionItemHierarchy(
                              item,
                              setRenderedRows,
                              renderedRows,
                              openProductionItems,
                              setOpenProductionItems,
                              index
                            );
                          }
                        }
                      }}
                    >
                      <FontAwesomeIcon
                        icon={openProductionItems.includes(item.id) ? faChevronUp : faChevronDown}
                        size="1x"
                        color={valmetGreyLight}
                      />
                    </CollapseButton>
                  </ContextMenuTrigger>
                )}
                {item.description}
                <ContextMenu id={`${item.id}-context-menu`}>
                  <MenuItem
                    onClick={() =>
                      expandAll(openProductionItems, setOpenProductionItems, item, setRenderedRows, renderedRows, index)
                    }
                  >
                    Expand all
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      closeProductionItemHierarchy(
                        item,
                        setRenderedRows,
                        renderedRows,
                        openProductionItems,
                        setOpenProductionItems
                      )
                    }
                  >
                    Collapse all
                  </MenuItem>
                </ContextMenu>
              </DataContainer>
            </DataCell>
            {columns.map((column, i) => (
              <TypedDataCell key={item.id + "_" + i} column={column} item={item} />
            ))}
          </DataRow>
        ))}
      </TableBody>
    </DetailsTable>
  );
}

export default ProductionTable;
