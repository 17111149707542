import React from "react";
import styled from "styled-components";

import { ProjectRelationTypeItem } from "../../../../common/types";
import EditDetails from "../ProjectBasics/RelationsSection/EditDetails";
import { useQuery } from "@apollo/client/react/hooks";
import { GET_RELATION_TYPES } from "../ProjectBasics/RelationsSection/queries";

function RelationsSection(): React.ReactElement {
  type ProjectRelationTypesResult = { relationTypes: ProjectRelationTypeItem[] };
  const { data: relationTypeItemsResult, loading } = useQuery<ProjectRelationTypesResult>(GET_RELATION_TYPES, {
    variables: {},
    fetchPolicy: "cache-first",
  });
  const relationTypeItems = (relationTypeItemsResult && relationTypeItemsResult.relationTypes) || [];

  return (
    <ContentSection>
      <SectionTitle>Add relations:</SectionTitle>
      <EditDetails relations={[]} relationTypeItems={relationTypeItems} />
      {loading && <LoadingSection>Loading relations...</LoadingSection>}
    </ContentSection>
  );
}

export default RelationsSection;

const ContentSection = styled.div`
  margin-top: 20px;
`;

const SectionTitle = styled.div`
  font-size: 14px;
  margin-bottom: 4px;
`;

const LoadingSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
