import { ActionButton } from "../../../../common/components";
import React from "react";
import styled from "styled-components";
import { settingGreen } from "../../../../common/colors";
import { FullscreenWrapper, Container, ContentContainer, SectionTitle, TitleContainer, ContentSection } from "./utils";

export interface SuccessDialogProps {
  onClickSuccess: () => void;
}

function SuccessDialog(props: SuccessDialogProps): React.ReactElement {
  const { onClickSuccess } = props;

  return (
    <FullscreenWrapper>
      <Container>
        <ContentContainer>
          <TitleContainer>Success</TitleContainer>
          <ContentSection>
            <SectionTitle>Project with the inputted data has been created.</SectionTitle>
            <SuccessButtonContainer>
              <ActionButton
                onClick={() => {
                  onClickSuccess();
                }}
              >
                Show project
              </ActionButton>
            </SuccessButtonContainer>
          </ContentSection>
        </ContentContainer>
      </Container>
    </FullscreenWrapper>
  );
}

export default SuccessDialog;

const SuccessButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  button {
    font-size: 16px;
    font-weight: bold;
  }
  > button:first-child {
    color: ${settingGreen};
  }
`;
