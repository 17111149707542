import { EntityTypeId, NodeId, ProjectId, ProjectPhaseId, ProjectStatusId, ProjectVirtualType } from "../common/types";

export type SetProjectHeaderInfo = {
  type: "SetProjectHeaderInfo";
  projectId: ProjectId;
  projectTechnicalTypeId: EntityTypeId;
  description: string;
  projectPhaseId: ProjectPhaseId;
  projectStatusId: ProjectStatusId;
  projectVirtualType: ProjectVirtualType;
  legalEntityCurrency: string | undefined;
};

export type SetNodeHeaderInfo = {
  type: "SetNodeHeaderInfo";
  nodeId: NodeId;
  description: string;
  mainProjectId: ProjectId | null;
};

export type ResetHeaderInfo = {
  type: "ResetHeaderInfo";
};

export type ProjectHeaderAction = SetProjectHeaderInfo | SetNodeHeaderInfo | ResetHeaderInfo;

export const setProjectHeaderInfo = (
  projectId: ProjectId,
  projectTechnicalTypeId: EntityTypeId,
  description: string,
  projectPhaseId: ProjectPhaseId,
  projectStatusId: ProjectStatusId,
  projectVirtualType: ProjectVirtualType,
  legalEntityCurrency: string | undefined
): SetProjectHeaderInfo => ({
  type: "SetProjectHeaderInfo",
  projectId,
  projectTechnicalTypeId,
  description,
  projectPhaseId,
  projectStatusId,
  projectVirtualType,
  legalEntityCurrency,
});

export const setNodeHeaderInfo = (
  nodeId: NodeId,
  description: string,
  mainProjectId: ProjectId | null
): SetNodeHeaderInfo => ({
  type: "SetNodeHeaderInfo",
  nodeId,
  description,
  mainProjectId,
});

export const resetHeaderInfo: ResetHeaderInfo = { type: "ResetHeaderInfo" };
