import { find } from "lodash";
import { CostEditValues, ProjectCostColumnId, ProjectCostItem, ProjectCostValues } from "../../../../common/types";

export const updateChildren = (
  items: ProjectCostItem[],
  updateItems: ProjectCostItem[],
  valueName: ProjectCostColumnId,
  optionalUpdateItems?: ProjectCostItem[]
) => {
  items.forEach(item => {
    if (valueName === "estimateChange") {
      if (optionalUpdateItems) {
        const curUpdateItem = find(updateItems, updateItem => updateItem.id === item.id);
        const prevUpdateItem = find(optionalUpdateItems, updateItem => updateItem.id === item.id);
        if (curUpdateItem && prevUpdateItem) {
          item.values[valueName] =
            curUpdateItem.values["currentEstimate"] !== undefined &&
            curUpdateItem.values["currentEstimate"] !== null &&
            prevUpdateItem.values["currentEstimate"] !== undefined &&
            prevUpdateItem.values["currentEstimate"] !== null
              ? curUpdateItem.values["currentEstimate"] - prevUpdateItem.values["currentEstimate"]
              : null;
          if (item.childItems && curUpdateItem.childItems && prevUpdateItem.childItems)
            updateChildren(item.childItems, curUpdateItem.childItems, valueName, prevUpdateItem.childItems);
        }
      }
    } else if (valueName === "ETC") {
      if (optionalUpdateItems) {
        const curUpdateItem = find(updateItems, updateItem => updateItem.id === item.id);
        const baseUpdateItem = find(optionalUpdateItems, updateItem => updateItem.id === item.id);
        if (curUpdateItem && baseUpdateItem) {
          const currentEstimate = curUpdateItem.values["currentEstimate"] || 0;
          const committed = baseUpdateItem.values["committed"] || 0;
          item.values[valueName] = currentEstimate - committed;
          if (item.childItems && curUpdateItem.childItems && baseUpdateItem.childItems)
            updateChildren(item.childItems, curUpdateItem.childItems, valueName, baseUpdateItem.childItems);
        }
      }
    } else if (valueName === "currentEstimate") {
      const updateItem = find(updateItems, updateItem => updateItem.id === item.id);
      if (updateItem) item.values.currentEstimate = updateItem.values.currentEstimate;
      const defaultPrevItem = optionalUpdateItems
        ? find(optionalUpdateItems, updateitem => updateitem.id === item.id)
        : undefined;
      if (defaultPrevItem) item.values.defaultPrevEstimate = defaultPrevItem.values.currentEstimate;
      if (item.childItems && updateItem && updateItem.childItems)
        updateChildren(
          item.childItems,
          updateItem.childItems,
          valueName,
          defaultPrevItem && defaultPrevItem.childItems
        );
    } else if (valueName === "periodicWipCosts") {
      if (optionalUpdateItems) {
        const curUpdateItem = find(updateItems, updateItem => updateItem.id === item.id);
        const baseUpdateItem = find(optionalUpdateItems, updateItem => updateItem.id === item.id);
        if (curUpdateItem && baseUpdateItem) {
          const currentWipCosts = curUpdateItem.values.wipCostsCumulative || 0;
          const prevWipCosts = baseUpdateItem.values.wipCostsCumulative || 0;
          item.values.periodicWipCosts = currentWipCosts - prevWipCosts;
          if (item.childItems && curUpdateItem.childItems && baseUpdateItem.childItems)
            updateChildren(item.childItems, curUpdateItem.childItems, valueName, baseUpdateItem.childItems);
        }
      }
    } else {
      const updateItem = find(updateItems, updateItem => updateItem.id === item.id);
      if (updateItem) {
        const updateValue = valueName === "prevEstimate" ? "currentEstimate" : valueName;
        item.values[valueName] = updateItem.values[updateValue];
        if (item.childItems && updateItem.childItems)
          updateChildren(item.childItems, updateItem.childItems, valueName, optionalUpdateItems);
      }
    }
  });
};

export const clearItemActuals = (projectItems: ProjectCostItem[]) => {
  projectItems.forEach(item => {
    item.values.actuals = null;
    item.values.actualHours = null;
    item.values.committed = null;
    if (item.childItems) {
      clearItemActuals(item.childItems);
    }
  });
};

const isValueAvailable = (value: number | null) => {
  return value !== null && value !== 0;
};

export const isValuesAvailable = (values: ProjectCostValues, summaryValues?: CostEditValues) => {
  return (
    isValueAvailable(summaryValues ? summaryValues.asSold : values.asSold) ||
    isValueAvailable(summaryValues ? summaryValues.originalBudget : values.originalBudget) ||
    isValueAvailable(values.proposedBudgetChange) ||
    isValueAvailable(summaryValues ? summaryValues.revisedBudget : values.revisedBudget) ||
    isValueAvailable(summaryValues ? summaryValues.currentEstimate : values.currentEstimate) ||
    isValueAvailable(values.committed) ||
    isValueAvailable(values.actuals) ||
    isValueAvailable(summaryValues ? summaryValues.estimatedHours : values.estimatedHours) ||
    isValueAvailable(values.actualHours)
  );
};
