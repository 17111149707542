import * as React from "react";
import onClickOutside, { HandleClickOutside, InjectedOnClickOutProps } from "react-onclickoutside";
import styled from "styled-components";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { valmetGreyBorder, filterGreen } from "../../common/colors";
import { Currency } from "../../common/types";
import { IconButton, ValueButton } from "../../common/components";

type Props = {
  selections: Currency[];
  value?: Currency;
  onValueSelected: (currency: Currency) => void;
  disabled: boolean;
};

type State = {
  isOpen: boolean;
};

class CurrencyDropdown extends React.Component<
  Props & InjectedOnClickOutProps & HandleClickOutside<React.MouseEventHandler>,
  State
> {
  state: State = { isOpen: false };

  toggle = (): void => {
    if (this.state.isOpen) {
      this.props.disableOnClickOutside();
    } else {
      this.props.enableOnClickOutside();
    }

    this.setState(state => ({ isOpen: !state.isOpen }));
  };

  handleClickOutside = (): void => {
    this.props.disableOnClickOutside();
    this.setState({ isOpen: false });
  };

  render(): React.ReactElement {
    const { selections, value, onValueSelected, disabled } = this.props;
    return (
      <Wrapper>
        <ContentContainer
          onClick={() => {
            !disabled && this.toggle();
          }}
          disabled={disabled}
        >
          <CurrencyTextContainer>{value ? value.description : "-"}</CurrencyTextContainer>
          <IconButton title={"Select currency"} fontSize={"16px"} disabled={disabled}>
            <FontAwesomeIcon icon={faChevronDown} size="1x" color={"white"} />
          </IconButton>
        </ContentContainer>
        {this.state.isOpen && (
          <DropdownContent onClick={this.handleClickOutside} hasContent={selections.length > 0}>
            {selections.map(selection => (
              <ValueButton key={selection.id} onClick={() => onValueSelected(selection)}>
                {`${selection.description}`}
              </ValueButton>
            ))}
          </DropdownContent>
        )}
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ContentContainer = styled.div<{ disabled: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: ${({ disabled }) => {
    return disabled ? `not-allowed` : `context-menu`;
  }};
`;

const CurrencyTextContainer = styled.div`
  text-align: right;
  /*width: 275px;*/
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const DropdownContent = styled.div<{ hasContent: boolean }>`
  background: white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  position: absolute;
  display: flex;
  flex-direction: column;
  min-width: 200px;
  ${({ hasContent }) => {
    if (hasContent) {
      return `border: 1px solid ${valmetGreyBorder}`;
    }
  }};
  z-index: 500;
  button:hover {
    background: ${filterGreen};
  }
  margin-top: 26px;
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default onClickOutside<any, Props>(CurrencyDropdown);
