import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { faCrown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Period, periodToString } from "../../../../common/period";
import { CommentPackageType } from "./types";
import { commentTextBackgroundBlue, defaultGrey, editBlue, settingGreen } from "../../../../common/colors";
import { formatTimestamp } from "./utils";
import { ActionButton } from "../../../../common/components";

export const Crown = (props: { color?: string }) => {
  const { color } = props;
  return (
    <Icon>
      <FontAwesomeIcon icon={faCrown} color={color} />
    </Icon>
  );
};

export const Icon = styled.div`
  padding: 0 1px;
  width: 20px;
  margin-right: 5px;
`;

export const CommentHeader = ({ period, commentType }: { period: Period; commentType: CommentPackageType }) => {
  return (
    <Header>
      <HeaderPeriod>{periodToString(period)}</HeaderPeriod>
      {commentType === CommentPackageType.Total && <Crown color={settingGreen} />}
      {commentType} financial comment
    </Header>
  );
};

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: center;
  font-size: 18px;
  padding: 5px;
`;
const HeaderPeriod = styled.div`
  margin-right: 10px;
  font-weight: bold;
`;

export function CommentPackageInfo({
  modifiedBy,
  modified,
  published,
}: {
  modifiedBy: string;
  modified: string;
  published: string | null;
}) {
  return (
    <CommentPackageInfoContainer>
      <CommentPackageStatus color={published ? settingGreen : editBlue}>
        {published ? "Published" : "Saved"}
      </CommentPackageStatus>
      <MetaInfoContainer>
        <CommentPackageMetaInfo>
          {published ? formatTimestamp(published) : formatTimestamp(modified)}
        </CommentPackageMetaInfo>
        <CommentPackageMetaInfo>{modifiedBy}</CommentPackageMetaInfo>
      </MetaInfoContainer>
    </CommentPackageInfoContainer>
  );
}

const MetaInfoContainer = styled.div`
  min-width: 200px;
`;

const CommentPackageInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const CommentPackageStatus = styled.div<{ color: string }>`
  display: flex;
  flex-direction: row;
  font-weight: bold;
  color: ${p => p.color};
`;

const CommentPackageMetaInfo = styled.div`
  display: flex;
  flex-direction: row;
  font-weight: normal;
  font-size: 10px;
  color: ${defaultGrey};
  margin-left: 1em;
`;

export function UsableComment(props: React.PropsWithChildren<{ used: boolean; usedHidden: boolean; use: () => void }>) {
  const { usedHidden, used, use, children } = props;
  const [height, setHeight] = useState(0);
  const containerRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    const ref = containerRef.current;
    if (!ref) return;

    setHeight(ref.clientHeight);
  }, [containerRef.current]);
  return (
    <CommentWrapper show={!usedHidden || !used} height={height}>
      <CommentContainer used={used} ref={containerRef}>
        {children}
        <CommentFooter>
          <ActionButton onClick={use}>Use comment</ActionButton>
          {used && "(USED)"}
        </CommentFooter>
      </CommentContainer>
    </CommentWrapper>
  );
}

const CommentWrapper = styled.div<{ show: boolean; height: number }>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  max-height: ${({ show, height }) => (show === false ? 0 : height)}px;
  transition: 0.25s linear;
  overflow: hidden;
`;

const CommentContainer = styled.div<{ used: boolean }>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background: ${commentTextBackgroundBlue};
  color: ${defaultGrey};
  ${p => (p.used ? `opacity: 0.6` : "")};
  border-radius: 2px;
  font-size: 10px;
  padding: 4px 10px;
  width: calc(400px - 20px);
`;

const CommentFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 10px;
  white-space: normal;
  font-weight: normal;
  margin-top: 12px;
`;
