import React, { useCallback, useEffect, useState } from "react";
import { Action } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { useMutation } from "@apollo/client/react/hooks";

import Section from "../Section";
import {
  AppState,
  ProjectTagsSectionInput,
  ProjectId,
  ProjectTag,
  ProjectTagsSaveResult,
} from "../../../../../common/types";
import { setProjectTags } from "../../../../../actions/projectActions";
import { BasicDataSectionType, getEmptyProjectTagsSectionInput } from "../../../../../common/constants";
import { SAVE_PROJECT_TAGS } from "./queries";
import { pollForBasicDataSaveReady } from "../../queries";
import ViewDetails from "./ViewDetails";
import EditDetails from "./EditDetails";
import styled from "styled-components";
import { defaultGrey } from "../../../../../common/colors";

const mapStateToProps = (state: AppState) => {
  return {
    projectTagsInput: state.projectState.projectInput
      ? state.projectState.projectInput.projectTags
      : getEmptyProjectTagsSectionInput(),
  };
};
const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, void, Action>) => {
  return {
    setProjectTagsInput: (input: ProjectTagsSectionInput) => dispatch(setProjectTags(input)),
  };
};

interface TagsSectionProps {
  projectId: ProjectId;
  sectionDetails: ProjectTag[] | undefined;
  sectionEditable: boolean;
  editSectionType: BasicDataSectionType | undefined;
  setEditSectionType: (type: BasicDataSectionType | undefined) => void;
  refetchDetails: () => void;
}

const TYPE: BasicDataSectionType = BasicDataSectionType.Tags;

function TagsSection(
  props: TagsSectionProps & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
) {
  const {
    projectId,
    sectionDetails,
    sectionEditable,
    editSectionType,
    setEditSectionType,
    refetchDetails,
    projectTagsInput,
    setProjectTagsInput,
  } = props;
  const [editMode, setEditMode] = useState(false);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  // Once input is changed, clear error messages.
  useEffect(() => {
    if (errorMessages.length > 0) {
      setErrorMessages([]);
    }
  }, [projectTagsInput]);

  const [saveMutation, { loading: saving }] = useMutation<{
    saveProjectTags: ProjectTagsSaveResult;
  }>(SAVE_PROJECT_TAGS);

  const { pollForReady, ready, loading: polling, error: pollingError } = pollForBasicDataSaveReady();

  useEffect(() => {
    if (ready && !polling && !pollingError) {
      setEditMode(false);
      setEditSectionType(undefined);
      setProjectTagsInput(getEmptyProjectTagsSectionInput());
      refetchDetails();
    } else if (!polling && pollingError) {
      setErrorMessages(errorMessages.concat([pollingError]));
    }
  }, [ready, polling, pollingError]);

  const save = useCallback(
    (input: ProjectTag[]) => {
      saveMutation({
        variables: {
          projectId: projectId,
          tags: { tags: input.map(tag => tag.id) },
        },
      })
        .then(({ data }) => {
          if (data && data.saveProjectTags && data.saveProjectTags.applicationModifiedDateTime) {
            pollForReady(projectId, data.saveProjectTags.applicationModifiedDateTime);
          } else if (data && data.saveProjectTags.errors) {
            setErrorMessages(errorMessages.concat(data.saveProjectTags.errors));
          }
        })
        .catch(apolloError => {
          setErrorMessages(errorMessages.concat([apolloError.message]));
        });
    },
    [saveMutation]
  );

  const disabled = editSectionType !== undefined && editSectionType !== TYPE;

  return (
    <Section
      header="Tags"
      disabled={disabled}
      editMode={editMode}
      editable={sectionEditable && sectionDetails !== undefined}
      saving={saving || polling}
      saveEnabled={!projectTagsInput.pristine}
      errors={errorMessages.length > 0 ? errorMessages : undefined}
      onEditClicked={() => {
        setEditSectionType(TYPE);
        setEditMode(true);
      }}
      onCancelEdit={() => {
        setEditSectionType(undefined);
        setEditMode(false);
        setProjectTagsInput(getEmptyProjectTagsSectionInput());
      }}
      onSaveClicked={() => {
        save(projectTagsInput.input);
      }}
    >
      <Container>
        {!sectionDetails ? undefined : editMode ? (
          <EditDetails projectId={projectId} tags={sectionDetails} />
        ) : (
          <ViewDetails tags={sectionDetails} />
        )}
      </Container>
    </Section>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(TagsSection);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-bottom: 0px;
  color: ${defaultGrey};
`;
