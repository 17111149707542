import React from "react";
import { ProjectRelation, ProjectRelationTypeItem } from "../../../../../common/types";
import CreateProjectSection from "../CreateProjectSection";
import styled from "styled-components";
import { defaultGrey } from "../../../../../common/colors";
import { useQuery } from "@apollo/client/react/hooks";
import { GET_RELATION_TYPES } from "./queries";
import ViewDetails from "./ViewDetails";

export interface RelationsCreateProjectSectionProps {
  sectionDetails: ProjectRelation[];
}

function RelationsCreateProjectSection(props: RelationsCreateProjectSectionProps): React.ReactElement {
  const { sectionDetails } = props;

  type ProjectRelationTypesResult = { relationTypes: ProjectRelationTypeItem[] };
  const { data: relationTypeItemsResult } = useQuery<ProjectRelationTypesResult>(GET_RELATION_TYPES, {
    variables: {},
    fetchPolicy: "cache-first",
  });
  const relationTypeItems = (relationTypeItemsResult && relationTypeItemsResult.relationTypes) || [];

  // VPOP-990: In project creation, relations should be read-only in basics tab.
  return (
    <CreateProjectSection header="Relations">
      <Container>
        <ViewDetails relations={sectionDetails} relationTypeItems={relationTypeItems} />
      </Container>
    </CreateProjectSection>
  );
}

export default RelationsCreateProjectSection;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-bottom: 0px;
  color: ${defaultGrey};
  > div:first-child {
    border-top: 0;
    padding-top: 0;
  }
  > div:last-child {
    margin-bottom: 10px;
  }
`;
