import React, { useCallback, useEffect, useMemo } from "react";
import {
  AppState,
  PersonsResult,
  ProjectId,
  ProjectRoles,
  ProjectRolesEditInformation,
  ProjectRolesInput,
  ProjectRolesSectionInput,
  ProjectRolesVisibility,
} from "../../../../../common/types";
import { connect } from "react-redux";
import styled from "styled-components";
import { defaultGrey } from "../../../../../common/colors";
import { ThunkDispatch } from "redux-thunk";
import { Action } from "redux";
import { setProjectError, setProjectRoles } from "../../../../../actions/projectActions";
import { useQuery } from "@apollo/client/react/hooks";
import { GET_PERSONS, GET_PROJECT_ROLES_EDIT_INFORMATION } from "./queries";
import { parseNumberOrNull } from "../../../../../common/utils";
import LoadingView from "../../../../LoadingView";
import EditableSearchableDropdown from "../EditableComponents/EditableSearchableDropdown";
import { getDataItem } from "./ViewDetails";

export interface EditDetailsProps {
  projectId: ProjectId;
  roles: ProjectRoles;
  rolesVisibility: ProjectRolesVisibility;
}

const mapStateToProps = (state: AppState) => {
  return {
    sectionInput: state.projectState.projectInput ? state.projectState.projectInput.projectRoles : undefined,
    editInformation: state.projectState.projectDetailsEditInformation
      ? state.projectState.projectDetailsEditInformation.projectRoles
      : undefined,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, void, Action>) => {
  return {
    setProjectRolesInput: (input: ProjectRolesSectionInput) => dispatch(setProjectRoles(input)),
    setProjectError: (value: boolean) => {
      dispatch(setProjectError(value, "roles"));
    },
  };
};

const checkInputErrors = (
  sectionInput: ProjectRolesInput | undefined,
  editInformation: ProjectRolesEditInformation | undefined
) => {
  if (sectionInput && editInformation) {
    const errors = [];
    if (
      sectionInput.mainProjectControllerId === null &&
      editInformation.mainProjectControllerProperties.nullable !== null &&
      !editInformation.mainProjectControllerProperties.nullable
    ) {
      errors.push("mainProjectController");
    }
    if (
      sectionInput.mainProjectManagerId === null &&
      editInformation.mainProjectManagerProperties.nullable !== null &&
      !editInformation.mainProjectManagerProperties.nullable
    ) {
      errors.push("mainProjectManager");
    }
    if (
      sectionInput.projectControllerId === null &&
      editInformation.projectControllerProperties.nullable !== null &&
      !editInformation.projectControllerProperties.nullable
    ) {
      errors.push("projectController");
    }
    if (
      sectionInput.projectManagerId === null &&
      editInformation.projectManagerProperties.nullable !== null &&
      !editInformation.projectManagerProperties.nullable
    ) {
      errors.push("projectManager");
    }
    if (
      sectionInput.projectManagersManagerId === null &&
      editInformation.projectManagersManagerProperties.nullable !== null &&
      !editInformation.projectManagersManagerProperties.nullable
    ) {
      errors.push("projectManagersManager");
    }
    if (
      sectionInput.projectCoordinatorId === null &&
      editInformation.projectCoordinatorProperties.nullable !== null &&
      !editInformation.projectCoordinatorProperties.nullable
    ) {
      errors.push("projectCoordinator");
    }

    return errors;
  } else {
    return [];
  }
};

function EditDetails(
  props: EditDetailsProps & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
): React.ReactElement {
  const {
    projectId,
    roles,
    rolesVisibility,
    sectionInput,
    editInformation,
    setProjectRolesInput: setSectionInput,
    setProjectError,
  } = props;
  const rolesInput = sectionInput ? sectionInput.input : undefined;
  const { data: editInformationData, error: editInformationError, loading: editInformationLoading } = useQuery(
    GET_PROJECT_ROLES_EDIT_INFORMATION,
    {
      variables: { projectId: projectId },
      skip: editInformation !== undefined,
    }
  );

  const editInfo = editInformation
    ? editInformation
    : !editInformationLoading && !editInformationError && editInformationData
    ? editInformationData.projectDetailsEditInformation.projectRoles
    : undefined;

  const { loading: personsLoading, data: personsData, error: personsError } = useQuery<PersonsResult>(GET_PERSONS, {});

  const onEditInformationDataChange = useCallback(() => {
    if (editInfo) {
      console.log("rolesInput ", rolesInput, "roles ", roles.projectCoordinator);
      setSectionInput({
        input: {
          mainProjectManagerId:
            rolesInput && rolesInput.mainProjectManagerId !== null && editInfo.mainProjectManagerProperties.editable
              ? rolesInput.mainProjectManagerId
              : roles.mainProjectManager !== null && editInfo.mainProjectManagerProperties.editable
              ? roles.mainProjectManager.userId
              : null,
          mainProjectControllerId:
            rolesInput &&
            rolesInput.mainProjectControllerId !== null &&
            editInfo.mainProjectControllerProperties.editable
              ? rolesInput.mainProjectControllerId
              : roles.mainProjectController !== null && editInfo.mainProjectControllerProperties.editable
              ? roles.mainProjectController.userId
              : null,
          projectControllerId:
            rolesInput && rolesInput.projectControllerId !== null && editInfo.projectControllerProperties.editable
              ? rolesInput.projectControllerId
              : roles.projectController !== null && editInfo.projectControllerProperties.editable
              ? roles.projectController.userId
              : null,
          projectManagerId:
            rolesInput && rolesInput.projectManagerId !== null && editInfo.projectManagerProperties.editable
              ? rolesInput.projectManagerId
              : roles.projectManager !== null && editInfo.projectManagerProperties.editable
              ? roles.projectManager.userId
              : null,
          projectManagersManagerId:
            rolesInput &&
            rolesInput.projectManagersManagerId !== null &&
            editInfo.projectManagersManagerProperties.editable
              ? rolesInput.projectManagersManagerId
              : roles.projectManagersManager !== null && editInfo.projectManagersManagerProperties.editable
              ? roles.projectManagersManager.userId
              : null,
          projectCoordinatorId:
            rolesInput && rolesInput.projectCoordinatorId !== null && editInfo.projectCoordinatorProperties.editable
              ? rolesInput.projectCoordinatorId
              : roles.projectCoordinator !== null && editInfo.projectCoordinatorProperties.editable
              ? roles.projectCoordinator.userId
              : null,
        },
        pristine: true,
      });
    }
  }, [editInfo, setSectionInput, rolesInput, roles]);

  useEffect(onEditInformationDataChange, [editInfo, roles]);

  const loading = editInformationLoading || personsLoading;
  const error = editInformationError || personsError;

  const inputErrors = useMemo(() => checkInputErrors(rolesInput, editInfo), [rolesInput, editInfo]);
  useEffect(() => {
    setProjectError(inputErrors.length > 0);
  }, [inputErrors]);

  return loading || !sectionInput ? (
    <LoadingView />
  ) : !error && editInfo && rolesInput && personsData ? (
    <RoleSection>
      <InformationItem>
        {rolesVisibility.mainProjectManager && (
          <>
            <TitleItem>Main project manager:</TitleItem>
            {editInfo.mainProjectManagerProperties.editable ? (
              <EditableSearchableDropdown
                value={rolesInput.mainProjectManagerId !== null ? rolesInput.mainProjectManagerId : ""}
                onValueChanged={value =>
                  setSectionInput({
                    input: { ...rolesInput, mainProjectManagerId: parseNumberOrNull(value) },
                    pristine: false,
                  })
                }
                options={personsData.persons.map(person => {
                  return { id: person.userId, description: `${person.firstName} ${person.lastName}` };
                })}
                searchable={true}
                disabled={!editInfo.mainProjectManagerProperties.editable}
                error={inputErrors.includes("mainProjectManager")}
                maxResults={20}
              />
            ) : (
              getDataItem("mainProjectManager", roles)
            )}
          </>
        )}
      </InformationItem>
      <InformationItem>
        {rolesVisibility.mainProjectController && (
          <>
            <TitleItem>Main project controller:</TitleItem>
            {editInfo.mainProjectControllerProperties.editable ? (
              <EditableSearchableDropdown
                value={rolesInput.mainProjectControllerId !== null ? rolesInput.mainProjectControllerId : ""}
                onValueChanged={value =>
                  setSectionInput({
                    input: { ...rolesInput, mainProjectControllerId: parseNumberOrNull(value) },
                    pristine: false,
                  })
                }
                options={personsData.persons.map(person => {
                  return { id: person.userId, description: `${person.firstName} ${person.lastName}` };
                })}
                searchable={true}
                disabled={!editInfo.mainProjectControllerProperties.editable}
                error={inputErrors.includes("mainProjectController")}
                maxResults={20}
              />
            ) : (
              getDataItem("mainProjectController", roles)
            )}
          </>
        )}
      </InformationItem>
      <InformationItem>
        {rolesVisibility.projectController && (
          <>
            <TitleItem>Project controller:</TitleItem>
            {editInfo.projectControllerProperties.editable ? (
              <EditableSearchableDropdown
                value={rolesInput.projectControllerId !== null ? rolesInput.projectControllerId : ""}
                onValueChanged={value =>
                  setSectionInput({
                    input: { ...rolesInput, projectControllerId: parseNumberOrNull(value) },
                    pristine: false,
                  })
                }
                options={personsData.persons.map(person => {
                  return { id: person.userId, description: `${person.firstName} ${person.lastName}` };
                })}
                searchable={true}
                disabled={!editInfo.projectControllerProperties.editable}
                error={inputErrors.includes("projectController")}
                maxResults={20}
              />
            ) : (
              getDataItem("projectController", roles)
            )}
          </>
        )}
      </InformationItem>
      <InformationItem>
        {rolesVisibility.projectManager && (
          <>
            <TitleItem>Project manager:</TitleItem>
            {editInfo.projectManagerProperties.editable ? (
              <EditableSearchableDropdown
                value={rolesInput.projectManagerId !== null ? rolesInput.projectManagerId : ""}
                onValueChanged={value =>
                  setSectionInput({
                    input: { ...rolesInput, projectManagerId: parseNumberOrNull(value) },
                    pristine: false,
                  })
                }
                options={personsData.persons.map(person => {
                  return { id: person.userId, description: `${person.firstName} ${person.lastName}` };
                })}
                searchable={true}
                disabled={!editInfo.projectManagerProperties.editable}
                error={inputErrors.includes("projectManager")}
                maxResults={20}
              />
            ) : (
              getDataItem("projectManager", roles)
            )}
          </>
        )}
      </InformationItem>
      <InformationItem>
        {rolesVisibility.projectManagersManager && (
          <>
            <TitleItem>{"Project manager's manager:"}</TitleItem>
            {editInfo.projectManagersManagerProperties.editable ? (
              <EditableSearchableDropdown
                value={rolesInput.projectManagersManagerId !== null ? rolesInput.projectManagersManagerId : ""}
                onValueChanged={value =>
                  setSectionInput({
                    input: { ...rolesInput, projectManagersManagerId: parseNumberOrNull(value) },
                    pristine: false,
                  })
                }
                options={personsData.persons.map(person => {
                  return { id: person.userId, description: `${person.firstName} ${person.lastName}` };
                })}
                searchable={true}
                disabled={!editInfo.projectManagersManagerProperties.editable}
                error={inputErrors.includes("projectManagersManager")}
                maxResults={20}
              />
            ) : (
              getDataItem("projectManagersManager", roles)
            )}
          </>
        )}
      </InformationItem>
      <InformationItem>
        {rolesVisibility.projectCoordinator && (
          <>
            <TitleItem>Project coordinator:</TitleItem>
            {editInfo.projectCoordinatorProperties.editable ? (
              <EditableSearchableDropdown
                value={rolesInput.projectCoordinatorId !== null ? rolesInput.projectCoordinatorId : ""}
                onValueChanged={value =>
                  setSectionInput({
                    input: { ...rolesInput, projectCoordinatorId: parseNumberOrNull(value) },
                    pristine: false,
                  })
                }
                options={personsData.persons.map(person => {
                  return { id: person.userId, description: `${person.firstName} ${person.lastName}` };
                })}
                searchable={true}
                disabled={!editInfo.projectCoordinatorProperties.editable}
                error={inputErrors.includes("projectCoordinator")}
                maxResults={20}
              />
            ) : (
              getDataItem("projectCoordinator", roles)
            )}
          </>
        )}
      </InformationItem>
    </RoleSection>
  ) : (
    <LoadingContainer>Error loading editing data.</LoadingContainer>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(EditDetails);

const RoleSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;

const InformationItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const TitleItem = styled.div`
  font-size: 14px;
  margin-bottom: 4px;
`;

const LoadingContainer = styled.div`
  padding: 20px;
  color: ${defaultGrey};
`;
