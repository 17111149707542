import { gql } from "@apollo/client";

export const MY_PROJECTS = gql`
  query GetMyProjects {
    myProjects {
      projects {
        id
        desc
        technicalType
      }
    }
  }
`;
