import React from "react";
import { ProjectTag } from "../../../../../common/types";
import styled from "styled-components";
import { defaultGrey, projectsYellow } from "../../../../../common/colors";

export interface ViewDetailsProps {
  tags: ProjectTag[];
}

function ViewDetails(props: ViewDetailsProps): React.ReactElement {
  const { tags } = props;

  return (
    <TagsSection>
      {!tags.length
        ? "No project tags."
        : tags.map(tag => (
            <TagView key={tag.id}>
              <Tooltip>{tag.description || tag.id}</Tooltip>
              <span>{tag.id}</span>
            </TagView>
          ))}
    </TagsSection>
  );
}

export default ViewDetails;

const TagsSection = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Tooltip = styled.div`
  visibility: hidden;
  position: absolute;
  top: 35px;
  left: 0;
  max-width: 600px;
  background: ${defaultGrey};
  border-radius: 6px;
  color: #ffffff;
  text-align: center;
  margin-top: 10px;
  width: max-content;
  padding: 4px;
  font-size: 12px;
  word-break: break-word;
  z-index: 1;

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: -8px;
    left: 20px;
    border-color: transparent transparent ${defaultGrey} transparent;
    border-style: solid;
    border-width: 0 6px 8px 6px;
  }
`;

const TagView = styled.div`
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: bold;
  background: ${projectsYellow};
  margin-right: 20px;
  height: 35px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  &:hover {
    ${Tooltip} {
      visibility: visible;
    }
  }
`;
