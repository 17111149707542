import React from "react";
import { ProjectId, ProjectIntegrations, ProjectIntegrationsVisibility } from "../../../../../common/types";
import styled from "styled-components";
import { defaultGrey, settingGreen } from "../../../../../common/colors";
import { yesNo } from "../utils";
import { ProfitPITProgressValues, ProfitStatusValues } from "./constants";
import { PITProgressTooltip } from "./PITProgressTooltip";

interface IntegrationsDetailsProps {
  projectId: ProjectId;
  integrations: ProjectIntegrations;
  visibility: ProjectIntegrationsVisibility;
}

function ViewDetails(props: IntegrationsDetailsProps) {
  const { integrations, visibility } = props;

  const { isInRadar, radarUrl, sendToRadar, profitStatusId, profitPITProgressId } = integrations;

  const profitStatus = (() => {
    const status = ProfitStatusValues.find(v => v.id === profitStatusId);
    return status ? status : { id: -1, description: "-" };
  })();
  const profitPITProgress = (() => {
    const progress = ProfitPITProgressValues.find(v => v.id === profitPITProgressId);
    return progress ? progress : { id: -1, description: "-" };
  })();

  return (
    <Container>
      <CodeSection>
        <InformationItem>
          <TitleItem>Send to Radar:</TitleItem>
          <DataItem>{yesNo(sendToRadar)}</DataItem>
        </InformationItem>
        {isInRadar && (
          <InformationItem>
            <TitleItem>Radar Link</TitleItem>
            <DataItem>
              {radarUrl ? (
                <Link href={radarUrl} target="_blank" rel="noreferrer">
                  Link
                </Link>
              ) : (
                "-"
              )}
            </DataItem>
          </InformationItem>
        )}
      </CodeSection>
      {(visibility.profitStatusId || visibility.profitPITProgressId) && (
        <CodeSection>
          {visibility.profitStatusId && (
            <InformationItem>
              <TitleItem>Profit Status:</TitleItem>
              <DataItem>{profitStatus.description}</DataItem>
            </InformationItem>
          )}
          {visibility.profitPITProgressId && (
            <InformationItem>
              <TitleItem>
                <PITProgressTooltip integrations={integrations} />
              </TitleItem>
              <DataItem>
                {profitPITProgress.description}
                {integrations.profitPITProgressPercentage !== null
                  ? " - " + integrations.profitPITProgressPercentage
                  : undefined}
              </DataItem>
            </InformationItem>
          )}
        </CodeSection>
      )}
    </Container>
  );
}

export default ViewDetails;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-bottom: 0px;
  color: ${defaultGrey};
`;

const InformationItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding-right: 5px;
`;

const TitleItem = styled.div`
  font-size: 14px;
  margin-bottom: 4px;
`;

const DataItem = styled.div<{ emphasize?: boolean }>`
  font-weight: bold;
  font-size: ${({ emphasize }) => (emphasize ? "22px" : "14px")};
`;

const CodeSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;

const Link = styled.a`
  color: ${settingGreen};
`;
