import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useQuery } from "@apollo/client/react/hooks";
import { ThunkDispatch } from "redux-thunk";
import { Action } from "redux";
import { connect } from "react-redux";

import {
  AppState,
  FieldProperties,
  OrganisationKey,
  OrganisationKeysResult,
  ProjectId,
  ProjectReportingRelations,
  ProjectReportingRelationsEditInformationData,
  ProjectReportingRelationsInput,
  ProjectReportingRelationsVisibility,
  ReportingRelationsSectionInput,
  SearchCustomersResult,
} from "../../../../../common/types";
import { defaultGrey, valmetGreyBorder } from "../../../../../common/colors";
import { ToggleSwitch } from "../../../../../common/components";
import EditableSearchableDropdown from "../EditableComponents/EditableSearchableDropdown";
import { setProjectError, setReportingRelations } from "../../../../../actions/projectActions";
import { GET_ORGANISATION_KEYS, SEARCH_CUSTOMERS } from "../InformationSection/queries";
import { GET_REPORTING_RELATIONS_EDIT_INFORMATION } from "./queries";
import { DetailsField, checkInputErrors } from "./checkInputErrors";
import { CUSTOMERS_QUERY_SIZE, getEmptyReportingRelationsSectionInput } from "../../../../../common/constants";
import LoadingView from "../../../../LoadingView";
import { combineToString, editClone, getDataItem, yesNo } from "../utils";
import { parseNumberOrNull } from "../../../../../common/utils";
import { cloneDeep } from "lodash";
import { useDebouncedState } from "../../../../../hooks/useDebouncedState";

type Props = {
  projectId: ProjectId;
  reportingRelations: ProjectReportingRelations;
  visibility: ProjectReportingRelationsVisibility;
};

const mapStateToProps = (state: AppState) => {
  return {
    projectCreationMode: state.projectState.projectCreationMode,
    editInformation: state.projectState.projectDetailsEditInformation
      ? state.projectState.projectDetailsEditInformation.reportingRelations
      : undefined,
    sectionInput: state.projectState.projectInput ? state.projectState.projectInput.reportingRelations : undefined,
    hierarchy: state.projectState.hierarchy,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, void, Action>) => {
  return {
    setSectionInput: (input: ReportingRelationsSectionInput) => dispatch(setReportingRelations(input)),
    setProjectError: (value: boolean) => {
      dispatch(setProjectError(value, "project"));
    },
  };
};

function EditDetails(
  props: Props & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
): React.ReactElement {
  const { projectId, reportingRelations, visibility, editInformation, sectionInput, setSectionInput } = props;
  const [editInfo, setEditInfo] = useState(editInformation);
  useEffect(() => {
    setEditInfo(editInformation);
  }, [editInformation]);

  const {
    loading: organisationKeysLoading,
    data: organisationKeysData,
    error: organisationKeysError,
  } = useQuery<OrganisationKeysResult>(GET_ORGANISATION_KEYS, {});

  const sortedExternalReporting = useMemo(() => {
    if (!organisationKeysData) return [];
    function compare(a: OrganisationKey, b: OrganisationKey): number {
      const aValue = a.description === "Yes" ? 0 : a.description === "No" ? 1 : 2;
      const bValue = b.description === "Yes" ? 0 : b.description === "No" ? 1 : 2;
      return aValue - bValue;
    }
    return organisationKeysData.organisationKeys.externalReportings.concat([]).sort(compare);
  }, [organisationKeysData]);

  const {
    loading: editInformationLoading,
    error: editInformationError,
  } = useQuery<ProjectReportingRelationsEditInformationData>(GET_REPORTING_RELATIONS_EDIT_INFORMATION, {
    variables: { projectId: projectId },
    fetchPolicy: "network-only",
    skip: editInformation !== undefined,
    onCompleted: data => {
      if (data) {
        setEditInfo(data.projectDetailsEditInformation.reportingRelations);
        setSectionInput(getEmptyReportingRelationsSectionInput());
      }
    },
  });

  const [customersInput, customersSearchInput, setCustomersInput] = useDebouncedState(
    500,
    editInfo && editInfo.externalOrganisation.customerId !== null
      ? `${editInfo.externalOrganisation.customerId || ""} ${
          reportingRelations.externalOrganisation.customerName || ""
        }`
      : ""
  );
  const [customersLoading, setCustomersLoading] = useState(false);
  useEffect(() => setCustomersLoading(true), [customersInput]);

  useEffect(() => {
    const cusomerString =
      editInfo && editInfo.externalOrganisation.customerId !== null
        ? `${editInfo.externalOrganisation.customerId || ""} ${
            reportingRelations.externalOrganisation.customerName || ""
          }`
        : "";
    setCustomersInput(cusomerString);
  }, [editInfo, reportingRelations]);

  const { data: customersData, error: customersError, fetchMore } = useQuery<SearchCustomersResult>(SEARCH_CUSTOMERS, {
    variables: {
      num: CUSTOMERS_QUERY_SIZE,
      searchQuery: customersSearchInput
        .trim()
        .split(" ")
        .filter(term => term.trim().length > 0),
    },
    onCompleted: () => setCustomersLoading(false),
  });

  const setReportingRelationsInput = useCallback(
    (newInput: ProjectReportingRelationsInput) => setSectionInput({ input: newInput, pristine: false }),
    [setSectionInput]
  );

  const loading = organisationKeysLoading || editInformationLoading;

  const onEditInformationDataChange = useCallback(() => {
    const input = sectionInput ? sectionInput.input : null;

    console.log("Edit info changed", editInfo, input);
    function select<T>(inp: T | null, original: T | null, field: FieldProperties) {
      return field.editable && inp !== null ? inp : original;
    }
    if (editInfo) {
      const ext = editInfo.externalOrganisation;
      setSectionInput({
        input: {
          externalOrganisation: {
            businessGroupId: select(
              input && input.externalOrganisation.businessGroupId,
              ext.businessGroupId,
              ext.businessGroupProperties
            ),
            businessTypeId: select(
              input && input.externalOrganisation.businessTypeId,
              ext.businessTypeId,
              ext.businessTypeProperties
            ),
            legalEntityId: select(
              input && input.externalOrganisation.legalEntityId,
              ext.legalEntityId,
              ext.legalEntityProperties
            ),
            reportingId: select(
              input && input.externalOrganisation.reportingId,
              ext.reportingId,
              ext.reportingProperties
            ),
            customerId: select(input && input.externalOrganisation.customerId, ext.customerId, ext.customerProperties),
            externalReportingIndustryId: select(
              input && input.externalOrganisation.externalReportingIndustryId,
              ext.externalReportingIndustryId,
              ext.externalReportingIndustryProperties
            ),
            orderValueIncludedInRelated: select(
              input && input.externalOrganisation.orderValueIncludedInRelated,
              ext.orderValueIncludedInRelated,
              ext.orderValueIncludedInRelatedProperties
            ),
          },
          serMarginTraceability: select(
            input && input.serMarginTraceability,
            reportingRelations.serMarginTraceability,
            editInfo.serMarginTraceabilityProperties
          ),
        },
        pristine: sectionInput ? sectionInput.pristine : true,
      });
    }
  }, [sectionInput, setSectionInput, editInfo, reportingRelations]);

  useEffect(onEditInformationDataChange, [editInfo, reportingRelations]);

  const inputErrors: DetailsField[] = useMemo(() => checkInputErrors(sectionInput?.input, editInfo), [
    sectionInput,
    editInfo,
  ]);

  const extVis = visibility.externalOrganisation;

  return loading ? (
    <LoadingView />
  ) : visibility.externalOrganisation === null ? (
    <></>
  ) : sectionInput && editInfo && organisationKeysData ? (
    <>
      <DataSection
        noBorder={
          extVis === null ||
          !(
            extVis.businessType ||
            extVis.businessGroup ||
            extVis.legalEntity ||
            extVis.externalReportingIndustry ||
            extVis.customerId
          )
        }
      >
        {(visibility.relatingProjectId || visibility.relatingProjectDesc) && (
          <CodeSection2>
            <InformationItem>
              <>
                <TitleItem>Relating project:</TitleItem>
                {getDataItem(
                  combineToString([
                    visibility.relatingProjectId ? reportingRelations.relatingProjectId : undefined,
                    visibility.relatingProjectDesc ? reportingRelations.relatingProjectDesc : undefined,
                  ])
                )}
              </>
            </InformationItem>
            <InformationItem>
              {visibility.serMarginTraceability && (
                <>
                  <TitleItem>SER margin traceability:</TitleItem>
                  {editInfo.serMarginTraceabilityProperties.editable ? (
                    <DataItem>
                      <ToggleSwitch
                        checked={
                          sectionInput.input.serMarginTraceability || reportingRelations.serMarginTraceability || false
                        }
                        text={yesNo(sectionInput.input.serMarginTraceability || false)}
                        onChange={value =>
                          setReportingRelationsInput({ ...sectionInput.input, serMarginTraceability: value })
                        }
                      />
                    </DataItem>
                  ) : (
                    getDataItem(yesNo(reportingRelations.serMarginTraceability || false))
                  )}
                </>
              )}
            </InformationItem>
          </CodeSection2>
        )}
        <CodeSection2>
          <InformationItem>
            {visibility.externalOrganisation.reporting && (
              <>
                <TitleItem>External reporting:</TitleItem>
                {editInfo.externalOrganisation.reportingProperties.editable ? (
                  <EditableSearchableDropdown
                    value={
                      sectionInput.input.externalOrganisation.reportingId !== null
                        ? sectionInput.input.externalOrganisation.reportingId
                        : ""
                    }
                    onValueChanged={value => {
                      console.log("Reporting: ", value);
                      setReportingRelationsInput(
                        editClone(
                          sectionInput.input,
                          inp => (inp.externalOrganisation.reportingId = parseNumberOrNull(value))
                        )
                      );
                    }}
                    options={sortedExternalReporting.map(reporting => {
                      return { id: reporting.id, description: reporting.description };
                    })}
                    disabled={!editInfo.externalOrganisation.reportingProperties.editable}
                    error={inputErrors.includes(DetailsField.externalOrganisationReportingId)}
                    searchable={true}
                    maxResults={20}
                  />
                ) : (
                  getDataItem(reportingRelations.externalOrganisation.reporting)
                )}
              </>
            )}
          </InformationItem>
          <InformationItem>
            {visibility.externalOrganisation.orderValueIncludedInRelated &&
              // Only if reporting is not "yes" (ID 10487 in dev and prod), allow editing "Order value included" field.
              sectionInput.input.externalOrganisation.reportingId !== 10487 && (
                <>
                  <TitleItem title="Is this project's value included in the related project">
                    Order value included in related:
                  </TitleItem>
                  {editInfo.externalOrganisation.orderValueIncludedInRelatedProperties.editable ? (
                    <DataItem>
                      <ToggleSwitch
                        checked={sectionInput.input.externalOrganisation.orderValueIncludedInRelated || false}
                        text={yesNo(sectionInput.input.externalOrganisation.orderValueIncludedInRelated || false)}
                        onChange={value =>
                          setReportingRelationsInput(
                            editClone(
                              sectionInput.input,
                              inp => (inp.externalOrganisation.orderValueIncludedInRelated = value)
                            )
                          )
                        }
                      />
                    </DataItem>
                  ) : (
                    getDataItem(yesNo(reportingRelations.externalOrganisation.orderValueIncludedInRelated || false))
                  )}
                </>
              )}
          </InformationItem>
        </CodeSection2>
      </DataSection>

      {extVis !== null &&
        (extVis.businessType ||
          extVis.businessGroup ||
          extVis.legalEntity ||
          extVis.externalReportingIndustry ||
          extVis.customerId) && (
          <DataSection noBorder={true}>
            <CodeSection12>
              <InformationItem>
                {visibility.externalOrganisation.businessType && (
                  <>
                    <TitleItem>External business type:</TitleItem>
                    {editInfo.externalOrganisation.businessTypeProperties.editable ? (
                      <EditableSearchableDropdown
                        value={sectionInput.input.externalOrganisation.businessTypeId || ""}
                        onValueChanged={value =>
                          setReportingRelationsInput(
                            editClone(
                              sectionInput.input,
                              inp => (inp.externalOrganisation.businessTypeId = parseNumberOrNull(value))
                            )
                          )
                        }
                        options={organisationKeysData.organisationKeys.externalBusinessTypes.map(businessType => {
                          return { id: businessType.id, description: businessType.description };
                        })}
                        disabled={!editInfo.externalOrganisation.businessTypeProperties.editable}
                        error={inputErrors.includes(DetailsField.externalOrganisationBusinessTypeId)}
                        searchable={true}
                        maxResults={20}
                      />
                    ) : (
                      getDataItem(reportingRelations.externalOrganisation.businessType)
                    )}
                  </>
                )}
              </InformationItem>
              <InformationItem>
                {visibility.externalOrganisation.legalEntity && (
                  <>
                    <TitleItem>External legal company:</TitleItem>
                    {editInfo.externalOrganisation.legalEntityProperties.editable ? (
                      <EditableSearchableDropdown
                        value={sectionInput.input.externalOrganisation.legalEntityId || ""}
                        onValueChanged={value =>
                          setReportingRelationsInput(
                            editClone(
                              sectionInput.input,
                              inp => (inp.externalOrganisation.legalEntityId = parseNumberOrNull(value))
                            )
                          )
                        }
                        options={organisationKeysData.organisationKeys.externalLegalEntities.map(legalEntity => {
                          return { id: legalEntity.id, description: legalEntity.description };
                        })}
                        disabled={!editInfo.externalOrganisation.legalEntityProperties.editable}
                        error={inputErrors.includes(DetailsField.externalOrganisationLegalEntityId)}
                        searchable={true}
                        maxResults={20}
                      />
                    ) : (
                      getDataItem(reportingRelations.externalOrganisation.legalEntity)
                    )}
                  </>
                )}
              </InformationItem>
            </CodeSection12>
            <InformationItem>
              {visibility.externalOrganisation.businessGroup && (
                <>
                  <TitleItem>External business group:</TitleItem>
                  {editInfo.externalOrganisation.businessGroupProperties.editable ? (
                    <EditableSearchableDropdown
                      value={sectionInput.input.externalOrganisation.businessGroupId || ""}
                      onValueChanged={value =>
                        setReportingRelationsInput(
                          editClone(
                            sectionInput.input,
                            inp => (inp.externalOrganisation.businessGroupId = parseNumberOrNull(value))
                          )
                        )
                      }
                      options={organisationKeysData.organisationKeys.externalBusinessGroups.map(businessGroup => {
                        return { id: businessGroup.id, description: businessGroup.description };
                      })}
                      disabled={!editInfo.externalOrganisation.businessGroupProperties.editable}
                      error={inputErrors.includes(DetailsField.externalOrganisationBusinessGroupId)}
                      searchable={true}
                      maxResults={20}
                      inputWidth="full"
                    />
                  ) : (
                    getDataItem(reportingRelations.externalOrganisation.businessGroup)
                  )}
                </>
              )}
            </InformationItem>
            <InformationItem>
              {visibility.externalOrganisation.externalReportingIndustry && (
                <>
                  <TitleItem>External industry:</TitleItem>
                  {editInfo.externalOrganisation.externalReportingIndustryProperties.editable ? (
                    <EditableSearchableDropdown
                      value={sectionInput.input.externalOrganisation.externalReportingIndustryId || ""}
                      onValueChanged={value =>
                        setReportingRelationsInput(
                          editClone(
                            sectionInput.input,
                            inp => (inp.externalOrganisation.externalReportingIndustryId = parseNumberOrNull(value))
                          )
                        )
                      }
                      options={organisationKeysData.organisationKeys.externalIndustries.map(industry => ({
                        id: industry.id,
                        description: industry.description,
                      }))}
                      disabled={!editInfo.externalOrganisation.externalReportingIndustryProperties.editable}
                      error={inputErrors.includes(DetailsField.externalReportingIndustryId)}
                      searchable={true}
                      maxResults={20}
                      inputWidth="full"
                    />
                  ) : (
                    getDataItem(reportingRelations.externalOrganisation.externalReportingIndustry)
                  )}
                </>
              )}
            </InformationItem>
            <InformationItem>
              {(visibility.externalOrganisation.customerId || visibility.externalOrganisation.customerName) && (
                <>
                  <TitleItem>External customer:</TitleItem>
                  {editInfo.externalOrganisation.customerProperties.editable ? (
                    <EditableSearchableDropdown
                      value={
                        sectionInput.input.externalOrganisation.customerId !== null
                          ? sectionInput.input.externalOrganisation.customerId
                          : ""
                      }
                      onValueChanged={value =>
                        setReportingRelationsInput(
                          editClone(
                            sectionInput.input,
                            inp => (inp.externalOrganisation.customerId = parseNumberOrNull(value))
                          )
                        )
                      }
                      options={
                        !customersData
                          ? []
                          : customersData?.searchCustomers.customers.map(customer => ({
                              id: customer.id,
                              description: combineToString([customer.id, customer.name]) || "",
                            }))
                      }
                      disabled={!editInfo.externalOrganisation.customerProperties.editable}
                      error={inputErrors.includes(DetailsField.externalOrganisationCustomerId)}
                      searchable={true}
                      inputWidth="full"
                      scrollingDisabled
                      controlledTextInput={customersInput}
                      setControlledTextInput={input => setCustomersInput(input)}
                      dataError={!!customersError}
                      loading={customersLoading}
                      onLoadMore={() =>
                        fetchMore({
                          variables: {
                            num: CUSTOMERS_QUERY_SIZE,
                            searchQuery: customersSearchInput
                              .trim()
                              .split(" ")
                              .filter(term => term.trim().length > 0),
                            offset: customersData?.searchCustomers.customers.length,
                          },
                          updateQuery: (previousResults, { fetchMoreResult }) => {
                            if (!fetchMoreResult) return previousResults;
                            const mergedResults = cloneDeep(previousResults);
                            mergedResults.searchCustomers.customers = mergedResults.searchCustomers.customers.concat(
                              fetchMoreResult.searchCustomers.customers
                            );
                            mergedResults.searchCustomers.availableResults =
                              fetchMoreResult.searchCustomers.availableResults;
                            return mergedResults;
                          },
                        })
                      }
                      morePagesAvailable={
                        customersData
                          ? customersData.searchCustomers.customers.length <
                            customersData.searchCustomers.availableResults
                          : false
                      }
                    />
                  ) : (
                    getDataItem(
                      combineToString([
                        visibility.externalOrganisation.customerId
                          ? reportingRelations.externalOrganisation.customerId
                          : undefined,
                        visibility.externalOrganisation.customerName
                          ? reportingRelations.externalOrganisation.customerName
                          : undefined,
                      ])
                    )
                  )}
                </>
              )}
            </InformationItem>
            <InformationItem>
              {visibility.relatedLegalCompany && (
                <>
                  <TitleItem>Related legal company:</TitleItem>
                  {getDataItem(reportingRelations.relatedLegalCompany)}
                </>
              )}
            </InformationItem>
          </DataSection>
        )}
    </>
  ) : (
    <LoadingContainer>Error loading reporting relations data.</LoadingContainer>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(EditDetails);

const LoadingContainer = styled.div`
  padding: 20px;
  color: ${defaultGrey};
`;

export const ProjectDataSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;

export const ProjectDataSectionTwo = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const InformationItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding-right: 5px;
`;

export const DataItem = styled.div<{ emphasize?: boolean }>`
  font-weight: bold;
  font-size: ${({ emphasize }) => (emphasize ? "22px" : "14px")};
  align-self: baseline;
  padding-right: 10px;
`;

export const TitleItem = styled.div`
  font-size: 14px;
  margin-bottom: 4px;
  display: flex;
  flex-direction: row;
`;

export const DataSection = styled.div<{ noBorder?: boolean }>`
  margin-bottom: ${({ noBorder }) => (noBorder ? "0px;" : `20px;`)};
  border-bottom: ${({ noBorder }) => (noBorder ? "0;" : `1px solid ${valmetGreyBorder}`)};
`;

export const CodeSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;

export const CodeSection2 = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const CodeSection12 = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
`;
