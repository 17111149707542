import React, { useContext } from "react";
import ReactDOM from "react-dom";

export enum HeaderActionsRefs {
  commentPackageEditRef = "commentPackageEditRef",
  createProjectActionsRef = "createProjectActionsRef",
  errorsBoxRef = "errorsBoxRef",
}

export type ContextType = {
  [key in HeaderActionsRefs]: React.RefObject<HTMLDivElement | null>;
};

export type HeaderRefs = {
  [key in HeaderActionsRefs]: React.RefObject<HTMLDivElement>;
};

export const ProjectHeaderActionsContext = React.createContext<ContextType>({
  [HeaderActionsRefs.commentPackageEditRef]: { current: null },
  [HeaderActionsRefs.createProjectActionsRef]: { current: null },
  [HeaderActionsRefs.errorsBoxRef]: { current: null },
});

export function HeaderActions({
  children,
  refName,
}: React.PropsWithChildren<{ refName: HeaderActionsRefs }>): React.ReactPortal | null {
  const { [refName]: ref } = useContext(ProjectHeaderActionsContext);
  if (ref.current) {
    return ReactDOM.createPortal(children, ref.current);
  } else {
    return null;
  }
}
