import { gql } from "@apollo/client";
import { FieldPropertiesFields } from "../queries";

export const GET_PROJECT_ROLES_EDIT_INFORMATION = gql`
  query GetProjectRolesEditInformation($projectId: ProjectId!) {
    projectDetailsEditInformation(projectId: $projectId) {
      projectRoles {
        mainProjectManagerProperties {
          ...FieldPropertiesFields
        }
        mainProjectControllerProperties {
          ...FieldPropertiesFields
        }
        projectControllerProperties {
          ...FieldPropertiesFields
        }
        projectManagerProperties {
          ...FieldPropertiesFields
        }
        projectManagersManagerProperties {
          ...FieldPropertiesFields
        }
        projectCoordinatorProperties {
          ...FieldPropertiesFields
        }
      }
    }
  }
  ${FieldPropertiesFields}
`;

export const GET_PERSONS = gql`
  query GetPersons {
    persons {
      userId
      firstName
      lastName
    }
  }
`;

const ProjectPersonSaveFields = gql`
  fragment ProjectPersonSaveFields on ProjectPerson {
    userId
    firstName
    lastName
  }
`;

export const SAVE_PROJECT_ROLES = gql`
  mutation SaveProjectRoles($projectId: ProjectId!, $roles: ProjectRolesInput!) {
    saveProjectRoles(projectId: $projectId, roles: $roles) {
      result {
        mainProjectManager {
          ...ProjectPersonSaveFields
        }
        mainProjectController {
          ...ProjectPersonSaveFields
        }
        projectController {
          ...ProjectPersonSaveFields
        }
        projectManager {
          ...ProjectPersonSaveFields
        }
        projectManagersManager {
          ...ProjectPersonSaveFields
        }
      }
      errors
      applicationModifiedDateTime
    }
  }
  ${ProjectPersonSaveFields}
`;
