import {
  Column,
  ColumnFields,
  ColumnFieldSpec,
  ColumnFieldSpecType,
  ColumnSet,
  EditColumnSpec,
  ColumnSets,
  ColumnFieldTypes,
  EditColumnSets,
  GeneratedColumn,
} from "./columnsTypes";
import { values } from "lodash";

const hideForFuture = {
  hideForFuturePeriods: true,
};

const COLUMN_FIELDS: ColumnFieldTypes = {
  StringValue: {
    value: { title: "", decimals: 0 },
  },
  Recognition: {
    ns: { title: "NS", decimals: 0, ...hideForFuture },
    cogs: { title: "Cogs", decimals: 0, ...hideForFuture },
    pm: { title: "PM", decimals: 0, ...hideForFuture },
    warCost: { title: "War Cost", decimals: 0, ...hideForFuture },
  },
  RecPercent: {
    nsPercent: { title: "NS%", decimals: 0, type: ColumnFieldSpecType.PERCENTAGE, ...hideForFuture },
    costPercent: { title: "COGS%", decimals: 0, type: ColumnFieldSpecType.PERCENTAGE, ...hideForFuture },
    pmPercent: { title: "PM%", decimals: 1, type: ColumnFieldSpecType.PERCENTAGE, ...hideForFuture },
  },
  EstRec: {
    ns: { title: "NS", decimals: 0 },
    cogs: { title: "Cogs", decimals: 0 },
    pm: { title: "PM", decimals: 0 },
    wipCosts: { title: "WIP Costs", decimals: 0 },
  },
  EstRecPercent: {
    nsPercent: { title: "NS%", decimals: 0, type: ColumnFieldSpecType.PERCENTAGE },
    cogsPercent: { title: "COGS%", decimals: 0, type: ColumnFieldSpecType.PERCENTAGE },
    pmPercent: { title: "PM%", decimals: 1, type: ColumnFieldSpecType.PERCENTAGE },
  },
  NetSalesCostsPM: {
    ns: { title: "NS", decimals: 0 },
    cost: { title: "Costs", decimals: 0 },
    pm: { title: "PM", decimals: 0 },
    pmPercent: { title: "PM%", decimals: 1, type: ColumnFieldSpecType.PERCENTAGE },
    warCost: { title: "War Cost", decimals: 0 },
  },
  Estimate: {
    ns: { title: "NS", decimals: 0 },
    cost: { title: "Costs", decimals: 0 },
    pm: { title: "PM", decimals: 0 },
    pmPercent: { title: "PM%", decimals: 1, type: ColumnFieldSpecType.PERCENTAGE },
    warCost: { title: "War Cost", decimals: 0 },
  },
  NetSalesCogsPM: {
    ns: { title: "NS", decimals: 0, ...hideForFuture },
    cogs: { title: "Cogs", decimals: 0, ...hideForFuture },
    pm: { title: "PM", decimals: 0, ...hideForFuture },
    pmPercent: { title: "PM%", decimals: 1, type: ColumnFieldSpecType.PERCENTAGE, ...hideForFuture },
  },
  AsSoldBudgetEstimated: {
    asld: { title: "ASLD", decimals: 0 },
    obud: { title: "OBUD", decimals: 0 },
    rbud: { title: "RBUD", decimals: 0 },
    est: { title: "EST", decimals: 0 },
  },
  Contingency: {
    asld: { title: "ASLD", decimals: 0 },
    obud: { title: "OBUD", decimals: 0 },
    rbud: { title: "RBUD", decimals: 0 },
    prevEst: { title: "Prev EST", decimals: 0 },
    est: { title: "EST", decimals: 0 },
  },
  WarrCostVar: {
    period: { title: "Period", decimals: 0 },
    toASLD: { title: "To ASLD", decimals: 0 },
    toRBUD: { title: "To RBUD", decimals: 0 },
    YTD: { title: "YTD", decimals: 0 },
  },
  Invoicing: {
    invoicing: { title: "Invoicing", decimals: 0 },
    paymRec: { title: "Paym Rec", decimals: 0 },
  },
  InvoicingExt: {
    invoicing: { title: "Invoicing", decimals: 0 },
    receivable: { title: "Receivables", decimals: 0 },
    paymRec: { title: "Paym Rec", decimals: 0 },
    paymRecPercentage: { title: "Paym Rec%", decimals: 1, type: ColumnFieldSpecType.PERCENTAGE },
    ovdRec: { title: "OVD Rec", decimals: 0 },
  },
  SalesPrice: {
    asld: { title: "ASLD", decimals: 0 },
    obud: { title: "OBUD", decimals: 0 },
    rbud: { title: "RBUD", decimals: 0 },
    prevEst: { title: "Prev EST", decimals: 0 },
    est: { title: "EST", decimals: 0 },
  },
  YTDRec: {
    actNS: { title: "Act NS", decimals: 0, ...hideForFuture },
    actCogs: { title: "Act Cogs", decimals: 0, ...hideForFuture },
    actPM: { title: "Act PM", decimals: 0, ...hideForFuture },
  },
  PeriodRec: {
    ns: { title: "NS", decimals: 0, ...hideForFuture },
    cogs: { title: "Cogs", decimals: 0, ...hideForFuture },
    pm: { title: "PM", decimals: 0, ...hideForFuture },
    pmPercent: { title: "PM%", decimals: 1, type: ColumnFieldSpecType.PERCENTAGE, ...hideForFuture },
  },
  Rates: {
    orRate: { title: "OR Rate", decimals: 6, type: ColumnFieldSpecType.RATE },
    hedgeRate: { title: "Hedge Rate", decimals: 6, type: ColumnFieldSpecType.RATE },
    avgRate: { title: "Avg Rate", decimals: 6, type: ColumnFieldSpecType.RATE },
    endRate: { title: "End Rate", decimals: 6, type: ColumnFieldSpecType.RATE },
    erRate: { title: "ER Rate", decimals: 6, type: ColumnFieldSpecType.RATE },
    irRate: { title: "IR Rate", decimals: 6, type: ColumnFieldSpecType.RATE },
  },
  OrdersReceived: {
    or: { title: "OR", decimals: 0 },
    orPeriod: { title: "OR Period", decimals: 0 },
    orPTD: { title: "OR PTD", decimals: 0 },
    oblCorrYTD: { title: "OBL Corr YTD", decimals: 0 },
    adjOnOR: { title: "Adj on OR", decimals: 0 },
  },
  RecWarranty: {
    periodCogs: { title: "Period Cogs", decimals: 0, ...hideForFuture },
    ytdCogs: { title: "YTD Cogs", decimals: 0, ...hideForFuture },
    prevCogs: { title: "Prev Cogs", decimals: 0, ...hideForFuture },
    cogs: { title: "Cogs", decimals: 0, ...hideForFuture },
  },
  ActualCostsCommitments: {
    actualCosts: { title: "Act Costs", decimals: 0, ...hideForFuture },
    hardComm: { title: "Hard Comm", decimals: 0, ...hideForFuture },
    softComm: { title: "Soft Comm", decimals: 0, ...hideForFuture },
    wipCosts: { title: "Wip Costs", decimals: 0, ...hideForFuture },
  },
  CommCosts: {
    commCosts: { title: "Comm Costs", decimals: 0, ...hideForFuture },
    actualCosts: { title: "Actual Costs", decimals: 0, ...hideForFuture },
    hardComm: { title: "Hard Comm", decimals: 0, ...hideForFuture },
    softComm: { title: "Soft Comm", decimals: 0, ...hideForFuture },
    wipCosts: { title: "Wip Costs", decimals: 0, ...hideForFuture },
  },
  YTDRecPercentage: {
    actNSPercentage: {
      title: "ACT NS%",
      decimals: 0,
      type: ColumnFieldSpecType.PERCENTAGE,
      ...hideForFuture,
    },
    actCogsPercentage: {
      title: "ACT COGS%",
      decimals: 0,
      type: ColumnFieldSpecType.PERCENTAGE,
      ...hideForFuture,
    },
    actPMPercentage: {
      title: "ACT PM%",
      decimals: 1,
      type: ColumnFieldSpecType.PERCENTAGE,
      ...hideForFuture,
    },
  },
  PM: {
    period: { title: "Period", decimals: 0 },
    asld: { title: "ASLD", decimals: 0 },
    rbud: { title: "RBUD", decimals: 0 },
    ptd: { title: "PTD", decimals: 0 },
    ytd: { title: "YTD", decimals: 0 },
  },
  PMCurrentYear: {
    period: { title: "Period", decimals: 0 },
    ptd: { title: "PTD", decimals: 0 },
    ytd: { title: "YTD", decimals: 0 },
  },
  EstimatedCommittedVariance: {
    est: { title: "EST", decimals: 0 },
    etc: { title: "ETC", decimals: 0, hideForFuturePeriods: true },
    totalComm: { title: "Total Comm", decimals: 0, hideForFuturePeriods: true },
  },
  VarToPrevEst: {
    nsVar: { title: "NS VAR", decimals: 0 },
    costVar: { title: "COST VAR", decimals: 0 },
  },
  ContingencyVar: {
    period: { title: "Period", decimals: 0 },
    toASLD: { title: "To ASLD", decimals: 0 },
    YTD: { title: "YTD", decimals: 0 },
  },
  Backlog: {
    obl: { title: "OBL", decimals: 0 },
    cost: { title: "Costs", decimals: 0 },
    pm: { title: "PM", decimals: 0 },
    pmPercent: { title: "PM%", decimals: 1, type: ColumnFieldSpecType.PERCENTAGE },
  },
  // Editable column sets
  EstRecognitionPercentEdit: {
    nsPercent: { title: "NS%", decimals: 2, type: ColumnFieldSpecType.PERCENTAGE },
    cogsPercent: { title: "Cogs%", decimals: 2, type: ColumnFieldSpecType.PERCENTAGE },
  },
  RecognitionEdit: {
    ns: { title: "NS", decimals: 5, ...hideForFuture },
    cogs: { title: "Cogs", decimals: 5, ...hideForFuture },
    war: { title: "War", decimals: 5, ...hideForFuture },
  },
  EstRecognitionEdit: {
    ns: { title: "NS", decimals: 5 },
    cogs: { title: "Cogs", decimals: 5 },
    war: { title: "War", decimals: 5 },
    wipCosts: { title: "Wip Costs", decimals: 5 },
  },
  SalesCostsEdit: {
    salesPrice: { title: "Sales Price", decimals: 5 },
    ns: { title: "NS", decimals: 5 },
    cost: { title: "Costs", decimals: 5 },
    cont: { title: "Cont", decimals: 5 },
    war: { title: "War", decimals: 5 },
  },
  InvoicingEdit: {
    sent: { title: "Sent", decimals: 5 },
    paid: { title: "Paid", decimals: 5 },
    due: { title: "Due", decimals: 5 },
  },
  CommittedCostsEdit: {
    act: { title: "Act", decimals: 5, ...hideForFuture },
    hardCom: { title: "Hard Comm", decimals: 5, ...hideForFuture },
    softCom: { title: "Soft Comm", decimals: 5, ...hideForFuture },
  },
  PTDVariancesEdit: {
    netSalesVarianceTotal: { title: "NS", decimals: 5 },
    costVarianceTotal: { title: "Costs", decimals: 5 },
  },
  OrdersReceivedEdit: {
    orPeriod: { title: "Period", decimals: 5 },
    adjOnOR: { title: "Adj on OR", decimals: 5 },
  },
  RatesEdit: {
    erRate: { title: "ER Rate", decimals: 6, type: ColumnFieldSpecType.RATE },
    irRate: { title: "IR Rate", decimals: 6, type: ColumnFieldSpecType.RATE },
  },
};

export const ALL_COLUMNS: ColumnSets = {
  desc: {
    column: { id: "desc", name: "Description" },
    fields: COLUMN_FIELDS.StringValue,
    isStringValue: true,
  },
  org: {
    column: { id: "org", name: "Organisation" },
    fields: COLUMN_FIELDS.StringValue,
    isStringValue: true,
  },
  p_id: {
    column: { id: "p_id", name: "Project Id" },
    fields: COLUMN_FIELDS.StringValue,
    isStringValue: true,
  },
  type: {
    column: { id: "type", name: "Type" },
    fields: COLUMN_FIELDS.StringValue,
    isStringValue: true,
  },
  as_sold: {
    column: { id: "as_sold", name: "As Sold" },
    fields: COLUMN_FIELDS.NetSalesCostsPM,
  },
  budget: {
    column: { id: "budget", name: "Budget" },
    fields: COLUMN_FIELDS.NetSalesCostsPM,
  },
  backlog: {
    column: { id: "backlog", name: "Backlog" },
    fields: COLUMN_FIELDS.Backlog,
  },
  backlog_y: {
    column: { id: "backlog_y", name: "Backlog Y" },
    fields: COLUMN_FIELDS.Backlog,
  },
  backlog_y_1: {
    column: { id: "backlog_y_1", name: "Backlog Y+1" },
    fields: COLUMN_FIELDS.Backlog,
  },
  backlog_y_n: {
    column: { id: "backlog_y_n", name: "Backlog Y+n" },
    fields: COLUMN_FIELDS.Backlog,
  },
  comm_costs: {
    column: { id: "comm_costs", name: "Comm Costs" },
    fields: COLUMN_FIELDS.CommCosts,
  },
  comm_costs_del: {
    column: { id: "comm_costs_del", name: "Comm Costs Del" },
    fields: COLUMN_FIELDS.ActualCostsCommitments,
  },
  comm_period_costs_del: {
    column: { id: "comm_period_costs_del", name: "Comm Period Costs Del" },
    fields: COLUMN_FIELDS.ActualCostsCommitments,
  },
  comm_costs_warr: {
    column: { id: "comm_costs_warr", name: "Comm Costs Warr" },
    fields: COLUMN_FIELDS.ActualCostsCommitments,
  },
  comm_period_costs_warr: {
    column: { id: "comm_period_costs_warr", name: "Comm Period Costs Warr" },
    fields: COLUMN_FIELDS.ActualCostsCommitments,
  },
  contingency: {
    column: { id: "contingency", name: "Contingency" },
    fields: COLUMN_FIELDS.Contingency,
  },
  contingency_var: {
    column: { id: "contingency_var", name: "Contingency Var" },
    fields: COLUMN_FIELDS.ContingencyVar,
  },
  cost_var: {
    column: { id: "cost_var", name: "Cost Var" },
    fields: COLUMN_FIELDS.EstimatedCommittedVariance,
  },
  cost_var_del: {
    column: { id: "cost_var_del", name: "Cost Var Del" },
    fields: COLUMN_FIELDS.EstimatedCommittedVariance,
  },
  cost_var_warr: {
    column: { id: "cost_var_warr", name: "Cost Var Warr" },
    fields: COLUMN_FIELDS.EstimatedCommittedVariance,
  },
  est: {
    column: { id: "est", name: "Estimate" },
    fields: COLUMN_FIELDS.Estimate,
    isEstimate: true,
  },
  est_rec: {
    column: { id: "est_rec", name: "EST REC" },
    fields: COLUMN_FIELDS.EstRec,
  },
  est_rec_percentage: {
    column: { id: "est_rec_percentage", name: "EST REC%" },
    fields: COLUMN_FIELDS.EstRecPercent,
  },
  invoicing_ext: {
    column: { id: "invoicing_ext", name: "Invoicing, ext" },
    fields: COLUMN_FIELDS.InvoicingExt,
  },
  orders_received: {
    column: { id: "orders_received", name: "Orders received" },
    fields: COLUMN_FIELDS.OrdersReceived,
  },
  periodInv: {
    column: { id: "periodInv", name: "Period Inv." },
    fields: COLUMN_FIELDS.Invoicing,
  },
  period_rec: {
    column: { id: "period_rec", name: "Period Rec." },
    fields: COLUMN_FIELDS.PeriodRec,
  },
  pm_impact: {
    column: { id: "pm_impact", name: "PM Impact" },
    fields: COLUMN_FIELDS.PM,
  },
  pm_variance: {
    column: { id: "pm_variance", name: "PM Variance" },
    fields: COLUMN_FIELDS.PM,
  },
  pm_impact_curr_year: {
    column: { id: "pm_impact_curr_year", name: "PM Impact Curr Year" },
    fields: COLUMN_FIELDS.PMCurrentYear,
  },
  prev_est: {
    column: { id: "prev_est", name: "Prev. Est." },
    fields: COLUMN_FIELDS.NetSalesCostsPM,
  },
  prev_rec: {
    column: { id: "prev_rec", name: "Prev. Rec." },
    fields: COLUMN_FIELDS.NetSalesCogsPM,
  },
  rates: {
    column: { id: "rates", name: "Rates" },
    fields: COLUMN_FIELDS.Rates,
  },
  rec: {
    column: { id: "rec", name: "REC %" },
    fields: COLUMN_FIELDS.RecPercent,
  },
  rec_warranty: {
    column: { id: "rec_warranty", name: "Rec warranty" },
    fields: COLUMN_FIELDS.RecWarranty,
  },
  recognition: {
    column: { id: "recognition", name: "Recognition" },
    fields: COLUMN_FIELDS.Recognition,
  },
  revised: {
    column: { id: "revised", name: "Revised" },
    fields: COLUMN_FIELDS.NetSalesCostsPM,
  },
  sales_price: {
    column: { id: "sales_price", name: "Sales Price" },
    fields: COLUMN_FIELDS.SalesPrice,
  },
  var_to_prev_est: {
    column: { id: "var_to_prev_est", name: "Var to prev est" },
    fields: COLUMN_FIELDS.VarToPrevEst,
  },
  warranty: {
    column: { id: "warranty", name: "Warranty" },
    fields: COLUMN_FIELDS.AsSoldBudgetEstimated,
  },
  warr_cost_var: {
    column: { id: "warr_cost_var", name: "Warr Cost Var" },
    fields: COLUMN_FIELDS.WarrCostVar,
  },
  ytd_rec: {
    column: { id: "ytd_rec", name: "YTD Rec" },
    fields: COLUMN_FIELDS.YTDRec,
  },
  ytd_rec_percentage: {
    column: { id: "ytd_rec_percentage", name: "YTD Rec%" },
    fields: COLUMN_FIELDS.YTDRecPercentage,
  },
};

export const RECOGNITION_EDIT_COLUMN_SETS: EditColumnSets = {
  est_rec_percent_edit: {
    column: { id: "est_rec_percent_edit", name: "Est Rec%" },
    fields: COLUMN_FIELDS.EstRecognitionPercentEdit,
  },
  as_sold_edit: {
    column: { id: "as_sold_edit", name: "As Sold" },
    fields: COLUMN_FIELDS.SalesCostsEdit,
  },
  budget_edit: {
    column: { id: "budget_edit", name: "Budget" },
    fields: COLUMN_FIELDS.SalesCostsEdit,
  },
  revised_edit: {
    column: { id: "revised_edit", name: "Revised" },
    fields: COLUMN_FIELDS.SalesCostsEdit,
  },
  est_edit: {
    column: { id: "est_edit", name: "Estimate" },
    fields: COLUMN_FIELDS.SalesCostsEdit,
  },
  est_rec_edit: {
    column: { id: "est_rec_edit", name: "Est Rec" },
    fields: COLUMN_FIELDS.EstRecognitionEdit,
  },
  rec_edit: {
    column: { id: "rec_edit", name: "Rec" },
    fields: COLUMN_FIELDS.RecognitionEdit,
  },
  invoicing_edit: {
    column: { id: "invoicing_edit", name: "Invoicing" },
    fields: COLUMN_FIELDS.InvoicingEdit,
  },
  comm_costs_edit: {
    column: { id: "comm_costs_edit", name: "Comm Costs" },
    fields: COLUMN_FIELDS.CommittedCostsEdit,
  },
  comm_costs_warr_edit: {
    column: { id: "comm_costs_warr_edit", name: "Comm Costs Warr" },
    fields: COLUMN_FIELDS.CommittedCostsEdit,
  },
  ptd_variances_edit: {
    column: { id: "ptd_variances_edit", name: "PTD Variances" },
    fields: COLUMN_FIELDS.PTDVariancesEdit,
  },
  orders_received_edit: {
    column: { id: "orders_received_edit", name: "Orders Received" },
    fields: COLUMN_FIELDS.OrdersReceivedEdit,
  },
  rates_edit: {
    column: { id: "rates_edit", name: "Rates" },
    fields: COLUMN_FIELDS.RatesEdit,
  },
};

function selectFields(fields: ColumnFields, selectedFields: string[]): ColumnFields {
  const newFields: { [key: string]: ColumnFieldSpec } = {};
  for (const i in selectedFields) {
    const key = selectedFields[i];
    if (!Object.prototype.hasOwnProperty.call(fields, key)) {
      throw new Error(`${fields} does not contain ${key}`);
    }
    newFields[key] = fields[key];
  }
  return newFields;
}

const NSCostsMassFreeze = selectFields(COLUMN_FIELDS.SalesCostsEdit, ["salesPrice", "ns", "cost", "cont", "war"]);

export const RECOGNITION_MASS_FREEZE_COLUMN_SETS: { [key: string]: EditColumnSpec } = {
  as_sold_edit: {
    column: { id: "as_sold_edit", name: "As Sold" },
    fields: NSCostsMassFreeze,
  },
  budget_edit: {
    column: { id: "budget_edit", name: "Budget" },
    fields: NSCostsMassFreeze,
  },
  revised_edit: {
    column: { id: "revised_edit", name: "Revised" },
    fields: NSCostsMassFreeze,
  },
  est_edit: {
    column: { id: "est_edit", name: "Estimate" },
    fields: NSCostsMassFreeze,
  },
  est_rec_edit: {
    column: { id: "est_rec_edit", name: "Est Rec" },
    fields: selectFields(COLUMN_FIELDS.EstRecognitionEdit, ["ns", "cogs", "war"]),
  },
  rec_edit: {
    column: { id: "rec_edit", name: "Rec" },
    fields: COLUMN_FIELDS.RecognitionEdit,
  },
  invoicing_edit: {
    column: { id: "invoicing_edit", name: "Invoicing" },
    fields: COLUMN_FIELDS.InvoicingEdit,
  },
  comm_costs_edit: {
    column: { id: "comm_costs_edit", name: "Comm Costs" },
    fields: COLUMN_FIELDS.CommittedCostsEdit,
  },
  comm_costs_warr_edit: {
    column: { id: "comm_costs_warr_edit", name: "Comm Costs Warr" },
    fields: COLUMN_FIELDS.CommittedCostsEdit,
  },
};

export const RECOGNITION_MASS_FREEZE_COLUMNS: EditColumnSpec[] = values(RECOGNITION_MASS_FREEZE_COLUMN_SETS);

export const AVAILABLE_COLUMNS: Column[] = values(ALL_COLUMNS).map(x => x.column);

// All columns except string typed columns:
// p_id (project ID), type (project type), desc (description), org (organisation).
export const AVAILABLE_RECOGNITION_COLUMNS: Column[] = [
  ALL_COLUMNS.as_sold,
  ALL_COLUMNS.budget,
  ALL_COLUMNS.backlog,
  ALL_COLUMNS.backlog_y,
  ALL_COLUMNS.backlog_y_1,
  ALL_COLUMNS.backlog_y_n,
  ALL_COLUMNS.comm_costs,
  ALL_COLUMNS.comm_costs_del,
  ALL_COLUMNS.comm_period_costs_del,
  ALL_COLUMNS.comm_period_costs_warr,
  ALL_COLUMNS.comm_costs_warr,
  ALL_COLUMNS.contingency,
  ALL_COLUMNS.contingency_var,
  ALL_COLUMNS.cost_var,
  ALL_COLUMNS.cost_var_del,
  ALL_COLUMNS.cost_var_warr,
  ALL_COLUMNS.est,
  ALL_COLUMNS.est_rec,
  ALL_COLUMNS.est_rec_percentage,
  ALL_COLUMNS.invoicing_ext,
  ALL_COLUMNS.orders_received,
  ALL_COLUMNS.periodInv,
  ALL_COLUMNS.period_rec,
  ALL_COLUMNS.pm_impact,
  ALL_COLUMNS.pm_variance,
  ALL_COLUMNS.pm_impact_curr_year,
  ALL_COLUMNS.prev_est,
  ALL_COLUMNS.prev_rec,
  ALL_COLUMNS.rates,
  ALL_COLUMNS.recognition,
  ALL_COLUMNS.rec,
  ALL_COLUMNS.rec_warranty,
  ALL_COLUMNS.revised,
  ALL_COLUMNS.sales_price,
  ALL_COLUMNS.var_to_prev_est,
  ALL_COLUMNS.warranty,
  ALL_COLUMNS.warr_cost_var,
  ALL_COLUMNS.ytd_rec,
  ALL_COLUMNS.ytd_rec_percentage,
].map(x => x.column);

export const DEFAULT_RECOGNITION_COLUMN_SETS: { [key: string]: ColumnSet } = {
  RECOGNITIONS: {
    id: "recognitions",
    description: "Recognitions",
    columns: [
      ALL_COLUMNS.rec,
      ALL_COLUMNS.recognition,
      ALL_COLUMNS.est_rec_percentage,
      ALL_COLUMNS.est_rec,
      ALL_COLUMNS.as_sold,
      ALL_COLUMNS.est,
      ALL_COLUMNS.backlog,
      ALL_COLUMNS.orders_received,
      ALL_COLUMNS.comm_costs,
      ALL_COLUMNS.period_rec,
    ].map(x => x.column),
  },
  BASIC: {
    id: "basic",
    description: "Basic",
    columns: [
      ALL_COLUMNS.as_sold,
      ALL_COLUMNS.revised,
      ALL_COLUMNS.est,
      ALL_COLUMNS.contingency,
      ALL_COLUMNS.warranty,
      ALL_COLUMNS.comm_costs,
      ALL_COLUMNS.invoicing_ext,
      ALL_COLUMNS.est_rec_percentage,
      ALL_COLUMNS.rec,
      ALL_COLUMNS.pm_variance,
      ALL_COLUMNS.cost_var_del,
      ALL_COLUMNS.cost_var_warr,
    ].map(x => x.column),
  },
};

const projectListingColumns: (Column | GeneratedColumn)[] = [
  ALL_COLUMNS.p_id,
  ALL_COLUMNS.type,
  ALL_COLUMNS.desc,
  ALL_COLUMNS.org,
].map(x => x.column);

export const DEFAULT_LISTING_COLUMN_SETS: { [key: string]: ColumnSet } = {
  RECOGNITIONS: {
    ...DEFAULT_RECOGNITION_COLUMN_SETS.RECOGNITIONS,
    columns: projectListingColumns.concat(DEFAULT_RECOGNITION_COLUMN_SETS.RECOGNITIONS.columns),
  },
  BASIC: {
    ...DEFAULT_RECOGNITION_COLUMN_SETS.BASIC,
    columns: projectListingColumns.concat(DEFAULT_RECOGNITION_COLUMN_SETS.BASIC.columns),
  },
};

export const ALL_DEFAULT_LISTING_COLUMN_SETS: ColumnSet[] = values(DEFAULT_LISTING_COLUMN_SETS);
export const ALL_DEFAULT_RECOGNITION_COLUMN_SETS: ColumnSet[] = values(DEFAULT_RECOGNITION_COLUMN_SETS);

export const HIERARCHY_COLUMN: GeneratedColumn = {
  id: "hierarchy",
  name: "Hierarchy",
};
