import React, { useState, useEffect, useRef } from "react";

import styled from "styled-components";
import { useQuery } from "@apollo/client/react/hooks";
import { SearchProjectData, SearchProject } from "../../../common/types";
import SearchDropdown from "./SearchDropdown";
import { withRouter, RouteComponentProps } from "react-router";
import { History } from "history";
import routes from "../../../common/routes";
import { orderBy } from "lodash";
import { gql } from "@apollo/client";

const SEARCH_PROJECTS = gql`
  query SearchProjects($searchQuery: [String!]!) {
    searchProjects(searchQuery: $searchQuery) {
      projects {
        id
        description
      }
      moreResultsAvailable
    }
  }
`;

const resetSearch = (
  setSearchInput: React.Dispatch<React.SetStateAction<string>>,
  setSearchData: React.Dispatch<React.SetStateAction<SearchProjectData | undefined>>
) => {
  setSearchInput("");
  setSearchData(undefined);
};

const onResultSelected = (searchProject: SearchProject, history: History) => {
  history.push(`${routes.PROJECT}/${searchProject.id}`);
};

// Regular expression used for splitting the search input into terms. Should match with data layer definition.
// Space and special characters - _ ; , . are used to split the "search words" in the data layer.
const splitRe = /[ \-_;,.]/;

function Search(props: RouteComponentProps) {
  const [searchInput, setSearchInput] = useState("");
  const [searchInputTerms, setSearchInputTerms] = useState<string[]>([]);
  const [searchData, setSearchData] = useState<SearchProjectData | undefined>(undefined);
  const history = props.history;
  const timeout = useRef<number>();

  const { data, loading } = useQuery<SearchProjectData>(SEARCH_PROJECTS, {
    variables: {
      searchQuery: searchInputTerms,
    },
    skip: searchInputTerms.length === 0 || searchInputTerms === [""],
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (data !== undefined) {
      setSearchData(data);
      console.log("Data received for", searchInputTerms, data && data.searchProjects);
    } else {
      setSearchInputTerms(searchInputTerms.concat([]));
    }
  }, [data, setSearchData]);

  useEffect(() => {
    const terms = searchInput
      .trim()
      .split(splitRe)
      .filter(term => term.trim().length > 0);
    if (terms.length !== searchInputTerms.length || !terms.every((term, index) => searchInputTerms[index] === term)) {
      clearTimeout(timeout.current);
      timeout.current = setTimeout(() => {
        setSearchInputTerms(terms);
      }, 1000);
    }
    return () => clearTimeout(timeout.current);
  }, [searchInput, searchInputTerms, setSearchInputTerms]);

  return (
    <Container>
      <SearchInput placeholder="Search" value={searchInput} onChange={e => setSearchInput(e.target.value)} />
      {(searchData || loading) && (
        <SearchDropdown
          searchProjects={searchData ? orderBy(searchData.searchProjects.projects, ["id"], ["desc"]) : []}
          resetSearch={() => resetSearch(setSearchInput, setSearchData)}
          onResultSelected={project => onResultSelected(project, history)}
          moreAvailable={!!searchData && searchData.searchProjects.moreResultsAvailable}
          loading={loading}
        />
      )}
    </Container>
  );
}

export default withRouter(Search);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 0.5;
  margin-right: 20px;
`;

const SearchInput = styled.input`
  display: block;
  background-color: transparent;
  outline: none;
  border: none;
  color: white;
  font-size: 18px;
  min-width: 75px;
  width: 100%;

  padding: 4px;
  border-radius: 3px;

  &:focus {
    background-color: #686968;
  }

  ::placeholder {
    /* Firefox, Chrome, Opera */
    color: white;
    opacity: 1;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: white;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: white;
  }
`;
