import { ProjectDates, ProjectDatesVisibility, ProjectId } from "../../../../../common/types";
import CreateProjectSection from "../CreateProjectSection";
import EditDetails from "./EditDetails";
import React from "react";
import styled from "styled-components";
import { defaultGrey } from "../../../../../common/colors";

interface DatesCreateProjectSectionProps {
  projectId: ProjectId;
  sectionDetails?: ProjectDates;
  sectionVisibility: ProjectDatesVisibility;
}

function DatesCreateProjectSection(props: DatesCreateProjectSectionProps) {
  const { projectId, sectionDetails, sectionVisibility } = props;

  return (
    <CreateProjectSection header="Dates" loadingErrors={sectionDetails ? undefined : ["No data found for dates."]}>
      <Container>
        {sectionDetails && (
          <EditDetails projectId={projectId} dates={sectionDetails} datesVisibility={sectionVisibility} />
        )}
      </Container>
    </CreateProjectSection>
  );
}

export default DatesCreateProjectSection;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-bottom: 0px;
  color: ${defaultGrey};
`;
