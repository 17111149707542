import { LogActionType } from "../../../../common/types";
import { filter } from "lodash";
import { format } from "date-fns";
import { ParsedLogEvent, ParsedMonthlyLogs, ParsedYearlyLogs, StatusMessage } from "./util";
import MonthlyLogs from "./MonthlyLogs";
import React, { useState } from "react";
import LogsList from "./LogsList";
import { TabContainer } from "../../../../common/components";

interface YearlyLogsProps {
  selectedYear: string | null;
  logs: ParsedYearlyLogs;
  filterInput: string;
  hideScheduledLoadingLogs: boolean;
}

const filterLogs = (
  projectLogs: ParsedLogEvent[],
  { filterInput, hideScheduledLoadingLogs }: { filterInput: string; hideScheduledLoadingLogs: boolean }
): ParsedLogEvent[] => {
  const isScheduledLoading = (item: ParsedLogEvent): boolean =>
    [LogActionType.ScheduledLoading, LogActionType.CalculationEngineAction].includes(item.actionTypeId);

  const filterInputLowerCase = filterInput.toLowerCase();

  return filter(
    projectLogs,
    item =>
      !(hideScheduledLoadingLogs && isScheduledLoading(item)) &&
      (filterInput.length === 0 ||
        item.messageString.toLowerCase().includes(filterInputLowerCase) ||
        (item.user && item.user.toLowerCase().includes(filterInputLowerCase)) ||
        format(new Date(item.logDateTime), "dd.MM.yyyy HH:mm").includes(filterInputLowerCase))
  );
};

const initAreOpen = (length: number) => {
  if (length == 0) return [];
  const areOpen = [true];
  for (let i = 0; i < length - 1; i++) {
    areOpen.push(false);
  }
  return areOpen;
};

function YearlyLogs(props: YearlyLogsProps) {
  const { selectedYear, logs, filterInput, hideScheduledLoadingLogs } = props;
  const [areOpen, setAreOpen] = useState<boolean[]>(initAreOpen(logs.subGroups.length));
  const showThisContent = logs.period == selectedYear;
  const updateAreOpen = (newValue: boolean, index: number) =>
    setAreOpen(prevState => {
      const state = prevState.map((value, i) => {
        if (i == index) return newValue;
        else return value;
      });
      return state;
    });
  const filteredMonthly: ParsedMonthlyLogs[] = logs.subGroups.map(sub => {
    return {
      period: sub.period,
      logs: filterLogs(sub.logs, { filterInput, hideScheduledLoadingLogs }),
    };
  });

  const filteredData = filteredMonthly.flatMap(month => month.logs);
  if (logs.subGroups.length == 0) return <StatusMessage>No logs available for this year.</StatusMessage>;
  else if (filterInput.length > 0 && (!filteredData || filteredData.length === 0))
    return <StatusMessage>No changelog events.</StatusMessage>;
  else if (filterInput.length > 0 && filteredData && filteredData.length > 0)
    return (
      <TabContainer show={showThisContent}>
        <LogsList logEvents={filteredData} />
      </TabContainer>
    );
  return (
    <TabContainer show={showThisContent}>
      {filteredMonthly.map((subgroup, index) => (
        <MonthlyLogs
          key={index}
          logs={subgroup}
          isOpen={areOpen[index]}
          setIsOpen={value => updateAreOpen(value, index)}
        />
      ))}
    </TabContainer>
  );
}

export default YearlyLogs;
