import { ProjectId } from "../common/types";
import { EditableColumnId } from "../common/columnsTypes";
import {
  ProjectRecognitionsEditResultData,
  ProjectRecognitionsResult,
  ProjectRecognitionWaitingForReady,
  RecognitionEditOptions,
  RecognitionEditType,
} from "../components/sites/Project/ProjectRecognitions/types";
import { parsePeriod, Period } from "../common/period";
import { RecPlanEstRecEditModes } from "../components/sites/Project/ProjectRecognitions/recognitionsEditingSetting";

export const START_RECOGNITIONS_EDITING = "START_RECOGNITIONS_EDITING";
export const END_RECOGNITIONS_EDITING = "END_RECOGNITIONS_EDITING";
export const INITIALIZE_RECOGNITIONS_EDIT_TYPE = "INITIALIZE_RECOGNITIONS_EDIT_TYPE";
export const INITIALIZE_RECOGNITIONS_EDIT_STATE = "INITIALIZE_RECOGNITIONS_EDIT_STATE";
export const RECOGNITIONS_EDIT_CHANGE = "RECOGNITIONS_EDIT_CHANGE";
export const RECOGNITIONS_EDIT_CALCULATE = "RECOGNITIONS_EDIT_CALCULATE";
export const RECOGNITIONS_EDIT_COPY_CURRENT = "RECOGNITIONS_EDIT_COPY_CURRENT";
export const RECOGNITIONS_ADD_FREEZING = "RECOGNITIONS_ADD_FREEZING";
export const RECOGNITIONS_REMOVE_FREEZING = "RECOGNITIONS_REMOVE_FREEZING";
export const RECOGNITIONS_HAS_ERROR = "RECOGNITIONS_HAS_ERROR";
export const RECOGNITIONS_COPY_EST_REC_NS_TO_COGS = "RECOGNITIONS_COPY_EST_REC_NS_TO_COGS";
export const START_RECOGNITIONS_MASS_FREEZE = "START_RECOGNITIONS_MASS_FREEZE";
export const END_RECOGNITIONS_MASS_FREEZE = "END_RECOGNITIONS_MASS_FREEZE";
export const CHANGE_RECOGNITIONS_WAITING_FOR_READY = "CHANGE_RECOGNITIONS_WAITING_FOR_READY";
export const INITIALIZE_RECOGNITIONS_WAITING_FOR_READY = "INITIALIZE_RECOGNITIONS_WAITING_FOR_READY";

export type RecognitionsAction =
  | RecognitionsDefaultAction
  | InitializeRecognitionsEditType
  | InitializeRecognitionsEditState
  | RecognitionsEditChange
  | RecognitionsEditCopyCurrent
  | RecognitionsAddFreezing
  | RecognitionsRemoveFreezing
  | RecognitionsHasError
  | CopyEstRecNsToCogs
  | StartRecognitionsMassFreeze
  | ChangeRecognitionsWaitingForReady
  | InitializeRecognitionsWaitingForReady
  | RecognitionsEditCalculate;

export type RecognitionsDefaultAction = {
  type: "START_RECOGNITIONS_EDITING" | "END_RECOGNITIONS_EDITING" | "END_RECOGNITIONS_MASS_FREEZE";
};

export type RecognitionsEditCalculate = {
  type: "RECOGNITIONS_EDIT_CALCULATE";
  estRecEditMode: RecPlanEstRecEditModes;
};

export type StartRecognitionsMassFreeze = {
  type: "START_RECOGNITIONS_MASS_FREEZE";
  fromPeriod: Period | null;
  toPeriod: Period | null;
};

export type InitializeRecognitionsEditType = {
  type: "INITIALIZE_RECOGNITIONS_EDIT_TYPE";
  editType: RecognitionEditType;
};

export type InitializeRecognitionsEditState = {
  type: "INITIALIZE_RECOGNITIONS_EDIT_STATE";
  recognitions: ProjectRecognitionsResult | null;
  options: RecognitionEditOptions;
};

export type InitializeRecognitionsWaitingForReady = {
  type: "INITIALIZE_RECOGNITIONS_WAITING_FOR_READY";
  projectId: ProjectId | null;
};

export interface RecognitionsEditChange {
  type: "RECOGNITIONS_EDIT_CHANGE";
  period: Period;
  columnId: EditableColumnId;
  field: string;
  value: number;
}

export interface RecognitionsEditCopyCurrent {
  type: "RECOGNITIONS_EDIT_COPY_CURRENT";
  period: Period;
  columnId: EditableColumnId;
  field: string;
  value: number;
  estRecEditMode: RecPlanEstRecEditModes;
}

export interface RecognitionsAddFreezing {
  type: "RECOGNITIONS_ADD_FREEZING";
  period: Period;
  columnId: EditableColumnId;
  field: string;
  value: number;
  freezingComment: string;
  estRecEditMode: RecPlanEstRecEditModes;
}

export interface RecognitionsRemoveFreezing {
  type: "RECOGNITIONS_REMOVE_FREEZING";
  period: Period;
  columnId: EditableColumnId;
  field: string;
}

export type ValueChange = {
  period: Period;
  columnId: EditableColumnId;
  field: string;
  value?: number;
  freezingComment?: string;
};

export type RecognitionsHasError = {
  type: "RECOGNITIONS_HAS_ERROR";
  id: string;
  hasError: boolean;
};

export type CopyEstRecNsToCogs = {
  type: "RECOGNITIONS_COPY_EST_REC_NS_TO_COGS";
  periods: Period[];
  estRecEditMode: RecPlanEstRecEditModes;
};

export type ChangeRecognitionsWaitingForReady = {
  type: "CHANGE_RECOGNITIONS_WAITING_FOR_READY";
  projectId: ProjectId;
  waitForReady: ProjectRecognitionWaitingForReady | null;
};

export const startRecognitionsEditing = { type: START_RECOGNITIONS_EDITING };

export const endRecognitionsEditing = { type: END_RECOGNITIONS_EDITING };

export const recognitionsEditCalculate = (estRecEditMode: RecPlanEstRecEditModes): RecognitionsEditCalculate => ({
  type: RECOGNITIONS_EDIT_CALCULATE,
  estRecEditMode: estRecEditMode,
});

export const initializeRecognitionsEditType = (editType: RecognitionEditType): InitializeRecognitionsEditType => ({
  type: "INITIALIZE_RECOGNITIONS_EDIT_TYPE",
  editType,
});

export const initializeRecognitionsEditState = (
  recognitions: ProjectRecognitionsEditResultData
): InitializeRecognitionsEditState => {
  const info = recognitions.projectRecognitionsEditInformation;
  return {
    type: INITIALIZE_RECOGNITIONS_EDIT_STATE,
    recognitions: recognitions.projectRecognitions,
    options: {
      editProjectType: info ? info.editType : RecognitionEditType.Manual,
      asSoldPeriod: info && info.asSoldPeriod ? parsePeriod(info.asSoldPeriod) : null,
      budgetPeriod: info && info.budgetPeriod ? parsePeriod(info.budgetPeriod) : null,
      oblPeriod: info && info.oblPeriod ? parsePeriod(info.oblPeriod) : null,
      recCompletionPeriod: info && info.recCompletionPeriod ? parsePeriod(info.recCompletionPeriod) : null,
      migratedFromLegacySystem: info ? info.migratedFromLegacySystem : false,
      additionalAdjustments: info ? info.additionalAdjustments : false,
      editablePcsProject: info ? info.editablePcsProject : false,
      revenueMethodIsStraightLine: info ? info.revenueMethodIsStraightLine : false,
      isPTProjectWithEstRecFromRelated: info ? info.isPTProjectWithEstRecFromRelated : false,
    },
  };
};

export const initializeRecognitionsWaitingForReady = (
  projectId: ProjectId | null
): InitializeRecognitionsWaitingForReady => ({
  type: "INITIALIZE_RECOGNITIONS_WAITING_FOR_READY",
  projectId,
});

export const recognitionsEditChange = (
  period: Period,
  columnId: EditableColumnId,
  field: string,
  value: number
): RecognitionsEditChange => ({ type: RECOGNITIONS_EDIT_CHANGE, period, columnId, field, value });

export const recognitionsCopyCurrent = (
  period: Period,
  columnId: EditableColumnId,
  field: string,
  value: number,
  estRecEditMode: RecPlanEstRecEditModes
): RecognitionsEditCopyCurrent => ({
  type: RECOGNITIONS_EDIT_COPY_CURRENT,
  period,
  columnId,
  field,
  value,
  estRecEditMode,
});

export const recognitionsAddFreezing = (
  period: Period,
  columnId: EditableColumnId,
  field: string,
  value: number,
  freezingComment: string,
  estRecEditMode: RecPlanEstRecEditModes
): RecognitionsAddFreezing => ({
  type: RECOGNITIONS_ADD_FREEZING,
  period,
  columnId,
  field,
  value,
  freezingComment,
  estRecEditMode,
});

export const recognitionsRemoveFreezing = (
  period: Period,
  columnId: EditableColumnId,
  field: string
): RecognitionsRemoveFreezing => ({ type: RECOGNITIONS_REMOVE_FREEZING, period, columnId, field });

export const recognitionsHasError = (id: string, hasError: boolean): RecognitionsHasError => ({
  type: RECOGNITIONS_HAS_ERROR,
  id,
  hasError,
});

export const copyEstRecNsToCogs = (periods: Period[], estRecEditMode: RecPlanEstRecEditModes): CopyEstRecNsToCogs => ({
  type: RECOGNITIONS_COPY_EST_REC_NS_TO_COGS,
  periods,
  estRecEditMode: estRecEditMode,
});

export const startRecognitionsMassFreeze = (
  fromPeriod: Period | null,
  toPeriod: Period | null
): StartRecognitionsMassFreeze => ({
  type: START_RECOGNITIONS_MASS_FREEZE,
  fromPeriod,
  toPeriod,
});

export const endRecognitionsMassFreeze = (): RecognitionsDefaultAction => ({
  type: END_RECOGNITIONS_MASS_FREEZE,
});

export const changeRecognitionsWaitingForReady = (
  projectId: ProjectId,
  waitForReady: ProjectRecognitionWaitingForReady | null
): ChangeRecognitionsWaitingForReady => ({
  type: "CHANGE_RECOGNITIONS_WAITING_FOR_READY",
  projectId: projectId,
  waitForReady: waitForReady,
});
