import { History } from "history";
import { find } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import styled from "styled-components";
import { faChevronDown, faChevronUp, faSort } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  defaultGrey,
  valmetDarkGreyTable,
  valmetGreyBorder,
  valmetGreyId,
  valmetGreyLight,
  valmetGreyLightTable,
  valmetGreyTable,
} from "../../../../../common/colors";
import { LISTING_NODE_TYPE } from "../../../../../common/constants";
import { HIERARCHY_COLUMN } from "../../../../../common/columns";
import routes from "../../../../../common/routes";
import { ListingNode, NodeId, ProjectData, ProjectId, ProjectListItem, ProjectType } from "../../../../../common/types";
import { RecognitionEditType, ListingRecognitionsData } from "../../../Project/ProjectRecognitions/types";
import TableDataCell from "./../../../../TableDataCell";
import { TableHeaderCell } from "../../../../TableDataCell/TableDataCell";
import { Column, GeneratedColumn, StringValue, ColumnId, GeneratedColumnId } from "../../../../../common/columnsTypes";

type ProjectsTableProps = RouteComponentProps & {
  dataTypes: (Column | GeneratedColumn)[];
  projects: ListingNode[];
  projectTypes: ProjectType[];
  recLoading: boolean;
  recognitionsData: ListingRecognitionsData;
};

type State<T> = { value: T; setValue: (value: T) => void };

interface HierarchyState {
  visibleProjects: State<Set<ProjectId>>;
  visibleNodes: State<Set<NodeId>>;
  openNodes: State<Set<NodeId>>;
}

const renderSorting = (): React.ReactElement => {
  return (
    <button type="button" onClick={() => console.log("SORT")}>
      <FontAwesomeIcon icon={faSort} size="1x" />
    </button>
  );
};

const addChildrenToVisible = (hierarchyState: HierarchyState, node: ListingNode) => {
  const visibleProjects = new Set(hierarchyState.visibleProjects.value);
  const visibleNodes = new Set(hierarchyState.visibleNodes.value);

  node.childProjects.forEach(p => visibleProjects.add(p.projectId));
  node.childNodes.forEach(n => visibleNodes.add(n.nodeId));

  hierarchyState.visibleProjects.setValue(visibleProjects);
  hierarchyState.visibleNodes.setValue(visibleNodes);
};

const removeChildrenFromVisible = (hierarchyState: HierarchyState, node: ListingNode) => {
  const visibleProjects = new Set(hierarchyState.visibleProjects.value);
  const visibleNodes = new Set(hierarchyState.visibleNodes.value);

  const removeRecursively = (node: ListingNode) => {
    node.childProjects.forEach(p => visibleProjects.delete(p.projectId));
    node.childNodes.forEach(n => visibleNodes.delete(n.nodeId));
    node.childNodes.forEach(removeRecursively);
  };

  removeRecursively(node);

  hierarchyState.visibleProjects.setValue(visibleProjects);
  hierarchyState.visibleNodes.setValue(visibleNodes);
};

const addNodeToOpenSet = (hierarchyState: HierarchyState, node: ListingNode) => {
  const openNodes = new Set(hierarchyState.openNodes.value);
  openNodes.add(node.nodeId);
  hierarchyState.openNodes.setValue(openNodes);
};

const removeNodesFromOpenSet = (hierarchyState: HierarchyState, node: ListingNode) => {
  const openNodes = new Set(hierarchyState.openNodes.value);

  const removeRecursively = (node: ListingNode) => {
    openNodes.delete(node.nodeId);
    node.childNodes.forEach(removeRecursively);
  };

  removeRecursively(node);

  hierarchyState.openNodes.setValue(openNodes);
};

const closeNodeHierarchy = (node: ListingNode, hierarchyState: HierarchyState) => {
  removeChildrenFromVisible(hierarchyState, node);
  removeNodesFromOpenSet(hierarchyState, node);
};

const openNodeHierarchy = (node: ListingNode, hierarchyState: HierarchyState) => {
  addNodeToOpenSet(hierarchyState, node);
  addChildrenToVisible(hierarchyState, node);
};

const nodeHasChildren = (node: ListingNode) => {
  return (node.childNodes && node.childNodes.length > 0) || (node.childProjects && node.childProjects.length > 0);
};

const getIcon = (node: ListingNode, index: number, openNodes: Set<NodeId>, id: string) => {
  if (index === 0 && id === HIERARCHY_COLUMN.id) {
    if (nodeHasChildren(node)) {
      return openNodes.has(node.nodeId) ? faChevronUp : faChevronDown;
    }
  } else {
    return undefined;
  }
};

const toggleNode = (node: ListingNode, hierarchyState: HierarchyState) => {
  if (hierarchyState.openNodes.value.has(node.nodeId)) {
    closeNodeHierarchy(node, hierarchyState);
  } else {
    openNodeHierarchy(node, hierarchyState);
  }
};

const isItemVisible = (item: ProjectData | ListingNode, hierarchyState: HierarchyState) => {
  if (item.__typename === LISTING_NODE_TYPE) {
    const node = item as ListingNode;
    return hierarchyState.visibleNodes.value.has(node.nodeId);
  } else {
    const project = item as ProjectData;
    return hierarchyState.visibleProjects.value.has(project.projectId);
  }
};

const renderDataRow = (
  item: ProjectData | ListingNode,
  index: number,
  columnTypes: (Column | GeneratedColumn)[],
  basicColumnTypes: (Column | GeneratedColumn)[],
  recColumnTypes: Column[],
  renderedItems: ProjectListItem[],
  hierarchyState: HierarchyState,
  history: History,
  recognitionsData: ListingRecognitionsData,
  recLoading: boolean
) => {
  if (!isItemVisible(item, hierarchyState)) return undefined;

  if (item.__typename === LISTING_NODE_TYPE) {
    const node = item as ListingNode;
    const recData = recognitionsData.nodes.get(node.nodeId);
    return (
      <DataRow
        key={index.toString() + item.key}
        childLevel={item.childLevel}
        onDoubleClick={() => history.push(`${routes.NODE}/${(item as ListingNode).nodeId}`)}
      >
        <Margin />
        <TableHeaderCell
          columnId={HIERARCHY_COLUMN.id}
          value={node.description}
          icon={getIcon(node, 0, hierarchyState.openNodes.value, HIERARCHY_COLUMN.id)}
          onIconClick={() => toggleNode(node, hierarchyState)}
          extraPadding={!nodeHasChildren(node)}
        />
        {basicColumnTypes.map(columnType => (
          <EmptyCell key={columnType.id} />
        ))}
        <BoundaryCell />
        {recColumnTypes.map(columnType => {
          const data = recData && recData.columns.get(columnType.id);
          return data ? (
            <TableDataCell
              key={columnType.id}
              dataEntity={data}
              editType={RecognitionEditType.Manual}
              isFuturePeriod={false}
              commentTypes={[]}
            />
          ) : (
            <EmptyCell key={columnType.id}>{recLoading ? "Loading..." : "No Data"}</EmptyCell>
          );
        })}
        <Margin />
      </DataRow>
    );
  } else {
    const project = item as ProjectData;
    const recData = recognitionsData.projects.get(project.projectId);
    const descColumn = find(project.columns, column => column.columnId === "desc");
    const descValue = descColumn && (descColumn.value as StringValue);
    return (
      <DataRow
        key={item.key}
        childLevel={item.childLevel}
        onDoubleClick={() => history.push(`${routes.PROJECT}/${(item as ProjectData).projectId}`)}
      >
        <Margin />
        <TableHeaderCell
          columnId={HIERARCHY_COLUMN.id}
          value={`${project.projectId} ${descValue ? descValue.value : ""}`}
          extraPadding={true}
        />
        {basicColumnTypes.map(columnType => {
          const data = find(item.columns, column => column.columnId === columnType.id);
          return data ? (
            <TableDataCell
              key={columnType.id}
              dataEntity={data}
              editType={RecognitionEditType.Manual}
              isFuturePeriod={false}
              commentTypes={[]}
            />
          ) : (
            <EmptyCell key={columnType.id}>{recLoading ? "Loading..." : "No data"}</EmptyCell>
          );
        })}
        <BoundaryCell />
        {recColumnTypes.map(columnType => {
          const data = recData && recData.columns.get(columnType.id);
          return data ? (
            <TableDataCell
              key={columnType.id}
              dataEntity={data}
              editType={RecognitionEditType.Manual}
              isFuturePeriod={false}
              commentTypes={[]}
            />
          ) : (
            <EmptyCell key={columnType.id}>{recLoading ? "Loading..." : "No Data"}</EmptyCell>
          );
        })}
        <Margin />
      </DataRow>
    );
  }
};

const setProjectLevelAndKey = (level: number, projects: ProjectData[]): ProjectData[] => {
  return projects.map(project => ({ ...project, childLevel: level, key: "p" + project.projectId }));
};

const flattenNodes = (level: number, nodes: ListingNode[]): (ListingNode | ProjectData)[] => {
  return nodes.flatMap(node => {
    const flattenedChildren = flattenNodes(level + 1, node.childNodes);
    const childListingProjects = setProjectLevelAndKey(level + 1, node.childProjects);
    const listingNode: ListingNode = {
      ...node,
      key: "n" + node.nodeId,
      childLevel: level,
    };
    return ([listingNode] as (ListingNode | ProjectData)[]).concat(childListingProjects, flattenedChildren);
  });
};

function useState_<T>(initialState: T): State<T> {
  const [value, setValue] = useState<T>(initialState);
  return { value, setValue };
}

function useHierarchyState(projects: ListingNode[]) {
  const [visibleNodes, setVisibleNodes] = useState<Set<NodeId>>(new Set());
  const hierarchyState: HierarchyState = {
    visibleProjects: useState_<Set<ProjectId>>(new Set()),
    visibleNodes: { setValue: setVisibleNodes, value: visibleNodes },
    openNodes: useState_<Set<NodeId>>(new Set()),
  };

  useEffect(() => {
    const newVisibleNodes = new Set(projects.map(n => n.nodeId));
    setVisibleNodes(newVisibleNodes);
  }, [projects, setVisibleNodes]);

  return hierarchyState;
}

function ProjectsTable(props: ProjectsTableProps): React.ReactElement {
  const { history, dataTypes, projects, recognitionsData, recLoading } = props;
  const hierarchyState = useHierarchyState(projects);

  const renderedItems: ProjectListItem[] = useMemo(() => flattenNodes(0, projects), [projects]);

  const basicColumnIds: (ColumnId | GeneratedColumnId)[] = ["p_id", "type", "desc", "org"];
  const basicDataTypes = dataTypes.filter(c => basicColumnIds.includes(c.id));
  const recDataTypes = dataTypes.filter(c => !basicColumnIds.includes(c.id) && c.id !== "hierarchy") as Column[];

  return (
    <Container>
      <ConfigurableTable>
        <TableBody>
          <HeaderRow>
            <Margin />
            <HeaderCell key={HIERARCHY_COLUMN.id} columnId={HIERARCHY_COLUMN.id}>
              {HIERARCHY_COLUMN.name}
            </HeaderCell>
            {(basicDataTypes as Column[]).map(dataType => (
              <HeaderCell key={dataType.id} columnId={dataType.id}>
                {dataType.name} {false && renderSorting()}
              </HeaderCell>
            ))}
            <BoundaryHeaderCell />
            {(recDataTypes as Column[]).map(dataType => (
              <HeaderCell key={dataType.id} columnId={dataType.id}>
                {dataType.name} {false && renderSorting()}
              </HeaderCell>
            ))}
            <Margin />
          </HeaderRow>
          {renderedItems.map((project, index) =>
            renderDataRow(
              project,
              index,
              dataTypes,
              basicDataTypes,
              recDataTypes,
              renderedItems,
              hierarchyState,
              history,
              recognitionsData,
              recLoading
            )
          )}
        </TableBody>
      </ConfigurableTable>
    </Container>
  );
}

export default withRouter(ProjectsTable);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const ConfigurableTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  tr:nth-child(1) {
    background-color: ${valmetGreyTable};
  }
`;

const Margin = styled.th`
  width: 20px;
`;

const TableBody = styled.tbody`
  tr:last-child {
    border-bottom: 1px solid ${valmetGreyBorder};
  }
  tr:not(:first-child):hover {
    background-color: ${valmetGreyTable};
  }
`;

const HeaderRow = styled.tr`
  position: relative;
  z-index: 100;
  text-transform: uppercase;
  color: ${defaultGrey};
  th:nth-last-child(2) {
    white-space: nowrap;
  }
  th {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    background-color: ${valmetGreyTable};
  }
  th:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    border-bottom: 1px solid ${valmetGreyBorder};
  }
`;

const HeaderCell = styled.th<{ columnId: string }>`
  white-space: nowrap;
  font-weight: 600;
  font-size: 10px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 5px;
  padding-right: 5px;
  button {
    background-color: transparent;
    outline: none;
    border: none;
    padding: 0;
    margin-left: 8px;
    cursor: pointer;
  }
  ${({ columnId }) => {
    switch (columnId) {
      case "p_id":
      case HIERARCHY_COLUMN.id:
      case "type":
      case "desc":
      case "org":
        return "text-align:left";
      default:
        return "text-align:right; padding-right: 41px;";
    }
  }};
`;

const DataRow = styled.tr<{ childLevel?: number }>`
  height: 116.2px;
  border-top: 1px solid ${valmetGreyBorder};
  background-color: ${({ childLevel }) => (childLevel ? `${valmetGreyLightTable}` : "")};
  cursor: pointer;
  td:nth-last-child(2) {
    white-space: nowrap;
  }
  td:nth-child(2) {
    padding-left: ${({ childLevel }) => (childLevel ? `${childLevel * 20}px` : "0px")};
  }
`;

const EmptyCell = styled.td`
  color: ${valmetGreyLight};
  font-size: 10px;
`;

const BoundaryHeaderCell = styled.th`
  width: 1px;
  /*padding-top: 12px;*/
  /*padding-bottom: 12px;*/
  padding-left: 5px;
  padding-right: 5px;
  &:after {
    content: "";
    left: 0;
    bottom: 0;
    height: 100%;
    border-left: 1px solid ${valmetGreyBorder};
  }
`;
const BoundaryCell = styled.td`
  width: 1px;
  padding-left: 5px;
  padding-right: 5px;
  left: 0;
  bottom: 0;
  border-left: 1px solid ${valmetDarkGreyTable};
`;
