import {
  FieldProperties,
  ProjectInformationEditInformation,
  ProjectInformationInput,
} from "../../../../../common/types";
import { PROJECT_EDIT_CHAR_LIMIT } from "../../../../../common/constants";

export enum DetailsField {
  projectName = "projectName",
  projectDescription = "projectDescription",
  projectKey = "projectKey",
  mainProjectKey = "mainProjectKey",
  projectTechnicalTypeId = "projectTechnicalTypeId",
  projectTypeId = "projectTypeId",
  projectPhaseId = "projectPhaseId",
  projectStatusId = "projectStatusId",
  organisationBusinessGroupId = "organisation.businessGroupId",
  organisationBusinessTypeId = "organisation.businessTypeId",
  organisationLegalEntityId = "organisation.legalEntityId",
  organisationCustomerId = "organisation.customerId",
  contractOwnerBusinessGroupId = "contractOwner.businessGroupId",
  EDCId = "EDCId",
  deliveryOrgId = "deliveryOrgId",
  deliveryLocationId = "deliveryLocationId",
  financingMethod = "financingMethod",
  performanceObligation = "performanceObligation",
  intExtId = "intExtId",
  autReportingCountryId = "autReportingCountryId",
  industryId = "industryId",
  autSiteCodeId = "autSiteCodeId",
  icpTradingPartnerId = "icpTradingPartnerId",
}

export const checkInputErrors = (
  sectionInput: ProjectInformationInput | undefined,
  editInformation: ProjectInformationEditInformation | undefined
): DetailsField[] => {
  if (sectionInput === undefined || editInformation === undefined) return [];

  const errors: DetailsField[] = [];

  const validate = (field: DetailsField, valid: boolean) => {
    if (!valid) errors.push(field);
  };

  const mandatoryNotNull = (value: string | number | null, props: FieldProperties): boolean => {
    return !((value === null || value === undefined) && props.nullable !== null && !props.nullable);
  };

  const lengthLessThanLimit = (value: string | null, limit: number): boolean => {
    return value === null || value.length <= limit;
  };

  validate(
    DetailsField.projectName,
    mandatoryNotNull(sectionInput.projectName, editInformation.projectNameProperties) &&
      lengthLessThanLimit(sectionInput.projectName, PROJECT_EDIT_CHAR_LIMIT.projectName)
  );
  validate(
    DetailsField.projectDescription,
    mandatoryNotNull(sectionInput.projectDescription, editInformation.projectDescriptionProperties) &&
      lengthLessThanLimit(sectionInput.projectDescription, PROJECT_EDIT_CHAR_LIMIT.projectDescription)
  );
  validate(
    DetailsField.projectKey,
    mandatoryNotNull(sectionInput.projectKey, editInformation.projectKeyProperties) &&
      lengthLessThanLimit(sectionInput.projectKey, PROJECT_EDIT_CHAR_LIMIT.projectKey)
  );
  validate(
    DetailsField.mainProjectKey,
    mandatoryNotNull(sectionInput.mainProjectKey, editInformation.mainProjectKeyProperties) &&
      lengthLessThanLimit(sectionInput.mainProjectKey, PROJECT_EDIT_CHAR_LIMIT.mainProjectKey)
  );
  validate(
    DetailsField.projectTechnicalTypeId,
    mandatoryNotNull(sectionInput.projectTechnicalTypeId, editInformation.projectTechnicalTypeProperties)
  );
  validate(
    DetailsField.projectTypeId,
    mandatoryNotNull(
      sectionInput.projectTypeId || editInformation.projectTypeId.cached,
      editInformation.projectTypeProperties
    )
  );
  validate(
    DetailsField.projectPhaseId,
    mandatoryNotNull(sectionInput.projectPhaseId, editInformation.projectPhaseProperties)
  );
  validate(
    DetailsField.projectStatusId,
    mandatoryNotNull(sectionInput.projectStatusId, editInformation.projectStatusProperties)
  );
  validate(
    DetailsField.organisationBusinessGroupId,
    mandatoryNotNull(
      sectionInput.organisation.businessGroupId || editInformation.organisation.businessGroupId.cached,
      editInformation.organisation.businessGroupProperties
    )
  );
  validate(
    DetailsField.organisationBusinessTypeId,
    mandatoryNotNull(
      sectionInput.organisation.businessTypeId || editInformation.organisation.businessTypeId.cached,
      editInformation.organisation.businessTypeProperties
    )
  );
  validate(
    DetailsField.organisationLegalEntityId,
    mandatoryNotNull(
      sectionInput.organisation.legalEntityId || editInformation.organisation.legalEntityId.cached,
      editInformation.organisation.legalEntityProperties
    )
  );
  validate(
    DetailsField.organisationCustomerId,
    mandatoryNotNull(sectionInput.organisation.customerId, editInformation.organisation.customerProperties)
  );
  validate(
    DetailsField.intExtId,
    mandatoryNotNull(sectionInput.organisation.intExtId, editInformation.organisation.intExtProperties)
  );
  validate(
    DetailsField.contractOwnerBusinessGroupId,
    mandatoryNotNull(
      sectionInput.contractOwner.businessGroupId || editInformation.contractOwner.businessGroupId.cached,
      editInformation.contractOwner.businessGroupProperties
    )
  );
  validate(DetailsField.EDCId, mandatoryNotNull(sectionInput.EDCId, editInformation.EDCProperties));
  validate(
    DetailsField.deliveryOrgId,
    mandatoryNotNull(sectionInput.deliveryOrgId, editInformation.deliveryOrgProperties)
  );
  validate(
    DetailsField.deliveryLocationId,
    mandatoryNotNull(sectionInput.deliveryLocationId, editInformation.deliveryLocationProperties)
  );
  validate(
    DetailsField.financingMethod,
    mandatoryNotNull(sectionInput.financingMethod, editInformation.financingMethodProperties)
  );
  validate(
    DetailsField.performanceObligation,
    mandatoryNotNull(sectionInput.performanceObligation, editInformation.performanceObligationProperties)
  );
  validate(
    DetailsField.autReportingCountryId,
    mandatoryNotNull(sectionInput.autReportingCountryId, editInformation.autReportingCountryProperties)
  );
  validate(
    DetailsField.industryId,
    mandatoryNotNull(sectionInput.organisation.industryId, editInformation.organisation.industryProperties)
  );
  validate(
    DetailsField.autSiteCodeId,
    mandatoryNotNull(sectionInput.organisation.autSiteCodeId, editInformation.organisation.autSiteCodeProperties)
  );
  validate(
    DetailsField.icpTradingPartnerId,
    mandatoryNotNull(
      sectionInput.organisation.icpTradingPartnerId,
      editInformation.organisation.icpTradingPartnerProperties
    )
  );
  return errors;
};
