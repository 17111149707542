import { ProjectCostAction, EstimateCode } from "../common/types";

export const SET_ESTIMATE_CODE = "SET_ESTIMATE_CODE";
export const START_COSTS_EDITING = "START_COSTS_EDITING";
export const END_COSTS_EDITING = "END_COSTS_EDITING";
export const ADD_COSTS_EDIT = "ADD_COSTS_EDIT";
export const SET_REFETCH_PENDING = "SET_REFETCH_PENDING";

export const setEstimateCode = (code: EstimateCode, projectId: number): ProjectCostAction => ({
  type: SET_ESTIMATE_CODE,
  code,
  projectId
});

export const startCostsEditing = (): ProjectCostAction => ({
  type: START_COSTS_EDITING
});

export const endCostsEditing = (): ProjectCostAction => ({
  type: END_COSTS_EDITING
});

export const setRefetchPending = (refetchPending: boolean): ProjectCostAction => ({
  type: SET_REFETCH_PENDING,
  refetchPending
});
