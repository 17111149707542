/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Report from "powerbi-report-component";
import { useQuery } from "@apollo/client/react/hooks";
import { GET_PROJECT_OVERVIEW_REPORT } from "./queries";
import { ProjectReport, ProjectReportPage, ProjectOverviewReportResponse } from "../../../../../common/types";
import LoadingView from "../../../../LoadingView";

export interface ProjectsReportsProps {
  projectId: number;
}

const setProjectFilter = (report: any, projectId: number) => {
  report
    .setFilters([
      {
        $schema: "http://powerbi.com/product/schema#basic",
        target: {
          table: "DIM_PROJECT",
          column: "ProjectRid",
        },
        operator: "In",
        values: [projectId],
        filterType: 1, // pbi.models.FilterType.BasicFilter,
        requireSingleSelection: true, // Limits selection of values to one.
      },
    ])
    .catch((errors: any) => {
      console.log(errors);
    });
};

function ProjectsReports(props: ProjectsReportsProps): React.ReactElement {
  const { projectId } = props;
  const [selectedReport, setSelectedReport] = useState<ProjectReport | undefined>(undefined);
  const [selectedPage, setSelectedPage] = useState<ProjectReportPage | undefined>(undefined);

  /*
  const { loading, data, error } = useQuery<ProjectReportsResponse>(GET_PROJECT_REPORTS, {
    variables: {
      projectId
    },
    fetchPolicy: "cache-and-network"
  });

  if (data && data.projectReports.length && !selectedReport) {
    setSelectedReport(data.projectReports[0]);
    setSelectedPage(data.projectReports[0].pages[0]);
  }
  */

  const { loading, data, error, refetch } = useQuery<ProjectOverviewReportResponse>(GET_PROJECT_OVERVIEW_REPORT, {
    fetchPolicy: "network-only",
  });

  if (data && !selectedReport) {
    setSelectedReport(data.projectOverviewReport);
  }

  useEffect(() => {
    let timer: number;
    if (data) {
      const refetchTime = new Date(data.projectOverviewReport.tokenExpiration).getTime() - new Date().getTime();
      timer = setTimeout(() => refetch(), refetchTime);
    }

    return () => clearTimeout(timer);
  }, [data, refetch]);

  return (
    <Container>
      {/*data && data.projectReports.length && selectedReport && selectedPage && (
        <ReportsNavigation
          reports={data.projectReports}
          selectedReport={selectedReport}
          setSelectedReport={reportId => setSelectedReport(reportId)}
          selectedPage={selectedPage}
          setSelectedPage={pageId => setSelectedPage(pageId)}
        />
      )*/}
      {selectedReport && (
        <Report
          embedType="report"
          tokenType="Embed"
          accessToken={selectedReport.token}
          embedUrl={selectedReport.embedUrl}
          embedId={selectedReport.reportId}
          dashboardId=""
          pageName={selectedPage ? selectedPage.id : ""}
          extraSettings={{
            filterPaneEnabled: false,
            navContentPaneEnabled: false,
          }}
          permissions="All"
          style={{
            height: "100%",
            width: "100%",
            border: "0",
            background: "#eee",
          }}
          onLoad={(report: any) => {
            setProjectFilter(report, projectId);
          }}
          onError={(data: any) => console.log(`Error ASDASD`)}
        />
      )}
      {loading && <LoadingView />}
      {error && <InfoContainer>Error opening report.</InfoContainer>}
    </Container>
  );
}

export default ProjectsReports;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding-left: 100px;
  padding-right: 100px;
`;

const InfoContainer = styled.div`
  padding: 20px;
`;
