import { ApolloClient } from "@apollo/client";
import { GraphQLError } from "graphql";
import { gql } from "@apollo/client";
import {
  AddProjectToHierarchyRequest,
  HierarchyTreeNode,
  MainProjectChangeRequest,
  NewNodeRequest,
  NodeId,
  NodeRequest,
  PollReadyResult,
  ProjectId,
  RemoveNodeRequest,
} from "../../../../common/types";
import { HierarchyFields } from "../queries";
import { saveProjectHierarchyChanges } from "../ProjectBasics/HierarchySection/queries";
import { useCallback, useState } from "react";
import { useLazyQuery } from "@apollo/client/react/hooks";

export const SEARCH_NODES = gql`
  query SearchNodes($searchQuery: [String!]!, $num: Int!) {
    searchNodes(searchQuery: $searchQuery, num: $num) {
      nodes {
        id
        description
        mainProjectId
        mainProjectDescription
      }
      moreResultsAvailable
    }
  }
`;
export const GET_NODE = gql`
  query GetNode($nodeId: NodeId!) {
    getNode(nodeId: $nodeId) {
      id
      description
      itemType
      mainProjectId
      childItems {
        ...HierarchyFields
        childItems {
          ...HierarchyFields
          childItems {
            ...HierarchyFields
            childItems {
              ...HierarchyFields
              childItems {
                ...HierarchyFields
                childItems {
                  ...HierarchyFields
                  childItems {
                    ...HierarchyFields
                    childItems {
                      ...HierarchyFields
                      childItems {
                        ...HierarchyFields
                        childItems {
                          ...HierarchyFields
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${HierarchyFields}
`;

export const GET_PROJECT_HIERARCHY = gql`
  query GetProjectHierarchy($id: HierarchyItemId!, $itemType: HierarchyItemEnum!) {
    headerInfo(id: $id, itemType: $itemType) {
      hierarchy {
        ...HierarchyFields
        childItems {
          ...HierarchyFields
          childItems {
            ...HierarchyFields
            childItems {
              ...HierarchyFields
              childItems {
                ...HierarchyFields
                childItems {
                  ...HierarchyFields
                  childItems {
                    ...HierarchyFields
                    childItems {
                      ...HierarchyFields
                      childItems {
                        ...HierarchyFields
                        childItems {
                          ...HierarchyFields
                          childItems {
                            ...HierarchyFields
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${HierarchyFields}
`;

export const POLL_FOR_NODE_HIERARCHY_CHANGES_READY = gql`
  query PollForNodeHierarchyChangesReady(
    $projectId: ProjectId
    $nodeIds: [NodeId!]!
    $applicationModifiedDateTime: ISOLocalDateTime!
  ) {
    pollForNodeHierarchyChangesReady(
      projectId: $projectId
      nodeIds: $nodeIds
      applicationModifiedDateTime: $applicationModifiedDateTime
    ) {
      ready
    }
  }
`;

export const POLL_FOR_PROJECT_HIERARCHY_CHANGES_READY = gql`
  query PollForProjectHierarchyChangesReady($projectId: ProjectId!, $applicationModifiedDateTime: ISOLocalDateTime!) {
    pollForProjectHierarchyChangesReady(
      projectId: $projectId
      applicationModifiedDateTime: $applicationModifiedDateTime
    ) {
      ready
    }
  }
`;

export const queryNodesReady = async (
  client: ApolloClient<Record<string, unknown>>,
  projectId: ProjectId,
  nodeIds: number[],
  applicationModifiedDateTime: string
): Promise<[PollReadyResult | undefined, Readonly<GraphQLError[] | undefined>]> => {
  const { data, errors } = await client
    .query<{ pollForNodeHierarchyChangesReady: PollReadyResult }>({
      query: POLL_FOR_NODE_HIERARCHY_CHANGES_READY,
      variables: { projectId, nodeIds, applicationModifiedDateTime },
      fetchPolicy: "no-cache",
    })
    .then(res => {
      return res;
    })
    .catch(e => {
      console.error(e);
      return e;
    });

  return [data ? data.pollForNodeHierarchyChangesReady : undefined, errors];
};

export const queryProjectReady = async (
  client: ApolloClient<Record<string, unknown>>,
  projectId: ProjectId,
  applicationModifiedDateTime: string
): Promise<[PollReadyResult | undefined, Readonly<GraphQLError[] | undefined>]> => {
  const { data, errors } = await client
    .query<{ pollForProjectHierarchyChangesReady: PollReadyResult }>({
      query: POLL_FOR_PROJECT_HIERARCHY_CHANGES_READY,
      variables: { projectId, applicationModifiedDateTime },
      fetchPolicy: "no-cache",
    })
    .then(res => {
      return res;
    })
    .catch(e => {
      console.error(e);
      return e;
    });

  return [data ? data.pollForProjectHierarchyChangesReady : undefined, errors];
};

export const pollForProjectHierarchyChangesReady = (
  pollInterval = 2000,
  retries = 30
): {
  pollForReady: (projectId: number, applicationModifiedDateTime: string) => void;
  ready: boolean;
  loading: boolean;
  error: string | undefined;
} => {
  const [error, setError] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [leftRetries, setLeftRetries] = useState<number>(0);

  const [queryForProjectHierarchyChangesReady, { data, startPolling, stopPolling }] = useLazyQuery<{
    pollForProjectHierarchyChangesReady: PollReadyResult;
  }>(POLL_FOR_PROJECT_HIERARCHY_CHANGES_READY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
    onCompleted(data) {
      if ((!data || !data.pollForProjectHierarchyChangesReady.ready) && leftRetries > 0) {
        if (leftRetries === retries) {
          startPolling(pollInterval);
        }
        setLeftRetries(leftRetries - 1);
      } else {
        setLeftRetries(0);
        setLoading(false);
        stopPolling();
        if (!data || !data.pollForProjectHierarchyChangesReady.ready) {
          setError("Timeout polling project hierarchy changes state.");
        }
      }
    },
    onError(error) {
      if (leftRetries > 0) {
        if (leftRetries === retries) {
          startPolling(pollInterval);
        }
        setLeftRetries(leftRetries - 1);
      } else {
        setLeftRetries(0);
        setLoading(false);
        stopPolling();
        setError(error.message);
      }
    },
  });

  const pollForReady = useCallback(
    (projectId: number, applicationModifiedDateTime: string) => {
      setLoading(true);
      setLeftRetries(retries);
      queryForProjectHierarchyChangesReady({ variables: { projectId, applicationModifiedDateTime } });
    },
    [queryForProjectHierarchyChangesReady]
  );

  return {
    pollForReady: pollForReady,
    ready: data ? data.pollForProjectHierarchyChangesReady.ready : false,
    loading: loading,
    error: error,
  };
};

export const saveProjectHierarchy = async (
  client: ApolloClient<Record<string, unknown>>,
  projectId: ProjectId,
  nodeId: NodeId,
  addedNodes: HierarchyTreeNode[],
  mainProjectChanges: MainProjectChangeRequest[],
  setErrors: (errors: string[] | undefined) => void,
  setNodeHierarchyApplicationModifiedDateTime: (
    projectApplicationModifiedDateTime: string | undefined,
    nodeId: number | undefined
  ) => void
): Promise<{ successful: boolean }> => {
  const newNodes: NewNodeRequest[] = addedNodes.map(n => ({
    tempId: n.item.id,
    description: n.item.description,
    parentNodeId: n.parentNodeId,
  }));
  const movedNodes: NodeRequest[] = [];
  const removedNodes: RemoveNodeRequest[] = [];
  const movedProjects: AddProjectToHierarchyRequest[] = [{ projectId, nodeId: nodeId }];
  return await saveProjectHierarchyChanges(
    client,
    setErrors,
    newNodes,
    movedNodes,
    movedProjects,
    removedNodes,
    mainProjectChanges,
    projectId
  ).then(result => {
    if (result && result.applicationModifiedDateTime) {
      setNodeHierarchyApplicationModifiedDateTime(result.applicationModifiedDateTime, nodeId);
      return { successful: result.updatedHierarchy !== null };
    } else {
      return { successful: false };
    }
  });
};
