import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { cloneDeep, remove } from "lodash";
import { valmetGreyLight } from "../../../../../common/colors";
import { PROJECT_COST_OTHER_COSTS_COLUMNS } from "./constants";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import { CollapseButton } from "../../../../../common/components";
import {
  ProjectCostOtherCostComponentItem,
  ProjectCostOtherCostSubItem,
  ProjectCostOtherItem,
  ProjectCostOtherPeriodDetailItem,
  ProjectCostOtherPeriodItem,
  ProjectCostOtherTableItem,
} from "./types";
import {
  DataCell,
  DataContainer,
  DataRow,
  DetailsTable,
  HeaderCell,
  HeaderRow,
  TableBody,
  TypedDataCell,
} from "./components";
import { justifyToRight } from "./column";

export interface OtherCostTableProps {
  otherItems: ProjectCostOtherItem[];
  popupWindow: boolean;
}

const orUndefined = <T extends unknown>(v: T | null): T | undefined => (v === null ? undefined : v);

const transformOtherCostItem = (item: ProjectCostOtherItem): ProjectCostOtherTableItem => {
  return {
    id: item.subProject,
    childLevel: 0,
    childItems: [...item.costComponentItems, ...item.otherItems],
    description: item.subProject,
    actuals: item.actuals,
  };
};

const transformOtherCostCostComponent = (
  item: ProjectCostOtherCostComponentItem,
  parentId: string
): ProjectCostOtherTableItem => {
  return {
    id: parentId + item.costComponent,
    childLevel: 1,
    childItems: item.childItems,
    description: item.costComponent,
    actuals: item.actuals,
  };
};

const transformOtherCostCostSubItem = (
  item: ProjectCostOtherCostSubItem,
  parentId: string
): ProjectCostOtherTableItem => {
  return {
    id: parentId + item.description,
    childLevel: 1,
    childItems: item.childItems,
    description: item.description,
    actuals: item.actuals,
  };
};

const transformOtherCostPeriodItem = (
  item: ProjectCostOtherPeriodItem,
  parentId: string
): ProjectCostOtherTableItem => {
  return {
    id: parentId + item.period,
    childLevel: 2,
    childItems: item.childItems,
    description: item.period,
    actuals: item.actuals,
  };
};

const transformOtherCostPeriodDetailItem = (
  item: ProjectCostOtherPeriodDetailItem,
  parentId: string,
  index: number
): ProjectCostOtherTableItem => {
  return {
    id: parentId + item.period + item.item + item.reference + index,
    childLevel: 3,
    description: item.description,
    actuals: item.actuals,
    item: orUndefined(item.item),
    supplier: orUndefined(item.supplier),
    transactionDate: orUndefined(item.transactionDate),
    reference: orUndefined(item.reference),
    invoicePictureURL: orUndefined(item.invoicePictureURL),
  };
};

const transformItem = (
  item:
    | ProjectCostOtherItem
    | ProjectCostOtherCostComponentItem
    | ProjectCostOtherCostSubItem
    | ProjectCostOtherPeriodItem
    | ProjectCostOtherPeriodDetailItem,
  index: number,
  parentId?: string
): ProjectCostOtherTableItem => {
  switch (item.__typename) {
    case "ProjectCostOtherItem":
      return transformOtherCostItem(item as ProjectCostOtherItem);
    case "ProjectCostOtherCostComponentItem":
      return transformOtherCostCostComponent(item as ProjectCostOtherCostComponentItem, parentId || "");
    case "ProjectCostOtherCostSubItem":
      return transformOtherCostCostSubItem(item as ProjectCostOtherCostSubItem, parentId || "");
    case "ProjectCostOtherPeriodItem":
      return transformOtherCostPeriodItem(item as ProjectCostOtherPeriodItem, parentId || "");
    case "ProjectCostOtherPeriodDetailItem":
      return transformOtherCostPeriodDetailItem(item as ProjectCostOtherPeriodDetailItem, parentId || "", index);
    default:
      throw Error("Tree item type '" + item.__typename + "' not supported");
  }
};

const getChildrenItems = (item: ProjectCostOtherTableItem, list: ProjectCostOtherTableItem[], parent?: boolean) => {
  if (!parent) {
    list.push(item);
  }

  if (item.childItems && item.childItems.length > 0) {
    item.childItems.forEach(
      (
        childItem:
          | ProjectCostOtherCostComponentItem
          | ProjectCostOtherCostSubItem
          | ProjectCostOtherPeriodItem
          | ProjectCostOtherPeriodDetailItem,
        index: number
      ) => getChildrenItems(transformItem(childItem, index, item.id), list)
    );
  }
};

const setInitialChildrenVisible = (
  items: ProjectCostOtherTableItem[],
  setRenderedRows: React.Dispatch<React.SetStateAction<ProjectCostOtherTableItem[]>>,
  setOpenOtherCostItems: React.Dispatch<React.SetStateAction<string[]>>
) => {
  const renderedRows: ProjectCostOtherTableItem[] = [];
  const newOpenOtherCostItems: string[] = [];
  items.forEach(item => {
    renderedRows.push(item);
    newOpenOtherCostItems.push(item.id);
    if (item.childItems) {
      item.childItems.forEach(
        (
          childItem:
            | ProjectCostOtherCostComponentItem
            | ProjectCostOtherCostSubItem
            | ProjectCostOtherPeriodItem
            | ProjectCostOtherPeriodDetailItem,
          index: number
        ) => {
          renderedRows.push(transformItem(childItem, index, item.id));
        }
      );
    }
  });
  setRenderedRows(renderedRows);
  setOpenOtherCostItems(newOpenOtherCostItems);
};

const closeOtherCostItemHierarchy = (
  otherCostItem: ProjectCostOtherTableItem,
  setRenderedRows: React.Dispatch<React.SetStateAction<ProjectCostOtherTableItem[]>>,
  renderedRows: ProjectCostOtherTableItem[],
  openOtherCostItems: string[],
  setOpenOtherCostItems: React.Dispatch<React.SetStateAction<string[]>>
) => {
  const childItems: ProjectCostOtherTableItem[] = [];
  getChildrenItems(otherCostItem, childItems, true);

  const newItems = cloneDeep(renderedRows);
  remove(newItems, openItem => childItems.map(item => item.id).includes(openItem.id));
  setRenderedRows(newItems);

  childItems.push(otherCostItem);
  const newOpenOtherCostItems = cloneDeep(openOtherCostItems);
  remove(newOpenOtherCostItems, openItem => childItems.map(item => item.id).includes(openItem));
  setOpenOtherCostItems(newOpenOtherCostItems);
};

const openOtherCostItemHierarchy = (
  otherCostItem: ProjectCostOtherTableItem,
  setRenderedRows: React.Dispatch<React.SetStateAction<ProjectCostOtherTableItem[]>>,
  renderedRows: ProjectCostOtherTableItem[],
  openOtherCostItems: string[],
  setOpenOtherCostItems: React.Dispatch<React.SetStateAction<string[]>>,
  index: number
) => {
  const newItems = cloneDeep(renderedRows);
  const newOpenOtherCostItems = cloneDeep(openOtherCostItems);
  if (otherCostItem.childItems) {
    otherCostItem.childItems.forEach(
      (
        childItem:
          | ProjectCostOtherCostComponentItem
          | ProjectCostOtherCostSubItem
          | ProjectCostOtherPeriodItem
          | ProjectCostOtherPeriodDetailItem,
        i: number
      ) => {
        const transformedItem = transformItem(childItem, i, otherCostItem.id);
        newItems.splice(index + 1 + i, 0, transformedItem);
      }
    );
    newOpenOtherCostItems.push(otherCostItem.id);
  }

  setOpenOtherCostItems(newOpenOtherCostItems);
  setRenderedRows(newItems);
};

const expandAll = (
  openOtherCostItems: string[],
  setOpenOtherCostItems: React.Dispatch<React.SetStateAction<string[]>>,
  item: ProjectCostOtherTableItem,
  setRenderedRows: React.Dispatch<React.SetStateAction<ProjectCostOtherTableItem[]>>,
  renderedRows: ProjectCostOtherTableItem[],
  index: number
) => {
  const newOtherCostItems = cloneDeep(renderedRows);
  const newOpenOtherCostItems = cloneDeep(openOtherCostItems);

  const childItems: ProjectCostOtherTableItem[] = [];
  getChildrenItems(item, childItems, true);
  remove(newOtherCostItems, otherCostItem => childItems.map(item => item.id).includes(otherCostItem.id));

  childItems.push(item);
  remove(newOpenOtherCostItems, otherCostItem => childItems.map(item => item.id).includes(otherCostItem));
  childItems.pop();

  newOpenOtherCostItems.push(item.id);
  childItems.forEach(childItem => {
    if (childItem.childItems) {
      newOpenOtherCostItems.push(childItem.id);
    }
  });

  newOtherCostItems.splice(index + 1, 0, ...childItems);

  setOpenOtherCostItems(newOpenOtherCostItems);
  setRenderedRows(newOtherCostItems);
};

function OtherTable(props: OtherCostTableProps): React.ReactElement {
  const { otherItems, popupWindow } = props;
  const [openOtherCostItems, setOpenOtherCostItems] = useState<string[]>([]);
  const [renderedRows, setRenderedRows] = useState<ProjectCostOtherTableItem[]>([]);

  useEffect(() => {
    if (otherItems) {
      setInitialChildrenVisible(
        otherItems.map((item, index) => transformItem(item, index)),
        setRenderedRows,
        setOpenOtherCostItems
      );
    }
  }, [otherItems]);

  return (
    <DetailsTable>
      <TableBody>
        <HeaderRow>
          <HeaderCell right={false} popupWindow={popupWindow}>
            Description
          </HeaderCell>
          {PROJECT_COST_OTHER_COSTS_COLUMNS.map((column, i) => (
            <HeaderCell key={i} right={justifyToRight(column)} title={column.tooltip} popupWindow={popupWindow}>
              {column.description}
            </HeaderCell>
          ))}
        </HeaderRow>
        {renderedRows.map((item, index) => (
          <DataRow
            key={item.id}
            childLevel={item.childLevel}
            extraPadding={!item.childItems || item.childItems.length === 0}
          >
            <DataCell>
              <DataContainer>
                {item.childItems && item.childItems.length > 0 && (
                  <ContextMenuTrigger id={`${item.id}-context-menu`}>
                    <CollapseButton
                      onClick={() => {
                        if (item.childItems && item.childItems.length > 0) {
                          if (openOtherCostItems.includes(item.id)) {
                            closeOtherCostItemHierarchy(
                              item,
                              setRenderedRows,
                              renderedRows,
                              openOtherCostItems,
                              setOpenOtherCostItems
                            );
                          } else {
                            openOtherCostItemHierarchy(
                              item,
                              setRenderedRows,
                              renderedRows,
                              openOtherCostItems,
                              setOpenOtherCostItems,
                              index
                            );
                          }
                        }
                      }}
                    >
                      <FontAwesomeIcon
                        icon={openOtherCostItems.includes(item.id) ? faChevronUp : faChevronDown}
                        size="1x"
                        color={valmetGreyLight}
                      />
                    </CollapseButton>
                  </ContextMenuTrigger>
                )}
                {item.description}
                <ContextMenu id={`${item.id}-context-menu`}>
                  <MenuItem
                    onClick={() =>
                      expandAll(openOtherCostItems, setOpenOtherCostItems, item, setRenderedRows, renderedRows, index)
                    }
                  >
                    Expand all
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      closeOtherCostItemHierarchy(
                        item,
                        setRenderedRows,
                        renderedRows,
                        openOtherCostItems,
                        setOpenOtherCostItems
                      )
                    }
                  >
                    Collapse all
                  </MenuItem>
                </ContextMenu>
              </DataContainer>
            </DataCell>
            {PROJECT_COST_OTHER_COSTS_COLUMNS.map((column, i) => (
              <TypedDataCell key={item.id + "_" + i} column={column} item={item} />
            ))}
          </DataRow>
        ))}
      </TableBody>
    </DetailsTable>
  );
}

export default OtherTable;
