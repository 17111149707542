import React from "react";

import { ProjectId, ProjectIntegrations, ProjectIntegrationsVisibility } from "../../../../../common/types";
import CreateProjectSection from "../CreateProjectSection";
import EditDetails from "./EditDetails";

interface IntegrationsCreateProjectSectionProps {
  projectId: ProjectId;
  sectionDetails: ProjectIntegrations;
  sectionVisibility: ProjectIntegrationsVisibility;
}

function IntegrationsCreateProjectSection(props: IntegrationsCreateProjectSectionProps) {
  const { projectId, sectionDetails, sectionVisibility } = props;

  return (
    <CreateProjectSection header="Integrations">
      <EditDetails projectId={projectId} integrations={sectionDetails} visibility={sectionVisibility} />
    </CreateProjectSection>
  );
}

export default IntegrationsCreateProjectSection;
