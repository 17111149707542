import React, { useState, createRef, useEffect } from "react";
import styled from "styled-components";
import { FilterId, FilterSetting, FilterValues } from "../../../../common/types";
import FilterDropdown from "./FilterDropdown";
import { find, filter, orderBy, includes } from "lodash";
import ResetButton from "./ResetButton";

interface FilterSectionProps {
  filterItem: FilterSetting;
  filterValues: FilterValues;
  onFilterSelected: (filter: FilterId, value: string | number | undefined) => void;
  singleSelection: boolean;
  onResetFilter: (id: FilterId) => void;
  dropdownMarginLeft?: string;
  minWidth?: string;
}

const getFilterDescription = (filter: FilterSetting, filterValues: FilterValues) => {
  if (filterValues[filter.id]) {
    const filterValue = filterValues[filter.id];
    if (Array.isArray(filterValue)) {
      if (filterValue.length === 1) {
        const option = find(filter.values, value => value.id === filterValue[0]);
        if (option) return option.description;
      } else if (filterValue.length > 1) {
        const option = find(filter.values, value => value.id === filterValue[0]);
        if (option) return option.description + ` (+${filterValue.length - 1})`;
      }
    } else {
      const option = find(filter.values, value => value.id === filterValue);
      if (option) return option.description;
    }
  }

  return "";
};

function SearchableFilterItem(props: FilterSectionProps): React.ReactElement {
  const {
    filterItem,
    filterValues,
    onFilterSelected,
    singleSelection,
    onResetFilter,
    dropdownMarginLeft,
    minWidth,
  } = props;
  const [input, setInput] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dropdown = createRef<any>();

  useEffect(() => {
    setInput(getFilterDescription(filterItem, filterValues));
  }, [filterValues, filterItem.values]);

  const dropdownId = `${filterItem.id}Dropdown`;
  const selections = filterValues[filterItem.id];

  return (
    <FilterItem key={filterItem.id}>
      <FilterSelection extraPadding={true}>
        {filterItem.name}
        <FilterDropdown
          filter={filterItem.id}
          values={orderBy(
            filterItem.values,
            [
              item =>
                selections !== undefined &&
                ((Array.isArray(selections) && includes(selections, item.id)) || selections === item.id),
            ],
            ["desc"]
          )}
          onFilterSelected={onFilterSelected}
          selections={selections}
          singleSelection={singleSelection}
          maxResults={20}
          marginLeft={dropdownMarginLeft}
        />
      </FilterSelection>
      <FilterSelectionColumn>
        <FilterSelection>
          <ResetButton
            onButtonClick={() => onResetFilter(filterItem.id)}
            show={filterValues[filterItem.id] !== undefined}
            id={dropdownId}
          />
          <FilterInput
            type="text"
            placeholder="Search"
            value={input}
            onFocus={() => {
              setInput("");
              dropdown.current.instanceRef.open();
            }}
            onBlur={event => {
              if (!event.relatedTarget || (event.relatedTarget as HTMLElement).id !== dropdownId) {
                //setInput(getFilterDescription(filterItem, filterValues));
              }
            }}
            onChange={event => {
              setInput(event.target.value);
              //dropdown.current.instanceRef.open();
            }}
            minWidth={minWidth}
          />
        </FilterSelection>

        <FilterDropdown
          ref={dropdown}
          id={dropdownId}
          filter={filterItem.id}
          values={orderBy(
            input.length > 0
              ? filter(filterItem.values, value =>
                  value.description.toLocaleUpperCase().includes(input.toLocaleUpperCase())
                )
              : filterItem.values,
            [
              item =>
                selections !== undefined &&
                ((Array.isArray(selections) && includes(selections, item.id)) || selections === item.id),
            ],
            ["desc"]
          )}
          onFilterSelected={onFilterSelected}
          selections={selections}
          disableIcon={true}
          onClose={() => {
            if (!selections) {
              setInput("");
            } else {
              setInput(getFilterDescription(filterItem, filterValues));
            }
          }}
          maxResults={20}
          singleSelection={singleSelection}
          extraMargin={true}
        />
      </FilterSelectionColumn>
    </FilterItem>
  );
}

export default SearchableFilterItem;

const FilterItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 4px;
`;

const FilterSelection = styled.div<{ extraPadding?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: ${({ extraPadding }) => (extraPadding ? "13" : "0")}px;
`;

const FilterSelectionColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const FilterInput = styled.input<{ minWidth?: string }>`
  border: 0;
  font-style: italic;
  font-size: 10px;
  min-width: ${({ minWidth }) => (minWidth ? minWidth : "120px")};
`;
