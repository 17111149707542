import { User, Action } from "../common/types";

export const AUTH_USER = "AUTH_USER";
export const UNAUTH_USER = "UNAUTH_USER";
export const AUTH_ERROR = "AUTH_ERROR";

export const authUser = (user: User): Action => ({
  type: AUTH_USER,
  data: user
});

export const unauthUser = (): Action => ({
  type: UNAUTH_USER
});

/*
export const authError = (CONST, error) => ({
  type: CONST,
  data: error
});
*/
