import { ApolloClient } from "@apollo/client";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useApolloClient } from "@apollo/client/react/hooks";
import { SearchNode, SearchNodeData } from "../../../../../common/types";
import { SEARCH_NODES } from "../queries";
import HierarchySearchDropdown from "./HierarchySearchDropdown";

export interface HierarchySearchProps {
  selectedNode?: SearchNode;
  onSelection: (node: SearchNode) => void;
  disabled?: boolean;
}

const onSearchChanged = async (
  input: string,
  setSearchData: React.Dispatch<React.SetStateAction<SearchNodeData | undefined>>,
  client: ApolloClient<Record<string, unknown>>
) => {
  const arrayedInput = input.trim().split(" ");
  if (arrayedInput.length > 0) {
    const { data } = await client
      .query({
        query: SEARCH_NODES,
        variables: { searchQuery: arrayedInput, num: 10 },
        fetchPolicy: "network-only",
      })
      .catch(e => {
        return e;
      });
    if (data) {
      setSearchData(data as SearchNodeData);
    }
  }
};

const resetSearch = (
  setSearchInput: React.Dispatch<React.SetStateAction<string>>,
  setSearchData: React.Dispatch<React.SetStateAction<SearchNodeData | undefined>>,
  selectedNode?: SearchNode
) => {
  setSearchInput(selectedNode ? selectedNode.description : "");
  setSearchData(undefined);
};

function HierarchySearch(props: HierarchySearchProps): React.ReactElement {
  const { selectedNode, onSelection, disabled } = props;
  const [searchInput, setSearchInput] = useState("");
  const [searchData, setSearchData] = useState<SearchNodeData | undefined>(undefined);
  const client = useApolloClient() as ApolloClient<Record<string, unknown>>;
  const timeout = useRef<number>();

  useEffect(() => {
    clearTimeout(timeout.current);
    if (searchInput.length > 0 && (!selectedNode || searchInput !== selectedNode.description)) {
      timeout.current = setTimeout(() => {
        onSearchChanged(searchInput, setSearchData, client);
      }, 500);
    } else if (searchInput.length === 0) {
      setSearchData(undefined);
    }
  }, [searchInput, client, selectedNode]);

  useEffect(() => {
    if (selectedNode) {
      setSearchInput(selectedNode.description);
    }
  }, [selectedNode]);

  return (
    <Container>
      <Title>Search for hierarchies:</Title>
      <DropdownContainer>
        <Row>
          <SearchInput
            placeholder="Search nodes..."
            value={searchInput}
            onChange={e => setSearchInput(e.target.value)}
            disabled={disabled}
          />
        </Row>
        {searchData && (
          <HierarchySearchDropdown
            searchNodes={searchData.searchNodes.nodes}
            resetSearch={() => resetSearch(setSearchInput, setSearchData, selectedNode)}
            onResultSelected={onSelection}
            moreAvailable={searchData.searchNodes.moreResultsAvailable}
          />
        )}
      </DropdownContainer>
    </Container>
  );
}

export default HierarchySearch;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-bottom: 20px;
`;

const Title = styled.div`
  margin-bottom: 20px;
`;

const SearchInput = styled.input`
  width: 300px;
  font-weight: bold;
  font-size: 18px;
`;
const DropdownContainer = styled.div``;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;
