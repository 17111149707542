import React, { useEffect } from "react";
import styled from "styled-components";
import { useQuery, useApolloClient } from "@apollo/client/react/hooks";
import { ApolloClient, gql } from "@apollo/client";
import ColumnSetsDropdown from "./ColumnSetsDropdown";
import { defaultGrey } from "../../../../../common/colors";
import { ViewsData, ViewListItem } from "../../../../../common/types";
import { enrichViewData } from "../../../../../common/utils";
import { ColumnSet } from "../../../../../common/columnsTypes";

export interface ColumnSetsDropdownSectionProps {
  selectedColumnSet: ColumnSet;
  defaultColumnSets: ColumnSet[];
  onColumnSetSelection: (columnSet: ColumnSet) => void;
  lastMutated?: number;
  onDelete: (id: string) => void;
  onEdit: (columnSet: ColumnSet) => void;
}

const GET_VIEWS = gql`
  query GetViews {
    views {
      id
      name
      isGlobal
    }
  }
`;

const GET_VIEW = gql`
  query GetView($id: ViewId!) {
    view(id: $id) {
      id
      name
      columns
      isGlobal
    }
  }
`;

const fetchViewData = async (id: string, client: ApolloClient<Record<string, unknown>>) => {
  const { data } = await client
    .query({
      query: GET_VIEW,
      variables: { id: id },
    })
    .catch(e => {
      return e;
    });
  return data;
};

const onCustomColumnSetSelected = async (
  viewId: string,
  client: ApolloClient<Record<string, unknown>>,
  onColumnSetSelection: (columnSet: ColumnSet) => void
) => {
  const data = await fetchViewData(viewId, client);
  if (data) {
    onColumnSetSelection(enrichViewData(data.view));
  }
};

const onEditSelected = async (
  view: ViewListItem,
  client: ApolloClient<Record<string, unknown>>,
  onEdit: (columnSet: ColumnSet) => void
) => {
  const data = await fetchViewData(view.id, client);
  if (data) {
    onEdit(enrichViewData(data.view));
  }
};

function ColumnSetsDropdownSection(props: ColumnSetsDropdownSectionProps): React.ReactElement {
  const { selectedColumnSet, onColumnSetSelection, defaultColumnSets, lastMutated, onDelete, onEdit } = props;
  const client = useApolloClient() as ApolloClient<Record<string, unknown>>;

  const { data, refetch } = useQuery<ViewsData>(GET_VIEWS, {
    fetchPolicy: "cache-and-network",
    onCompleted: viewData => {
      const findInDefault = defaultColumnSets.find(set => set.id === selectedColumnSet.id);
      const findInCustom = viewData.views.find(set => set.id === selectedColumnSet.id);
      // Always save selection again on initialization in case columns changed.
      if (findInDefault)
        onColumnSetSelection(findInDefault)
      else if (findInCustom)
        onCustomColumnSetSelected(findInCustom.id, client, onColumnSetSelection)
      else onColumnSetSelection(defaultColumnSets[0])
    }
  });

  const fetchedViews = data ? data.views : [];
  useEffect(() => {
    if (lastMutated) {
      refetch();
    }
  }, [lastMutated, refetch]);

  return (
    <Container>
      <ColumnSetsDropdown
        customColumnSets={fetchedViews}
        defaultColumnSets={defaultColumnSets}
        selection={selectedColumnSet}
        onColumnSetSelected={columnSet => onColumnSetSelection(columnSet)}
        onCustomColumnSetSelected={view => onCustomColumnSetSelected(view.id, client, onColumnSetSelection)}
        onDelete={onDelete}
        onEdit={view => onEditSelected(view, client, onEdit)}
      />
    </Container>
  );
}

export default ColumnSetsDropdownSection;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-size: 12px;
  color: ${defaultGrey};
  margin-left: 20px;
`;
