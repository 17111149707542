import React, { useState } from "react";
import {
  ActivityEstimateCommentTypeId,
  ActivityId,
  CostComponentEditRow,
  CurrencyScenario,
  EstimateCodeSelectionState,
  EstimateStatuses,
  ProjectCostActivityComment,
  ProjectCostCommentItem,
  ProjectCostSummaryRow,
} from "../../../../../common/types";
import { EDITABLE_COST_COMPONENTS, PROJECT_COST_SUMMARY_HEADERS } from "../constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { editBlue, settingGreen, valmetGreyLight, valmetGreyREC } from "../../../../../common/colors";
import styled from "styled-components";
import CostSummaryRow from "./CostSummaryRow";
import { isColumnEditable } from "./util";
import { isValuesAvailable } from "../utils";
import { IconButton, ToggleSwitch } from "../../../../../common/components";
import { useLocalStorage } from "../../../../../hooks/useLocalStorage";

export interface CostItemsTableProps {
  projectId: number;
  activityRows: ProjectCostSummaryRow[];
  comments: ProjectCostCommentItem[];
  setComments: (comments: ProjectCostCommentItem[]) => void;
  commentTypes: ActivityEstimateCommentTypeId[];
  estimateStatuses: EstimateStatuses;
  estimateCodesSelectionState: EstimateCodeSelectionState;
  userCanEdit: boolean;
  currentEstEditBlocked: boolean;
  selectedCurrencyScenario: CurrencyScenario;
  setShowCopyDialog: (value: boolean) => void;
  onChangeComponentRows: (activityId: string, rows: CostComponentEditRow[]) => void;
  errors: { [field: string]: boolean };
  setErrors: (errors: { [field: string]: boolean }) => void;
  selectedActivityId: string;
  setSelectedActivityId: (id: string) => void;
}

function CostItemsTable(props: CostItemsTableProps): React.ReactElement {
  const {
    projectId,
    activityRows,
    comments,
    setComments,
    commentTypes,
    estimateStatuses,
    estimateCodesSelectionState,
    userCanEdit,
    currentEstEditBlocked,
    selectedCurrencyScenario,
    setShowCopyDialog,
    onChangeComponentRows,
    errors,
    setErrors,
    selectedActivityId,
    setSelectedActivityId,
  } = props;

  const [displayActivityComments, setDisplayActivityComments] = useState<ActivityId | undefined>(undefined);
  const [hideEmptyRows, setHideEmptyRows] = useLocalStorage("hideEmptyRows", false);
  const [hideHours, setHideHours] = useLocalStorage("hideHours", false);
  const hideHourColumnsIds = ["estimatedHours", "actualHours"];
  const displayRows = hideEmptyRows
    ? activityRows.filter(row => isValuesAvailable(row.values, row.summaryValues))
    : activityRows;

  function findComments(activityId: string): ProjectCostActivityComment[] | undefined {
    const foundItem = comments.find(c => c.activityId === activityId);
    return foundItem ? foundItem.comments : undefined;
  }

  function setActivityComments(activityId: ActivityId, newComments: ProjectCostActivityComment[]) {
    const newActivityComments = comments.filter(item => item.activityId !== activityId);
    newActivityComments.push({ activityId: activityId, comments: newComments });
    setComments(newActivityComments);
  }

  const summaryHeaders = hideHours
    ? PROJECT_COST_SUMMARY_HEADERS.filter(header => !hideHourColumnsIds.includes(header.id))
    : PROJECT_COST_SUMMARY_HEADERS;

  return (
    <CostsTable>
      <TableBody>
        <HeaderRow>
          <HeaderCell>
            <ToggleSwitch
              checked={hideEmptyRows}
              onChange={value => setHideEmptyRows(value)}
              text={"Hide empty records"}
              color={settingGreen}
              fontSize={"10px"}
            />
            <ToggleSwitch
              checked={hideHours}
              onChange={value => setHideHours(value)}
              text={"Hide hours columns"}
              color={settingGreen}
              fontSize={"10px"}
            />
          </HeaderCell>
          {summaryHeaders.map((header, i) => (
            <HeaderCell
              key={i}
              widthLimit={
                header.id === "proposedBudgetChange"
                  ? 50
                  : header.id === "estimatedHours" || header.id === "actualHours"
                  ? 30
                  : undefined
              }
            >
              {header.id === EDITABLE_COST_COMPONENTS[1] &&
              activityRows.length > 0 &&
              !currentEstEditBlocked &&
              isColumnEditable(
                estimateStatuses[EDITABLE_COST_COMPONENTS[1]].estimateValue,
                userCanEdit,
                selectedCurrencyScenario
              ) ? (
                <HeaderCellContainer>
                  {header.description}
                  <IconButton
                    title={"Copy values from committed"}
                    onClick={() => {
                      setShowCopyDialog(true);
                    }}
                    fontSize={"10px"}
                  >
                    <FontAwesomeIcon icon={faCopy} size="1x" color={editBlue} />
                  </IconButton>
                </HeaderCellContainer>
              ) : (
                header.description
              )}
            </HeaderCell>
          ))}
        </HeaderRow>
        {displayRows.map((itemRow, i) => (
          <CostSummaryRow
            key={i}
            row={itemRow}
            projectId={projectId}
            comments={findComments(itemRow.id)}
            setComments={newComments => setActivityComments(itemRow.id, newComments)}
            commentTypes={commentTypes}
            displayComments={itemRow.id === displayActivityComments}
            setDisplayComments={id => setDisplayActivityComments(id)}
            estimateStatuses={estimateStatuses}
            estimateCodesSelectionState={estimateCodesSelectionState}
            userCanEdit={userCanEdit}
            selectedCurrencyScenario={selectedCurrencyScenario}
            onChangeComponentRows={onChangeComponentRows}
            errors={errors}
            setErrors={setErrors}
            selectedActivityId={selectedActivityId}
            setSelectedActivityId={setSelectedActivityId}
            summaryHeaders={summaryHeaders}
          />
        ))}
      </TableBody>
    </CostsTable>
  );
}

export default CostItemsTable;

const CostsTable = styled.table`
  margin-top: 85px;
  width: 100%;
  border-collapse: collapse;
  font-size: 10px;
  color: ${valmetGreyLight};
  tr:last-child {
    td {
      padding-bottom: 4px;
    }
  }
`;

const TableBody = styled.tbody``;

const HeaderRow = styled.tr`
  th:last-child {
    border-right: 0;
  }
  th {
    position: sticky;
    top: 85px;
  }
`;

const HeaderCell = styled.th<{ widthLimit?: number }>`
  border-right: 1px solid ${valmetGreyREC};
  border-bottom: 1px solid ${valmetGreyREC};
  text-transform: uppercase;
  padding-top: 4px;
  padding-bottom: 4px;
  background: #ffffff;
  ${({ widthLimit }) => widthLimit && `max-width:${widthLimit}px;`};
`;

const HeaderCellContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;
