import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  AppState,
  ProjectId,
  ProjectTag,
  ProjectTagsEditInformationData,
  ProjectTagsSectionInput,
} from "../../../../../common/types";
import { ThunkDispatch } from "redux-thunk";
import { Action } from "redux";
import { setProjectTags } from "../../../../../actions/projectActions";
import { useQuery } from "@apollo/client/react/hooks";
import { GET_PROJECT_TAGS_EDIT_INFORMATION } from "./queries";
import { cloneDeep, differenceWith } from "lodash";
import { connect } from "react-redux";
import LoadingView from "../../../../LoadingView";
import styled from "styled-components";
import { defaultGrey, projectsYellow, settingGreen, valmetGreyLight } from "../../../../../common/colors";
import { IconButton } from "../../../../../common/components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faPlusCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import EditableSearchableDropdown from "../EditableComponents/EditableSearchableDropdown";
import { getEmptyProjectTagsSectionInput } from "../../../../../common/constants";

export interface EditDetailsProps {
  projectId: ProjectId;
  tags: ProjectTag[];
}

const mapStateToProps = (state: AppState) => {
  return {
    sectionInput: state.projectState.projectInput
      ? state.projectState.projectInput.projectTags
      : getEmptyProjectTagsSectionInput(),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, void, Action>) => {
  return {
    setProjectTagsInput: (input: ProjectTagsSectionInput) => dispatch(setProjectTags(input)),
  };
};

const tagsEqual = (a: { id: string }, b: { id: string }): boolean => a.id === b.id;

function EditDetails(
  props: EditDetailsProps & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
): React.ReactElement {
  const { projectId, tags, sectionInput, setProjectTagsInput: setSectionInput } = props;
  const [userRemoved, setUserRemoved] = useState<ProjectTag[]>([]);
  const projectTagsInput = sectionInput.input;

  const { data, error, loading } = useQuery<ProjectTagsEditInformationData>(GET_PROJECT_TAGS_EDIT_INFORMATION, {
    variables: { projectId: projectId },
    // default fetch policy is "cache-first" which does NOT make the query if data available locally.
    // read more at: https://www.apollographql.com/docs/react/data/queries/#setting-a-fetch-policy
    fetchPolicy: "cache-and-network",
  });

  const tagsEditInformation = data?.projectDetailsEditInformation.tags;

  const onEditStateChange = useCallback(() => {
    if (tagsEditInformation) {
      setSectionInput({ input: tagsEditInformation.editableTags, pristine: true });
    }
  }, [setSectionInput, tagsEditInformation]);

  useEffect(onEditStateChange, [tagsEditInformation]);

  const combinedTags = useMemo(() => {
    const combinedTags: ProjectTag[] = [];
    tags.forEach(tag => {
      if (!tagsEditInformation?.editableTags.includes(tag) && !userRemoved.includes(tag)) {
        combinedTags.push(tag);
      } else {
        if (projectTagsInput?.includes(tag) && !userRemoved.includes(tag)) {
          combinedTags.push(tag);
        }
      }
    });

    return combinedTags.concat(differenceWith(projectTagsInput, combinedTags, tagsEqual));
  }, [tags, projectTagsInput, tagsEditInformation, userRemoved]);

  const availableTags = useMemo(
    () => differenceWith(tagsEditInformation?.allSelectableTags, projectTagsInput ? projectTagsInput : [], tagsEqual),
    [tagsEditInformation, projectTagsInput]
  );

  const addNewTag = () => {
    if (availableTags) {
      const newSectionInput = cloneDeep(projectTagsInput);
      newSectionInput.push(availableTags[0]);
      setSectionInput({ input: newSectionInput, pristine: false });
    }
  };

  const editTag = (newTag: ProjectTag | null, oldTag: ProjectTag) => {
    if (newTag) {
      const newSectionInput = cloneDeep(projectTagsInput);
      newSectionInput.splice(newSectionInput.indexOf(oldTag), 1, newTag);
      setSectionInput({ input: newSectionInput, pristine: false });
    }
  };

  const removeTag = (tag: ProjectTag) => {
    const newSectionInput = differenceWith(projectTagsInput, [tag], tagsEqual);
    setSectionInput({ input: newSectionInput, pristine: false });
    setUserRemoved([...userRemoved, tag]);
  };

  const userCreatedTags: ProjectTag[] = differenceWith(projectTagsInput, tags, tagsEqual).concat(userRemoved);

  return loading ? (
    <LoadingView />
  ) : data !== undefined && !error ? (
    <TagsSection>
      {combinedTags.map(tag =>
        userCreatedTags.includes(tag) ? (
          <EditableTagView key={tag.id}>
            <EditableTagViewInner>
              <EditableSearchableDropdown
                value={tag.id}
                onValueChanged={value => editTag(value ? { id: value, description: value } : null, tag)}
                options={availableTags
                  .map(tag => {
                    return { id: tag.id, description: tag.id };
                  })
                  .concat([{ id: tag.id, description: tag.id }])}
                searchable={false}
              />
              <IconButton onClick={() => removeTag(tag)} title="Remove">
                <FontAwesomeIcon icon={faTimes} size="1x" color={valmetGreyLight} />
              </IconButton>
            </EditableTagViewInner>
          </EditableTagView>
        ) : (
          <Tag
            key={tag.id}
            tag={tag}
            onRemoveTag={() => removeTag(tag)}
            isUserEditable={!!tagsEditInformation?.editableTags.find(value => value.id === tag.id)}
          />
        )
      )}
      <ButtonContainer>
        <IconButton
          onClick={addNewTag}
          title={!availableTags || !availableTags.length ? "No available tags" : "Add a new tag"}
          disabled={!availableTags || !availableTags.length}
        >
          <FontAwesomeIcon icon={faPlusCircle} size="1x" color={settingGreen} />
        </IconButton>
      </ButtonContainer>
    </TagsSection>
  ) : (
    <ErrorContainer>Error loading project tags data.</ErrorContainer>
  );
}

type TagProps = { tag: ProjectTag; onRemoveTag: () => void; isUserEditable?: boolean };

export const Tag: React.FC<TagProps> = (props: TagProps) => {
  const { isUserEditable, tag, onRemoveTag } = props;

  return (
    <TagView>
      <Tooltip>{tag.description || tag.id}</Tooltip>
      <span>{tag.id}</span>
      <IconButton
        onClick={onRemoveTag}
        disabled={!isUserEditable}
        title={isUserEditable ? "Remove" : "Tag is not editable"}
      >
        <FontAwesomeIcon icon={isUserEditable ? faTimes : faLock} size="1x" color={valmetGreyLight} />
      </IconButton>
    </TagView>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EditDetails);

const TagsSection = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Tooltip = styled.div`
  visibility: hidden;
  position: absolute;
  top: 35px;
  left: 0;
  max-width: 600px;
  background: ${defaultGrey};
  border-radius: 6px;
  color: #ffffff;
  text-align: center;
  margin-top: 10px;
  width: max-content;
  padding: 4px;
  font-size: 12px;
  word-break: break-word;
  z-index: 1;

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: -8px;
    left: 20px;
    border-color: transparent transparent ${defaultGrey} transparent;
    border-style: solid;
    border-width: 0 6px 8px 6px;
  }
`;

const TagView = styled.div`
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: bold;
  background: ${projectsYellow};
  margin-right: 20px;
  height: 35px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  &:hover {
    ${Tooltip} {
      visibility: visible;
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const EditableTagView = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: flex-start;
  height: 35px;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${projectsYellow};
`;

const EditableTagViewInner = styled.div`
  display: flex;
  align-items: center;
`;

const ErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;
