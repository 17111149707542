import React from "react";
import { ProjectRoles, ProjectRolesVisibility } from "../../../../../common/types";
import styled from "styled-components";

export interface ViewDetailsProps {
  roles: ProjectRoles;
  rolesVisibility: ProjectRolesVisibility;
}

export const getDataItem = (fieldName: string, projectRoles: ProjectRoles) => {
  const role = projectRoles[fieldName];
  return <DataItem>{role ? `${role.firstName} ${role.lastName}` : "-"}</DataItem>;
};

function ViewDetails(props: ViewDetailsProps): React.ReactElement {
  const { roles, rolesVisibility } = props;
  return (
    <RoleSection>
      <InformationItem>
        {rolesVisibility.mainProjectManager && (
          <>
            <TitleItem>Main project manager:</TitleItem>
            {getDataItem("mainProjectManager", roles)}
          </>
        )}
      </InformationItem>
      <InformationItem>
        {rolesVisibility.mainProjectController && (
          <>
            <TitleItem>Main project controller:</TitleItem>
            {getDataItem("mainProjectController", roles)}
          </>
        )}
      </InformationItem>
      <InformationItem>
        {rolesVisibility.projectController && (
          <>
            <TitleItem>Project controller:</TitleItem>
            {getDataItem("projectController", roles)}
          </>
        )}
      </InformationItem>
      <InformationItem>
        {rolesVisibility.projectManager && (
          <>
            <TitleItem>Project manager:</TitleItem>
            {getDataItem("projectManager", roles)}
          </>
        )}
      </InformationItem>
      <InformationItem>
        {rolesVisibility.projectManagersManager && (
          <>
            <TitleItem>{"Project manager's manager:"}</TitleItem>
            {getDataItem("projectManagersManager", roles)}
          </>
        )}
      </InformationItem>
      <InformationItem>
        {rolesVisibility.projectCoordinator && (
          <>
            <TitleItem>Project coordinator:</TitleItem>
            {getDataItem("projectCoordinator", roles)}
          </>
        )}
      </InformationItem>
    </RoleSection>
  );
}

export default ViewDetails;

const RoleSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;

const InformationItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const DataItem = styled.div<{ emphasize?: boolean }>`
  font-weight: bold;
  font-size: ${({ emphasize }) => (emphasize ? "22px" : "14px")};
`;

const TitleItem = styled.div`
  font-size: 14px;
  margin-bottom: 4px;
`;
