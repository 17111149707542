import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import styled from "styled-components";
import {
  addMainProjectChange,
  addNewNode,
  moveNode,
  moveProject,
  removeNode,
  resetProjectHierarchy,
  setModifiedHierarchy,
  setProjectHierarchyAddedNodes,
  setProjectHierarchyParentNodeId,
} from "../../../../../actions/hierarchyActions";
import { defaultGrey, errorRed } from "../../../../../common/colors";
import {
  AppState,
  EntityTypeId,
  FilterEnum,
  HierarchyItem,
  HierarchyItemEnum,
  HierarchyTreeNode,
  MainProjectChangeRequest,
  NewNodeRequest,
  NodeRequest,
  ProjectRequest,
  ProjectTypes,
  RemoveNodeRequest,
} from "../../../../../common/types";
import HierarchyTree from "../../ProjectHierarchy/HierarchyTree";
import { BasicDataSectionType } from "../../../../../common/constants";
import { HierarchyProjectsFilters, inactiveHierarchyProjectsFilters } from "../../../../../common/hierarchyUtils";
import { validateHierarchyTree } from "../../ProjectHierarchy/HierarchyTree/utils";
import { saveEditedProjectHierarchy } from "./queries";
import { useApolloClient } from "@apollo/client/react/hooks";
import { ApolloClient } from "@apollo/client";
import { setProjectHierarchy } from "../../../../../actions/projectActions";
import Section from "../Section";
import { useLocalStorage } from "../../../../../hooks/useLocalStorage";
import ErrorDialog from "./ErrorDialog";
import { pollForProjectHierarchyChangesReady } from "../../ProjectHierarchy/queries";
import AdditionalHeaderButtons from "./AdditionalHeaderButtons";
import { ToggleSwitch } from "../../../../../common/components";

interface HierarchySectionProps {
  projectId: number;
  itemType: HierarchyItemEnum;
  hierarchy: HierarchyItem;
  sectionEditable: boolean;
  editSectionType: BasicDataSectionType | undefined;
  setEditSectionType: (type: BasicDataSectionType | undefined) => void;
}

const mapStateToProps = (state: AppState) => {
  return {
    hierarchyState: state.hierarchyState,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setProjectHierarchyAddedNodes: (addedNodes: HierarchyTreeNode[]) => {
      dispatch(setProjectHierarchyAddedNodes(addedNodes));
    },
    setProjectHierarchyParentNodeId: (parentNodeId: number | undefined | null) => {
      dispatch(setProjectHierarchyParentNodeId(parentNodeId));
    },
    resetProjectHierarchy: () => {
      dispatch(resetProjectHierarchy());
    },
    setModifiedHierarchy: (modifiedHierarchy: HierarchyItem | undefined) => {
      dispatch(setModifiedHierarchy(modifiedHierarchy));
    },
    addNewNode: (node: NewNodeRequest) => {
      dispatch(addNewNode(node));
    },
    moveProject: (project: ProjectRequest) => {
      dispatch(moveProject(project));
    },
    moveNode: (node: NodeRequest) => {
      dispatch(moveNode(node));
    },
    removeNode: (node: RemoveNodeRequest) => {
      dispatch(removeNode(node));
    },
    addMainProjectChange: (mainProjectChange: MainProjectChangeRequest) => {
      dispatch(addMainProjectChange(mainProjectChange));
    },
    setProjectHierarchy: (hierarchy: HierarchyItem) => {
      dispatch(setProjectHierarchy(hierarchy));
    },
  };
};

const TYPE: BasicDataSectionType = BasicDataSectionType.Hierarchy;

function HierarchySection(
  props: HierarchySectionProps & ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>
): React.ReactElement {
  const {
    projectId,
    itemType,
    hierarchy,
    sectionEditable,
    editSectionType,
    setEditSectionType,
    setProjectHierarchyParentNodeId,
    setProjectHierarchyAddedNodes,
    resetProjectHierarchy,
    hierarchyState,
    setModifiedHierarchy,
    addNewNode,
    moveProject,
    moveNode,
    removeNode,
    addMainProjectChange,
    setProjectHierarchy,
  } = props;

  const { modifiedHierarchy, parentNodeId: selectedParentNodeId, addedNodes } = hierarchyState;
  const [editMode, setEditMode] = useState(false);
  const [displayInactiveItems, setDisplayInactiveItems] = useState(false);
  const [hierarchyProjectsFilters, setHierarchyProjectsFilters] = useLocalStorage(
    "hierarchyProjectsFilters",
    inactiveHierarchyProjectsFilters
  );
  const [groupAdjustments, setGroupAdjustments] = useLocalStorage("hierarchyGroupAdjustments", false);
  const [errors, setErrors] = useState<string[]>([]);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [saving, setSaving] = useState(false);

  const displayedHierarchy = modifiedHierarchy ? modifiedHierarchy : hierarchy;

  const disabled = editSectionType !== undefined && editSectionType !== TYPE;

  const client = useApolloClient() as ApolloClient<Record<string, unknown>>;
  const hasChanges =
    hierarchyState.newNodes.length > 0 ||
    hierarchyState.movedNodes.length > 0 ||
    hierarchyState.movedProjects.length > 0 ||
    hierarchyState.removedNodes.length > 0 ||
    hierarchyState.mainProjectChanges.length > 0;

  useEffect(() => {
    if (validationErrors.length > 0) {
      setValidationErrors([]);
    }
  }, [hierarchyState]);

  const endEditing = useCallback(() => {
    setEditSectionType(undefined);
    setEditMode(false);
    setModifiedHierarchy(undefined);
    resetProjectHierarchy();
  }, [setEditSectionType, setEditMode, setModifiedHierarchy, resetProjectHierarchy]);

  const { pollForReady, ready, loading: polling, error: pollingError } = pollForProjectHierarchyChangesReady();

  useEffect(() => {
    if (ready && !polling && !pollingError) {
      endEditing();
    }
    if (!polling) {
      setSaving(false);
      pollingError && setErrors(errors.concat([pollingError]));
    }
  }, [ready, polling, pollingError]);

  return (
    <Section
      header="Project hierarchy"
      stickyHeader={true}
      disabled={disabled}
      editMode={editMode}
      editable={sectionEditable}
      saving={saving}
      saveEnabled={hasChanges && validationErrors.length === 0}
      errors={validationErrors.length > 0 ? validationErrors : undefined}
      onEditClicked={() => {
        setEditSectionType(TYPE);
        setEditMode(true);
      }}
      onCancelEdit={() => {
        endEditing();
      }}
      onSaveClicked={() => {
        const errors = validateHierarchyTree(hierarchyState);
        console.log("validation errors ", errors);
        if (!errors) {
          setSaving(true);
          setValidationErrors([]);
          saveEditedProjectHierarchy(
            client,
            msgs => (msgs ? setErrors(msgs) : setErrors([])),
            hierarchyState.newNodes,
            hierarchyState.movedNodes,
            hierarchyState.movedProjects,
            hierarchyState.removedNodes,
            hierarchyState.mainProjectChanges,
            projectId,
            setProjectHierarchy
          ).then(({ successful, applicationModifiedDateTime }) => {
            if (successful && applicationModifiedDateTime !== undefined) {
              pollForReady(projectId, applicationModifiedDateTime);
            } else {
              setSaving(false);
            }
          });
        } else {
          setValidationErrors(errors);
        }
      }}
      additionalHeaderButtons={
        <>
          <ToggleSwitch
            onChange={setGroupAdjustments}
            checked={groupAdjustments}
            text={"Group adjustments to related"}
          />
          <AdditionalHeaderButtons
            itemType={itemType}
            projectId={projectId}
            hierarchyProjectsFilters={hierarchyProjectsFilters}
            setHierarchyProjectsFilters={setHierarchyProjectsFilters}
            displayInactiveItems={displayInactiveItems}
            setDisplayInactiveItems={setDisplayInactiveItems}
          />
        </>
      }
    >
      <Container>
        {displayedHierarchy && (
          <HierarchyTree
            projectId={projectId}
            hierarchy={displayedHierarchy}
            selectedParentNodeId={selectedParentNodeId}
            setSelectedParentNodeId={setProjectHierarchyParentNodeId}
            addedNodes={addedNodes}
            setAddedNodes={setProjectHierarchyAddedNodes}
            projectName={""}
            resetProjectHierarchy={() => resetProjectHierarchy()}
            displayInactiveItems={displayInactiveItems}
            drawOptions={{ groupAdjustments, hierarchyFilters: hierarchyProjectsFilters }}
            disableBorders={true}
            advancedMode={true}
            setModifiedHierarchy={setModifiedHierarchy}
            readOnly={!editMode}
            addNewNode={addNewNode}
            moveProject={moveProject}
            moveNode={moveNode}
            removeNode={removeNode}
            addMainProjectChange={addMainProjectChange}
            setHasErrors={hasErrors => hasErrors && setValidationErrors(["Error editing hierarchy."])}
          />
        )}
      </Container>
      {errors.length > 0 && <ErrorDialog errors={errors} onClose={() => setErrors([])} />}
    </Section>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(HierarchySection);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-bottom: 20px;
  color: ${defaultGrey};
`;
