import React from "react";
import { ProjectCurrency, ProjectCurrencyVisibility, ProjectId } from "../../../../../common/types";
import EditDetails from "./EditDetails";
import CreateProjectSection from "../CreateProjectSection";
import styled from "styled-components";
import { defaultGrey } from "../../../../../common/colors";

export interface CurrencyCreateProjectSectionProps {
  projectId: ProjectId;
  sectionDetails?: ProjectCurrency;
  sectionVisibility: ProjectCurrencyVisibility;
}

function CurrencyCreateProjectSection(props: CurrencyCreateProjectSectionProps): React.ReactElement {
  const { projectId, sectionDetails, sectionVisibility } = props;
  return (
    <CreateProjectSection
      header="Currency"
      loadingErrors={sectionDetails ? undefined : ["No data found for project currency."]}
    >
      <Container>
        {sectionDetails && (
          <EditDetails projectId={projectId} currency={sectionDetails} currencyVisibility={sectionVisibility} />
        )}
      </Container>
    </CreateProjectSection>
  );
}

export default CurrencyCreateProjectSection;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-bottom: 0px;
  color: ${defaultGrey};
`;
