import React, {useCallback, useEffect, useState} from "react";
import styled from "styled-components";
import { FiltersSetsDropdownSection } from "./FiltersSetsDropdownSection";
import { FiltersSettingsModal } from "./FiltersSettingsModal/FiltersSettingsModal";
import {AppState, FilterValues, ProjectFiltersId} from "../../../../common/types";
import { useMutation, useQuery } from "@apollo/client/react/hooks";
import {
  CREATE_PROJECT_FILTERS,
  CreateProjectFiltersResult,
  DELETE_PROJECT_FILTERS,
  LIST_PROJECT_FILTERS, SELECT_PROJECT_FILTERS, UPDATE_PROJECT_FILTERS_NAME,
  UpdateProjectFiltersResult,
} from "./queries";
import { Dispatch } from "redux";
import { resetSelectedFilters, setSelectedFilters } from "../../../../actions/filterActions";
import { connect } from "react-redux";
import { settingGreen, valmetGreyHeader } from "../../../../common/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { IconButton } from "../../../../common/components";
import { initState } from "../../../../reducers/filtersReducer";

export interface ProjectFiltersData {
  id: ProjectFiltersId;
  name: string;
  filters: FilterValues;
}

interface ListProjectFiltersResult {
  selectedId: ProjectFiltersId | null;
  lists: ProjectFiltersData[];
}

const mapStateToProps = (state: AppState) => {
  return {
    selectedId: state.filtersState.selectedFiltersId,
    selectedName: state.filtersState.selectedFiltersName,
    filterValues: state.filtersState.filters,
    filtersPristine: state.filtersState.filtersPristine,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setSelectedFilters: (selected: ProjectFiltersData) => {
      dispatch(setSelectedFilters(selected));
    },
    resetSelectedFilters: () => dispatch(resetSelectedFilters()),
  };
};

interface ModalEditInfo {
  isOpen: boolean;
  filtersId: ProjectFiltersId | undefined;
  filtersName: string;
}

const closedModalInfo = { isOpen: false, filtersId: undefined, filtersName: "", filterValues: initState.filters };

const updateFiltersNameInLists = (lists: ProjectFiltersData[], filtersId: ProjectFiltersId, newName: string): ProjectFiltersData[] => {
  return lists.map(filters => (filters.id === filtersId ? {...filters, name: newName} : filters))
};

const appendNewFilters = (lists: ProjectFiltersData[], newFilters: ProjectFiltersData)=> {
  return [...lists, newFilters]
}

const deleteFiltersList = (lists: ProjectFiltersData[], filterId: ProjectFiltersId): ProjectFiltersData[] => {
  return lists.filter(item => item.id !== filterId);
};

const cleanGraphqlProjectFilters = (graphql: FilterValues, hierarchy?: number[]): FilterValues => {
  return {
    hierarchy: hierarchy,
    projectPhase: graphql.projectPhase || initState.filters.projectPhase,
    technicalType: graphql.technicalType || initState.filters.technicalType,
    projectType: graphql.projectType || initState.filters.projectType,
    projectStatus: graphql.projectStatus || initState.filters.projectStatus,
    businessGroup: graphql.businessGroup || initState.filters.businessGroup,
    businessType: graphql.businessType || initState.filters.businessType,
    person: graphql.person || initState.filters.person,
    projectPeriod: graphql.projectPeriod || initState.filters.projectPeriod,
    oblPeriod: graphql.oblPeriod || initState.filters.oblPeriod,
    project: graphql.project || initState.filters.project,
    deliveryOrg: graphql.deliveryOrg || initState.filters.deliveryOrg,
    endDestCountry: graphql.endDestCountry || initState.filters.endDestCountry,
    legalEntity: graphql.legalEntity || initState.filters.legalEntity,
    extLegalEntity: graphql.extLegalEntity || initState.filters.extLegalEntity,
    estSalesPrice: graphql.estSalesPrice
      ? {
          min: graphql.estSalesPrice.min || undefined,
          max: graphql.estSalesPrice.max || undefined,
        }
      : initState.filters.estSalesPrice,
    customer: graphql.customer || initState.filters.customer,
    financingMethod: graphql.financingMethod || initState.filters.financingMethod,
  };
};

function FiltersConfiguration(
  props: ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
): React.ReactElement {
  const { selectedId, selectedName, filterValues, setSelectedFilters, resetSelectedFilters } = props;
  const [lists, setLists] = useState<ProjectFiltersData[]>([]);
  const [modalEditInfo, setModalEditInfo] = useState<ModalEditInfo>(closedModalInfo);
  const [loadingCompleted, setLoadingCompleted] = useState<boolean>(false);

  const { loading, error } = useQuery<{ listProjectFilters: ListProjectFiltersResult }>(LIST_PROJECT_FILTERS, {
    fetchPolicy: "cache-and-network",
    onCompleted: data => {
      const cleanedLists =
        data && data.listProjectFilters.lists
          ? data.listProjectFilters.lists.map(item => {
              const cleanedFilters = cleanGraphqlProjectFilters(item.filters, filterValues.hierarchy);
              return { ...item, filters: cleanedFilters };
            })
          : [];
      setLists(cleanedLists);
      if (data && data.listProjectFilters.selectedId) {
        const findSelected = cleanedLists.find(filters => filters.id === data.listProjectFilters.selectedId);
        if (findSelected) setSelectedFilters(findSelected);
      }
      setLoadingCompleted(true);
    },
  });

  const updateFiltersName = useCallback((filtersId: ProjectFiltersId) => {
    const [updateMutation, {data, loading}] = useMutation<{ updateProjectFiltersName: UpdateProjectFiltersResult }>(UPDATE_PROJECT_FILTERS_NAME);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const save = (newName: string) => updateMutation({variables: {projectFiltersId: filtersId, name: newName}}).then(({data}) => {
      if (data && data.updateProjectFiltersName.successful) {
        setLists(updateFiltersNameInLists(lists, filtersId, newName))
        if (filtersId === selectedId) setSelectedFilters({
          id: selectedId,
          name: newName,
          filters: filterValues
        })
      } else if (data && data.updateProjectFiltersName.error) {
        setErrorMessage(data.updateProjectFiltersName.error);
      }
    }).catch(apolloError => {
      setErrorMessage(apolloError.message);
    })
    return {
      saveName: save,
      successful: !!data && data.updateProjectFiltersName.successful,
      loading: loading,
      error: errorMessage,
    };
  }, [lists, setLists, setSelectedFilters, selectedId, filterValues])

  const createNewFilters = useCallback((filtersValue: FilterValues) => {
    const [createMutation, {data, loading}] = useMutation<{ createProjectFilters: CreateProjectFiltersResult }>(CREATE_PROJECT_FILTERS);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const save = (newName: string) => createMutation({variables: {name: newName, filters: filtersValue}}).then(({data}) => {
      if (data && data.createProjectFilters.successful && data.createProjectFilters.id) {
        const newFilters = {
          id: data.createProjectFilters.id,
          name: newName,
          filters: filtersValue,
        }
        setLists(appendNewFilters(lists, newFilters))
        setSelectedFilters(newFilters);
      } else if (data && data.createProjectFilters.error) {
        setErrorMessage(data.createProjectFilters.error);
      }
    }).catch(apolloError => {
      setErrorMessage(apolloError.message);
    })
    return {
      saveName: save,
      successful: !!data && data.createProjectFilters.successful,
      loading: loading,
      error: errorMessage,
    };
  }, [lists, setLists, setSelectedFilters])

  const [deleteMutation, { loading: deleting }] = useMutation<{
    deleteProjectFilters: UpdateProjectFiltersResult;
  }>(DELETE_PROJECT_FILTERS);
  const deleteFilters = useCallback(
    (lists: ProjectFiltersData[], filtersId: ProjectFiltersId) => {
      deleteMutation({ variables: { projectFiltersId: filtersId } }).then(({ data }) => {
        if (data && data.deleteProjectFilters.successful) {
          const newLists = deleteFiltersList(lists, filtersId);
          setLists(newLists);
          if (selectedId === filtersId && newLists.length > 0) {
            setSelectedFilters(newLists[0]);
          } else if (newLists.length === 0) {
            resetSelectedFilters();
          }
        }
      });
    },
    [selectedId, deleteMutation]
  );

  const [selectMutation] = useMutation<{ updateAndSelectFilters: UpdateProjectFiltersResult }>(
    SELECT_PROJECT_FILTERS
  );
  useEffect(() => {
    if (loadingCompleted && selectedId !== undefined) {
      // update exiting filters
      selectMutation({
        variables: { projectFiltersId: selectedId, },
      });
    }
  }, [selectedId]);

  return loading ? (
    <Container>Loading filters...</Container>
  ) : error ? (
    <Container>Error loading filters</Container>
  ) : (
    <Container>
      {deleting && <UpdateState>Deleting filters...</UpdateState>}
      <FiltersSetsDropdownSection
        selectedFilters={selectedId ? { id: selectedId, name: selectedName, filters: filterValues } : undefined}
        setSelectedFilters={setSelectedFilters}
        lists={lists}
        editFilters={filters =>
          setModalEditInfo({
            isOpen: true,
            filtersId: filters.id,
            filtersName: filters.name,
          })
        }
        deleteFilters={filters => deleteFilters(lists, filters.id)}
      />
      <IconButton
        color={settingGreen}
        hasText={true}
        onClick={() =>
          setModalEditInfo({ isOpen: true, filtersId: undefined, filtersName: "" })
        }
      >
        Add filters
        <FontAwesomeIcon icon={faPlus} size="1x" color={settingGreen} />
      </IconButton>
      {modalEditInfo.isOpen && (
        <FiltersSettingsModal
          editingFiltersId={modalEditInfo.filtersId}
          filtersName={modalEditInfo.filtersName}
          onSave={() => modalEditInfo.filtersId ? updateFiltersName(modalEditInfo.filtersId) : createNewFilters(filterValues)}
          closeModal={() => setModalEditInfo(closedModalInfo)}
        />
      )}
    </Container>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(FiltersConfiguration);

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-direction: row;
`;

const UpdateState = styled.div`
  font-size: 10px;
  color: ${valmetGreyHeader};
  margin-right: 20px;
`;
