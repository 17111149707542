import React from "react";
import styled from "styled-components";
import EstimateCodeSelection from "./EstimateCodeSelection";
import StatusSelection from "./StatusSelection/StatusSelection";
import {
  EstimateCode,
  EstimateCodeStatusId,
  EstimateType,
  ProjectCostEstimateType,
  ProjectCostEstimateValue,
  ProjectId,
  ProjectVirtualType,
  StatusLightValue,
} from "../../../../../common/types";

export interface ColumnSettingsViewProps {
  projectId: ProjectId;
  projectVirtualType: ProjectVirtualType;
  estimateType: EstimateType;
  onStatusChanged?: (statusCode: EstimateCodeStatusId) => void;
  onEstimateCodeSelected?: (estimateCode: EstimateCode) => void;
  estimateCodesAndStatuses?: ProjectCostEstimateType;
  selectedEstimateCode?: EstimateCode;
  statusValue?: ProjectCostEstimateValue;
  onCopy?: () => void;
  statusLocked?: boolean;
  statusLockedReason?: string;
}

const createLnStatus = (statusValue: ProjectCostEstimateValue) => {
  if (!statusValue.isReconcilingToLn && statusValue.lnInterfaceMessage && statusValue.lnInterfaceMessage.length) {
    return [
      {
        color: "red",
        message: `LN interface error: ${statusValue.lnInterfaceMessage}`,
      },
    ];
  } else if (statusValue.isPopProcessing) {
    return [
      {
        color: "orange",
        message: "Pop is processing changes",
      },
    ];
  } else if (!statusValue.isReconcilingToLn) {
    return [
      {
        color: "orange",
        message: "LN is not reconciling",
      },
    ];
  } else {
    return [
      {
        color: "green",
        message: "Ready",
      },
    ];
  }
};

const createInterfaceStatus = (
  interfaceStatus: number,
  estimateType: EstimateType.RevisedBudget | EstimateType.Estimate,
  lnInterfaceMessage: string
) => {
  switch (interfaceStatus + "|" + estimateType) {
    case "10" + "|" + EstimateType.RevisedBudget:
    case "10" + "|" + EstimateType.Estimate:
      return [
        { color: "orange", message: "Waiting to be sent to LN" },
        { color: "orange", message: "Waiting to be sent to LN" },
      ];
    case "20" + "|" + EstimateType.RevisedBudget:
    case "20" + "|" + EstimateType.Estimate:
      return [
        { color: "orange", message: "Sent to LN" },
        { color: "orange", message: "Sent to LN" },
      ];
    case "30" + "|" + EstimateType.RevisedBudget:
      return [
        { color: "green", message: "Delivered to LN successfully" },
        { color: "orange", message: "Preparing snapshot to be sent to LN" },
      ];
    case "30" + "|" + EstimateType.Estimate:
      return [
        { color: "green", message: "Delivered to LN successfully" },
        { color: "orange", message: "Preparing estimate to be sent to PROFIT" },
      ];
    case "35" + "|" + EstimateType.RevisedBudget:
      return [
        { color: "green", message: "Delivered to LN successfully" },
        { color: "orange", message: "Preparing snapshot to be sent to LN" },
      ];
    case "35" + "|" + EstimateType.Estimate:
      return [
        { color: "green", message: "Delivered to LN successfully" },
        { color: "orange", message: "PROFIT status not yet active" },
      ];
    case "39" + "|" + EstimateType.RevisedBudget:
    case "39" + "|" + EstimateType.Estimate:
      return [
        { color: "red", message: `Error in LN interface: ${lnInterfaceMessage}` },
        { color: "orange", message: "Error in LN interface" },
      ];
    case "40" + "|" + EstimateType.RevisedBudget:
      return [
        { color: "green", message: "Delivered to LN successfully" },
        { color: "orange", message: "Snapshot sent to LN" },
      ];
    case "40" + "|" + EstimateType.Estimate:
      return [
        { color: "green", message: "Delivered to LN successfully" },
        { color: "orange", message: "Estimate sent to PROFIT" },
      ];
    case "50" + "|" + EstimateType.RevisedBudget:
      return [
        { color: "green", message: "Delivered to LN successfully" },
        { color: "green", message: "Snapshot acknowledgement received" },
      ];
    case "50" + "|" + EstimateType.Estimate:
      return [
        { color: "green", message: "Delivered to LN successfully" },
        { color: "orange", message: "PROFIT received" },
      ];
    case "51" + "|" + EstimateType.RevisedBudget:
      return [
        { color: "green", message: "Delivered to LN successfully" },
        { color: "green", message: "Snapshot acknowledgement received" },
      ];
    case "51" + "|" + EstimateType.Estimate:
      return [
        { color: "green", message: "Delivered to LN successfully" },
        { color: "orange", message: "Not sent to PROFIT (late estimate approval)" },
      ];
    case "53" + "|" + EstimateType.RevisedBudget:
      return [
        { color: "green", message: "Delivered to LN successfully" },
        { color: "green", message: "Snapshot acknowledgement received" },
      ];
    case "53" + "|" + EstimateType.Estimate:
      return [
        { color: "green", message: "Delivered to LN successfully" },
        { color: "orange", message: "PROFIT calculating" },
      ];
    case "56" + "|" + EstimateType.RevisedBudget:
      return [
        { color: "green", message: "Delivered to LN successfully" },
        { color: "green", message: "Snapshot acknowledgement received" },
      ];
    case "56" + "|" + EstimateType.Estimate:
      return [
        { color: "green", message: "Delivered to LN successfully" },
        { color: "green", message: "PROFIT confirmed success" },
      ];
    case "59" + "|" + EstimateType.RevisedBudget:
      return [
        { color: "green", message: "Delivered to LN successfully" },
        { color: "red", message: `Error in PROFIT: ${lnInterfaceMessage}` },
      ];
    case "59" + "|" + EstimateType.Estimate:
      return [
        { color: "green", message: "Delivered to LN successfully" },
        { color: "red", message: `Error in PROFIT: ${lnInterfaceMessage}` },
      ];
    default:
      return [
        { color: "orange", message: "Not to be sent" },
        { color: "orange", message: "Not to be sent" },
      ];
  }
};

const createServiceOrderInterfaceStatus = (
  interfaceStatus: number,
  estimateType: EstimateType.RevisedBudget | EstimateType.Estimate,
  lnInterfaceMessage: string
) => {
  switch (interfaceStatus) {
    case 10:
      return [{ color: "orange", message: "Waiting to be sent to LN" }];
    case 20:
      return [{ color: "orange", message: "Sent to LN" }];
    case 30:
      return [{ color: "green", message: "Delivered to LN successfully" }];
    case 39:
      return [{ color: "red", message: `Error in LN interface: ${lnInterfaceMessage}` }];
    case 40:
    case 50:
    case 59:
      return [{ color: "green", message: "Delivered to LN successfully" }];
    default:
      return [{ color: "orange", message: "Not to be sent" }];
  }
};

const createStatusLights = (
  projectVirtualType: ProjectVirtualType,
  statusValue: ProjectCostEstimateValue,
  estimateType: EstimateType
): StatusLightValue[] => {
  if (estimateType == EstimateType.RevisedBudget || estimateType == EstimateType.Estimate) {
    const { interfaceStatus, lnInterfaceMessage } = statusValue;
    if (projectVirtualType === ProjectVirtualType.LnServiceOrder) {
      return createServiceOrderInterfaceStatus(interfaceStatus || 0, estimateType, lnInterfaceMessage);
    } else {
      return createInterfaceStatus(interfaceStatus || 0, estimateType, lnInterfaceMessage);
    }
  } else {
    return createLnStatus(statusValue);
  }
};

function ColumnSettingsView(props: ColumnSettingsViewProps): React.ReactElement {
  const {
    projectVirtualType,
    estimateType,
    onEstimateCodeSelected,
    onStatusChanged,
    estimateCodesAndStatuses,
    selectedEstimateCode,
    statusValue,
    onCopy,
    statusLocked,
    statusLockedReason,
  } = props;

  const canEditLocked =
    projectVirtualType === ProjectVirtualType.LnServiceOrder &&
    (estimateType === EstimateType.Estimate || estimateType === EstimateType.RevisedBudget);

  // Find latest estimate code so can check if latest estimate code is selected. Only when latest estimate code is selected,
  // user can change the status of the estimate code.
  const latestEstimateCode = estimateCodesAndStatuses
    ? estimateCodesAndStatuses.estimateCodes.sort((a, b) => -a.id.localeCompare(b.id))[0].id
    : undefined;

  return (
    <Container>
      {onEstimateCodeSelected && (
        <EstimateCodeSelection
          estimateCodes={estimateCodesAndStatuses ? estimateCodesAndStatuses.estimateCodes : undefined}
          onValueSelected={onEstimateCodeSelected}
          selectedValue={selectedEstimateCode}
        />
      )}
      {onStatusChanged && statusValue ? (
        <StatusSelection
          value={statusValue.estimateCodeStatus}
          onValueSelected={onStatusChanged}
          statusLights={createStatusLights(projectVirtualType, statusValue, estimateType)}
          canEditLocked={canEditLocked}
          onCopy={onCopy}
          locked={statusLocked || statusValue.estimateCode !== latestEstimateCode}
          lockedReason={statusLockedReason}
        />
      ) : (
        <Placeholder />
      )}
    </Container>
  );
}

export default ColumnSettingsView;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Placeholder = styled.div`
  height: 26px;
`;
