import * as React from "react";
import onClickOutside, { HandleClickOutside, InjectedOnClickOutProps } from "react-onclickoutside";
import styled from "styled-components";
import { faPlus, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cloneDeep, filter, find } from "lodash";
import {
  valmetGreyBorder,
  filterGreen,
  settingGreen,
  defaultGrey,
  valmetGreyHeader,
  greyTransparent,
  disabledGrey,
} from "../../../../../common/colors";
import ColumnsSection from "./ColumnsSection";
import SelectedColumnsSection from "./SelectedColumnsSection";
import { ButtonContainer, IconButton } from "../../../../../common/components";
import { Column, ColumnSet, GeneratedColumn } from "../../../../../common/columnsTypes";
type Props = {
  availableColumns: Column[];
  onSave: (columnSet: ColumnSet, global: boolean) => void;
  editableColumnSet?: ColumnSet;
  resetEditableColumnSet: () => void;
  isAdmin: boolean;
};

type State = {
  isOpen: boolean;
  selectedColumns: (Column | GeneratedColumn)[];
  columnSetName: string;
  isGlobal: boolean;
};

class ColumnSettingsModal extends React.Component<
  Props & InjectedOnClickOutProps & HandleClickOutside<React.MouseEventHandler>,
  State
> {
  state: State = { isOpen: false, selectedColumns: [], columnSetName: "", isGlobal: false };

  componentDidUpdate(prevProps: Props) {
    const editableColumnSet = this.props.editableColumnSet;
    if (editableColumnSet && editableColumnSet !== prevProps.editableColumnSet) {
      this.props.enableOnClickOutside();
      this.setState({
        isOpen: true,
        selectedColumns: editableColumnSet.columns,
        columnSetName: editableColumnSet.description,
        isGlobal: editableColumnSet.isGlobal !== undefined ? editableColumnSet.isGlobal : false,
      });
    }
  }

  toggle = (): void => {
    if (this.state.isOpen) {
      this.props.disableOnClickOutside();
    } else {
      this.props.enableOnClickOutside();
      document.body.style.overflow = "hidden";
    }

    this.setState(state => ({ isOpen: !state.isOpen }));
  };

  handleClickOutside = (): void => {
    this.props.disableOnClickOutside();
    this.resetState();
  };

  selectColumn = (column: Column) => {
    const selectedColumns = cloneDeep(this.state.selectedColumns);
    selectedColumns.push(column);
    this.setState({ selectedColumns });
  };

  unSelectColumn = (column: Column | GeneratedColumn) => {
    const selectedColumns = cloneDeep(this.state.selectedColumns);
    this.setState({ selectedColumns: filter(selectedColumns, selectedColumn => selectedColumn.id !== column.id) });
  };

  resetState = () => {
    this.props.resetEditableColumnSet();
    this.setState({ isOpen: false, selectedColumns: [], isGlobal: false, columnSetName: "" });
    document.body.style.overflow = "auto";
  };

  saveColumnSet = () => {
    // TODO: Global column sets
    const columnSet: ColumnSet = {
      id: this.props.editableColumnSet ? this.props.editableColumnSet.id : "",
      description: this.state.columnSetName,
      columns: this.state.selectedColumns,
    };
    this.props.onSave(columnSet, this.state.isGlobal);
    this.resetState();
  };

  moveColumnIndex = (startIndex: number, targetIndex: number) => {
    const changedData = this.state.selectedColumns;
    const movedColumn = changedData.splice(startIndex, 1)[0];
    changedData.splice(startIndex > targetIndex ? targetIndex : targetIndex - 1, 0, movedColumn);
    this.setState({ selectedColumns: changedData });
  };

  render(): React.ReactElement {
    const selectedColumns = this.state.selectedColumns;
    const editableColumnSet = this.props.editableColumnSet;
    return (
      <Wrapper>
        <IconButton onClick={this.toggle} color={settingGreen} hasText={true}>
          Add view
          <FontAwesomeIcon icon={faPlus} size="1x" color={settingGreen} />
        </IconButton>
        {this.state.isOpen && (
          <FullscreenWrapper>
            <ContentContainer>
              {editableColumnSet ? "Edit view:" : "Add view:"}
              <NameRow>
                <NameInput
                  type="text"
                  placeholder="Enter view name"
                  value={this.state.columnSetName}
                  onChange={event => this.setState({ columnSetName: event.target.value })}
                />
              </NameRow>
              <ColumnsContainer>
                <ColumnsSection
                  columns={filter(
                    this.props.availableColumns,
                    column => !find(selectedColumns, selectedColumn => selectedColumn.id === column.id)
                  )}
                  onColumnSelected={this.selectColumn}
                />
                <SelectedColumnsSection
                  columns={selectedColumns}
                  onColumnSelected={this.unSelectColumn}
                  onColumnMove={this.moveColumnIndex}
                />
              </ColumnsContainer>
              <ActionRow>
                <InputColumn>
                  <GlobalRow
                    disabled={!this.props.isAdmin}
                    title={!this.props.isAdmin ? "Only Admin users can create global views" : undefined}
                  >
                    <GlobalInput
                      disabled={!this.props.isAdmin}
                      type="checkbox"
                      checked={this.state.isGlobal}
                      onChange={event => this.setState({ isGlobal: event.target.checked })}
                    />
                    Global, available for everyone
                  </GlobalRow>
                </InputColumn>
                <ButtonContainer>
                  <IconButton
                    onClick={this.saveColumnSet}
                    disabled={this.state.columnSetName.length === 0 || this.state.selectedColumns.length === 0}
                    hasText={true}
                  >
                    Save <FontAwesomeIcon icon={faSave} size="1x" color={settingGreen} />
                  </IconButton>
                  <IconButton onClick={() => this.resetState()} hasText={true}>
                    Cancel
                  </IconButton>
                </ButtonContainer>
              </ActionRow>
            </ContentContainer>
          </FullscreenWrapper>
        )}
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  display: inline-block;
`;

const FullscreenWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  z-index: 1200;
  background: ${greyTransparent};
`;

const ContentContainer = styled.div`
  background: white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  border: 1px solid ${valmetGreyBorder};
  z-index: 500;
  font-size: 24px;
  color:${defaultGrey}
  padding-top:20px;
  padding-bottom:20px;
  padding-left:25px;
  padding-right:25px;
  button:hover {
    background: ${filterGreen};
  }
  align-self: flex-start;
  margin-top: 150px;
`;

const ColumnsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 22px;
`;

const ActionRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 60px;
`;

const NameRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
`;

const InputColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const NameInput = styled.input`
  outline: 0;
  border-width: 0 0 1px;
  border-color: ${valmetGreyHeader};
  font-size: 14px;
`;

const GlobalRow = styled.div<{ disabled?: boolean }>`
  display: flex;
  margin-top: 15px;
  flex-direction: row;
  font-size: 12px;
  align-items: center;
  input {
    margin-right: 4px;
  }
  ${({ disabled }) => disabled && `color: ${disabledGrey};`};
`;

const GlobalInput = styled.input``;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default onClickOutside<any, Props>(ColumnSettingsModal);
