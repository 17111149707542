import React from "react";
import styled from "styled-components";
import { defaultGrey, valmetGreyHeader, settingGreen, filterGreen } from "../../../../../common/colors";
import { Column, GeneratedColumn } from "../../../../../common/columnsTypes";

export interface SelectedColumnsSectionProps {
  columns: (Column | GeneratedColumn)[];
  onColumnSelected: (columnSet: Column | GeneratedColumn) => void;
  onColumnMove: (startIndex: number, targetIndex: number) => void;
}

const drag = (event: React.DragEvent, index: number) => {
  event.dataTransfer.setData("startIndex", index.toString());
};

const allowDrop = (event: React.DragEvent) => {
  event.preventDefault();
  const target = event.target as HTMLButtonElement;
  target.style.borderTop = `2px solid ${settingGreen}`;
};

const dragLeave = (event: React.DragEvent) => {
  event.preventDefault();
  const target = event.target as HTMLButtonElement;
  target.style.borderTop = "0";
};

const drop = (
  event: React.DragEvent,
  targetIndex: number,
  onColumnMove: (startIndex: number, targetIndex: number) => void
) => {
  event.preventDefault();
  const target = event.target as HTMLButtonElement;
  target.style.borderTop = "0";
  const startIndex = parseInt(event.dataTransfer.getData("startIndex"));
  if (startIndex !== targetIndex) {
    onColumnMove(startIndex, targetIndex);
  }
};

function SelectedColumnsSection(props: SelectedColumnsSectionProps): React.ReactElement {
  const { columns, onColumnSelected, onColumnMove } = props;
  return (
    <Container>
      <Title>Selected</Title>
      <ContentContainer>
        {columns.map((column, index) => (
          <ColumnRow
            key={column.id}
            draggable={true}
            onDragStart={event => drag(event, index)}
            onDragOver={event => allowDrop(event)}
            onDragLeave={event => dragLeave(event)}
            onDrop={event => drop(event, index, onColumnMove)}
          >
            <ColumnRowButton onClick={() => onColumnSelected(column)}>{column.name}</ColumnRowButton>
          </ColumnRow>
        ))}
      </ContentContainer>
    </Container>
  );
}

export default SelectedColumnsSection;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: 12px;
  color: ${defaultGrey};
  margin-left: 60px;
`;

const ColumnRow = styled.div``;

const ColumnRowButton = styled.div`
  display: flex;
  flex-direction: row;
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 0px;
`;

const Title = styled.div`
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 6px;
  color: ${valmetGreyHeader};
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 150px;
  width: 175px;
  div:hover {
    background: ${filterGreen};
  }
`;
