import React, { useState } from "react";
import styled from "styled-components";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import { faCrown, faEdit, faMinusCircle, faPlusCircle, faExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  cancelRed,
  commentBlue,
  defaultGrey,
  editBlue,
  errorRed,
  filterGreen,
  hierarchyItemPassThroughColor,
  projectDetailsYellow,
  projectsYellow,
  recSelectedHighlightBlue,
  settingGreen,
  valmetGreyHeader,
  valmetGreyREC,
} from "../../../../../common/colors";
import {
  AdjustmentRateInfo,
  ContainedMainProject,
  EntityTypeId,
  HierarchyItemEnum,
  HierarchyTreeItem,
  NodeId,
  ProjectId,
  ProjectTypeId,
  ProjectTypes,
} from "../../../../../common/types";
import ProjectTypeIcon from "../../../../ProjectTypeIcon/ProjectTypeIcon";
import { IconButton } from "../../../../../common/components";
import Tooltip from "../../../../Tooltip";

export interface HierarchyItemViewProps {
  id: ProjectId | NodeId;
  item: HierarchyTreeItem;
  description: string;
  childLevel: number;
  connectionUp?: boolean;
  connectionDown?: boolean;
  selected: boolean;
  onSelected: (id: number) => void;
  isProject: boolean;
  isActive: boolean;
  itemType: HierarchyItemEnum;
  extraConnections: number[];
  highlighted?: boolean;
  onCreateChildNode?: (parentID: number) => void;
  onDeleteNode?: (id: number) => void;
  draggable: boolean;
  onDragStart: (event: React.DragEvent<HTMLDivElement>) => void;
  onDragOver: (event: React.DragEvent<HTMLDivElement>) => void;
  onDragLeave: (event: React.DragEvent<HTMLDivElement>) => void;
  onDrop: (event: React.DragEvent<HTMLDivElement>) => void;
  userAdded?: boolean;
  readOnly?: boolean;
  mainProjectSelectable?: boolean;
  onEditNode?: () => void;
  onMainSelection?: (id: number) => void;
  mainToNode?: ContainedMainProject[];
  containedMainProject: ContainedMainProject | undefined;
  projectTechnicalTypeId: EntityTypeId | null;
  projectTypeId: ProjectTypeId | null;
  onDragEnd: () => void;
  minimize?: boolean;
  error: string | undefined; // optional error shown for the hierarchy item
}

export interface IconProps {
  id: number;
  itemType: HierarchyItemEnum;
  draggable: boolean;
  onMainSelection: ((id: number) => void) | undefined;
  mainProjectSelectable?: boolean;
  mainToNode?: ContainedMainProject[];
  containedMainProject: ContainedMainProject | undefined;
  projectTechnicalTypeId: EntityTypeId | null;
  adjustmentRateInfo: AdjustmentRateInfo | null;
}

export const getHierarchyItemViewColor = (
  projectTechnicalTypeId: EntityTypeId | null,
  projectType: ProjectTypeId | null
) => {
  switch (projectTechnicalTypeId) {
    case null:
      return projectsYellow;
    case EntityTypeId.AutomaticAdjustment:
      return valmetGreyREC;
    case EntityTypeId.ProjectAdjustment:
      return recSelectedHighlightBlue;
    default:
      switch (projectType) {
        case ProjectTypes.PT:
          return hierarchyItemPassThroughColor;
      }
      return projectDetailsYellow;
  }
};

function Icon(props: IconProps): React.ReactElement {
  const {
    itemType,
    draggable,
    onMainSelection,
    id,
    mainProjectSelectable,
    mainToNode,
    containedMainProject,
    projectTechnicalTypeId,
    adjustmentRateInfo,
  } = props;
  const [isHovered, setIsHovered] = useState(false);
  let content;
  if (mainToNode !== undefined && mainToNode.length > 0) {
    content = (
      <>
        <MainProjectCrownSection>
          <FontAwesomeIcon
            icon={faCrown}
            size="1x"
            title={
              mainToNode.length
                ? `Project ${id} is set as the main project for hierarchy ${
                    mainToNode[mainToNode.length - 1].parentDesc
                  }`
                : undefined
            }
          />
        </MainProjectCrownSection>
        <ProjectTypeIcon projectTechnicalType={projectTechnicalTypeId} noMargin />
      </>
    );
  } else if (containedMainProject) {
    content = (
      <>
        <FontAwesomeIcon
          icon={faCrown}
          size="1x"
          title={`Project ${containedMainProject.id} is inherited as the main project for hierarchy ${containedMainProject.parentDesc}`}
        />
      </>
    );
  } else {
    switch (itemType) {
      case HierarchyItemEnum.Node:
        if (draggable && mainProjectSelectable) {
          content = (
            <IconButton
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              onClick={event => {
                if (onMainSelection) onMainSelection(id);
                event.stopPropagation();
              }}
              fontSize={"16px"}
              color={defaultGrey}
              padding={"5px"}
            >
              <FontAwesomeIcon icon={isHovered ? faCrown : faCircle} size="1x" />
            </IconButton>
          );
        } else content = <FontAwesomeIcon icon={faCircle} size="1x" />;
        break;
      case HierarchyItemEnum.Project:
        if (draggable && mainProjectSelectable) {
          content = (
            <IconButton
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              onClick={event => {
                event.stopPropagation();
                if (onMainSelection) onMainSelection(id);
              }}
              fontSize={"16px"}
              color={defaultGrey}
              padding={"5px"}
            >
              {isHovered ? (
                <ProjectTypeIcon projectTechnicalType={projectTechnicalTypeId} overrideIcon={faCrown} noMargin />
              ) : (
                <ProjectTypeIcon projectTechnicalType={projectTechnicalTypeId} noMargin />
              )}
            </IconButton>
          );
        } else if (adjustmentRateInfo !== null && adjustmentRateInfo.editable) {
          content = (
            <ProjectTypeIcon
              projectTechnicalType={projectTechnicalTypeId}
              noMargin
              adjustmentRateInfo={adjustmentRateInfo}
            />
          );
        } else {
          content = <ProjectTypeIcon projectTechnicalType={projectTechnicalTypeId} noMargin />;
        }
        break;
    }
  }

  if (!content) {
    content = <FontAwesomeIcon icon={faCircle} size="1x" style={{ height: "auto" }} />;
  }
  return <IconWrapper>{content}</IconWrapper>;
}

function HierarchyItemView(props: HierarchyItemViewProps): React.ReactElement {
  const {
    id,
    description,
    item,
    childLevel,
    connectionUp,
    connectionDown,
    onSelected,
    selected,
    isProject,
    isActive,
    itemType,
    extraConnections,
    highlighted,
    onCreateChildNode,
    onDeleteNode,
    draggable,
    onDragStart,
    onDragOver,
    onDragLeave,
    onDrop,
    readOnly,
    userAdded,
    onEditNode,
    onMainSelection,
    mainProjectSelectable,
    mainToNode,
    containedMainProject,
    projectTechnicalTypeId,
    projectTypeId,
    onDragEnd,
    minimize,
    error,
  } = props;
  const paddings = [];
  for (let i = 0; i < childLevel; i++) {
    let paddingConnectionUp;
    let paddingConnectionDown;
    let extraConnection;
    if (i === childLevel - 1) {
      paddingConnectionUp = connectionUp;
      paddingConnectionDown = connectionDown;
    }
    if (extraConnections.includes(i + 1)) {
      paddingConnectionUp = true;
      paddingConnectionDown = true;
      extraConnection = true;
    }
    paddings.push(
      <LeftPaddingContainer
        connectionUp={paddingConnectionUp}
        connectionDown={paddingConnectionDown}
        extraConnection={extraConnection}
        key={i}
      >
        <InnerPadding />
        <InnerPadding />
      </LeftPaddingContainer>
    );
  }
  function Spacer() {
    return <span style={{ minWidth: "0.6em" }} />;
  }

  return (
    <Container>
      {paddings}
      <ContentContainer
        id={"contentContainer"}
        selected={selected}
        highlighted={highlighted}
        isProject={isProject}
        isActive={isActive}
        draggable={draggable}
        onDragStart={event => onDragStart(event)}
        onDragOver={event => onDragOver(event)}
        onDragLeave={event => onDragLeave(event)}
        onDrop={event => onDrop(event)}
        onDragEnd={() => onDragEnd()}
        onClick={() => {
          if (!isProject && !readOnly) onSelected(id);
        }}
        bgColor={getHierarchyItemViewColor(projectTechnicalTypeId, projectTypeId)}
        minimize={minimize}
      >
        <InfoContainer selectable={draggable}>
          <Icon
            id={id}
            itemType={itemType}
            draggable={draggable}
            onMainSelection={onMainSelection}
            mainProjectSelectable={mainProjectSelectable}
            mainToNode={mainToNode}
            containedMainProject={containedMainProject}
            projectTechnicalTypeId={projectTechnicalTypeId}
            adjustmentRateInfo={item.hierarchyItem?.adjustmentRateInfo || null}
          />
          {!userAdded && isProject ? id : ""}
          {<Spacer />}
          <InfoText title={description}>{description}</InfoText>
        </InfoContainer>
        {!isProject && !readOnly && (
          <ButtonContainer>
            {onEditNode && (
              <IconButton
                onClick={event => {
                  event.stopPropagation();
                  onEditNode();
                }}
                padding={"0 4px"}
              >
                <FontAwesomeIcon icon={faEdit} size="1x" color={editBlue} />
              </IconButton>
            )}
            {onDeleteNode && (
              <IconButton
                onClick={event => {
                  event.stopPropagation();
                  onDeleteNode(id);
                }}
                padding={"0 4px"}
              >
                <FontAwesomeIcon icon={faMinusCircle} size="1x" color={cancelRed} />
              </IconButton>
            )}
            {onCreateChildNode && (
              <IconButton
                onClick={event => {
                  event.stopPropagation();
                  onCreateChildNode(id);
                }}
                padding={"0 4px"}
              >
                <FontAwesomeIcon icon={faPlusCircle} size="1x" color={settingGreen} />
              </IconButton>
            )}
          </ButtonContainer>
        )}
      </ContentContainer>
      {error && (
        <Tooltip message={error} offset={{ x: -40, y: 40 }}>
          <ErrorContainer>
            <FontAwesomeIcon icon={faExclamation} />
          </ErrorContainer>
        </Tooltip>
      )}
    </Container>
  );
}

export default HierarchyItemView;

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

const LeftPaddingContainer = styled.div<{
  connectionUp?: boolean;
  connectionDown?: boolean;
  extraConnection?: boolean;
}>`
  display: flex;
  flex-direction: column;
  width: 25px;
  ${({ connectionUp, connectionDown, extraConnection }) =>
    (connectionUp || connectionDown) && !extraConnection ? `>div:first-child{border-bottom: 2px solid;}` : ``}
  ${({ connectionUp }) => (connectionUp ? `>div:first-child{border-left: 2px solid;}` : ``)}
  ${({ connectionDown }) => (connectionDown ? `>div:last-child{border-left: 2px solid;}` : ``)}
`;

const InnerPadding = styled.div`
  display: flex;
  flex: 1;
`;

const ContentContainer = styled.div<{
  selected: boolean;
  highlighted?: boolean;
  isProject: boolean;
  isActive: boolean;
  bgColor: string;
  minimize?: boolean;
}>`
  display: flex;
  /*z-index: 2;*/
  align-items: center;
  min-width: 200px;
  border: 2px ${({ isActive }) => (isActive ? "solid" : "dashed")}
  border: ${({ highlighted }) => (highlighted ? "3px solid" : "1px solid")} ${valmetGreyHeader};
  margin-left: ${({ isProject }) => (isProject ? "0" : "-16px")};
  margin-top: ${({ minimize }) => (minimize ? 5 : 10)}px;
  border-radius: ${({ isProject }) => (isProject ? "15" : "0")}px;
  padding-left: 10px;
  padding-right: 10px;
  background: ${({ bgColor, highlighted, selected }) => (highlighted ? filterGreen : selected ? commentBlue : bgColor)};
  ${({ minimize }) => minimize && "transform: scale(0.75, 0.75) translate(-16.5%, -10%);"}
  height:${({ minimize }) => (minimize ? "20px" : "30px")};
  transition: transform 0.8s, height 0.8s, margin-top 0.8s;
`;

const ErrorContainer = styled.div<{
  minimize?: boolean;
}>`
  display: flex;
  flex-direction: row;
  column-gap: 5px;
  align-items: center;
  border: 1px solid ${valmetGreyHeader};
  margin-top: ${({ minimize }) => (minimize ? 5 : 10)}px;
  padding-left: 10px;
  padding-right: 10px;
  background: ${errorRed};
  ${({ minimize }) => minimize && "transform: scale(0.75, 0.75) translate(-16.5%, -10%);"}
  height:${({ minimize }) => (minimize ? "20px" : "30px")};
  transition: transform 0.8s, height 0.8s, margin-top 0.8s;
`;

const InfoContainer = styled.div<{ selectable: boolean }>`
  display: flex;
  align-items: center;
  flex: 1;
  ${({ selectable }) => (selectable ? "cursor: pointer;" : "")}
  max-width: 575px;
  background: transparent;
`;

const InfoText = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background: transparent;
  line-height: 1.5;
`;

const ButtonContainer = styled.div`
  display: flex;
  padding-left: 4px;
  background: transparent;
`;

const IconWrapper = styled.div`
  min-width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-right: 5px;
`;

const MainProjectCrownSection = styled.div`
  display: flex;
  align-items: center;
  margin-left: -10px;
  padding: 0 7px;
  background-color: #fae498;
  border-radius: 15px 0 0 15px;
  border: 1px solid #ad9c62;
  border-left: 0;
  height: 30px;
`;
