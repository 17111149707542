import React, { useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { useQuery } from "@apollo/client/react/hooks";
import {
  AppState,
  HierarchyItem,
  HierarchyItemEnum,
  ProjectDetailsData,
  ProjectDetailsEditInformation,
} from "../../../../common/types";
import ErrorBox from "../../../ErrorBox/ErrorBox";
import IntegrationsSection from "./IntegrationsSection/IntegrationsSection";
import { GET_PROJECT_DETAILS } from "./queries";
import LoadingView from "../../../LoadingView";
import { BasicDataSectionType } from "../../../../common/constants";
import { ThunkDispatch } from "redux-thunk";
import { Action } from "redux";
import { setProjectDetailsEditInformation } from "../../../../actions/projectActions";
import TagsSection from "./TagsSection/TagsSection";
import DatesSection from "./DatesSection/DatesSection";
import CurrencySection from "./CurrencySection/CurrencySection";
import RelationsSection from "./RelationsSection/RelationsSection";
import RolesSection from "./RolesSection/RolesSection";
import HierarchySection from "./HierarchySection/HierarchySection";
import InformationSection from "./InformationSection/InformationSection";
import ReportingRelationsSection from "./ReportingRelationsSection/ReportingRelationsSection";

export interface ProjectBasicsViewProps {
  projectId: number;
  itemType: HierarchyItemEnum;
  hierarchy?: HierarchyItem;
  userCanEdit: boolean;
}

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, void, Action>) => {
  return {
    setEditInformation: (editInformation: ProjectDetailsEditInformation) =>
      dispatch(setProjectDetailsEditInformation(editInformation)),
  };
};

function ProjectBasicsView(props: ProjectBasicsViewProps & ReturnType<typeof mapDispatchToProps>): React.ReactElement {
  const { projectId, itemType, hierarchy, userCanEdit } = props;
  const [editSection, setEditSection] = useState<BasicDataSectionType | undefined>(undefined);

  const { loading, data, error, refetch } = useQuery<ProjectDetailsData>(GET_PROJECT_DETAILS, {
    variables: {
      projectId: projectId,
    },
    fetchPolicy: "cache-and-network",
    skip: !projectId || itemType === HierarchyItemEnum.Node,
  });

  const projectDetailsData = data && data.projectDetails;

  return itemType === HierarchyItemEnum.Node ? (
    <Container>
      <ContentContainer>
        <ContainerColumn>
          {hierarchy && (
            <HierarchySection
              projectId={projectId}
              itemType={HierarchyItemEnum.Node}
              hierarchy={hierarchy}
              sectionEditable={userCanEdit}
              editSectionType={editSection}
              setEditSectionType={setEditSection}
            />
          )}
        </ContainerColumn>
      </ContentContainer>
    </Container>
  ) : (
    <Container>
      {projectDetailsData && !error && (
        <ContentContainer>
          <ContainerColumn>
            {projectDetailsData.projectDetailsVisibility.projectInformation && (
              <InformationSection
                projectId={projectId}
                sectionDetails={projectDetailsData.projectDetails.projectInformation}
                sectionVisibility={projectDetailsData.projectDetailsVisibility.projectInformation}
                sectionEditable={userCanEdit && projectDetailsData.projectDetails.editable.information}
                editSectionType={editSection}
                setEditSectionType={setEditSection}
                refetchDetails={refetch}
              />
            )}
            {projectDetailsData.projectDetailsVisibility.reportingRelations && (
              <ReportingRelationsSection
                projectId={projectId}
                sectionDetails={projectDetailsData.projectDetails.reportingRelations}
                sectionVisibility={projectDetailsData.projectDetailsVisibility.reportingRelations}
                sectionEditable={userCanEdit && projectDetailsData.projectDetails.editable.reportingRelations}
                editSectionType={editSection}
                setEditSectionType={setEditSection}
                refetchDetails={refetch}
              />
            )}
            {projectDetailsData.projectDetailsVisibility.projectDates && (
              <DatesSection
                projectId={projectId}
                sectionDetails={projectDetailsData.projectDetails.projectDates}
                sectionVisibility={projectDetailsData.projectDetailsVisibility.projectDates}
                sectionEditable={userCanEdit && projectDetailsData.projectDetails.editable.dates}
                editSectionType={editSection}
                setEditSectionType={setEditSection}
                refetchDetails={refetch}
              />
            )}
            {projectDetailsData.projectDetailsVisibility.projectTags && (
              <TagsSection
                projectId={projectId}
                sectionDetails={projectDetailsData.projectDetails.projectTags}
                sectionEditable={userCanEdit && projectDetailsData.projectDetails.editable.tags}
                editSectionType={editSection}
                setEditSectionType={setEditSection}
                refetchDetails={refetch}
              />
            )}
          </ContainerColumn>
          <ContainerColumn>
            {projectDetailsData.projectDetailsVisibility.projectCurrency && (
              <CurrencySection
                projectId={projectId}
                sectionDetails={projectDetailsData.projectDetails.projectCurrency}
                sectionVisibility={projectDetailsData.projectDetailsVisibility.projectCurrency}
                sectionEditable={userCanEdit && projectDetailsData.projectDetails.editable.currency}
                editSectionType={editSection}
                setEditSectionType={setEditSection}
                refetchDetails={refetch}
              />
            )}
            {projectDetailsData.projectDetailsVisibility.projectRelations && (
              <RelationsSection
                projectId={projectId}
                sectionDetails={projectDetailsData.projectDetails.relations}
                sectionEditable={userCanEdit && projectDetailsData.projectDetails.editable.relations}
                editSectionType={editSection}
                setEditSectionType={setEditSection}
                refetchDetails={refetch}
              />
            )}
            {projectDetailsData.projectDetailsVisibility.projectRoles && (
              <RolesSection
                projectId={projectId}
                sectionDetails={projectDetailsData.projectDetails.projectRoles}
                sectionVisibility={projectDetailsData.projectDetailsVisibility.projectRoles}
                sectionEditable={userCanEdit && projectDetailsData.projectDetails.editable.roles}
                editSectionType={editSection}
                setEditSectionType={setEditSection}
                refetchDetails={refetch}
              />
            )}
            {projectDetailsData.projectDetailsVisibility.integrations && (
              <IntegrationsSection
                projectId={projectId}
                sectionDetails={projectDetailsData.projectDetails.integrations}
                sectionVisibility={projectDetailsData.projectDetailsVisibility.integrations}
                sectionEditable={userCanEdit && projectDetailsData.projectDetails.editable.integrations}
                editSectionType={editSection}
                setEditSectionType={setEditSection}
                refetchDetails={refetch}
              />
            )}
            {hierarchy && (
              <HierarchySection
                projectId={projectId}
                itemType={HierarchyItemEnum.Project}
                hierarchy={hierarchy}
                sectionEditable={userCanEdit && projectDetailsData.projectDetails.editable.hierarchy}
                editSectionType={editSection}
                setEditSectionType={setEditSection}
              />
            )}
          </ContainerColumn>
        </ContentContainer>
      )}

      {loading && <LoadingView />}
      {error && <ErrorBox caption="Error" apolloError={error} />}
    </Container>
  );
}

export default connect(mapDispatchToProps)(ProjectBasicsView);

const Container = styled.div``;

const ContentContainer = styled.div`
  width: 1480px;
  display: flex;
  column-gap: 15px;
  @media (max-width: 1480px) {
    display: flex;
    flex-direction: column;
    width: 733px;
  }
  padding-bottom: 120px;
`;

const ContainerColumn = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
`;
