export enum RootReducerAction {
  RESET,
}

interface ResetAction {
  type: RootReducerAction.RESET;
}

export type RootStateAction = ResetAction;

export const reset = (): ResetAction => ({
  type: RootReducerAction.RESET,
});
