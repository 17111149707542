import { values } from "lodash";
import {
  BasicDataCurrency,
  FilterId,
  FilterSelections,
  FilterSetting,
  FilterType,
  InputValue,
  ProjectCurrencySectionInput,
  ProjectDatesSectionInput,
  ProjectInformationSectionInput,
  ProjectInput,
  ProjectIntegrationsSectionInput,
  ProjectRelationSectionInput,
  ProjectRolesSectionInput,
  ProjectTagsSectionInput,
  ReportingRelationsSectionInput,
} from "./types";

export const PROJECT_FILTERS: FilterSelections = {
  GENERIC: [
    /*{
      id: "hierarchy", name: "Hierarchy", values: [
        { id: 1, description: "Project hierarchy" }
      ]
    },*/
    { id: "projectPhase", name: "Phase", values: [], type: FilterType.NUMBER_ARRAY },
    { id: "technicalType", name: "Technical type", values: [], type: FilterType.NUMBER_ARRAY },
    { id: "businessGroup", name: "Business group", values: [], type: FilterType.NUMBER_ARRAY },
    { id: "businessType", name: "Business type", values: [], type: FilterType.NUMBER_ARRAY },
    { id: "person", name: "Person", values: [], type: FilterType.NUMBER },
    { id: "project", name: "Project", values: [], type: FilterType.STRING },
  ],
  TIME: [
    { id: "projectPeriod", name: "Year/Period", values: [], type: FilterType.STRING },
    { id: "oblPeriod", name: "Order backlog date", values: [], type: FilterType.STRING },
  ],
  ADDITIONAL: [
    { id: "projectType", name: "Project type", values: [], type: FilterType.NUMBER_ARRAY },
    { id: "projectStatus", name: "Project status", values: [], type: FilterType.NUMBER },
    { id: "customer", name: "Customer", values: [], type: FilterType.NUMBER_ARRAY },
    { id: "deliveryOrg", name: "Delivery Organisation", values: [], type: FilterType.NUMBER_ARRAY },
    { id: "endDestCountry", name: "End destination", values: [], type: FilterType.NUMBER_ARRAY },
    { id: "financingMethod", name: "Revenue method", values: [], type: FilterType.NUMBER_ARRAY },
    { id: "legalEntity", name: "Legal entity", values: [], type: FilterType.NUMBER_ARRAY },
    { id: "extLegalEntity", name: "External legal entity", values: [], type: FilterType.NUMBER_ARRAY },
  ],
  REC: [{ id: "estSalesPrice", name: "Estimated sales price", values: [], type: FilterType.NUMBER_RANGE }],
};

export const PROJECT_FILTERS_MAP: Map<FilterId, FilterSetting> = new Map(
  values(PROJECT_FILTERS)
    .flatMap(x => x)
    .map(x => [x.id, x])
);

export const LISTING_NODE_TYPE = "ListingNode";

export const FREEZING_COMMENT_TYPE = "Freezing comment";

export const DEFAULT_CURRENCY_CODE = "LC1";

export const TYPE_CREATE_PROJECT = "create-project";

export const getEmptyProjectInformationSectionInput = (): ProjectInformationSectionInput => ({
  input: {
    organisation: {
      businessGroupId: null,
      businessTypeId: null,
      legalEntityId: null,
      intExtId: null,
      customerId: null,
      industryId: null,
      autSiteCodeId: null,
      icpTradingPartnerId: null,
    },
    contractOwner: {
      businessGroupId: null,
      businessTypeId: null,
      legalEntityId: null,
    },
    EDCId: null,
    autReportingCountryId: null,
    deliveryOrgId: null,
    deliveryLocationId: null,
    financingMethod: null,
    performanceObligation: null,
    projectKey: null,
    mainProjectKey: null,
    projectName: null,
    projectDescription: null,
    projectPhaseId: null,
    projectStatusId: null,
    projectTechnicalTypeId: null,
    projectTypeId: null,
  },
  pristine: true,
  inherited: undefined,
});

export const getEmptyProjectDatesSectionInput = (): ProjectDatesSectionInput => ({
  input: {
    OBLPeriod: null,
    asSoldPeriod: null,
    budgetPeriod: null,
    recPlanStartPeriod: null,
    recPlanEndPeriod: null,
    recCompletionPeriod: null,
    startUpPeriod: null,
    projectClosingPeriodInERP: null,
    warrantyStartPeriod: null,
    warrantyEndPeriod: null,
    contractEffectivePeriod: null,
    contractExpiryPeriod: null,
    migrationPeriod: null,
    financeClosingPeriod: null,
  },
  pristine: true,
});

export const getEmptyProjectCurrencySectionInput = (): ProjectCurrencySectionInput => ({
  input: {
    contractCurrency: null,
    legalEntityCurrency: null,
    projectCurrency: null,
    revenueRecognitionCurrency: null,
    externalReportingCurrency: null,
    internalReportingCurrency: null,
  },
  pristine: true,
});

export const getEmptyProjectRelationSectionInput = (): ProjectRelationSectionInput => ({
  input: [
    {
      projectRelationId: null,
      relationType: null,
      company: null,
      relatingKeys: [],
    },
  ],
  pristine: true,
});

export const getEmptyProjectRolesSectionInput = (): ProjectRolesSectionInput => ({
  input: {
    mainProjectManagerId: null,
    mainProjectControllerId: null,
    projectControllerId: null,
    projectManagerId: null,
    projectManagersManagerId: null,
    projectCoordinatorId: null,
  },
  pristine: true,
});

export const getEmptyProjectTagsSectionInput = (): ProjectTagsSectionInput => ({
  input: [],
  pristine: true,
});

export const getEmptyProjectIntegrationsSectionInput = (): ProjectIntegrationsSectionInput => ({
  input: {
    sendToRadar: null,
    profitStatusId: null,
    profitPITProgressId: null,
    profitRecPercentage: null,
  },
  pristine: true,
});

export const getEmptyReportingRelationsSectionInput = (): ReportingRelationsSectionInput => ({
  input: {
    externalOrganisation: {
      businessGroupId: null,
      businessTypeId: null,
      legalEntityId: null,
      reportingId: null,
      customerId: null,
      externalReportingIndustryId: null,
      orderValueIncludedInRelated: null,
    },
    serMarginTraceability: null,
  },
  pristine: true,
});

export const getEmptyProjectInput = (): ProjectInput => ({
  projectInformation: getEmptyProjectInformationSectionInput(),
  projectDates: getEmptyProjectDatesSectionInput(),
  projectCurrency: getEmptyProjectCurrencySectionInput(),
  projectRelations: getEmptyProjectRelationSectionInput(),
  projectRoles: getEmptyProjectRolesSectionInput(),
  projectTags: getEmptyProjectTagsSectionInput(),
  projectIntegrations: getEmptyProjectIntegrationsSectionInput(),
  reportingRelations: getEmptyReportingRelationsSectionInput(),
});

export const PROJECT_EDIT_CHAR_LIMIT = {
  projectKey: 128,
  mainProjectKey: 128,
  projectName: 128,
  projectDescription: 128,
};

export const MAX_NODE_NAME_LENGTH = 115;
export const MIN_NODE_NAME_LENGTH = 1;

export const CUSTOMERS_QUERY_SIZE = 30;

export const DUMMY_HIERARCHY_ID = 0;

export const REC_WAIT_FOR_READY_LOCAL_STORAGE_KEY = "projectsRecognitionsWaitingForReady";

export enum BasicDataSectionType {
  Information,
  Dates,
  Tags,
  Currency,
  Relations,
  Roles,
  Hierarchy,
  Integrations,
  ReportingRelations,
}

export const disabledFinancingMethods = [
  "Assets under Construction",
  "Building Assets",
  "Intangible Assets",
  "Overhead Projects",
];
