import { userPreferencesInitState } from "../reducers/userPreferencesReducer";
import { UserPreferencesState } from "../components/sites/Project/ProjectRecognitions/types";
import { RecPlanEstRecEditModes } from "../components/sites/Project/ProjectRecognitions/recognitionsEditingSetting";
import { ApolloClient, gql } from "@apollo/client";

export const INITIALIZE_USER_PREFERENCES_STATE = "INITIALIZE_USER_PREFERENCES_STATE";
export const UPDATE_EST_REC_EDIT_MODE = "UPDATE_EST_REC_EDIT_MODE";

export type UserPreferencesAction = InitializeUserPreferenceState | UpdateEstRecEditMode;

export type InitializeUserPreferenceState = {
  type: "INITIALIZE_USER_PREFERENCES_STATE";
  userPreferences: UserPreferencesState;
};

export type UpdateEstRecEditMode = {
  type: "UPDATE_EST_REC_EDIT_MODE";
  estRecEditMode: RecPlanEstRecEditModes;
};

const USER_PREFERENCES = gql`
  query UserPreferences {
    userPreferences {
      estRecEditMode
    }
  }
`;

export const initializeUserPreferencesState = (
  client: ApolloClient<Record<string, unknown>>
): Promise<InitializeUserPreferenceState> => {
  return client
    .query<{ userPreferences: UserPreferencesState }>({ query: USER_PREFERENCES })
    .then(res => {
      if (res !== undefined && res.data !== undefined) {
        return {
          type: INITIALIZE_USER_PREFERENCES_STATE,
          userPreferences: res.data.userPreferences,
        };
      } else {
        return {
          type: INITIALIZE_USER_PREFERENCES_STATE,
          userPreferences: userPreferencesInitState,
        };
      }
    });
};

export const SAVE_USER_PREFERENCES = gql`
  mutation SaveUserPreferences($userPreferencesInput: UserPreferencesInput!) {
    saveUserPreferences(userPreferencesInput: $userPreferencesInput) {
      userPreferencesState {
        estRecEditMode
      }
      error
    }
  }
`;

export const updateEstRecEditMode = (estRecEditMode: RecPlanEstRecEditModes): UpdateEstRecEditMode => ({
  type: UPDATE_EST_REC_EDIT_MODE,
  estRecEditMode: estRecEditMode,
});
