import Cookies from "js-cookie";
import { User } from "./types";
import store from "./../init/store";
import { authUser } from "../actions/authActions";
import { resetCookies, base64UnicodeDecode } from "./utils";

const loginUrl = `${window.location.protocol}//${window.location.host}/auth/login`;
const authUrl = `${window.location.protocol}//${window.location.host}/auth/authenticated`;

export const getUserCookie = (): User | undefined => {
  const rawCookie = Cookies.get("POP_USER_INFO");

  return rawCookie ? (JSON.parse(base64UnicodeDecode(rawCookie)) as User) : undefined;
};

export const checkLoginStatus = (): void => void fetch(authUrl)
  .then(res => {
    if (res.status === 401) {
      resetCookies(window.location.href);
      window.location.href = loginUrl;
    } else {
      const userCookie = getUserCookie();
      if (userCookie) store.dispatch(authUser(userCookie));
    }
  })
  .catch(() => {
    resetCookies();
    window.location.href = loginUrl;
  });
