import {
  CopyMode,
  EditableFieldsByColumn,
  EditableRecognitionCell,
  EditableRecognitionRow,
  EditingPredicateParams,
  FreezingAllDisabled,
  FreezingAllEnabled,
  FreezingDisabledField,
  FreezingDisabledResult,
  FreezingDisabledType,
  FreezingPartiallyDisabled,
  RecognitionEditOptions,
  RecognitionEditType,
  RecognitionsEditState,
  VisibleEditableColumnId,
} from "./types";
import { EditableColumnId } from "../../../../common/columnsTypes";
import { Period, periodEquals, periodGreaterThan } from "../../../../common/period";
import { cloneDeep } from "lodash";

// editing disabled
const disabledFields = (fullCellDisabled: boolean, frozenFields: string[]): boolean | string[] => {
  if (fullCellDisabled) return true;
  else {
    return frozenFields.length === 0 ? false : frozenFields;
  }
};

// freezing disabled
const freezingAllEnabled: FreezingAllEnabled = { type: FreezingDisabledType.AllEnabled };

const freezingAllDisabled = (disabledReasons: string[]): FreezingAllDisabled => {
  return { type: FreezingDisabledType.AllDisabled, reasons: disabledReasons };
};

const freezingAllDisabledCond = (disabled: boolean, reason: string): FreezingAllDisabled | FreezingAllEnabled =>
  disabled ? { type: FreezingDisabledType.AllDisabled, reasons: [reason] } : freezingAllEnabled;

const freezingPartiallyCond = (
  disabled: boolean,
  field: string,
  reason: string
): FreezingPartiallyDisabled | FreezingAllEnabled =>
  disabled
    ? { type: FreezingDisabledType.PartiallyDisabled, fields: [{ fieldName: field, reasons: [reason] }] }
    : freezingAllEnabled;

const unionFields = (aFields: FreezingDisabledField[], bFields: FreezingDisabledField[]): FreezingDisabledField[] => {
  const unionField = (fields: FreezingDisabledField[], field: FreezingDisabledField): FreezingDisabledField[] => {
    const findField = fields.findIndex(f => f.fieldName === field.fieldName);
    const newFields = cloneDeep(fields);
    if (findField > -1) {
      newFields[findField] = {
        fieldName: newFields[findField].fieldName,
        reasons: newFields[findField].reasons.concat(field.reasons),
      };
    } else {
      newFields[newFields.length] = field;
    }
    return newFields;
  };

  return bFields.reduce(unionField, aFields);
};

const union = (a: FreezingDisabledResult, b: FreezingDisabledResult): FreezingDisabledResult => {
  if (
    a.type === FreezingDisabledType.AllDisabled ||
    (a.type === FreezingDisabledType.PartiallyDisabled && b.type === FreezingDisabledType.AllEnabled)
  )
    return a;
  else if (
    b.type === FreezingDisabledType.AllDisabled ||
    (a.type === FreezingDisabledType.AllEnabled && b.type === FreezingDisabledType.PartiallyDisabled)
  )
    return b;
  else if (a.type === FreezingDisabledType.PartiallyDisabled && b.type === FreezingDisabledType.PartiallyDisabled)
    return { type: FreezingDisabledType.PartiallyDisabled, fields: unionFields(a.fields, b.fields) };
  else return freezingAllEnabled;
};

const intersectFields = (
  aFields: FreezingDisabledField[],
  bFields: FreezingDisabledField[]
): FreezingDisabledField[] => {
  const intersected = [] as FreezingDisabledField[];
  aFields.forEach(af => {
    const findField = bFields.find(bf => bf.fieldName === af.fieldName);
    if (findField !== undefined) {
      intersected.push({ fieldName: af.fieldName, reasons: af.reasons.concat(findField.reasons) });
    }
  });
  return intersected;
};

const intersect = (a: FreezingDisabledResult, b: FreezingDisabledResult): FreezingDisabledResult => {
  if (a.type === FreezingDisabledType.PartiallyDisabled && b.type === FreezingDisabledType.PartiallyDisabled)
    return { type: FreezingDisabledType.PartiallyDisabled, fields: intersectFields(a.fields, b.fields) };
  else if (a.type === FreezingDisabledType.AllEnabled || b.type === FreezingDisabledType.AllEnabled)
    return freezingAllEnabled;
  else if (a.type === FreezingDisabledType.PartiallyDisabled) return a;
  else if (b.type === FreezingDisabledType.PartiallyDisabled) return b;
  else return { type: FreezingDisabledType.AllDisabled, reasons: a.reasons.concat(b.reasons) };
};

const unionAll = (as: FreezingDisabledResult[]): FreezingDisabledResult => {
  return as.reduce(union, freezingAllEnabled);
};

type TrueCondition = {
  type: "True";
};

type FalseCondition = {
  type: "False";
  message: string;
};

const trueCond: TrueCondition = { type: "True" };
const falseCond = (message: string): FalseCondition => ({ type: "False", message });

type OrCondition = {
  type: "Or";
  left: Condition;
  right: Condition;
};

type AndCondition = {
  type: "And";
  left: Condition;
  right: Condition;
};

type Condition = TrueCondition | FalseCondition | OrCondition | AndCondition;

const holds = (condition: Condition): boolean => {
  switch (condition.type) {
    case "True":
      return true;
    case "False":
      return false;
    default:
      return false;
  }
};

const cond = (ok: boolean, message: string) => {
  return ok ? trueCond : falseCond(message);
};

const or = (left: Condition, right: Condition): Condition => {
  if (holds(left)) return left;
  if (holds(right)) return right;
  return { type: "Or", left, right };
};

const and = (left: Condition, right: Condition): Condition => {
  if (holds(left) && holds(right)) return trueCond;
  if (holds(left)) return right;
  if (holds(right)) return left;
  return { type: "And", left, right };
};

const either = (a: Condition, b: Condition, postfix: string): Condition => {
  if (!holds(a) && !holds(b)) {
    const ma = conditionToString(a);
    const mb = conditionToString(b);
    return falseCond("Either " + ma + " OR\n " + mb + " " + postfix);
  }
  return trueCond;
};

const conditionToString = (condition: Condition, addParentheses = false): string => {
  switch (condition.type) {
    case "True":
      return "True";
    case "False":
      return condition.message;
    case "Or":
      return (
        (addParentheses ? "(\n\t" : "") +
        conditionToString(condition.left, true) +
        (addParentheses ? ", OR \n\t" : ", OR \n") +
        conditionToString(condition.right, true) +
        (addParentheses ? "\n)" : "")
      );
    case "And":
      return (
        (addParentheses ? "(\n" : "") +
        conditionToString(condition.left, true) +
        ", AND \n" +
        conditionToString(condition.right, true) +
        (addParentheses ? "\n)" : "")
      );
  }
};

// The editing and freezing rules of "net sales" also apply to "sales price".
const recognitionsEditFieldDetails: EditableFieldsByColumn = {
  est_rec_percent_edit: {
    columnLabel: "Est Rec%",
    measures: ["nsPercent", "cogsPercent"],
    labels: ["NS%", "Cogs%"],
    calculated: true,
    copyMode: () => "future_smaller",
    options: params => ({
      disabled: (({
        options: { editProjectType, editablePcsProject, revenueMethodIsStraightLine },
        isRecCompletionPeriod,
        periodIsInPast,
        getCell,
        frozenFields: initialFrozenFields,
        estRecEditMode,
      }) => {
        if (
          estRecEditMode !== RecPlanEstRecEditModes.CogsPercentage &&
          estRecEditMode !== RecPlanEstRecEditModes.NsCogsPercentage
        ) {
          return true;
        }

        const estRecFreezings = getCell("est_rec_edit").freezings.map(v => v.fieldName);
        const frozen = [];
        if (estRecFreezings.includes("ns") || estRecEditMode === RecPlanEstRecEditModes.CogsPercentage)
          frozen.push("nsPercent");
        if (estRecFreezings.includes("cogs")) frozen.push("cogsPercent");
        const allFrozenSet = new Set(frozen.concat(initialFrozenFields));
        const frozenFields = Array.from(allFrozenSet);

        switch (editProjectType) {
          case RecognitionEditType.NonEditable:
          case RecognitionEditType.NonEditableAutomaticAdjustmentOnNS:
          case RecognitionEditType.NonEditableAutomaticAdjustmentOnIntNS:
          case RecognitionEditType.LnServiceOrder:
          case RecognitionEditType.LnSalesOrder:
            return true;
          case RecognitionEditType.Manual:
            return disabledFields(false, frozenFields);
          case RecognitionEditType.BaanProject:
          case RecognitionEditType.LnProject:
          case RecognitionEditType.EncompixPOrder:
            return disabledFields(periodIsInPast, frozenFields);
          case RecognitionEditType.BaanPcsProject:
            return disabledFields(!editablePcsProject || periodIsInPast, frozenFields);
          case RecognitionEditType.LnServiceContract: {
            const nsEditable = !revenueMethodIsStraightLine && !isRecCompletionPeriod;
            const costEditable = !isRecCompletionPeriod;
            if (periodIsInPast) {
              return disabledFields(true, frozenFields);
            } else if (!nsEditable && !costEditable) {
              return disabledFields(true, frozenFields);
            } else if (!nsEditable) {
              return disabledFields(false, Array.from(allFrozenSet.add("nsPercent")));
            } else if (!costEditable) {
              return disabledFields(false, Array.from(allFrozenSet.add("cogsPercent")));
            } else {
              return disabledFields(false, frozenFields);
            }
          }
          case RecognitionEditType.AutomaticAdjustmentOnNS:
          case RecognitionEditType.AutomaticAdjustmentOnIntNS:
            return true;
        }
      })(params),
      freezingDisabled: freezingAllDisabled(["Not freezable."]),
    }),
  },
  as_sold_edit: {
    columnLabel: "As Sold",
    measures: ["salesPrice", "ns", "cost", "cont", "war"],
    labels: ["Sales price", "NS", "Costs", "Cont", "War"],
    calculated: false,
    copyMode: () => "all",
    options: params => ({
      disabled: (({ options: { editProjectType, editablePcsProject }, isAsSoldPeriod, frozenFields }) => {
        switch (editProjectType) {
          case RecognitionEditType.NonEditable:
          case RecognitionEditType.NonEditableAutomaticAdjustmentOnNS:
          case RecognitionEditType.NonEditableAutomaticAdjustmentOnIntNS:
          case RecognitionEditType.LnServiceOrder:
          case RecognitionEditType.LnSalesOrder:
            return true;
          case RecognitionEditType.Manual:
          case RecognitionEditType.BaanProject:
            return disabledFields(!isAsSoldPeriod, frozenFields);
          case RecognitionEditType.BaanPcsProject:
            return disabledFields(!editablePcsProject || !isAsSoldPeriod, frozenFields);
          case RecognitionEditType.LnProject:
          case RecognitionEditType.LnServiceContract:
          case RecognitionEditType.EncompixPOrder:
            return disabledFields(true, frozenFields);
          case RecognitionEditType.AutomaticAdjustmentOnNS:
          case RecognitionEditType.AutomaticAdjustmentOnIntNS:
            return true;
        }
      })(params),
      freezingDisabled: (({ options: { editProjectType } }) => {
        switch (editProjectType) {
          case RecognitionEditType.EncompixPOrder:
            return freezingAllDisabled(["Not freezable."]);
          case RecognitionEditType.AutomaticAdjustmentOnNS:
          case RecognitionEditType.AutomaticAdjustmentOnIntNS:
            return freezingAllDisabled(["Not freezable."]);
          default:
            return freezingAllEnabled;
        }
      })(params),
    }),
  },
  budget_edit: {
    columnLabel: "Budget",
    measures: ["salesPrice", "ns", "cost", "cont", "war"],
    labels: ["Sales price", "NS", "Costs", "Cont", "War"],
    calculated: false,
    copyMode: () => "all",
    options: params => ({
      disabled: (({ options: { editProjectType, editablePcsProject }, isBudgetPeriod, frozenFields }) => {
        switch (editProjectType) {
          case RecognitionEditType.NonEditable:
          case RecognitionEditType.NonEditableAutomaticAdjustmentOnNS:
          case RecognitionEditType.NonEditableAutomaticAdjustmentOnIntNS:
          case RecognitionEditType.LnServiceOrder:
          case RecognitionEditType.LnSalesOrder:
            return true;
          case RecognitionEditType.Manual:
          case RecognitionEditType.BaanProject:
            return disabledFields(!isBudgetPeriod, frozenFields);
          case RecognitionEditType.BaanPcsProject:
            return disabledFields(!editablePcsProject || !isBudgetPeriod, frozenFields);
          case RecognitionEditType.LnProject:
          case RecognitionEditType.LnServiceContract:
          case RecognitionEditType.EncompixPOrder:
            return disabledFields(true, frozenFields);
          case RecognitionEditType.AutomaticAdjustmentOnNS:
          case RecognitionEditType.AutomaticAdjustmentOnIntNS:
            return true;
        }
      })(params),
      freezingDisabled: (({ options: { editProjectType } }) => {
        switch (editProjectType) {
          case RecognitionEditType.EncompixPOrder:
            return freezingAllDisabled(["Not freezable."]);
          case RecognitionEditType.AutomaticAdjustmentOnNS:
          case RecognitionEditType.AutomaticAdjustmentOnIntNS:
            return freezingAllDisabled(["Not freezable."]);
          default:
            return freezingAllEnabled;
        }
      })(params),
    }),
  },
  revised_edit: {
    columnLabel: "Revised",
    measures: ["salesPrice", "ns", "cost", "cont", "war"],
    labels: ["Sales price", "NS", "Costs", "Cont", "War"],
    calculated: false,
    copyMode: () => "future",
    options: params => ({
      disabled: (({ options: { editProjectType, editablePcsProject }, periodIsInPast, frozenFields }) => {
        switch (editProjectType) {
          case RecognitionEditType.NonEditable:
          case RecognitionEditType.NonEditableAutomaticAdjustmentOnNS:
          case RecognitionEditType.NonEditableAutomaticAdjustmentOnIntNS:
          case RecognitionEditType.LnServiceOrder:
          case RecognitionEditType.LnSalesOrder:
            return true;
          case RecognitionEditType.Manual:
          case RecognitionEditType.BaanProject:
            return disabledFields(periodIsInPast, frozenFields);
          case RecognitionEditType.BaanPcsProject:
            return disabledFields(!editablePcsProject || periodIsInPast, frozenFields);
          case RecognitionEditType.LnProject:
          case RecognitionEditType.LnServiceContract:
          case RecognitionEditType.EncompixPOrder:
            return disabledFields(true, frozenFields);
          case RecognitionEditType.AutomaticAdjustmentOnNS:
          case RecognitionEditType.AutomaticAdjustmentOnIntNS:
            return true;
        }
      })(params),
      freezingDisabled: (({ options: { editProjectType } }) => {
        switch (editProjectType) {
          case RecognitionEditType.EncompixPOrder:
            return freezingAllDisabled(["Not freezable."]);
          case RecognitionEditType.AutomaticAdjustmentOnNS:
          case RecognitionEditType.AutomaticAdjustmentOnIntNS:
            return freezingAllDisabled(["Not freezable."]);
          default:
            return freezingAllEnabled;
        }
      })(params),
    }),
  },
  est_edit: {
    columnLabel: "Estimate",
    measures: ["salesPrice", "ns", "cost", "cont", "war"],
    labels: ["Sales price", "NS", "Costs", "Cont", "War"],
    calculated: false,
    copyMode: () => "future",
    options: params => ({
      disabled: (({ options: { editProjectType, editablePcsProject }, periodIsInPast, frozenFields }) => {
        switch (editProjectType) {
          case RecognitionEditType.NonEditable:
          case RecognitionEditType.NonEditableAutomaticAdjustmentOnNS:
          case RecognitionEditType.NonEditableAutomaticAdjustmentOnIntNS:
          case RecognitionEditType.LnServiceOrder:
          case RecognitionEditType.LnSalesOrder:
            return true;
          case RecognitionEditType.Manual:
            return disabledFields(false, frozenFields);
          case RecognitionEditType.BaanProject:
            return disabledFields(periodIsInPast, frozenFields);
          case RecognitionEditType.BaanPcsProject:
            return disabledFields(!editablePcsProject || periodIsInPast, frozenFields);
          case RecognitionEditType.LnProject:
          case RecognitionEditType.LnServiceContract:
          case RecognitionEditType.EncompixPOrder:
            return disabledFields(true, frozenFields);
          case RecognitionEditType.AutomaticAdjustmentOnNS:
          case RecognitionEditType.AutomaticAdjustmentOnIntNS:
            return true;
        }
      })(params),
      freezingDisabled: (({ options: { editProjectType } }) => {
        switch (editProjectType) {
          case RecognitionEditType.EncompixPOrder:
            return freezingAllDisabled(["Not freezable."]);
          case RecognitionEditType.AutomaticAdjustmentOnNS:
          case RecognitionEditType.AutomaticAdjustmentOnIntNS:
            return freezingAllDisabled(["Not freezable."]);
          default:
            return freezingAllEnabled;
        }
      })(params),
    }),
  },
  est_rec_edit: {
    columnLabel: "Est Rec",
    measures: ["ns", "cogs", "war", "wipCosts"],
    labels: ["NS", "Cogs", "War", "Wip Costs"],
    calculated: ["wipCosts"],
    copyMode: () => "future_smaller",
    options: params => ({
      disabled: (({
        options: { editProjectType, editablePcsProject },
        isRecCompletionPeriod,
        periodIsInPast,
        frozenFields,
        estRecEditMode,
      }) => {
        if (estRecEditMode !== RecPlanEstRecEditModes.WipCosts && estRecEditMode !== RecPlanEstRecEditModes.Ns) {
          return true;
        }
        const initialDisabled = ["cogs", "war"];
        if (estRecEditMode === RecPlanEstRecEditModes.WipCosts) initialDisabled.push("ns");
        if (estRecEditMode === RecPlanEstRecEditModes.Ns) initialDisabled.push("wipCosts");
        const disabledFieldsSet = new Set(initialDisabled.concat(frozenFields));
        const allDisabledFields = Array.from(disabledFieldsSet);

        switch (editProjectType) {
          case RecognitionEditType.NonEditable:
          case RecognitionEditType.NonEditableAutomaticAdjustmentOnNS:
          case RecognitionEditType.NonEditableAutomaticAdjustmentOnIntNS:
          case RecognitionEditType.LnServiceOrder:
          case RecognitionEditType.LnSalesOrder:
            return true;
          case RecognitionEditType.Manual:
          case RecognitionEditType.BaanProject:
          case RecognitionEditType.LnProject:
          case RecognitionEditType.EncompixPOrder:
            return disabledFields(periodIsInPast, allDisabledFields);
          case RecognitionEditType.BaanPcsProject:
            return disabledFields(!editablePcsProject || periodIsInPast, allDisabledFields);
          case RecognitionEditType.LnServiceContract:
            return disabledFields(isRecCompletionPeriod || periodIsInPast, allDisabledFields);
          case RecognitionEditType.AutomaticAdjustmentOnNS:
          case RecognitionEditType.AutomaticAdjustmentOnIntNS:
            return true;
        }
      })(params),
      freezingDisabled: (({
        options: { editProjectType, isPTProjectWithEstRecFromRelated },
        periodIsInPast,
        estRecEditMode,
      }) => {
        if (isPTProjectWithEstRecFromRelated) {
          // For PT projects we allow freezing past periods.
          return freezingAllDisabledCond(!periodIsInPast, "Only past periods can be frozen");
        }

        if (estRecEditMode !== RecPlanEstRecEditModes.WipCosts && estRecEditMode !== RecPlanEstRecEditModes.Ns)
          return freezingAllDisabled(["Not freezable."]);

        const periodInPast = freezingAllDisabledCond(periodIsInPast, "Period is in the past");
        const nsDisabled = freezingPartiallyCond(true, "ns", "Not freezable.");
        const cogsDisabled = freezingPartiallyCond(true, "cogs", "Not freezable.");
        const warDisabled = freezingPartiallyCond(true, "war", "Not freezable.");

        switch (editProjectType) {
          case RecognitionEditType.EncompixPOrder:
            return unionAll([periodInPast, nsDisabled, cogsDisabled, warDisabled]);
          case RecognitionEditType.AutomaticAdjustmentOnNS:
          case RecognitionEditType.AutomaticAdjustmentOnIntNS:
            return freezingAllDisabled(["Not freezable."]);
          default:
            return freezingAllEnabled;
        }
      })(params),
    }),
  },
  rec_edit: {
    columnLabel: "Rec",
    measures: ["ns", "cogs", "war"],
    labels: ["NS", "Cogs", "War"],
    calculated: false,
    copyMode: editType => (editType === RecognitionEditType.Manual ? "future" : "none"),
    options: params => ({
      disabled: (({
        options: { editProjectType, editablePcsProject },
        periodIsInPast,
        periodIsInFuture,
        frozenFields,
      }) => {
        switch (editProjectType) {
          case RecognitionEditType.NonEditable:
          case RecognitionEditType.NonEditableAutomaticAdjustmentOnNS:
          case RecognitionEditType.NonEditableAutomaticAdjustmentOnIntNS:
          case RecognitionEditType.LnServiceOrder:
          case RecognitionEditType.LnSalesOrder:
            return true;
          case RecognitionEditType.Manual:
            return disabledFields(periodIsInFuture, frozenFields);
          case RecognitionEditType.BaanProject:
            return disabledFields(periodIsInPast, frozenFields);
          case RecognitionEditType.BaanPcsProject:
            return disabledFields(!editablePcsProject || periodIsInPast, frozenFields);
          case RecognitionEditType.LnProject:
          case RecognitionEditType.LnServiceContract:
          case RecognitionEditType.EncompixPOrder:
            return disabledFields(true, frozenFields);
          case RecognitionEditType.AutomaticAdjustmentOnNS:
          case RecognitionEditType.AutomaticAdjustmentOnIntNS:
            return true;
        }
      })(params),
      freezingDisabled: (({ options: { editProjectType, migratedFromLegacySystem }, periodIsInFuture }) => {
        const periodInFuture = freezingAllDisabledCond(periodIsInFuture, "Period is in the future.");
        const notMigratedFromLegacy = freezingAllDisabledCond(
          !migratedFromLegacySystem,
          "Project does not have 'Migrated from legacy system' tag"
        );
        switch (editProjectType) {
          case RecognitionEditType.EncompixPOrder:
            return freezingAllDisabled(["Not freezable."]);
          case RecognitionEditType.LnProject:
          case RecognitionEditType.LnServiceContract:
          case RecognitionEditType.LnServiceOrder:
          case RecognitionEditType.LnSalesOrder:
            return union(periodInFuture, notMigratedFromLegacy);
          case RecognitionEditType.AutomaticAdjustmentOnNS:
          case RecognitionEditType.AutomaticAdjustmentOnIntNS:
            return freezingAllDisabled(["Not freezable."]);
          default:
            return freezingAllEnabled;
        }
      })(params),
    }),
  },
  invoicing_edit: {
    columnLabel: "Invoicing, Ext",
    measures: ["sent", "paid", "due"],
    labels: ["Sent", "Paid", "Due"],
    calculated: false,
    copyMode: editType => {
      if (editType === RecognitionEditType.Manual) {
        return "future_smaller";
      } else {
        return { sent: "future_smaller", paid: "future_smaller" };
      }
    },
    options: params => ({
      disabled: (({ options: { editProjectType, editablePcsProject }, periodIsInPast, frozenFields }) => {
        switch (editProjectType) {
          case RecognitionEditType.NonEditable:
          case RecognitionEditType.NonEditableAutomaticAdjustmentOnNS:
          case RecognitionEditType.NonEditableAutomaticAdjustmentOnIntNS:
          case RecognitionEditType.LnServiceOrder:
          case RecognitionEditType.LnSalesOrder:
            return true;
          case RecognitionEditType.Manual:
            return disabledFields(false, frozenFields);
          case RecognitionEditType.BaanProject:
            return disabledFields(periodIsInPast, frozenFields);
          case RecognitionEditType.BaanPcsProject:
            return disabledFields(!editablePcsProject || periodIsInPast, frozenFields);
          case RecognitionEditType.LnProject:
          case RecognitionEditType.LnServiceContract:
          case RecognitionEditType.EncompixPOrder:
            return disabledFields(true, frozenFields);
          case RecognitionEditType.AutomaticAdjustmentOnNS:
          case RecognitionEditType.AutomaticAdjustmentOnIntNS:
            return true;
        }
      })(params),
      freezingDisabled: (({ options: { editProjectType, migratedFromLegacySystem }, periodIsInFuture }) => {
        const periodInFuture = freezingAllDisabledCond(periodIsInFuture, "Period is in the future.");
        const notMigratedFromLegacy = freezingAllDisabledCond(
          !migratedFromLegacySystem,
          "Project does not have 'Migrated from legacy system' tag"
        );
        switch (editProjectType) {
          case RecognitionEditType.EncompixPOrder:
            return freezingAllDisabled(["Not freezable."]);
          case RecognitionEditType.LnProject:
          case RecognitionEditType.LnServiceContract:
          case RecognitionEditType.LnServiceOrder:
          case RecognitionEditType.LnSalesOrder:
            return union(periodInFuture, notMigratedFromLegacy);
          case RecognitionEditType.AutomaticAdjustmentOnNS:
          case RecognitionEditType.AutomaticAdjustmentOnIntNS:
            return freezingAllDisabled(["Not freezable."]);
          default:
            return freezingAllEnabled;
        }
      })(params),
    }),
  },
  comm_costs_edit: {
    columnLabel: "Comm Costs",
    measures: ["act", "hardCom", "softCom"],
    labels: ["Act", "Hard Com", "Soft Com"],
    calculated: false,
    copyMode: editType => {
      if (editType === RecognitionEditType.Manual) {
        return "future_smaller";
      } else {
        return { act: "future_smaller" };
      }
    },
    options: params => ({
      disabled: (({ options: { editProjectType }, frozenFields }) => {
        switch (editProjectType) {
          case RecognitionEditType.NonEditable:
          case RecognitionEditType.NonEditableAutomaticAdjustmentOnNS:
          case RecognitionEditType.NonEditableAutomaticAdjustmentOnIntNS:
          case RecognitionEditType.LnServiceOrder:
          case RecognitionEditType.LnSalesOrder:
            return true;
          case RecognitionEditType.Manual:
            return disabledFields(false, frozenFields);
          case RecognitionEditType.BaanProject:
          case RecognitionEditType.BaanPcsProject:
          case RecognitionEditType.LnProject:
          case RecognitionEditType.LnServiceContract:
          case RecognitionEditType.EncompixPOrder:
            return disabledFields(true, frozenFields);
          case RecognitionEditType.AutomaticAdjustmentOnNS:
          case RecognitionEditType.AutomaticAdjustmentOnIntNS:
            return true;
        }
      })(params),
      freezingDisabled: (({ options: { editProjectType, migratedFromLegacySystem }, periodIsInFuture }) => {
        const periodInFuture = freezingAllDisabledCond(periodIsInFuture, "Period is in the future.");
        const notMigratedFromLegacy = freezingAllDisabledCond(
          !migratedFromLegacySystem,
          "Project does not have 'Migrated from legacy system' tag"
        );
        switch (editProjectType) {
          case RecognitionEditType.EncompixPOrder:
            return freezingAllDisabled(["Not freezable."]);
          case RecognitionEditType.LnProject:
          case RecognitionEditType.LnServiceContract:
          case RecognitionEditType.LnServiceOrder:
          case RecognitionEditType.LnSalesOrder:
            return union(periodInFuture, notMigratedFromLegacy);
          case RecognitionEditType.AutomaticAdjustmentOnNS:
          case RecognitionEditType.AutomaticAdjustmentOnIntNS:
            return freezingAllDisabled(["Not freezable."]);
          default:
            return freezingAllEnabled;
        }
      })(params),
    }),
  },
  comm_costs_warr_edit: {
    columnLabel: "Comm Costs Warr",
    measures: ["act", "hardCom", "softCom"],
    labels: ["Act", "Hard Com", "Soft Com"],
    calculated: false,
    copyMode: editType => {
      if (editType === RecognitionEditType.Manual) {
        return "future_smaller";
      } else {
        return { act: "future_smaller" };
      }
    },
    options: params => ({
      disabled: (({ options: { editProjectType }, frozenFields }) => {
        switch (editProjectType) {
          case RecognitionEditType.NonEditable:
          case RecognitionEditType.NonEditableAutomaticAdjustmentOnNS:
          case RecognitionEditType.NonEditableAutomaticAdjustmentOnIntNS:
          case RecognitionEditType.LnServiceOrder:
          case RecognitionEditType.LnSalesOrder:
            return true;
          case RecognitionEditType.Manual:
            return disabledFields(false, frozenFields);
          case RecognitionEditType.BaanProject:
          case RecognitionEditType.BaanPcsProject:
          case RecognitionEditType.LnProject:
          case RecognitionEditType.LnServiceContract:
          case RecognitionEditType.EncompixPOrder:
            return disabledFields(true, frozenFields);
          case RecognitionEditType.AutomaticAdjustmentOnNS:
          case RecognitionEditType.AutomaticAdjustmentOnIntNS:
            return true;
        }
      })(params),
      freezingDisabled: (({ options: { editProjectType, migratedFromLegacySystem }, periodIsInFuture }) => {
        const periodInFuture = freezingAllDisabledCond(periodIsInFuture, "Period is in the future.");
        const notMigratedFromLegacy = freezingAllDisabledCond(
          !migratedFromLegacySystem,
          "Project does not have 'Migrated from legacy system' tag"
        );
        switch (editProjectType) {
          case RecognitionEditType.EncompixPOrder:
            return freezingAllDisabled(["Not freezable."]);
          case RecognitionEditType.LnProject:
          case RecognitionEditType.LnServiceContract:
          case RecognitionEditType.LnServiceOrder:
          case RecognitionEditType.LnSalesOrder:
            return union(periodInFuture, notMigratedFromLegacy);
          case RecognitionEditType.AutomaticAdjustmentOnNS:
          case RecognitionEditType.AutomaticAdjustmentOnIntNS:
            return freezingAllDisabled(["Not freezable."]);
          default:
            return freezingAllEnabled;
        }
      })(params),
    }),
  },
  ptd_variances_edit: {
    columnLabel: "PTD Variances",
    measures: ["netSalesVarianceTotal", "costVarianceTotal"],
    labels: ["NS", "Costs"],
    calculated: false,
    copyMode: () => "none",
    options: params => ({
      disabled: true,
      freezingDisabled: calculatedColumnSetFreezingDisabled(params),
    }),
  },
  orders_received_edit: {
    columnLabel: "Orders Received",
    measures: ["orPeriod", "adjOnOR"],
    labels: ["Period", "Adj on OR"],
    calculated: false,
    copyMode: () => "none",
    options: params => ({
      disabled: true,
      freezingDisabled: ordersReceivedFreezingDisabled(params),
    }),
  },
  rates_edit: {
    columnLabel: "Rates",
    measures: ["erRate", "irRate"],
    labels: ["ER Rate", "IR Rate"],
    calculated: false,
    copyMode: () => "future",
    options: params => {
      switch (params.options.editProjectType) {
        case RecognitionEditType.AutomaticAdjustmentOnNS:
          return {
            disabled: true,
            freezingDisabled: freezingPartiallyCond(true, "irRate", "Project is not Adjustment on Internal NS"),
          };
        case RecognitionEditType.AutomaticAdjustmentOnIntNS:
          return {
            disabled: true,
            freezingDisabled: freezingPartiallyCond(true, "erRate", "Project is not Adjustment on NS"),
          };
        default:
          return {
            disabled: true,
            freezingDisabled: freezingAllDisabled(["Not freezable."]),
          };
      }
    },
  },
};

export const RECOGNITION_EDIT_COLUMNS = {
  fieldDetails: recognitionsEditFieldDetails,
  allColumnIds: Object.keys(recognitionsEditFieldDetails) as EditableColumnId[],
  allColumns: (Object.keys(recognitionsEditFieldDetails) as VisibleEditableColumnId[]).map(key => ({
    id: key,
    name: recognitionsEditFieldDetails[key].columnLabel,
  })),
  allNonCalculatedColumnIds: ((Object.keys(recognitionsEditFieldDetails) as VisibleEditableColumnId[]).filter(
    key => recognitionsEditFieldDetails[key].calculated !== true // i.e. is false or array of calculated fields
  ) as EditableColumnId[]).concat(["related_est_rec_edit"]),
  isEditableColumn: (columnId: EditableColumnId) =>
    columnId !== "related_est_rec_edit" && recognitionsEditFieldDetails[columnId] !== undefined,
  isCalculatedColumn: (columnId: EditableColumnId, field: string) => {
    if (columnId === "related_est_rec_edit") return true;
    const details = recognitionsEditFieldDetails[columnId];
    if (details) {
      if (details.calculated instanceof Array) return details.calculated.includes(field);
      return details.calculated;
    }
    return false;
  },
  getCopyModeForField: (editType: RecognitionEditType, columnId: EditableColumnId, fieldName: string): CopyMode => {
    if (columnId === "related_est_rec_edit") return "none";
    const details = recognitionsEditFieldDetails[columnId];
    if (!details) {
      return "none";
    } else {
      const copyMode = details.copyMode(editType);
      if (typeof copyMode === "string") {
        return copyMode;
      } else {
        return copyMode[fieldName] || "none";
      }
    }
  },
};

export const makeEditingPredicateParams = (
  thisPeriod: Period,
  item: EditableRecognitionRow,
  cell: EditableRecognitionCell,
  options: RecognitionEditOptions,
  estRecEditMode: RecPlanEstRecEditModes
): EditingPredicateParams => {
  const isItemPeriod = (period: Period | null): boolean => (period ? periodEquals(period, item.period) : false);
  const period = item.period;

  const periodEstRecEditMode: RecPlanEstRecEditModes = options.isPTProjectWithEstRecFromRelated
    ? RecPlanEstRecEditModes.NotEditable
    : estRecEditMode;

  return {
    period,
    frozenFields: cell.freezings.map(v => v.fieldName),
    options,
    periodIsInPast: periodGreaterThan(thisPeriod, period),
    periodIsCurrent: periodEquals(period, thisPeriod),
    periodIsInFuture: periodGreaterThan(period, thisPeriod),
    isAsSoldPeriod: isItemPeriod(options.asSoldPeriod),
    isBudgetPeriod: isItemPeriod(options.budgetPeriod),
    isRecCompletionPeriod: isItemPeriod(options.recCompletionPeriod),
    getCell: item.getCell.bind(item),
    estRecEditMode: periodEstRecEditMode,
  };
};

const conditionToFreezingDisabledResult = (condition: Condition): FreezingDisabledResult =>
  freezingAllDisabledCond(!holds(condition), conditionToString(condition));

const conditionToPartialFreezingDisabledResult = (condition: Condition, field: string): FreezingDisabledResult =>
  freezingPartiallyCond(!holds(condition), field, conditionToString(condition));

export const calculatedColumnSetFreezingDisabled = ({
  options: { editProjectType, migratedFromLegacySystem, additionalAdjustments, editablePcsProject },
  periodIsInFuture,
}: EditingPredicateParams): FreezingDisabledResult => {
  const notFuturePeriod = cond(!periodIsInFuture, "Not freezable for a future period");
  const migrated = cond(migratedFromLegacySystem, "'Migrated from legacy system'");
  const additional = cond(additionalAdjustments, "'Additional adjustments'");
  const hasEitherTag = either(migrated, additional, "tag needed");

  switch (editProjectType) {
    case RecognitionEditType.NonEditable:
    case RecognitionEditType.NonEditableAutomaticAdjustmentOnNS:
    case RecognitionEditType.NonEditableAutomaticAdjustmentOnIntNS:
    case RecognitionEditType.AutomaticAdjustmentOnNS:
    case RecognitionEditType.AutomaticAdjustmentOnIntNS:
      return freezingAllDisabled(["Not editable."]);
    case RecognitionEditType.Manual:
      return freezingAllEnabled;
    case RecognitionEditType.LnProject:
    case RecognitionEditType.EncompixPOrder:
    case RecognitionEditType.LnServiceContract:
    case RecognitionEditType.BaanProject:
    case RecognitionEditType.LnServiceOrder:
    case RecognitionEditType.LnSalesOrder: {
      const condition = and(notFuturePeriod, hasEitherTag);
      return conditionToFreezingDisabledResult(condition);
    }
    case RecognitionEditType.BaanPcsProject: {
      const editablePcsTag = cond(editablePcsProject, "No 'Editable PCS project' tag");
      const condition = and(notFuturePeriod, and(editablePcsTag, hasEitherTag));
      return conditionToFreezingDisabledResult(condition);
    }
  }
};

export const ordersReceivedFreezingDisabled = ({
  options: { editProjectType, migratedFromLegacySystem, additionalAdjustments, editablePcsProject },
  periodIsInFuture,
}: EditingPredicateParams): FreezingDisabledResult => {
  const notFuturePeriod = cond(!periodIsInFuture, "Not freezable for a future period");
  const migrated = cond(migratedFromLegacySystem, "'Migrated from legacy system'");
  const additional = cond(additionalAdjustments, "'Additional adjustments'");
  const hasEitherTag = either(migrated, additional, "tag needed");

  switch (editProjectType) {
    case RecognitionEditType.NonEditable:
    case RecognitionEditType.NonEditableAutomaticAdjustmentOnNS:
    case RecognitionEditType.NonEditableAutomaticAdjustmentOnIntNS:
    case RecognitionEditType.AutomaticAdjustmentOnNS:
    case RecognitionEditType.AutomaticAdjustmentOnIntNS:
      return freezingAllDisabled(["Not editable."]);
    case RecognitionEditType.Manual:
      return conditionToFreezingDisabledResult(notFuturePeriod);
    case RecognitionEditType.LnProject:
    case RecognitionEditType.LnServiceContract:
    case RecognitionEditType.EncompixPOrder:
    case RecognitionEditType.BaanProject:
    case RecognitionEditType.LnServiceOrder:
    case RecognitionEditType.LnSalesOrder: {
      const adjOnOR_cond = notFuturePeriod;
      const OR_cond = and(notFuturePeriod, hasEitherTag);
      if (holds(and(adjOnOR_cond, OR_cond))) {
        return freezingAllEnabled;
      } else {
        return union(
          conditionToPartialFreezingDisabledResult(adjOnOR_cond, "adjOnOR"),
          conditionToPartialFreezingDisabledResult(OR_cond, "orPeriod")
        );
      }
    }
    case RecognitionEditType.BaanPcsProject: {
      const editablePcsTag = cond(editablePcsProject, "No 'Editable PCS project' tag");
      const commonCond = and(editablePcsTag, notFuturePeriod);
      if (holds(commonCond)) {
        return freezingAllEnabled;
      } else {
        const adjOnOR_cond = commonCond;
        const OR_cond = and(commonCond, hasEitherTag);
        return union(
          conditionToPartialFreezingDisabledResult(adjOnOR_cond, "adjOnOR"),
          conditionToPartialFreezingDisabledResult(OR_cond, "orPeriod")
        );
      }
    }
  }
};

// Edit modes for editing EST REC and EST REC%
export enum RecPlanEstRecEditModes {
  WipCosts = "WipCosts",
  Ns = "Ns",
  CogsPercentage = "CogsPercentage",
  NsCogsPercentage = "NsCogsPercentage",
  NotEditable = "NotEditable",
}

export const initState: RecognitionsEditState = {
  projectId: null,
  editing: false,
  initialized: false,
  waitingForReady: null,

  initialRecognitions: [],
  recognitions: [],
  changes: new Map(),
  latestChange: null,
  saveChanges: null,

  options: {
    editProjectType: RecognitionEditType.NonEditable,
    migratedFromLegacySystem: false,
    additionalAdjustments: false,
    editablePcsProject: false,
    revenueMethodIsStraightLine: false,
    asSoldPeriod: null,
    budgetPeriod: null,
    oblPeriod: null,
    recCompletionPeriod: null,
    isPTProjectWithEstRecFromRelated: false,
  },

  recognitionsErrors: [],

  massFreeze: {
    dialogOpen: false,
    fromPeriod: null,
    toPeriod: null,
  },

  user: undefined,
};
