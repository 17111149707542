import React, { useCallback, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import {
  AppState,
  CompaniesResult,
  ProjectId,
  ProjectRelation,
  ProjectRelationEditInformationData,
  ProjectRelationInput,
  ProjectRelationSectionInput,
  ProjectRelationsEditInformation,
  ProjectRelationTypeItem,
} from "../../../../../common/types";
import { ThunkDispatch } from "redux-thunk";
import { Action } from "redux";
import { setProjectError, setProjectRelations } from "../../../../../actions/projectActions";
import produce from "immer";
import { IconButton } from "../../../../../common/components";
import { cloneDeep } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { settingGreen } from "../../../../../common/colors";
import styled from "styled-components";
import { useQuery } from "@apollo/client/react/hooks";
import { GET_COMPANIES, GET_PROJECT_RELATION_EDIT_INFORMATION } from "./queries";
import LoadingView from "../../../../LoadingView";
import ViewDetails from "./ViewDetails";
import EditableRelationsDetailsRow from "./EditableRelationsDetailsRow";
import { getEmptyProjectRelationSectionInput } from "../../../../../common/constants";

export interface EditDetailsProps {
  projectId?: ProjectId;
  relations: ProjectRelation[];
  relationTypeItems: ProjectRelationTypeItem[];
}

const mapStateToProps = (state: AppState) => {
  return {
    sectionInput: state.projectState.projectInput
      ? state.projectState.projectInput.projectRelations
      : getEmptyProjectRelationSectionInput(),
    editInformation: state.projectState.projectDetailsEditInformation
      ? state.projectState.projectDetailsEditInformation.relations
      : undefined,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, void, Action>) => {
  return {
    setProjectRelationsInput: (input: ProjectRelationSectionInput) => dispatch(setProjectRelations(input)),
    setProjectError: (value: boolean) => {
      dispatch(setProjectError(value, "relations"));
    },
  };
};

export const isDigit = (c: string): boolean => {
  const c0 = "0".charCodeAt(0);
  const c9 = "9".charCodeAt(0);
  return c0 <= c.charCodeAt(0) && c.charCodeAt(0) <= c9;
};

const relatingKeysValid = (
  relationType: string,
  findRelationTypeItem: (type: string) => ProjectRelationTypeItem | undefined,
  relatingKeys: string[]
): boolean => {
  const relationTypeItem = findRelationTypeItem(relationType);
  if (relationTypeItem === undefined) return false;
  // NOTE: there are possible hidden relating key(s) for Baan PCS projects
  if (relationType !== "BAAN PCS PROJECT") {
    if (relatingKeys.length !== relationTypeItem.relatingKeys.length) return false;
  }
  // For all relating keys required by the relation type check if the corresponding value is not empty
  for (const index in relationTypeItem.relatingKeys) {
    const value = relatingKeys[index];
    if (value === undefined || value === null || value.trim() === "") return false;
  }
  return true;
};

const checkInputErrors = (
  sectionInput: ProjectRelationInput[] | undefined,
  editInformation: ProjectRelationsEditInformation | undefined,
  findRelationTypeItem: (type: string) => ProjectRelationTypeItem | undefined
) => {
  if (!editInformation) {
    return false;
  } else if (sectionInput) {
    const nullable = editInformation.properties.nullable;
    for (let i = 0; i < sectionInput.length; i++) {
      const sectionInputItem = sectionInput[i];
      if (sectionInputItem.projectRelationId === null) {
        console.log("section input", sectionInputItem);
      }
      if (sectionInputItem.company === null && nullable !== null && !nullable) {
        return true;
      }
      if (sectionInputItem.relationType === null && nullable !== null && !nullable) {
        return true;
      }
      if (
        sectionInputItem.relationType &&
        !relatingKeysValid(sectionInputItem.relationType, findRelationTypeItem, sectionInputItem.relatingKeys)
      ) {
        return true;
      }
    }
    return false;
  } else {
    return false;
  }
};

function EditDetails(
  props: EditDetailsProps & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
): React.ReactElement {
  const {
    projectId,
    relations,
    relationTypeItems,
    sectionInput,
    editInformation,
    setProjectRelationsInput: setSectionInput,
    setProjectError,
  } = props;
  const relationTypeItem = (relationType: string) => relationTypeItems.find(item => item.relationType === relationType);

  const { data, error, loading } = useQuery<ProjectRelationEditInformationData>(GET_PROJECT_RELATION_EDIT_INFORMATION, {
    variables: { projectId: projectId },
    skip: projectId === undefined,
    fetchPolicy: "network-only",
  });
  const editInfo = editInformation
    ? editInformation
    : !loading && !error && data
    ? data.projectDetailsEditInformation.relations
    : undefined;

  const { loading: companiesLoading, data: companiesData, error: companiesError } = useQuery<CompaniesResult>(
    GET_COMPANIES,
    {}
  );

  const onEditInformationDataChange = useCallback(() => {
    if (editInfo && editInfo.properties.editable) {
      const newSectionInput = editInfo.values.map(relation => {
        return {
          projectRelationId: relation.projectRelationId,
          relationType: relation.relationType,
          company: relation.company,
          relatingKeys: relation.relatingKeys,
        };
      });
      if (newSectionInput.length > 0) setSectionInput({ input: newSectionInput, pristine: true });
      else
        setSectionInput({
          input: [
            {
              projectRelationId: null,
              relationType: null,
              company: null,
              relatingKeys: [],
            },
          ],
          pristine: true,
        });
    }
  }, [editInfo, setSectionInput]);

  useEffect(onEditInformationDataChange, [editInfo]);

  const inputErrors = useMemo(() => checkInputErrors(sectionInput.input, editInfo, relationTypeItem), [
    sectionInput,
    editInfo,
  ]);
  useEffect(() => {
    setProjectError(inputErrors);
  }, [inputErrors]);

  const isInputItemValid = useCallback(
    (relations: ProjectRelationInput[]) =>
      relations.every(relation => {
        return (
          relation.relationType !== null &&
          relation.company !== null &&
          relatingKeysValid(relation.relationType, relationTypeItem, relation.relatingKeys)
        );
      }),
    [relationTypeItems]
  );

  return loading || companiesLoading ? (
    <LoadingView />
  ) : editInfo && !editInfo.properties.editable ? (
    <ViewDetails relations={relations} relationTypeItems={relationTypeItems} comment={editInfo.properties.message} />
  ) : editInfo && companiesData && !error && !companiesError ? (
    <EditContainer>
      <EditableRows>
        {sectionInput.input.map((value, i) => (
          <EditableRelationsDetailsRow
            key={i}
            sectionInputItem={value}
            companiesData={companiesData}
            relationTypeItems={relationTypeItems.filter(item => item.selectable)}
            showHeader={!sectionInput.input[i - 1] || sectionInput.input[i - 1].relationType !== value.relationType}
            noBorder={i === 0}
            setSectionInputItem={item => {
              const nextItem = produce(sectionInput.input, next => {
                next[i] = item;
              });
              const isValid = isInputItemValid(nextItem);
              setProjectError(!isValid);
              setSectionInput({ input: nextItem, pristine: false });
            }}
            onRemoveSectionInputItem={() => {
              const nextItem = produce(sectionInput.input, next => {
                next.splice(i, 1);
              });
              setProjectError(!isInputItemValid(nextItem));
              setSectionInput({ input: nextItem, pristine: false });
            }}
          />
        ))}
      </EditableRows>
      <ButtonContainer>
        <IconButton
          onClick={() => {
            const newSectionInput = cloneDeep(sectionInput.input);
            newSectionInput.push({
              projectRelationId: null,
              relationType: null,
              company: null,
              relatingKeys: [],
            });
            setSectionInput({ input: newSectionInput, pristine: false });
          }}
          title={"Add relation"}
        >
          <FontAwesomeIcon icon={faPlusCircle} size="1x" color={settingGreen} />
        </IconButton>
      </ButtonContainer>
    </EditContainer>
  ) : (
    <LoadingContainer>Error loading editing data</LoadingContainer>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(EditDetails);

const EditContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const EditableRows = styled.div``;

const ButtonContainer = styled.div<{ margin?: boolean; border?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 10px;
  margin-bottom: ${({ margin }) => (margin ? "20px;" : `0px;`)};
  ${({ border }) => `padding-top: ${border ? `10px` : "0"}`}
`;

const LoadingContainer = styled.div`
  padding-bottom: 20px;
`;
