import React, { useCallback, useEffect, useState, PropsWithChildren } from "react";
import styled from "styled-components";
import { faCommentDots, faEdit, faLock, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import onClickOutside, { HandleClickOutside, InjectedOnClickOutProps } from "react-onclickoutside";
import { format } from "date-fns";
import {
  commentBlue,
  commentBackgroundBlue,
  commentTextBackgroundBlue,
  defaultGrey,
  filterGreen,
  cancelRed,
} from "../../common/colors";
import { Comment, CommentType, CommentTypes, ListingProjectCellFreezing } from "../../common/types";
import {
  EditableRecognitionCellComment,
  EditableRecognitionCellFreezing,
} from "../sites/Project/ProjectRecognitions/types";
import NumberInput from "../TableDataCell/NumberInput";
import { valueToSave, valueForDisplay } from "../TableDataCell/TableDataCell";
import { sortBy } from "lodash";
import FullscreenSpinner from "../FullscreenSpinner";
import { ActionButton, IconButton } from "../../common/components";
import { ColumnFieldSpec } from "../../common/columnsTypes";
import { Format } from "../../common/utils";

type Props = {
  field: ColumnFieldSpec;
  commentTypes: CommentType[];
  comments: (Comment | EditableRecognitionCellComment)[];
  freezingTitle?: string;
  frozenTitle?: string;
  freezings: (ListingProjectCellFreezing | EditableRecognitionCellFreezing)[];
  freezingDisabledReason?: string[];
  onAddFreezing?: (value: number, comment: string) => void;
  onRemoveFreezing?: () => void;
  initialFreezeValue?: number;
  onAddComment?: (type: CommentType, value: number, content: string) => Promise<Comment>;
  onDeleteComment?: (type: CommentType, value: number, content: string) => Promise<void>;
  className?: string;
};

interface EditableComment {
  type: CommentType;
  value?: number | null;
  content: string;
  currencyScenario?: string;
  createdBy?: string;
  createdDateTime?: string;
}

type State = {
  isOpen: boolean;
  addFreezingMode: "button" | "edit";
  onLeft: boolean;
  width: number;
};

interface FreezingContentFreezing {
  createdDateTime?: string;
  createdBy?: string;
  comment: string;
  value: number;
}

interface FreezingContentProps {
  field: ColumnFieldSpec;
  freezingTitle: string;
  frozenTitle: string;
  freezingDisabledReason?: string[];
  onAddFreezing?: (value: number, comment: string) => void;
  onRemoveFreezing?: () => void;
  initialFreezeValue: number;
  mode: "button" | "edit";
  setMode: (mode: "button" | "edit") => void;
  freezing?: FreezingContentFreezing;
}

const FreezingContent = ({
  field,
  freezingTitle,
  frozenTitle,
  freezingDisabledReason,
  onAddFreezing,
  onRemoveFreezing,
  initialFreezeValue,
  mode,
  setMode,
  freezing,
}: FreezingContentProps) => {
  const [value, setValue] = useState<number>(valueForDisplay(field, initialFreezeValue));
  const [valueError, setValueError] = useState<boolean>(false);
  const [comment, setComment] = useState<string>("");

  const canFreeze = comment.length > 0 && !valueError;

  const editExistingFreezing = (comment: string) => {
    setComment(comment);
    setMode("edit");
  };

  const renderButton = () => (
    <CommentsContainer>
      <ActionButton onClick={() => setMode("edit")}>
        <FontAwesomeIcon icon={faLock} size="1x" color={commentBlue} />
        Freeze value
      </ActionButton>
    </CommentsContainer>
  );

  const renderEdit = (onAddFreezing: (value: number, comment: string) => void) => (
    <CommentsContainer>
      <TitleContainer>{freezingTitle}</TitleContainer>
      <FreezeContainer>
        <FreezeContainerRow>
          <FreezeLabel>Value</FreezeLabel>
          <ValueInput
            value={value.toFixed(field.decimals)}
            decimals={field.decimals}
            type="float"
            onChange={value => {
              setValue(value);
              setValueError(false);
            }}
            onError={() => setValueError(true)}
          />
        </FreezeContainerRow>
        <FreezeContainerRow>
          <FreezeLabel>Comment</FreezeLabel>
          <FreezingCommentInput value={comment} rows={3} onChange={ev => setComment(ev.target.value)} />
        </FreezeContainerRow>
        <FreezeContainerRow>
          {onRemoveFreezing && freezing && (
            <ActionButton onClick={() => onRemoveFreezing()}>
              <FontAwesomeIcon icon={faTrash} />
              Delete
            </ActionButton>
          )}
          <CommentActions>
            <ActionButton onClick={() => setMode("button")} color={defaultGrey}>
              Cancel
            </ActionButton>
            <ActionButton
              disabled={!canFreeze}
              onClick={() => {
                setMode("button");
                onAddFreezing(value, comment);
              }}
            >
              Freeze
            </ActionButton>
          </CommentActions>
        </FreezeContainerRow>
      </FreezeContainer>
    </CommentsContainer>
  );

  const renderFreezing = ({ createdDateTime, createdBy, comment, value }: FreezingContentFreezing) => {
    const date = () => (createdDateTime ? format(new Date(createdDateTime), "dd.MM.yyyy") : undefined);
    return (
      <CommentsContainer>
        <TitleContainer>{frozenTitle}</TitleContainer>
        <CommentContainer>
          <CommentSubtitle>
            <Commenter>{createdBy}</Commenter>
            <CommentDate>{createdDateTime && date()}</CommentDate>
          </CommentSubtitle>
          <CommentHeader>
            <CommentTitle>Frozen</CommentTitle>
            <CommentValue>Value: {Format.withThousandSeparator(valueForDisplay(field, value), 0)}</CommentValue>
          </CommentHeader>
          <CommentText>{comment}</CommentText>
          {onRemoveFreezing && (
            <FreezeContainerRow>
              <ActionButton onClick={() => onRemoveFreezing()}>
                <FontAwesomeIcon icon={faTrash} />
                Delete
              </ActionButton>
              <CommentActions>
                <ActionButton onClick={() => editExistingFreezing(comment)}>
                  <FontAwesomeIcon icon={faEdit} />
                  Edit
                </ActionButton>
              </CommentActions>
            </FreezeContainerRow>
          )}
        </CommentContainer>
      </CommentsContainer>
    );
  };

  if (onAddFreezing) {
    if (mode === "edit") return renderEdit(onAddFreezing);
    else if (freezing) return renderFreezing(freezing);
    else if (mode === "button") return renderButton();
    else return null;
  } else if (freezing) return renderFreezing(freezing);
  else {
    return (
      <CommentsContainer>
        {!!freezingDisabledReason && (
          <>
            <CommentTitle>Freezing disabled:</CommentTitle>
            {freezingDisabledReason
              .flatMap(reason => reason.split("\n"))
              .map((reason, index) => {
                return (
                  <div key={"reason-" + index} style={{ whiteSpace: "pre" }}>
                    {reason}
                  </div>
                );
              })}
          </>
        )}
      </CommentsContainer>
    );
  }
};

function CommentComponent(comment: EditableComment, key: string, editingDisabled: boolean, editComment: () => void) {
  const { type, value, content, currencyScenario, createdBy, createdDateTime } = comment;
  const date = () => (createdDateTime ? format(new Date(createdDateTime), "dd.MM.yyyy") : undefined);
  return (
    <CommentContainer key={key}>
      <CommentSubtitle>
        <Commenter>{createdBy}</Commenter>
        <CommentDate>{createdDateTime && date()}</CommentDate>
      </CommentSubtitle>
      <CommentHeader>
        {type && <CommentTypeDesc>{type}</CommentTypeDesc>}
        {typeof value === "number" && <CommentValue>Value: {Format.withThousandSeparator(value, 0)}</CommentValue>}
      </CommentHeader>
      <CommentText>{content}</CommentText>
      <CommentFooter>
        {currencyScenario && <div>{currencyScenario}</div>}
        {type !== CommentTypes.FreezingComment && (
          <CommentActions>
            <ActionButton onClick={editComment} disabled={editingDisabled}>
              <FontAwesomeIcon icon={faEdit} />
              Edit
            </ActionButton>
          </CommentActions>
        )}
      </CommentFooter>
    </CommentContainer>
  );
}

function CommentEditComponent(
  comment: EditableComment | null,
  key: string,
  availableCommentTypes: CommentType[],
  ensureVisibleRef: (elem: HTMLDivElement | null) => void,
  isNewComment: boolean,
  saveComment: () => void,
  cancel: () => void,
  setComment: (comment: EditableComment) => void,
  deleteComment?: (() => void) | undefined
) {
  if (comment === null) {
    return <CommentContainer key={key} />;
  }
  const { type, value, content, createdBy, createdDateTime } = comment;
  const date = () => (createdDateTime ? format(new Date(createdDateTime), "dd.MM.yyyy") : undefined);
  return (
    <CommentContainer key={key} ref={ensureVisibleRef}>
      {isNewComment && <CommentTitle>New comment</CommentTitle>}
      <CommentSubtitle>
        <Commenter>{createdBy}</Commenter>
        <CommentDate>{createdDateTime && date()}</CommentDate>
      </CommentSubtitle>
      <FreezeContainerRow>
        <CommentLabel>Type</CommentLabel>
        {isNewComment ? (
          <CommentTypeSelect
            disabled={availableCommentTypes.length < 1}
            value={type || ""}
            onChange={ev => setComment({ ...comment, type: ev.target.value })}
          >
            {availableCommentTypes.map(v => (
              <option value={v} key={v}>
                {v}
              </option>
            ))}
          </CommentTypeSelect>
        ) : (
          <CommentTypeDesc>{type}</CommentTypeDesc>
        )}
      </FreezeContainerRow>
      <FreezeContainerRow>
        <CommentLabel>Value</CommentLabel>
        <ValueInput
          value={typeof value === "number" ? value.toString() : "0"}
          type="float"
          onChange={newValue => setComment({ ...comment, value: newValue })}
        />
      </FreezeContainerRow>
      <SubmitCommentRow>
        <CommentInput
          placeholder="Write comment here"
          value={content}
          rows={3}
          onChange={ev => setComment({ ...comment, content: ev.target.value })}
        />
      </SubmitCommentRow>
      <SubmitCommentRow>
        {deleteComment && (
          <ActionButton onClick={deleteComment} color={cancelRed}>
            <FontAwesomeIcon icon={faTrash} />
            Delete
          </ActionButton>
        )}
        <CommentActions>
          <ActionButton onClick={cancel} color={defaultGrey}>
            Cancel
          </ActionButton>
          <ActionButton onClick={saveComment}>Save</ActionButton>
        </CommentActions>
      </SubmitCommentRow>
    </CommentContainer>
  );
}

interface CommentsContentProps {
  comments: (Comment | EditableRecognitionCellComment)[];
  availableCommentTypes: CommentType[];
  onAddComment?: (type: CommentType, value: number, content: string) => Promise<Comment>;
  onEditComment?: (type: CommentType, value: number, content: string) => Promise<void>;
  onDeleteComment?: (type: CommentType, value: number, content: string) => Promise<void>;
  containerRef: HTMLDivElement | null;
}

// Make sure that the currently edited comment is fully visible.
// We have to use a "callback ref" instead of useRef, as the non-rendering useEffect will not be able
// to observe the reference being set. By passing the ref setting callback to the child element, we can
// react to the element being rendered and scroll the container so the child element will be visible.
// React FAQ example: https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
//function useEnsureVisible(containerRef: React.RefObject<HTMLElement>) {
function useEnsureVisible(containerRef: HTMLElement | null) {
  return useCallback(
    (elem: HTMLDivElement | null) => {
      if (elem && containerRef) {
        containerRef.scrollTo(0, elem.offsetTop - 10);
      }
    },
    [containerRef]
  );
}

const CommentsContent = ({
  comments: originalComments,
  availableCommentTypes: allCommentTypes,
  onAddComment,
  onDeleteComment,
  containerRef,
}: CommentsContentProps) => {
  const [saving, setSaving] = useState<boolean>(false);
  const [newComment, setNewComment] = useState<EditableComment | null>(null);
  const [commentEditIndex, setCommentEditIndex] = useState<number | null>(null);
  const [comments, setComments] = useState<EditableComment[]>(originalComments);
  const ensureVisibleRef = useEnsureVisible(containerRef);

  const availableCommentTypes = allCommentTypes.filter(v => !comments.map(c => c.type).includes(v));

  useEffect(() => {
    setComments(originalComments);
  }, [originalComments]);

  const editComment = (newComment: EditableComment, index: number) => {
    const newComments = comments.map((c, i) => (i === index ? newComment : c));
    setComments(newComments);
  };

  const saveComment = async (comment: EditableComment) => {
    if (onAddComment) {
      setSaving(true);
      await onAddComment(comment.type, comment.value ? comment.value : 0, comment.content);
      setCommentEditIndex(null);
      setSaving(false);
    }
  };

  const saveNewComment = async (comment: EditableComment) => {
    if (onAddComment && comment.type !== "" && comment.content !== "") {
      setSaving(true);
      const newComment: EditableComment = await onAddComment(
        comment.type,
        comment.value ? comment.value : 0,
        comment.content
      );
      const newComments = [newComment].concat(comments);
      setComments(newComments);
      setNewComment(null);
      setSaving(false);
    }
  };

  const deleteComment = async (comment: EditableComment) => {
    if (onDeleteComment) {
      setSaving(true);
      await onDeleteComment(comment.type, comment.value ? comment.value : 0, comment.content);
      setCommentEditIndex(null);
      setSaving(false);
    }
  };

  const editingDisabled = newComment !== null || !onAddComment;

  const commentComponents = sortBy(comments, comment => {
    if (!comment.createdDateTime) return -Infinity;
    const date = new Date(comment.createdDateTime);
    return -date.getTime();
  }).map((comment, index) =>
    index === commentEditIndex && onAddComment
      ? CommentEditComponent(
          comment,
          `comment-${index}`,
          availableCommentTypes,
          ensureVisibleRef,
          false,
          () => saveComment(comment),
          () => setCommentEditIndex(null),
          newComment => editComment(newComment, index),
          () => deleteComment(comment)
        )
      : CommentComponent(comment, `comment-${index}`, editingDisabled, () => setCommentEditIndex(index))
  );

  const newCommentComponent =
    availableCommentTypes &&
    CommentEditComponent(
      newComment,
      "new-comment",
      availableCommentTypes,
      ensureVisibleRef,
      true,
      () => (newComment ? saveNewComment(newComment) : undefined),
      () => setNewComment(null),
      newComment => setNewComment(newComment)
    );

  const newEmptyComment = (): EditableComment => ({
    type: availableCommentTypes[0],
    content: "",
  });

  const showNewComment = newComment !== null;
  const newCommentButton = !showNewComment && onAddComment && (
    <ActionButton onClick={() => setNewComment(newEmptyComment())} disabled={commentEditIndex !== null}>
      <FontAwesomeIcon icon={faPlus} />
      New Comment
    </ActionButton>
  );

  if (comments.length === 0) {
    return (
      <CommentsContainer>
        <TitleRowContainer>{newCommentButton}</TitleRowContainer>
        {onAddComment && showNewComment && newCommentComponent}
        {saving && <SaveCommentSpinner text={"Saving..."} />}
      </CommentsContainer>
    );
  } else {
    return (
      <>
        <CommentsContainer>
          <TitleRowContainer>
            <TitleContainer>Comments</TitleContainer>
            {newCommentButton}
          </TitleRowContainer>
          {onAddComment && showNewComment && newCommentComponent}
          {comments.length > 0 && commentComponents}
        </CommentsContainer>
        {saving && <SaveCommentSpinner text={"Saving..."} />}
      </>
    );
  }
};

function CommentPopover(props: {
  field: ColumnFieldSpec;
  isOpen: boolean;
  hasCommentOrFreezing: boolean;
  freezingTitle: string;
  frozenTitle: string;
  addFreezingMode: "button" | "edit";
  setMode: (mode: "button" | "edit") => void;
  freezingDisabledReason?: string[];
  onLeft: boolean;
  width: number;
  comments: (Comment | EditableRecognitionCellComment)[];
  availableCommentTypes: CommentType[];
  freezing?: ListingProjectCellFreezing | EditableRecognitionCellFreezing;
  onAddFreezing?: (value: number, comment: string) => void;
  onRemoveFreezing?: () => void;
  initialFreezeValue?: number;
  onAddComment?: (type: CommentType, value: number, content: string) => Promise<Comment>;
  onDeleteComment?: (type: CommentType, value: number, content: string) => Promise<void>;
  popoverRef: React.RefObject<HTMLDivElement>;
}) {
  const {
    field,
    isOpen,
    hasCommentOrFreezing,
    freezingTitle,
    frozenTitle,
    addFreezingMode,
    setMode,
    onLeft,
    width,
    comments,
    availableCommentTypes,
    freezingDisabledReason,
    freezing,
    onAddFreezing,
    onRemoveFreezing,
    initialFreezeValue,
    onAddComment,
    onDeleteComment,
    popoverRef,
  } = props;

  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null); //useRef<HTMLDivElement>(null);
  const containerCallbackRef = useCallback((elem: HTMLDivElement) => setContainerRef(elem), []);
  const [overflow, setOverflow] = useState({ top: false, bottom: false });

  useEffect(() => {
    const cont = containerRef;
    if (!cont) return;

    const updateOverflow = () => {
      if (cont.scrollHeight === cont.clientHeight) {
        setOverflow({ top: false, bottom: false });
      } else {
        const top = cont.scrollTop > 5;
        const bottom = cont.scrollTop + cont.clientHeight - cont.scrollHeight < -5;
        setOverflow({ top, bottom });
      }
    };

    updateOverflow();

    cont.addEventListener("scroll", updateOverflow);
    return () => {
      cont.removeEventListener("scroll", updateOverflow);
    };
  }, [containerRef]);

  const getFreezingContent = (
    freezing: ListingProjectCellFreezing | EditableRecognitionCellFreezing
  ): FreezingContentFreezing => {
    const comment = comments.find(
      c =>
        c.type === CommentTypes.FreezingComment &&
        c.createdBy === freezing.createdBy &&
        c.createdDateTime === freezing.createdDateTime
    );
    return {
      createdBy: freezing.createdBy,
      createdDateTime: freezing.createdDateTime,
      comment: comment ? comment.content : "No comment",
      value: freezing.value,
    };
  };

  return (
    <ContentWrapper
      isOpen={isOpen}
      fullSize={hasCommentOrFreezing || addFreezingMode === "edit"}
      onLeft={onLeft}
      width={width}
      onClick={ev => ev.stopPropagation()}
    >
      <div ref={popoverRef}>
        <ContentContainer>
          <DataContainer ref={containerCallbackRef} overflows={overflow}>
            <FreezingContent
              field={field}
              freezingTitle={freezingTitle}
              frozenTitle={frozenTitle}
              freezingDisabledReason={freezingDisabledReason}
              onAddFreezing={onAddFreezing}
              onRemoveFreezing={onRemoveFreezing}
              initialFreezeValue={initialFreezeValue || 0}
              mode={addFreezingMode}
              setMode={setMode}
              freezing={freezing && getFreezingContent(freezing)}
            />
            <CommentsContent
              comments={comments}
              availableCommentTypes={availableCommentTypes}
              onAddComment={onAddComment}
              onDeleteComment={onDeleteComment}
              containerRef={containerRef}
            />
          </DataContainer>
        </ContentContainer>
      </div>
    </ContentWrapper>
  );
}

class CommentView extends React.Component<
  Props & InjectedOnClickOutProps & HandleClickOutside<React.MouseEventHandler>,
  State
> {
  popoverRef: React.RefObject<HTMLDivElement>;
  state: State = {
    isOpen: false,
    addFreezingMode: "button",
    onLeft: false,
    width: 0,
  };

  constructor(props: Props & InjectedOnClickOutProps & HandleClickOutside<React.MouseEventHandler>) {
    super(props);
    this.popoverRef = React.createRef();
  }

  componentDidUpdate() {
    if (this.popoverRef.current) {
      const div = this.popoverRef.current;
      const rect = div.getBoundingClientRect();
      if (rect.width !== this.state.width) this.setWidth(rect.width);
    }
  }

  toggle = (): void => {
    if (this.state.isOpen) {
      this.props.disableOnClickOutside();
    } else {
      this.props.enableOnClickOutside();
    }

    this.setState(state => ({ isOpen: !state.isOpen }));
  };

  setToLeftSide(onLeft: boolean) {
    this.setState(() => ({ onLeft }));
  }

  setWidth(width: number) {
    this.setState(() => ({ width }));
  }

  handleClickOutside = (): void => {
    // NOTE: this condition fixes a 5-second delay in showing the comment popup
    if (this.state.isOpen) {
      this.props.disableOnClickOutside();
      this.setState({ isOpen: false, addFreezingMode: "button" });
    }
  };

  render(): React.ReactElement {
    const {
      field,
      commentTypes,
      comments,
      freezingTitle = "Freeze value",
      frozenTitle = "Value frozen",
      freezings,
      initialFreezeValue,
      freezingDisabledReason,
      onAddFreezing,
      onRemoveFreezing,
      onAddComment,
      onDeleteComment,
      className,
    } = this.props;
    const { addFreezingMode, isOpen, onLeft, width } = this.state;

    if (this.popoverRef.current && !onLeft) {
      const div = this.popoverRef.current;
      const rect = div.getBoundingClientRect();
      if (rect.width !== width) this.setWidth(rect.width);
      const overflowsBody = rect.right + 10.0 > document.body.clientWidth;
      if (!onLeft && overflowsBody) {
        this.setToLeftSide(true);
      }
    }

    const freezing = freezings.length > 0 ? freezings[0] : undefined;
    const hasCommentOrFreezing = freezing !== undefined || comments.length > 0;

    return (
      <Wrapper className={className}>
        <IconButton
          onClick={e => {
            this.toggle();
            e.stopPropagation();
          }}
        >
          <FontAwesomeIcon
            icon={freezing ? faLock : faCommentDots}
            size="1x"
            color={hasCommentOrFreezing ? commentBlue : commentBackgroundBlue}
          />
        </IconButton>
        {isOpen && (
          <CommentPopover
            field={field}
            isOpen={isOpen}
            hasCommentOrFreezing={hasCommentOrFreezing}
            onLeft={onLeft}
            width={width}
            comments={comments}
            availableCommentTypes={commentTypes}
            freezingTitle={freezingTitle}
            frozenTitle={frozenTitle}
            freezing={freezing}
            freezingDisabledReason={freezingDisabledReason}
            initialFreezeValue={initialFreezeValue}
            addFreezingMode={addFreezingMode}
            setMode={mode => this.setState({ addFreezingMode: mode })}
            onAddFreezing={onAddFreezing}
            onRemoveFreezing={onRemoveFreezing}
            onAddComment={onAddComment}
            onDeleteComment={onDeleteComment}
            popoverRef={this.popoverRef}
          />
        )}
      </Wrapper>
    );
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default onClickOutside<any, Props>(CommentView);

const Wrapper = styled.div`
  display: flex;
  height: 17px;
  align-content: center;
`;

type ContentWrapperProps = React.ComponentProps<"div"> &
  PropsWithChildren<{
    isOpen: boolean;
    fullSize: boolean;
    onLeft: boolean;
    width: number;
  }>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ContentWrapper = styled(({ isOpen, fullSize, onLeft, width, ...rest }: ContentWrapperProps) => (
  <div {...rest} />
))<ContentWrapperProps>`
  visibility: visible;
  /*${({ isOpen }) => (isOpen ? "visible" : "hidden")};*/
  background: ${commentBackgroundBlue};
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 10px;
  position: absolute;
  display: inline-block;
  ${({ fullSize }) => (fullSize ? "width: 400px;" : "max-width: 400px;")}
  z-index: 500;
  /*button:hover {
    background: ${filterGreen};
  }*/
  margin-left: ${({ onLeft, width }) => (onLeft ? -(width + 32) : 32)}px;
  margin-top: ${({ fullSize }) => (fullSize ? "-48px" : "-10px")};
  div::after {
    content: "";
    position: absolute;
    top: ${({ fullSize }) => (fullSize ? "47px" : "6px")};
    ${({ onLeft }) => (onLeft ? "right: 0" : "left: 0")};
    ${({ onLeft }) => (onLeft ? "margin-right: -20px" : "margin-left: -20px")};
    border-color: ${({ onLeft }) =>
      onLeft
        ? `transparent transparent transparent ${commentBackgroundBlue}`
        : `transparent ${commentBackgroundBlue} transparent transparent`};
    border-width: 10px;
    border-style: solid;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const DataContainer = styled.div<{ overflows: { top: boolean; bottom: boolean } }>`
  display: flex;
  flex-direction: column;
  max-height: 320px;
  padding-right: 4px;
  overflow-y: auto;
  ${({ overflows: { top } }) => (top ? `border-top: 3px solid #00000022` : "border-top: 3px solid transparent")};
  ${({ overflows: { bottom } }) =>
    bottom ? `border-bottom: 3px solid #00000022` : "border-bottom: 3px solid transparent"};
`;

const CommentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4px;
`;

const TitleContainer = styled.div`
  margin-bottom: 4px;
  margin-top: 4px;
  font-size: 14px;
  color: ${defaultGrey};
`;

const CommentContainer = styled.div<{ show?: boolean }>`
  display: flex;
  flex-direction: column;
  background: ${commentTextBackgroundBlue};
  font-size: 10px;
  margin-bottom: 10px;
  color: ${defaultGrey};
  padding: 4px 10px;
  overflow: hidden;
`;

const FreezeContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${commentTextBackgroundBlue};
  font-size: 12px;
  padding: 10px;
  color: ${defaultGrey};
`;

const FreezeContainerRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;
  justify-content: flex-start;
  align-items: baseline;
`;

const SubmitCommentRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;
  justify-content: flex-start;
  align-items: center;
`;

const FreezeLabel = styled.div`
  min-width: 64px;
`;

const CommentLabel = styled.div`
  display: flex;
  font-size: 12px;
  width: 48px;
  min-width: 48px;
`;

const CommentSubtitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 10px;
  font-weight: normal;
`;

const Commenter = styled.div`
  display: flex;
`;

const CommentDate = styled.div`
  display: flex;
`;

const CommentHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: bold;
  margin-bottom: 12px;
`;

const CommentTypeDesc = styled.div`
  display: flex;
  font-size: 14px;
`;

const CommentValue = styled.div`
  display: flex;
  font-size: 12px;
`;

const CommentTitle = styled.span`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
`;

const CommentText = styled.div`
  font-size: 12px;
  white-space: normal;
  font-weight: normal;
`;

const CommentFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 10px;
  white-space: normal;
  font-weight: normal;
  margin-top: 12px;
`;

const CommentActions = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 2;
  justify-content: flex-end;
`;

const FreezingCommentInput = styled.textarea`
  border: 0;
  width: 100%;
  font-size: 12px;
`;

const CommentInput = styled.textarea`
  border: 0;
  max-height: 100px;
  padding: 5px;
  font-size: 12px;
  flex-grow: 1;
`;

const ValueInput = styled(NumberInput)`
  border: 0;
  padding: 5px;
  font-size: 12px;
  flex-grow: 1;
`;

const CommentTypeSelect = styled.select`
  border: 0;
  padding: 5px;
  font-size: 12px;
  margin: 0;
  flex-grow: 1;
`;

const SaveCommentSpinner = styled(FullscreenSpinner)`
  div::after {
    content: none !important;
  }
`;

const TitleRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
