import React from "react";
import { useQuery } from "@apollo/client/react/hooks";

import { NodeCommentPackagesResult } from "./types";
import { GET_NODE_COMMENT_PACKAGES } from "./queries";
import Comments from "./Comments";
import { NodeId } from "../../../../common/types";

interface Props {
  nodeId: NodeId;
}

function TotalComments(props: Props): React.ReactElement {
  const { nodeId } = props;

  const { data, loading, error } = useQuery<{ nodeCommentPackages: NodeCommentPackagesResult }>(
    GET_NODE_COMMENT_PACKAGES,
    {
      variables: { nodeId },
    }
  );

  const projectId = data && data.nodeCommentPackages.projectId;
  const description = (data && data.nodeCommentPackages.nodeDescription) || "";
  const isTotal = data && data.nodeCommentPackages.isTotal;
  const totalPackages = data && (isTotal ? data.nodeCommentPackages.totalPackages || [] : undefined);
  const subtotalPackages = data && (!isTotal ? data.nodeCommentPackages.subtotalPackages || [] : undefined);

  return (
    <Comments
      projectId={projectId}
      nodeId={nodeId}
      totalPackages={totalPackages}
      subtotalPackages={subtotalPackages}
      totalNodeName={isTotal ? description : null}
      subtotalNodeName={!isTotal ? description : null}
      isMainProject={true}
      isTotal={isTotal === undefined ? false : isTotal}
      loading={loading}
      error={error}
    />
  );
}

export default TotalComments;
