import styled from "styled-components";
import { disabledGrey, settingGreen } from "../../common/colors";
import React from "react";
import { faSpinner, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonContainer, IconButton } from "../../common/components";

export interface FullscreenWaitingDialogProps {
  text: string;
  enableClose: boolean;
  onClose: () => void;
  color?: string;
}

export default function FullscreenWaitingDialog(props: FullscreenWaitingDialogProps) {
  const { text, enableClose, onClose, color } = props;
  return (
    <Wrapper>
      <WaitingDialog width={text.length <= 20 ? "180px" : `70%`}>
        <WaitingInfo color={color ? color : enableClose ? settingGreen : disabledGrey}>{text}</WaitingInfo>
        <ButtonContainer>
          <IconButton onClick={() => onClose()} disabled={!enableClose}>
            {enableClose ? (
              <FontAwesomeIcon icon={faTimes} size="1x" color={color ? color : settingGreen} />
            ) : (
              <FontAwesomeIcon icon={faSpinner} size="1x" color={color ? color : disabledGrey} spin={true} />
            )}
          </IconButton>
        </ButtonContainer>
      </WaitingDialog>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0px;
  bottom: 0px;
  width: 100%;
  background: ${disabledGrey};
  z-index: 1200;
  left: 0;
`;

const WaitingDialog = styled.div<{ width: string }>`
  position: relative;
  width: ${({ width }) => width};
  height: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  font-size: 18px;
  font-weight: bold;
  padding: 10px;
`;

const WaitingInfo = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: ${({ color }) => color};
`;
