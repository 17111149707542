import React, { useMemo } from "react";
import { ProjectRelation, ProjectRelationTypeItem } from "../../../../../common/types";
import { sortBy } from "lodash";
import styled from "styled-components";
import { valmetGreyBorder } from "../../../../../common/colors";

export interface ViewDetailsProps {
  relations: ProjectRelation[];
  relationTypeItems: ProjectRelationTypeItem[];
  comment?: string | null;
}

function ViewDetails(props: ViewDetailsProps): React.ReactElement {
  const { relations, relationTypeItems, comment } = props;
  const relationTypeItem = (relationType: string) => relationTypeItems.find(item => item.relationType === relationType);

  const sortedRelations = useMemo(() => sortBy(relations, ["relationType"]), [relations]);

  return (
    <>
      {comment && <TitleItem>{comment}</TitleItem>}
      {sortedRelations.length > 0 &&
        sortedRelations.map((relation, i) => {
          const relTypeItem = relationTypeItem(relation.relationType);
          return (
            <RelationItem
              relation={relation}
              relTypeItem={relTypeItem}
              typeName={relTypeItem ? relTypeItem.displayName : relation.relationType}
              showHeader={!sortedRelations[i - 1] || sortedRelations[i - 1].relationType !== relation.relationType}
              key={relation.company + i}
            />
          );
        })}
      {!relations.length && <LoadingContainer>No relations set.</LoadingContainer>}
    </>
  );
}

export default ViewDetails;

type RelationItemProps = {
  relation: ProjectRelation;
  relTypeItem: ProjectRelationTypeItem | undefined;
  typeName: string;
  showHeader: boolean;
};

const RelationItem: React.FC<RelationItemProps> = (props: RelationItemProps) => {
  const { relation, relTypeItem, typeName, showHeader } = props;

  return (
    <RelationSection border={showHeader}>
      <StatusItem>
        {showHeader && <TitleItem>Status:</TitleItem>}
        <StatusText title={relation.erpLinkStatus !== null ? relation.erpLinkStatus : undefined}>
          {relation.erpLinkStatus !== null ? relation.erpLinkStatus : "-"}
        </StatusText>
      </StatusItem>
      <InformationItem>
        {showHeader && <TitleItem>Type:</TitleItem>}
        <DataItem>{typeName}</DataItem>
      </InformationItem>
      <InformationItem>
        {showHeader && <TitleItem>Company:</TitleItem>}
        <DataItem>{relation.company}</DataItem>
      </InformationItem>
      {relTypeItem &&
        relTypeItem.relatingKeys.map((relkey, index) => {
          const value = relation.relatingKeys[index];
          return (
            <InformationItem key={`relkey-${index}`}>
              {showHeader && <TitleItem>{relkey.desc}:</TitleItem>}
              <DataItem>{value}</DataItem>
            </InformationItem>
          );
        })}
    </RelationSection>
  );
};

const RelationSection = styled.div<{ border?: boolean }>`
  display: grid;
  grid-template-columns: 4fr 9fr 5fr 6fr 6fr 5fr;
  ${({ border }) => `border-top: ${border ? `1px solid ${valmetGreyBorder}` : "0"}`}
  ${({ border }) => `padding-top: ${border ? `10px` : "0"}`}
`;

const InformationItem = styled.div<{ hidden?: boolean }>`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  ${({ hidden }) => `visibility: ${hidden ? "hidden" : "visible"}`}
`;

const StatusItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  font-size: 11px;
  overflow: hidden;
`;
const StatusText = styled.div`
  font-size: 11px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 70px;
`;

const DataItem = styled.div<{ emphasize?: boolean }>`
  font-weight: bold;
  font-size: ${({ emphasize }) => (emphasize ? "22px" : "14px")};
`;
const TitleItem = styled.div`
  font-size: 14px;
  margin-bottom: 10px;
`;

const LoadingContainer = styled.div`
  padding-bottom: 20px;
`;
