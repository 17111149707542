import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { ApolloError } from "@apollo/client";
import { faChevronDown, faChevronRight, faEdit, faLock, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { entries, groupBy } from "lodash";

import { NodeId, ProjectId } from "../../../../common/types";
import { editBlue, settingGreen } from "../../../../common/colors";
import {
  currentPeriod,
  parsePeriodOrFail,
  Period,
  periodAsInt,
  periodEquals,
  periodSortOrder,
  periodToString,
  prevPeriod,
} from "../../../../common/period";
import ErrorBox from "../../../ErrorBox";
import SpinnerBox from "../../../SpinnerBox";
import {
  ActionButton,
  CollapseButton,
  GroupContainer,
  GroupContentContainer,
  GroupedItemContainer,
  GroupHeader,
  TabButton,
  TabButtonsContainer,
  TabContainer,
} from "../../../../common/components";
import {
  CommentPackage,
  CommentPackageType,
  EditableCommentPackage,
  ExistingCommentPackage,
  NewCommentPackage,
} from "./types";
import { useItemToggle } from "./utils";
import { CommentPackageInfo, Crown } from "./shared-components";
import CommentPackageEdit from "./CommentPackageEdit";
import CommentPackageView from "./CommentPackageView";
import LoadingView from "../../../LoadingView";

interface CommentsProps {
  projectId?: ProjectId;
  nodeId?: NodeId;
  projectPackages?: CommentPackage[];
  totalPackages?: CommentPackage[];
  subtotalPackages?: CommentPackage[];
  totalNodeName: string | null;
  subtotalNodeName: string | null;
  isTotal: boolean;
  isMainProject: boolean;
  loading: boolean;
  error?: ApolloError;
}

enum Tab {
  Project,
  Total,
  Subtotal,
}

const currentYear = (): string => new Date().getFullYear().toString();

interface CommentPackageItemProps {
  commentPackage: EditableCommentPackage;
  setEditComment: (commentPackage: EditableCommentPackage) => void;
  setViewComment: (commentPackage: EditableCommentPackage) => void;
}

function CommentPackageItem(props: CommentPackageItemProps) {
  const { commentPackage, setEditComment, setViewComment } = props;
  switch (commentPackage.type) {
    case "NewComment": {
      const { period, commentType } = commentPackage;
      return (
        <GroupedItemContainer>
          <GroupedCommentItem>
            <CommentPackageTitle>{periodToString(period)}</CommentPackageTitle>
            <CommentPackageActions>
              <ActionButton color={editBlue} onClick={() => setEditComment(commentPackage)}>
                <FontAwesomeIcon icon={faPlus} />
                Create {commentType} financial comment package
              </ActionButton>
            </CommentPackageActions>
          </GroupedCommentItem>
        </GroupedItemContainer>
      );
    }
    case "EditablePackage": {
      const { period, commentType, modifiedBy, modified, published } = commentPackage;
      return (
        <GroupedItemContainer>
          <GroupedCommentItem>
            <CommentPackageTitle>
              <CommentPackagePeriod>{periodToString(period)}</CommentPackagePeriod>
              <CommentPackageDesc>
                <ActionButton onClick={() => setViewComment(commentPackage)}>
                  {commentType} financial comment
                </ActionButton>
              </CommentPackageDesc>
            </CommentPackageTitle>
            <CommentPackageStatusContainer>
              <CommentPackageInfo modifiedBy={modifiedBy} modified={modified} published={published} />
              <CommentPackageActions>
                <ActionButton color={settingGreen} onClick={() => setEditComment(commentPackage)}>
                  <FontAwesomeIcon icon={faEdit} />
                </ActionButton>
              </CommentPackageActions>
            </CommentPackageStatusContainer>
          </GroupedCommentItem>
        </GroupedItemContainer>
      );
    }
    case "LockedPackage": {
      const { period, commentType, modifiedBy, modified, published } = commentPackage;
      return (
        <GroupedItemContainer>
          <GroupedCommentItem>
            <CommentPackageTitle>
              <CommentPackagePeriod>{periodToString(period)}</CommentPackagePeriod>
              <CommentPackageDesc>
                <ActionButton onClick={() => setViewComment(commentPackage)}>
                  {commentType} financial comment
                </ActionButton>
              </CommentPackageDesc>
            </CommentPackageTitle>
            <CommentPackageStatusContainer>
              <CommentPackageInfo modifiedBy={modifiedBy} modified={modified} published={published} />
              <CommentPackageActions>
                <ActionButton disabled={true}>
                  <FontAwesomeIcon icon={faLock} />
                </ActionButton>
              </CommentPackageActions>
            </CommentPackageStatusContainer>
          </GroupedCommentItem>
        </GroupedItemContainer>
      );
    }
  }
}

interface CommentsTabProps {
  tab: Tab;
  groupedByYear: { [key: string]: EditableCommentPackage[] };
  editCommentPackage: (p: EditableCommentPackage) => void;
  viewCommentPackage: (p: EditableCommentPackage) => void;
}

function CommentsTab(props: CommentsTabProps) {
  const { groupedByYear, editCommentPackage, viewCommentPackage } = props;

  const [isGroupOpen, , toggleGroup] = useItemToggle([currentYear()]);

  return (
    <TabContainer show={true}>
      {entries(groupedByYear)
        .sort((a, b) => -a[0].localeCompare(b[0]))
        .map(([year, packages]) => {
          return (
            <GroupContainer key={year}>
              <GroupHeader onClick={() => toggleGroup(year)}>
                <CollapseButton fontSize={"16px"}>
                  <FontAwesomeIcon
                    icon={isGroupOpen(year) ? faChevronDown : faChevronRight}
                    size="1x"
                    color={settingGreen}
                  />
                </CollapseButton>
                {year}
              </GroupHeader>
              {isGroupOpen(year) && (
                <GroupContentContainer>
                  {packages.map(pak => (
                    <CommentPackageItem
                      key={pak.commentType + "-" + periodToString(pak.period)}
                      commentPackage={pak}
                      setEditComment={editCommentPackage}
                      setViewComment={viewCommentPackage}
                    />
                  ))}
                </GroupContentContainer>
              )}
            </GroupContainer>
          );
        })}
    </TabContainer>
  );
}

const fromTabToCommentType = (tab: Tab): CommentPackageType => {
  switch (tab) {
    case Tab.Project:
      return CommentPackageType.Project;
    case Tab.Total:
      return CommentPackageType.Total;
    case Tab.Subtotal:
      return CommentPackageType.Subtotal;
  }
};

const selectPackages = (
  tab: Tab,
  projectPackages: CommentPackage[] | undefined,
  totalPackages: CommentPackage[] | undefined,
  subtotalPackages: CommentPackage[] | undefined
): CommentPackage[] | undefined => {
  switch (tab) {
    case Tab.Project:
      return projectPackages;
    case Tab.Total:
      return totalPackages;
    case Tab.Subtotal:
      return subtotalPackages;
  }
};

function Comments(props: CommentsProps) {
  const {
    projectId,
    nodeId,
    projectPackages,
    totalPackages,
    subtotalPackages,
    totalNodeName,
    subtotalNodeName,
    isMainProject,
    isTotal,
    loading,
    error,
  } = props;

  const [tab, setTab] = useState(nodeId !== undefined ? (isTotal ? Tab.Total : Tab.Subtotal) : Tab.Project);
  const [viewPackage, setViewPackage] = useState<EditableCommentPackage | null>(null);
  const [editPackage, setEditPackage] = useState<EditableCommentPackage | null>(null);

  const editablePackages = useMemo(() => {
    const ongoingPeriod = currentPeriod();
    const previousPeriod = prevPeriod(ongoingPeriod);
    const isEditablePeriod = (p: Period): boolean => periodEquals(p, ongoingPeriod) || periodEquals(p, previousPeriod);
    const packages: EditableCommentPackage[] = (
      selectPackages(tab, projectPackages, totalPackages, subtotalPackages) || []
    )
      .map(pak => {
        const period = parsePeriodOrFail(pak.period);
        const existing: ExistingCommentPackage = {
          type: isEditablePeriod(period) ? "EditablePackage" : "LockedPackage",
          ...pak,
          period,
        };
        return existing;
      })
      // Sort should happen after map, as otherwise it would be editing the original array in place.
      // Map creates a copy, which is safe to sort in-place.
      .sort((a, b) => periodSortOrder(a.period, b.period));
    return packages;
  }, [tab, projectPackages, totalPackages, subtotalPackages]);

  const groupedByYear = useMemo(() => {
    const ongoingPeriod = currentPeriod();
    const previousPeriod = prevPeriod(ongoingPeriod);
    const currentPackage = editablePackages.find(x => periodEquals(x.period, ongoingPeriod));
    const previousPackage = editablePackages.find(x => periodEquals(x.period, previousPeriod));
    const newPackage = (period: Period): NewCommentPackage => ({
      type: "NewComment",
      period,
      commentType: fromTabToCommentType(tab),
      content: "",
    });
    const newPackages: EditableCommentPackage[] = [];
    if ((isMainProject && tab === Tab.Total) || (isMainProject && tab === Tab.Subtotal) || tab === Tab.Project) {
      if (!currentPackage) newPackages.push(newPackage(ongoingPeriod));
      if (!previousPackage) newPackages.push(newPackage(previousPeriod));
    }
    const currentPlusExisting: EditableCommentPackage[] = newPackages.concat(editablePackages);
    const grouped = groupBy(currentPlusExisting, p => p.period.year);
    for (const key in grouped) {
      if (!Object.prototype.hasOwnProperty.call(grouped, key)) continue;
      grouped[key].sort((a, b) => periodAsInt(b.period) - periodAsInt(a.period));
    }
    return grouped;
  }, [editablePackages, tab]);

  useEffect(() => {
    if (editPackage && editablePackages) {
      const newEditablePackage = editablePackages.find(p => periodEquals(p.period, editPackage.period));
      if (newEditablePackage) setEditPackage(newEditablePackage);
    }
  }, [editablePackages, editPackage, setEditPackage]);

  return (
    <Container>
      {editPackage !== null ? (
        projectId ? (
          <CommentPackageEdit
            projectId={projectId}
            nodeId={nodeId}
            commentPackage={editPackage}
            onCancel={() => setEditPackage(null)}
          />
        ) : (
          <LoadingView />
        )
      ) : (
        <>
          {viewPackage && (
            <CommentPackageView
              commentPackage={viewPackage}
              close={() => setViewPackage(null)}
              edit={() => {
                setEditPackage(viewPackage);
                setViewPackage(null);
              }}
            />
          )}
          <TabButtonsContainer>
            {projectPackages !== undefined && (
              <TabButton selected={tab === Tab.Project} onClick={() => setTab(Tab.Project)}>
                Project Financial Comments
              </TabButton>
            )}
            {totalPackages !== undefined && !(totalPackages.length === 0 && !isMainProject) && (
              <TabButton selected={tab === Tab.Total} onClick={() => setTab(Tab.Total)}>
                <Crown />
                {totalNodeName || "Total"} Financial Comments
              </TabButton>
            )}
            {subtotalPackages !== undefined && !(subtotalPackages.length === 0 && !isMainProject) && (
              <TabButton selected={tab === Tab.Subtotal} onClick={() => setTab(Tab.Subtotal)}>
                <Crown />
                {subtotalNodeName || "Subtotal"} Financial Comments
              </TabButton>
            )}
          </TabButtonsContainer>
          <CommentsContainer>
            {loading ? (
              <SpinnerBox />
            ) : error ? (
              <ErrorBox caption="Could not load financial comments" apolloError={error} />
            ) : (
              <CommentsTab
                tab={tab}
                groupedByYear={groupedByYear}
                editCommentPackage={setEditPackage}
                viewCommentPackage={setViewPackage}
              />
            )}
          </CommentsContainer>
        </>
      )}
    </Container>
  );
}

export default Comments;

const Container = styled.div`
  @media (min-width: 1250px) {
    width: 60vw;
  }
  @media (max-width: 1250px) {
    width: 90vw;
  }
  transition: width 100ms linear;
`;

const CommentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
`;

const CommentPackageTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`;
const CommentPackagePeriod = styled.span`
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  font-size: 16px;
`;
const CommentPackageDesc = styled.span`
  display: flex;
  flex-direction: row;
  font-size: 14px;
`;

const CommentPackageStatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const CommentPackageActions = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 2em;
`;

const GroupedCommentItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
