import React, { useCallback, useEffect, useState } from "react";
import { Action } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { useMutation, useQuery } from "@apollo/client/react/hooks";

import Section from "../Section";
import {
  AppState,
  ProjectId,
  ProjectRelation,
  ProjectRelationInput,
  ProjectRelationSaveResult,
  ProjectRelationSectionInput,
  ProjectRelationTypeItem,
} from "../../../../../common/types";
import { setProjectRelations } from "../../../../../actions/projectActions";
import { BasicDataSectionType, getEmptyProjectRelationSectionInput } from "../../../../../common/constants";
import { SAVE_PROJECT_RELATIONS } from "./queries";
import { pollForBasicDataSaveReady } from "../../queries";
import ViewDetails from "./ViewDetails";
import EditDetails from "./EditDetails";
import styled from "styled-components";
import { defaultGrey } from "../../../../../common/colors";
import { GET_RELATION_TYPES } from "./queries";

const mapStateToProps = (state: AppState) => {
  return {
    projectRelationsInput: state.projectState.projectInput
      ? state.projectState.projectInput.projectRelations
      : getEmptyProjectRelationSectionInput(),
    inputErrors: state.projectState.projectErrors.relations,
  };
};
const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, void, Action>) => {
  return {
    setProjectRelationsInput: (input: ProjectRelationSectionInput) => dispatch(setProjectRelations(input)),
  };
};

interface RelationsSectionProps {
  projectId: ProjectId;
  sectionDetails: ProjectRelation[];
  sectionEditable: boolean;
  editSectionType: BasicDataSectionType | undefined;
  setEditSectionType: (type: BasicDataSectionType | undefined) => void;
  refetchDetails: () => void;
}

const TYPE: BasicDataSectionType = BasicDataSectionType.Relations;

function RelationsSection(
  props: RelationsSectionProps & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
) {
  const {
    projectId,
    sectionDetails,
    sectionEditable,
    editSectionType,
    setEditSectionType,
    refetchDetails,
    projectRelationsInput,
    inputErrors,
    setProjectRelationsInput,
  } = props;
  const [editMode, setEditMode] = useState(false);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  // Once input is changed, clear error messages.
  useEffect(() => {
    if (errorMessages.length > 0) {
      setErrorMessages([]);
    }
  }, [projectRelationsInput]);

  const [saveMutation, { loading: saving }] = useMutation<{
    saveProjectRelations: ProjectRelationSaveResult;
  }>(SAVE_PROJECT_RELATIONS);

  const { pollForReady, ready, loading: polling, error: pollingError } = pollForBasicDataSaveReady();
  useEffect(() => {
    if (ready && !polling && !pollingError) {
      setEditMode(false);
      setEditSectionType(undefined);
      setProjectRelationsInput(getEmptyProjectRelationSectionInput());
      refetchDetails();
    } else if (!polling && pollingError) {
      setErrorMessages(errorMessages.concat([pollingError]));
    }
  }, [ready, polling, pollingError]);

  const save = useCallback(
    (input: ProjectRelationInput[]) => {
      saveMutation({
        variables: {
          projectId: projectId,
          relations: input,
        },
      })
        .then(({ data }) => {
          if (data && data.saveProjectRelations && data.saveProjectRelations.applicationModifiedDateTime) {
            pollForReady(projectId, data.saveProjectRelations.applicationModifiedDateTime);
          } else if (data && data.saveProjectRelations.errors) {
            setErrorMessages(errorMessages.concat(data.saveProjectRelations.errors));
          }
        })
        .catch(apolloError => {
          setErrorMessages(errorMessages.concat([apolloError.message]));
        });
    },
    [saveMutation]
  );

  const disabled = editSectionType !== undefined && editSectionType !== TYPE;

  type ProjectRelationTypesResult = { relationTypes: ProjectRelationTypeItem[] };
  const { data: relationTypeItemsResult } = useQuery<ProjectRelationTypesResult>(GET_RELATION_TYPES);
  const relationTypeItems = (relationTypeItemsResult && relationTypeItemsResult.relationTypes) || [];

  return (
    <Section
      header="ERP Links"
      disabled={disabled}
      editMode={editMode}
      editable={sectionEditable && sectionDetails !== undefined}
      saving={saving || polling}
      saveEnabled={!projectRelationsInput.pristine && !inputErrors}
      errors={errorMessages.length > 0 ? errorMessages : undefined}
      onEditClicked={() => {
        setEditSectionType(TYPE);
        setEditMode(true);
      }}
      onCancelEdit={() => {
        setEditSectionType(undefined);
        setEditMode(false);
        setProjectRelationsInput(getEmptyProjectRelationSectionInput());
      }}
      onSaveClicked={() => {
        save(projectRelationsInput.input);
      }}
    >
      <Container>
        {editMode ? (
          <EditDetails projectId={projectId} relations={sectionDetails} relationTypeItems={relationTypeItems} />
        ) : (
          <ViewDetails relations={sectionDetails} relationTypeItems={relationTypeItems} />
        )}
      </Container>
    </Section>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(RelationsSection);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-bottom: 0px;
  color: ${defaultGrey};
  > div:first-child {
    border-top: 0;
    padding-top: 0;
  }
  > div:last-child {
    margin-bottom: 10px;
  }
`;
