import { gql } from "@apollo/client";
import { ProjectFiltersId } from "../../../../common/types";

const ProjectFiltersFields = gql`
  fragment ProjectFiltersFields on ProjectFilters {
    technicalType
    projectPhase
    projectType
    projectStatus
    businessGroup
    businessType
    person
    project
    oblPeriod
    projectPeriod
    customer
    deliveryOrg
    endDestCountry
    financingMethod
    legalEntity
    extLegalEntity
    estSalesPrice {
      min
      max
    }
  }
`;

const UpdateProjectFiltersResultFields = gql`
  fragment UpdateProjectFiltersResultFields on UpdateProjectFiltersResult {
    id
    successful
    error
  }
`;

const CreateProjectFiltersResultFields = gql`
  fragment CreateProjectFiltersResultFields on CreateProjectFiltersResult {
    id
    successful
    error
  }
`;

export const LIST_PROJECT_FILTERS = gql`
  query ListProjectFilters {
    listProjectFilters {
      selectedId
      lists {
        id
        name
        filters {
          ...ProjectFiltersFields
        }
      }
    }
  }
  ${ProjectFiltersFields}
`;

export const CREATE_PROJECT_FILTERS = gql`
   mutation CreateProjectFilters(
    $name: String!
    $filters: ProjectFiltersInput!
  ) {
    createProjectFilters(name: $name, filters: $filters) {
      ...CreateProjectFiltersResultFields
    }
  }
  ${CreateProjectFiltersResultFields}
`;

export const UPDATE_PROJECT_FILTERS_NAME = gql`
   mutation UpdateProjectFiltersName(
    $projectFiltersId: ProjectFiltersId!
    $name: String!
  ) {
    updateProjectFiltersName(projectFiltersId: $projectFiltersId, name: $name) {
      ...UpdateProjectFiltersResultFields
    }
  }
  ${UpdateProjectFiltersResultFields}
`;

export const SELECT_PROJECT_FILTERS = gql`
   mutation SelectProjectFilters($projectFiltersId: ProjectFiltersId!) {
    selectProjectFilters(projectFiltersId: $projectFiltersId) {
      ...UpdateProjectFiltersResultFields
    }
  }
  ${UpdateProjectFiltersResultFields}
`;

export const DELETE_PROJECT_FILTERS = gql`
  mutation DeleteProjectFilters($projectFiltersId: ProjectFiltersId!) {
    deleteProjectFilters(projectFiltersId: $projectFiltersId) {
      ...UpdateProjectFiltersResultFields
    }
  }
  ${UpdateProjectFiltersResultFields}
`;

export interface CreateProjectFiltersResult {
  id: ProjectFiltersId | null;
  successful: boolean;
  error: string | null;
}

export interface UpdateProjectFiltersResult {
  id: ProjectFiltersId;
  successful: boolean;
  error: string | null;
}
