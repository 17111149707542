import { ProjectHeaderAction } from "../actions/projectHeaderActions";
import {
  EntityTypeId,
  HierarchyItemEnum,
  NodeId,
  ProjectId,
  ProjectPhaseId,
  ProjectStatusId,
  ProjectVirtualType,
} from "../common/types";

interface ProjectInfo {
  type: HierarchyItemEnum.Project;
  projectId: ProjectId;
  description: string;
  technicalTypeId: EntityTypeId;
  projectPhaseId?: ProjectPhaseId;
  projectStatusId?: ProjectStatusId;
  projectVirtualType: ProjectVirtualType;
  legalEntityCurrency?: string;
}

interface NodeInfo {
  type: HierarchyItemEnum.Node;
  nodeId: NodeId;
  description: string;
  mainProjectId: ProjectId | null;
}

type ItemInfo = ProjectInfo | NodeInfo;

export interface ProjectHeaderState {
  item?: ItemInfo;
}

export const initialState: ProjectHeaderState = {
  item: undefined,
};

const reducer = (
  state: Readonly<ProjectHeaderState> = initialState,
  action: ProjectHeaderAction
): ProjectHeaderState => {
  switch (action.type) {
    case "SetProjectHeaderInfo": {
      const {
        projectId,
        projectTechnicalTypeId,
        description,
        projectPhaseId,
        projectStatusId,
        projectVirtualType,
        legalEntityCurrency,
      } = action;
      return {
        ...state,
        item: {
          type: HierarchyItemEnum.Project,
          projectId,
          description,
          technicalTypeId: projectTechnicalTypeId,
          projectPhaseId,
          projectStatusId,
          projectVirtualType,
          legalEntityCurrency: legalEntityCurrency,
        }, //as ProjectInfo
      };
    }
    case "SetNodeHeaderInfo": {
      const { nodeId, description, mainProjectId } = action;
      return {
        ...state,
        item: {
          type: HierarchyItemEnum.Node,
          nodeId,
          description,
          mainProjectId,
        }, //as NodeInfo
      };
    }
  }
  return state;
};

export default reducer;
