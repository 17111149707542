export enum DetailsColumnType {
  General,
  NumericString,
  Number,
  Date,
  Link,
}

type DetailsColumnNonLinkType =
  | DetailsColumnType.General
  | DetailsColumnType.NumericString
  | DetailsColumnType.Number
  | DetailsColumnType.Date;

const justifyToRightColumnType = (type: DetailsColumnType): boolean => {
  switch (type) {
    case DetailsColumnType.General:
      return false;
    case DetailsColumnType.NumericString:
    case DetailsColumnType.Number:
    case DetailsColumnType.Date:
      return true;
  }
  return false;
};

export const justifyToRight = <T>(column: DetailsTableColumn<T>): boolean => {
  switch (column.type) {
    case DetailsColumnType.Link:
      return justifyToRightColumnType(column.actualType);
    default:
      return justifyToRightColumnType(column.type);
  }
};

export type DetailsTableGeneralColumn<T> = {
  id: keyof T;
  description: string;
  tooltip?: string;
  type: DetailsColumnType.General;
};

export type DetailsTableNumericColumn<T> = {
  id: keyof T;
  description: string;
  tooltip?: string;
  type: DetailsColumnType.NumericString;
};

export type DetailsTableNumberColumn<T> = {
  id: keyof T;
  description: string;
  tooltip?: string;
  type: DetailsColumnType.Number;
  decimals?: number;
};

export type DetailsTableDateColumn<T> = {
  id: keyof T;
  description: string;
  tooltip?: string;
  type: DetailsColumnType.Date;
};

export type DetailsTableLinkColumn<T> = {
  id: keyof T;
  description: string;
  tooltip?: string;
  type: DetailsColumnType.Link;
  actualType: DetailsColumnNonLinkType;
  linkId: keyof T;
};

export type DetailsTableColumn<T> =
  | DetailsTableGeneralColumn<T>
  | DetailsTableNumericColumn<T>
  | DetailsTableNumberColumn<T>
  | DetailsTableDateColumn<T>
  | DetailsTableLinkColumn<T>;

export const dateAsLink = <T>(
  id: keyof T,
  description: string,
  tooltip: string | undefined,
  linkId: keyof T
): DetailsTableLinkColumn<T> => ({
  id,
  description,
  tooltip,
  type: DetailsColumnType.Link,
  actualType: DetailsColumnType.Date,
  linkId,
});
