import { ActionButton, ButtonContainer } from "../../common/components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleRight, faExclamationCircle, faSave, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { cancelRed, disabledGrey, settingGreen } from "../../common/colors";
import React from "react";
import { HeaderActions, HeaderActionsRefs } from "../sites/Project/ProjectHeaderActions";
import FullscreenSpinner from "../FullscreenSpinner";

export interface CreateProjectActionsHeaderProps {
  hasErrors: boolean;
  saving: boolean;
  readyToSave: boolean;
  finalTab?: boolean;
  onClickContinue: () => void;
  onClickCancel: () => void;
}

function CreateProjectActionsHeader(props: CreateProjectActionsHeaderProps) {
  const { hasErrors, saving, readyToSave, finalTab, onClickContinue, onClickCancel } = props;
  return (
    <HeaderActions refName={HeaderActionsRefs.createProjectActionsRef}>
      <ButtonContainer disabledSave={hasErrors || !readyToSave} buttonLoading={saving}>
        <ActionButton disabled={hasErrors || saving || !readyToSave} onClick={onClickContinue}>
          {saving ? "Saving..." : finalTab ? "Save project" : "Continue"}
          {hasErrors ? (
            <FontAwesomeIcon
              icon={finalTab ? faSave : faExclamationCircle}
              size="1x"
              color={cancelRed}
              title={"There are errors in the project data input."}
            />
          ) : (
            <FontAwesomeIcon
              icon={saving ? faSpinner : faChevronCircleRight}
              size="1x"
              spin={saving}
              color={hasErrors || saving || !readyToSave ? disabledGrey : settingGreen}
            />
          )}
        </ActionButton>
        <ActionButton onClick={onClickCancel}>Cancel</ActionButton>
      </ButtonContainer>
      {saving && <FullscreenSpinner text="Saving..." />}
    </HeaderActions>
  );
}

export default CreateProjectActionsHeader;
