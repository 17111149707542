import React from "react";
import { connect } from "react-redux";
import { Route, RouteProps, RouteComponentProps } from "react-router-dom";
import { AppState } from "../../common/types";

interface AuthProps {
  authorized: boolean;
}

type PrivateRouteProps = RouteProps & AuthProps;
type EmptyObject = Record<string, never>;

const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component, ...rest }) => {
  if (!Component) {
    return null;
  }
  return <Route {...rest} render={(props: RouteComponentProps<EmptyObject>) => <Component {...props} />} />;
};

const mapStateToProps = (state: AppState): AuthProps => {
  return {
    authorized: state.authState.authenticated
  };
};

export default connect(mapStateToProps)(PrivateRoute);
