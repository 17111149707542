import { gql } from "@apollo/client";
import { useLazyQuery } from "@apollo/client/react/hooks";
import { useCallback, useState } from "react";
import { PollReadyResult } from "../../../common/types";

export const HierarchyFields = gql`
  fragment HierarchyFields on HierarchyItem {
    id
    description
    active
    itemType
    mainProjectId
    projectTechnicalTypeId
    projectTypeId
    hiddenInHierarchyView
    relatingProjectId
    adjustmentRateInfo {
      currencyPairs {
        from
        to
      }
      editable
    }
  }
`;

export const GET_HEADER_INFO = gql`
  query GetHeaderInfo($id: HierarchyItemId!, $itemType: HierarchyItemEnum!) {
    headerInfo(id: $id, itemType: $itemType) {
      id
      description
      itemType
      projectTechnicalTypeId
      projectVirtualType
      mainProjectId
      hierarchy {
        ...HierarchyFields
        childItems {
          ...HierarchyFields
          childItems {
            ...HierarchyFields
            childItems {
              ...HierarchyFields
              childItems {
                ...HierarchyFields
                childItems {
                  ...HierarchyFields
                  childItems {
                    ...HierarchyFields
                    childItems {
                      ...HierarchyFields
                      childItems {
                        ...HierarchyFields
                        childItems {
                          ...HierarchyFields
                          childItems {
                            ...HierarchyFields
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      projectPhaseId
      projectStatusId
      legalEntityCurrency
    }
  }
  ${HierarchyFields}
`;

export const GET_PROJECT_NOTIFICATIONS = gql`
  query GetProjectNotifications($projectId: ProjectId!) {
    projectNotifications(projectId: $projectId) {
      notifications {
        notificationField
        modified
        message
        userCanDismiss
      }
    }
  }
`;

export const DISMISS_PROJECT_NOTIFICATION = gql`
  mutation DismissProjectNotification($projectId: ProjectId!, $notificationField: NotificationField!) {
    dismissProjectNotification(projectId: $projectId, notificationField: $notificationField) {
      notifications {
        notificationField
        modified
        message
        userCanDismiss
      }
    }
  }
`;

export const SAVE_NEW_PROJECT = gql`
  mutation SaveNewProject(
    $projectId: ProjectId!
    $information: ProjectInformationInput!
    $dates: ProjectDatesInput!
    $currency: ProjectCurrencyInput!
    $roles: ProjectRolesInput!
    $tags: ProjectTagsInput!
    $integrations: ProjectIntegrationsInput!
    $reportingRelations: ProjectReportingRelationsInput!
  ) {
    saveNewProject(
      projectId: $projectId
      information: $information
      dates: $dates
      currency: $currency
      roles: $roles
      tags: $tags
      integrations: $integrations
      reportingRelations: $reportingRelations
    ) {
      projectId
      errors
      applicationModifiedDateTime
    }
  }
`;

export const GET_PROJECT_ACCESS = gql`
  query GetProjectAccess($projectId: ProjectId!) {
    projectAccess(projectId: $projectId) {
      access {
        isAdmin
        isEditor
        isProjectController
        isProjectManager
        hasCostsAccessOnly
        accessRights
      }
      error
    }
  }
`;

export const POLL_FOR_PROJECT_BASIC_DATA_SAVE_READY = gql`
  query PollProjectBasicDataSaveReady($projectId: ProjectId!, $applicationModifiedDateTime: ISOLocalDateTime!) {
    pollProjectBasicDataSaveReady(projectId: $projectId, applicationModifiedDateTime: $applicationModifiedDateTime) {
      ready
    }
  }
`;

export const PROJECT_RECOGNITION_EDITING_DISABLED = gql`
  query ProjectRecognitionsEditingDisabled($projectId: ProjectId!) {
    projectRecognitionEditingDisabled(projectId: $projectId) {
      message
      disabled
      recalculateMessage
      recalculateDisabled
    }
  }
`;

export const pollForBasicDataSaveReady = (
  pollInterval = 2000,
  retries = 120
): {
  pollForReady: (projectId: number, applicationModifiedDateTime: string) => void;
  ready: boolean;
  loading: boolean;
  error: string | undefined;
} => {
  const [error, setError] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [leftRetries, setLeftRetries] = useState<number>(0);

  const [queryForBasicDataSaveReady, { data, startPolling, stopPolling }] = useLazyQuery<{
    pollProjectBasicDataSaveReady: PollReadyResult;
  }>(POLL_FOR_PROJECT_BASIC_DATA_SAVE_READY, {
    notifyOnNetworkStatusChange: true,
    onCompleted(data) {
      if ((!data || !data.pollProjectBasicDataSaveReady.ready) && leftRetries > 0) {
        if (leftRetries === retries) {
          startPolling(pollInterval);
        }
        setLeftRetries(leftRetries - 1);
      } else {
        setLeftRetries(0);
        setLoading(false);
        stopPolling();
        if (!data || !data.pollProjectBasicDataSaveReady.ready) {
          setError("Timeout polling data saving state.");
        }
      }
    },
    onError(error) {
      if (leftRetries > 0) {
        if (leftRetries === retries) {
          startPolling(pollInterval);
        }
        setLeftRetries(leftRetries - 1);
      } else {
        setLeftRetries(0);
        setLoading(false);
        stopPolling();
        setError(error.message);
      }
    },
  });

  const pollForReady = useCallback(
    (projectId: number, applicationModifiedDateTime: string) => {
      setLoading(true);
      setLeftRetries(retries);
      queryForBasicDataSaveReady({ variables: { projectId, applicationModifiedDateTime } });
    },
    [queryForBasicDataSaveReady]
  );

  return {
    pollForReady: pollForReady,
    ready: data ? data.pollProjectBasicDataSaveReady.ready : false,
    loading: loading,
    error: error,
  };
};
