import React from "react";
import styled from "styled-components";
import { useQuery } from "@apollo/client/react/hooks";
import { filterGreenMoreTransparent, settingGreen } from "../../../../../common/colors";
import { CurrencyScenario, ProjectVirtualType } from "../../../../../common/types";
import { ProjectCostItemDetailsResult } from "../types";
import { GET_PROJECT_COST_DETAILS } from "./queries";
import CostItemDetailsTable from "./CostItemDetailsTable";
import CostItemDetailsPopup from "./CostItemDetailsPopup";

export enum Tabs {
  Labor,
  Production,
  Purchases,
  Other,
  Warehouse,
}

export interface CostItemDetailsProps {
  title: string;
  projectId: number;
  projectVirtualType: ProjectVirtualType;
  itemId: string;
  selectedCurrencyScenario: CurrencyScenario;
  currencyRate: number;
  popupOpen: boolean;
  closePopup: () => void;
}

function CostItemDetails(props: CostItemDetailsProps): React.ReactElement {
  const {
    projectId,
    projectVirtualType,
    title,
    itemId,
    selectedCurrencyScenario,
    currencyRate,
    popupOpen,
    closePopup,
  } = props;
  const { loading, data, error } = useQuery<ProjectCostItemDetailsResult>(GET_PROJECT_COST_DETAILS, {
    variables: {
      projectId,
      activityId: itemId,
      estimatesCurrencyId: selectedCurrencyScenario.estimatesCurrencyId,
      actualsCurrencyId: selectedCurrencyScenario.actualsCurrencyId,
      currencyRate: currencyRate,
    },
    fetchPolicy: "cache-and-network",
  });

  return (
    <Container>
      <TitleContainer>{title}</TitleContainer>
      {data && !loading && !error && !popupOpen && (
        <CostItemDetailsTable data={data} projectVirtualType={projectVirtualType} popupWindow={false} />
      )}
      {(loading || error) && (
        <LoadingContainer>{!error ? "Loading..." : "Error fetching cost item data."}</LoadingContainer>
      )}
      {popupOpen && (
        <CostItemDetailsPopup
          title={title}
          data={data}
          loading={loading}
          error={error}
          projectVirtualType={projectVirtualType}
          closePopup={closePopup}
        />
      )}
    </Container>
  );
}

export default CostItemDetails;

const Container = styled.div`
  margin: 20px 20px 0;
  display: flex;
  flex-direction: column;
`;

const TitleContainer = styled.div`
  display: flex;
  border: solid ${settingGreen};
  border-width: 1px 1px 0 1px;
  font-size: 11px;
  padding: 10px;
  background: ${filterGreenMoreTransparent};
  color: ${settingGreen};
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${settingGreen};
  justify-content: center;
  align-items: center;
`;
