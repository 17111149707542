import React, { useState, createRef, useEffect } from "react";
import styled from "styled-components";
import { FilterSetting, FilterValues, FilterOption, FilterId } from "../../../../common/types";
import FilterDropdown from "./FilterDropdown";
import { filter } from "lodash";
import { addMonths, subMonths } from "date-fns";
import ResetButton from "./ResetButton";

interface PeriodFilterProps {
  filterItem: FilterSetting;
  filterValues: FilterValues;
  onFilterSelected: (filter: FilterId, value: string | number | undefined) => void;
  onResetFilter: (id: FilterId) => void;
}

const getFilterDescription = (filter: FilterSetting, filterValues: FilterValues) => {
  let description = "";
  if (filterValues[filter.id]) {
    const filterValue = filterValues[filter.id] as string;
    description = filterValue;
  }
  return description;
};

const createFilterOptions = (input?: string) => {
  const currentDate = new Date();
  const stringifiedCurrentDate = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}`;
  const filters: FilterOption[] = [];
  if (!input || input.length === 0) {
    const timeRange = 6;
    let i;
    for (i = 6; i > 0; i--) {
      const newDate = subMonths(currentDate, i);
      const stringifiedDate = `${newDate.getFullYear()}-${newDate.getMonth() + 1}`;
      filters.push({ id: stringifiedDate, description: stringifiedDate });
    }
    filters.push({ id: stringifiedCurrentDate, description: stringifiedCurrentDate });
    for (i = 1; i <= timeRange; i++) {
      const newDate = addMonths(currentDate, i);
      const stringifiedDate = `${newDate.getFullYear()}-${newDate.getMonth() + 1}`;
      filters.push({ id: stringifiedDate, description: stringifiedDate });
    }
  } else {
    const yearInput = input.slice(0, 4);
    if (!isNaN(+yearInput)) {
      let createdYear = yearInput;
      createdYear = createdYear.concat(
        currentDate
          .getFullYear()
          .toString()
          .slice(yearInput.length, 4)
      );
      const timeRange = 12;
      let i;
      for (i = 1; i <= timeRange; i++) {
        const stringifiedDate = `${createdYear}-${i}`;
        filters.push({ id: stringifiedDate, description: stringifiedDate });
      }
    }
  }

  return input !== undefined ? filter(filters, filter => filter.description.includes(input)) : filters;
};

function PeriodFilter(props: PeriodFilterProps): React.ReactElement {
  const { filterItem, filterValues, onFilterSelected, onResetFilter } = props;
  const [input, setInput] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dropdown = createRef<any>();

  useEffect(() => {
    setInput(getFilterDescription(filterItem, filterValues));
  }, [filterValues, filterItem]);

  return (
    <FilterItem key={filterItem.id}>
      <FilterSelection extraPadding={true}>
        {filterItem.name}
        <FilterDropdown
          filter={filterItem.id}
          values={createFilterOptions()}
          onFilterSelected={onFilterSelected}
          selections={filterValues[filterItem.id]}
          singleSelection={true}
        />
      </FilterSelection>
      <FilterSelectionColumn>
        <FilterSelection>
          <ResetButton
            onButtonClick={() => onResetFilter(filterItem.id)}
            show={filterValues[filterItem.id] !== undefined}
          />
          <FilterInput
            type="text"
            placeholder="Search"
            value={input}
            onFocus={() => setInput("")}
            onBlur={() => {
              if (input.length === 0) {
                setInput(getFilterDescription(filterItem, filterValues));
              }
            }}
            onChange={event => {
              setInput(event.target.value);
              dropdown.current.instanceRef.open();
            }}
          />
        </FilterSelection>

        <FilterDropdown
          ref={dropdown}
          filter={filterItem.id}
          values={createFilterOptions(input)}
          onFilterSelected={onFilterSelected}
          selections={filterValues[filterItem.id]}
          disableIcon={true}
          singleSelection={true}
          onClose={() => {
            if (!filterValues[filterItem.id]) {
              setInput("");
            } else {
              setInput(getFilterDescription(filterItem, filterValues));
            }
          }}
          extraMargin={true}
        />
      </FilterSelectionColumn>
    </FilterItem>
  );
}

export default PeriodFilter;

const FilterItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 4px;
`;

const FilterSelection = styled.div<{ extraPadding?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: ${({ extraPadding }) => (extraPadding ? "13" : "0")}px;
`;

const FilterSelectionColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const FilterInput = styled.input`
  width: 50px;
  border: 0;
  font-style: italic;
  font-size: 10px;
`;
