import React from "react";
import styled from "styled-components";
import {
  IconDefinition,
  faArrowsAltH,
  faCog,
  faHandHolding,
  faNewspaper,
  faUserTie,
  faLayerGroup,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AdjustmentRateInfo, EntityTypeId } from "../../common/types";
import { filterGreenTransparent, settingGreen } from "../../common/colors";
import Tooltip from "../Tooltip";

enum Scale {
  default = "default",
  small = "small",
}

function ProjectTypeIcon(props: {
  projectTechnicalType: EntityTypeId | null;
  overrideIcon?: IconDefinition;
  noMargin?: boolean;
  adjustmentRateInfo?: AdjustmentRateInfo | null;
}) {
  const { projectTechnicalType, overrideIcon, noMargin, adjustmentRateInfo } = props;
  if (overrideIcon) {
    return (
      <IconContainer noMargin={noMargin}>
        <FontAwesomeIcon icon={overrideIcon} size="1x" />
      </IconContainer>
    );
  }
  switch (projectTechnicalType) {
    case EntityTypeId.ProjectAdjustment:
    case EntityTypeId.ManualAdjustment:
      return (
        <IconContainer noMargin={noMargin}>
          <FontAwesomeIcon icon={faNewspaper} size="1x" />
          <OverlappedIcon icon={faHandHolding} size="1x" left={0} top={2} />
        </IconContainer>
      );
    case EntityTypeId.AutomaticAdjustment:
      return adjustmentRateInfo && adjustmentRateInfo.editable ? (
        <Tooltip
          message={
            "Rate editable between: " + adjustmentRateInfo.currencyPairs.map(({ from, to }) => from + "->" + to).join()
          }
          offset={{ x: 0, y: 10 }}
        >
          <IconContainer noMargin={noMargin} editable={true}>
            <FontAwesomeIcon icon={faNewspaper} size="1x" />
            <OverlappedIcon icon={faCog} size="1x" left={8} top={4} scale={Scale.small} />
          </IconContainer>
        </Tooltip>
      ) : (
        <IconContainer noMargin={noMargin}>
          <FontAwesomeIcon icon={faNewspaper} size="1x" />
          <OverlappedIcon icon={faCog} size="1x" left={8} top={4} scale={Scale.small} />
        </IconContainer>
      );
    case EntityTypeId.ProjectWithRelations:
      return (
        <IconContainer noMargin={noMargin}>
          <FontAwesomeIcon icon={faNewspaper} size="1x" />
          <OverlappedIcon icon={faArrowsAltH} size="1x" left={0} top={8} />
        </IconContainer>
      );
    case EntityTypeId.AggregatedOrders:
      return (
        <IconContainer noMargin={noMargin}>
          <FontAwesomeIcon icon={faNewspaper} size="1x" />
          <OverlappedIcon icon={faLayerGroup} size="1x" left={8} top={4} scale={Scale.small} />
        </IconContainer>
      );
    case EntityTypeId.ServiceProject:
      return (
        <IconContainer noMargin={noMargin}>
          <FontAwesomeIcon icon={faNewspaper} size="1x" />
          <OverlappedIcon icon={faUserTie} size="1x" left={8} top={4} scale={Scale.small} />
        </IconContainer>
      );
  }
  return (
    <IconContainer noMargin={noMargin}>
      <FontAwesomeIcon icon={faNewspaper} size="1x" />
    </IconContainer>
  );
}

export default ProjectTypeIcon;

const IconContainer = styled.div<{ noMargin?: boolean; editable?: boolean }>`
  position: relative;
  color: #222;
  ${({ editable }) =>
    editable
      ? `
  background-color: ${filterGreenTransparent};
  border-radius: 5px;
  border: 1px solid ${settingGreen};
`
      : ""}
  padding: 2px;
  ${({ noMargin }) => (!noMargin ? "margin-right: 10px;" : "")}
`;

const OverlappedIcon = styled(FontAwesomeIcon)<{ left: number; top: number; scale?: Scale }>`
  position: absolute;
  width: 0;
  ${({ left, top }) => `left: ${left}px; top: ${top}px;`}
  color: #eeeeee;
  path {
    stroke: black;
    stroke-width: 28;
  }
  transform: ${({ scale }) => (scale === Scale.small ? "scale(0.8, 0.8)" : "scale(-1.2, 1.3)")};
`;
