import React from "react";
import { ParsedMonthlyLogs } from "./util";
import { settingGreen } from "../../../../common/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import LogsList from "./LogsList";
import { CollapseButton, GroupContainer, GroupHeader } from "../../../../common/components";

export interface MonthlyLogsProps {
  logs: ParsedMonthlyLogs;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}

function MonthlyLogs(props: MonthlyLogsProps) {
  const { period, logs } = props.logs;
  const { isOpen, setIsOpen } = props;

  return (
    <GroupContainer>
      <GroupHeader onClick={() => setIsOpen(!isOpen)}>
        <CollapseButton fontSize={"16px"}>
          <FontAwesomeIcon icon={isOpen ? faChevronDown : faChevronRight} size="1x" color={settingGreen} />
        </CollapseButton>
        {period}
      </GroupHeader>
      {isOpen && <LogsList logEvents={logs} />}
    </GroupContainer>
  );
}

export default MonthlyLogs;
