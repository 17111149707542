import React, { useCallback, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { valmetGreyLight } from "../../../../../common/colors";
import {
  CostDetailsNonPoInvoice,
  CostDetailsNonPoInvoicesItem,
  ProjectCostPurchasesAggregatedValues,
  ProjectCostPurchasesItem,
  ProjectCostPurchasesOrder,
  ProjectCostPurchasesOrderLine,
  ProjectCostPurchasesSupplierOrders,
  ProjectCostPurchasesTableItem,
  ProjectCostPurchaseSurchargesItem,
  SerOrderCostPurchasesAggregatedValues,
  SerOrderCostPurchasesItem,
  SerOrderCostPurchasesOrder,
  SerOrderCostPurchasesOrderLine,
  SerOrderCostPurchasesSupplierOrders,
  SerOrderCostPurchaseSurchargesItem,
} from "./types";
import { justifyToRight } from "./column";
import { PROJECT_COST_PURCHASES_COLUMNS, PROJECT_COST_PURCHASES_COLUMNS_SER_ORDER } from "./constants";
import { ContextMenu, ContextMenuTrigger, MenuItem } from "react-contextmenu";
import { CollapseButton } from "../../../../../common/components";
import { ProjectVirtualType } from "../../../../../common/types";
import { initLogger } from "../../../../../logging";
import { CostDetailsPurchasesItem } from "../types";
import {
  DataCell,
  DataContainer,
  DataRow,
  DetailsTable,
  HeaderCell,
  HeaderRow,
  TypedDataCell,
  TableBody,
} from "./components";

const logger = initLogger(__filename);

interface CostsSummaryProps {
  purchasesItems: CostDetailsPurchasesItem[];
  projectVirtualType: ProjectVirtualType;
  popupWindow: boolean;
}

const orUndefined = <T extends unknown>(v: T | null): T | undefined => (v === null ? undefined : v);

const transformProjectNonPoInvoice = (
  item: CostDetailsNonPoInvoice,
  parentId: string,
  last: boolean
): ProjectCostPurchasesTableItem => {
  return {
    _original: item,
    last,
    id: parentId + "-" + item.itemId,
    childLevel: 3,
    description: item.description,
    actuals: item.actuals,
    invoiceDate: orUndefined(item.invoiceDate),
    invoicePictureUrl: orUndefined(item.invoicePictureURL),
  };
};

const transformProjectNonPoInvoicesItem = (
  item: CostDetailsNonPoInvoicesItem,
  parentId: string
): ProjectCostPurchasesTableItem[] => {
  if (item.childItems.length > 0) {
    const id = parentId + "non-pos";
    const childItems = item.childItems.map((np, index) =>
      transformProjectNonPoInvoice(np, id, index === item.childItems.length - 1)
    );
    return [
      {
        _original: item,
        id,
        childLevel: 2,
        description: "615 Non-PO Invoices",
        actuals: item.actuals,
        childItems,
      },
    ];
  } else {
    return [];
  }
};

const transformSerOrderNonPoInvoice = (
  item: CostDetailsNonPoInvoice,
  parentId: string,
  last: boolean
): ProjectCostPurchasesTableItem => {
  return {
    _original: item,
    last,
    id: parentId + "-" + item.itemId,
    childLevel: 3,
    description: item.description,
    poLineAmount: item.actuals,
    invoiceDate: orUndefined(item.invoiceDate),
    invoicePictureUrl: orUndefined(item.invoicePictureURL),
  };
};

const transformSerOrderNonPoInvoicesItem = (
  item: CostDetailsNonPoInvoicesItem,
  parentId: string
): ProjectCostPurchasesTableItem[] => {
  if (item.childItems.length > 0) {
    const id = parentId + "non-pos";
    const childItems = item.childItems.map((np, index) =>
      transformSerOrderNonPoInvoice(np, id, index === item.childItems.length - 1)
    );
    return [
      {
        _original: item,
        id,
        childLevel: 2,
        description: "615 Non-PO Invoices",
        poLineAmount: item.actuals,
        childItems,
      },
    ];
  } else {
    return [];
  }
};

const getProjectAggregatedValues = (item: { aggregatedValues: ProjectCostPurchasesAggregatedValues }) => ({
  softCommitments: item.aggregatedValues.softCommitments,
  hardCommitments: item.aggregatedValues.hardCommitments,
  actuals: item.aggregatedValues.actuals,
  total: item.aggregatedValues.total,
});

const transformPurchaseItem = (item: ProjectCostPurchasesItem): ProjectCostPurchasesTableItem => {
  const id = item.subProject;
  const childItems = item.childItems
    .map(so => transformPurchaseSupplierOrders(so, id))
    .concat([transformPurchaseSurchargesItem(item.surcharges, id)]);
  return {
    _original: item,
    id,
    childLevel: 0,
    description: item.subProject,
    childItems,
    ...getProjectAggregatedValues(item),
  };
};

const transformPurchaseSupplierOrders = (
  item: ProjectCostPurchasesSupplierOrders,
  parentId: string
): ProjectCostPurchasesTableItem => {
  const id = parentId + item.supplier;
  const childItems = item.childItems
    .map(order => transformPurchaseOrder(order, id))
    .concat(transformProjectNonPoInvoicesItem(item.nonPoInvoices, id));
  return {
    _original: item,
    id,
    childLevel: 1,
    description: item.supplier,
    childItems,
    ...getProjectAggregatedValues(item),
  };
};

const transformPurchaseSurchargesItem = (
  item: ProjectCostPurchaseSurchargesItem,
  parentId: string
): ProjectCostPurchasesTableItem => {
  const id = parentId + "surcharges";
  const childItems = item.childItems.map(order => transformPurchaseOrder(order, id));
  return {
    _original: item,
    id,
    childLevel: 1,
    description: "Surcharge costs total",
    childItems: childItems,
    ...getProjectAggregatedValues(item),
  };
};

const transformPurchaseOrder = (item: ProjectCostPurchasesOrder, parentId: string): ProjectCostPurchasesTableItem => {
  const id = parentId + "-order-" + item.order;
  const lastChildIndex = item.childItems.length - 1;
  const childItems = item.childItems.map((line, index) =>
    transformProjectPurchaseOrderLine(line, id, index, lastChildIndex)
  );
  return {
    _original: item,
    id,
    childLevel: 2,
    description: item.order,
    childItems,
    ...getProjectAggregatedValues(item),
  };
};

const transformProjectPurchaseOrderLine = (
  item: ProjectCostPurchasesOrderLine,
  parentId: string,
  index: number,
  lastIndex: number
): ProjectCostPurchasesTableItem => {
  return {
    _original: item,
    last: index === lastIndex,
    id: parentId + item.description + index,
    childLevel: 3,
    description: item.description,
    orderedQuantity: orUndefined(item.purchasedQuantity),
    orderDate: orUndefined(item.orderDate),
    orderPictureUrl: orUndefined(item.orderPictureURL),
    actualReceiptDate: orUndefined(item.actualReceiptDate),
    plannedReceiptDate: orUndefined(item.plannedReceiptDate),
    estimatedReceiptDate: orUndefined(item.estimatedReceiptDate),
    invoicedQuantity: orUndefined(item.invoicedQuantity),
    invoiceDate: orUndefined(item.invoiceDate),
    invoicePictureUrl: orUndefined(item.invoicePictureURL),
    softCommitments: orUndefined(item.softCommitments),
    hardCommitments: orUndefined(item.hardCommitments),
    actuals: orUndefined(item.actuals),
    total: item.total,
  };
};

const getSerOrderAggregatedValues = (item: { aggregatedValues: SerOrderCostPurchasesAggregatedValues }) => ({
  poLineAmount: item.aggregatedValues.poLineAmount,
  poLinePrice: item.aggregatedValues.poLinePrice,
});

const transformSerOrderPurchaseItem = (item: SerOrderCostPurchasesItem): ProjectCostPurchasesTableItem => {
  const id = item.subProject;
  const childItems = item.childItems
    .map(so => transformSerOrderPurchaseSupplierOrders(so, id))
    .concat([transformSerOrderPurchaseSurchargesItem(item.surcharges, id)]);
  return {
    _original: item,
    id,
    childLevel: 0,
    description: item.subProject,
    childItems,
    ...getSerOrderAggregatedValues(item),
  };
};

const transformSerOrderPurchaseSupplierOrders = (
  item: SerOrderCostPurchasesSupplierOrders,
  parentId: string
): ProjectCostPurchasesTableItem => {
  const id = parentId + item.supplier;
  const childItems = item.childItems
    .map(order => transformSerOrderPurchaseOrder(order, id))
    .concat(transformSerOrderNonPoInvoicesItem(item.nonPoInvoices, id));
  return {
    _original: item,
    id,
    childLevel: 1,
    description: item.supplier,
    childItems,
    ...getSerOrderAggregatedValues(item),
  };
};

const transformSerOrderPurchaseSurchargesItem = (
  item: SerOrderCostPurchaseSurchargesItem,
  parentId: string
): ProjectCostPurchasesTableItem => {
  const id = parentId + "surcharges";
  const childItems = item.childItems.map(order => transformSerOrderPurchaseOrder(order, id));
  return {
    _original: item,
    id,
    childLevel: 1,
    description: "Surcharge costs total",
    childItems,
    ...getSerOrderAggregatedValues(item),
  };
};

const transformSerOrderPurchaseOrder = (
  item: SerOrderCostPurchasesOrder,
  parentId: string
): ProjectCostPurchasesTableItem => {
  const id = parentId + item.order;
  const lastChildIndex = item.childItems.length - 1;
  const childItems = item.childItems.map((line, index) =>
    transformSerOrderPurchaseOrderLine(line, id, index, lastChildIndex)
  );
  return {
    _original: item,
    id,
    childLevel: 2,
    description: item.order,
    childItems,
    ...getSerOrderAggregatedValues(item),
  };
};

const transformSerOrderPurchaseOrderLine = (
  item: SerOrderCostPurchasesOrderLine,
  parentId: string,
  index: number,
  lastIndex: number
): ProjectCostPurchasesTableItem => {
  logger.info("Transform ser order line", item);
  return {
    _original: item,
    last: index === lastIndex,
    id: parentId + item.description + index,
    childLevel: 3,
    description: item.description,
    orderedQuantity: orUndefined(item.purchasedQuantity),
    orderDate: orUndefined(item.orderDate),
    orderPictureUrl: orUndefined(item.orderPictureURL),
    actualReceiptDate: orUndefined(item.actualReceiptDate),
    invoicedQuantity: orUndefined(item.invoicedQuantity),
    invoiceDate: orUndefined(item.invoiceDate),
    invoicePictureUrl: orUndefined(item.invoicePictureURL),
    poLineAmount: item.poLineAmount,
    poLinePrice: item.poLinePrice,
  };
};

const transformItem = (item: CostDetailsPurchasesItem): ProjectCostPurchasesTableItem => {
  switch (item.__typename) {
    case "ProjectCostPurchasesItem":
      return transformPurchaseItem(item);
    case "SerOrderCostPurchasesItem":
      return transformSerOrderPurchaseItem(item);
  }
};

function getAllChildren(item: ProjectCostPurchasesTableItem, result: Set<string>): void {
  item.childItems?.forEach(child => {
    result.add(child.id);
    getAllChildren(child, result);
  });
}

function expand(item: ProjectCostPurchasesTableItem, open: Set<string>): ProjectCostPurchasesTableItem[] {
  const result: ProjectCostPurchasesTableItem[] = [];
  open.add(item.id);
  item.childItems?.forEach(child => {
    result.push(child);
    const children = expand(child, open);
    children.forEach(c => result.push(c));
  });
  return result;
}

function PurchasesTable(props: CostsSummaryProps): React.ReactElement {
  const { purchasesItems, projectVirtualType, popupWindow } = props;
  const [openItems, setOpenItems] = useState<Set<string>>(new Set());
  const [renderedRows, setRenderedRows] = useState<ProjectCostPurchasesTableItem[]>([]);

  useEffect(() => {
    const newRows = purchasesItems.map(transformItem);
    if (newRows.length > 0 && newRows[0].childItems) {
      const visibleRows = newRows.flatMap(row => [row].concat(row.childItems || []));
      newRows.forEach(row => openItems.add(row.id));
      setOpenItems(openItems);
      setRenderedRows(visibleRows);
    } else {
      setRenderedRows(newRows);
    }
  }, [purchasesItems]);

  const expandAll = useCallback(
    (item: ProjectCostPurchasesTableItem) => {
      const itemsToClose = new Set<string>();
      getAllChildren(item, itemsToClose);

      // First close the sub hierarchy
      const closedHierarchy = renderedRows.filter(row => !itemsToClose.has(row.id));

      const children = expand(item, openItems);
      const newRows = closedHierarchy.flatMap(it => {
        if (item.id === it.id) {
          return [it].concat(children);
        } else {
          return [it];
        }
      });
      console.log("Purchase Rows", newRows);
      setRenderedRows(newRows);
      setOpenItems(openItems);
    },
    [renderedRows, setRenderedRows, openItems, setOpenItems]
  );

  const openItem = useCallback(
    (item: ProjectCostPurchasesTableItem) => {
      const newRows = renderedRows.flatMap(it => {
        if (item.id === it.id && it.childItems) {
          return [it].concat(it.childItems);
        } else {
          return [it];
        }
      });
      setRenderedRows(newRows);
      setOpenItems(openItems.add(item.id));
    },
    [renderedRows, setRenderedRows, openItems, setOpenItems]
  );

  const closeItem = useCallback(
    (item: ProjectCostPurchasesTableItem) => {
      const itemsToClose = new Set<string>();
      getAllChildren(item, itemsToClose);

      const newOpenItems = openItems;
      itemsToClose.forEach(id => newOpenItems.delete(id));
      newOpenItems.delete(item.id);
      setOpenItems(newOpenItems);

      setRenderedRows(renderedRows.filter(row => !itemsToClose.has(row.id)));
    },
    [renderedRows, setRenderedRows, openItems, setOpenItems]
  );

  const columns =
    projectVirtualType === ProjectVirtualType.LnServiceOrder
      ? PROJECT_COST_PURCHASES_COLUMNS_SER_ORDER
      : PROJECT_COST_PURCHASES_COLUMNS;

  return (
    <DetailsTable>
      <TableBody>
        <HeaderRow>
          <HeaderCell right={false} popupWindow={popupWindow}>
            Description
          </HeaderCell>
          {columns.map((column, i) => (
            <HeaderCell key={i} right={justifyToRight(column)} title={column.tooltip} popupWindow={popupWindow}>
              {column.description}
            </HeaderCell>
          ))}
        </HeaderRow>
        {renderedRows.map(item => (
          <DataRow
            key={item.id}
            childLevel={item.childLevel}
            last={item.last}
            extraPadding={!item.childItems || item.childItems.length === 0}
          >
            <DataCell>
              <DataContainer>
                {item.childItems && item.childItems.length > 0 && (
                  <ContextMenuTrigger id={`${item.id}-context-menu`}>
                    <CollapseButton
                      onClick={e => {
                        e.stopPropagation();
                        if (item.childItems && item.childItems.length > 0) {
                          if (openItems.has(item.id)) {
                            closeItem(item);
                          } else {
                            openItem(item);
                          }
                        }
                      }}
                    >
                      <FontAwesomeIcon
                        icon={openItems.has(item.id) ? faChevronUp : faChevronDown}
                        size="1x"
                        color={valmetGreyLight}
                      />
                    </CollapseButton>
                  </ContextMenuTrigger>
                )}
                {item.description}
                <ContextMenu id={`${item.id}-context-menu`}>
                  <MenuItem onClick={() => expandAll(item)}>Expand all</MenuItem>
                  <MenuItem onClick={() => closeItem(item)}>Collapse all</MenuItem>
                </ContextMenu>
              </DataContainer>
            </DataCell>
            {columns.map((column, i) => (
              <TypedDataCell key={item.id + "_" + i} column={column} item={item} />
            ))}
          </DataRow>
        ))}
      </TableBody>
    </DetailsTable>
  );
}

export default PurchasesTable;
