import React, { useState } from "react";
import styled from "styled-components";
import Filters from "./Filters";
import TopList from "./TopList";
import ProjectsList from "./ProjectsList";
import ColumnConfiguration from "./ColumnConfiguration";
import { ProjectType } from "../../../common/types";
import { valmetGreyBorder } from "../../../common/colors";
import FiltersConfiguration from "./FiltersConfiguration/FiltersConfiguration";

function Projects(): React.ReactElement {
  const [topListOpen, setTopListOpen] = useState(false);
  const [projectsFound, setProjectsFound] = useState<number | undefined>(undefined);
  const [types, setTypes] = useState<ProjectType[]>([]);
  return (
    <Container>
      <HeaderContainer>
        {topListOpen && <TopList onCloseTopList={() => setTopListOpen(false)} />}
        <ConfigurationRow>
          <ColumnConfiguration
            onOpenTopList={() => setTopListOpen(true)}
            topListOpen={topListOpen}
            projectsFound={projectsFound}
          />
          <FiltersConfiguration />
        </ConfigurationRow>
        <Filters setTypes={setTypes} />
      </HeaderContainer>
      <ProjectsList onProjectsFound={projects => setProjectsFound(projects)} projectTypes={types} />
    </Container>
  );
}

export default Projects;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 8px;
`;

const HeaderContainer = styled.div`
  width: 100vw;
  position: sticky;
  left: 0;
  z-index: 1000;
`;

const ConfigurationRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: flex-end;
  border-bottom: 1px solid ${valmetGreyBorder};
  margin-bottom: 0px;
  padding-bottom: 10px;
  padding-left: 100px;
  padding-right: 100px;
`;
