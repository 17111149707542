import React from "react";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { valmetGreyBorder } from "../../../../common/colors";

export interface LNActivityStatusTooltipProps {
  isBlocked: boolean;
  coldLinesExist: boolean;
}
function LNActivityStatusTooltip(props: LNActivityStatusTooltipProps): React.ReactElement {
  const { isBlocked, coldLinesExist } = props;
  const color = () => {
    if (isBlocked && !coldLinesExist) return "red";
    else if (!isBlocked && coldLinesExist) return "green";
    else return "yellow";
  };

  const booleanString = (value: boolean) => (value ? "Yes" : "No");

  return (
    <TooltipContainer>
      <FontAwesomeIcon icon={faCircle} size="1x" color={color()} />
      <TooltipMessage>
        Is blocked: {booleanString(isBlocked)} <br /> Cold line exist: {booleanString(coldLinesExist)}
      </TooltipMessage>
    </TooltipContainer>
  );
}

export default LNActivityStatusTooltip;

const TooltipContainer = styled.div`
  position: relative;
  padding: 0 6px 0;

  :hover span {
    display: block;
  }
`;

const TooltipMessage = styled.span`
  display: none;
  position: absolute;
  top: 20px;
  left: 0;
  background: #ffffff;
  width: 165px;
  z-index: 10;
  border-radius: 4px;
  border: 1px solid ${valmetGreyBorder};
  padding: 4px;
`;
