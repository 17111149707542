import React from "react";
import styled from "styled-components";

import { ProjectReportingRelations, ProjectReportingRelationsVisibility } from "../../../../../common/types";
import { getDataItem, combineToString, yesNo } from "../utils";
import { valmetGreyBorder } from "../../../../../common/colors";

type Props = {
  reportingDetails: ProjectReportingRelations;
  visibility: ProjectReportingRelationsVisibility;
};

export default function ViewDetails(props: Props): React.ReactElement {
  const { reportingDetails: details, visibility } = props;
  const extVis = visibility.externalOrganisation;
  return (
    <>
      <DataSection
        noBorder={
          extVis === null ||
          !(
            extVis.businessType ||
            extVis.businessGroup ||
            extVis.legalEntity ||
            extVis.externalReportingIndustry ||
            extVis.customerId
          )
        }
      >
        {(visibility.relatingProjectId || visibility.relatingProjectDesc) && (
          <CodeSection2>
            <InformationItem>
              <>
                <TitleItem>Relating project:</TitleItem>
                {getDataItem(
                  combineToString([
                    visibility.relatingProjectId ? details.relatingProjectId : undefined,
                    visibility.relatingProjectDesc ? details.relatingProjectDesc : undefined,
                  ])
                )}
              </>
            </InformationItem>
            <InformationItem>
              {visibility.serMarginTraceability && (
                <>
                  <TitleItem>SER margin traceability:</TitleItem>
                  {getDataItem(yesNo(details.serMarginTraceability || false))}
                </>
              )}
            </InformationItem>
          </CodeSection2>
        )}
        <CodeSection2>
          <InformationItem>
            {extVis !== null && extVis.reporting && (
              <>
                <TitleItem>External reporting:</TitleItem>
                {getDataItem(details.externalOrganisation.reporting)}
              </>
            )}
          </InformationItem>
          <InformationItem>
            {extVis !== null &&
              extVis.orderValueIncludedInRelated &&
              // Only if reporting is not "yes" (ID 10487 in dev and prod), allow editing "Order value included" field.
              details.externalOrganisation.reporting !== "Yes" && (
                <>
                  <TitleItem title="Is this project's value included in the related project">
                    Order value included in related:
                  </TitleItem>
                  {getDataItem(yesNo(details.externalOrganisation.orderValueIncludedInRelated || false))}
                </>
              )}
          </InformationItem>
        </CodeSection2>
      </DataSection>
      {extVis !== null &&
        (extVis.businessType ||
          extVis.businessGroup ||
          extVis.legalEntity ||
          extVis.externalReportingIndustry ||
          extVis.customerId) && (
          <DataSection noBorder={true}>
            <CodeSection12>
              <InformationItem>
                {extVis.businessType && (
                  <>
                    <TitleItem>External business type:</TitleItem>
                    {getDataItem(details.externalOrganisation.businessType)}
                  </>
                )}
              </InformationItem>
              <InformationItem>
                {extVis.legalEntity && (
                  <>
                    <TitleItem>External legal company:</TitleItem>
                    {getDataItem(details.externalOrganisation.legalEntity)}
                  </>
                )}
              </InformationItem>
            </CodeSection12>
            <InformationItem>
              {extVis.businessGroup && (
                <>
                  <TitleItem>External business group:</TitleItem>
                  {getDataItem(details.externalOrganisation.businessGroup)}
                </>
              )}
            </InformationItem>
            <InformationItem>
              {extVis.externalReportingIndustry && (
                <>
                  <TitleItem>External industry:</TitleItem>
                  {getDataItem(details.externalOrganisation.externalReportingIndustry)}
                </>
              )}
            </InformationItem>
            <InformationItem>
              {(extVis.customerId || extVis.customerName) && (
                <>
                  <TitleItem>External customer:</TitleItem>
                  {getDataItem(
                    combineToString([
                      extVis.customerId ? details.externalOrganisation.customerId : undefined,
                      extVis.customerName ? details.externalOrganisation.customerName : undefined,
                    ])
                  )}
                </>
              )}
            </InformationItem>
            <InformationItem>
              {visibility.relatedLegalCompany && (
                <>
                  <TitleItem>Related legal company:</TitleItem>
                  {getDataItem(details.relatedLegalCompany)}
                </>
              )}
            </InformationItem>
          </DataSection>
        )}
    </>
  );
}

const TitleItem = styled.div`
  font-size: 14px;
  margin-bottom: 4px;
`;

const InformationItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding-right: 5px;
`;

export const DataSection = styled.div<{ noBorder?: boolean }>`
  margin-bottom: ${({ noBorder }) => (noBorder ? "0px;" : `20px;`)};
  border-bottom: ${({ noBorder }) => (noBorder ? "0;" : `1px solid ${valmetGreyBorder}`)};
`;

const CodeSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;

const CodeSection2 = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const CodeSection12 = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
`;
