import React, { useEffect } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";

import { EditableCommentPackage } from "./types";
import { ActionButton } from "../../../../common/components";
import { defaultGrey, greyTransparent } from "../../../../common/colors";
import { CommentHeader, CommentPackageInfo } from "./shared-components";

interface CommentPackageViewProps {
  commentPackage: EditableCommentPackage;
  close: () => void;
  edit?: () => void;
}

const useKeyDown = (onEvent: (key: string) => void) => {
  useEffect(() => {
    function onKeyDown(ev: KeyboardEvent) {
      onEvent(ev.key);
    }
    document.addEventListener("keydown", onKeyDown);
    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [onEvent]);
};

function CommentPackageView(props: CommentPackageViewProps) {
  const { commentPackage, close, edit } = props;
  const { modifiedBy, modified, published } =
    commentPackage.type !== "NewComment"
      ? commentPackage
      : {
          modifiedBy: "-",
          modified: new Date().toISOString(),
          published: null,
        };

  useKeyDown((key: string) => {
    if (key === "Escape" || key === "Esc") close();
  });

  const isEditable = commentPackage.type === "EditablePackage";
  return (
    <CommentPackageViewWrapper>
      <CommentPackageViewContainer>
        <ContentContainer>
          <HeaderContainer>
            <CommentHeader period={commentPackage.period} commentType={commentPackage.commentType} />
            <CommentPackageInfo modifiedBy={modifiedBy} modified={modified} published={published} />
          </HeaderContainer>
          <CommentContent>{commentPackage.content}</CommentContent>
        </ContentContainer>
        <ViewActionsContainer>
          <ActionsSpanner />
          <ViewActions>
            {edit && isEditable && (
              <ActionButton onClick={edit}>
                <FontAwesomeIcon icon={faEdit} />
                Edit
              </ActionButton>
            )}
            <ActionButton onClick={close} color={defaultGrey}>
              Close
            </ActionButton>
          </ViewActions>
        </ViewActionsContainer>
      </CommentPackageViewContainer>
    </CommentPackageViewWrapper>
  );
}

export default CommentPackageView;

const CommentPackageViewWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 100vh;
  background-color: ${greyTransparent};
`;

const CommentPackageViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 1000;
  max-width: 80vw;
  min-width: 600px;
  background-color: #fff;
  /*padding: 10px;*/
  box-shadow: ${greyTransparent} 0px 5px 10px;
  border-bottom-right-radius: 40px;
  overflow: hidden;
`;

const ContentContainer = styled.div`
  padding: 20px;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const CommentContent = styled.pre`
  margin: 10px 0px;
  padding: 16px;
  white-space: break-spaces;
  font-family: sans-serif !important;
  max-height: 60vh;
  overflow: scroll;
`;

const ViewActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const ActionsSpanner = styled.div`
  display: flex;
  justify-self: stretch;
  align-self: flex-end;
  width: 100%;
  height: 25px;
  margin-right: -20px;
  background-color: #eee;
`;

const ViewActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  background-color: #fff;
  padding: 10px 100px;
  min-width: 100px;
  border-top-left-radius: 200px;
  box-shadow: #0002 0px 0px 25px;
`;
