import { produce } from "immer";
import { SettingsState, SettingsStateAction } from "../common/types";
import { SET_SELECTED_CURRENCY } from "../actions/settingsActions";

export const initState: SettingsState = {
  selectedCurrency: undefined,
};

export default function settingsReducer(
  state: Readonly<SettingsState> = initState,
  action: SettingsStateAction
): SettingsState {
  switch (action.type) {
    case SET_SELECTED_CURRENCY:
      return produce(state, newState => {
        if (action.selectedCurrency !== undefined) {
          newState.selectedCurrency = action.selectedCurrency;
        }
      });
    default:
      return state;
  }
}
