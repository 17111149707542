import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { faPlus, faSave } from "@fortawesome/free-solid-svg-icons";
import { cancelRed, disabledSettingGreen, greyTransparent, settingGreen } from "../../../../../common/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonContainer, IconButton, TextButton } from "../../../../../common/components";
import { ProjectFiltersId } from "../../../../../common/types";

export interface FiltersSettingsModalProps {
  editingFiltersId: ProjectFiltersId | undefined;
  filtersName: string;
  onSave: () => {
    saveName: (name: string) => void;
    successful: boolean;
    loading: boolean;
    error: string | undefined;
  };
  closeModal: () => void;
}

export function FiltersSettingsModal(props: FiltersSettingsModalProps): React.ReactElement {
  const { editingFiltersId, filtersName, onSave, closeModal } = props;
  const [name, setName] = useState<string>(filtersName);
  const { saveName, successful, loading, error } = onSave();
  const originName = filtersName;

  useEffect(() => {
    if (successful && !loading) {
      closeModal();
    }
  }, [successful, loading, error]);

  const disabledSave = name.length === 0 || originName === name;

  return (
    <FullscreenWrapper>
      <EditContainer>
        {editingFiltersId ? "Edit filters:" : "Add filters:"}
        {!loading && error && <ErrorRow>Saving error: {error}</ErrorRow>}
        <NameRow>
          Filters name:{" "}
          <NameInput
            type="text"
            placeholder="Enter filters name"
            value={name}
            onChange={event => setName(event.target.value)}
          />
        </NameRow>
        {loading ? (
          <TextButton>Saving...</TextButton>
        ) : (
          <ButtonContainer>
            <IconButton onClick={() => saveName(name)} disabled={disabledSave} hasText={true}>
              Save
              <FontAwesomeIcon icon={faSave} size="1x" color={disabledSave ? disabledSettingGreen : settingGreen} />
            </IconButton>
            <IconButton onClick={() => closeModal()} hasText={true}>
              Cancel
            </IconButton>
          </ButtonContainer>
        )}
      </EditContainer>
    </FullscreenWrapper>
  );
}

const FullscreenWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  z-index: 1200;
  background: ${greyTransparent};
`;

const EditContainer = styled.div`
  background: white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  z-index: 500;
  font-size: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 25px;
  padding-right: 25px;
  align-self: flex-start;
  margin-top: 150px;
`;

const ErrorRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  color: ${cancelRed};
`;

const NameRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
`;

const NameInput = styled.input`
  margin-left: 10px;
`;
