import { HistoryAction } from "../common/types";

export const ADD_PROJECT_HISTORY = "ADD_PROJECT_HISTORY";
export const TOGGLE_HISTORY_VIEW = "TOGGLE_HISTORY_VIEW";

export const addProjectToHistory = (id: number, name: string): HistoryAction => ({
  type: ADD_PROJECT_HISTORY,
  data: { id, name }
});

export const toggleHistoryView = (): HistoryAction => ({
  type: TOGGLE_HISTORY_VIEW
});
