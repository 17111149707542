import { cloneDeep } from "lodash";
import React, { useEffect, useState } from "react";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { commentBlue } from "../../../../../../common/colors";
import {
  AppState,
  OrganisationKeysResult,
  ProjectInformation,
  ProjectInformationEditInformation,
  ProjectInformationInput,
  ProjectInformationSectionInput,
  ProjectOrganisationVisibility,
  SearchCustomersResult,
} from "../../../../../../common/types";
import { parseNumberOrNull } from "../../../../../../common/utils";
import EditableSearchableDropdown from "../../EditableComponents/EditableSearchableDropdown";
import { CodeSection, DataSection, InformationItem, TitleItem } from "../EditDetails";
import { ExtendedCodeSection } from "../ViewDetails";
import { useQuery } from "@apollo/client/react/hooks";
import { SEARCH_CUSTOMERS } from "../queries";
import { CUSTOMERS_QUERY_SIZE } from "../../../../../../common/constants";
import { combineToString, getDataItem, visibilityCheckWithOverride, editClone, inheritOrgValue } from "../../utils";
import { useDebouncedState } from "../../../../../../hooks/useDebouncedState";
import { IconButton } from "../../../../../../common/components";
import { ThunkDispatch } from "redux-thunk";
import { Action } from "redux";
import { setProjectInformation } from "../../../../../../actions/projectActions";
import { connect } from "react-redux";
import { DetailsField } from "../checkInputErrors";

type OrganisationDataSectionProps = {
  editInfo: ProjectInformationEditInformation;
  organisationKeysData: OrganisationKeysResult;
  projectInformation: ProjectInformation;
  inputErrors: DetailsField[];
  projectOrganisationVisibility: ProjectOrganisationVisibility | null;
};

const mapStateToProps = (state: AppState) => {
  return {
    sectionInput: state.projectState.projectInput ? state.projectState.projectInput.projectInformation : undefined,
  };
};
const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, void, Action>) => {
  return {
    setSectionInput: (input: ProjectInformationSectionInput) => dispatch(setProjectInformation(input)),
  };
};

function UnlockIcon(props: { onClick: () => void }) {
  const { onClick } = props;
  return (
    <IconButton onClick={onClick} fontSize={"12px"}>
      <FontAwesomeIcon
        icon={faLock}
        size="1x"
        color={commentBlue}
        title="Value has been edited in POP. Click to reset back to the value from ERP."
      />
    </IconButton>
  );
}

const OrganisationDataSection: React.FC<
  OrganisationDataSectionProps & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
> = (
  props: OrganisationDataSectionProps & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
) => {
  const {
    sectionInput,
    setSectionInput,
    editInfo,
    organisationKeysData,
    projectInformation,
    inputErrors,
    projectOrganisationVisibility,
  } = props;

  const [customersInput, customersSearchInput, setCustomersInput] = useDebouncedState(
    500,
    editInfo.organisation.customerId !== null
      ? `${editInfo.organisation.customerId || ""} ${projectInformation.organisation.customerName || ""}`
      : ""
  );
  const [customersLoading, setCustomersLoading] = useState(false);
  useEffect(() => setCustomersLoading(true), [customersInput]);

  const { data: customersData, error: customersError, fetchMore } = useQuery<SearchCustomersResult>(SEARCH_CUSTOMERS, {
    variables: {
      num: CUSTOMERS_QUERY_SIZE,
      searchQuery: customersSearchInput
        .trim()
        .split(" ")
        .filter(term => term.trim().length > 0),
    },
    onCompleted: () => setCustomersLoading(false),
  });

  const setSectionInputWithoutInherited = (newInput: ProjectInformationInput) =>
    setSectionInput({ input: newInput, pristine: false, inherited: sectionInput ? sectionInput.inherited : undefined });

  return projectOrganisationVisibility !== null && sectionInput !== undefined ? (
    <DataSection>
      <CodeSection>
        <InformationItem>
          {projectOrganisationVisibility.businessType && (
            <>
              <TitleItem>
                Business type:
                {sectionInput.input.organisation.businessTypeId && editInfo.organisation.businessTypeId.cached && (
                  <UnlockIcon
                    onClick={() =>
                      setSectionInputWithoutInherited(
                        editClone(sectionInput.input, inp => (inp.organisation.businessTypeId = null))
                      )
                    }
                  />
                )}
              </TitleItem>
              {editInfo.organisation.businessTypeProperties.editable ? (
                <EditableSearchableDropdown
                  value={
                    sectionInput.input.organisation.businessTypeId !== null
                      ? sectionInput.input.organisation.businessTypeId
                      : editInfo.organisation.businessTypeId.cached || ""
                  }
                  onValueChanged={value => {
                    setSectionInputWithoutInherited(
                      editClone(sectionInput.input, inp => {
                        inp.organisation.businessTypeId = parseNumberOrNull(value);
                      })
                    );
                  }}
                  options={organisationKeysData.organisationKeys.businessTypes.map(businessType => {
                    return { id: businessType.id, description: businessType.description };
                  })}
                  disabled={!editInfo.organisation.businessTypeProperties.editable}
                  error={inputErrors.includes(DetailsField.organisationBusinessTypeId)}
                  searchable={true}
                  maxResults={20}
                />
              ) : (
                getDataItem(projectInformation.organisation.businessType)
              )}
            </>
          )}
        </InformationItem>
        <InformationItem>
          {projectOrganisationVisibility.legalEntity && (
            <>
              <TitleItem>
                Legal company:
                {sectionInput.input.organisation.legalEntityId && editInfo.organisation.legalEntityId.cached && (
                  <UnlockIcon
                    onClick={() =>
                      setSectionInputWithoutInherited(
                        editClone(sectionInput.input, inp => (inp.organisation.legalEntityId = null))
                      )
                    }
                  />
                )}
              </TitleItem>
              {editInfo.organisation.legalEntityProperties.editable ? (
                <EditableSearchableDropdown
                  value={
                    sectionInput.input.organisation.legalEntityId !== null
                      ? sectionInput.input.organisation.legalEntityId
                      : editInfo.organisation.legalEntityId.cached || ""
                  }
                  onValueChanged={value => {
                    setSectionInputWithoutInherited(
                      editClone(sectionInput.input, inp => {
                        inp.organisation.legalEntityId = parseNumberOrNull(value);
                      })
                    );
                  }}
                  options={organisationKeysData.organisationKeys.legalEntities.map(legalEntity => {
                    return { id: legalEntity.id, description: legalEntity.description };
                  })}
                  disabled={!editInfo.organisation.legalEntityProperties.editable}
                  error={inputErrors.includes(DetailsField.organisationLegalEntityId)}
                  searchable={true}
                  maxResults={20}
                />
              ) : (
                getDataItem(projectInformation.organisation.legalEntity)
              )}
            </>
          )}
        </InformationItem>
        <InformationItem>
          {projectOrganisationVisibility.intExt && (
            <>
              <TitleItem>Int/Ext:</TitleItem>
              {editInfo.organisation.intExtProperties.editable ? (
                <EditableSearchableDropdown
                  value={
                    sectionInput.input.organisation.intExtId !== null ? sectionInput.input.organisation.intExtId : ""
                  }
                  onValueChanged={value =>
                    setSectionInputWithoutInherited(
                      editClone(sectionInput.input, inp => (inp.organisation.intExtId = parseNumberOrNull(value)))
                    )
                  }
                  options={organisationKeysData.organisationKeys.intExts.map(intExt => {
                    return { id: intExt.id, description: intExt.description };
                  })}
                  searchable={false}
                  disabled={!editInfo.organisation.intExtProperties.editable}
                  error={inputErrors.includes(DetailsField.intExtId)}
                />
              ) : (
                getDataItem(projectInformation.organisation.intExt)
              )}
            </>
          )}
        </InformationItem>
      </CodeSection>
      <ExtendedCodeSection>
        <InformationItem>
          {projectOrganisationVisibility.businessGroup && (
            <>
              <TitleItem>
                Business group:
                {sectionInput.input.organisation.businessGroupId && editInfo.organisation.businessGroupId.cached && (
                  <UnlockIcon
                    onClick={() =>
                      setSectionInputWithoutInherited(
                        editClone(sectionInput.input, inp => (inp.organisation.businessGroupId = null))
                      )
                    }
                  />
                )}
              </TitleItem>
              {editInfo.organisation.businessGroupProperties.editable ? (
                <EditableSearchableDropdown
                  value={
                    sectionInput.input.organisation.businessGroupId !== null
                      ? sectionInput.input.organisation.businessGroupId
                      : editInfo.organisation.businessGroupId.cached || ""
                  }
                  onValueChanged={value => {
                    const newValue = parseNumberOrNull(value);
                    const contractInherit = editInfo.contractOwner.businessGroupProperties.shouldInherit || false;
                    const contractInheritedValue = contractInherit
                      ? inheritOrgValue(
                          newValue,
                          organisationKeysData.organisationKeys.businessGroups,
                          organisationKeysData.organisationKeys.contractOwnerBusinessGroups
                        )
                      : null;
                    setSectionInput({
                      input: editClone(sectionInput.input, inp => {
                        inp.organisation.businessGroupId = newValue;
                        if (contractInherit) inp.contractOwner.businessGroupId = contractInheritedValue;
                      }),
                      pristine: false,
                      inherited:
                        sectionInput.inherited &&
                        editClone(sectionInput.inherited, inh => {
                          inh.contractOwner.businessGroupId = contractInherit && contractInheritedValue != null;
                        }),
                    });
                  }}
                  options={organisationKeysData.organisationKeys.businessGroups.map(businessGroup => {
                    return { id: businessGroup.id, description: businessGroup.description };
                  })}
                  disabled={!editInfo.organisation.businessGroupProperties.editable}
                  error={inputErrors.includes(DetailsField.organisationBusinessGroupId)}
                  searchable={true}
                  maxResults={20}
                  inputWidth={390}
                />
              ) : (
                getDataItem(projectInformation.organisation.businessGroup)
              )}
            </>
          )}
        </InformationItem>
        <InformationItem>
          {projectOrganisationVisibility.industry && (
            <>
              <TitleItem>Industry:</TitleItem>
              {editInfo.organisation.industryProperties.editable ? (
                <EditableSearchableDropdown
                  value={
                    sectionInput.input.organisation.industryId !== null
                      ? sectionInput.input.organisation.industryId
                      : ""
                  }
                  onValueChanged={value => {
                    setSectionInputWithoutInherited(
                      editClone(sectionInput.input, inp => {
                        inp.organisation.industryId = parseNumberOrNull(value);
                      })
                    );
                  }}
                  options={organisationKeysData.organisationKeys.industries.map(industry => {
                    return { id: industry.id, description: industry.description };
                  })}
                  searchable={true}
                  disabled={!editInfo.organisation.industryProperties.editable}
                  error={inputErrors.includes(DetailsField.industryId)}
                />
              ) : (
                getDataItem(projectInformation.organisation.industry)
              )}
            </>
          )}
        </InformationItem>
      </ExtendedCodeSection>
      <ExtendedCodeSection>
        <InformationItem>
          {(projectOrganisationVisibility.customerId || projectOrganisationVisibility.customerName) && (
            <>
              <TitleItem>Customer:</TitleItem>
              {editInfo.organisation.customerProperties.editable ? (
                <EditableSearchableDropdown
                  value={
                    sectionInput.input.organisation.customerId !== null
                      ? sectionInput.input.organisation.customerId
                      : ""
                  }
                  onValueChanged={value =>
                    setSectionInputWithoutInherited(
                      editClone(sectionInput.input, inp => (inp.organisation.customerId = parseNumberOrNull(value)))
                    )
                  }
                  options={
                    !customersData
                      ? []
                      : customersData?.searchCustomers.customers.map(customer => ({
                          id: customer.id,
                          description: combineToString([customer.id, customer.name]) || "",
                        }))
                  }
                  disabled={!editInfo.organisation.customerProperties.editable}
                  error={inputErrors.includes(DetailsField.organisationCustomerId)}
                  searchable={true}
                  inputWidth={390}
                  scrollingDisabled
                  controlledTextInput={customersInput}
                  setControlledTextInput={input => setCustomersInput(input)}
                  loading={customersLoading}
                  dataError={!!customersError}
                  onLoadMore={() =>
                    fetchMore({
                      variables: {
                        num: CUSTOMERS_QUERY_SIZE,
                        searchQuery: customersSearchInput
                          .trim()
                          .split(" ")
                          .filter(term => term.trim().length > 0),
                        offset: customersData?.searchCustomers.customers.length,
                      },
                      updateQuery: (previousResults, { fetchMoreResult }) => {
                        if (!fetchMoreResult) return previousResults;
                        const mergedResults = cloneDeep(previousResults);
                        mergedResults.searchCustomers.customers = mergedResults.searchCustomers.customers.concat(
                          fetchMoreResult.searchCustomers.customers
                        );
                        mergedResults.searchCustomers.availableResults =
                          fetchMoreResult.searchCustomers.availableResults;
                        return mergedResults;
                      },
                    })
                  }
                  morePagesAvailable={
                    customersData
                      ? customersData.searchCustomers.customers.length < customersData.searchCustomers.availableResults
                      : false
                  }
                />
              ) : (
                getDataItem(
                  combineToString([
                    projectOrganisationVisibility.customerId ? projectInformation.organisation.customerId : undefined,
                    projectOrganisationVisibility.customerName
                      ? projectInformation.organisation.customerName
                      : undefined,
                  ])
                )
              )}
            </>
          )}
        </InformationItem>
        <InformationItem>
          {visibilityCheckWithOverride(
            projectOrganisationVisibility.autSiteCode,
            sectionInput.input,
            "autSiteCodeId",
            organisationKeysData.organisationKeys.businessGroups,
            projectInformation.projectVirtualType
          ) && (
            <>
              <TitleItem>AUT site code:</TitleItem>
              {editInfo.organisation.autSiteCodeProperties.editable ? (
                <EditableSearchableDropdown
                  value={
                    sectionInput.input.organisation.autSiteCodeId !== null
                      ? sectionInput.input.organisation.autSiteCodeId
                      : ""
                  }
                  onValueChanged={value =>
                    setSectionInputWithoutInherited(
                      editClone(sectionInput.input, inp => (inp.organisation.autSiteCodeId = parseNumberOrNull(value)))
                    )
                  }
                  options={organisationKeysData.organisationKeys.autSites.map(autSite => {
                    return { id: autSite.id, description: autSite.description };
                  })}
                  searchable={true}
                  maxResults={20}
                  scrollingDisabled
                  disabled={!editInfo.organisation.autSiteCodeProperties.editable}
                  error={inputErrors.includes(DetailsField.autSiteCodeId)}
                />
              ) : (
                getDataItem(projectInformation.organisation.autSiteCode)
              )}
            </>
          )}
        </InformationItem>
      </ExtendedCodeSection>
      <InformationItem>
        {projectOrganisationVisibility.icpTradingPartner && (
          <>
            <TitleItem>ICP trading partner:</TitleItem>
            {editInfo.organisation.icpTradingPartnerProperties.editable ? (
              <EditableSearchableDropdown
                value={
                  sectionInput.input.organisation.icpTradingPartnerId !== null
                    ? sectionInput.input.organisation.icpTradingPartnerId
                    : ""
                }
                onValueChanged={value =>
                  setSectionInputWithoutInherited(
                    editClone(
                      sectionInput.input,
                      inp => (inp.organisation.icpTradingPartnerId = parseNumberOrNull(value))
                    )
                  )
                }
                options={organisationKeysData.organisationKeys.icpTradingPartners.map(icpTradingPartner => {
                  return { id: icpTradingPartner.id, description: icpTradingPartner.description };
                })}
                searchable={false}
                disabled={!editInfo.organisation.icpTradingPartnerProperties.editable}
                error={inputErrors.includes(DetailsField.icpTradingPartnerId)}
                inputWidth="full"
              />
            ) : (
              getDataItem(projectInformation.organisation.icpTradingPartner)
            )}
          </>
        )}
      </InformationItem>
    </DataSection>
  ) : null;
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganisationDataSection);
