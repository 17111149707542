import React from "react";
import styled from "styled-components";
import { EntityTypeId, HierarchyItemEnum, ProjectId, ProjectTypes } from "../../../../../common/types";
import { HierarchyProjectsFilters } from "../../../../../common/hierarchyUtils";
import { SwitchesDropdown, SwitchesDropdownValue, ToggleSwitch } from "../../../../../common/components";

interface AdditionalHeaderButtonsProps {
  itemType: HierarchyItemEnum;
  projectId: ProjectId;
  hierarchyProjectsFilters: HierarchyProjectsFilters;
  setHierarchyProjectsFilters: (filters: HierarchyProjectsFilters) => void;
  displayInactiveItems: boolean;
  setDisplayInactiveItems: (value: boolean) => void;
}

function AdditionalHeaderButtons(props: AdditionalHeaderButtonsProps): React.ReactElement {
  const {
    itemType,
    projectId,
    hierarchyProjectsFilters,
    setHierarchyProjectsFilters,
    displayInactiveItems,
    setDisplayInactiveItems,
  } = props;
  const { filterActive, hideProjectTypes, hideProjectTechnicalTypes } = hierarchyProjectsFilters;

  const onChangeHideProjectTypes = (selectedType: ProjectTypes, checked: boolean) =>
    setHierarchyProjectsFilters({
      filterActive: true,
      hideProjectTypes: checked
        ? hideProjectTypes.filter(type => type !== selectedType)
        : hideProjectTypes.concat([selectedType]),
      hideProjectTechnicalTypes: hideProjectTechnicalTypes,
    });
  const onChangeHideProjectTechnicalTypes = (selectedType: EntityTypeId, checked: boolean) =>
    setHierarchyProjectsFilters({
      filterActive: true,
      hideProjectTypes: hideProjectTypes,
      hideProjectTechnicalTypes: checked
        ? hideProjectTechnicalTypes.filter(type => type !== selectedType)
        : hideProjectTechnicalTypes.concat([selectedType]),
    });

  const dropdownValues: SwitchesDropdownValue[] = [
    {
      id: ProjectTypes.Actual,
      description: "Actual",
      checked: !filterActive || !hideProjectTypes.includes(ProjectTypes.Actual),
      onChange: checked => onChangeHideProjectTypes(ProjectTypes.Actual, checked),
    },
    {
      id: ProjectTypes.Estimate,
      description: "Estimate",
      checked: !filterActive || !hideProjectTypes.includes(ProjectTypes.Estimate),
      onChange: checked => onChangeHideProjectTypes(ProjectTypes.Estimate, checked),
    },
    {
      id: ProjectTypes.Budget,
      description: "Budget",
      checked: !filterActive || !hideProjectTypes.includes(ProjectTypes.Budget),
      onChange: checked => onChangeHideProjectTypes(ProjectTypes.Budget, checked),
    },
    {
      id: ProjectTypes.PT,
      description: "PT",
      checked: !filterActive || !hideProjectTypes.includes(ProjectTypes.PT),
      onChange: checked => onChangeHideProjectTypes(ProjectTypes.PT, checked),
    },
    {
      id: ProjectTypes.AutPT,
      description: "AUT PT",
      checked: !filterActive || !hideProjectTypes.includes(ProjectTypes.AutPT),
      onChange: checked => onChangeHideProjectTypes(ProjectTypes.AutPT, checked),
    },
    {
      id: ProjectTypes.CostPlus,
      description: "Cost Plus",
      checked: !filterActive || !hideProjectTypes.includes(ProjectTypes.CostPlus),
      onChange: checked => onChangeHideProjectTypes(ProjectTypes.CostPlus, checked),
    },
    {
      id: EntityTypeId.AutomaticAdjustment,
      description: "Automatic Adjustment",
      checked: !filterActive || !hideProjectTechnicalTypes.includes(EntityTypeId.AutomaticAdjustment),
      onChange: checked => onChangeHideProjectTechnicalTypes(EntityTypeId.AutomaticAdjustment, checked),
    },
  ];

  return (
    <>
      {projectId === 0 && (
        <ToggleSwitch
          checked={displayInactiveItems}
          onChange={value => setDisplayInactiveItems(value)}
          text={"Display inactive projects"}
        />
      )}
      <DropdownContainer>
        <SwitchesDropdown
          title={"Project filter"}
          values={dropdownValues}
          onChangeAll={checked => {
            if (checked) {
              setHierarchyProjectsFilters({
                filterActive: false,
                hideProjectTypes: [],
                hideProjectTechnicalTypes: [],
              });
            } else {
              setHierarchyProjectsFilters({
                filterActive: true,
                hideProjectTypes: [
                  ProjectTypes.Actual,
                  ProjectTypes.Estimate,
                  ProjectTypes.Budget,
                  ProjectTypes.PT,
                  ProjectTypes.AutPT,
                  ProjectTypes.CostPlus,
                ],
                hideProjectTechnicalTypes: [EntityTypeId.AutomaticAdjustment],
              });
            }
          }}
        />
      </DropdownContainer>
    </>
  );
}

export default AdditionalHeaderButtons;

const DropdownContainer = styled.div`
  text-transform: none;
  right: 0;
`;
