export interface Period {
  year: number;
  month: number;
}

export const periodEquals = (a: Period, b: Period): boolean => a.year === b.year && a.month === b.month;

export const periodGreaterThan = (a: Period, b: Period): boolean =>
  a.year > b.year || (a.year === b.year && a.month > b.month);
export const periodLessThan = (a: Period, b: Period): boolean =>
  a.year < b.year || (a.year === b.year && a.month < b.month);

export const periodSortOrder = (a: Period, b: Period): number =>
  a.year > b.year || (a.year === b.year && a.month > b.month) ? 1 : a.year === b.year && a.month === b.month ? 0 : -1;

export const periodToString = (p: Period): string => `${p.month}/${p.year}`;

export const periodQuarterString = (p: Period): string => `Q${Math.ceil(p.month / 3)}/${p.year}`;

export const periodYearString = (p: Period): string => `${p.year}`;

const periodRegexp = new RegExp("^(\\d{4})-(\\d?\\d)$");
export const parsePeriod = (str: string): Period | null => {
  const result = periodRegexp.exec(str);
  return result && { year: parseInt(result[1]), month: parseInt(result[2]) };
};
export const periodAsString = (p: Period): string => `${p.year}-${p.month}`;

export const reformatPeriodString = (p: string): string => {
  const parsed = parsePeriod(p);
  return parsed ? periodToString(parsed) : "";
};

export const periodAsInt = (p: Period): number => p.year * 100 + p.month;
export const periodFromInt = (p: number): Period => {
  const year = Math.floor(p / 100);
  const month = p % 100;
  return { year, month };
};

export const currentPeriod = (): Period => {
  const date = new Date();
  return { year: date.getUTCFullYear(), month: date.getUTCMonth() + 1 };
};

export const prevPeriod = (p: Period): Period =>
  p.month === 1 ? { year: p.year - 1, month: 12 } : { year: p.year, month: p.month - 1 };
export const nextPeriod = (p: Period): Period =>
  p.month === 12 ? { year: p.year + 1, month: 1 } : { year: p.year, month: p.month + 1 };

// Generate array with all periods from start to end
export const periodRange = (start: Period, end: Period) => {
  const out: Period[] = [];
  for (let iter = { ...start }; !periodGreaterThan(iter, end); iter = nextPeriod(iter)) {
    out.push(iter);
  }
  return out;
};

export const parsePeriodOrFail = (str: string): Period => {
  const period = parsePeriod(str);
  if (period) return period;
  else throw new TypeError(`Couldn't parse period ${str}`);
};
