import {
  HierarchyItem,
  HierarchyTreeNode,
  MainProjectChangeRequest,
  NewNodeRequest,
  NodeRequest,
  ProjectRequest,
  RemoveNodeRequest,
} from "../common/types";

export enum HierarchyActionType {
  SET_PROJECT_PARENT_NODE = "SET_PROJECT_PARENT_NODE",
  SET_PROJECT_HIERARCHY_ADDED_NODES = "SET_PROJECT_HIERARCHY_ADDED_NODES",
  SET_PROJECT_HIERARCHY_NODE = "SET_PROJECT_HIERARCHY_NODE",
  RESET_PROJECT_HIERARCHY = "RESET_PROJECT_HIERARCHY",
  SET_MODIFIED_HIERARCHY = "SET_MODIFIED_HIERARCHY",
  ADD_NEW_NODE = "ADD_NEW_NODE",
  MOVE_NODE = "MOVE_NODE",
  MOVE_PROJECT = "MOVE_PROJECT",
  REMOVE_NODE = "REMOVE_NODE",
  ADD_MAIN_PROJECT_CHANGE = "ADD_MAIN_PROJECT_CHANGE",
}

export type SetProjectHierarchyParentNodeAction = {
  type: HierarchyActionType.SET_PROJECT_PARENT_NODE;
  parentNodeId: number | undefined | null;
  projectId?: number;
};

export type SetProjectHierarchyAddedNodesAction = {
  type: HierarchyActionType.SET_PROJECT_HIERARCHY_ADDED_NODES;
  addedNodes: HierarchyTreeNode[];
};

export type SetProjectHierarchyNodeAction = {
  type: HierarchyActionType.SET_PROJECT_HIERARCHY_NODE;
  nodeValue?: string;
  projectId?: number;
};

export type ResetProjectHierarchyAction = {
  type: HierarchyActionType.RESET_PROJECT_HIERARCHY;
};

export type SetModifiedHierarchyAction = {
  type: HierarchyActionType.SET_MODIFIED_HIERARCHY;
  hierarchy: HierarchyItem | undefined;
};

export type AddNewNodeHierarchyAction = {
  type: HierarchyActionType.ADD_NEW_NODE;
  node: NewNodeRequest;
};

export type MoveNodeHierarchyAction = {
  type: HierarchyActionType.MOVE_NODE;
  node: NodeRequest;
};

export type MoveProjectHierarchyAction = {
  type: HierarchyActionType.MOVE_PROJECT;
  project: ProjectRequest;
};

export type RemoveNodeHierarchyAction = {
  type: HierarchyActionType.REMOVE_NODE;
  node: RemoveNodeRequest;
};

export type AddMainProjectChangeAction = {
  type: HierarchyActionType.ADD_MAIN_PROJECT_CHANGE;
  mainProjectChange: MainProjectChangeRequest;
};

export type ProjectHierarchyAction =
  | SetProjectHierarchyParentNodeAction
  | SetProjectHierarchyAddedNodesAction
  | SetProjectHierarchyNodeAction
  | ResetProjectHierarchyAction
  | SetModifiedHierarchyAction
  | AddNewNodeHierarchyAction
  | MoveNodeHierarchyAction
  | MoveProjectHierarchyAction
  | RemoveNodeHierarchyAction
  | AddMainProjectChangeAction;

export const setProjectHierarchyParentNodeId = (
  parentNodeId: number | undefined | null,
  projectId?: number
): ProjectHierarchyAction => ({
  type: HierarchyActionType.SET_PROJECT_PARENT_NODE,
  parentNodeId,
  projectId,
});

export const setProjectHierarchyAddedNodes = (addedNodes: HierarchyTreeNode[]): ProjectHierarchyAction => ({
  type: HierarchyActionType.SET_PROJECT_HIERARCHY_ADDED_NODES,
  addedNodes,
});

export const setProjectHierarchyNode = (
  nodeValue: string | undefined,
  projectId: number | undefined
): ProjectHierarchyAction => ({
  type: HierarchyActionType.SET_PROJECT_HIERARCHY_NODE,
  nodeValue,
  projectId,
});

export const resetProjectHierarchy = (): ProjectHierarchyAction => ({
  type: HierarchyActionType.RESET_PROJECT_HIERARCHY,
});

export const setModifiedHierarchy = (hierarchy: HierarchyItem | undefined): ProjectHierarchyAction => ({
  type: HierarchyActionType.SET_MODIFIED_HIERARCHY,
  hierarchy,
});

export const addNewNode = (node: NewNodeRequest): ProjectHierarchyAction => ({
  type: HierarchyActionType.ADD_NEW_NODE,
  node,
});

export const moveNode = (node: NodeRequest): ProjectHierarchyAction => ({
  type: HierarchyActionType.MOVE_NODE,
  node,
});

export const moveProject = (project: ProjectRequest): ProjectHierarchyAction => ({
  type: HierarchyActionType.MOVE_PROJECT,
  project,
});

export const removeNode = (node: RemoveNodeRequest): ProjectHierarchyAction => ({
  type: HierarchyActionType.REMOVE_NODE,
  node,
});

export const addMainProjectChange = (mainProjectChange: MainProjectChangeRequest): ProjectHierarchyAction => ({
  type: HierarchyActionType.ADD_MAIN_PROJECT_CHANGE,
  mainProjectChange,
});
