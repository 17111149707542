import React, { useCallback, useRef, useState } from "react";
import LaborTable from "./LaborTable";
import ProductionTable from "./ProductionTable";
import PurchasesTable from "./PurchasesTable";
import OtherTable from "./OtherTable";
import { Tabs } from "./CostItemDetails";
import { ProjectCostItemDetailsResult } from "../types";
import { ProjectVirtualType } from "../../../../../common/types";
import styled from "styled-components";
import { filterGreen, settingGreen } from "../../../../../common/colors";
import WarehouseTable from "./WarehouseTable";

export interface CostItemDetailsTableProps {
  data: ProjectCostItemDetailsResult;
  projectVirtualType: ProjectVirtualType;
  popupWindow: boolean;
}

export interface SectionTabsProps {
  selectedTab: number;
  setTab: React.Dispatch<React.SetStateAction<Tabs>>;
  popupWindow: boolean;
}

const SectionTabs = (props: SectionTabsProps): React.ReactElement => {
  const { selectedTab, setTab, popupWindow } = props;
  const laborTabRef = useRef<HTMLButtonElement>(null);
  const productionTabRef = useRef<HTMLButtonElement>(null);
  const purchaseTabRef = useRef<HTMLButtonElement>(null);
  const otherTabRef = useRef<HTMLButtonElement>(null);
  const warehouseTabRef = useRef<HTMLButtonElement>(null);

  const changeTab = useCallback(
    (tab: Tabs, ref: React.RefObject<HTMLButtonElement>) => {
      setTab(tab);
      setTimeout(() => {
        if (ref.current) ref.current.scrollIntoView({ behavior: "smooth" });
      }, 1);
    },
    [setTab]
  );

  return (
    <TabContainer popupWindow={popupWindow}>
      <Tab selected={selectedTab === Tabs.Labor} ref={laborTabRef} onClick={() => changeTab(Tabs.Labor, laborTabRef)}>
        LABOR
      </Tab>
      <Tab
        selected={selectedTab === Tabs.Production}
        ref={productionTabRef}
        onClick={() => changeTab(Tabs.Production, productionTabRef)}
      >
        PRODUCTION
      </Tab>
      <Tab
        selected={selectedTab === Tabs.Purchases}
        ref={purchaseTabRef}
        onClick={() => changeTab(Tabs.Purchases, purchaseTabRef)}
      >
        PURCHASES
      </Tab>
      <Tab selected={selectedTab === Tabs.Other} ref={otherTabRef} onClick={() => changeTab(Tabs.Other, otherTabRef)}>
        OTHER
      </Tab>
      <Tab
        selected={selectedTab === Tabs.Warehouse}
        ref={warehouseTabRef}
        onClick={() => changeTab(Tabs.Warehouse, warehouseTabRef)}
      >
        WAREHOUSE
      </Tab>
    </TabContainer>
  );
};

function CostItemDetailsTable(props: CostItemDetailsTableProps): React.ReactElement {
  const { data, projectVirtualType, popupWindow } = props;
  const [tab, setTab] = useState(Tabs.Labor);
  return (
    <TableContainer popupWindow={popupWindow}>
      <SectionTabs selectedTab={tab} setTab={setTab} popupWindow={popupWindow} />
      <ContentContainer popupWindow={popupWindow}>
        {tab === Tabs.Labor && (
          <LaborTable
            laborItems={data.projectCostsDetails.labor}
            projectVirtualType={projectVirtualType}
            popupWindow={popupWindow}
          />
        )}
        {tab === Tabs.Production && (
          <ProductionTable
            productionItems={data.projectCostsDetails.production}
            projectVirtualType={projectVirtualType}
            popupWindow={popupWindow}
          />
        )}
        {tab === Tabs.Purchases && (
          <PurchasesTable
            purchasesItems={data.projectCostsDetails.purchases}
            projectVirtualType={projectVirtualType}
            popupWindow={popupWindow}
          />
        )}
        {tab === Tabs.Other && <OtherTable otherItems={data.projectCostsDetails.other} popupWindow={popupWindow} />}
        {tab === Tabs.Warehouse && (
          <WarehouseTable
            warehouseItems={data.projectCostsDetails.warehouse}
            projectVirtualType={projectVirtualType}
            popupWindow={popupWindow}
          />
        )}
      </ContentContainer>
    </TableContainer>
  );
}

export default CostItemDetailsTable;

const TableContainer = styled.div<{ popupWindow: boolean }>`
  box-sizing: border-box;
  border: 1px solid ${settingGreen};
  display: flex;
  flex-direction: column;
  max-height: fit-content;
  width: 100%;
  ${({ popupWindow }) => popupWindow && `margin-top: 76px;`}
  min-height: 200px;
`;

const TabContainer = styled.div<{ popupWindow: boolean }>`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid ${settingGreen};
  position: sticky;
  top: 0;
  width: 100%;
  ${({ popupWindow }) => popupWindow && "z-index: 999;"}
  background-color: #fff;
`;

const ContentContainer = styled.div<{ popupWindow: boolean }>`
  display: flex;
  flex-direction: column;
  ${({ popupWindow }) => popupWindow && "overflow-y: scroll; height: fit-content; max-height: 80vh"}
`;

const Tab = styled.button<{ selected?: boolean }>`
  display: flex;
  width: 80px;
  height: 30px;
  align-items: center;
  justify-content: center;
  font-size: 9px;
  font-weight: 600;
  color: ${settingGreen};
  padding: 0;
  margin-top: 1px;
  cursor: pointer;
  outline: none;
  background-color:  ${({ selected }) => (selected ? `${filterGreen}` : `transparent`)}
  border: 0;
  border-right: 1px solid ${settingGreen};
`;
