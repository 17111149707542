import * as React from "react";
import onClickOutside, { HandleClickOutside, InjectedOnClickOutProps } from "react-onclickoutside";
import styled from "styled-components";
import { faChevronDown, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { valmetGreyLight, valmetGreyBorder, filterGreen, settingGreen } from "../../../../common/colors";
import { FilterId, FilterOption, NumberRange } from "../../../../common/types";
import { includes } from "lodash";
import { CollapseButton, ValueButton } from "../../../../common/components";

type Props = {
  filter: FilterId;
  values: FilterOption[];
  selections?: string | number | number[] | string[] | NumberRange;
  onFilterSelected: (filter: FilterId, value: string | number) => void;
  disableIcon?: boolean;
  singleSelection?: boolean;
  onClose?: () => void;
  maxResults?: number;
  id?: string;
  extraMargin?: boolean;
  marginLeft?: string;
};

type State = {
  isOpen: boolean;
};

class Dropdown extends React.Component<
  Props & InjectedOnClickOutProps & HandleClickOutside<React.MouseEventHandler>,
  State
> {
  state: State = { isOpen: false };

  toggle = (): void => {
    if (this.state.isOpen) {
      this.props.disableOnClickOutside();
      if (this.props.onClose) this.props.onClose();
    } else {
      this.props.enableOnClickOutside();
    }

    this.setState(state => ({ isOpen: !state.isOpen }));
  };

  handleClickOutside = (): void => {
    this.props.disableOnClickOutside();
    this.setState({ isOpen: false });
    if (this.props.onClose) this.props.onClose();
  };

  open = (): void => {
    this.props.enableOnClickOutside();

    this.setState({ isOpen: true });
  };

  render(): React.ReactElement {
    const {
      values,
      onFilterSelected,
      filter,
      selections,
      disableIcon,
      singleSelection,
      maxResults,
      id,
      extraMargin,
      marginLeft,
    } = this.props;
    const slicedValues = maxResults !== undefined && maxResults < values.length ? values.slice(0, maxResults) : values;
    return (
      <Wrapper>
        {!disableIcon && (
          <CollapseButton onClick={this.toggle} title={"Select filter"} fontSize={"14px"}>
            <FontAwesomeIcon icon={faChevronDown} size="lg" color={valmetGreyLight} />
          </CollapseButton>
        )}
        {this.state.isOpen && (
          <DropdownContent
            onClick={() => {
              if (singleSelection) this.handleClickOutside();
            }}
            extraMargin={extraMargin}
            marginLeft={marginLeft}
          >
            {slicedValues.map(value => (
              <ValueButton key={value.id} onClick={() => onFilterSelected(filter, value.id)} id={id}>
                {value.description}
                <SelectionMarker>
                  {((Array.isArray(selections) && includes(selections, value.id)) || value.id === selections) && (
                    <FontAwesomeIcon icon={faCheck} size="sm" />
                  )}
                </SelectionMarker>
              </ValueButton>
            ))}
            {maxResults !== undefined && maxResults < values.length && (
              <ResultsTextContainer>Search for more results.</ResultsTextContainer>
            )}
          </DropdownContent>
        )}
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  display: inline-block;
  > button:first-child {
    min-width: 40px;
  }
`;

const DropdownContent = styled.div<{ extraMargin?: boolean; marginLeft?: string }>`
  background: white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  position: absolute;
  display: flex;
  flex-direction: column;

  border: 1px solid ${valmetGreyBorder};
  z-index: 500;
  button:hover {
    background: ${filterGreen};
  }
  margin-left: ${({ extraMargin, marginLeft }) => (marginLeft ? marginLeft : extraMargin ? "13px" : "0")};
`;

const SelectionMarker = styled.div`
  color: ${settingGreen};
  margin-left: 6px;
`;

const ResultsTextContainer = styled.div`
  font-size: 10px;
  padding: 6px;
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default onClickOutside<any, Props>(Dropdown);
