import React, { useEffect } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { Currency, AppState, CurrenciesResult } from "../../common/types";
import { Dispatch } from "redux";
import { setSelectedCurrency } from "../../actions/settingsActions";
import { useQuery } from "@apollo/client/react/hooks";
import CurrencyDropdown from "./CurrencyDropdown";
import { DEFAULT_CURRENCY_CODE } from "../../common/constants";
import { endRecognitionsEditing } from "../../actions/recognitionsEditActions";
import { gql } from "@apollo/client";

interface CurrencySelectorProps {
  selectedCurrency?: Currency;
  editingRecPlan: boolean;
}

const mapStateToProps = (state: AppState): CurrencySelectorProps => {
  return {
    selectedCurrency: state.settingsState.selectedCurrency,
    editingRecPlan: state.recognitionEditState.editing,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    selectCurrency: (currency: Currency) => {
      dispatch(endRecognitionsEditing); // Reset recognition editing when currency is changed
      dispatch(setSelectedCurrency(currency));
    },
  };
};

const GET_CURRENCIES = gql`
  query GetCurrencies {
    currencies {
      id
      code
      description
    }
  }
`;

function CurrencySelector(props: CurrencySelectorProps & ReturnType<typeof mapDispatchToProps>): React.ReactElement {
  const { selectedCurrency, selectCurrency, editingRecPlan } = props;
  const currencyData = useQuery<CurrenciesResult>(GET_CURRENCIES, {}).data;
  const currencies = currencyData ? currencyData.currencies : [];

  useEffect(() => {
    if (currencies.length && !selectedCurrency) {
      let currency;
      for (let i = 0; i < currencies.length; i++) {
        if (currencies[i].code === DEFAULT_CURRENCY_CODE) {
          currency = currencies[i];
          break;
        }
      }
      selectCurrency(currency ? currency : currencies[0]);
    }
  }, [currencies, selectedCurrency, selectCurrency]);

  return (
    <Container>
      <CurrencyDropdown
        selections={currencies}
        onValueSelected={currency => selectCurrency(currency)}
        value={selectedCurrency}
        disabled={editingRecPlan}
      />
    </Container>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CurrencySelector);

const Container = styled.div`
  margin-right: 20px;
  margin-left: 20px;
  display: flex;
  flex-direction: row;
  flex-grow: 0.5;
  justify-content: flex-end;
`;
