import React, { useEffect, useState } from "react";
import {
  CompaniesResult,
  CompanyCode,
  ProjectRelatingKey,
  ProjectRelationInput,
  ProjectRelationType,
  ProjectRelationTypeItem,
  RelationProjectSearchResult,
  SourceSystem,
} from "../../../../../common/types";
import { useQuery } from "@apollo/client/react/hooks";
import { GET_IN_USE_RELATION_PROJECTS, SEARCH_RELATION_PROJECTS } from "./queries";
import { parseNumberOrNull } from "../../../../../common/utils";
import { IconButton } from "../../../../../common/components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { valmetGreyBorder } from "../../../../../common/colors";
import EditableText from "../EditableComponents/EditableText";
import EditableSearchableDropdown from "../EditableComponents/EditableSearchableDropdown";
import { isDigit } from "./EditDetails";
import { DropdownItem } from "../EditableComponents/EditableSearchableDropdown/EditableSearchableDropdown";

const filterString = (s: string, p: (c: string) => boolean): string => {
  let result = "";
  for (let i = 0; i < s.length; i++) {
    if (p(s[i])) result += s[i];
  }
  return result;
};

const validateAllDigits = (s: string): string | null => {
  for (let i = 0; i < s.length; i++) {
    if (!isDigit(s[i])) return "Only numbers allowed";
  }
  return null;
};

const validateProjectLineInUse = (
  project: string | null,
  line: string | null,
  inUseProjects: RelationProjectSearchResult[] | undefined
): [string, string | null] => {
  return project !== null &&
    line !== null &&
    inUseProjects !== undefined &&
    inUseProjects.some(p => p.project == project && p.line == line)
    ? ["", `Combination of project ${project} and line ${line} is already in use`]
    : [line || "", null];
};

type SearchProjectsResult = { searchRelationProjects: RelationProjectSearchResult[] };

const relatingKeySelection = (
  company: CompanyCode | null,
  projectSearch: string,
  setProjectSearch: (v: string) => void,
  projectsSearchResults: DropdownItem[],
  projectsSearchParts: DropdownItem[],
  projectsSearchLoading: boolean,
  sectionInputItem: ProjectRelationInput,
  setSectionInputItem: (relationInputItem: ProjectRelationInput) => void,
  relationTypeItem: ProjectRelationTypeItem,
  showHeader: boolean
) => {
  const renderRelatingKeyInput = (relkey: ProjectRelatingKey, input: string | undefined, index: number) => {
    const setRelatingKey = (value: string) => {
      const relatingKeys: string[] = [];
      for (let i = 0; i < index; i++) relatingKeys.push(sectionInputItem.relatingKeys[i]);
      relatingKeys[index] = value;
      console.log("Set relating key for " + index + ":", value, relatingKeys);
      setSectionInputItem({ ...sectionInputItem, relatingKeys });
    };
    switch (relkey.kind) {
      case "BaanProject":
      case "BaanPcsProject":
      case "BaanSoProject":
      case "LnProject": {
        console.log("Render " + index + ":", input, projectSearch);
        return (
          <InformationItem key={`relkey-${index}`} hidden={sectionInputItem.company === null}>
            {showHeader && <TitleItem>{relkey.desc}:</TitleItem>}
            <EditableSearchableDropdown
              value={input || ""}
              controlledTextInput={projectSearch}
              setControlledTextInput={setProjectSearch}
              onValueChanged={value => setRelatingKey(value || "")}
              options={projectsSearchResults}
              searchable={true}
              disabled={!sectionInputItem.relationType}
              error={sectionInputItem.relatingKeys[index] === undefined || sectionInputItem.relatingKeys[index] === ""}
              maxResults={20}
              inputWidth={"smallest"}
              loading={projectsSearchLoading}
            />
          </InformationItem>
        );
      }
      case "BaanProjectPart":
        return (
          <InformationItem key={`relkey-${index}`} hidden={sectionInputItem.company === null}>
            {showHeader && <TitleItem>Part:</TitleItem>}
            <EditableSearchableDropdown
              value={sectionInputItem.relatingKeys[1] !== undefined ? sectionInputItem.relatingKeys[1] : ""}
              onValueChanged={value => setRelatingKey(value || "")}
              options={projectsSearchParts}
              searchable={true}
              disabled={
                !sectionInputItem.relationType || !sectionInputItem.company || !sectionInputItem.relatingKeys[0]
              }
              error={sectionInputItem.relatingKeys[1] === undefined || sectionInputItem.relatingKeys[1] === ""}
              maxResults={20}
              inputWidth={"smallest"}
            />
          </InformationItem>
        );
      case "BaanSoLine":
        return (
          <InformationItem key={`relkey-${index}`} hidden={sectionInputItem.company === null}>
            {showHeader && <TitleItem>{relkey.desc}</TitleItem>}
            <EditableSearchableDropdown
              value={sectionInputItem.relatingKeys[1] !== undefined ? sectionInputItem.relatingKeys[1] : ""}
              onValueChanged={value => setRelatingKey(value || "")}
              options={projectsSearchParts}
              searchable={true}
              disabled={
                !sectionInputItem.relationType || !sectionInputItem.company || !sectionInputItem.relatingKeys[0]
              }
              error={sectionInputItem.relatingKeys[1] === undefined || sectionInputItem.relatingKeys[1] === ""}
              maxResults={20}
              inputWidth={"smallest"}
            />
          </InformationItem>
        );
      case "FreeText":
        return (
          <InformationItem key={`relkey-${index}`} hidden={sectionInputItem.company === null}>
            {showHeader && <TitleItem>{relkey.desc}:</TitleItem>}
            <EditableText
              id={"relatingKey" + index}
              value={input || ""}
              onValueChanged={value => setRelatingKey(value || "")}
              error={sectionInputItem.relatingKeys[index] === ""}
              inputSize={"smallest"}
            />
          </InformationItem>
        );
      case "Numbers":
        return (
          <InformationItem key={`relkey-${index}`} hidden={sectionInputItem.company === null}>
            {showHeader && <TitleItem>{relkey.desc}:</TitleItem>}
            <EditableText
              id={"relatingKey" + index}
              value={input || ""}
              onValueChanged={value => setRelatingKey(value || "")}
              validate={value => [filterString(value, isDigit), validateAllDigits(value)]}
              error={sectionInputItem.relatingKeys[index] === ""}
              inputSize={"smallest"}
            />
          </InformationItem>
        );
    }
  };
  return relationTypeItem.relatingKeys.map((rk, i) => {
    return renderRelatingKeyInput(rk, sectionInputItem.relatingKeys[i], i);
  });
};

export interface EditableRelationsDetailsRowProps {
  sectionInputItem: ProjectRelationInput;
  companiesData: CompaniesResult;
  relationTypeItems: ProjectRelationTypeItem[];
  showHeader: boolean;
  setSectionInputItem: (relationInputItem: ProjectRelationInput) => void;
  onRemoveSectionInputItem: () => void;
  noBorder?: boolean;
}

function EditableRelationsDetailsRow(props: EditableRelationsDetailsRowProps): React.ReactElement {
  const {
    sectionInputItem,
    setSectionInputItem,
    onRemoveSectionInputItem,
    companiesData,
    relationTypeItems,
    showHeader,
    noBorder,
  } = props;
  const { projectRelationId, relationType, company, relatingKeys } = sectionInputItem;
  const isBaanRel = (relationType: ProjectRelationType): boolean =>
    relationTypeItems.find(item => item.relationType === relationType && item.sourceSystem === SourceSystem.Baan) !==
    undefined;

  const selectedRelationTypeItem = relationTypeItems.find(item => item.relationType === relationType);

  const companies = !selectedRelationTypeItem
    ? []
    : (selectedRelationTypeItem.relationType === "BAAN PCS PROJECT"
        ? companiesData.baanPcsCompanies
        : selectedRelationTypeItem.relationType === "BAAN SALES ORDER"
        ? companiesData.baanSoCompanies
        : companiesData.companies
      )
        .filter(c => c.sourceSystem === selectedRelationTypeItem.sourceSystem)
        .map(company => {
          return {
            id: company.code,
            description: company.code.toString(),
          };
        });

  // Auto select, if only one possible company value.
  useEffect(() => {
    if (companies.length === 1) {
      const company = companies[0];
      if (company.id !== sectionInputItem.company) {
        setSectionInputItem({
          ...sectionInputItem,
          company: company.id,
        });
      }
    }
  }, [companies]);

  const relationTypes = relationTypeItems.map(item => ({
    id: item.relationType,
    description: item.displayName,
  }));

  const [projectSearch, setProjectSearch] = useState<string>("");
  const { data: projectsSearchData, loading: projectsSearchLoading } = useQuery<SearchProjectsResult>(
    SEARCH_RELATION_PROJECTS,
    {
      variables: {
        relationType: selectedRelationTypeItem?.relationType,
        company,
        searchText: projectSearch,
      },
      skip: selectedRelationTypeItem === undefined || company === null,
    }
  );

  const projectSearchResults =
    projectsSearchData?.searchRelationProjects.map(v => ({ id: v.project, description: v.project })) || [];

  const projectSearchParts =
    projectsSearchData?.searchRelationProjects.flatMap(result =>
      result.project === relatingKeys[0] ? result.subProjects.map(v => ({ id: v, description: v })) : []
    ) || [];

  const onChangeRelationType = (value: ProjectRelationType | null) => {
    const oldRelationType = relationType;
    const oldRelationTypeItem = relationType ? relationTypeItems.find(i => i.relationType === oldRelationType) : null;
    const relationTypeItem = value ? relationTypeItems.find(i => i.relationType === value) : null;

    if (value === null) {
      setSectionInputItem({
        projectRelationId: projectRelationId,
        relationType: null,
        company: null,
        relatingKeys: [],
      });
    } else if (
      relationTypeItem &&
      oldRelationTypeItem &&
      relationTypeItem.sourceSystem !== oldRelationTypeItem.sourceSystem
    ) {
      // Source systems are different: need to clear company and relating keys.
      setSectionInputItem({
        projectRelationId: projectRelationId,
        relationType: value,
        company: null,
        relatingKeys: [],
      });
    } else {
      // Source systems are same: no need to clear company, but need to clear relating keys.
      setSectionInputItem({ ...sectionInputItem, relationType: value, relatingKeys: [] });
    }
    setProjectSearch("");
  };

  console.log("relating keys 0", relatingKeys[0]);

  return (
    <RelationRow className={"EditableRelationRow"}>
      <RelationSection border={!noBorder && showHeader}>
        <InformationItem>
          {showHeader && <TitleItem>Type:</TitleItem>}
          <EditableSearchableDropdown
            value={relationType !== null ? relationType : ""}
            onValueChanged={onChangeRelationType}
            options={relationTypes}
            searchable={false}
            error={relationType === null}
            inputWidth={"small"}
          />
        </InformationItem>
        <InformationItem>
          {showHeader && <TitleItem>Company:</TitleItem>}

          <EditableSearchableDropdown
            value={company !== null ? company : ""}
            onValueChanged={value => {
              setProjectSearch("");
              setSectionInputItem({ ...sectionInputItem, company: parseNumberOrNull(value), relatingKeys: [] });
            }}
            options={companies}
            searchable={true}
            disabled={!relationType}
            error={company === null}
            maxResults={20}
            inputWidth={"smallest"}
          />
        </InformationItem>
        {selectedRelationTypeItem &&
          relatingKeySelection(
            company,
            projectSearch,
            setProjectSearch,
            projectSearchResults,
            projectSearchParts,
            projectsSearchLoading,
            sectionInputItem,
            setSectionInputItem,
            selectedRelationTypeItem,
            showHeader
          )}
      </RelationSection>
      <ButtonContainer border={showHeader}>
        <IconButton onClick={() => onRemoveSectionInputItem()} title={"Remove relation"} fontSize={"16px"}>
          <FontAwesomeIcon icon={faMinusCircle} size="1x" color={"red"} />
        </IconButton>
      </ButtonContainer>
    </RelationRow>
  );
}

export default EditableRelationsDetailsRow;

const RelationSection = styled.div<{ border?: boolean }>`
  display: grid;
  grid-template-columns: 1.1fr 1fr 1fr 1fr 1fr;
  ${({ border }) => `border-top: ${border ? `1px solid ${valmetGreyBorder}` : "0"}`}
  ${({ border }) => `padding-top: ${border ? `10px` : "0"}`}
`;

const RelationRow = styled.div`
  display: flex;
`;

const InformationItem = styled.div<{ hidden?: boolean }>`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  ${({ hidden }) => `visibility: ${hidden ? "hidden" : "visible"}`}
`;

const DataItem = styled.div<{ emphasize?: boolean }>`
  font-weight: bold;
  font-size: ${({ emphasize }) => (emphasize ? "22px" : "14px")};
`;
const TitleItem = styled.div`
  font-size: 14px;
  margin-bottom: 10px;
`;

const ButtonContainer = styled.div<{ margin?: boolean; border?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 10px;
  margin-bottom: ${({ margin }) => (margin ? "20px;" : `0px;`)};
  ${({ border }) => `padding-top: ${border ? `10px` : "0"}`}
`;
