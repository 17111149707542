import React from "react";
import styled from "styled-components";

export const DateValue = (props: { value: string | undefined }) => (
  <DataItem>{props.value ? props.value : "-"}</DataItem>
);

export const InformationItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const DataItem = styled.div<{ emphasize?: boolean }>`
  font-weight: bold;
  font-size: 14px;
`;

export const TitleItem = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 14px;
  margin-bottom: 4px;
`;
