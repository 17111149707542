import styled from "styled-components";
import { settingGreen } from "../../../../common/colors";

export type TextItem = string;
export interface KeywordItem {
  kw: string;
  tooltip?: string;
}
export type StringItem = TextItem | KeywordItem;
export type KeywordString = StringItem[];

interface LogMessageHeader {
  type: "header";
  header: string;
}
interface LogMessageParagraph {
  type: "paragraph";
  paragraph: KeywordString;
}
interface LogMessageList {
  type: "list";
  items: KeywordString[];
}

export type LogMessageItem = LogMessageHeader | LogMessageParagraph | LogMessageList;

export interface ParsedLogEvent {
  actionTypeId: number;
  logDateTime: string;
  message: LogMessageItem[];
  messageString: string;
  size: number; // roughly how many lines the log content is
  batchRid: number | null;
  executionTimeSeconds: number | null;
  user: string;
}

export interface ParsedMonthlyLogs {
  period: string;
  logs: ParsedLogEvent[];
}

export interface ParsedYearlyLogs {
  period: string;
  subGroups: ParsedMonthlyLogs[];
}

export const StatusMessage = styled.div`
  display: flex;
  justify-content: center;
  border: 1px solid ${settingGreen};
  padding: 10px 0;
  background-color: white;
`;
