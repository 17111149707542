import { ApolloClient } from "@apollo/client";
import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { useApolloClient, useQuery } from "@apollo/client/react/hooks";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  editBlue,
  greyTransparent,
  projectDetailsYellow,
  valmetGreyBorder,
  valmetGreyHeader,
} from "../../../common/colors";
import { ActionButton, ButtonContainer } from "../../../common/components";
import { HierarchyItemEnum, NotificationField, NotificationMessage, ProjectId } from "../../../common/types";
import { ProjectHeaderMinimizedHeight } from "./constants";
import { DISMISS_PROJECT_NOTIFICATION, GET_PROJECT_NOTIFICATIONS } from "./queries";

type NotificationsViewProps = { itemType: HierarchyItemEnum; id: number };

function dismissProjectNotification(
  client: ApolloClient<Record<string, unknown>>,
  projectId: ProjectId,
  notificationField: NotificationField
) {
  return client
    .mutate<{ dismissProjectNotification: { notifications: NotificationMessage[] }; __typename: string }>({
      mutation: DISMISS_PROJECT_NOTIFICATION,
      variables: {
        projectId,
        notificationField,
      },
    })
    .then(result => {
      const { data } = result;
      if (data) {
        type Q = { projectNotifications: { notifications: NotificationMessage[] } };
        const dataToWrite = {
          projectNotifications: { ...data.dismissProjectNotification },
          __typename: data.__typename,
        };
        client.writeQuery<Q>({
          query: GET_PROJECT_NOTIFICATIONS,
          variables: { projectId },
          data: dataToWrite,
        });
      }
    });
}

function NotificationsView(props: NotificationsViewProps) {
  const { itemType, id } = props;

  const client = useApolloClient() as ApolloClient<Record<string, unknown>>;
  const [open, setOpen] = useState(false);
  const [hidden, setHidden] = useState(true);
  const [dismissingNotification, setDismissingNotification] = useState<NotificationField | null>(null);
  const [dismissableNotification, setDismissableNotification] = useState<NotificationMessage | null>(null);
  type NotificationsQueryResult = { projectNotifications: { notifications: NotificationMessage[] } | null };

  const { data, loading, error } = useQuery<NotificationsQueryResult>(GET_PROJECT_NOTIFICATIONS, {
    variables: { projectId: id },
    skip: itemType !== HierarchyItemEnum.Project,
  });

  useEffect(() => {
    if (data && data.projectNotifications && data.projectNotifications.notifications.length === 0) setHidden(true);
    else setTimeout(() => setHidden(false), 1000);
  }, [data]);

  if (loading || (data && data.projectNotifications === null)) return null;

  const dismissButton = (message: NotificationMessage) => {
    if (dismissingNotification === message.notificationField) {
      return <LoadingDots />;
    } else {
      return (
        <ActionButton
          color={editBlue}
          onClick={() => {
            setDismissableNotification(message);
          }}
        >
          Dismiss
        </ActionButton>
      );
    }
  };

  if (error !== undefined) {
    return <div>Error while loading notifications</div>;
  } else if (hidden) {
    return (
      <NotificationsContainer open={false} hide={hidden}>
        <NotificationsHeader onClick={() => setOpen(!open)}>
          <NotificationsTitle>
            <FontAwesomeIcon icon={faExclamationCircle} size="1x" />
            notifications
          </NotificationsTitle>
        </NotificationsHeader>
      </NotificationsContainer>
    );
  } else if (data !== undefined && data.projectNotifications) {
    const {
      projectNotifications: { notifications },
    } = data;
    return (
      <NotificationsContainer open={open} hide={hidden}>
        <NotificationsHeader onClick={() => setOpen(!open)}>
          <NotificationsTitle>
            <FontAwesomeIcon icon={faExclamationCircle} size="1x" />
            {notifications.length} notifications
          </NotificationsTitle>
        </NotificationsHeader>
        <NotificationsContent open={open} maxHeight={notifications.length * 32 + "px"}>
          {(open || true) &&
            notifications.map(n => {
              return (
                <NotificationRow key={n.notificationField.toString()}>
                  {n.message}
                  {n.userCanDismiss && dismissButton(n)}
                </NotificationRow>
              );
            })}
        </NotificationsContent>
        {dismissableNotification && (
          <DismissDialognWrapper>
            <DismissDialogContainer>
              <DialogTitle>Dismiss notification?</DialogTitle>
              <DialogInfo>Are you sure you want to dismiss notification</DialogInfo>
              <DialogInfo>{`"${dismissableNotification.message}" ?`}</DialogInfo>
              <ButtonContainer>
                <ActionButton
                  onClick={() => {
                    setDismissableNotification(null);
                    setDismissingNotification(dismissableNotification.notificationField);
                    dismissProjectNotification(client, id, dismissableNotification.notificationField).then(() => {
                      setDismissingNotification(null);
                    });
                  }}
                >
                  Dismiss
                </ActionButton>
                <ActionButton onClick={() => setDismissableNotification(null)}>Cancel</ActionButton>
              </ButtonContainer>
            </DismissDialogContainer>
          </DismissDialognWrapper>
        )}
      </NotificationsContainer>
    );
  }
  return null;
}

export default NotificationsView;

const transition = "0.36s";

const NotificationsContainer = styled.div<{ open: boolean; hide: boolean }>`
  box-sizing: border-box;
  z-index: 1500;
  position: absolute;
  overflow: hidden;
  right: 0;
  border: ${({ hide }) => (hide ? "none" : `1px solid ${valmetGreyBorder}`)};
  width: ${({ hide, open }) => (hide ? "0" : open ? "380px" : "160px")};
  transition: width ${transition}, right 0.6s;
`;

const NotificationsHeader = styled.div`
  box-sizing: border-box;
  padding: 12px;
  background-color: ${projectDetailsYellow};
  color: ${valmetGreyHeader};
  height: ${ProjectHeaderMinimizedHeight};
  cursor: pointer;
`;

const NotificationsTitle = styled.div`
  display: flex;
  flex-direction: row;
  width: 130px;
  justify-content: space-between;
  align-items: center;
`;

const NotificationsContent = styled.div<{ open: boolean; maxHeight: string }>`
  overflow: hidden;
  padding: 0px;
  background-color: white;
  max-height: ${({ open, maxHeight }) => (open ? maxHeight : "0")};
  /*transform: ${({ open }) => (open ? "scaleY(1.0)" : "scaleY(0.0)")};*/
  transition: ${transition};
`;

const NotificationRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
  height: 32px;
  padding: 4px;
  align-items: center;
  font-size: 12px;
  margin-left: 12px;
`;

const dotsAnimation = keyframes`
  0% { content: ""; }
  20% { content: "."; }
  40% { content: ".."; }
  60% { content: "..."; }
  80% { content: ""; }
`;

const LoadingDots = styled.span`
  &:before {
    animation: ${dotsAnimation} 0.8s linear infinite;
    content: "";
  }
`;

const DismissDialognWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  z-index: 1200;
  background: ${greyTransparent};
  overflow-y: hidden;
  height: 100vh;
`;

const DismissDialogContainer = styled.div`
  background: white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  border: 1px solid ${valmetGreyBorder};
  z-index: 1500;
  font-size: 24px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 25px;
  padding-right: 25px;
  align-self: flex-start;
  margin-top: 150px;
  width: 675px;
`;

const DialogTitle = styled.h1`
  font-size: 24px;
`;

const DialogInfo = styled.div`
  font-size: 16px;
  margin-top: 20px;
`;
