import React, { useCallback, useEffect, useState } from "react";
import { Action } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { useMutation } from "@apollo/client/react/hooks";
import {
  BasicDataSectionType,
  getEmptyProjectInformationSectionInput,
  getEmptyReportingRelationsSectionInput,
} from "../../../../../common/constants";
import Section from "../Section";
import styled from "styled-components";
import { defaultGrey } from "../../../../../common/colors";
import {
  AppState,
  ProjectId,
  ProjectReportingRelations,
  ProjectReportingRelationsSaveResult,
  ProjectReportingRelationsVisibility,
  ProjectReportingRelationsInput,
  ReportingRelationsSectionInput,
} from "../../../../../common/types";
import { setReportingRelations } from "../../../../../actions/projectActions";
import ViewDetails from "./ViewDetails";
import EditDetails from "./EditDetails";
import { SAVE_REPORTING_RELATIONS } from "./queries";
import { pollForBasicDataSaveReady } from "../../queries";

const mapStateToProps = (state: AppState) => {
  return {
    reportingRelationsInput: state.projectState.projectInput
      ? state.projectState.projectInput.reportingRelations
      : getEmptyReportingRelationsSectionInput(),
  };
};
const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, void, Action>) => {
  return {
    setReportingRelationsInput: (input: ReportingRelationsSectionInput) => dispatch(setReportingRelations(input)),
  };
};

interface SectionProps {
  projectId: ProjectId;
  sectionDetails: ProjectReportingRelations | undefined;
  sectionVisibility: ProjectReportingRelationsVisibility;
  sectionEditable: boolean;
  editSectionType: BasicDataSectionType | undefined;
  setEditSectionType: (type: BasicDataSectionType | undefined) => void;
  refetchDetails: () => void;
}

const TYPE: BasicDataSectionType = BasicDataSectionType.ReportingRelations;

function ReportingRelationsSection(
  props: SectionProps & ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>
) {
  const {
    projectId,
    sectionDetails,
    sectionVisibility,
    sectionEditable,
    editSectionType,
    setEditSectionType,
    refetchDetails,
    reportingRelationsInput,
    setReportingRelationsInput,
  } = props;
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const [saveMutation, { loading: saving }] = useMutation<{
    saveProjectReportingRelations: ProjectReportingRelationsSaveResult;
  }>(SAVE_REPORTING_RELATIONS);

  const { pollForReady, ready, loading: polling, error: pollingError } = pollForBasicDataSaveReady();
  useEffect(() => {
    if (ready && !polling && !pollingError) {
      setEditSectionType(undefined);
      setReportingRelationsInput(getEmptyReportingRelationsSectionInput());
      refetchDetails();
    } else if (!polling && pollingError) {
      setErrorMessages(errorMessages.concat([pollingError]));
    }
  }, [ready, polling, pollingError]);

  const save = useCallback(
    (input: ProjectReportingRelationsInput) => {
      setErrorMessages([]);
      saveMutation({
        variables: {
          projectId,
          reportingRelations: input,
        },
      })
        .catch(apolloError => Promise.reject([apolloError.message]))
        .then(result => {
          if (result.data) {
            const response = result.data.saveProjectReportingRelations;
            if (response.result && response.applicationModifiedDateTime) {
              return pollForReady(projectId, response.applicationModifiedDateTime);
            } else if (response.errors) {
              return Promise.reject(response.errors);
            }
          } else {
            return Promise.reject("Unexpected error while saving");
          }
        })
        .catch(errors => setErrorMessages(errors));
    },
    [projectId, saveMutation]
  );

  const disabled = editSectionType !== undefined && editSectionType !== TYPE;
  const editMode = editSectionType === TYPE;

  return (
    <Section
      header="Order / Reporting Relations"
      disabled={disabled}
      editMode={editMode}
      editable={sectionEditable && sectionDetails !== undefined}
      saving={saving || polling}
      saveEnabled={!reportingRelationsInput.pristine}
      errors={errorMessages.length > 0 ? errorMessages : undefined}
      onEditClicked={() => {
        setEditSectionType(TYPE);
      }}
      onCancelEdit={() => {
        setEditSectionType(undefined);
        setReportingRelationsInput(getEmptyReportingRelationsSectionInput());
      }}
      onSaveClicked={() => {
        save(reportingRelationsInput.input);
      }}
    >
      <Container>
        {!sectionDetails ? undefined : editMode ? (
          <EditDetails projectId={projectId} reportingRelations={sectionDetails} visibility={sectionVisibility} />
        ) : (
          <ViewDetails reportingDetails={sectionDetails} visibility={sectionVisibility} />
        )}
      </Container>
    </Section>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportingRelationsSection);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-bottom: 0px;
  color: ${defaultGrey};
`;
