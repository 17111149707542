import { immerable } from "immer";

import { Period } from "../../../../common/period";
import {
  CommentType,
  HierarchyItemEnum,
  HierarchyItemId,
  ListingProjectCell,
  NodeId,
  ProjectCostColumnId,
  ProjectId,
  User,
} from "../../../../common/types";
import {
  ColumnId,
  EditableColumnId,
  EditableColumnSetValue,
  EditColumnValueTypeMap,
} from "../../../../common/columnsTypes";
import { RecPlanEstRecEditModes } from "./recognitionsEditingSetting";

export interface RecognitionsEditStateChange {
  period: Period;
  columnId: EditableColumnId;
  field: string;
  value: number;
  freezingComment?: string;
}

export interface RecognitionRowAdditionalInfo {
  ptProjectRecOverriddenFromRelated: boolean;
}

export interface RecognitionCellFieldMessage {
  fieldId: string;
  message: string;
}

export interface RecognitionCellAdditionalInfo {
  message: RecognitionCellFieldMessage[] | null;
}

export class EditableRecognitionRow {
  [immerable] = true;

  period: Period;
  cells: EditableRecognitionCell[];
  additionalInfo?: RecognitionRowAdditionalInfo;

  constructor(period: Period, cells: EditableRecognitionCell[]) {
    this.period = period;
    this.cells = cells;
  }

  static withAdditionalInfo(
    period: Period,
    cells: EditableRecognitionCell[],
    additionalInfo: RecognitionRowAdditionalInfo
  ): EditableRecognitionRow {
    const result = new EditableRecognitionRow(period, cells);
    result.additionalInfo = additionalInfo;
    return result;
  }

  findCell<C extends EditableColumnId>(columnId: C): EditableRecognitionCellT<EditColumnValueTypeMap[C]> | undefined {
    const cell = this.cells.find(c => c.columnId === columnId);
    if (cell) {
      return cell as EditableRecognitionCellT<EditColumnValueTypeMap[C]>;
    }
    return undefined;
  }

  getCell<C extends EditableColumnId>(columnId: C): EditableRecognitionCellT<EditColumnValueTypeMap[C]> {
    const cell = this.findCell(columnId);
    if (cell === undefined) throw Error(`Column ${columnId} was not found`);
    return cell;
  }
}

export interface EditableRecognitionCellT<V extends EditableColumnSetValue> {
  columnId: EditableColumnId;
  value: V;
  comments: EditableRecognitionCellComments[];
  freezings: EditableRecognitionCellFreezing[];
  additionalInfo?: RecognitionCellAdditionalInfo;
}

export type EditableRecognitionCell = EditableRecognitionCellT<EditableColumnSetValue>;

export interface EditableRecognitionCellFreezing {
  fieldName: string;
  value: number;
  createdBy: string;
  createdDateTime?: string;
}

export interface EditableRecognitionCellComment {
  type: CommentType;
  value?: number;
  content: string;
  currencyScenario?: string;
  createdBy: string;
  createdDateTime?: string;
}

export interface EditableRecognitionCellComments {
  fieldName: string;
  comments: EditableRecognitionCellComment[];
}

export interface RecognitionPlanChangeFreezing {
  comment: string;
}

export interface RecognitionPlanChange {
  period: string;
  column: string;
  field: string;
  value: number;
  freezing: RecognitionPlanChangeFreezing | null;
}

export interface RecognitionPlanSaveResult {
  error: string | undefined;
  applicationModifiedDateTime: string | undefined;
}

export interface ByMonthRecognition {
  period: string;
  columns: ListingProjectCell[];
}

export interface ByQuarterRecognition {
  period: string;
  columns: ListingProjectCell[];
  subGroups: ByMonthRecognition[];
}

export interface ByYearRecognition {
  period: string;
  columns: ListingProjectCell[];
  subGroups: ByQuarterRecognition[];
}

export interface ByPeriodRecognition {
  period: string;
  columns: ListingProjectCell[];
  subGroups?: ByPeriodRecognition[];
  childLevel?: number;
}

export type RateCurrencyPair = {
  from: string;
  to: string;
};

export interface ProjectRecognitionsResult {
  id: HierarchyItemId;
  itemType: HierarchyItemEnum;
  grouped: ByYearRecognition[];
  asSoldPeriod?: string;
  budgetPeriod?: string;
  oblPeriod?: string;
  completePeriod?: string;
  editType: RecognitionEditType;
  rateCurrencyPairs: RateCurrencyPair[] | null;
}

export interface ProjectRecognitionsResultData {
  projectRecognitions: ProjectRecognitionsResult | null;
}

export enum RecognitionEditType {
  NonEditable = "NonEditable",
  Manual = "Manual",
  BaanProject = "BaanProject",
  BaanPcsProject = "BaanPcsProject",
  LnProject = "LnProject",
  LnServiceContract = "LnServiceContract",
  LnServiceOrder = "LnServiceOrder",
  LnSalesOrder = "LnSalesOrder",
  EncompixPOrder = "EncompixPOrder",
  AutomaticAdjustmentOnNS = "AutomaticAdjustmentOnNS",
  AutomaticAdjustmentOnIntNS = "AutomaticAdjustmentOnIntNS",
  NonEditableAutomaticAdjustmentOnNS = "NonEditableAutomaticAdjustmentOnNS",
  NonEditableAutomaticAdjustmentOnIntNS = "NonEditableAutomaticAdjustmentOnIntNS",
}

export type ProjectRecognitionWaitingForReadyMap = Map<ProjectId, ProjectRecognitionWaitingForReady>;

export interface ProjectRecognitionWaitingForReady {
  state: RecognitionWaitingState;
  applicationModifiedDateTime: string | null;
}

export enum RecognitionWaitingState {
  Saving = "Saving",
  Recalculating = "Recalculating",
}

export interface ProjectRecognitionsEditInformation {
  editType: RecognitionEditType;
  asSoldPeriod?: string;
  budgetPeriod?: string;
  oblPeriod?: string;
  recCompletionPeriod?: string;
  migratedFromLegacySystem: boolean;
  additionalAdjustments: boolean;
  editablePcsProject: boolean;
  revenueMethodIsStraightLine: boolean;
  isPTProjectWithEstRecFromRelated: boolean;
}

export interface ProjectRecognitionsEditResultData {
  projectRecognitions: ProjectRecognitionsResult | null;
  projectRecognitionsEditInformation: ProjectRecognitionsEditInformation | null;
}

export interface RecognitionEditOptions {
  editProjectType: RecognitionEditType;
  asSoldPeriod: Period | null;
  budgetPeriod: Period | null;
  oblPeriod: Period | null;
  recCompletionPeriod: Period | null;
  migratedFromLegacySystem: boolean;
  additionalAdjustments: boolean;
  editablePcsProject: boolean;
  revenueMethodIsStraightLine: boolean;
  isPTProjectWithEstRecFromRelated: boolean;
}

export interface EditingPredicateParams {
  period: Period;
  frozenFields: string[];

  // Tells whether the cell's period is in the past, is the current period, or in the future
  periodIsInPast: boolean;
  periodIsCurrent: boolean;
  periodIsInFuture: boolean;

  isAsSoldPeriod: boolean;
  isBudgetPeriod: boolean;
  isRecCompletionPeriod: boolean;

  options: RecognitionEditOptions;

  getCell: (columnId: EditableColumnId) => EditableRecognitionCell;
  estRecEditMode: RecPlanEstRecEditModes;
}

export type CopyMode = "none" | "all" | "future" | "future_smaller";
export type CopyModeForFields = CopyMode | { [index: string]: CopyMode | undefined };

export enum FreezingDisabledType {
  AllEnabled,
  AllDisabled,
  PartiallyDisabled,
}
export type FreezingAllEnabled = {
  type: FreezingDisabledType.AllEnabled;
};
export type FreezingAllDisabled = {
  type: FreezingDisabledType.AllDisabled;
  reasons: string[];
};
export type FreezingDisabledField = {
  fieldName: string;
  reasons: string[];
};
export type FreezingPartiallyDisabled = {
  type: FreezingDisabledType.PartiallyDisabled;
  fields: FreezingDisabledField[];
};
export type FreezingDisabledResult = FreezingAllEnabled | FreezingAllDisabled | FreezingPartiallyDisabled;

export type VisibleEditableColumnId = Exclude<EditableColumnId, "related_est_rec_edit">;

export type EditableFieldsByColumn = {
  [index in VisibleEditableColumnId]: {
    columnLabel: string;
    measures: string[];
    labels: string[];
    calculated: boolean | string[];
    copyMode: (editType: RecognitionEditType) => CopyModeForFields;
    options: (
      params: EditingPredicateParams
    ) => {
      disabled: boolean | string[];
      freezingDisabled: FreezingDisabledResult;
    };
  };
};

export interface ListingNodeRecognitions {
  nodeId: NodeId;
  columns: ListingProjectCell[];
}

export interface ListingProjectRecognitions {
  projectId: ProjectId;
  columns: ListingProjectCell[];
}

export interface ListingRecognitionsResult {
  nodes: ListingNodeRecognitions[];
  projects: ListingProjectRecognitions[];
}

export interface ListingRecognitionsQueryData {
  listingRecognitions: ListingRecognitionsResult;
}

// Used during runtime
export interface ListingRecognitionsItemData {
  columns: Map<ColumnId, ListingProjectCell>;
}

// Used during runtime
export interface ListingRecognitionsData {
  nodes: Map<NodeId, ListingRecognitionsItemData>;
  projects: Map<ProjectId, ListingRecognitionsItemData>;
}

export interface RecognitionsEditState {
  projectId: HierarchyItemId | null;
  editing: boolean;
  initialized: boolean;
  waitingForReady: ProjectRecognitionWaitingForReady | null;

  initialRecognitions: EditableRecognitionRow[];
  recognitions: EditableRecognitionRow[];
  changes: Map<string, RecognitionsEditStateChange>;
  latestChange: RecognitionsEditStateChange | null;
  saveChanges: RecognitionsEditStateChange[] | null;

  options: RecognitionEditOptions;

  recognitionsErrors: string[];

  massFreeze: {
    dialogOpen: boolean;
    fromPeriod: Period | null;
    toPeriod: Period | null;
  };

  user?: User;
}

export interface UserPreferencesState {
  estRecEditMode: RecPlanEstRecEditModes;
}

export interface UserPreferencesResult {
  userPreferencesState: UserPreferencesState | null;
  error: string | null;
}
