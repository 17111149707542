import { gql } from "@apollo/client";
import { CommentFields, FreezingFields } from "../../Project/ProjectRecognitions/queries";

const ProjectFields = gql`
  fragment ProjectFields on ListingProject {
    projectId
    columns {
      columnId
      value {
        ... on StringValue {
          value
        }
        ... on RecPercent {
          nsPercent
          costPercent
          pmPercent
        }
        ... on Recognition {
          ns
          cogs
          pm
          warCost
        }
        ... on EstRecPercent {
          nsPercent
          cogsPercent
          pmPercent
        }
        ... on EstRec {
          ns
          cogs
          pm
          wipCosts
        }
        ... on YTDRecPercentage {
          actNSPercentage
          actCogsPercentage
          actPMPercentage
        }
        ... on NetSalesCostsPM {
          ns
          cost
          pm
          pmPercent
          warCost
        }
        ... on Estimate {
          ns
          cost
          pm
          pmPercent
          warCost
          nsOverrun
          costOverrun
          warCostOverrun
        }
        ... on NetSalesCogsPM {
          ns
          cogs
          pm
          pmPercent
        }
        ... on Backlog {
          obl
          cost
          pm
          pmPercent
        }
        ... on VarToPrevEst {
          nsVar
          costVar
        }
        ... on SalesPrice {
          asld
          obud
          rbud
          prevEst
          est
        }
        ... on YTDRec {
          actNS
          actCogs
          actPM
        }
        ... on AsSoldBudgetEstimated {
          asld
          obud
          rbud
          est
        }
        ... on EstimatedCommittedVariance {
          est
          etc
          totalComm
        }
        ... on OrdersReceived {
          or
          orPeriod
          orPTD
          oblCorrYTD
          adjOnOR
        }
        ... on ActualCostsCommitments {
          actualCosts
          hardComm
          softComm
          wipCosts
        }
        ... on Invoicing {
          invoicing
          paymRec
        }
        ... on InvoicingExt {
          invoicing
          receivable
          paymRec
          paymRecPercentage
          ovdRec
        }
        ... on PeriodRec {
          ns
          cogs
          pm
          pmPercent
        }
        ... on Rates {
          orRate
          hedgeRate
          avgRate
          endRate
          erRate
          irRate
        }
        ... on RecWarranty {
          periodCogs
          ytdCogs
          prevCogs
          cogs
        }
        ... on PM {
          period
          asld
          rbud
          ptd
          ytd
        }
        ... on PMCurrentYear {
          period
          ptd
          ytd
        }
        ... on WarrCostVar {
          period
          toASLD
          toRBUD
          YTD
        }
        ... on Contingency {
          asld
          obud
          rbud
          prevEst
          est
        }
        ... on CommCosts {
          commCosts
          actualCosts
          hardComm
          softComm
          wipCosts
        }
        ... on ContingencyVar {
          period
          toASLD
          YTD
        }
      }
      comments {
        ...CommentFields
      }
      freezings {
        ...FreezingFields
      }
    }
  }
  ${CommentFields}
  ${FreezingFields}
`;

const NodeFields = gql`
  fragment NodeFields on ListingNode {
    nodeId
    description
    childProjects {
      ...ProjectFields
    }
    columns {
      columnId
      value {
        ... on StringValue {
          value
        }
        ... on RecPercent {
          nsPercent
          costPercent
          pmPercent
        }
        ... on Recognition {
          ns
          cogs
          pm
          warCost
        }
        ... on EstRecPercent {
          nsPercent
          cogsPercent
          pmPercent
        }
        ... on EstRec {
          ns
          cogs
          pm
          wipCosts
        }
        ... on YTDRecPercentage {
          actNSPercentage
          actCogsPercentage
          actPMPercentage
        }
        ... on NetSalesCostsPM {
          ns
          cost
          pm
          pmPercent
          warCost
        }
        ... on Estimate {
          ns
          cost
          pm
          pmPercent
          warCost
          nsOverrun
          costOverrun
          warCostOverrun
        }
        ... on NetSalesCogsPM {
          ns
          cogs
          pm
          pmPercent
        }
        ... on Backlog {
          obl
          cost
          pm
          pmPercent
        }
        ... on VarToPrevEst {
          nsVar
          costVar
        }
        ... on SalesPrice {
          asld
          obud
          rbud
          prevEst
          est
        }
        ... on YTDRec {
          actNS
          actCogs
          actPM
        }
        ... on AsSoldBudgetEstimated {
          asld
          obud
          rbud
          est
        }
        ... on EstimatedCommittedVariance {
          est
          etc
          totalComm
        }
        ... on OrdersReceived {
          or
          orPeriod
          orPTD
          oblCorrYTD
          adjOnOR
        }
        ... on ActualCostsCommitments {
          actualCosts
          hardComm
          softComm
          wipCosts
        }
        ... on Invoicing {
          invoicing
          paymRec
        }
        ... on InvoicingExt {
          invoicing
          receivable
          paymRec
          paymRecPercentage
          ovdRec
        }
        ... on PeriodRec {
          ns
          cogs
          pm
          pmPercent
        }
        ... on Rates {
          orRate
          hedgeRate
          avgRate
          endRate
          erRate
          irRate
        }
        ... on RecWarranty {
          periodCogs
          ytdCogs
          prevCogs
          cogs
        }
        ... on PM {
          period
          asld
          rbud
          ptd
          ytd
        }
        ... on PMCurrentYear {
          period
          ptd
          ytd
        }
        ... on WarrCostVar {
          period
          toASLD
          toRBUD
          YTD
        }
        ... on Contingency {
          asld
          obud
          rbud
          prevEst
          est
        }
        ... on CommCosts {
          commCosts
          actualCosts
          hardComm
          softComm
          wipCosts
        }
        ... on ContingencyVar {
          period
          toASLD
          YTD
        }
      }
      comments {
        ...CommentFields
      }
      freezings {
        ...FreezingFields
      }
    }
  }
  ${ProjectFields}
`;

export const GET_PROJECTS_WITH_HIERARCHY = gql`
  query GetProjects(
    $num: Int!
    $filters: ProjectFiltersInput!
    $columns: [ProjectColumn!]!
    $offset: Int!
    $currencyId: CurrencyId!
  ) {
    projects(filters: $filters, columns: $columns, num: $num, offset: $offset, currencyId: $currencyId) {
      nodes {
        ...NodeFields
        childNodes {
          ...NodeFields
          childNodes {
            ...NodeFields
            childNodes {
              ...NodeFields
              childNodes {
                ...NodeFields
                childNodes {
                  ...NodeFields
                  childNodes {
                    ...NodeFields
                    childNodes {
                      ...NodeFields
                      childNodes {
                        ...NodeFields
                        childNodes {
                          ...NodeFields
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      availableResults
    }
  }
  ${ProjectFields}
  ${NodeFields}
`;

const ListingProjectRecognitionFields = gql`
  fragment ListingProjectRecognitionFields on ListingProjectRecognitions {
    projectId
    columns {
      columnId
      value {
        ... on StringValue {
          value
        }
        ... on RecPercent {
          nsPercent
          costPercent
          pmPercent
        }
        ... on Recognition {
          ns
          cogs
          pm
          warCost
        }
        ... on EstRecPercent {
          nsPercent
          cogsPercent
          pmPercent
        }
        ... on EstRec {
          ns
          cogs
          pm
          wipCosts
        }
        ... on YTDRecPercentage {
          actNSPercentage
          actCogsPercentage
          actPMPercentage
        }
        ... on NetSalesCostsPM {
          ns
          cost
          pm
          pmPercent
          warCost
        }
        ... on Estimate {
          ns
          cost
          pm
          pmPercent
          warCost
          nsOverrun
          costOverrun
          warCostOverrun
        }
        ... on NetSalesCogsPM {
          ns
          cogs
          pm
          pmPercent
        }
        ... on Backlog {
          obl
          cost
          pm
          pmPercent
        }
        ... on VarToPrevEst {
          nsVar
          costVar
        }
        ... on SalesPrice {
          asld
          obud
          rbud
          prevEst
          est
        }
        ... on YTDRec {
          actNS
          actCogs
          actPM
        }
        ... on AsSoldBudgetEstimated {
          asld
          obud
          rbud
          est
        }
        ... on EstimatedCommittedVariance {
          est
          etc
          totalComm
        }
        ... on OrdersReceived {
          or
          orPeriod
          orPTD
          oblCorrYTD
          adjOnOR
        }
        ... on ActualCostsCommitments {
          actualCosts
          hardComm
          softComm
          wipCosts
        }
        ... on Invoicing {
          invoicing
          paymRec
        }
        ... on InvoicingExt {
          invoicing
          receivable
          paymRec
          paymRecPercentage
          ovdRec
        }
        ... on PeriodRec {
          ns
          cogs
          pm
          pmPercent
        }
        ... on Rates {
          orRate
          hedgeRate
          avgRate
          endRate
          erRate
          irRate
        }
        ... on RecWarranty {
          periodCogs
          ytdCogs
          prevCogs
          cogs
        }
        ... on PM {
          period
          asld
          rbud
          ytd
          ptd
        }
        ... on PMCurrentYear {
          period
          ytd
          ptd
        }
        ... on WarrCostVar {
          period
          toASLD
          toRBUD
          YTD
        }
        ... on Contingency {
          asld
          obud
          rbud
          prevEst
          est
        }
        ... on CommCosts {
          commCosts
          actualCosts
          hardComm
          softComm
          wipCosts
        }
        ... on ContingencyVar {
          period
          toASLD
          YTD
        }
      }
      comments {
        ...CommentFields
      }
      freezings {
        ...FreezingFields
      }
    }
  }
  ${CommentFields}
  ${FreezingFields}
`;

const ListingNodeRecognitionFields = gql`
  fragment ListingNodeRecognitionFields on ListingNodeRecognitions {
    nodeId
    columns {
      columnId
      value {
        ... on StringValue {
          value
        }
        ... on RecPercent {
          nsPercent
          costPercent
          pmPercent
        }
        ... on Recognition {
          ns
          cogs
          pm
          warCost
        }
        ... on EstRecPercent {
          nsPercent
          cogsPercent
          pmPercent
        }
        ... on EstRec {
          ns
          cogs
          pm
          wipCosts
        }
        ... on YTDRecPercentage {
          actNSPercentage
          actCogsPercentage
          actPMPercentage
        }
        ... on NetSalesCostsPM {
          ns
          cost
          pm
          pmPercent
          warCost
        }
        ... on Estimate {
          ns
          cost
          pm
          pmPercent
          warCost
          nsOverrun
          costOverrun
          warCostOverrun
        }
        ... on NetSalesCogsPM {
          ns
          cogs
          pm
          pmPercent
        }
        ... on Backlog {
          obl
          cost
          pm
          pmPercent
        }
        ... on VarToPrevEst {
          nsVar
          costVar
        }
        ... on SalesPrice {
          asld
          obud
          rbud
          prevEst
          est
        }
        ... on YTDRec {
          actNS
          actCogs
          actPM
        }
        ... on AsSoldBudgetEstimated {
          asld
          obud
          rbud
          est
        }
        ... on EstimatedCommittedVariance {
          est
          etc
          totalComm
        }
        ... on OrdersReceived {
          or
          orPeriod
          orPTD
          oblCorrYTD
          adjOnOR
        }
        ... on ActualCostsCommitments {
          actualCosts
          hardComm
          softComm
          wipCosts
        }
        ... on Invoicing {
          invoicing
          paymRec
        }
        ... on InvoicingExt {
          invoicing
          receivable
          paymRec
          paymRecPercentage
          ovdRec
        }
        ... on PeriodRec {
          ns
          cogs
          pm
          pmPercent
        }
        ... on Rates {
          orRate
          hedgeRate
          avgRate
          endRate
          erRate
          irRate
        }
        ... on RecWarranty {
          periodCogs
          ytdCogs
          prevCogs
          cogs
        }
        ... on PM {
          period
          asld
          rbud
          ytd
          ptd
        }
        ... on PMCurrentYear {
          period
          ytd
          ptd
        }
        ... on WarrCostVar {
          period
          toASLD
          toRBUD
          YTD
        }
        ... on Contingency {
          asld
          obud
          rbud
          prevEst
          est
        }
        ... on CommCosts {
          commCosts
          actualCosts
          hardComm
          softComm
          wipCosts
        }
        ... on ContingencyVar {
          period
          toASLD
          YTD
        }
      }
      comments {
        ...CommentFields
      }
      freezings {
        ...FreezingFields
      }
    }
  }
`;

export const GET_LISTING_RECOGNITIONS = gql`
  query GetListingRecognitions(
    $projectIds: [ProjectId!]!
    $filters: ProjectFiltersInput!
    $columns: [ProjectColumn!]!
    $currencyId: CurrencyId!
  ) {
    listingRecognitions(projectIds: $projectIds, filters: $filters, columns: $columns, currencyId: $currencyId) {
      nodes {
        ...ListingNodeRecognitionFields
      }
      projects {
        ...ListingProjectRecognitionFields
      }
    }
  }
  ${ListingProjectRecognitionFields}
  ${ListingNodeRecognitionFields}
`;
