import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useApolloClient, useQuery } from "@apollo/client/react/hooks";
import { ApolloClient, ApolloError } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronRight } from "@fortawesome/free-solid-svg-icons";

import { NodeId, ProjectId } from "../../../../common/types";
import ErrorBox from "../../../ErrorBox";
import SpinnerBox from "../../../SpinnerBox";
import { ActionButton, ToggleSwitch } from "../../../../common/components";
import {
  cancelRed,
  commentBackgroundBlue,
  disabledGrey,
  editBlue,
  settingGreen,
  warningYellow,
} from "../../../../common/colors";
import { HeaderActionsRefs, HeaderActions } from "../ProjectHeaderActions";
import {
  CommentPackageType,
  EditableCommentPackage,
  EstimationCommentForCompilation,
  ProjectCommentPackage,
  ProjectPeriodCommentsResult,
  RecognitionCommentForCompilation,
  TotalPeriodCommentsResult,
} from "./types";
import { GET_PERIOD_COMMENTS, GET_TOTAL_PERIOD_COMMENTS, publishCommentPackage, saveCommentPackage } from "./queries";
import RecognitionComment from "./RecognitionComment";
import EstimationComment from "./EstimationComment";
import { formatTimestamp, useItemToggle } from "./utils";
import { CommentHeader } from "./shared-components";
import FullscreenSpinner from "../../../FullscreenSpinner";
import { Period } from "../../../../common/period";
import ProjectFinancialComment from "./ProjectFinancialComment";

interface CommentPackageEditProps {
  projectId: ProjectId;
  nodeId: NodeId | undefined;
  commentPackage: EditableCommentPackage;
  onCancel: () => void;
}

const CommentText_MaxLength = 2000;

const recCommentId = (comment: RecognitionCommentForCompilation): string => {
  return "rec-" + comment.commentType + "-" + comment.column + "-" + comment.field;
};
const estCommentId = (comment: EstimationCommentForCompilation): string => {
  return "est-" + comment.commentType + "-" + comment.commentId;
};

interface ProjectCommentSelectionProps {
  projectId: ProjectId;
  period: Period;
  commentText: string;
  setCommentText: (commentText: string) => void;
  isCommentUsed: (commentId: string) => boolean;
  setCommentUsed: (commentId: string, used: boolean) => void;
}

function ProjectCommentSelection(props: ProjectCommentSelectionProps) {
  const { projectId, period, commentText, setCommentText, isCommentUsed, setCommentUsed } = props;

  const { data, loading, error } = useQuery<{ projectPeriodComments: ProjectPeriodCommentsResult }>(
    GET_PERIOD_COMMENTS,
    {
      variables: {
        projectId,
        period,
      },
      fetchPolicy: "cache-and-network",
    }
  );

  const [openGroup, setOpenGroup] = useState<string | null>("rec");
  const isGroupOpen = (groupId: string) => openGroup === groupId;
  const toggleGroup = (groupId: string) => (openGroup === groupId ? setOpenGroup(null) : setOpenGroup(groupId));
  const [isUsedHidden, , toggleUsedHidden] = useItemToggle(["rec", "est"]);

  const useRecognitionComment = useCallback(
    (comment: RecognitionCommentForCompilation) => {
      const lines = [
        comment.createdBy.padEnd(50, " ") + formatTimestamp(comment.createdDateTime),
        comment.commentType.padEnd(50, " ") + "Value: " + (comment.value !== null ? comment.value.toFixed(0) : 0),
        comment.content,
        comment.currencyScenario,
      ];
      setCommentText(commentText + "\n\n--\n\n" + lines.join("\n\n"));
      setCommentUsed(recCommentId(comment), true);
    },
    [commentText, setCommentText]
  );
  const useEstimationComment = useCallback(
    (comment: EstimationCommentForCompilation) => {
      const lines = [
        comment.createdBy.padEnd(50, " ") + formatTimestamp(comment.createdDateTime),
        comment.commentType,
        comment.content,
      ];
      setCommentText(commentText + "\n\n--\n\n" + lines.join("\n\n"));
      setCommentUsed(estCommentId(comment), true);
    },
    [commentText, setCommentText]
  );

  const commentGroups = [
    {
      groupId: "rec",
      title: "Recognition",
      renderComments: (data: ProjectPeriodCommentsResult) =>
        data.recognitionPlanComments.map(comment => (
          <RecognitionComment
            key={recCommentId(comment)}
            comment={comment}
            used={isCommentUsed(recCommentId(comment))}
            use={() => useRecognitionComment(comment)}
            usedHidden={isUsedHidden("rec")}
          />
        )),
    },
    {
      groupId: "est",
      title: "Estimation",
      renderComments: (data: ProjectPeriodCommentsResult) =>
        data.costEstimationComments.map(comment => (
          <EstimationComment
            key={estCommentId(comment)}
            comment={comment}
            used={isCommentUsed(estCommentId(comment))}
            use={() => useEstimationComment(comment)}
            usedHidden={isUsedHidden("est")}
          />
        )),
    },
  ];
  return (
    <CommentSelectionContainer>
      {loading ? (
        <CommentSelectionSpinnerContainer>
          <SpinnerBox />
        </CommentSelectionSpinnerContainer>
      ) : error || !data ? (
        <ErrorBox caption="Could not load comments for the period" apolloError={error} />
      ) : (
        commentGroups.map(({ groupId, title, renderComments }) => {
          const isOpen = isGroupOpen(groupId);
          const usedHidden = isUsedHidden(groupId);
          const commentElements = renderComments(data.projectPeriodComments);
          return (
            <CommentSelectGroupContainer key={groupId}>
              <CommentSelectGroupHeader>
                <GroupTitle onClick={() => toggleGroup(groupId)}>
                  <Icon>
                    <FontAwesomeIcon icon={isOpen ? faChevronDown : faChevronRight} size="1x" color={settingGreen} />
                  </Icon>
                  {title}
                </GroupTitle>
                <ToggleSwitch
                  checked={!usedHidden}
                  onChange={() => toggleUsedHidden(groupId)}
                  text={"Show used comments"}
                  fontSize={"12px"}
                />
              </CommentSelectGroupHeader>
              <CommentSelectCommentsContainer
                height={isOpen ? "calc(max(100vh - 400px - 32px * 2, 400px - 32px * 2))" : "0"}
              >
                <CommentSelectCommentsContent>
                  {commentElements.length > 0 ? commentElements : <Placeholder>No comments</Placeholder>}
                </CommentSelectCommentsContent>
              </CommentSelectCommentsContainer>
            </CommentSelectGroupContainer>
          );
        })
      )}
    </CommentSelectionContainer>
  );
}

function TotalCommentSelection(props: ProjectCommentSelectionProps) {
  const { projectId, period, commentText, setCommentText, isCommentUsed, setCommentUsed } = props;

  const { data, loading, error } = useQuery<{
    totalPeriodComments: TotalPeriodCommentsResult;
  }>(GET_TOTAL_PERIOD_COMMENTS, {
    variables: { projectId, period },
    fetchPolicy: "cache-and-network",
  });

  const [isUsedHidden, , toggleUsedHidden] = useItemToggle(["project-financial"]);

  const useComment = useCallback(
    (comment: ProjectCommentPackage) => {
      const lines = [
        comment.modifiedBy.padEnd(50, " ") + formatTimestamp(comment.modified),
        comment.projectId + " - " + comment.description,
        comment.content,
      ];
      setCommentText(commentText + "\n\n--\n\n" + lines.join("\n\n"));
      setCommentUsed("" + comment.projectId, true);
    },
    [commentText, setCommentText]
  );

  const commentGroups = [
    {
      groupId: "project-financial",
      title: "Project Financial",
      renderComments: (data: TotalPeriodCommentsResult) =>
        data.comments.map(comment => (
          <ProjectFinancialComment
            key={comment.projectId}
            comment={comment}
            used={isCommentUsed("" + comment.projectId)}
            use={() => useComment(comment)}
            usedHidden={isUsedHidden("project-financial")}
          />
        )),
    },
  ];
  return (
    <CommentSelectionContainer>
      {loading ? (
        <CommentSelectionSpinnerContainer>
          <SpinnerBox />
        </CommentSelectionSpinnerContainer>
      ) : error || !data ? (
        <ErrorBox caption="Could not load comments for the period" apolloError={error} />
      ) : (
        commentGroups.map(({ groupId, title, renderComments }) => {
          const isOpen = true; //isGroupOpen(groupId);
          const usedHidden = isUsedHidden(groupId);
          const commentElements = renderComments(data.totalPeriodComments);
          return (
            <CommentSelectGroupContainer key={groupId}>
              <CommentSelectGroupHeader>
                <GroupTitle>
                  <Icon>
                    <FontAwesomeIcon icon={isOpen ? faChevronDown : faChevronRight} size="1x" color={settingGreen} />
                  </Icon>
                  {title}
                </GroupTitle>
                <ToggleSwitch
                  checked={!usedHidden}
                  onChange={() => toggleUsedHidden(groupId)}
                  text={"Show used comments"}
                  fontSize={"12px"}
                />
              </CommentSelectGroupHeader>
              <CommentSelectCommentsContainer height={"calc(100vh - 400px - 26px)"}>
                <CommentSelectCommentsContent>
                  {commentElements.length > 0 ? commentElements : <Placeholder>No comments</Placeholder>}
                </CommentSelectCommentsContent>
              </CommentSelectCommentsContainer>
            </CommentSelectGroupContainer>
          );
        })
      )}
    </CommentSelectionContainer>
  );
}

function CommentPackageEdit(props: CommentPackageEditProps) {
  const {
    projectId,
    nodeId,
    commentPackage: { period, commentType, content },
    commentPackage,
    onCancel,
  } = props;

  const [isCommentUsed, setCommentUsed, , { unsetAll: resetUsed }] = useItemToggle([]);

  const publishEnabled: boolean = useMemo(
    () => commentPackage.type === "EditablePackage" && commentPackage.published !== commentPackage.modified,
    [commentPackage]
  );

  //const [isGroupOpen, , toggleGroup, {
  //  numItemsSet: numberOfOpenGroups
  //}] = useItemToggle(["rec", "est"]);
  const [originalText, setOriginalText] = useState(content);
  const [commentText, setCommentText] = useState(content);
  const [status, setStatus] = useState<string | null>(null);
  const [saved, setSaved] = useState(false);
  const [saveError, setSaveError] = useState<string | ApolloError | null>(null);

  useEffect(() => {
    if (commentText !== content) setSaved(false);
  }, [setSaved, commentText, content]);

  const client = useApolloClient() as ApolloClient<Record<string, unknown>>;

  const save = useCallback(() => {
    setSaveError(null);
    setStatus("Saving");
    saveCommentPackage(projectId, nodeId, { period: period, commentType, content: commentText }, client)
      .then(result => {
        console.log("Save was successful:", result);
        setSaved(true);
        setSaveError(null);
        if (result.commentPackage) setOriginalText(result.commentPackage.content);
      })
      .catch(error => {
        console.log("Save failed:", error);
        setSaveError(error);
      })
      .finally(() => {
        setStatus(null);
      });
  }, [period, commentType, commentText]);

  const publish = useCallback(() => {
    if (publishEnabled && commentPackage.type === "EditablePackage") {
      const { period, commentType, content, modified, published } = commentPackage;

      setSaveError(null);
      setStatus("Publishing");
      publishCommentPackage(
        projectId,
        nodeId,
        {
          period,
          commentType,
          content,
          modified,
          published,
        },
        client
      )
        .then(result => {
          console.log("Publish was successful:", result);
          setSaveError(null);
        })
        .catch(error => {
          console.log("Publish failed:", error);
          setSaveError(error);
        })
        .finally(() => {
          setStatus(null);
        });
    }
  }, [commentPackage, publishCommentPackage]);

  const overMaxLen = useMemo(() => {
    return Math.max(commentText.length - CommentText_MaxLength, 0);
  }, [commentText]);

  const [validationError, setValidationError] = useState(false);

  useEffect(() => {
    if (overMaxLen > 0) setValidationError(true);
    else setValidationError(false);
  }, [overMaxLen, setValidationError]);

  const edited = commentText !== originalText;

  const actionsDisabled = !!status || validationError;
  return (
    <Container>
      <HeaderActions refName={HeaderActionsRefs.commentPackageEditRef}>
        <ActionButton
          color={editBlue}
          onClick={() => publish()}
          disabled={actionsDisabled || !publishEnabled || edited}
        >
          Publish
        </ActionButton>
        <ActionButton color={settingGreen} onClick={() => save()} disabled={actionsDisabled || !edited}>
          Save
        </ActionButton>
        <ActionButton
          color={settingGreen}
          onClick={() => {
            setCommentText(originalText);
            resetUsed();
            setSaveError(null);
          }}
          disabled={actionsDisabled || !edited}
        >
          Undo
        </ActionButton>
        {!saved ? (
          <ActionButton color={cancelRed} onClick={onCancel}>
            Cancel
          </ActionButton>
        ) : (
          <ActionButton color={editBlue} onClick={onCancel}>
            Back
          </ActionButton>
        )}
      </HeaderActions>

      <HeaderContainer>
        <CommentHeader period={period} commentType={commentType} />
        <ValidationBox error={overMaxLen > 0}>
          The comment length is {commentText.length} / {CommentText_MaxLength} letters.
        </ValidationBox>
        {typeof saveError === "string" ? (
          <ErrorBox caption="Could not save comment package" errorText={saveError} />
        ) : saveError !== null ? (
          <ErrorBox caption="Could not save comment package" apolloError={saveError || undefined} />
        ) : null}
      </HeaderContainer>
      <ContentContainer>
        {commentType === CommentPackageType.Project ? (
          <ProjectCommentSelection
            projectId={projectId}
            period={period}
            commentText={commentText}
            setCommentText={setCommentText}
            isCommentUsed={isCommentUsed}
            setCommentUsed={setCommentUsed}
          />
        ) : (
          <TotalCommentSelection
            projectId={projectId}
            period={period}
            commentText={commentText}
            setCommentText={setCommentText}
            isCommentUsed={isCommentUsed}
            setCommentUsed={setCommentUsed}
          />
        )}
        <CommentEditorContainer>
          <CommentEditor value={commentText} onChange={ev => setCommentText(ev.target.value)} />
        </CommentEditorContainer>
      </ContentContainer>
      {status && <FullscreenSpinner text={status} />}
    </Container>
  );
}

export default CommentPackageEdit;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const ValidationBox = styled.div<{ error?: boolean }>`
  display: flex;
  padding: 10px;
  justify-content: center;
  ${({ error }) => error && `color: ${cancelRed};`}
  ${({ error }) => error && `font-weight: bold;`}
  ${({ error }) => error && `background-color: ${warningYellow};`}
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`;

const CommentSelectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${settingGreen};
`;

const CommentSelectionSpinnerContainer = styled.div`
  width: 400px;
`;

const CommentEditorContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const CommentEditor = styled.textarea`
  min-width: 500px;
  width: 50vw;
  min-height: 400px;
  height: calc(100vh - 400px);
  padding: 8px;
  resize: none;
  font-size: 14px;
`;

const CommentSelectGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: ${commentBackgroundBlue};
`;

const GroupTitle = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
`;

const Icon = styled.div`
  padding: 1px;
  width: 20px;
`;

const CommentSelectGroupHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: white;
  border: 1px solid ${settingGreen};
  padding: 8px;
  text-transform: uppercase;
`;

const CommentSelectCommentsContainer = styled.div<{ height: string }>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  height: ${p => p.height};
  transition: height 100ms linear;
`;

const CommentSelectCommentsContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
`;

const Placeholder = styled.div`
  color: ${disabledGrey};
`;
