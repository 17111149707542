import React, { useCallback, useEffect, useState } from "react";
import { Action } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { useApolloClient, useLazyQuery, useMutation } from "@apollo/client/react/hooks";
import { ApolloClient } from "@apollo/client";

import Section from "../Section";
import {
  AppState,
  ProjectInformationSectionInput,
  ProjectId,
  ProjectInformation,
  ProjectInformationVisibility,
  ProjectInformationSaveResult,
  ProjectInformationInput,
  HeaderInfoData,
  HierarchyItemEnum,
  HierarchyItem,
} from "../../../../../common/types";
import {
  setProjectHierarchy,
  setProjectInformation,
  updateProjectHierarchy,
} from "../../../../../actions/projectActions";
import { BasicDataSectionType, getEmptyProjectInformationSectionInput } from "../../../../../common/constants";
import { SAVE_PROJECT_INFORMATION } from "./queries";
import { GET_HEADER_INFO, pollForBasicDataSaveReady } from "../../queries";
import ViewDetails from "./ViewDetails";
import EditDetails from "./EditDetails";
import styled from "styled-components";
import { defaultGrey } from "../../../../../common/colors";
import { cloneDeep } from "lodash";
import { pollForProjectHierarchyChangesReady } from "../../ProjectHierarchy/queries";

const mapStateToProps = (state: AppState) => {
  return {
    projectInformationInput: state.projectState.projectInput
      ? state.projectState.projectInput.projectInformation
      : getEmptyProjectInformationSectionInput(),
    inputErrors: state.projectState.projectErrors.project,
  };
};
const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, void, Action>) => {
  return {
    setProjectInformationInput: (input: ProjectInformationSectionInput) => dispatch(setProjectInformation(input)),
    updateProjectHierarchy: (projectId: ProjectId, description: string, active: boolean) => {
      dispatch(updateProjectHierarchy(projectId, description, active));
    },
    setProjectHierarchy: (hierarchy: HierarchyItem) => {
      dispatch(setProjectHierarchy(hierarchy));
    },
  };
};

interface InformationSectionProps {
  projectId: ProjectId;
  sectionDetails: ProjectInformation | undefined;
  sectionVisibility: ProjectInformationVisibility;
  sectionEditable: boolean;
  editSectionType: BasicDataSectionType | undefined;
  setEditSectionType: (type: BasicDataSectionType | undefined) => void;
  refetchDetails: () => void;
}

const TYPE: BasicDataSectionType = BasicDataSectionType.Information;

const findProjectFromHierarchyItem = (item: HierarchyItem, projectId: ProjectId): HierarchyItem | null => {
  if (item.itemType === HierarchyItemEnum.Project) {
    if (item.id === projectId) return item;
    else return null;
  } else if (item.itemType === HierarchyItemEnum.Node) {
    return item.childItems.reduce(
      (found: HierarchyItem | null, item: HierarchyItem) =>
        found ? found : findProjectFromHierarchyItem(item, projectId),
      null
    );
  } else {
    return null;
  }
};

const updateHeaderQueryCache = (
  client: ApolloClient<Record<string, unknown>>,
  projectId: number,
  update: (origin: HeaderInfoData) => void
) => {
  const cachedHeaderData = client.readQuery<HeaderInfoData>({
    query: GET_HEADER_INFO,
    variables: { id: projectId, itemType: HierarchyItemEnum.Project },
  });
  const updatedCache = cloneDeep(cachedHeaderData);
  if (updatedCache) {
    update(updatedCache);
    client.writeQuery({
      query: GET_HEADER_INFO,
      variables: { id: projectId, itemType: HierarchyItemEnum.Project },
      data: updatedCache,
    });
  }
};

function InformationSection(
  props: InformationSectionProps & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
) {
  const {
    projectId,
    sectionDetails,
    sectionVisibility,
    sectionEditable,
    editSectionType,
    setEditSectionType,
    refetchDetails,
    projectInformationInput,
    inputErrors,
    setProjectInformationInput,
    updateProjectHierarchy,
    setProjectHierarchy,
  } = props;
  const [editMode, setEditMode] = useState(false);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  // Once input is changed, clear error messages.
  useEffect(() => {
    if (errorMessages.length > 0) {
      setErrorMessages([]);
    }
  }, [projectInformationInput]);

  const client = useApolloClient() as ApolloClient<Record<string, unknown>>;

  const [saveMutation, { loading: saving }] = useMutation<{
    saveProjectInformation: ProjectInformationSaveResult;
  }>(SAVE_PROJECT_INFORMATION);

  const { pollForReady, ready, loading: polling, error: pollingError } = pollForBasicDataSaveReady();
  useEffect(() => {
    if (ready && !polling && !pollingError) {
      setEditMode(false);
      setEditSectionType(undefined);
      setProjectInformationInput(getEmptyProjectInformationSectionInput());
      refetchDetails();
    } else if (!polling && pollingError) {
      setErrorMessages(errorMessages.concat([pollingError]));
    }
  }, [ready, polling, pollingError]);

  const {
    pollForReady: pollForHierarchyChangesReady,
    ready: hierarchyReady,
    loading: pollingHierarchyReady,
    error: hierarchyReadyError,
  } = pollForProjectHierarchyChangesReady();
  const [
    queryHeaderInfo,
    { data: headerInfoData, loading: headerInfoLoading, error: headerInfoError },
  ] = useLazyQuery<HeaderInfoData>(GET_HEADER_INFO, { fetchPolicy: "network-only" });
  useEffect(() => {
    if (hierarchyReady && !pollingHierarchyReady && !hierarchyReadyError) {
      queryHeaderInfo({ variables: { id: projectId, itemType: HierarchyItemEnum.Project } });
    } else if (!pollingHierarchyReady && hierarchyReadyError) {
      setErrorMessages(errorMessages.concat([hierarchyReadyError]));
    }
  }, [hierarchyReady, pollingHierarchyReady, hierarchyReadyError]);
  useEffect(() => {
    if (headerInfoData && headerInfoData.headerInfo && !headerInfoLoading && !headerInfoError) {
      const newHierarchy = headerInfoData.headerInfo.hierarchy;
      updateHeaderQueryCache(client, projectId, origin => {
        if (origin.headerInfo) origin.headerInfo.hierarchy = newHierarchy;
      });
      setProjectHierarchy(newHierarchy);
    } else if (!headerInfoLoading && headerInfoError) {
      setErrorMessages(errorMessages.concat(headerInfoError.message));
    }
  }, [headerInfoData, headerInfoLoading, headerInfoError]);

  const save = useCallback(
    (input: ProjectInformationInput) => {
      saveMutation({
        variables: {
          projectId: projectId,
          information: input,
        },
        update: (cache, data) => {
          if (data && data.data) {
            const newDescription = data.data.saveProjectInformation.result.description;
            if (newDescription) {
              // 1) update project description in query cache
              updateHeaderQueryCache(client, projectId, origin => {
                if (origin.headerInfo) {
                  origin.headerInfo.description = newDescription;
                  const hierarchyItem = findProjectFromHierarchyItem(origin.headerInfo.hierarchy, projectId);
                  if (hierarchyItem) {
                    hierarchyItem.description = newDescription;
                  }
                }
              });
              // 2) update project description in redux
              const inp = projectInformationInput.input as ProjectInformationInput;
              const active = inp.projectStatusId === 1;
              updateProjectHierarchy(projectId, newDescription, active);
            }
          }
        },
      })
        .then(({ data }) => {
          if (data && data.saveProjectInformation && data.saveProjectInformation.applicationModifiedDateTime) {
            pollForReady(projectId, data.saveProjectInformation.applicationModifiedDateTime);
            if (data.saveProjectInformation.hierarchyModifiedDateTime) {
              pollForHierarchyChangesReady(projectId, data.saveProjectInformation.hierarchyModifiedDateTime);
            }
          } else if (data && data.saveProjectInformation.errors) {
            setErrorMessages(errorMessages.concat(data.saveProjectInformation.errors));
          }
        })
        .catch(apolloError => {
          setErrorMessages(errorMessages.concat([apolloError.message]));
        });
    },
    [saveMutation]
  );

  const savingAndPolling = saving || polling || pollingHierarchyReady || headerInfoLoading;

  const disabled = editSectionType !== undefined && editSectionType !== TYPE;

  return (
    <Section
      header="Project Information"
      disabled={disabled}
      editMode={editMode}
      editable={sectionEditable && sectionDetails !== undefined}
      saving={savingAndPolling}
      saveEnabled={!projectInformationInput.pristine && !inputErrors}
      errors={errorMessages.length > 0 ? errorMessages : undefined}
      onEditClicked={() => {
        setEditSectionType(TYPE);
        setEditMode(true);
      }}
      onCancelEdit={() => {
        setEditSectionType(undefined);
        setEditMode(false);
        setProjectInformationInput(getEmptyProjectInformationSectionInput());
      }}
      onSaveClicked={() => {
        save(projectInformationInput.input);
      }}
    >
      <Container>
        {!sectionDetails ? undefined : editMode ? (
          <EditDetails projectId={projectId} information={sectionDetails} informationVisibility={sectionVisibility} />
        ) : (
          <ViewDetails projectId={projectId} information={sectionDetails} informationVisibility={sectionVisibility} />
        )}
      </Container>
    </Section>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(InformationSection);

const Container = styled.div`
  padding: 20px;
  padding-bottom: 0px;
  color: ${defaultGrey};
  > div:last-child {
    border-bottom: 0;
    margin-bottom: 0px;
  }
`;
