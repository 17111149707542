import { useEffect, useState } from "react";

declare global {
  interface Window {
    __APP_VERSION__: string;
    __APP_VERSION_FILE__: string;
  }
}

export enum UpdateStatus {
  Checking = "Checking",
  Current = "Current",
  Available = "Available"
}

const reloadPage = () => {
  return window.location.reload(true);
};

const currentVersion = window.__APP_VERSION__;

export const useUpdateCheck = () => {
  const interval = 10000;

  const [status, setStatus] = useState(UpdateStatus.Checking);

  const checkUpdate = () => {
    if (typeof currentVersion === "undefined") {
      setStatus(UpdateStatus.Current);
      return;
    }
    setStatus(UpdateStatus.Checking);
    fetch("/" + window.__APP_VERSION_FILE__, { cache: "no-store" })
      .then(function(res) {
        return res.json();
      })
      .then(function(data) {
        if (data.version === currentVersion) {
          setStatus(UpdateStatus.Current);
        } else {
          setStatus(UpdateStatus.Available);
        }
      })
      .catch(function() {
        setStatus(UpdateStatus.Current);
      });
  };

  useEffect(
    function() {
      const timeoutId = window.setTimeout(() => {
        return checkUpdate();
      }, interval);
      return () => {
        clearTimeout(timeoutId);
      };
    },
    [interval, status]
  );
  return { status: status, reloadPage: reloadPage, checkUpdate: checkUpdate };
};
