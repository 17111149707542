import { filter } from "lodash";
import { DropdownItem } from "./EditableSearchableDropdown/EditableSearchableDropdown";
import { DropdownItem as DropdownItemForHierarchy } from "./EditableSearchableHierarchyDropdown/SearchableDropdown";
import { HierarchyDropdownItem } from "./EditableSearchableHierarchyDropdown/HierarchyDropdown";

type DropdownTypes = DropdownItem | DropdownItemForHierarchy;

export function filterAndSort(input: string, options: DropdownItem[]): DropdownItem[];
export function filterAndSort(input: string, options: DropdownItemForHierarchy[]): DropdownItemForHierarchy[];

export function filterAndSort(input: string, options: DropdownTypes[]): DropdownTypes[] {
  const inputUpperCase = input.toUpperCase();
  const score = (item: DropdownTypes): number => {
    if (item.description === input) return 1e10;
    const descUpperCase = item.description.toUpperCase();
    if (descUpperCase === inputUpperCase) return 1e9;
    const s1 = descUpperCase
      .split(" ")
      .map(x => x.trim())
      .indexOf(inputUpperCase);
    const s2 = descUpperCase.indexOf(inputUpperCase);

    return Math.max(0, s1) * 1e4 + Math.max(0, s2) * 1e2;
  };
  if (input.length === 0) {
    return options;
  } else {
    return filter(options, option => option.description.toUpperCase().includes(inputUpperCase)).sort(
      (optA, optB) => score(optB) - score(optA)
    );
  }
}

export function bottomListing(item: HierarchyDropdownItem): DropdownItemForHierarchy[] {
  return item.childItems && item.childItems.length > 0
    ? item.childItems.flatMap(child => bottomListing(child))
    : [{ id: item.id, description: item.description, depth: item.depth }];
}
