import React from "react";
import styled from "styled-components";
//import KeyNumbersSection from "./KeyNumbersSection";
//import ProjectDataSection from "./ProjectDataSection";
import ProjectReports from "./ProjectReports";

export interface ProjectsOverviewProps {
  projectId: number;
}

function ProjectOverview(props: ProjectsOverviewProps): React.ReactElement {
  return (
    <Container>
      {/*<KeyNumbersSection items={DUMMY_DATA.keyNumbers} />
      <ProjectDataSection items={DUMMY_DATA.overviewData} />*/}
      <ProjectReports projectId={props.projectId} />
    </Container>
  );
}

export default ProjectOverview;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 1000px;
  justify-content: center;
`;
