import { find } from "lodash";
import { View } from "./types";
import { ColumnSet } from "./columnsTypes";
import { AVAILABLE_COLUMNS } from "./columns";
import Cookies from "js-cookie";

export const enrichViewData = (view: View) => {
  const columnSet: ColumnSet = {
    id: view.id,
    description: view.name,
    columns: [],
    isGlobal: view.isGlobal,
  };
  view.columns.forEach(column => {
    const matchingColumn = find(AVAILABLE_COLUMNS, col => col.id === column);
    columnSet.columns.push({ id: column, name: matchingColumn ? matchingColumn.name : column });
  });
  return columnSet;
};

export const setDevCookie = () => {
  if (!Cookies.get("POP_USER_INFO")) {
    Cookies.set(
      "POP_USER_INFO",
      "eyJ1c2VybmFtZSI6Im1hdHRpLm1laWthbGFpbmVuQHZhbG1ldHBhcnRuZXJzLmNvbSIsImRpc3BsYXlOYW1lIjoiTWF0dGkgTWVpa8OkbMOkaW5lbiIsInVzZXJJZCI6InV1aWQiLCJpc0VkaXRvciI6dHJ1ZSwiaXNBZG1pbiI6IHRydWV9"
    );
  }
};

export const resetCookies = (redirectUri?: string) => {
  Cookies.remove("POP_USER_INFO");
  Cookies.remove("POP_SESSION");
  if (redirectUri !== undefined) Cookies.set("POP_REDIRECT_AFTER_LOGIN", redirectUri);
};

export const parseNumberOrNull = (text: string | null) => {
  const parsed = text !== null ? parseInt(text) : undefined;
  return parsed !== undefined ? parsed : null;
};

export const base64UnicodeDecode = (str: string) => {
  return decodeURIComponent(
    atob(str)
      .split("")
      .map(char => {
        return "%" + ("00" + char.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
};

export const Format = {
  withThousandSeparator: (x: number, decimals: number) => {
    return x
      .toLocaleString("en-US", {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
      })
      .replaceAll(",", " ");
  },
};
