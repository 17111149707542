import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  defaultGrey,
  filterGreen,
  greyTransparent,
  valmetGreyBorder,
} from "../../../../../common/colors";
import { MAX_NODE_NAME_LENGTH } from "../../../../../common/constants";
import { HierarchyTreeItem } from "../../../../../common/types";
import { ActionButton, ButtonContainer } from "../../../../../common/components";
import { ActionsSection, NameInput } from "./shared-components";

export interface CreateNodeDialogProps {
  onCreate: (nodeName: string) => void;
  onCancel: () => void;
  onEdit: (nodeName: string) => void;
  editableItem?: HierarchyTreeItem;
}

function CreateNodeDialog(props: CreateNodeDialogProps): React.ReactElement {
  const { onCancel, onCreate, editableItem, onEdit } = props;
  const [nodeName, setNodeName] = useState(editableItem?.description || "");

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <FullscreenWrapper>
      <Container>
        <ContentContainer>
          <TitleContainer>{editableItem ? `Edit node "${editableItem.description}"` : "Node creation"}</TitleContainer>
          <ContentSection>
            <NameInput
              type="text"
              value={nodeName}
              onChange={event => setNodeName(event.target.value)}
              placeholder={"Input node name..."}
              maxLength={MAX_NODE_NAME_LENGTH}
            />
          </ContentSection>
          <ActionsSection>
            <ButtonContainer disabledSave={!nodeName.length}>
              <ActionButton onClick={() => (editableItem ? onEdit(nodeName) : onCreate(nodeName))}>
                {editableItem ? "Edit node" : "Create node"}
              </ActionButton>
              <ActionButton
                onClick={() => {
                  setNodeName("");
                  onCancel();
                }}
              >
                Cancel
              </ActionButton>
            </ButtonContainer>
          </ActionsSection>
        </ContentContainer>
      </Container>
    </FullscreenWrapper>
  );
}

export default CreateNodeDialog;

const FullscreenWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1200;
  background: ${greyTransparent};
  overflow-y: hidden;
`;

const Container = styled.div`
  background: white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  border: 1px solid ${valmetGreyBorder};
  z-index: 1500;
  font-size: 24px;
  color:${defaultGrey}
  padding-top:20px;
  padding-bottom:20px;
  padding-left:25px;
  padding-right:25px;
  button:hover {
    background: ${filterGreen};
  }
  width: 675px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleContainer = styled.div``;

const ContentSection = styled.div`
  margin-top: 20px;
`;
