import { ProjectVirtualType } from "../../../../../common/types";
import {
  DataCell,
  DataContainer,
  DataRow,
  DetailsTable,
  HeaderCell,
  HeaderRow,
  TableBody,
  TypedDataCell,
} from "./components";
import { justifyToRight } from "./column";
import { ContextMenu, ContextMenuTrigger, MenuItem } from "react-contextmenu";
import { CollapseButton } from "../../../../../common/components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { valmetGreyLight } from "../../../../../common/colors";
import React, { useCallback, useEffect, useState } from "react";
import { PROJECT_COST_WAREHOUSE_COLUMNS, PROJECT_COST_WAREHOUSE_COLUMNS_SER_ORDER } from "./constants";
import {
  ProjectCostWarehouseCostLineItem,
  ProjectCostWarehouseOrderItem,
  ProjectCostWarehouseSubItem,
  ProjectCostWarehouseTableItem,
  SerOrderCostWarehouseCostLineItem,
  SerOrderCostWarehouseSubItem,
  SerOrderCostWarehouseOrderItem,
} from "./types";
import { CostDetailsWarehouseItem } from "../types";

type Props = {
  warehouseItems: CostDetailsWarehouseItem[];
  projectVirtualType: ProjectVirtualType;
  popupWindow: boolean;
};

const orUndefined = <T extends unknown>(v: T | null): T | undefined => (v === null ? undefined : v);

const composeId = (parent: string, id: string): string => parent + "_" + id;

const transformProjectLineItem = (
  item: ProjectCostWarehouseCostLineItem,
  parentId: string,
  last: boolean
): ProjectCostWarehouseTableItem => {
  return {
    _original: item,
    id: composeId(parentId, item.itemId),
    description: item.description,
    childLevel: 3,
    supplier: orUndefined(item.supplier),
    item: orUndefined(item.item),
    quantity: item.actualQuantity,
    orderCurrency: orUndefined(item.orderCurrency),
    transactionDate: orUndefined(item.transactionDate),
    softCommitments: item.softCommitments,
    hardCommitments: item.hardCommitments,
    actuals: item.actuals,
    total: item.total,
    last,
  };
};

const transformSerOrderLineItem = (
  item: SerOrderCostWarehouseCostLineItem,
  parentId: string,
  last: boolean
): ProjectCostWarehouseTableItem => {
  return {
    _original: item,
    id: composeId(parentId, item.itemId),
    description: item.description,
    childLevel: 3,
    supplier: orUndefined(item.supplier),
    item: orUndefined(item.item),
    orderLineAmount: item.orderLineAmount,
    quantity: item.actualQuantity,
    orderCurrency: orUndefined(item.orderCurrency),
    transactionDate: orUndefined(item.transactionDate),
    receivedDate: orUndefined(item.receivedDate),
    invoicedQuantity: item.invoicedQuantity,
    invoicedDate: orUndefined(item.invoicedDate),
    date: orUndefined(item.date),
    invoicePictureUrl: orUndefined(item.invoicePictureUrl),
    orderPictureUrl: orUndefined(item.orderPictureUrl),
    last,
  };
};

const transformProjectSubItem = (
  item: ProjectCostWarehouseSubItem,
  parentId: string,
  last: boolean
): ProjectCostWarehouseTableItem => {
  const id = composeId(parentId, item.itemId);
  return {
    _original: item,
    id,
    description: item.description,
    childLevel: 2,
    childItems: item.childItems.map((ch, index) =>
      transformProjectLineItem(ch, id, index === item.childItems.length - 1)
    ),
    orderCurrency: orUndefined(item.aggregatedValues.orderCurrency),
    actuals: item.aggregatedValues.actuals,
    hardCommitments: item.aggregatedValues.hardCommitments,
    softCommitments: item.aggregatedValues.softCommitments,
    total: item.aggregatedValues.total,
    last,
  };
};

const transformSerOrderSubItem = (
  item: SerOrderCostWarehouseSubItem,
  parentId: string,
  last: boolean
): ProjectCostWarehouseTableItem => {
  const id = composeId(parentId, item.itemId);
  return {
    _original: item,
    id,
    description: item.description,
    childLevel: 2,
    childItems: item.childItems.map((ch, index) =>
      transformSerOrderLineItem(ch, id, index === item.childItems.length - 1)
    ),
    orderCurrency: orUndefined(item.aggregatedValues.orderCurrency),
    orderLineAmount: item.aggregatedValues.orderLineAmount,
    last,
  };
};

const transformProjectOrderItem = (
  item: ProjectCostWarehouseOrderItem,
  parentId: string
): ProjectCostWarehouseTableItem => {
  const id = composeId(parentId, item.itemId);
  return {
    _original: item,
    id,
    description: item.description,
    childLevel: 1,
    childItems: item.childItems.map((ch, index) =>
      transformProjectSubItem(ch, id, index === item.childItems.length - 1)
    ),
    orderCurrency: orUndefined(item.aggregatedValues.orderCurrency),
    actuals: item.aggregatedValues.actuals,
    hardCommitments: item.aggregatedValues.hardCommitments,
    softCommitments: item.aggregatedValues.softCommitments,
    total: item.aggregatedValues.total,
  };
};

const transformSerOrderOrderItem = (
  item: SerOrderCostWarehouseOrderItem,
  parentId: string
): ProjectCostWarehouseTableItem => {
  const id = composeId(parentId, item.itemId);
  return {
    _original: item,
    id,
    description: item.description,
    childLevel: 1,
    childItems: item.childItems.map((ch, index) =>
      transformSerOrderSubItem(ch, id, index === item.childItems.length - 1)
    ),
    orderCurrency: orUndefined(item.aggregatedValues.orderCurrency),
    orderLineAmount: item.aggregatedValues.orderLineAmount,
  };
};

const transformItem = (item: CostDetailsWarehouseItem): ProjectCostWarehouseTableItem => {
  const id = item.itemId;
  switch (item.__typename) {
    case "ProjectCostWarehouseItem":
      return {
        _original: item,
        id,
        description: item.description,
        childLevel: 0,
        childItems: item.childItems.map(ch => transformProjectOrderItem(ch, id)),
        orderCurrency: orUndefined(item.aggregatedValues.orderCurrency),
        actuals: item.aggregatedValues.actuals,
        hardCommitments: item.aggregatedValues.hardCommitments,
        softCommitments: item.aggregatedValues.softCommitments,
        total: item.aggregatedValues.total,
      };
    case "SerOrderCostWarehouseItem":
      return {
        _original: item,
        id,
        description: item.description,
        childLevel: 0,
        childItems: item.childItems.map(ch => transformSerOrderOrderItem(ch, id)),
        orderCurrency: orUndefined(item.aggregatedValues.orderCurrency),
        orderLineAmount: item.aggregatedValues.orderLineAmount,
      };
  }
};

function getAllChildren(item: ProjectCostWarehouseTableItem, result: Set<string>): void {
  item.childItems?.forEach(child => {
    result.add(child.id);
    getAllChildren(child, result);
  });
}

function expand(item: ProjectCostWarehouseTableItem, open: Set<string>): ProjectCostWarehouseTableItem[] {
  const result: ProjectCostWarehouseTableItem[] = [];
  open.add(item.id);
  item.childItems?.forEach(child => {
    result.push(child);
    const children = expand(child, open);
    children.forEach(c => result.push(c));
  });
  return result;
}

function WarehouseTable(props: Props) {
  const { warehouseItems, projectVirtualType, popupWindow } = props;

  const [openItems, setOpenItems] = useState<Set<string>>(new Set());
  const [renderedRows, setRenderedRows] = useState<ProjectCostWarehouseTableItem[]>([]);

  useEffect(() => {
    const newRows = warehouseItems.map(transformItem);
    console.log("Warehouse Rows", newRows);
    if (newRows.length > 0 && newRows[0].childItems) {
      setOpenItems(openItems.add(newRows[0].id));
      setRenderedRows(newRows.concat(newRows[0].childItems));
    } else {
      setRenderedRows(newRows);
    }
  }, [warehouseItems]);

  const expandAll = useCallback(
    (item: ProjectCostWarehouseTableItem) => {
      const itemsToClose = new Set<string>();
      getAllChildren(item, itemsToClose);

      // First close the sub hierarchy
      const closedHierarchy = renderedRows.filter(row => !itemsToClose.has(row.id));

      const children = expand(item, openItems);
      const newRows = closedHierarchy.flatMap(it => {
        if (item.id === it.id) {
          return [it].concat(children);
        } else {
          return [it];
        }
      });
      console.log("Warehouse Rows", newRows);
      setRenderedRows(newRows);
      setOpenItems(openItems);
    },
    [renderedRows, setRenderedRows, openItems, setOpenItems]
  );

  const openItem = useCallback(
    (item: ProjectCostWarehouseTableItem) => {
      const newRows = renderedRows.flatMap(it => {
        if (item.id === it.id && it.childItems) {
          return [it].concat(it.childItems);
        } else {
          return [it];
        }
      });
      setRenderedRows(newRows);
      setOpenItems(openItems.add(item.id));
    },
    [renderedRows, setRenderedRows, openItems, setOpenItems]
  );

  const closeItem = useCallback(
    (item: ProjectCostWarehouseTableItem) => {
      const itemsToClose = new Set<string>();
      getAllChildren(item, itemsToClose);

      const newOpenItems = openItems;
      itemsToClose.forEach(id => newOpenItems.delete(id));
      newOpenItems.delete(item.id);
      setOpenItems(newOpenItems);

      setRenderedRows(renderedRows.filter(row => !itemsToClose.has(row.id)));
    },
    [renderedRows, setRenderedRows, openItems, setOpenItems]
  );

  const columns =
    projectVirtualType === ProjectVirtualType.LnServiceOrder
      ? PROJECT_COST_WAREHOUSE_COLUMNS_SER_ORDER
      : PROJECT_COST_WAREHOUSE_COLUMNS;

  return (
    <DetailsTable>
      <TableBody>
        <HeaderRow>
          <HeaderCell right={false} popupWindow={popupWindow}>
            Description
          </HeaderCell>
          {columns.map((column, i) => (
            <HeaderCell key={i} right={justifyToRight(column)} popupWindow={popupWindow}>
              {column.description}
            </HeaderCell>
          ))}
        </HeaderRow>
        {renderedRows.map(item => (
          <DataRow
            key={item.id}
            childLevel={item.childLevel}
            last={item.last}
            extraPadding={!item.childItems || item.childItems.length === 0}
          >
            <DataCell>
              <DataContainer>
                {item.childItems && item.childItems.length > 0 && (
                  <ContextMenuTrigger id={`${item.id}-context-menu`}>
                    <CollapseButton
                      onClick={e => {
                        e.stopPropagation();
                        if (item.childItems && item.childItems.length > 0) {
                          if (openItems.has(item.id)) {
                            closeItem(item);
                          } else {
                            openItem(item);
                          }
                        }
                      }}
                    >
                      <FontAwesomeIcon
                        icon={openItems.has(item.id) ? faChevronUp : faChevronDown}
                        size="1x"
                        color={valmetGreyLight}
                      />
                    </CollapseButton>
                  </ContextMenuTrigger>
                )}
                {item.description}
                <ContextMenu id={`${item.id}-context-menu`}>
                  <MenuItem onClick={() => expandAll(item)}>Expand all</MenuItem>
                  <MenuItem onClick={() => closeItem(item)}>Collapse all</MenuItem>
                </ContextMenu>
              </DataContainer>
            </DataCell>
            {columns.map((column, i) => (
              <TypedDataCell key={item.id + "_" + i} column={column} item={item} />
            ))}
          </DataRow>
        ))}
      </TableBody>
    </DetailsTable>
  );
}

export default WarehouseTable;
