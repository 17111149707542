import { createStore, combineReducers, applyMiddleware, AnyAction, Reducer } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import authReducer from "../reducers/authReducer";
import filtersReducer from "../reducers/filtersReducer";
import historyReducer from "../reducers/historyReducer";
import columnSetReducer from "../reducers/columnSetReducer";
import settingsReducer from "../reducers/settingsReducer";
import projectReducer, {
  initNewProjectAction,
  initProjectAction,
  initState as projectInit,
} from "../reducers/projectReducer";
import hierarchyReducer from "../reducers/hierarchyReducer";
import recognitionsEditReducer from "../reducers/recognitionsEditReducer";
import costsReducer from "../reducers/costsReducer";
import projectHeaderReducer from "../reducers/projectHeaderReducer";
import { readItemTypeFromPath, readProjectIdFromPath } from "../components/sites/Project/utils";
import { HierarchyItemEnum } from "../common/types";
import { RootReducerAction } from "./rootReducerAction";
import userPreferencesReducer from "../reducers/userPreferencesReducer";

const combinedReducer = combineReducers({
  authState: authReducer,
  filtersState: filtersReducer,
  historyState: historyReducer,
  columnSetState: columnSetReducer,
  settingsState: settingsReducer,
  projectState: projectReducer,
  recognitionEditState: recognitionsEditReducer,
  hierarchyState: hierarchyReducer,
  costsState: costsReducer,
  projectHeaderState: projectHeaderReducer,
  userPreferencesState: userPreferencesReducer,
});

export const preloadedState = () => {
  // Because refreshing page will lose Redux state, we add preloaded state here for initializing states based on url pathname and local storage
  // Preloaded state wins initState.
  // Read more here: https://redux.js.org/usage/structuring-reducers/initializing-state
  const itemType = readItemTypeFromPath();
  const projectId = readProjectIdFromPath(itemType);
  if (itemType === HierarchyItemEnum.NewProject) {
    return { projectState: initNewProjectAction(projectInit) };
  } else if (projectId !== undefined) {
    return { projectState: initProjectAction(projectInit, projectId) };
  } else {
    return undefined;
  }
};

const reducer: Reducer = (state: ReturnType<typeof reducer> | undefined, action: AnyAction) => {
  if (action.type === RootReducerAction.RESET) {
    const preloaded = preloadedState();
    if (preloaded !== undefined) {
      return { ...state, projectState: preloaded.projectState };
    }
  }
  return combinedReducer(state, action);
};

export default createStore(reducer, preloadedState(), composeWithDevTools(applyMiddleware(thunk)));
