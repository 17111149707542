import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import SearchableDropdown, { DropdownItem } from "./SearchableDropdown";
import { find } from "lodash";
import HierarchyDropdown, { HierarchyDropdownItem } from "./HierarchyDropdown";
import { InputIconButton } from "../../../../../../common/components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { valmetGreyLight, warningYellow } from "../../../../../../common/colors";
import { bottomListing, filterAndSort } from "../utils";
import { useMount } from "../../../../../../hooks/useMount";

type SingleSelection = { isSingleSelection: true; value: string };
type MultiSelections = { isSingleSelection: false; values: string[] };

interface EditableSearchableHierarchyDropdownProps {
  selected: SingleSelection | MultiSelections;
  hierarchy: HierarchyDropdownItem[];
  onValueChanged: (value: string | null) => void;
  onDescChanged: (desc: string) => void;
  searchable?: boolean;
  disabled?: boolean;
  error?: boolean;
  maxResults?: number;
  inputWidth?: string | number;
  nonNullable?: boolean;
  disabledValueIds?: string[];
  flexible?: boolean;
  scrollingDisabled?: boolean;
  onLoadMore?: () => void;
  morePagesAvailable?: boolean;
  dataError?: boolean;
  loading?: boolean;
  maxHierarchyDepth?: number; // Items deeper than this will be flattened.
  minSelectableDepth?: number; // Starts from which depth, items are selectable. If this is undefined, only bottom items are selectable.
}

const DEFAULT_MAX_HIERARCHY_DEPTH = 2; // Top depth is 0 and items deeper than max depth will be flattened.
const MIN_FLEX_SIZE = 5;

const getLastSelectedDesc = (selected: SingleSelection | MultiSelections, searchableItems: DropdownItem[]): string => {
  const findItem =
    selected.isSingleSelection && selected.value
      ? find(searchableItems, option => option.id === selected.value)
      : !selected.isSingleSelection && selected.values.length > 0
      ? find(searchableItems, option => option.id === selected.values[0])
      : undefined;
  return findItem == undefined ? "" : findItem.description;
};

function EditableSearchableHierarchyDropdown(props: EditableSearchableHierarchyDropdownProps): React.ReactElement {
  const {
    selected,
    hierarchy,
    onValueChanged,
    onDescChanged,
    searchable,
    disabled,
    error,
    maxResults,
    nonNullable,
    inputWidth,
    disabledValueIds,
    flexible,
    scrollingDisabled,
    onLoadMore,
    morePagesAvailable,
    dataError,
    loading,
    maxHierarchyDepth,
    minSelectableDepth,
  } = props;
  const [inputText, setInputText] = useState<string>("");
  const [selectedOptionDescription, setSelectedOptionDescription] = useState<string>("");
  const [isOpen, setIsOpen] = useState<boolean>(false);

  // only bottom items are selectable and searchable
  const searchableItems = useMemo(() => hierarchy.flatMap(item => bottomListing(item)), [hierarchy]);

  const opts = useMemo(
    () =>
      searchable && searchableItems
        ? (filterAndSort(inputText, searchableItems) as DropdownItem[])
        : searchableItems || [],
    [searchable, searchableItems, inputText]
  );

  // items deeper than max depth are flattened.
  function flattenDeepItems(item: HierarchyDropdownItem): HierarchyDropdownItem {
    return {
      id: item.id,
      desc: item.desc,
      description: item.description,
      depth: item.depth,
      childItems:
        !item.childItems || item.childItems.length === 0
          ? undefined
          : item.depth + 1 < (maxHierarchyDepth || DEFAULT_MAX_HIERARCHY_DEPTH)
          ? item.childItems.map(child => flattenDeepItems(child))
          : item.childItems.flatMap(bottomListing).map(i => {
              return {
                id: i.id,
                desc: i.description
                  .split("/")
                  .splice(item.depth + 1)
                  .join("/"),
                description: i.description,
                depth: i.depth,
                childItems: undefined,
              };
            }),
    };
  }
  const exceedsMaxDepth = useMemo(
    () => Math.max(...searchableItems.map(item => item.depth)) > (maxHierarchyDepth || DEFAULT_MAX_HIERARCHY_DEPTH),
    [searchableItems]
  );
  const flattenedHierarchy = useMemo(
    () => (exceedsMaxDepth ? hierarchy.map(item => flattenDeepItems(item)) : hierarchy),
    [hierarchy, exceedsMaxDepth]
  );

  const [openItems, setOpenItems] = useState<string[]>([]);
  const openItem = (id: string) => {
    if (!openItems.includes(id)) setOpenItems([...openItems, id]);
  };
  const closeItem = (id: string) => setOpenItems(openItems.filter(i => i !== id));

  useEffect(() => {
    const desc = getLastSelectedDesc(selected, searchableItems);
    setSelectedOptionDescription(desc);
    setInputText(desc);
    onDescChanged(desc);
  }, [selected, searchableItems]);

  const getWidth = useMemo(() => {
    const actualInputWidth = flexible
      ? inputText.length > MIN_FLEX_SIZE
        ? inputText.length + 1
        : MIN_FLEX_SIZE
      : inputWidth
      ? inputWidth
      : "";

    if (actualInputWidth !== undefined) {
      if (typeof actualInputWidth === "number") {
        //A bit of an hax to get Chrome work with width
        return `${actualInputWidth}${flexible ? "ch" : "px"}`;
      } else {
        switch (actualInputWidth) {
          case "small":
            return "100px";
          case "smallest":
            return "75px";
          default:
            return "160px";
        }
      }
    }
  }, [flexible, inputText.length, inputWidth]);

  const isSearching = searchable && inputText.length > 0 && inputText !== selectedOptionDescription;

  return (
    <Container
      onFocus={() => {
        setIsOpen(true);
      }}
      onBlur={e => {
        if (!e.currentTarget.contains(e.relatedTarget as Node)) {
          setInputText(selectedOptionDescription);
          setIsOpen(false);
        }
      }}
    >
      <TextInput
        type="text"
        value={dataError ? "Error loading data" : inputText}
        readOnly={!searchable}
        onChange={event => setInputText(event.target.value)}
        disabled={disabled || dataError}
        error={error}
        placeholder={searchable ? "Select or search..." : "Select..."}
        searchable={searchable}
        inputWidth={getWidth}
      />
      {!disabled && !nonNullable && (
        <InputIconButton
          onClick={() => {
            onValueChanged(null);
            setInputText("");
          }}
          disabled={disabled}
        >
          <FontAwesomeIcon icon={faTimes} size="1x" color={valmetGreyLight} />
        </InputIconButton>
      )}
      {isOpen &&
        (!isSearching ? (
          <HierarchyDropdown
            selection={selected.isSingleSelection ? selected.value.toString() : selected.values.map(v => v.toString())}
            onValueSelected={(value, _) => {
              onValueChanged(value);
              if (selected.isSingleSelection) setIsOpen(false);
            }}
            hierarchy={flattenedHierarchy}
            openItems={openItems}
            openItem={openItem}
            closeItem={closeItem}
            inputWidth={getWidth}
            maxResults={maxResults}
            loading={loading}
            minSelectableDepth={minSelectableDepth}
          />
        ) : (
          <SearchableDropdown
            onValueSelected={(value, _) => {
              onValueChanged(value);
              setIsOpen(false);
            }}
            values={opts}
            selection={selected.isSingleSelection ? selected.value : selected.values}
            maxResults={maxResults}
            inputWidth={getWidth}
            disabledValueIds={disabledValueIds}
            scrollingDisabled={scrollingDisabled}
            onLoadMore={onLoadMore}
            morePagesAvailable={morePagesAvailable}
            loading={loading}
          />
        ))}
    </Container>
  );
}

export default EditableSearchableHierarchyDropdown;

const Container = styled.div`
  display: flex;
`;

const TextInput = styled.input<{
  error?: boolean;
  searchable?: boolean;
  inputWidth?: string;
}>`
  ${({ inputWidth }) => inputWidth && `width: ${inputWidth};`}
  font-weight: bold;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${({ error }) => error && `border: 2px solid red; background: ${warningYellow}`};
  ${({ searchable }) => (searchable ? "cursor: text;" : "cursor: context-menu;")};
  padding-right: 12px;
`;
