import React, { useCallback, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import {
  AppState,
  BasicDataCurrency,
  BasicDataCurrencyResult,
  FieldProperties,
  ProjectCurrency,
  ProjectCurrencyEditInformation,
  ProjectCurrencyInput,
  ProjectCurrencySectionInput,
  ProjectCurrencyVisibility,
  ProjectId,
} from "../../../../../common/types";
import { ThunkDispatch } from "redux-thunk";
import { Action } from "redux";
import { setProjectCurrency, setProjectError } from "../../../../../actions/projectActions";
import { useQuery } from "@apollo/client/react/hooks";
import { GET_BASIC_DATA_CURRENCY, GET_PROJECT_CURRENCY_EDIT_INFORMATION } from "./queries";
import LoadingView from "../../../../LoadingView";
import styled from "styled-components";
import EditableSearchableDropdown from "../EditableComponents/EditableSearchableDropdown";

export interface EditDetailsProps {
  projectId: ProjectId;
  currency: ProjectCurrency;
  currencyVisibility: ProjectCurrencyVisibility;
}

const mapStateToProps = (state: AppState) => {
  return {
    sectionInput: state.projectState.projectInput ? state.projectState.projectInput.projectCurrency : undefined,
    editInformation: state.projectState.projectDetailsEditInformation
      ? state.projectState.projectDetailsEditInformation.projectCurrency
      : undefined,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, void, Action>) => {
  return {
    setProjectCurrencyInput: (input: ProjectCurrencySectionInput) => dispatch(setProjectCurrency(input)),
    setProjectError: (value: boolean) => {
      dispatch(setProjectError(value, "currency"));
    },
  };
};

const checkInputErrors = (
  sectionInput: ProjectCurrencyInput | undefined,
  editInformation: ProjectCurrencyEditInformation | undefined
) => {
  if (sectionInput && editInformation) {
    const errors = [];
    if (
      sectionInput.contractCurrency === null &&
      editInformation.contractCurrencyProperties.nullable !== null &&
      !editInformation.contractCurrencyProperties.nullable
    ) {
      errors.push("contractCurrency");
    }
    if (
      sectionInput.legalEntityCurrency === null &&
      editInformation.legalEntityCurrencyProperties.nullable !== null &&
      !editInformation.legalEntityCurrencyProperties.nullable
    ) {
      errors.push("legalEntityCurrency");
    }
    if (
      sectionInput.revenueRecognitionCurrency === null &&
      editInformation.revenueRecognitionCurrencyProperties.nullable !== null &&
      !editInformation.revenueRecognitionCurrencyProperties.nullable
    ) {
      errors.push("revenueRecognitionCurrency");
    }
    if (
      sectionInput.externalReportingCurrency === null &&
      editInformation.externalReportingCurrencyProperties.nullable !== null &&
      !editInformation.externalReportingCurrencyProperties.nullable
    ) {
      errors.push("externalReportingCurrency");
    }
    if (
      sectionInput.internalReportingCurrency === null &&
      editInformation.internalReportingCurrencyProperties.nullable !== null &&
      !editInformation.internalReportingCurrencyProperties.nullable
    ) {
      errors.push("internalReportingCurrency");
    }
    return errors;
  } else {
    return [];
  }
};

function EditDetails(
  props: EditDetailsProps & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
): React.ReactElement {
  const {
    projectId,
    currency,
    currencyVisibility,
    sectionInput,
    editInformation,
    setProjectCurrencyInput: setSectionInput,
    setProjectError,
  } = props;
  const projectCurrencyInput: ProjectCurrencyInput | undefined = sectionInput ? sectionInput.input : undefined;

  // If it's project creation mode, all edit information is queried together and saved into Redux.
  // If it's not project creation mode, edit information is queried separately in sections
  // and it should be queried every time when EditDetails is loaded, so we don't save this data into Redux.
  const { data, error, loading } = useQuery<{
    projectDetailsEditInformation: { projectCurrency: ProjectCurrencyEditInformation };
  }>(GET_PROJECT_CURRENCY_EDIT_INFORMATION, {
    variables: { projectId: projectId },
    skip: editInformation !== undefined,
  });

  const editInfo: ProjectCurrencyEditInformation | undefined =
    editInformation || (!loading && !error && data ? data.projectDetailsEditInformation.projectCurrency : undefined);

  const {
    loading: currenciesLoading,
    data: currenciesData,
    error: currenciesError,
  } = useQuery<BasicDataCurrencyResult>(GET_BASIC_DATA_CURRENCY, {});

  const onProjectCurrencyChange = useCallback(() => {
    if (editInfo) {
      setSectionInput({
        input: {
          projectCurrency:
            currency.projectCurrency !== undefined && editInfo.projectCurrencyProperties.editable
              ? currency.projectCurrency
              : null,
          contractCurrency:
            currency.contractCurrency !== undefined && editInfo.contractCurrencyProperties.editable
              ? currency.contractCurrency
              : null,
          legalEntityCurrency:
            currency.legalEntityCurrency !== undefined && editInfo.legalEntityCurrencyProperties.editable
              ? currency.legalEntityCurrency
              : null,
          revenueRecognitionCurrency:
            currency.revenueRecognitionCurrency !== undefined && editInfo.revenueRecognitionCurrencyProperties.editable
              ? currency.revenueRecognitionCurrency
              : null,
          externalReportingCurrency:
            currency.externalReportingCurrency !== undefined && editInfo.externalReportingCurrencyProperties.editable
              ? currency.externalReportingCurrency
              : null,
          internalReportingCurrency:
            currency.internalReportingCurrency !== undefined && editInfo.internalReportingCurrencyProperties.editable
              ? currency.internalReportingCurrency
              : null,
        },
        pristine: true,
      });
    } else {
      setSectionInput({
        input: {
          projectCurrency: null,
          contractCurrency: null,
          legalEntityCurrency: null,
          revenueRecognitionCurrency: null,
          externalReportingCurrency: null,
          internalReportingCurrency: null,
        },
        pristine: true,
      });
    }
  }, [setSectionInput, currency, editInfo]);

  useEffect(onProjectCurrencyChange, [currency, editInfo]);

  const inputErrors = useMemo(() => checkInputErrors(projectCurrencyInput, editInfo), [projectCurrencyInput, editInfo]);
  useEffect(() => {
    setProjectError(inputErrors.length > 0);
  }, [inputErrors]);

  type CurrencyFieldKey = keyof ProjectCurrency;

  const CurrencySelect = (title: string, key: CurrencyFieldKey, fieldProps: FieldProperties) => {
    const inputValue: BasicDataCurrency | null = projectCurrencyInput ? projectCurrencyInput[key] : null;
    const cur: BasicDataCurrency | null = currency[key];
    return (
      <DataCell>
        {currencyVisibility[key] && projectCurrencyInput && (
          <>
            <DataTitle>{title}</DataTitle>
            {fieldProps.editable ? (
              <EditableSearchableDropdown
                value={inputValue !== null ? inputValue : ""}
                onValueChanged={value =>
                  setSectionInput({
                    input: { ...projectCurrencyInput, [key]: value },
                    pristine: false,
                  })
                }
                options={
                  currenciesData
                    ? currenciesData.basicDataCurrency.map(currency => {
                        return { id: currency, description: currency };
                      })
                    : []
                }
                disabled={!fieldProps.editable}
                error={inputErrors.includes(key)}
                searchable={true}
                maxResults={20}
                inputWidth={"smallest"}
              />
            ) : (
              <DataItem>{cur ? cur : "-"}</DataItem>
            )}
          </>
        )}
      </DataCell>
    );
  };

  return loading || currenciesLoading || !sectionInput ? (
    <LoadingView />
  ) : projectCurrencyInput && editInfo && currenciesData && !error && !currenciesError ? (
    <CurrencySection>
      {CurrencySelect("Contract currency", "contractCurrency", editInfo.contractCurrencyProperties)}
      {CurrencySelect("Legal company's currency", "legalEntityCurrency", editInfo.legalEntityCurrencyProperties)}
      {CurrencySelect(
        "Revenue recognition currency",
        "revenueRecognitionCurrency",
        editInfo.revenueRecognitionCurrencyProperties
      )}
      {CurrencySelect(
        "External reporting currency",
        "externalReportingCurrency",
        editInfo.externalReportingCurrencyProperties
      )}
      {CurrencySelect(
        "Internal reporting currency",
        "internalReportingCurrency",
        editInfo.internalReportingCurrencyProperties
      )}
    </CurrencySection>
  ) : (
    <LoadingContainer>Error loading project currency data.</LoadingContainer>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(EditDetails);

const CurrencySection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;

const DataCell = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const DataTitle = styled.div`
  font-size: 14px;
  margin-bottom: 4px;
`;

const DataItem = styled.div`
  font-weight: bold;
  font-size: 14px;
`;

const LoadingContainer = styled.div``;
