import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { useQuery } from "@apollo/client/react/hooks";
import { defaultGrey, valmetGreyBorder } from "../../../../../common/colors";
import { disabledFinancingMethods, PROJECT_EDIT_CHAR_LIMIT } from "../../../../../common/constants";
import {
  AppState,
  AutReportingCountriesResult,
  CountriesResult,
  FieldProperties,
  FinancingMethodsResult,
  FreetextProjectDescription,
  OrganisationKeysResult,
  PerformanceObligationsResult,
  ProjectDeliveryLocationsResult,
  ProjectInformation,
  ProjectInformationEditInformation,
  ProjectInformationEditInformationData,
  ProjectInformationInput,
  ProjectInformationVisibility,
  ProjectPhasesResult,
  ProjectStatusesResult,
  ProjectTechnicalTypesResult,
  ProjectTypesResult,
} from "../../../../../common/types";
import { parseNumberOrNull } from "../../../../../common/utils";
import EditableSearchableDropdown from "../EditableComponents/EditableSearchableDropdown";
import EditableText from "../EditableComponents/EditableText";
import InformationDataSection from "./EditableDataSections/InformationDataSection";
import OrganisationDataSection from "./EditableDataSections/OrganisationDataSection";
import { DetailsField, checkInputErrors } from "./checkInputErrors";
import {
  getDataItem,
  checkAutReportingCountryEditable,
  checkAutSiteCodeEditable,
  editClone,
  inheritOrgValue,
  CopiedIcon,
} from "../utils";
import { ThunkDispatch } from "redux-thunk";
import { Action } from "redux";
import { ProjectInformationSectionInput } from "../../../../../common/types";
import { setProjectError, setProjectInformation } from "../../../../../actions/projectActions";
import {
  GET_AUT_REPORTING_COUNTRIES,
  GET_COUNTRIES,
  GET_DELIVERY_LOCATIONS,
  GET_FINANCING_METHODS,
  GET_ORGANISATION_KEYS,
  GET_PERFORMANCE_OBLIGATIONS,
  GET_PROJECT_INFORMATION_EDIT_INFORMATION,
  GET_PROJECT_PHASES,
  GET_PROJECT_STATUSES,
  GET_PROJECT_TECHNICAL_TYPES,
  GET_PROJECT_TYPES,
} from "./queries";
import LoadingView from "../../../../LoadingView";
import { UnlockIcon } from "../../../../../common/components";

interface EditDetailsProps {
  projectId: number;
  information: ProjectInformation;
  informationVisibility: ProjectInformationVisibility;
}

const mapStateToProps = (state: AppState) => {
  return {
    projectCreationMode: state.projectState.projectCreationMode,
    projectInformationInput: state.projectState.projectInput
      ? state.projectState.projectInput.projectInformation
      : undefined,
    editInformation: state.projectState.projectDetailsEditInformation
      ? state.projectState.projectDetailsEditInformation.projectInformation
      : undefined,
    hierarchy: state.projectState.hierarchy,
  };
};
const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, void, Action>) => {
  return {
    setProjectInformationInput: (input: ProjectInformationSectionInput) => dispatch(setProjectInformation(input)),
    setProjectError: (value: boolean) => {
      dispatch(setProjectError(value, "project"));
    },
  };
};

function EditDetails(
  props: EditDetailsProps & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
): React.ReactElement {
  const {
    projectId,
    information,
    informationVisibility,
    hierarchy,
    projectCreationMode,
    editInformation,
    projectInformationInput: sectionInput,
    setProjectInformationInput: setSectionInput,
    setProjectError,
  } = props;
  const [editInfo, setEditInfo] = useState<ProjectInformationEditInformation | undefined>(editInformation);
  useEffect(() => {
    setEditInfo(editInformation);
  }, [editInformation]);

  const projectInformationInput = sectionInput ? sectionInput.input : undefined;
  const projectInformationInherited = sectionInput ? sectionInput.inherited : undefined;
  const setSectionInputWithoutInherited = (newInput: ProjectInformationInput) =>
    setSectionInput({ input: newInput, pristine: false, inherited: projectInformationInherited });

  const {
    loading: editInformationLoading,
    error: editInformationError,
  } = useQuery<ProjectInformationEditInformationData>(GET_PROJECT_INFORMATION_EDIT_INFORMATION, {
    variables: { projectId: projectId },
    fetchPolicy: "network-only",
    skip: editInformation !== undefined,
    onCompleted: data => {
      if (data) setEditInfo(data.projectDetailsEditInformation.projectInformation);
    },
  });

  const { loading: countriesLoading, data: countriesData, error: countriesError } = useQuery<CountriesResult>(
    GET_COUNTRIES
  );

  const {
    loading: autCountriesLoading,
    data: autCountriesData,
    error: autCountriesError,
  } = useQuery<AutReportingCountriesResult>(GET_AUT_REPORTING_COUNTRIES, {});

  const {
    loading: financingMethodsLoading,
    data: financingMethodsData,
    error: financingMethodsError,
  } = useQuery<FinancingMethodsResult>(GET_FINANCING_METHODS, {});

  const {
    loading: performanceObligationsLoading,
    data: performanceObligationsData,
    error: performanceObligationsError,
  } = useQuery<PerformanceObligationsResult>(GET_PERFORMANCE_OBLIGATIONS, {});

  const {
    loading: organisationKeysLoading,
    data: organisationKeysData,
    error: organisationKeysError,
  } = useQuery<OrganisationKeysResult>(GET_ORGANISATION_KEYS, {});

  const {
    loading: projectTechnicalTypesLoading,
    data: projectTechnicalTypesData,
    error: projectTechnicalTypesError,
  } = useQuery<ProjectTechnicalTypesResult>(GET_PROJECT_TECHNICAL_TYPES, {});

  const {
    loading: projectTypesLoading,
    data: projectTypesData,
    error: projectTypesError,
  } = useQuery<ProjectTypesResult>(GET_PROJECT_TYPES, {});

  const {
    loading: projectPhasesLoading,
    data: projectPhasesData,
    error: projectPhasesError,
  } = useQuery<ProjectPhasesResult>(GET_PROJECT_PHASES, {});

  const {
    loading: projectStatusesLoading,
    data: projectStatusesData,
    error: projectStatusesError,
  } = useQuery<ProjectStatusesResult>(GET_PROJECT_STATUSES, {});

  const {
    loading: projectDeliveryLocationsLoading,
    data: projectDeliveryLocationsData,
    error: projectDeliveryLocationsError,
  } = useQuery<ProjectDeliveryLocationsResult>(GET_DELIVERY_LOCATIONS, {});

  useEffect(() => {
    if (projectInformationInput === undefined || editInfo === undefined || organisationKeysData === undefined) return;

    const autReportingCountryEditable = checkAutReportingCountryEditable(
      projectInformationInput,
      organisationKeysData.organisationKeys.businessGroups,
      information.projectVirtualType
    );

    const autSiteCodeEditable = checkAutSiteCodeEditable(
      projectInformationInput,
      organisationKeysData.organisationKeys.businessGroups,
      information.projectVirtualType
    );

    const autReportingCountryEditableChanged =
      autReportingCountryEditable !== editInfo.autReportingCountryProperties.editable;

    const autSiteCodeChanged = autSiteCodeEditable !== editInfo.organisation.autSiteCodeProperties.editable;

    if (autReportingCountryEditableChanged || autSiteCodeChanged) {
      setEditInfo({
        ...editInfo,
        autReportingCountryProperties: autReportingCountryEditable
          ? { editable: true, nullable: false }
          : { editable: false, nullable: null },
        organisation: {
          ...editInfo.organisation,
          autSiteCodeProperties: autSiteCodeEditable
            ? { editable: true, nullable: false }
            : { editable: false, nullable: null },
        },
      });

      const resetAutReportingCountry = (input: ProjectInformationInput) => {
        return !autReportingCountryEditable
          ? editClone(input, inp => (inp.autReportingCountryId = editInfo.autReportingCountryId))
          : projectInformationInput;
      };
      const resetAutSiteCode = (input: ProjectInformationInput) => {
        return !autSiteCodeEditable
          ? editClone(input, inp => (inp.organisation.autSiteCodeId = editInfo.organisation.autSiteCodeId))
          : projectInformationInput;
      };

      if (!autReportingCountryEditable || !autSiteCodeEditable) {
        const nextSectionInput = resetAutReportingCountry(resetAutSiteCode(projectInformationInput));
        const nextInherited = projectInformationInherited
          ? projectInformationInherited
          : projectCreationMode
          ? {
              externalOrganisation: {
                businessGroupId: false,
                businessTypeId: false,
                legalEntityId: false,
                externalReportingIndustryId: false,
              },
              contractOwner: {
                businessGroupId: false,
              },
            }
          : undefined;
        setSectionInput({ input: nextSectionInput, pristine: false, inherited: nextInherited });
      }
    }
  }, [
    projectInformationInput,
    setSectionInput,
    editInfo,
    setEditInfo,
    organisationKeysData,
    information.projectVirtualType,
  ]);

  const onEditInformationDataChange = useCallback(() => {
    function selectValue<T>(input: T | null, oldValue: T | null, fieldProps: FieldProperties): T | null {
      if (fieldProps.editable) {
        return input !== null ? input : oldValue;
      } else {
        return oldValue;
      }
    }

    function selectValueOrUndefined<T>(input: T | null, oldValue: T | null, fieldProps: FieldProperties) {
      return projectInformationInput && input !== null && fieldProps.editable
        ? input
        : oldValue !== null && fieldProps.editable
        ? oldValue
        : null;
    }

    const sectionInp = projectInformationInput ? projectInformationInput : null;

    if (editInfo && organisationKeysData) {
      const contractOwnerBusinessGroup = editInfo.contractOwner.businessGroupProperties.shouldInherit
        ? inheritOrgValue(
            (sectionInp && sectionInp.organisation.businessGroupId) || editInfo.organisation.businessGroupId.effective,
            organisationKeysData.organisationKeys.businessGroups,
            organisationKeysData.organisationKeys.contractOwnerBusinessGroups
          )
        : selectValue(
            sectionInp && sectionInp.contractOwner.businessGroupId,
            editInfo.contractOwner.businessGroupId.effective,
            editInfo.contractOwner.businessGroupProperties
          );

      setSectionInput({
        input: {
          organisation: {
            businessGroupId:
              (sectionInp && sectionInp.organisation.businessGroupId) ||
              editInfo.organisation.businessGroupId.effective,
            businessTypeId:
              (sectionInp && sectionInp.organisation.businessTypeId) || editInfo.organisation.businessTypeId.effective,
            legalEntityId:
              (sectionInp && sectionInp.organisation.legalEntityId) || editInfo.organisation.legalEntityId.effective,
            intExtId: selectValue(
              sectionInp && sectionInp.organisation.intExtId,
              editInfo.organisation.intExtId,
              editInfo.organisation.intExtProperties
            ),
            customerId: selectValue(
              sectionInp && sectionInp.organisation.customerId,
              editInfo.organisation.customerId,
              editInfo.organisation.customerProperties
            ),
            industryId: selectValue(
              sectionInp && sectionInp.organisation.industryId,
              editInfo.organisation.industryId,
              editInfo.organisation.industryProperties
            ),
            autSiteCodeId: selectValue(
              sectionInp && sectionInp.organisation.autSiteCodeId,
              editInfo.organisation.autSiteCodeId,
              editInfo.organisation.autSiteCodeProperties
            ),
            icpTradingPartnerId: selectValue(
              sectionInp && sectionInp.organisation.icpTradingPartnerId,
              editInfo.organisation.icpTradingPartnerId,
              editInfo.organisation.icpTradingPartnerProperties
            ),
          },
          contractOwner: {
            businessGroupId: contractOwnerBusinessGroup,
            businessTypeId: selectValue(
              sectionInp && sectionInp.contractOwner.businessTypeId,
              editInfo.contractOwner.businessTypeId,
              editInfo.contractOwner.businessTypeProperties
            ),
            legalEntityId: selectValue(
              sectionInp && sectionInp.contractOwner.legalEntityId,
              editInfo.contractOwner.legalEntityId,
              editInfo.contractOwner.legalEntityProperties
            ),
          },
          EDCId: selectValue(sectionInp && sectionInp.EDCId, editInfo.EDCId, editInfo.EDCProperties),
          autReportingCountryId: selectValue(
            sectionInp && sectionInp.autReportingCountryId,
            editInfo.autReportingCountryId,
            editInfo.autReportingCountryProperties
          ),
          deliveryOrgId: selectValue(
            sectionInp && sectionInp.deliveryOrgId,
            editInfo.deliveryOrgId,
            editInfo.deliveryOrgProperties
          ),
          deliveryLocationId: selectValue(
            sectionInp && sectionInp.deliveryLocationId,
            editInfo.deliveryLocationId,
            editInfo.deliveryLocationProperties
          ),
          financingMethod: selectValueOrUndefined(
            sectionInp && sectionInp.financingMethod,
            information.financingMethod,
            editInfo.financingMethodProperties
          ),
          performanceObligation: selectValueOrUndefined(
            sectionInp && sectionInp.performanceObligation,
            information.performanceObligation,
            editInfo.performanceObligationProperties
          ),
          projectKey: selectValueOrUndefined(
            sectionInp && sectionInp.projectKey,
            information.projectKey,
            editInfo.projectKeyProperties
          ),
          mainProjectKey: selectValueOrUndefined(
            sectionInp && sectionInp.mainProjectKey,
            information.mainProjectKey,
            editInfo.mainProjectKeyProperties
          ),
          projectName: selectValueOrUndefined(
            sectionInp && sectionInp.projectName,
            information.name,
            editInfo.projectNameProperties
          ),
          projectDescription: selectValueOrUndefined(
            sectionInp && sectionInp.projectDescription,
            editInfo.projectDescription === null
              ? null
              : editInfo.projectDescription.__typename == "FreetextProjectDescription"
              ? editInfo.projectDescription.desc
              : editInfo.projectDescription.postfix,
            editInfo.projectDescriptionProperties
          ),
          projectPhaseId: selectValue(
            sectionInp && sectionInp.projectPhaseId,
            editInfo.projectPhaseId,
            editInfo.projectPhaseProperties
          ),
          projectStatusId: selectValue(
            sectionInp && sectionInp.projectStatusId,
            editInfo.projectStatusId,
            editInfo.projectStatusProperties
          ),
          projectTechnicalTypeId: selectValue(
            sectionInp && sectionInp.projectTechnicalTypeId,
            editInfo.projectTechnicalTypeId,
            editInfo.projectTechnicalTypeProperties
          ),
          projectTypeId: selectValue(
            sectionInp && sectionInp.projectTypeId,
            editInfo.projectTypeId.effective,
            editInfo.projectTypeProperties
          ),
        },
        pristine: sectionInput ? sectionInput.pristine : true,
        inherited: {
          contractOwner: {
            businessGroupId:
              editInfo.contractOwner.businessGroupProperties.shouldInherit == true &&
              contractOwnerBusinessGroup != null,
          },
        },
      });
    }
  }, [editInfo, setSectionInput, projectInformationInput, information, organisationKeysData]);

  useEffect(onEditInformationDataChange, [editInfo, information, organisationKeysData]);

  const loading =
    editInformationLoading ||
    countriesLoading ||
    autCountriesLoading ||
    financingMethodsLoading ||
    performanceObligationsLoading ||
    organisationKeysLoading ||
    projectTechnicalTypesLoading ||
    projectPhasesLoading ||
    projectStatusesLoading ||
    projectTypesLoading ||
    projectDeliveryLocationsLoading;

  const errors = [
    editInformationError,
    countriesError,
    autCountriesError,
    financingMethodsError,
    performanceObligationsError,
    organisationKeysError,
    projectTechnicalTypesError,
    projectPhasesError,
    projectStatusesError,
    projectTypesError,
    projectDeliveryLocationsError,
  ].filter(apolloError => apolloError !== undefined);

  const inputErrors = useMemo(() => checkInputErrors(projectInformationInput, editInfo), [
    projectInformationInput,
    editInfo,
  ]);
  useEffect(() => {
    setProjectError(inputErrors.length > 0);
  }, [inputErrors]);

  return loading || !sectionInput ? (
    <LoadingView />
  ) : editInfo &&
    projectTechnicalTypesData &&
    projectPhasesData &&
    projectInformationInput &&
    countriesData &&
    autCountriesData &&
    organisationKeysData &&
    financingMethodsData &&
    performanceObligationsData &&
    projectTypesData &&
    projectStatusesData &&
    projectDeliveryLocationsData &&
    errors.length === 0 ? (
    <>
      <DataSection>
        <InformationItem>
          {informationVisibility.description && (
            <>
              <TitleItem>Project description:</TitleItem>
              {editInfo.projectDescriptionProperties.editable ? (
                editInfo.projectDescription.__typename === "FreetextProjectDescription" ? (
                  <InformationItemValue>
                    <EditableText
                      value={projectInformationInput.projectDescription || ""}
                      onValueChanged={value =>
                        setSectionInputWithoutInherited({ ...projectInformationInput, projectDescription: value })
                      }
                      inputSize="extra-large"
                      error={inputErrors.includes(DetailsField.projectDescription)}
                      id={"projectDescription"}
                      charLimit={PROJECT_EDIT_CHAR_LIMIT.projectDescription}
                    />
                  </InformationItemValue>
                ) : (
                  <ProjectDescContainer>
                    <DescriptionStemItem emphasize={true}>
                      {editInfo.projectDescription.stem ? editInfo.projectDescription.stem : "-"}
                    </DescriptionStemItem>
                    <DescriptionPostfixItem>
                      <DescriptionSeparator>/</DescriptionSeparator>
                      <EditableText
                        value={projectInformationInput.projectDescription || ""}
                        onValueChanged={value =>
                          setSectionInputWithoutInherited({
                            ...projectInformationInput,
                            projectDescription: value,
                          })
                        }
                        inputSize="full"
                        error={inputErrors.includes(DetailsField.projectDescription)}
                        id={"projectDescription"}
                        charLimit={PROJECT_EDIT_CHAR_LIMIT.projectDescription}
                      />
                    </DescriptionPostfixItem>
                  </ProjectDescContainer>
                )
              ) : (
                getDataItem(information.description, true)
              )}
            </>
          )}
        </InformationItem>
        <InformationItem>
          {informationVisibility.name && (
            <>
              <TitleItem>Project name:</TitleItem>
              {editInfo.projectNameProperties.editable ? (
                <EditableText
                  value={projectInformationInput.projectName ? projectInformationInput.projectName : ""}
                  onValueChanged={value =>
                    setSectionInputWithoutInherited({ ...projectInformationInput, projectName: value })
                  }
                  inputSize="full"
                  error={inputErrors.includes(DetailsField.projectName)}
                  id={"projectName"}
                  charLimit={PROJECT_EDIT_CHAR_LIMIT.projectName}
                />
              ) : (
                getDataItem(information.name, false)
              )}
            </>
          )}
        </InformationItem>
        <ProjectDataSectionTwo>
          <InformationItem>
            {informationVisibility.projectKey && (
              <>
                <TitleItem>Project key:</TitleItem>
                {editInfo.projectKeyProperties.editable ? (
                  <EditableText
                    value={projectInformationInput.projectKey ? projectInformationInput.projectKey : ""}
                    onValueChanged={value =>
                      setSectionInputWithoutInherited({ ...projectInformationInput, projectKey: value })
                    }
                    inputSize={"full"}
                    error={inputErrors.includes(DetailsField.projectKey)}
                    id={"projectKey"}
                    charLimit={PROJECT_EDIT_CHAR_LIMIT.projectKey}
                  />
                ) : (
                  getDataItem(information.projectKey)
                )}
              </>
            )}
          </InformationItem>
          <InformationItem>
            {informationVisibility.mainProjectKey && (
              <>
                <TitleItem>Main project key:</TitleItem>
                {editInfo.mainProjectKeyProperties.editable ? (
                  <EditableText
                    value={projectInformationInput.mainProjectKey ? projectInformationInput.mainProjectKey : ""}
                    onValueChanged={value =>
                      setSectionInputWithoutInherited({ ...projectInformationInput, mainProjectKey: value })
                    }
                    inputSize={"full"}
                    error={inputErrors.includes(DetailsField.mainProjectKey)}
                    id={"mainProjectKey"}
                    charLimit={PROJECT_EDIT_CHAR_LIMIT.mainProjectKey}
                  />
                ) : (
                  getDataItem(information.mainProjectKey)
                )}
              </>
            )}
          </InformationItem>
        </ProjectDataSectionTwo>
      </DataSection>

      <InformationDataSection
        editInfo={editInfo}
        organisationKeysData={organisationKeysData}
        projectInformation={information}
        inputErrors={inputErrors}
        projectId={projectId}
        projectTechnicalTypesData={projectTechnicalTypesData}
        projectTypesData={projectTypesData}
        projectPhasesData={projectPhasesData}
        projectStatusesData={projectStatusesData}
        countriesData={countriesData}
        autReportingCountriesData={autCountriesData}
        hierarchy={hierarchy}
        deliveryLocationsHierarchy={projectDeliveryLocationsData}
        projectInformationVisibility={informationVisibility}
      />

      <OrganisationDataSection
        editInfo={editInfo}
        organisationKeysData={organisationKeysData}
        projectInformation={information}
        inputErrors={inputErrors}
        projectOrganisationVisibility={informationVisibility.organisation}
      />

      {informationVisibility.contractOwner !== null ? (
        <DataSection>
          <InformationItem>
            {informationVisibility.contractOwner.businessGroup && (
              <>
                <TitleItem>
                  Contract owner business group:
                  {sectionInput.inherited &&
                    sectionInput.inherited.contractOwner.businessGroupId &&
                    CopiedIcon("Inherited value from own business group.")}
                  {projectInformationInput.contractOwner.businessGroupId &&
                    editInfo.contractOwner.businessGroupId.cached && (
                      <UnlockIcon
                        onClick={() =>
                          setSectionInputWithoutInherited(
                            editClone(projectInformationInput, inp => (inp.contractOwner.businessGroupId = null))
                          )
                        }
                      />
                    )}
                </TitleItem>
                {editInfo.contractOwner.businessGroupProperties.editable ? (
                  <EditableSearchableDropdown
                    value={
                      projectInformationInput.contractOwner.businessGroupId !== null
                        ? projectInformationInput.contractOwner.businessGroupId
                        : editInfo.contractOwner.businessGroupId.cached || ""
                    }
                    onValueChanged={value =>
                      setSectionInput({
                        input: editClone(
                          projectInformationInput,
                          inp => (inp.contractOwner.businessGroupId = parseNumberOrNull(value))
                        ),
                        pristine: false,
                        inherited:
                          sectionInput.inherited &&
                          editClone(sectionInput.inherited, inh => (inh.contractOwner.businessGroupId = false)),
                      })
                    }
                    options={organisationKeysData.organisationKeys.contractOwnerBusinessGroups.map(businessGroup => {
                      return { id: businessGroup.id, description: businessGroup.description };
                    })}
                    disabled={!editInfo.contractOwner.businessGroupProperties.editable}
                    error={inputErrors.includes(DetailsField.contractOwnerBusinessGroupId)}
                    searchable={true}
                    maxResults={20}
                    inputWidth="full"
                  />
                ) : (
                  getDataItem(information.contractOwner.businessGroup)
                )}
              </>
            )}
          </InformationItem>
        </DataSection>
      ) : null}
      <DataSection noBorder={true}>
        <CodeSection>
          <InformationItem>
            {informationVisibility.financingMethod && (
              <>
                <TitleItem>Revenue method:</TitleItem>
                {editInfo.financingMethodProperties.editable ? (
                  <EditableSearchableDropdown
                    value={
                      projectInformationInput.financingMethod !== null ? projectInformationInput.financingMethod : ""
                    }
                    onValueChanged={value =>
                      setSectionInputWithoutInherited({ ...projectInformationInput, financingMethod: value })
                    }
                    options={financingMethodsData.financingMethods.map(financingMethod => {
                      return { id: financingMethod, description: financingMethod };
                    })}
                    searchable={false}
                    disabled={!editInfo.financingMethodProperties.editable}
                    disabledValueIds={disabledFinancingMethods}
                    error={inputErrors.includes(DetailsField.financingMethod)}
                  />
                ) : (
                  getDataItem(information.financingMethod)
                )}
              </>
            )}
          </InformationItem>
          <InformationItem>
            {informationVisibility.performanceObligation && (
              <>
                <TitleItem>Performance obligation:</TitleItem>
                {editInfo.performanceObligationProperties.editable ? (
                  <EditableSearchableDropdown
                    value={
                      projectInformationInput.performanceObligation !== null
                        ? projectInformationInput.performanceObligation
                        : ""
                    }
                    onValueChanged={value =>
                      setSectionInputWithoutInherited({ ...projectInformationInput, performanceObligation: value })
                    }
                    options={performanceObligationsData.performanceObligations.map(performanceObligation => {
                      return { id: performanceObligation, description: performanceObligation };
                    })}
                    searchable={false}
                    disabled={!editInfo.performanceObligationProperties.editable}
                    error={inputErrors.includes(DetailsField.performanceObligation)}
                  />
                ) : (
                  getDataItem(information.performanceObligation)
                )}
              </>
            )}
          </InformationItem>
        </CodeSection>
      </DataSection>
    </>
  ) : (
    <LoadingContainer>Error loading project information data.</LoadingContainer>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(EditDetails);

const LoadingContainer = styled.div`
  padding: 20px;
  color: ${defaultGrey};
`;

export const ProjectDataSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;

export const ProjectDataSectionTwo = styled.div<{ fr1?: number; fr2?: number }>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-columns: ${({ fr1 = 1.0, fr2 = 1.0 }) => fr1 + "fr " + fr2 + "fr"};
`;

export const InformationItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding-right: 5px;
`;

const InformationItemValue = styled.div`
  display: flex;
  flex-direction: row;
`;

export const DataItem = styled.div<{ emphasize?: boolean }>`
  font-weight: bold;
  font-size: ${({ emphasize }) => (emphasize ? "22px" : "14px")};
  align-self: baseline;
  padding-right: 10px;
`;

const ProjectDescContainer = styled.div`
  /*display: grid;
  grid-template-columns: 1fr 1fr;*/
  display: flex;
  align-items: center;
`;

const DescriptionStemItem = styled.div<{ emphasize?: boolean }>`
  font-weight: bold;
  font-size: ${({ emphasize }) => (emphasize ? "20px" : "14px")};
  align-self: baseline;
  padding-right: 10px;
  min-width: 200px;
  width: 100%;
`;

const DescriptionPostfixItem = styled.div`
  display: flex;
  flex-direction: row;
  align-self: baseline;
  padding-right: 10px;
  min-width: 200px;
  width: 80%;
`;

const DescriptionSeparator = styled.span`
  font-weight: bold;
  font-size: 22px;
  align-self: flex-end;
  padding-right: 10px;
`;

export const TitleItem = styled.div`
  font-size: 14px;
  margin-bottom: 4px;
  display: flex;
  flex-direction: row;
`;

export const DataSection = styled.div<{ noBorder?: boolean }>`
  margin-bottom: ${({ noBorder }) => (noBorder ? "0px;" : `20px;`)};
  border-bottom: ${({ noBorder }) => (noBorder ? "0;" : `1px solid ${valmetGreyBorder}`)};
`;

export const CodeSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;
