import { FilterValues } from "./types";

export interface GeneratedColumn {
  id: GeneratedColumnId;
  name: string;
}

export type ColumnFieldTypes = {
  StringValue: ColumnFields;
  Recognition: ColumnFields;
  RecPercent: ColumnFields;
  EstRec: ColumnFields;
  EstRecPercent: ColumnFields;
  NetSalesCostsPM: ColumnFields;
  Estimate: ColumnFields;
  NetSalesCogsPM: ColumnFields;
  AsSoldBudgetEstimated: ColumnFields;
  Contingency: ColumnFields;
  WarrCostVar: ColumnFields;
  Invoicing: ColumnFields;
  InvoicingExt: ColumnFields;
  SalesPrice: ColumnFields;
  YTDRec: ColumnFields;
  PeriodRec: ColumnFields;
  Rates: ColumnFields;
  OrdersReceived: ColumnFields;
  RecWarranty: ColumnFields;
  ActualCostsCommitments: ColumnFields;
  CommCosts: ColumnFields;
  YTDRecPercentage: ColumnFields;
  PM: ColumnFields;
  PMCurrentYear: ColumnFields;
  EstimatedCommittedVariance: ColumnFields;
  VarToPrevEst: ColumnFields;
  ContingencyVar: ColumnFields;
  Backlog: ColumnFields;
  EstRecognitionPercentEdit: ColumnFields;
  RecognitionEdit: ColumnFields;
  EstRecognitionEdit: ColumnFields;
  SalesCostsEdit: ColumnFields;
  InvoicingEdit: ColumnFields;
  CommittedCostsEdit: ColumnFields;
  PTDVariancesEdit: ColumnFields;
  OrdersReceivedEdit: ColumnFields;
  RatesEdit: ColumnFields;
};

export enum ProjectColumn {
  p_id,
  type,
  currency,
  desc,
  org,
  rec,
  recognition,
  est_rec_percentage,
  est_rec,
  as_sold,
  budget,
  revised,
  prev_est,
  est,
  contingency,
  warranty,
  cost_var_del,
  cost_var_warr,
  cost_var,
  prev_rec,
  period_rec,
  backlog,
  backlog_y,
  backlog_y_1,
  backlog_y_n,
  sales_price,
  invoicing,
  invoicing_ext,
  periodInv,
  comm_costs_del,
  comm_period_costs_del,
  comm_costs_warr,
  pm_impact,
  pm_variance,
  pm_impact_curr_year,
  warr_cost_var,
  contingency_var,
  comm_costs,
  comm_period_costs_warr,
  rates,
  rec_warranty,
  orders_received,
  ytd_rec_percentage,
  var_to_prev_est,
  ytd_rec,
}

export type ColumnId = keyof typeof ProjectColumn;

export enum EditableProjectColumn {
  // generated column
  //rec_percent_edit,
  est_rec_percent_edit,

  as_sold_edit,
  budget_edit,
  revised_edit,
  est_edit,
  est_rec_edit,
  rec_edit,
  invoicing_edit,
  comm_costs_edit,
  comm_costs_warr_edit,
  ptd_variances_edit,
  orders_received_edit,
  rates_edit,
  related_est_rec_edit,
}

export type EditableColumnId = keyof typeof EditableProjectColumn;

export type EditColumnValueTypeMap = {
  est_rec_percent_edit: RecPercentEdit;
  as_sold_edit: SalesCostsEdit;
  budget_edit: SalesCostsEdit;
  revised_edit: SalesCostsEdit;
  est_edit: SalesCostsEdit;
  est_rec_edit: EstRecognitionEdit;
  rec_edit: RecognitionEdit;
  invoicing_edit: InvoicingEdit;
  comm_costs_edit: CommittedCostsEdit;
  comm_costs_warr_edit: CommittedCostsEdit;
  ptd_variances_edit: PTDVariancesEdit;
  orders_received_edit: OrdersReceivedEdit;
  rates_edit: RatesEdit;
  related_est_rec_edit: RelatedEstRecEdit;
};

export enum GeneratedListingColumn {
  hierarchy,
}

export type GeneratedColumnId = keyof typeof GeneratedListingColumn;

export interface RecPercent {
  nsPercent: number;
  costPercent: number;
  pmPercent: number;
}

export interface Recognition {
  ns: number;
  cogs: number;
  pm: number;
  warCost: number;
}

export interface EstRec {
  ns: number;
  cogs: number;
  pm: number;
  wipCosts: number;
}

export interface EstRecPercent {
  nsPercent: number;
  cogsPercent: number;
  pmPercent: number;
}

export interface Provisions {
  warranty: number;
  contingency: number;
  missingCost: number;
}

export interface NetSalesCostsPM {
  ns: number;
  costs: number;
  pm: number;
  pmPercent: number;
  warCost: number;
}

export interface Estimate {
  ns: number;
  costs: number;
  pm: number;
  pmPercent: number;
  warCost: number;
  nsOverrun: boolean;
  costOverrun: boolean;
  warCostOverrun: boolean;
}

export interface NetSalesCogsPM {
  ns: number;
  cogs: number;
  pm: number;
  pmPercent: number;
}

export interface AsSoldBudgetEstimated {
  asld: number;
  obud: number;
  rbud: number;
  est: number;
}

export interface EstimatedCommittedVariance {
  est: number;
  totalCommitted: number;
  etc: number;
}

export interface ActualCostsCommitments {
  act: number;
  hardCom: number;
  softCom: number;
}

export interface Invoicing {
  invoicing: number;
  paymRec: number;
}

export interface InvoicingExt {
  invoicing: number;
  receivable: number;
  paymRec: number;
  paymRecPercentage: number;
  ovdRec: number;
}

export interface CurrentPeriodYearTotal {
  current?: number;
  period: number;
  ytd: number;
  total: number;
}

export interface StringValue {
  value: string;
}

export interface Recognition {
  ns: number;
  cogs: number;
  pm: number;
  warCost: number;
}

export interface RecPercent {
  nsPercent: number;
  costPercent: number;
  pmPercent: number;
}

export interface Backlog {
  obl: number;
  cost: number;
  pm: number;
  pmPercent: number;
}

export interface CommCosts {
  commCosts: number;
  actualCosts: number;
  hardComm: number;
  softComm: number;
  wipCosts: number;
}

export interface Contingency {
  asld: number;
  obud: number;
  rbud: number;
  prevEst: number;
  est: number;
}

export interface ContingencyVar {
  period: number;
  toASLD: number;
  YTD: number;
}

export interface CostVar {
  costVar: number;
}

export interface PeriodRec {
  ns: number;
  cogs: number;
  pm: number;
  pmPercent: number;
}

export interface Rates {
  orRate?: number;
  hedgeRate?: number;
  avgRate?: number;
  endRate?: number;
  erRate?: number;
  irRate?: number;
}

export interface RecWarranty {
  periodCogs: number;
  ytdCogs: number;
  prevCogs: number;
  cogs: number;
}

export interface PM {
  period: number;
  asld: number;
  rbud: number;
  ptd: number;
  ytd: number;
}

export interface PMCurrentYear {
  period: number;
  ptd: number;
  ytd: number;
}

export interface WarrCostVar {
  period: number;
  toASLD: number;
  toRBUD: number;
  YTD: number;
}

export interface OrdersReceived {
  or: number;
  orPeriod: number;
  orPTD: number;
}

export interface YTDRecPercentage {
  actNSPercentage: number;
  actCogsPercentage: number;
  actPMPercentage: number;
}

export interface VarToPrevEst {
  nsVar: number;
  costVar: number;
}

export interface YTDRec {
  actNS: number;
  actCogs: number;
  actPM: number;
}

export interface SalesPrice {
  asld: number;
  obud: number;
  rbud: number;
  prevEst: number;
  est: number;
}

export interface RecPercentEdit extends Record<string, number> {
  nsPercent: number;
  cogsPercent: number;
}

export interface SalesCostsEdit extends Record<string, number> {
  salesPrice: number;
  ns: number;
  cost: number;
  cont: number;
  war: number;
}

export interface RecognitionEdit extends Record<string, number> {
  ns: number;
  cogs: number;
  war: number;
}

export interface EstRecognitionEdit extends Record<string, number> {
  ns: number;
  cogs: number;
  war: number;
  wipCosts: number;
}

export interface InvoicingEdit extends Record<string, number> {
  sent: number;
  paid: number;
  due: number;
}

export interface CommittedCostsEdit extends Record<string, number> {
  act: number;
  hardCom: number;
  softCom: number;
}

export interface PTDVariancesEdit extends Record<string, number> {
  netSalesVarianceTotal: number;
  costsVarianceTotal: number;
}

export interface OrdersReceivedEdit extends Record<string, number> {
  orPeriod: number;
  adjOnOR: number;
}

export interface RatesEdit extends Record<string, number> {
  erRate: number;
  irRate: number;
}

export interface RelatedEstRecEdit extends Record<string, number> {
  recNs: number;
  estNs: number;
  nsPercent: number;
}

export type ColumnSetValue =
  | StringValue
  | RecPercent
  | Recognition
  | EstRecPercent
  | EstRec
  | NetSalesCostsPM
  | NetSalesCogsPM
  | Backlog
  | Provisions
  | AsSoldBudgetEstimated
  | Invoicing
  | InvoicingExt
  | ActualCostsCommitments
  | EstimatedCommittedVariance
  | CurrentPeriodYearTotal
  | CommCosts
  | ContingencyVar
  | CostVar
  | Contingency
  | PeriodRec
  | Rates
  | RecWarranty
  | PM
  | PMCurrentYear
  | WarrCostVar
  | OrdersReceived
  | YTDRecPercentage
  | VarToPrevEst
  | YTDRec
  | SalesPrice
  | SalesCostsEdit
  | RecognitionEdit
  | EstRecognitionEdit
  | InvoicingEdit
  | CommittedCostsEdit
  | RatesEdit
  | RelatedEstRecEdit;

export type EditableColumnSetValue =
  | RecPercentEdit
  | SalesCostsEdit
  | RecognitionEdit
  | EstRecognitionEdit
  | InvoicingEdit
  | CommittedCostsEdit
  | PTDVariancesEdit
  | OrdersReceivedEdit
  | RatesEdit
  | RelatedEstRecEdit;

export interface ColumnSet {
  id: string;
  description: string;
  columns: (Column | GeneratedColumn)[];
  isGlobal?: boolean;
}

export interface Column {
  id: ColumnId;
  name: string;
}

export interface EditableColumn {
  id: EditableColumnId;
  name: string;
}

export enum ColumnFieldSpecType {
  PERCENTAGE = "percentage",
  CURRENCY = "currency",
  RATE = "rate",
}

export interface ColumnFieldSpec {
  title: string;
  decimals: number;
  type?: ColumnFieldSpecType;
  format?: (v: number) => string; // optional formatting method for numeric values
  hideForFuturePeriods?: boolean;
}

export interface ColumnFields {
  [key: string]: ColumnFieldSpec;
}

export interface ColumnSpec {
  column: Column;
  fields: ColumnFields;
  isStringValue?: boolean;
  isEstimate?: boolean;
}

export interface EditColumnSpec {
  column: EditableColumn;
  fields: ColumnFields;
}

export interface ColumnSetState {
  columnSet: ColumnSet;
  recognitionColumnSet: ColumnSet;
  customColumnSets: ColumnSet[];
  customRecognitionColumnSets: ColumnSet[];
}

export interface ColumnSetAction {
  type: string;
  columnSet?: ColumnSet;
  column?: Column | GeneratedColumn;
  filters?: FilterValues;
}

export type ColumnSets = {
  [key: string]: ColumnSpec;
};

export type EditColumnSets = {
  [key: string]: EditColumnSpec;
};
