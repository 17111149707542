import { cloneDeep, filter, find, findIndex, keys, remove, sortBy } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ContextMenu, ContextMenuTrigger, MenuItem } from "react-contextmenu";
import styled from "styled-components";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  commentBlue,
  commentBackgroundBlue,
  defaultGrey,
  filterGreen,
  selectedRowHighlight,
  settingGreen,
  valmetBlueTable,
  valmetGreyLight,
  valmetGreyREC,
  valmetGreyTable,
} from "../../../../common/colors";
import { PROJECT_COST_SUMMARY_HEADERS, PROJECT_COST_SUMMARY_HEADERS_SER_ORDER } from "./constants";
import {
  ColumnCopyData,
  CostHeaderMeasures,
  CurrencyScenario,
  DraftEstimate,
  EstimateCode,
  EstimateCodeSelectionState,
  EstimateCodeStatusId,
  EstimateStatuses,
  EstimateType,
  ProjectCostColumnId,
  ProjectCostEstimateType,
  ProjectCostEstimateValue,
  ProjectCostItem,
  ProjectCostsEditing,
  ProjectCostValues,
  ProjectVirtualType,
  StatusChangeData,
} from "../../../../common/types";
import ProjectHeader from "../ProjectHeader";
import ColumnCopyDialog from "./ColumnCopyDialog";
import ColumnSettingsView from "./ColumnSettingsView";
import CostItemsEditor from "./CostItemEditor/CostItemsEditor";
import StatusChangeDialog from "./StatusChangeDialog";
import { isValuesAvailable } from "./utils";
import LNActivityStatusTooltip from "./LNActivityStatusTooltip";
import { CollapseButton, IconButton, ToggleSwitch } from "../../../../common/components";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons/faInfoCircle";
import { useLocalStorage } from "../../../../hooks/useLocalStorage";
import { Format } from "../../../../common/utils";

export interface CostsDetailsProps {
  projectId: number;
  projectVirtualType: ProjectVirtualType;
  projectItems: ProjectCostItem[];
  onSelectedForDetails: (selectedItem: ProjectCostItem | undefined) => void;
  selectedDetailsItem?: ProjectCostItem;
  projectDescription: string | undefined;
  onSave: () => void;
  estimateCodesAndStatuses: ProjectCostEstimateType[];
  estimateCodesSelectionState: EstimateCodeSelectionState;
  setEstimateCodesSelectionState: (state: EstimateCodeSelectionState) => void;
  refetchData: () => void;
  openProjectItems: string[];
  setOpenProjectItems: React.Dispatch<React.SetStateAction<string[]>>;
  onCacheUpdated: (lastUpdated: number) => void;
  userCanEdit: boolean;
  editing: ProjectCostsEditing;
  allBlocked: boolean;
  currentEstBlockedAndChanged: ProjectCostItem[];
  selectedCurrencyScenario: CurrencyScenario;
  headerMeasures: CostHeaderMeasures | undefined;
  openDetailsPopup: () => void;
}

const getChildrenProjects = (
  project: ProjectCostItem,
  list: ProjectCostItem[],
  parent?: boolean,
  childLevel?: number,
  openProjectItems?: string[]
) => {
  if (!parent) {
    if (childLevel !== undefined) project.childLevel = childLevel;
    list.push(project);
  }

  if (
    project.childItems &&
    project.childItems.length > 0 &&
    (!openProjectItems || (openProjectItems && openProjectItems.includes(project.id)))
  ) {
    project.childItems.forEach(childProject =>
      getChildrenProjects(
        childProject,
        list,
        undefined,
        childLevel !== undefined ? childLevel + 1 : undefined,
        openProjectItems
      )
    );
  }
};

const closeProjectItemHierarchy = (
  projectItem: ProjectCostItem,
  setRenderedRows: React.Dispatch<React.SetStateAction<ProjectCostItem[]>>,
  renderedRows: ProjectCostItem[],
  openProjectItems: string[],
  setOpenProjectItems: React.Dispatch<React.SetStateAction<string[]>>
) => {
  const childItems: ProjectCostItem[] = [];
  getChildrenProjects(projectItem, childItems, true);

  const newProjects = cloneDeep(renderedRows);
  remove(newProjects, proj => childItems.map(item => item.id).includes(proj.id));
  setRenderedRows(newProjects);

  childItems.push(projectItem);
  const newOpenProjects = cloneDeep(openProjectItems);
  remove(newOpenProjects, proj => childItems.map(item => item.id).includes(proj));
  setOpenProjectItems(newOpenProjects);
};

const setInitialChildrenVisible = (
  items: ProjectCostItem[],
  setRenderedRows: React.Dispatch<React.SetStateAction<ProjectCostItem[]>>,
  setOpenProjectItems: React.Dispatch<React.SetStateAction<string[]>>,
  previousOpenProjectItems: string[],
  initalItems: string[]
) => {
  const renderedRows: ProjectCostItem[] = [];
  const openProjectItems: string[] = [];

  const recursivelyOpenItems = (items: ProjectCostItem[], level: number, untilLevel: number) => {
    if (level > untilLevel) return;

    items.forEach(item => {
      item.childLevel = level;
      renderedRows.push(item);
      if (level < untilLevel) {
        openProjectItems.push(item.id);
      }
      if (item.childItems) {
        recursivelyOpenItems(item.childItems, level + 1, untilLevel);
      }
    });
  };

  if (previousOpenProjectItems.length === 0 && items.length > 0) {
    initalItems.map(id => !openProjectItems.includes(id) && openProjectItems.push(id));
    recursivelyOpenItems(items, 0, 3);
    setOpenProjectItems(openProjectItems);
  } else {
    items.forEach(item => {
      renderedRows.push(item);
      if (previousOpenProjectItems.includes(item.id)) {
        const childItems: ProjectCostItem[] = [];
        getChildrenProjects(
          item,
          childItems,
          true,
          item.childLevel !== undefined ? item.childLevel : 0,
          previousOpenProjectItems
        );
        childItems.forEach(childItem => {
          renderedRows.push(childItem);
        });
      }
    });
  }
  setRenderedRows(renderedRows);
};

const openProjectItemHierarchy = (
  projectItem: ProjectCostItem,
  setRenderedRows: React.Dispatch<React.SetStateAction<ProjectCostItem[]>>,
  renderedRows: ProjectCostItem[],
  openProjectItems: string[],
  setOpenProjectItems: React.Dispatch<React.SetStateAction<string[]>>
) => {
  const newProjects = cloneDeep(renderedRows);
  const newOpenProjects = cloneDeep(openProjectItems);
  const index = findIndex(newProjects, row => row.id === projectItem.id, 0);
  if (projectItem.childItems) {
    projectItem.childItems.forEach((childProject, i) => {
      childProject.childLevel = projectItem.childLevel ? projectItem.childLevel + 1 : 1;
      newProjects.splice(index + 1 + i, 0, childProject);
    });
  }
  newOpenProjects.push(projectItem.id);

  setOpenProjectItems(newOpenProjects);
  setRenderedRows(newProjects);
};

const getNumber = (item: ProjectCostItem, valueId: ProjectCostColumnId): number => {
  const itemValue = item.values[valueId];
  if (itemValue === null) return 0;
  return itemValue;
};

const getNumericValue = (item: ProjectCostItem, valueId: ProjectCostColumnId): string => {
  const itemValue = item.values[valueId];
  if (itemValue !== null && itemValue !== undefined) return Format.withThousandSeparator(itemValue, 0);
  else return "0";
};

const getPercentageValue = (item: ProjectCostItem, valueId: ProjectCostColumnId) => {
  const itemValue = item.values[valueId];
  if (itemValue !== null && itemValue !== undefined) return (itemValue * 100).toFixed(1) + " %";
  else return "0 %";
};

const expandAll = (
  openProjectItems: string[],
  setOpenProjectItems: React.Dispatch<React.SetStateAction<string[]>>,
  item: ProjectCostItem,
  setRenderedRows: React.Dispatch<React.SetStateAction<ProjectCostItem[]>>,
  renderedRows: ProjectCostItem[]
) => {
  const newProjects = cloneDeep(renderedRows);
  const newOpenProjects = cloneDeep(openProjectItems);
  const index = findIndex(newProjects, row => row.id === item.id, 0);

  const childItems: ProjectCostItem[] = [];
  getChildrenProjects(item, childItems, true, item.childLevel !== undefined ? item.childLevel : 0);
  remove(newProjects, proj => childItems.map(item => item.id).includes(proj.id));

  childItems.push(item);
  remove(newOpenProjects, proj => childItems.map(item => item.id).includes(proj));
  childItems.pop();

  newOpenProjects.push(item.id);
  childItems.forEach(childItem => {
    if (childItem.childItems) {
      newOpenProjects.push(childItem.id);
    }
  });

  newProjects.splice(index + 1, 0, ...childItems);

  setOpenProjectItems(newOpenProjects);
  setRenderedRows(newProjects);
};

const getStatusValue = (estimateCodesAndStatuses?: ProjectCostEstimateType, selectedEstimateCode?: EstimateCode) => {
  return estimateCodesAndStatuses && selectedEstimateCode
    ? find(estimateCodesAndStatuses.estimateValues, item => item.estimateCode === selectedEstimateCode.id)
    : undefined;
};

function CostsDetails(props: CostsDetailsProps): React.ReactElement {
  const {
    projectId,
    projectVirtualType,
    projectDescription,
    projectItems,
    onSelectedForDetails,
    selectedDetailsItem,
    onSave,
    estimateCodesAndStatuses,
    estimateCodesSelectionState,
    setEstimateCodesSelectionState,
    refetchData,
    openProjectItems,
    setOpenProjectItems,
    onCacheUpdated,
    userCanEdit,
    editing,
    allBlocked,
    currentEstBlockedAndChanged,
    selectedCurrencyScenario,
    headerMeasures,
    openDetailsPopup,
  } = props;

  const [hideEmptyRows, setHideEmptyRows] = useLocalStorage("hideEmptyRows", false);
  const [hideHours, setHideHours] = useLocalStorage("hideHours", false);
  const hideHourColumnsIds = ["estimatedHours", "actualHours"];
  const [renderedRows, setRenderedRows] = useState(projectItems);
  const [selectedItem, setSelectedItem] = useState<ProjectCostItem | undefined>(undefined);
  const [ongoingStatusChange, setOngoingStatusChange] = useState<StatusChangeData | undefined>(undefined);
  const [ongoingColumnCopy, setOngoingColumnCopy] = useState<ColumnCopyData | undefined>(undefined);

  const costHeaderMeasuresId = "costs-header-measures";
  const onProjectItemsChange = useCallback(() => {
    setInitialChildrenVisible(projectItems, setRenderedRows, setOpenProjectItems, openProjectItems, [
      costHeaderMeasuresId,
    ]);
  }, [projectItems, openProjectItems, setOpenProjectItems]);

  useEffect(onProjectItemsChange, [projectItems]);

  const asSoldEstimateCodesAndStatuses = find(
    estimateCodesAndStatuses,
    item => item.estimateType === EstimateType.AsSold
  );

  const originalBudgetEstimateCodesAndStatuses = find(
    estimateCodesAndStatuses,
    item => item.estimateType === EstimateType.OriginalBudget
  );

  const proposedBudgetChangeEstimateCodesAndStatuses = find(
    estimateCodesAndStatuses,
    item => item.estimateType === EstimateType.ProposedBudgetChange
  );

  const revisedBudgetEstimateCodesAndStatuses = find(
    estimateCodesAndStatuses,
    item => item.estimateType === EstimateType.RevisedBudget
  );

  const estimateEstimateCodesAndStatuses = find(
    estimateCodesAndStatuses,
    item => item.estimateType === EstimateType.Estimate
  );

  const statuses: EstimateStatuses = useMemo(
    () => ({
      asSold: {
        estimateType: EstimateType.AsSold,
        estimateValue: getStatusValue(asSoldEstimateCodesAndStatuses, estimateCodesSelectionState.asSold),
      },
      originalBudget: {
        estimateType: EstimateType.OriginalBudget,
        estimateValue: getStatusValue(
          originalBudgetEstimateCodesAndStatuses,
          estimateCodesSelectionState.originalBudget
        ),
      },
      proposedBudgetChange: {
        estimateType: EstimateType.ProposedBudgetChange,
        estimateValue: getStatusValue(
          proposedBudgetChangeEstimateCodesAndStatuses,
          estimateCodesSelectionState.proposedBudgetChange
        ),
      },
      revisedBudget: {
        estimateType: EstimateType.RevisedBudget,
        estimateValue: getStatusValue(revisedBudgetEstimateCodesAndStatuses, estimateCodesSelectionState.revisedBudget),
      },
      currentEstimate: {
        estimateType: EstimateType.Estimate,
        estimateValue: getStatusValue(estimateEstimateCodesAndStatuses, estimateCodesSelectionState.currentEstimate),
      },
    }),
    [
      asSoldEstimateCodesAndStatuses,
      originalBudgetEstimateCodesAndStatuses,
      proposedBudgetChangeEstimateCodesAndStatuses,
      revisedBudgetEstimateCodesAndStatuses,
      estimateEstimateCodesAndStatuses,
      estimateCodesSelectionState,
    ]
  );

  const getDraftStatuses: () => DraftEstimate[] = useCallback(() => {
    return estimateCodesAndStatuses.flatMap(estimateType => {
      if (estimateType.estimateType === EstimateType.ProposedBudgetChange) {
        return [];
      }

      const draftEst: ProjectCostEstimateValue[] = estimateType.estimateValues.filter(
        est => est.estimateCodeStatus === EstimateCodeStatusId.Draft
      );
      const sorted: ProjectCostEstimateValue[] = sortBy(draftEst, est => est.estimateCode).reverse();
      // NOTE: sorted so that the newest estimate code is first
      if (sorted[0] !== undefined) {
        const draftEst: DraftEstimate = {
          estimateType: estimateType.estimateType,
          estimateCode: sorted[0].estimateCode,
        };
        return [draftEst];
      } else {
        return [];
      }
    });
  }, [estimateCodesAndStatuses]);

  const filterRenderedRows = useCallback(
    (rows: ProjectCostItem[]) => {
      if (hideEmptyRows) return filter(rows, row => isValuesAvailable(row.values));
      else return rows;
    },
    [hideEmptyRows]
  );

  const filteredRenderedRows = useMemo(() => filterRenderedRows(renderedRows), [filterRenderedRows, renderedRows]);

  // In backend, only actuals currency id is used to query costs details, so here we only compare actualsCurrencyId
  if (
    selectedDetailsItem &&
    (selectedDetailsItem.currencyCode !== selectedCurrencyScenario.currencyCode ||
      selectedDetailsItem.currencyId !== selectedCurrencyScenario.actualsCurrencyId)
  ) {
    onSelectedForDetails(
      filteredRenderedRows.filter(
        row => row.id === selectedDetailsItem.id && row.estimateCodeId === selectedDetailsItem.estimateCodeId
      )[0]
    );
  }

  const emptyProjectCostValues: ProjectCostValues = {
    asSold: null,
    originalBudget: null,
    proposedBudgetChange: null,
    revisedBudget: null,
    calculatedEstimate: null,
    lastEstimate: null,
    prevEstimate: null,
    estimateChange: null,
    currentEstimate: null,
    ETC: null,
    committed: null,
    actuals: null,
    hardCommitments: null,
    wipCosts: null,
    wipCostsCumulative: null,
    periodicWipCosts: null,
    softCommitments: null,
    estimatedHours: null,
    actualHours: null,
    defaultPrevEstimate: null,
  };

  const costHeaderMeasures = (): ProjectCostItem[] => {
    const headerNSValues: ProjectCostValues = headerMeasures
      ? {
          asSold: headerMeasures.asSoldNetSales,
          originalBudget: headerMeasures.budgetedNetSales,
          proposedBudgetChange: null,
          revisedBudget: headerMeasures.revisedNetSales,
          calculatedEstimate: null,
          prevEstimate: headerMeasures.previousEstimatedNetSales,
          estimateChange: headerMeasures.currentEstimatedNetSales - headerMeasures.previousEstimatedNetSales,
          currentEstimate: headerMeasures.currentEstimatedNetSales,
          lastEstimate: null,
          ETC: headerMeasures.backlog,
          committed: null,
          actuals: null,
          hardCommitments: null,
          wipCosts: null,
          wipCostsCumulative: null,
          periodicWipCosts: null,
          softCommitments: null,
          estimatedHours: null,
          actualHours: null,
          defaultPrevEstimate: null,
        }
      : cloneDeep(emptyProjectCostValues);
    const costsRow = filteredRenderedRows.length > 0 ? filteredRenderedRows[0].values : undefined;
    const headerCostsValues: ProjectCostValues = costsRow
      ? {
          asSold: costsRow.asSold || 0,
          originalBudget: costsRow.originalBudget || 0,
          proposedBudgetChange: costsRow.proposedBudgetChange || 0,
          revisedBudget: costsRow.revisedBudget || 0,
          calculatedEstimate: costsRow.calculatedEstimate || 0,
          prevEstimate: costsRow.prevEstimate || 0,
          estimateChange: (costsRow.currentEstimate || 0) - (costsRow.prevEstimate || 0),
          currentEstimate: costsRow.currentEstimate,
          lastEstimate: costsRow.lastEstimate || 0,
          ETC: costsRow.ETC,
          committed: costsRow.committed || 0,
          actuals: costsRow.actuals || 0,
          hardCommitments: costsRow.hardCommitments || 0,
          wipCosts: costsRow.wipCosts || 0,
          wipCostsCumulative: costsRow.wipCostsCumulative || 0,
          periodicWipCosts: costsRow.periodicWipCosts,
          softCommitments: costsRow.softCommitments || 0,
          estimatedHours: null,
          actualHours: null,
          defaultPrevEstimate: null,
        }
      : cloneDeep(emptyProjectCostValues);
    const headerProfitMarginValues: ProjectCostValues = cloneDeep(emptyProjectCostValues);
    const headerNSKeys = keys(headerNSValues).map(key => key as ProjectCostColumnId);
    headerNSKeys.forEach(
      key =>
        (headerProfitMarginValues[key] =
          headerNSValues[key] !== undefined && headerNSValues[key] !== null
            ? (headerNSValues[key] || 0) - (headerCostsValues[key] || 0)
            : null)
    );
    const headerPMValues: ProjectCostValues = cloneDeep(emptyProjectCostValues);
    headerNSKeys.forEach(key => {
      headerPMValues[key] = headerNSValues[key]
        ? (headerProfitMarginValues[key] || 0) / (headerNSValues[key] || 1)
        : null;
    });
    headerPMValues.estimateChange = (headerPMValues.currentEstimate || 0) - (headerPMValues.prevEstimate || 0);
    const costHeaderMeasuresCurrency = headerMeasures ? headerMeasures.currencyId : 1;
    const costHeaderMeasuresRate = headerMeasures && headerMeasures.currencyRate;
    return [
      {
        id: "net-sales",
        projectRelatingKey1: null,
        description: "Estimated Net Sales",
        currencyId: costHeaderMeasuresCurrency,
        estimateCodeId: "",
        values: headerNSValues,
        currencyRate: costHeaderMeasuresRate,
        currencyCode: selectedCurrencyScenario.currencyCode,
        hasWarrantyOrContingencyComponents: false,
        tooltip: null,
      },
      {
        id: "profit-margin",
        projectRelatingKey1: null,
        description: "Estimated PM",
        currencyId: costHeaderMeasuresCurrency,
        estimateCodeId: "",
        values: headerProfitMarginValues,
        currencyRate: costHeaderMeasuresRate,
        currencyCode: selectedCurrencyScenario.currencyCode,
        hasWarrantyOrContingencyComponents: false,
        tooltip: null,
      },
      {
        id: "PM",
        projectRelatingKey1: null,
        description: "Estimated PM %",
        currencyId: costHeaderMeasuresCurrency,
        estimateCodeId: "",
        values: headerPMValues,
        currencyRate: costHeaderMeasuresRate,
        currencyCode: selectedCurrencyScenario.currencyCode,
        hasWarrantyOrContingencyComponents: false,
        tooltip: null,
      },
    ];
  };

  const summaryHeaders = (projectVirtualType === ProjectVirtualType.LnServiceOrder
    ? PROJECT_COST_SUMMARY_HEADERS_SER_ORDER
    : PROJECT_COST_SUMMARY_HEADERS
  ).filter(header => (hideHours ? !hideHourColumnsIds.includes(header.id) : true));

  const calculatedEstVisible = projectVirtualType === ProjectVirtualType.LnServiceOrder;

  const headerHeaders = summaryHeaders.slice(0, calculatedEstVisible ? 8 : 7);

  const statusLocked = allBlocked
    ? { locked: true, reason: "This project is finished" }
    : !userCanEdit
    ? { locked: true, reason: "Costs can't be edited" }
    : { locked: false, reason: undefined };

  const proposedBudgetStatusLocked = !(
    (statuses.asSold.estimateValue &&
      statuses.asSold.estimateValue.estimateCodeStatus === EstimateCodeStatusId.Draft) ||
    (statuses.originalBudget.estimateValue &&
      statuses.originalBudget.estimateValue.estimateCodeStatus === EstimateCodeStatusId.Draft) ||
    (statuses.revisedBudget.estimateValue &&
      statuses.revisedBudget.estimateValue.estimateCodeStatus === EstimateCodeStatusId.Draft) ||
    (statuses.currentEstimate.estimateValue &&
      statuses.currentEstimate.estimateValue.estimateCodeStatus === EstimateCodeStatusId.Draft)
  )
    ? { locked: true, reason: "No draft in other columns" }
    : !statuses.proposedBudgetChange.estimateValue
    ? { locked: true, reason: "Proposed budget change has no estimation" }
    : statuses.proposedBudgetChange.estimateValue.estimateCodeStatus !== EstimateCodeStatusId.Draft
    ? { locked: true, reason: "Proposed budget change is not a draft" }
    : allBlocked
    ? { locked: true, reason: "This project is finished" }
    : !userCanEdit
    ? { locked: true, reason: "Costs can't be edited" }
    : { locked: false, reason: undefined };

  const approveBlocked =
    statuses.currentEstimate.estimateValue?.estimateCodeStatus === EstimateCodeStatusId.Draft &&
    currentEstBlockedAndChanged.length > 0;
  const approveBlockedStatus = (currentEstBlockedAndChanged: ProjectCostItem[]) =>
    currentEstBlockedAndChanged.length === 1
      ? {
          locked: true,
          reason: `Activity ${currentEstBlockedAndChanged[0].description} is blocked and its value has been changed. Approving is not allowed.`,
        }
      : {
          locked: true,
          reason: `Activities ${currentEstBlockedAndChanged
            .map(item => item.description)
            .join(", ")} are blocked and their values have been changed. Approving is not allowed.`,
        };

  console.log(approveBlocked, currentEstBlockedAndChanged);

  const currentEstStatusLocked = allBlocked
    ? { locked: true, reason: "This project is finished" }
    : approveBlocked
    ? approveBlockedStatus(currentEstBlockedAndChanged)
    : !userCanEdit
    ? { locked: true, reason: "Costs can't be edited" }
    : { locked: false, reason: undefined };

  return (
    <Container>
      <CostsTable>
        <TableBody>
          {headerMeasures && (
            <HeaderRow>
              <HeaderCell>
                <CollapseButton
                  onClick={e => {
                    e.stopPropagation();
                    if (openProjectItems.includes(costHeaderMeasuresId)) {
                      const newOpenProjects = cloneDeep(openProjectItems);
                      remove(newOpenProjects, proj => costHeaderMeasuresId === proj);
                      setOpenProjectItems(newOpenProjects);
                    } else {
                      const newOpenProjects = cloneDeep(openProjectItems);
                      newOpenProjects.push(costHeaderMeasuresId);
                      setOpenProjectItems(newOpenProjects);
                    }
                  }}
                >
                  <FontAwesomeIcon
                    icon={openProjectItems.includes(costHeaderMeasuresId) ? faChevronUp : faChevronDown}
                    size="1x"
                    color={valmetGreyLight}
                  />
                </CollapseButton>
              </HeaderCell>
              {openProjectItems.includes(costHeaderMeasuresId) &&
                headerHeaders.map((header, i) => (
                  <HeaderCell
                    key={i}
                    widthLimit={
                      header.id === "proposedBudgetChange" ||
                      header.id === "estimatedHours" ||
                      header.id === "actualHours"
                        ? 50
                        : undefined
                    }
                  >
                    {[
                      "proposedBudgetChange",
                      "calculatedEstimate",
                      "ETC",
                      "committed",
                      "wipCosts",
                      "estimatedHours",
                      "actualHours",
                    ].includes(header.id)
                      ? ""
                      : header.description}
                  </HeaderCell>
                ))}
            </HeaderRow>
          )}
          {headerMeasures &&
            openProjectItems.includes(costHeaderMeasuresId) &&
            costHeaderMeasures().map(measures => (
              <DataRow key={measures.id}>
                <DataCell>
                  <DataContainer total={false} leaf={true}>
                    {measures.description}
                  </DataContainer>
                </DataCell>
                {headerHeaders.map((column, i) => {
                  const strValue =
                    measures.id === "PM"
                      ? getPercentageValue(measures, column.id)
                      : getNumericValue(measures, column.id);
                  return (
                    <DataCell key={`${measures.id}_${i}`} extraPadding={measures.id === "total"}>
                      <ValueSpan>{measures.values[column.id] !== null ? strValue : null}</ValueSpan>
                    </DataCell>
                  );
                })}
              </DataRow>
            ))}
          <SeparateRow>
            <td />
            {summaryHeaders.map(value => (
              <td key={value.id} />
            ))}
          </SeparateRow>

          <ColumnSettingsRow>
            <ColumnSettingsCell>
              <ToggleSwitch
                checked={hideEmptyRows}
                onChange={value => setHideEmptyRows(value)}
                text={"Hide empty records"}
                color={settingGreen}
                fontSize={"12px"}
              />
              <ToggleSwitch
                checked={hideHours}
                onChange={value => setHideHours(value)}
                text={"Hide hours columns"}
                color={settingGreen}
                fontSize={"12px"}
              />
            </ColumnSettingsCell>
            <ColumnSettingsCell>
              <ColumnSettingsView
                projectId={projectId}
                projectVirtualType={projectVirtualType}
                estimateType={EstimateType.AsSold}
                onStatusChanged={statusCode => {
                  if (
                    estimateCodesSelectionState.asSold &&
                    statuses.asSold.estimateValue &&
                    statusCode !== statuses.asSold.estimateValue.estimateCodeStatus
                  )
                    setOngoingStatusChange({
                      projectId: projectId,
                      estimateCode: estimateCodesSelectionState.asSold.id,
                      estimateType: EstimateType.AsSold,
                      status: statusCode,
                    });
                }}
                estimateCodesAndStatuses={asSoldEstimateCodesAndStatuses}
                selectedEstimateCode={estimateCodesSelectionState.asSold}
                statusValue={statuses.asSold.estimateValue}
                statusLocked={statusLocked.locked}
                statusLockedReason={statusLocked.reason}
              />
            </ColumnSettingsCell>
            <ColumnSettingsCell>
              <ColumnSettingsView
                projectId={projectId}
                projectVirtualType={projectVirtualType}
                estimateType={EstimateType.OriginalBudget}
                onStatusChanged={statusCode => {
                  if (
                    estimateCodesSelectionState.originalBudget &&
                    statuses.originalBudget.estimateValue &&
                    statuses.originalBudget.estimateValue.estimateCodeStatus !== statusCode
                  )
                    setOngoingStatusChange({
                      projectId: projectId,
                      estimateCode: estimateCodesSelectionState.originalBudget.id,
                      estimateType: EstimateType.OriginalBudget,
                      status: statusCode,
                    });
                }}
                estimateCodesAndStatuses={originalBudgetEstimateCodesAndStatuses}
                selectedEstimateCode={estimateCodesSelectionState.originalBudget}
                statusValue={statuses.originalBudget.estimateValue}
                statusLocked={statusLocked.locked}
                statusLockedReason={statusLocked.reason}
              />
            </ColumnSettingsCell>
            <ColumnSettingsCell>
              <ColumnSettingsView
                projectId={projectId}
                projectVirtualType={projectVirtualType}
                estimateType={EstimateType.ProposedBudgetChange}
                onStatusChanged={statusCode => {
                  if (
                    estimateCodesSelectionState.proposedBudgetChange &&
                    statuses.proposedBudgetChange.estimateValue &&
                    statuses.proposedBudgetChange.estimateValue.estimateCodeStatus !== statusCode
                  )
                    setOngoingStatusChange({
                      projectId: projectId,
                      estimateCode: estimateCodesSelectionState.proposedBudgetChange.id,
                      estimateType: EstimateType.ProposedBudgetChange,
                      status: statusCode,
                      relatedStatuses: getDraftStatuses(),
                    });
                }}
                estimateCodesAndStatuses={proposedBudgetChangeEstimateCodesAndStatuses}
                selectedEstimateCode={estimateCodesSelectionState.proposedBudgetChange}
                statusValue={statuses.proposedBudgetChange.estimateValue}
                statusLocked={proposedBudgetStatusLocked.locked}
                statusLockedReason={proposedBudgetStatusLocked.reason}
              />
            </ColumnSettingsCell>
            <ColumnSettingsCell>
              <ColumnSettingsView
                projectId={projectId}
                projectVirtualType={projectVirtualType}
                estimateType={EstimateType.RevisedBudget}
                onStatusChanged={statusCode => {
                  if (
                    estimateCodesSelectionState.revisedBudget &&
                    statuses.revisedBudget.estimateValue &&
                    statuses.revisedBudget.estimateValue.estimateCodeStatus !== statusCode
                  )
                    setOngoingStatusChange({
                      projectId: projectId,
                      estimateCode: estimateCodesSelectionState.revisedBudget.id,
                      estimateType: EstimateType.RevisedBudget,
                      status: statusCode,
                    });
                }}
                onEstimateCodeSelected={estimateCode =>
                  setEstimateCodesSelectionState({ ...estimateCodesSelectionState, revisedBudget: estimateCode })
                }
                estimateCodesAndStatuses={revisedBudgetEstimateCodesAndStatuses}
                selectedEstimateCode={estimateCodesSelectionState.revisedBudget}
                statusValue={statuses.revisedBudget.estimateValue}
                onCopy={() => {
                  if (estimateCodesSelectionState.revisedBudget && revisedBudgetEstimateCodesAndStatuses)
                    setOngoingColumnCopy({
                      projectId: projectId,
                      estimateType: EstimateType.RevisedBudget,
                      targetEstimateCode: estimateCodesSelectionState.revisedBudget.id,
                      estimateCodesAndStatuses: revisedBudgetEstimateCodesAndStatuses,
                      column: "revisedBudget",
                      currencyScenario: selectedCurrencyScenario,
                    });
                }}
                statusLocked={statusLocked.locked}
                statusLockedReason={statusLocked.reason}
              />
            </ColumnSettingsCell>
            {calculatedEstVisible && <ColumnSettingsCell />}
            <ColumnSettingsCell>
              <ColumnSettingsView
                projectId={projectId}
                projectVirtualType={projectVirtualType}
                estimateType={EstimateType.Estimate}
                onEstimateCodeSelected={estimateCode =>
                  setEstimateCodesSelectionState({ ...estimateCodesSelectionState, prevEstimate: estimateCode })
                }
                estimateCodesAndStatuses={estimateEstimateCodesAndStatuses}
                selectedEstimateCode={estimateCodesSelectionState.prevEstimate}
              />
            </ColumnSettingsCell>
            <ColumnSettingsCell />
            <ColumnSettingsCell>
              <ColumnSettingsView
                projectId={projectId}
                projectVirtualType={projectVirtualType}
                estimateType={EstimateType.Estimate}
                onStatusChanged={statusCode => {
                  if (
                    estimateCodesSelectionState.currentEstimate &&
                    statuses.currentEstimate.estimateValue &&
                    statuses.currentEstimate.estimateValue.estimateCodeStatus !== statusCode
                  )
                    setOngoingStatusChange({
                      projectId: projectId,
                      estimateCode: estimateCodesSelectionState.currentEstimate.id,
                      estimateType: EstimateType.Estimate,
                      status: statusCode,
                    });
                }}
                onEstimateCodeSelected={estimateCode =>
                  setEstimateCodesSelectionState({ ...estimateCodesSelectionState, currentEstimate: estimateCode })
                }
                estimateCodesAndStatuses={estimateEstimateCodesAndStatuses}
                selectedEstimateCode={estimateCodesSelectionState.currentEstimate}
                statusValue={statuses.currentEstimate.estimateValue}
                onCopy={() => {
                  if (estimateCodesSelectionState.currentEstimate && estimateEstimateCodesAndStatuses)
                    setOngoingColumnCopy({
                      projectId: projectId,
                      estimateType: EstimateType.Estimate,
                      targetEstimateCode: estimateCodesSelectionState.currentEstimate.id,
                      estimateCodesAndStatuses: estimateEstimateCodesAndStatuses,
                      column: "currentEstimate",
                      currencyScenario: selectedCurrencyScenario,
                    });
                }}
                statusLocked={currentEstStatusLocked.locked}
                statusLockedReason={currentEstStatusLocked.reason}
              />
            </ColumnSettingsCell>
            <ColumnSettingsCell />
            <ColumnSettingsCell />
            <ColumnSettingsCell />
            <ColumnSettingsCell>
              <PeriodicWIPHeader>Prev. to current est.</PeriodicWIPHeader>
              <PeriodicWIPHeader>
                {estimateCodesSelectionState.prevEstimate?.description}—
                {estimateCodesSelectionState.currentEstimate?.description}
              </PeriodicWIPHeader>
            </ColumnSettingsCell>
          </ColumnSettingsRow>
          <HeaderRow>
            <HeaderCell />
            {summaryHeaders.map((header, i) => (
              <HeaderCell
                key={i}
                widthLimit={
                  header.id === "proposedBudgetChange" || header.id === "estimatedHours" || header.id === "actualHours"
                    ? 150
                    : undefined
                }
              >
                {header.description}
              </HeaderCell>
            ))}
          </HeaderRow>
          {filteredRenderedRows.map((item, index, array) => {
            const isLeafItem = !item.childItems || item.childItems.length === 0;
            const itemChildLevel = item.childLevel || 0;
            const nextItemChildLevel = array.length > index + 1 ? array[index + 1].childLevel || 0 : 0;
            const isQuestionLine =
              item.hasWarrantyOrContingencyComponents &&
              (isLeafItem || array.length === index + 1 || itemChildLevel >= nextItemChildLevel);
            const isSelectedForDetails = selectedDetailsItem?.id === item.id;
            return (
              <DataRow
                key={item.id}
                childLevel={item.childLevel}
                isLeaf={isLeafItem}
                onClick={() => {
                  if (isLeafItem && !isSelectedForDetails) onSelectedForDetails(item);
                  else if (isSelectedForDetails) onSelectedForDetails(undefined);
                }}
                onDoubleClick={() => {
                  item.childLevel && item.childLevel > 1 && setSelectedItem(item);
                  const selection = window.getSelection();
                  if (selection) selection.removeAllRanges();
                }}
                selected={isSelectedForDetails}
                questionLine={isQuestionLine}
              >
                <DataCell>
                  <DataContainer total={item.id === "total"} leaf={isLeafItem}>
                    {!isLeafItem ? (
                      <MenuTrigger id={`${item.id}-context-menu`}>
                        <div
                          onClick={e => {
                            e.stopPropagation();
                            if (openProjectItems.includes(item.id)) {
                              closeProjectItemHierarchy(
                                item,
                                setRenderedRows,
                                renderedRows,
                                openProjectItems,
                                setOpenProjectItems
                              );
                            } else {
                              openProjectItemHierarchy(
                                item,
                                setRenderedRows,
                                renderedRows,
                                openProjectItems,
                                setOpenProjectItems
                              );
                            }
                          }}
                        >
                          <CollapseButton>
                            <FontAwesomeIcon
                              icon={openProjectItems.includes(item.id) ? faChevronUp : faChevronDown}
                              size="1x"
                              color={valmetGreyLight}
                            />
                          </CollapseButton>
                          {item.tooltip && (
                            <LNActivityStatusTooltip
                              isBlocked={item.tooltip.isBlocked}
                              coldLinesExist={item.tooltip.coldLinesExist}
                            />
                          )}
                          {item.description}
                        </div>
                      </MenuTrigger>
                    ) : (
                      <ActivityRowHeader>
                        <ActivityRowTitle>
                          {item.tooltip && (
                            <LNActivityStatusTooltip
                              isBlocked={item.tooltip.isBlocked}
                              coldLinesExist={item.tooltip.coldLinesExist}
                            />
                          )}
                          {item.description}
                        </ActivityRowTitle>
                        <IconButton color={isSelectedForDetails ? commentBlue : commentBackgroundBlue}>
                          <FontAwesomeIcon
                            icon={faInfoCircle}
                            size="1x"
                            onClick={() => {
                              onSelectedForDetails(item);
                              openDetailsPopup();
                            }}
                          />
                        </IconButton>
                      </ActivityRowHeader>
                    )}
                    <ContextMenuContainer>
                      <ContextMenu id={`${item.id}-context-menu`}>
                        <MenuItem
                          onClick={() =>
                            expandAll(openProjectItems, setOpenProjectItems, item, setRenderedRows, renderedRows)
                          }
                        >
                          Expand all
                        </MenuItem>
                        <MenuItem
                          onClick={() =>
                            closeProjectItemHierarchy(
                              item,
                              setRenderedRows,
                              renderedRows,
                              openProjectItems,
                              setOpenProjectItems
                            )
                          }
                        >
                          Collapse all
                        </MenuItem>
                      </ContextMenu>
                    </ContextMenuContainer>
                  </DataContainer>
                </DataCell>
                {summaryHeaders.map((column, i) => {
                  const value = getNumber(item, column.id);
                  const strValue = getNumericValue(item, column.id);
                  return (
                    <DataCell key={`${item.id}_${i}`} extraPadding={item.id === "total"}>
                      {column.id === "ETC" ? (
                        <ValueSpan bad={value < -1000}>{strValue}</ValueSpan>
                      ) : column.id === "estimateChange" ? (
                        <ValueSpan good={value < 0.0} bad={value > 0.0}>
                          {strValue}
                        </ValueSpan>
                      ) : (
                        <ValueSpan>{strValue}</ValueSpan>
                      )}
                    </DataCell>
                  );
                })}
              </DataRow>
            );
          })}
        </TableBody>
      </CostsTable>
      {filteredRenderedRows.length === 0 && <InfoContainer>No data found for selected project.</InfoContainer>}
      {selectedItem && projectDescription && (
        <CostItemsEditor
          projectId={projectId}
          projectDescription={projectDescription}
          projectRelatingKey1={selectedItem?.projectRelatingKey1 || ""}
          topActivity={selectedItem}
          onCancel={() => setSelectedItem(undefined)}
          onSave={() => {
            setSelectedItem(undefined);
            onSave();
          }}
          estimateStatuses={statuses}
          estimateCodesSelectionState={estimateCodesSelectionState}
          userCanEdit={userCanEdit}
          currentEstEditBlocked={currentEstBlockedAndChanged.length > 0}
          selectedCurrencyScenario={selectedCurrencyScenario}
        />
      )}
      {ongoingStatusChange && (
        <StatusChangeDialog
          changeData={ongoingStatusChange}
          close={() => setOngoingStatusChange(undefined)}
          onSaved={() => {
            setOngoingStatusChange(undefined);
            refetchData();
          }}
        />
      )}
      {ongoingColumnCopy && (
        <ColumnCopyDialog
          data={ongoingColumnCopy}
          close={() => setOngoingColumnCopy(undefined)}
          onSaved={() => {
            onCacheUpdated(new Date().getTime());
            setOngoingColumnCopy(undefined);
          }}
        />
      )}
    </Container>
  );
}

export default CostsDetails;

const Container = styled.div`
  .react-contextmenu {
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    color: ${defaultGrey};
    font-size: 16px;
    margin: 2px 0 0;
    outline: none;
    opacity: 0;
    pointer-events: none;
    text-align: left;
    transition: opacity 250ms ease !important;
  }

  .react-contextmenu-item {
    background: 0 0;
    border: 0;
    color: #373a3c;
    cursor: pointer;
    line-height: 1.5;
    padding: 3px 20px;
    text-align: inherit;
    white-space: nowrap;
    font-size: 12px;
    font-weight: normal;
  }

  .react-contextmenu-item.react-contextmenu-item--selected {
    background-color: ${filterGreen};
  }
`;

const CostsTable = styled.table`
  width: 100%;
  border-spacing: 0;
  font-size: 11px;
  color: ${valmetGreyLight};

  tr:last-child {
    td {
      padding-bottom: 4px;
    }
  }
`;

const MenuTrigger = styled(ContextMenuTrigger)`
  display: inline-block;
`;

const ActivityRowHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  button {
    visibility: hidden;
  }

  &:hover button {
    visibility: visible;
  }
`;

const ActivityRowTitle = styled.div`
  display: flex;
`;

const TableBody = styled.tbody``;

const HeaderRow = styled.tr`
  th:first-child {
    border-left: 20px solid #ffffff;
  }

  th:last-child {
    border-right: 20px solid #ffffff;
  }

  th:not(:first-child) {
    text-align: right;
    padding-right: 10px;
    padding-left: 10px;
  }
`;

const HeaderCell = styled.th<{ widthLimit?: number }>`
  border-right: 1px solid ${valmetGreyREC};
  text-transform: uppercase;
  padding-top: 4px;
  padding-bottom: 4px;
  position: sticky;
  position: -webkit-sticky;
  top: ${ProjectHeader.WrappedComponent.minimizedHeight};
  background: white;
  vertical-align: bottom;
  ${({ widthLimit }) => widthLimit && `max-width:${widthLimit}px;`};
  white-space: nowrap;
`;

const DataRow = styled.tr<{ childLevel?: number; isLeaf?: boolean; selected?: boolean; questionLine?: boolean }>`
  background: ${({ isLeaf, selected, questionLine }) =>
    selected ? `${selectedRowHighlight}` : questionLine ? `${valmetBlueTable}` : !isLeaf ? `${valmetGreyTable}` : ""};
  font-size: ${({ isLeaf }) => (isLeaf ? "10px" : "11px")};

  td:first-child {
    font-weight: ${({ childLevel }) => !childLevel && "bold"};
    padding-right: 10px;
    padding-left: ${({ childLevel, isLeaf }) =>
      childLevel ? `${childLevel * 10 + 10 + (!isLeaf ? 0 : 5)}px` : "10px"};
    text-align: left;
    border-left: 20px solid #ffffff;
    min-width: 300px;
    box-sizing: border-box;
  }

  td:last-child {
    border-right: 20px solid #ffffff;
  }

  td:not(:first-child) {
    text-align: right;
    padding-right: 10px;
    padding-left: 10px;
    white-space: nowrap;
  }

  cursor: pointer;
`;

const DataCell = styled.td<{ extraPadding?: boolean }>`
  border-right: 1px solid ${valmetGreyREC};
  text-align: center;
  padding-top: 4px;
  ${({ extraPadding }) => extraPadding && "padding-right:19px"};
`;

const DataContainer = styled.div<{ total?: boolean; leaf: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ total }) => (total ? "right" : "left")};
  ${({ leaf }) => leaf && "padding-left: 8px"}
  ${({ total }) => total && "font-weight:600"};
`;

const ContextMenuContainer = styled.div`
  z-index: 10;
`;

const ColumnSettingsRow = styled.tr`
  td {
    border-top: 20px solid #ffffff;
  }

  td:first-child {
    border-left: 20px solid #ffffff;
  }
`;

const ColumnSettingsCell = styled.td`
  vertical-align: bottom;
`;

const PeriodicWIPHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  font-size: 12px;
  height: 26px;
`;

const ValueSpan = styled.span<{ bad?: boolean; good?: boolean }>`
  ${({ bad }) => bad && "color: red;"}
  ${({ good }) => good && "color: green;"}
`;

const SeparateRow = styled.tr`
  td {
    border-bottom: 1px solid ${settingGreen};
  }
`;

const InfoContainer = styled.div`
  text-align: center;
  margin-top: 20px;
  padding: 20px;
`;
