import React from "react";
import styled from "styled-components";
import StatusDropdown from "./StatusDropdown";
import { EstimateCodeStatusId, StatusLightValue } from "../../../../../../common/types";

export interface StatusSelectionProps {
  value?: EstimateCodeStatusId;
  onValueSelected: (value: EstimateCodeStatusId) => void;
  statusLights: StatusLightValue[];
  canEditLocked: boolean;
  onCopy?: () => void;
  locked?: boolean;
  lockedReason?: string;
}

function StatusSelection(props: StatusSelectionProps): React.ReactElement {
  const { value, onValueSelected, statusLights, canEditLocked, onCopy, locked, lockedReason } = props;
  return (
    <Container>
      <StatusDropdown
        value={value}
        onValueSelected={onValueSelected}
        statusLights={statusLights}
        canEditLocked={canEditLocked}
        onCopy={onCopy}
        locked={locked}
        lockedReason={lockedReason}
      />
    </Container>
  );
}

export default StatusSelection;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  font-size: 12px;
  height: 26px;
`;
