import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { HierarchyDropdownItem } from "../../Project/ProjectBasics/EditableComponents/EditableSearchableHierarchyDropdown/HierarchyDropdown";
import { FilterHierarchyOption, FilterSetting } from "../../../../common/types";
import EditableSearchableHierarchyDropdown from "../../Project/ProjectBasics/EditableComponents/EditableSearchableHierarchyDropdown/EditableSearchableHierarchyDropdown";
import { parseNumberOrNull } from "../../../../common/utils";

interface OrganisationKeyHierarchyDropdownProps {
  selectedValues: number[];
  onValueChanged: (value: number | undefined) => void;
  organisationKeyHierarchy: FilterHierarchyOption[];
  filterItem: FilterSetting;
  maxResults: number;
  inputWidth: number;
}
function toHierarchyDropdownItem(
  filterOption: FilterHierarchyOption,
  depth: number,
  parentPath: string | undefined
): HierarchyDropdownItem {
  const description = parentPath === undefined ? filterOption.description : parentPath + "/" + filterOption.description; // From root item to current item, join with `/`
  return {
    id: filterOption.id.toString(),
    desc: filterOption.description,
    description: description,
    depth: depth,
    childItems:
      filterOption.children &&
      filterOption.children.map(child => toHierarchyDropdownItem(child, depth + 1, description)),
  };
}
function OrganisationKeyHierarchyDropdown(props: OrganisationKeyHierarchyDropdownProps): React.ReactElement {
  const { selectedValues, onValueChanged, organisationKeyHierarchy, filterItem, maxResults, inputWidth } = props;
  const hierarchy: HierarchyDropdownItem[] = useMemo(
    () =>
      (organisationKeyHierarchy as FilterHierarchyOption[]).map(filterOption =>
        toHierarchyDropdownItem(filterOption, 0, undefined)
      ),
    [organisationKeyHierarchy]
  );

  const [selectedDesc, setSelectedDesc] = useState<string>("");
  const multiSelectionDesc = useMemo(() => {
    return selectedDesc.length > 0
      ? selectedValues.length > 1
        ? `${selectedDesc} (+${selectedValues.length - 1})`
        : selectedDesc
      : "";
  }, [selectedDesc, selectedValues]);

  return (
    <HierarchyDropdownContainer>
      Business group: {multiSelectionDesc}
      <EditableSearchableHierarchyDropdown
        key={filterItem.id}
        selected={{ isSingleSelection: false, values: selectedValues.map(v => v.toString()) }}
        onValueChanged={value => onValueChanged(parseNumberOrNull(value) || undefined)}
        onDescChanged={setSelectedDesc}
        hierarchy={hierarchy}
        searchable={true}
        maxResults={maxResults}
        inputWidth={inputWidth}
        maxHierarchyDepth={5}
        minSelectableDepth={2}
      />
    </HierarchyDropdownContainer>
  );
}

export default OrganisationKeyHierarchyDropdown;

const HierarchyDropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;
