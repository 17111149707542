import { FilterAction, FilterId, NumberRange, OrganisationKey } from "../common/types";
import { ProjectFiltersData } from "../components/sites/Projects/FiltersConfiguration/FiltersConfiguration";

export const SET_FILTER = "SET_FILTER";
export const RESET_FILTERS = "RESET_FILTERS";
export const RESET_FILTER = "RESET_FILTER";
export const SET_SELECTED_FILTERS = "SET_SELECTED_FILTERS";
export const RESET_SELECTED_FILTERS = "RESET_SELECTED_FILTERS";

export const setFilter = (id: FilterId, value: string | number | NumberRange | undefined): FilterAction => ({
  type: SET_FILTER,
  data: { id, value },
});

export const resetFilters = (): FilterAction => ({
  type: RESET_FILTERS,
});

export const resetFilter = (id: FilterId): FilterAction => ({
  type: RESET_FILTER,
  data: { id, value: undefined },
});

export const setSelectedFilters = (selected: ProjectFiltersData): FilterAction => ({
  type: SET_SELECTED_FILTERS,
  selectedFilters: selected,
});

export const resetSelectedFilters = (): FilterAction => ({
  type: RESET_SELECTED_FILTERS,
});
