import React from "react";
import { CostEstimationStatus } from "../../../../common/types";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCogs } from "@fortawesome/free-solid-svg-icons";
import { valmetGreyLight } from "../../../../common/colors";

type POPCostEstimationIndicatorProps = { status: CostEstimationStatus };

export const POPCostEstimationIndicator: React.FC<POPCostEstimationIndicatorProps> = (
  props: POPCostEstimationIndicatorProps
) => {
  let description;
  let color;
  switch (props.status) {
    case CostEstimationStatus.POPWithLN:
      description = "Process in POP and LN interface activated";
      color = "green";
      break;
    case CostEstimationStatus.POPWithoutLN:
      description = "Process in POP, but LN interface not activated";
      color = "orange";
      break;
    case CostEstimationStatus.NotInPOP:
      description = "Process not in POP";
      color = valmetGreyLight;
      break;
  }
  return (
    <Container>
      <FontAwesomeIcon icon={faCogs} size="1x" color={color} />
      <Description>{description}</Description>
    </Container>
  );
};

const Container = styled.div`
  margin: 0 10px;
  font-size: 12px;
`;

const Description = styled.span`
  margin-left: 5px;
`;
