import React, { PropsWithChildren, useEffect, useState } from "react";
import styled from "styled-components";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTasks } from "@fortawesome/free-solid-svg-icons";
import { faCalendarCheck } from "@fortawesome/free-regular-svg-icons";

import routes from "../../common/routes";
import { defaultGreyDark } from "../../common/colors";

type MenuLinkProps = {
  route: string;
};

function MenuLink(props: RouteComponentProps & PropsWithChildren<MenuLinkProps>) {
  const { route, history, location, children } = props;
  return (
    <MenuLinkItem
      emphasize={route === location.pathname}
      href={route}
      onClick={ev => {
        history.push(route);
        ev.preventDefault();
      }}
    >
      {children}
    </MenuLinkItem>
  );
}

function BurgerMenu(props: RouteComponentProps) {
  const { history, location, match } = props;
  const [open, setOpen] = useState(false);
  // Hide menu dropdown, when navigating to new route.
  useEffect(() => history.listen(() => setOpen(false)));
  return (
    <MenuContainer>
      <BurgerButton active={open} onClick={() => setOpen(!open)}>
        <FontAwesomeIcon icon={faBars} />
      </BurgerButton>
      {open && (
        <ItemsContainer onClick={() => setOpen(false)}>
          <MenuLink history={history} location={location} match={match} route={routes.ESTIMATE_SCHEDULE}>
            Estimate Approval Schedule
            <FontAwesomeIcon icon={faCalendarCheck} />
          </MenuLink>
          <MenuLink history={history} location={location} match={match} route={routes.LOADING_STATUS}>
            Loading Status
            <FontAwesomeIcon icon={faTasks} />
          </MenuLink>
        </ItemsContainer>
      )}
    </MenuContainer>
  );
}

export default withRouter(BurgerMenu);

const MenuContainer = styled.div`
  position: relative;
`;

const BurgerButton = styled.button<{ active: boolean }>`
  background: ${({ active }) => (active ? defaultGreyDark : "transparent")};
  outline: none;
  border: none;
  font-size: 18px;
  padding: 26px 20px;
  height: 72px;
  cursor: pointer;
  color: white;
`;

const ItemsContainer = styled.div`
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;

  min-width: 260px;
  padding: 10px;
  right: 0;

  background: ${defaultGreyDark};
`;

const MenuLinkItem = styled.a<{ emphasize?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  background-color: transparent;
  outline: none;
  border: none;
  font-size: 18px;
  margin-right: 10px;
  text-decoration: ${({ emphasize }) => (emphasize ? "underline" : "none")};
  cursor: pointer;
  color: white;
  flex-grow: 0;
  padding: 10px;
`;
