import React from "react";
import styled from "styled-components";
import {
  FilterHierarchyOption,
  FilterId,
  FilterSetting,
  FilterType,
  FilterValues,
  NumberRange,
} from "../../../../common/types";
import FilterDropdown from "./FilterDropdown";
import { find } from "lodash";
import PeriodFilter from "./PeriodFilter";
import SearchableFilterItem from "./SearchableFilterItem";
import TextFilter from "./TextFilter";
import ResetButton from "./ResetButton";
import OrganisationKeyHierarchyDropdown from "./OrganisationKeyHierarchyDropdown";
import FilterNumberRange from "./FilterNumberRange";

interface FilterSectionProps {
  filters: FilterSetting[];
  filterValues: FilterValues;
  extraLarge?: boolean;
  onFilterSelected: (filter: FilterId, value: string | number | NumberRange | undefined) => void;
  onResetFilter: (id: FilterId) => void;
}

const getFilterDescription = (filter: FilterSetting, filterValues: FilterValues) => {
  switch (filter.type) {
    case FilterType.NUMBER:
      if (filterValues[filter.id] !== undefined) {
        const selected = filterValues[filter.id] as number;
        const option = find(filter.values, value => value.id === selected);
        if (option) return option.description;
      }
      break;
    case FilterType.NUMBER_ARRAY:
      if (filterValues[filter.id] !== undefined) {
        const values = filterValues[filter.id] as number[];
        if (values.length > 1) {
          const option = find(filter.values, value => value.id === values[0]);
          return (option && option.description) + ` (+${values.length - 1})`;
        } else {
          const option = find(filter.values, value => value.id === values[0]);
          if (option) return option.description;
        }
      }
      break;
    case FilterType.STRING:
      if (filterValues[filter.id] !== undefined) {
        const selected = filterValues[filter.id] as string;
        const option = find(filter.values, value => value.id === selected);
        if (option) return option.description;
      }
      break;
  }
  return undefined;
};

function FilterSection(props: FilterSectionProps): React.ReactElement {
  const { extraLarge, filters, filterValues, onFilterSelected, onResetFilter } = props;
  return (
    <Container extraLarge={extraLarge}>
      <FilterContainer>
        {filters.map(filter => {
          const description = getFilterDescription(filter, filterValues);
          switch (filter.id) {
            case "projectPeriod":
            case "oblPeriod":
              return (
                <PeriodFilter
                  key={filter.id}
                  filterItem={filter}
                  filterValues={filterValues}
                  onFilterSelected={onFilterSelected}
                  onResetFilter={onResetFilter}
                />
              );
            case "project":
              return (
                <TextFilter
                  key={filter.id}
                  filterItem={filter}
                  filterValues={filterValues}
                  onFilterSelected={onFilterSelected}
                  onResetFilter={onResetFilter}
                />
              );
            case "businessGroup":
              return (
                <OrganisationKeyHierarchyDropdown
                  key={filter.id}
                  selectedValues={filterValues.businessGroup || []}
                  onValueChanged={value => onFilterSelected(filter.id, value || undefined)}
                  organisationKeyHierarchy={filter.values as FilterHierarchyOption[]}
                  filterItem={filter}
                  maxResults={20}
                  inputWidth={400}
                />
              );
            case "businessType":
            case "deliveryOrg":
            case "endDestCountry":
            case "legalEntity":
            case "extLegalEntity":
              return (
                <SearchableFilterItem
                  key={filter.id}
                  filterItem={filter}
                  filterValues={filterValues}
                  onFilterSelected={onFilterSelected}
                  singleSelection={false}
                  onResetFilter={onResetFilter}
                  dropdownMarginLeft={filter.id === "extLegalEntity" ? "-100px" : "0"}
                />
              );
            case "person":
            case "customer":
              return (
                <SearchableFilterItem
                  key={filter.id}
                  filterItem={filter}
                  filterValues={filterValues}
                  onFilterSelected={onFilterSelected}
                  singleSelection={true}
                  onResetFilter={onResetFilter}
                />
              );
            case "projectStatus":
              return (
                <FilterItem key={filter.id}>
                  <FilterSelection>
                    {filter.name}
                    <FilterDropdown
                      filter={filter.id}
                      values={filter.values}
                      singleSelection={true}
                      onFilterSelected={onFilterSelected}
                      selections={filterValues[filter.id]}
                    />
                  </FilterSelection>
                  <FilterValue>
                    <ResetButton
                      onButtonClick={() => onResetFilter(filter.id)}
                      show={filterValues[filter.id] !== undefined}
                    />
                    {description && <FilterValueText>{description}</FilterValueText>}
                  </FilterValue>
                </FilterItem>
              );
            case "estSalesPrice": {
              const toKilos = (num: number | undefined): number | undefined =>
                num !== undefined ? Math.round(num * 0.001) : undefined;
              const fromKilos = (num: number | undefined): number | undefined =>
                num !== undefined ? Math.round(num * 1000.0) : undefined;

              const filterRange: NumberRange | undefined = filterValues[filter.id];
              const filterRangeKilos = filterRange
                ? { min: toKilos(filterRange.min), max: toKilos(filterRange.max) }
                : { min: undefined, max: undefined };
              return (
                <FilterItem key={filter.id}>
                  <FilterSelection>
                    {filter.name}
                    <FilterNumberRange
                      rangeValue={filterRangeKilos}
                      setRangeValue={value =>
                        onFilterSelected(filter.id, { min: fromKilos(value.min), max: fromKilos(value.max) })
                      }
                      defaultMinOnSlider={50}
                      defaultMaxOnSlider={10000}
                      unitText="k"
                    />
                  </FilterSelection>
                </FilterItem>
              );
            }
            default:
              return (
                <FilterItem key={filter.id}>
                  <FilterSelection>
                    {filter.name}
                    <FilterDropdown
                      filter={filter.id}
                      values={filter.values}
                      onFilterSelected={onFilterSelected}
                      selections={filterValues[filter.id]}
                    />
                  </FilterSelection>
                  <FilterValue>
                    <ResetButton
                      onButtonClick={() => onResetFilter(filter.id)}
                      show={filterValues[filter.id] !== undefined}
                    />
                    {description && <FilterValueText>{description}</FilterValueText>}
                  </FilterValue>
                </FilterItem>
              );
          }
        })}
      </FilterContainer>
    </Container>
  );
}

export default FilterSection;

const Container = styled.div<{ extraLarge?: boolean }>`
  display: flex;
  flex-direction: row;
  flex: ${({ extraLarge }) => (extraLarge ? "2.5" : "1")};
`;

const FilterContainer = styled.div`
  display: flex;
  flex: 1;
  font-size: 12px;
  justify-content: space-around;
`;

const FilterItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 4px;
`;

const FilterSelection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 13px;
`;

const FilterValue = styled.div`
  display: flex;
  align-items: center;
  font-style: italic;
  font-size: 10px;
  height: 17px;
`;

const FilterValueText = styled.div``;
