import { useEffect } from "react";

export function useWindowActive(callback: (active: boolean) => void): boolean {
  useEffect(() => {
    function handler() {
      if (document.visibilityState === "hidden") {
        callback(false);
      } else {
        callback(true);
      }
    }
    document.addEventListener("visibilitychange", handler);
    return () => {
      document.removeEventListener("visibilitychange", handler);
    };
  });
  return document.visibilityState !== "hidden";
}
