import React, { useCallback, useEffect, useState } from "react";
import { Action } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { useMutation } from "@apollo/client/react/hooks";

import Section from "../Section";
import {
  AppState,
  ProjectDates,
  ProjectDatesInput,
  ProjectDatesSaveResult,
  ProjectDatesSectionInput,
  ProjectDatesVisibility,
  ProjectId,
} from "../../../../../common/types";
import { setProjectDates } from "../../../../../actions/projectActions";
import { BasicDataSectionType, getEmptyProjectDatesSectionInput } from "../../../../../common/constants";
import { SAVE_PROJECT_DATES } from "./queries";
import { pollForBasicDataSaveReady } from "../../queries";
import ViewDetails from "./ViewDetails";
import EditDetails from "./EditDetails";
import styled from "styled-components";
import { defaultGrey } from "../../../../../common/colors";

const mapStateToProps = (state: AppState) => {
  return {
    projectDatesInput: state.projectState.projectInput
      ? state.projectState.projectInput.projectDates
      : getEmptyProjectDatesSectionInput(),
    inputErrors: state.projectState.projectErrors.date,
  };
};
const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, void, Action>) => {
  return {
    setProjectDatesInput: (input: ProjectDatesSectionInput) => dispatch(setProjectDates(input)),
  };
};

interface DatesSectionProps {
  projectId: ProjectId;
  sectionDetails: ProjectDates | undefined;
  sectionVisibility: ProjectDatesVisibility;
  sectionEditable: boolean;
  editSectionType: BasicDataSectionType | undefined;
  setEditSectionType: (type: BasicDataSectionType | undefined) => void;
  refetchDetails: () => void;
}

const TYPE: BasicDataSectionType = BasicDataSectionType.Dates;

function DatesSection(
  props: DatesSectionProps & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
) {
  const {
    projectId,
    sectionDetails,
    sectionVisibility,
    sectionEditable,
    editSectionType,
    setEditSectionType,
    refetchDetails,
    projectDatesInput,
    inputErrors,
    setProjectDatesInput,
  } = props;
  const [editMode, setEditMode] = useState(false);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  // Once input is changed, clear error messages.
  useEffect(() => {
    if (errorMessages.length > 0) {
      setErrorMessages([]);
    }
  }, [projectDatesInput]);

  const [saveMutation, { loading: saving }] = useMutation<{
    saveProjectDates: ProjectDatesSaveResult;
  }>(SAVE_PROJECT_DATES);

  const { pollForReady, ready, loading: polling, error: pollingError } = pollForBasicDataSaveReady();

  useEffect(() => {
    if (ready && !polling && !pollingError) {
      setEditMode(false);
      setEditSectionType(undefined);
      setProjectDatesInput(getEmptyProjectDatesSectionInput());
      refetchDetails();
    } else if (!polling && pollingError) {
      setErrorMessages(errorMessages.concat([pollingError]));
    }
  }, [ready, polling, pollingError]);

  const save = useCallback(
    (input: ProjectDatesInput) => {
      saveMutation({
        variables: {
          projectId: projectId,
          dates: input,
        },
      })
        .then(({ data }) => {
          if (data && data.saveProjectDates && data.saveProjectDates.applicationModifiedDateTime) {
            pollForReady(projectId, data.saveProjectDates.applicationModifiedDateTime);
          } else if (data && data.saveProjectDates.errors) {
            setErrorMessages(errorMessages.concat(data.saveProjectDates.errors));
          }
        })
        .catch(apolloError => {
          setErrorMessages(errorMessages.concat([apolloError.message]));
        });
    },
    [saveMutation]
  );

  const disabled = editSectionType !== undefined && editSectionType !== TYPE;

  return (
    <Section
      header="Dates"
      disabled={disabled}
      editMode={editMode}
      editable={sectionEditable && sectionDetails !== undefined}
      saving={saving || polling}
      saveEnabled={!projectDatesInput.pristine && !inputErrors}
      errors={errorMessages.length > 0 ? errorMessages : undefined}
      onEditClicked={() => {
        setEditSectionType(TYPE);
        setEditMode(true);
      }}
      onCancelEdit={() => {
        setEditSectionType(undefined);
        setEditMode(false);
        setProjectDatesInput(getEmptyProjectDatesSectionInput());
      }}
      onSaveClicked={() => {
        save(projectDatesInput.input);
      }}
    >
      <Container>
        {!sectionDetails ? undefined : editMode ? (
          <EditDetails projectId={projectId} dates={sectionDetails} datesVisibility={sectionVisibility} />
        ) : (
          <ViewDetails dates={sectionDetails} datesVisibility={sectionVisibility} />
        )}
      </Container>
    </Section>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(DatesSection);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-bottom: 0px;
  color: ${defaultGrey};
`;
