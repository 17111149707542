import React from "react";
import styled from "styled-components";
import {
  defaultGrey,
  filterGreen,
  valmetGreyLight,
  valmetGreyREC,
  valmetGreyTable,
} from "../../../../../common/colors";
import { DetailsColumnType, DetailsTableColumn, justifyToRight } from "./column";
import { format } from "date-fns";
import { Format } from "../../../../../common/utils";
import ProjectHeader from "../../ProjectHeader";

type ExternalLinkProps = {
  href?: string;
  text?: string;
};

export const ExternalLink = (props: ExternalLinkProps) => {
  const { href, text } = props;
  if (href) {
    return (
      <a href={href} target="_blank" rel="noopener noreferrer">
        {text ? text : "Link"}
      </a>
    );
  } else {
    return <span>{text ? text : ""}</span>;
  }
};

type DataCellProps<T extends { id: string }> = {
  column: DetailsTableColumn<T>;
  item: T;
};

export const TypedDataCell = <T extends { id: string }>(props: DataCellProps<T>) => {
  const { column, item } = props;

  const value = item[column.id] as number | string | unknown;
  const toRight = justifyToRight(column);

  switch (column.type) {
    case DetailsColumnType.General:
    case DetailsColumnType.NumericString: {
      const stringValue =
        value && typeof value === "number" ? value.toString() : typeof value === "string" ? value : "";
      return (
        <DataCell>
          <DataContainer right={toRight}>{stringValue}</DataContainer>
        </DataCell>
      );
    }
    case DetailsColumnType.Number: {
      const decimals = column.decimals || 0;
      const stringValue = value !== undefined ? Format.withThousandSeparator(value as number, decimals) : "";
      const negative = stringValue.startsWith("-");
      return (
        <DataCell>
          <DataContainer right={toRight} negative={negative}>
            {stringValue}
          </DataContainer>
        </DataCell>
      );
    }
    case DetailsColumnType.Date: {
      if (typeof value !== "string" && value !== undefined) {
        throw new Error("Invalid value type for Date: " + typeof value);
      }
      return (
        <DataCell>
          <DataContainer right={toRight}>{value && format(new Date(value as string), "dd.MM.yyyy")}</DataContainer>
        </DataCell>
      );
    }
    case DetailsColumnType.Link: {
      const href = (item[column.linkId] as unknown) as string;
      const stringValue =
        value && typeof value === "number" ? value.toString() : typeof value === "string" ? value : "";
      const formatted =
        column.actualType === DetailsColumnType.Date
          ? stringValue && format(new Date(stringValue), "dd.MM.yyyy")
          : stringValue;
      return (
        <DataCell>
          <DataContainer right={toRight}>
            <ExternalLink href={href} text={formatted} />
          </DataContainer>
        </DataCell>
      );
    }
  }
  console.log("Typed cell - should not be reachable", props);
};

export const DetailsTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-size: 9px;
  color: ${valmetGreyLight};
  tr:last-child {
    td {
      padding-bottom: 10px;
    }
  }
`;

export const TableBody = styled.tbody`
  tr:nth-child(even) {
    background-color: ${valmetGreyTable};
  }
`;

export const HeaderRow = styled.tr`
  th:last-child {
    border-right: 0;
    padding-right: 30px;
    width: 60px;
  }
  font-size: 10px;
  height: 32px;
`;

export const HeaderCell = styled.th<{ right: boolean; popupWindow: boolean }>`
  border-right: 1px solid ${valmetGreyREC};
  text-transform: uppercase;
  padding-top: 4px;
  padding-bottom: 4px;
  white-space: nowrap;
  padding-right: 10px;
  padding-left: 10px;
  max-width: 70px;
  position: sticky;
  top: ${({ popupWindow }) => (popupWindow ? 0 : ProjectHeader.WrappedComponent.minimizedHeight)};
  background: white;
  text-align: ${props => (props.right ? "right" : "left")};
  box-shadow: 0 5px 18px -2px #bbbfbf;
`;

export const DataRow = styled.tr<{ childLevel: number; last?: boolean; extraPadding?: boolean }>`
  ${({ childLevel }) => childLevel === 0 && `background: ${valmetGreyTable}`};
  border-top: ${props => (props.childLevel < 2 ? "1px solid #ccc" : "0")};
  font-size: ${props => (props.childLevel < 2 ? "12px" : "10px")};
  font-weight: ${props => (props.childLevel < 3 ? "bold" : "normal")};
  td:first-child {
    padding-right: 10px;
    padding-left: ${({ childLevel, extraPadding }) =>
      childLevel ? `${childLevel * 10 + 10 + (extraPadding ? 10 : 0)}px` : `${10 + (extraPadding ? 10 : 0)}px`};
    text-align: left;
    ${({ childLevel }) => childLevel < 2 && "padding-top: 10px;"}
  }
  td:last-child {
    border-right: 0;
    padding-right: 16px;
  }
  & > td {
    ${({ childLevel, last }) => childLevel > 2 && last === true && "padding-bottom: 8px;"}
  }
`;

export const DataCell = styled.td<{ bold?: boolean }>`
  border-right: 1px solid ${valmetGreyREC};
  text-align: center;
  padding-top: 4px;
  padding-right: 10px;
  padding-left: 10px;
`;

export const DataContainer = styled.div<{ right?: boolean; negative?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: ${({ right }) => (right ? "flex-end" : "flex-start")};
  white-space: nowrap;
  color: ${props => (props.negative ? "red" : "inherit")};

  .react-contextmenu {
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    color: ${defaultGrey};
    font-size: 16px;
    margin: 2px 0 0;
    outline: none;
    opacity: 0;
    pointer-events: none;
    text-align: left;
    transition: opacity 250ms ease !important;
  }

  .react-contextmenu-item {
    background: 0 0;
    border: 0;
    color: #373a3c;
    cursor: pointer;
    line-height: 1.5;
    padding: 3px 20px;
    text-align: inherit;
    white-space: nowrap;
    font-size: 12px;
    font-weight: normal;
  }

  .react-contextmenu-item.react-contextmenu-item--selected {
    background-color: ${filterGreen};
  }
`;
