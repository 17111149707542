import * as React from "react";
import onClickOutside, { HandleClickOutside, InjectedOnClickOutProps } from "react-onclickoutside";
import styled from "styled-components";
import { valmetGreyBorder, filterGreen, defaultGrey, valmetGreyHeader } from "../../../../../common/colors";
import { ProjectId, SearchNode } from "../../../../../common/types";
import { ValueButton } from "../../../../../common/components";

type Props = {
  searchNodes: SearchNode[];
  resetSearch: () => void;
  onResultSelected: (searchProject: SearchNode) => void;
  moreAvailable: boolean;
};

const mainProjectText = (id: ProjectId | null, desc: string | null) => {
  if (id === null && desc === null) return <Subtitle notFound={true}>Main project not found.</Subtitle>;
  else
    return (
      <Subtitle>
        {id && id} {desc && desc}
      </Subtitle>
    );
};

class HistorySearchDropdown extends React.Component<
  Props & InjectedOnClickOutProps & HandleClickOutside<React.MouseEventHandler>
> {
  handleClickOutside = (): void => {
    this.props.disableOnClickOutside();
    this.props.resetSearch();
  };

  render(): React.ReactElement {
    const { searchNodes, onResultSelected, moreAvailable } = this.props;
    return (
      <DropdownContent onClick={this.handleClickOutside} hasContent={true}>
        {searchNodes.map((node, i) => (
          <RowContainer key={`${node.id}_${i}`}>
            <ValueButton onClick={() => onResultSelected(node)}>
              <Title>{`${node.description}`}</Title>
              {mainProjectText(node.mainProjectId, node.mainProjectDescription)}
            </ValueButton>
          </RowContainer>
        ))}
        {moreAvailable && (
          <IndicatorContainer>{`More than ${searchNodes.length} results found, please refine search...`}</IndicatorContainer>
        )}
      </DropdownContent>
    );
  }
}

const DropdownContent = styled.div<{ hasContent: boolean }>`
  background: white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  position: absolute;
  display: flex;
  flex-direction: column;
  min-width: 200px;
  ${({ hasContent }) => {
    if (hasContent) {
      return `border: 1px solid ${valmetGreyBorder}`;
    }
  }};
  z-index: 901;
  button:hover {
    background: ${filterGreen};
  }
  color: ${defaultGrey};
`;

const RowContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  :hover {
    background: ${filterGreen};
  }
`;

const IndicatorContainer = styled.div`
  font-size: 10px;
  margin: 6px;
`;

const Title = styled.div`
  font-size: 16px;
`;

const Subtitle = styled.div<{ notFound?: boolean }>`
  font-size: 12px;
  ${({ notFound }) => notFound && `color: ${valmetGreyHeader}`};
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default onClickOutside<any, Props>(HistorySearchDropdown);
