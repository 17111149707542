import * as React from "react";
import onClickOutside, { HandleClickOutside, InjectedOnClickOutProps } from "react-onclickoutside";
import styled from "styled-components";
import { faChevronCircleLeft, faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { valmetGreyBorder, filterGreen, defaultGrey, settingGreen } from "../../common/colors";
import { Period } from "../../common/period";
import { IconButton } from "../../common/components";

type Props = {
  close: () => void;
  year?: number;
  month?: number;
  onValueChanged: (value: string) => void;
  minPeriod?: Period;
  maxPeriod?: Period;
};

const MONTHS = [
  { id: 1, desc: "Jan" },
  { id: 2, desc: "Feb" },
  { id: 3, desc: "Mar" },
  { id: 4, desc: "Apr" },
  { id: 5, desc: "May" },
  { id: 6, desc: "Jun" },
  { id: 7, desc: "Jul" },
  { id: 8, desc: "Aug" },
  { id: 9, desc: "Sep" },
  { id: 10, desc: "Oct" },
  { id: 11, desc: "Nov" },
  { id: 12, desc: "Dec" },
];

const onMonthClick = (month: number, year: number, onValueChanged: (value: string) => void, close: () => void) => {
  onValueChanged(`${year}-${month}`);
  close();
};

const onYearClick = (month: number, year: number, onValueChanged: (value: string) => void, forward: boolean) => {
  onValueChanged(`${year + (forward ? 1 : -1)}-${month}`);
};

class MonthDropdown extends React.Component<
  Props & InjectedOnClickOutProps & HandleClickOutside<React.MouseEventHandler>
> {
  handleClickOutside = (): void => {
    this.props.disableOnClickOutside();
    this.props.close();
  };

  render(): React.ReactElement {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;
    const { year = currentYear, month = currentMonth, minPeriod, maxPeriod } = this.props;

    const monthDisabled = (m: number) =>
      (maxPeriod !== undefined && year === maxPeriod.year && m > maxPeriod.month) ||
      (minPeriod !== undefined && year === minPeriod.year && m < minPeriod.month);

    const prevYearEnabled = !minPeriod || year !== minPeriod.year;
    const nextYearEnabled = !maxPeriod || year !== maxPeriod.year;

    return (
      <DropdownContent>
        <YearContainer>
          <IconButton
            disabled={!prevYearEnabled}
            color={"#000"}
            padding={"5px"}
            onClick={() => onYearClick(month, year, this.props.onValueChanged, false)}
          >
            <FontAwesomeIcon icon={faChevronCircleLeft} size="lg" color={settingGreen} />
          </IconButton>
          <Year>{year}</Year>
          <IconButton
            disabled={!nextYearEnabled}
            color={"#000"}
            padding={"5px"}
            onClick={() => onYearClick(month, year, this.props.onValueChanged, true)}
          >
            <FontAwesomeIcon icon={faChevronCircleRight} size="lg" color={settingGreen} />
          </IconButton>
        </YearContainer>
        <MonthContainer>
          {MONTHS.map(m => (
            <IconButton
              key={m.id}
              onClick={() => onMonthClick(m.id, year, this.props.onValueChanged, this.props.close)}
              selected={m.id === month}
              disabled={monthDisabled(m.id)}
              color={"#000"}
              padding={"5px"}
            >
              {m.desc}
            </IconButton>
          ))}
        </MonthContainer>
      </DropdownContent>
    );
  }
}

const DropdownContent = styled.div`
  background: white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 1.2em;
  display: flex;
  flex-direction: column;
  min-width: 200px;
  border: 1px solid ${valmetGreyBorder};
  z-index: 901;
  color: ${defaultGrey};
  padding: 10px;
  margin-top: 4px;
`;

const YearContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
`;
const MonthContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  button:hover {
    background: ${filterGreen};
  }
`;

const Year = styled.div`
  margin-left: 20px;
  margin-right: 20px;
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default onClickOutside<any, Props>(MonthDropdown);
