import React from "react";
import { ButtonContainer, IconButton } from "../../../../../common/components";
import styled from "styled-components";
import { defaultGrey, disabledGrey, valmetGreyLight } from "../../../../../common/colors";
import { ProjectCostItemDetailsResult } from "../types";
import { ProjectVirtualType } from "../../../../../common/types";
import { ApolloError } from "@apollo/client";
import CostItemDetailsTable from "./CostItemDetailsTable";
import { LoadingContainer } from "./CostItemDetails";

export interface CostItemDetailsPopupProps {
  title: string;
  data: ProjectCostItemDetailsResult | undefined;
  loading: boolean;
  error: ApolloError | undefined;
  projectVirtualType: ProjectVirtualType;
  closePopup: () => void;
}

function CostItemDetailsPopup(props: CostItemDetailsPopupProps): React.ReactElement {
  const { title, data, loading, error, projectVirtualType, closePopup } = props;
  return (
    <Wrapper>
      <ContentContainer>
        <Header>
          <Title>{title}</Title>
          <ButtonContainer>
            <IconButton onClick={() => closePopup()} fontSize={"14px"} hasText={true}>
              Close
            </IconButton>
          </ButtonContainer>
        </Header>
        {data && !loading && !error && (
          <CostItemDetailsTable data={data} projectVirtualType={projectVirtualType} popupWindow={true} />
        )}
        {(loading || error) && (
          <LoadingContainer>{!error ? "Loading..." : "Error fetching cost item data."}</LoadingContainer>
        )}
      </ContentContainer>
    </Wrapper>
  );
}

export default CostItemDetailsPopup;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  width: 100%;
  background-color: ${disabledGrey};
  z-index: 1200;
`;

const ContentContainer = styled.div`
  max-height: 80%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  background: white;
  padding: 0 24px 24px;
  color: ${valmetGreyLight};
  width: 85%;
  align-items: center;
  overflow: hidden;

  .recharts-legend-item {
    cursor: pointer;
  }
`;

const Header = styled.div`
  background: #ffffff;
  padding: 24px;
  margin: 0 0 20px;
  position: fixed;
  width: 85%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 1000;
`;

const Title = styled.div`
  font-size: 24px;
  color: ${defaultGrey};
  align-self: flex-start;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
