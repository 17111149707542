import React from "react";
import styled from "styled-components";
import { valmetGreyBorder } from "../../../../../common/colors";
import { ProjectInformation, ProjectInformationVisibility } from "../../../../../common/types";
import { getDataItem } from "../utils";

interface ViewDetailsProps {
  projectId: number;
  information: ProjectInformation;
  informationVisibility: ProjectInformationVisibility;
}

function ViewDetails(props: ViewDetailsProps): React.ReactElement {
  const { projectId, information, informationVisibility } = props;

  const combineToString = (xs: (string | number | null | undefined)[]) => {
    const filtered = xs.filter(v => v !== undefined && v !== null && v !== "");
    if (filtered.length === 0) return undefined;
    return filtered.join(" ");
  };

  return (
    <>
      <DataSection>
        <InformationItem>
          {informationVisibility.description && (
            <>
              <TitleItem>Project description:</TitleItem>
              {getDataItem(information.description, true)}
            </>
          )}
        </InformationItem>
        <InformationItem>
          {informationVisibility.name && (
            <>
              <TitleItem>Project name:</TitleItem>
              {getDataItem(information.name, false)}
            </>
          )}
        </InformationItem>
        <ProjectDataSectionTwo>
          <InformationItem>
            {informationVisibility.projectKey && (
              <>
                <TitleItem>Project key:</TitleItem>
                {getDataItem(information.projectKey)}
              </>
            )}
          </InformationItem>
          <InformationItem>
            {informationVisibility.mainProjectKey && (
              <>
                <TitleItem>Main project key:</TitleItem>
                {getDataItem(information.mainProjectKey)}
              </>
            )}
          </InformationItem>
        </ProjectDataSectionTwo>
      </DataSection>
      <DataSection>
        <ProjectDataSection>
          <InformationItem>
            <TitleItem>Project ID:</TitleItem>
            {getDataItem(projectId)}
          </InformationItem>
          <InformationItem>
            {informationVisibility.projectTechnicalType && (
              <>
                <TitleItem>Project technical type:</TitleItem>
                {getDataItem(information.projectTechnicalType)}
              </>
            )}
          </InformationItem>
          <InformationItem>
            {informationVisibility.projectType && (
              <>
                <TitleItem>Project type:</TitleItem>
                {getDataItem(information.projectType)}
              </>
            )}
          </InformationItem>
          <InformationItem>
            {informationVisibility.projectPhase && (
              <>
                <TitleItem>Project phase:</TitleItem>
                {getDataItem(information.projectPhase)}
              </>
            )}
          </InformationItem>
          <InformationItem>
            {informationVisibility.projectStatus && (
              <>
                <TitleItem>Project status:</TitleItem>
                {getDataItem(information.projectStatus)}
              </>
            )}
          </InformationItem>
          <InformationItem title="The lowest status of the ERP links">
            {informationVisibility.projectCardStatus && (
              <>
                <TitleItem>Project card status:</TitleItem>
                {getDataItem(information.projectCardStatus)}
              </>
            )}
          </InformationItem>
          <InformationItem>
            {informationVisibility.projectOffice && (
              <>
                <TitleItem>Project office:</TitleItem>
                {getDataItem(information.projectOffice)}
              </>
            )}
          </InformationItem>
          <InformationItem title="End destination country">
            {informationVisibility.EDC && (
              <>
                <TitleItem>EDC:</TitleItem>
                {getDataItem(information.EDC)}
              </>
            )}
          </InformationItem>
          <InformationItem>
            {informationVisibility.autReportingCountry && (
              <>
                <TitleItem>AUT reporting country:</TitleItem>
                {getDataItem(information.autReportingCountry)}
              </>
            )}
          </InformationItem>
        </ProjectDataSection>
        <ProjectDataSection>
          <InformationItem>
            {informationVisibility.deliveryOrg && (
              <>
                <TitleItem>Delivery organisation:</TitleItem>
                {getDataItem(information.deliveryOrg)}
              </>
            )}
          </InformationItem>
          <InformationItem>
            {informationVisibility.deliveryLocation && (
              <>
                <TitleItem>Delivery location:</TitleItem>
                {getDataItem(information.deliveryLocation)}
              </>
            )}
          </InformationItem>
        </ProjectDataSection>
      </DataSection>

      {informationVisibility.organisation !== null && (
        <DataSection
          noBorder={
            informationVisibility.contractOwner === null &&
            !(informationVisibility.financingMethod || informationVisibility.performanceObligation)
          }
        >
          <CodeSection>
            <InformationItem>
              {informationVisibility.organisation.businessType && (
                <>
                  <TitleItem>Business type:</TitleItem>
                  {getDataItem(information.organisation.businessType)}
                </>
              )}
            </InformationItem>
            <InformationItem>
              {informationVisibility.organisation.legalEntity && (
                <>
                  <TitleItem>Legal company:</TitleItem>
                  {getDataItem(information.organisation.legalEntity)}
                </>
              )}
            </InformationItem>
            <InformationItem>
              {informationVisibility.organisation.intExt && (
                <>
                  <TitleItem>Int/Ext:</TitleItem>
                  {getDataItem(information.organisation.intExt)}
                </>
              )}
            </InformationItem>
          </CodeSection>
          <ExtendedCodeSection>
            <InformationItem>
              {informationVisibility.organisation.businessGroup && (
                <>
                  <TitleItem>Business group:</TitleItem>
                  {getDataItem(information.organisation.businessGroup)}
                </>
              )}
            </InformationItem>
            <InformationItem>
              {informationVisibility.organisation.industry && (
                <>
                  <TitleItem>Industry:</TitleItem>
                  {getDataItem(information.organisation.industry)}
                </>
              )}
            </InformationItem>
          </ExtendedCodeSection>
          <ExtendedCodeSection>
            <InformationItem>
              {(informationVisibility.organisation.customerId || informationVisibility.organisation.customerName) && (
                <>
                  <TitleItem>Customer:</TitleItem>
                  {getDataItem(
                    combineToString([
                      informationVisibility.organisation.customerId ? information.organisation.customerId : undefined,
                      informationVisibility.organisation.customerName
                        ? information.organisation.customerName
                        : undefined,
                      informationVisibility.organisation.customerName
                        ? information.organisation.customerName
                        : undefined,
                    ])
                  )}
                </>
              )}
            </InformationItem>
            <InformationItem>
              {informationVisibility.organisation.autSiteCode && (
                <>
                  <TitleItem>AUT site code:</TitleItem>
                  {getDataItem(information.organisation.autSiteCode)}
                </>
              )}
            </InformationItem>
          </ExtendedCodeSection>
          <InformationItem>
            {informationVisibility.organisation.icpTradingPartner && (
              <>
                <TitleItem>ICP trading partner:</TitleItem>
                {getDataItem(information.organisation.icpTradingPartner)}
              </>
            )}
          </InformationItem>
        </DataSection>
      )}

      {informationVisibility.contractOwner !== null && (
        <DataSection noBorder={!(informationVisibility.financingMethod || informationVisibility.performanceObligation)}>
          <InformationItem>
            {informationVisibility.contractOwner.businessGroup && (
              <>
                <TitleItem>Contract owner business group:</TitleItem>
                {getDataItem(information.contractOwner.businessGroup)}
              </>
            )}
          </InformationItem>
        </DataSection>
      )}

      <DataSection>
        <CodeSection>
          <InformationItem>
            {informationVisibility.financingMethod && (
              <>
                <TitleItem>Revenue method:</TitleItem>
                {getDataItem(information.financingMethod)}
              </>
            )}
          </InformationItem>
          <InformationItem>
            {informationVisibility.performanceObligation && (
              <>
                <TitleItem>Performance obligation:</TitleItem>
                {getDataItem(information.performanceObligation)}
              </>
            )}
          </InformationItem>
        </CodeSection>
      </DataSection>
    </>
  );
}

export default ViewDetails;

const ProjectDataSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;

const ProjectDataSectionTwo = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const InformationItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding-right: 5px;
`;

const TitleItem = styled.div`
  font-size: 14px;
  margin-bottom: 4px;
`;

export const DataSection = styled.div<{ noBorder?: boolean }>`
  margin-bottom: ${({ noBorder }) => (noBorder ? "0px;" : `20px;`)};
  border-bottom: ${({ noBorder }) => (noBorder ? "0;" : `1px solid ${valmetGreyBorder}`)};
`;

const CodeSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;

export const ExtendedCodeSection = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
`;
