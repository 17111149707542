import {
  EntityTypeId,
  HierarchyItem,
  ProjectCurrencySectionInput,
  ProjectDatesSectionInput,
  ProjectDetailsEditInformation,
  ProjectId,
  ProjectInformationSectionInput,
  ProjectIntegrationsSectionInput,
  ProjectRelationSectionInput,
  ProjectRolesSectionInput,
  ProjectTagsSectionInput,
  ReportingRelationsSectionInput,
} from "../common/types";

export enum ProjectActionType {
  INIT_PROJECT = "INIT_PROJECT",
  INIT_NEW_PROJECT = "INIT_NEW_PROJECT",
  CANCEL_NEW_PROJECT = "CANCEL_NEW_PROJECT",
  FINISH_NEW_PROJECT = "FINISH_NEW_PROJECT",
  SET_PROJECT_INFORMATION = "SET_PROJECT_INFORMATION",
  SET_PROJECT_RELATIONS = "SET_PROJECT_RELATIONS",
  SET_PROJECT_DATES = "SET_PROJECT_DATES",
  SET_PROJECT_CURRENCY = "SET_PROJECT_CURRENCY",
  SET_PROJECT_ROLES = "SET_PROJECT_ROLES",
  SET_PROJECT_TAGS = "SET_PROJECT_TAGS",
  SET_PROJECT_INTEGRATIONS = "SET_PROJECT_INTEGRATIONS",
  SET_REPORTING_RELATIONS = "SET_REPORTING_RELATIONS",
  SET_PROJECT_ERROR = "SET_PROJECT_ERROR",
  SET_PROJECT_RELATING_ID = "SET_PROJECT_RELATING_ID",
  SET_PROJECT_DETAILS_EDIT_INFORMATION = "SET_PROJECT_DETAILS_EDIT_INFORMATION",
  SET_PROJECT_PENDING_CHANGE = "SET_PROJECT_PENDING_CHANGE",
  CLEAR_PROJECT_PENDING_CHANGE = "CLEAR_PROJECT_PENDING_CHANGE",
  SET_PROJECT_HIERARCHY = "SET_PROJECT_HIERARCHY",
  UPDATE_PROJECT_HIERARCHY = "UPDATE_PROJECT_HIERARCHY",
  UPDATE_PROJECT_DESCRIPTION = "UPDATE_PROJECT_DESCRIPTION",
  SET_PROJECT_APPLICATION_MODIFIED_DATE_TIME = "SET_PROJECT_APPLICATION_MODIFIED_DATE_TIME",
  SET_NODE_HIERARCHY_APPLICATION_MODIFIED_DATE_TIME = "SET_NODE_HIERARCHY_APPLICATION_MODIFIED_DATE_TIME",
}

interface TypedAction {
  type:
    | ProjectActionType.INIT_NEW_PROJECT
    | ProjectActionType.CANCEL_NEW_PROJECT
    | ProjectActionType.FINISH_NEW_PROJECT;
}

export interface InitProjectStateAction {
  type: ProjectActionType.INIT_PROJECT;
  projectId: number;
}

export interface SetProjectRelationsAction {
  type: ProjectActionType.SET_PROJECT_RELATIONS;
  relationsInput: ProjectRelationSectionInput;
}

export interface SetProjectInformationAction {
  type: ProjectActionType.SET_PROJECT_INFORMATION;
  informationInput: ProjectInformationSectionInput;
}

export interface SetProjectDatesAction {
  type: ProjectActionType.SET_PROJECT_DATES;
  datesInput: ProjectDatesSectionInput;
}

export interface SetProjectCurrencyAction {
  type: ProjectActionType.SET_PROJECT_CURRENCY;
  currencyInput: ProjectCurrencySectionInput;
}

export interface SetProjectRolesAction {
  type: ProjectActionType.SET_PROJECT_ROLES;
  rolesInput: ProjectRolesSectionInput;
}

export interface SetProjectTagsAction {
  type: ProjectActionType.SET_PROJECT_TAGS;
  projectTagsInput: ProjectTagsSectionInput;
}

export interface SetProjectIntegrationsAction {
  type: ProjectActionType.SET_PROJECT_INTEGRATIONS;
  projectIntegrationsInput: ProjectIntegrationsSectionInput;
}

export interface SetReportingRelationsAction {
  type: ProjectActionType.SET_REPORTING_RELATIONS;
  reportingRelationsInput: ReportingRelationsSectionInput;
}

export interface SetProjectErrorAction {
  type: ProjectActionType.SET_PROJECT_ERROR;
  errorType: string;
  value: boolean;
}

export interface SetProjectPendingChangeAction {
  type: ProjectActionType.SET_PROJECT_PENDING_CHANGE | ProjectActionType.CLEAR_PROJECT_PENDING_CHANGE;
  change: string;
}

export interface SetProjectRelatingId {
  type: ProjectActionType.SET_PROJECT_RELATING_ID;
  projectId: number;
  relatingProjectId: undefined | number;
}

export interface SetProjectDetailsEditInformationAction {
  type: ProjectActionType.SET_PROJECT_DETAILS_EDIT_INFORMATION;
  projectDetailsEditInformation: ProjectDetailsEditInformation;
}

export interface SetProjectHierarchyAction {
  type: ProjectActionType.SET_PROJECT_HIERARCHY;
  hierarchy: HierarchyItem;
}

export interface UpdateProjectHierarchyAction {
  type: ProjectActionType.UPDATE_PROJECT_HIERARCHY;
  projectId: ProjectId;
  description: string;
  active: boolean;
}

export interface UpdateProjectDescriptionAction {
  type: ProjectActionType.UPDATE_PROJECT_DESCRIPTION;
  description: string;
}

export interface SetProjectApplicationModifiedDateTimeAction {
  type: ProjectActionType.SET_PROJECT_APPLICATION_MODIFIED_DATE_TIME;
  applicationModifiedDateTime: string | undefined;
}

export interface SetNodeHierarchyApplicationModifiedDateTimeAction {
  type: ProjectActionType.SET_NODE_HIERARCHY_APPLICATION_MODIFIED_DATE_TIME;
  applicationModifiedDateTime: string | undefined;
  nodeId: number | undefined;
}

export type ProjectStateAction =
  | TypedAction
  | InitProjectStateAction
  | SetProjectInformationAction
  | SetProjectRelationsAction
  | SetProjectCurrencyAction
  | SetProjectDatesAction
  | SetProjectRolesAction
  | SetProjectTagsAction
  | SetProjectIntegrationsAction
  | SetReportingRelationsAction
  | SetProjectErrorAction
  | SetProjectRelatingId
  | SetProjectDetailsEditInformationAction
  | SetProjectPendingChangeAction
  | SetProjectHierarchyAction
  | UpdateProjectHierarchyAction
  | SetProjectApplicationModifiedDateTimeAction
  | SetNodeHierarchyApplicationModifiedDateTimeAction
  | UpdateProjectDescriptionAction;

export const initNewProject = (): ProjectStateAction => ({
  type: ProjectActionType.INIT_NEW_PROJECT,
});

export const cancelNewProject = (): ProjectStateAction => ({
  type: ProjectActionType.CANCEL_NEW_PROJECT,
});

export const finishNewProject = (): ProjectStateAction => ({
  type: ProjectActionType.FINISH_NEW_PROJECT,
});

export const initProjectState = (projectId: number): InitProjectStateAction => ({
  type: ProjectActionType.INIT_PROJECT,
  projectId,
});

export const setProjectRelations = (relationsInput: ProjectRelationSectionInput): SetProjectRelationsAction => ({
  type: ProjectActionType.SET_PROJECT_RELATIONS,
  relationsInput,
});

export const setProjectInformation = (
  informationInput: ProjectInformationSectionInput
): SetProjectInformationAction => ({
  type: ProjectActionType.SET_PROJECT_INFORMATION,
  informationInput,
});

export const setProjectDates = (datesInput: ProjectDatesSectionInput): SetProjectDatesAction => ({
  type: ProjectActionType.SET_PROJECT_DATES,
  datesInput,
});

export const setProjectCurrency = (currencyInput: ProjectCurrencySectionInput): SetProjectCurrencyAction => ({
  type: ProjectActionType.SET_PROJECT_CURRENCY,
  currencyInput,
});

export const setProjectRoles = (rolesInput: ProjectRolesSectionInput): SetProjectRolesAction => ({
  type: ProjectActionType.SET_PROJECT_ROLES,
  rolesInput,
});

export const setProjectTags = (projectTagsInput: ProjectTagsSectionInput): SetProjectTagsAction => ({
  type: ProjectActionType.SET_PROJECT_TAGS,
  projectTagsInput,
});

export const setProjectIntegrations = (
  integrationsInput: ProjectIntegrationsSectionInput
): SetProjectIntegrationsAction => ({
  type: ProjectActionType.SET_PROJECT_INTEGRATIONS,
  projectIntegrationsInput: integrationsInput,
});

export const setReportingRelations = (input: ReportingRelationsSectionInput): SetReportingRelationsAction => ({
  type: ProjectActionType.SET_REPORTING_RELATIONS,
  reportingRelationsInput: input,
});

export const setProjectApplicationModifiedDateTime = (
  applicationModifiedDateTime: string | undefined
): SetProjectApplicationModifiedDateTimeAction => ({
  type: ProjectActionType.SET_PROJECT_APPLICATION_MODIFIED_DATE_TIME,
  applicationModifiedDateTime,
});

export const setNodeHierarchyApplicationModifiedDateTime = (
  applicationModifiedDateTime: string | undefined,
  nodeId: number | undefined
): SetNodeHierarchyApplicationModifiedDateTimeAction => ({
  type: ProjectActionType.SET_NODE_HIERARCHY_APPLICATION_MODIFIED_DATE_TIME,
  applicationModifiedDateTime,
  nodeId,
});

export const setProjectError = (value: boolean, errorType: string): SetProjectErrorAction => ({
  type: ProjectActionType.SET_PROJECT_ERROR,
  errorType,
  value,
});

export const setProjectRelatingId = (projectId: number, relatingProjectId: undefined | number) => ({
  type: ProjectActionType.SET_PROJECT_RELATING_ID,
  projectId,
  relatingProjectId,
});

export const setProjectDetailsEditInformation = (
  projectDetailsEditInformation: ProjectDetailsEditInformation
): SetProjectDetailsEditInformationAction => ({
  type: ProjectActionType.SET_PROJECT_DETAILS_EDIT_INFORMATION,
  projectDetailsEditInformation,
});

export const setProjectPendingChange = (change: string): SetProjectPendingChangeAction => ({
  type: ProjectActionType.SET_PROJECT_PENDING_CHANGE,
  change,
});

export const clearProjectPendingChange = (change: string): SetProjectPendingChangeAction => ({
  type: ProjectActionType.CLEAR_PROJECT_PENDING_CHANGE,
  change,
});

export const setProjectHierarchy = (hierarchy: HierarchyItem): SetProjectHierarchyAction => ({
  type: ProjectActionType.SET_PROJECT_HIERARCHY,
  hierarchy,
});

export const updateProjectHierarchy = (
  projectId: ProjectId,
  description: string,
  active: boolean
): UpdateProjectHierarchyAction => ({
  type: ProjectActionType.UPDATE_PROJECT_HIERARCHY,
  projectId,
  description,
  active,
});

export const updateProjectDescription = (description: string): UpdateProjectDescriptionAction => ({
  type: ProjectActionType.UPDATE_PROJECT_DESCRIPTION,
  description,
});
