import { gql } from "@apollo/client";
import { FieldPropertiesFields } from "../queries";

export const GET_PROJECT_INFORMATION_EDIT_INFORMATION = gql`
  query GetProjectInformationEditInformation($projectId: ProjectId!) {
    projectDetailsEditInformation(projectId: $projectId) {
      projectInformation {
        organisation {
          businessGroupId {
            effective
            cached
          }
          businessGroupProperties {
            ...FieldPropertiesFields
          }
          businessTypeId {
            effective
            cached
          }
          businessTypeProperties {
            ...FieldPropertiesFields
          }
          legalEntityId {
            effective
            cached
          }
          legalEntityProperties {
            ...FieldPropertiesFields
          }
          intExtId
          intExtProperties {
            ...FieldPropertiesFields
          }
          customerId
          customerProperties {
            ...FieldPropertiesFields
          }
          industryId
          industryProperties {
            ...FieldPropertiesFields
          }
          autSiteCodeId
          autSiteCodeProperties {
            ...FieldPropertiesFields
          }
          icpTradingPartnerId
          icpTradingPartnerProperties {
            ...FieldPropertiesFields
          }
        }
        contractOwner {
          businessGroupId {
            effective
            cached
          }
          businessGroupProperties {
            ...FieldPropertiesFields
          }
          businessTypeId
          businessTypeProperties {
            ...FieldPropertiesFields
          }
          legalEntityId
          legalEntityProperties {
            ...FieldPropertiesFields
          }
        }
        EDCId
        EDCProperties {
          ...FieldPropertiesFields
        }
        autReportingCountryId
        autReportingCountryProperties {
          ...FieldPropertiesFields
        }
        deliveryOrgId
        deliveryOrgProperties {
          ...FieldPropertiesFields
        }
        deliveryLocationId
        deliveryLocationProperties {
          ...FieldPropertiesFields
        }
        financingMethodProperties {
          ...FieldPropertiesFields
        }
        performanceObligationProperties {
          ...FieldPropertiesFields
        }
        projectKeyProperties {
          ...FieldPropertiesFields
        }
        mainProjectKeyProperties {
          ...FieldPropertiesFields
        }
        projectNameProperties {
          ...FieldPropertiesFields
        }
        projectDescription {
          ... on FreetextProjectDescription {
            desc
          }
          ... on CompositeProjectDescription {
            postfix
            stem
          }
        }
        projectDescriptionProperties {
          ...FieldPropertiesFields
        }
        projectPhaseId
        projectPhaseProperties {
          ...FieldPropertiesFields
        }
        projectStatusId
        projectStatusProperties {
          ...FieldPropertiesFields
        }
        projectTechnicalTypeId
        projectTechnicalTypeProperties {
          ...FieldPropertiesFields
        }
        projectTypeId {
          effective
          cached
        }
        projectTypeProperties {
          ...FieldPropertiesFields
        }
      }
    }
  }
  ${FieldPropertiesFields}
`;

export const GET_COUNTRIES = gql`
  query GetCountries_Project_Basics {
    countries {
      id
      code
      name
    }
  }
`;

export const GET_AUT_REPORTING_COUNTRIES = gql`
  query GetAutReportingCountries {
    autReportingCountries {
      id
      code
      name
    }
  }
`;

export const GET_FINANCING_METHODS = gql`
  query GetFinancingMethods {
    financingMethods
  }
`;

export const GET_PERFORMANCE_OBLIGATIONS = gql`
  query GetPerformanceObligations {
    performanceObligations
  }
`;

export const GET_ORGANISATION_KEYS = gql`
  query GetOrganisationKeys {
    organisationKeys {
      businessGroups {
        id
        code
        description
      }
      businessTypes {
        id
        code
        description
      }
      deliveryOrgs {
        id
        code
        description
      }
      legalEntities {
        id
        code
        description
      }
      intExts {
        id
        code
        description
      }
      externalBusinessGroups {
        id
        code
        description
      }
      externalBusinessTypes {
        id
        code
        description
      }
      externalLegalEntities {
        id
        code
        description
      }
      externalReportings {
        id
        code
        description
      }
      contractOwnerBusinessGroups {
        id
        code
        description
      }
      contractOwnerBusinessTypes {
        id
        code
        description
      }
      contractOwnerLegalEntities {
        id
        code
        description
      }
      industries {
        id
        code
        description
      }
      icpTradingPartners {
        id
        code
        description
      }
      autSites {
        id
        code
        description
      }
      externalIndustries {
        id
        code
        description
      }
    }
  }
`;

export const GET_CUSTOMERS = gql`
  query GetCustomers {
    customers {
      id
      name
    }
  }
`;

export const SEARCH_CUSTOMERS = gql`
  query SearchCustomers($num: Int!, $offset: Int, $searchQuery: [String!]) {
    searchCustomers(num: $num, offset: $offset, searchQuery: $searchQuery) {
      customers {
        id
        name
      }
      availableResults
    }
  }
`;

export const GET_PROJECT_TECHNICAL_TYPES = gql`
  query GetProjectTechnicalTypes {
    projectTechnicalTypes {
      id
      description
    }
  }
`;

export const GET_PROJECT_TYPES = gql`
  query GetProjectTypes {
    projectTypes {
      id
      description
    }
  }
`;

export const GET_PROJECT_PHASES = gql`
  query GetProjectPhases {
    projectPhases {
      id
      description
    }
  }
`;

export const GET_PROJECT_STATUSES = gql`
  query GetProjectStatuses {
    projectStatuses {
      id
      description
    }
  }
`;

export const LocationPropertiesFields = gql`
  fragment LocationPropertiesFields on DeliveryLocation {
    id
    desc
    code
    description
  }
`;

export const GET_DELIVERY_LOCATIONS = gql`
  query GetDeliveryLocations {
    deliveryLocations {
      ...LocationPropertiesFields
      childItems {
        ...LocationPropertiesFields
        childItems {
          ...LocationPropertiesFields
          childItems {
            ...LocationPropertiesFields
          }
        }
      }
    }
  }
  ${LocationPropertiesFields}
`;

export const SAVE_PROJECT_INFORMATION = gql`
  mutation SaveProjectInformation($projectId: ProjectId!, $information: ProjectInformationInput!) {
    saveProjectInformation(projectId: $projectId, information: $information) {
      result {
        organisation {
          businessGroup
          businessType
          legalEntity
          intExt
          corporation
          customerId
          customerName
          industry
          autSiteCode
          icpTradingPartner
        }
        contractOwner {
          businessGroup
          businessType
          legalEntity
        }
        EDC
        autReportingCountry
        financingMethod
        performanceObligation
        projectKey
        mainProjectKey
        projectOffice
        deliveryOrg
        deliveryLocation
        name
        description
        projectPhase
        projectStatus
        projectTechnicalType
        projectType
        projectVirtualType
      }
      visibility {
        projectDates {
          OBLPeriod
          asSoldPeriod
          budgetPeriod
          recPlanStartPeriod
          recPlanEndPeriod
          recCompletionPeriod
          startUpPeriod
          projectClosingPeriodInERP
          warrantyEndPeriod
          warrantyStartPeriod
          contractEffectivePeriod
          contractExpiryPeriod
          migrationPeriod
        }
        projectCurrency {
          contractCurrency
          legalEntityCurrency
          projectCurrency
          revenueRecognitionCurrency
        }
        projectRoles {
          mainProjectManager
          mainProjectController
          projectController
          projectManager
          projectManagersManager
          projectCoordinator
        }
        projectInformation {
          name
          description
          projectKey
          mainProjectKey
          projectPhase
          projectTechnicalType
          projectStatus
          projectType
          EDC
          deliveryOrg
          deliveryLocation
          projectOffice
          financingMethod
          performanceObligation
          autReportingCountry
          organisation {
            businessGroup
            businessType
            legalEntity
            intExt
            corporation
            customerId
            customerName
            industry
            autSiteCode
            icpTradingPartner
          }
          contractOwner {
            businessGroup
            businessType
            legalEntity
          }
        }
        projectRelations
        projectTags
        integrations {
          profitStatusId
          profitPITProgressId
        }
        reportingRelations {
          relatingProjectId
          relatingProjectDesc
          externalOrganisation {
            businessGroup
            businessType
            legalEntity
            reporting
            customerId
            customerName
            externalReportingIndustry
          }
          serMarginTraceability
        }
      }
      errors
      hierarchyModifiedDateTime
      applicationModifiedDateTime
    }
  }
`;
