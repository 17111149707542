import React from "react";
import styled from "styled-components";

import { RecognitionCommentForCompilation } from "./types";
import { formatTimestamp } from "./utils";
import { UsableComment } from "./shared-components";

interface Props {
  comment: RecognitionCommentForCompilation;
  used: boolean;
  use: () => void;
  usedHidden: boolean;
}

function RecognitionComment(props: Props) {
  const {
    comment: { commentType, value, content, currencyScenario, createdBy, createdDateTime },
    used,
    use,
    usedHidden,
  } = props;
  const date = () => (createdDateTime ? formatTimestamp(createdDateTime) : undefined);
  return (
    <UsableComment used={used} usedHidden={usedHidden} use={use}>
      <CommentSubtitle>
        <Commenter>{createdBy}</Commenter>
        <CommentDate>{createdDateTime && date()}</CommentDate>
      </CommentSubtitle>
      <CommentHeader>
        <CommentTypeDesc>{commentType}</CommentTypeDesc>
        {typeof value === "number" && <CommentValue>Value: {value}</CommentValue>}
      </CommentHeader>
      <CommentText>{content}</CommentText>
      <CommentFooter>{currencyScenario && <div>{currencyScenario}</div>}</CommentFooter>
    </UsableComment>
  );
}

export default RecognitionComment;

const CommentSubtitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 10px;
  font-weight: normal;
`;

const Commenter = styled.div`
  display: flex;
`;

const CommentDate = styled.div`
  display: flex;
`;

const CommentHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: bold;
  margin-bottom: 12px;
`;

const CommentTypeDesc = styled.div`
  display: flex;
  font-size: 14px;
`;

const CommentValue = styled.div`
  display: flex;
  font-size: 12px;
`;

const CommentText = styled.div`
  font-size: 12px;
  white-space: normal;
  font-weight: normal;
`;

const CommentFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 10px;
  white-space: normal;
  font-weight: normal;
  margin-top: 12px;
`;
