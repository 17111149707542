import { gql } from "@apollo/client";

const ProjectCostTransactionDetailsFields = gql`
  fragment ProjectCostProductionAggregatedValuesFields on ProjectCostProductionAggregatedValues {
    estimatedAmount
    actualAmount
    softCommitments
    hardCommitments
    total
  }
  fragment SerOrderCostProductionAggregatedValuesFields on SerOrderCostProductionAggregatedValues {
    actuals
    poLineAmount
    poLinePrice
  }
  fragment ProjectCostProductionOrderLineLaborFields on ProjectCostProductionOrderLineLaborItem {
    description
    line
    fiscalYearPeriod
    actualAmount
    actualQuantity
    estimateAmount
    estimateQuantity
    machineQuantity
    laborRateCode
    employeeCostCenter
    transactionDate
    reportingDate
    executionDate
    employee
    employeeName
    taskCode
    taskName
  }
  fragment ProjectCostProductionOrderLineMaterialFields on ProjectCostProductionOrderLineMaterialItem {
    description
    line
    item
    fiscalYearPeriod
    supplier
    actualAmount
    actualQuantity
    estimateAmount
    estimateQuantity
    transactionDate
    invoicePictureURL
    orderPictureURL
  }
  fragment SerOrderCostProductionOrderLineMaterialFields on SerOrderCostProductionOrderLineMaterialItem {
    description
    line
    item
    fiscalYearPeriod
    supplier
    actualAmount
    actualQuantity
    estimateAmount
    estimateQuantity
    transactionDate
    invoicePictureURL
    orderPictureURL
  }
  fragment ProjectCostProductionOrderLineOtherFields on ProjectCostProductionOrderLineOtherItem {
    description
    line
    item
    fiscalYearPeriod
    supplier
    actualAmount
    actualQuantity
    estimateAmount
    estimateQuantity
    transactionDate
    invoicePictureURL
    orderPictureURL
  }
  fragment SerOrderCostProductionOrderLineOtherFields on SerOrderCostProductionOrderLineOtherItem {
    description
    line
    item
    fiscalYearPeriod
    supplier
    actualAmount
    actualQuantity
    estimateAmount
    estimateQuantity
    transactionDate
    invoicePictureURL
    orderPictureURL
  }
  fragment ProjectCostProductionOrderLineSurchargeFields on ProjectCostProductionOrderLineSurchargeItem {
    description
    line
    item
    fiscalYearPeriod
    supplier
    actualQuantity
    estimateQuantity
    actualAmount
    estimateAmount
    transactionDate
    invoicePictureURL
    orderPictureURL
  }
  fragment SerOrderCostProductionOrderLineSurchargeFields on SerOrderCostProductionOrderLineSurchargeItem {
    description
    line
    item
    fiscalYearPeriod
    supplier
    actualQuantity
    estimateQuantity
    actualAmount
    estimateAmount
    transactionDate
    invoicePictureURL
    orderPictureURL
  }
  fragment ProjectCostProductionOrderLinePurchaseFields on ProjectCostProductionOrderLinePurchaseItem {
    description
    line
    item
    fiscalYearPeriod
    supplier
    purchasedQuantity
    invoicedQuantity
    aggregatedValues {
      ...ProjectCostProductionAggregatedValuesFields
    }
    transactionDate
    orderDate
    invoiceDate
    receiptDate
    invoicePictureURL
    orderPictureURL
  }
  fragment SerOrderCostProductionOrderLineLaborFields on SerOrderCostProductionOrderLineLaborItem {
    description
    line
    fiscalYearPeriod
    actualAmount
    actualQuantity
    estimateAmount
    estimateQuantity
    machineQuantity
    laborRateCode
    employeeCostCenter
    transactionDate
    reportingDate
    executionDate
    employee
    employeeName
    taskCode
    taskName
  }
  fragment SerOrderCostProductionOrderLinePurchaseFields on SerOrderCostProductionOrderLinePurchaseItem {
    description
    line
    item
    fiscalYearPeriod
    supplier
    purchasedQuantity
    invoicedQuantity
    aggregatedValues {
      ...SerOrderCostProductionAggregatedValuesFields
    }
    transactionDate
    orderDate
    invoiceDate
    receiptDate
    invoicePictureURL
    orderPictureURL
  }
  fragment ProjectCostOtherPeriodDetailsItemFields on ProjectCostOtherPeriodDetailItem {
    description
    period
    item
    actuals
    supplier
    transactionDate
    reference
    invoicePictureURL
  }
  fragment CostDetailsNonPoInvoicesItemFields on CostDetailsNonPoInvoicesItem {
    actuals
    childItems {
      itemId
      description
      item
      actuals
      transactionDate
      invoiceDate
      invoicePictureURL
    }
  }
  fragment ProjectCostPurchasesAggregatedValuesFields on ProjectCostPurchasesAggregatedValues {
    softCommitments
    hardCommitments
    actuals
    total
  }
  fragment SerOrderCostPurchasesAggregatedValuesFields on SerOrderCostPurchasesAggregatedValues {
    poLineAmount
    poLinePrice
  }
  fragment ProjectCostPurchasesItemFields on ProjectCostPurchasesItem {
    subProject
    aggregatedValues {
      ...ProjectCostPurchasesAggregatedValuesFields
    }
    childItems {
      supplier
      aggregatedValues {
        ...ProjectCostPurchasesAggregatedValuesFields
      }
      childItems {
        order
        aggregatedValues {
          ...ProjectCostPurchasesAggregatedValuesFields
        }
        childItems {
          description
          purchasedQuantity
          orderDate
          softCommitments
          deliveredQuantity
          actualReceiptDate
          plannedReceiptDate
          estimatedReceiptDate
          hardCommitments
          invoicedQuantity
          invoiceDate
          actuals
          total
          invoicePictureURL
          orderPictureURL
        }
      }
      nonPoInvoices {
        ...CostDetailsNonPoInvoicesItemFields
      }
    }
    surcharges {
      aggregatedValues {
        ...ProjectCostPurchasesAggregatedValuesFields
      }
      childItems {
        order
        aggregatedValues {
          ...ProjectCostPurchasesAggregatedValuesFields
        }
        childItems {
          description
          purchasedQuantity
          orderDate
          softCommitments
          deliveredQuantity
          actualReceiptDate
          plannedReceiptDate
          estimatedReceiptDate
          hardCommitments
          invoicedQuantity
          invoiceDate
          actuals
          total
          invoicePictureURL
          orderPictureURL
        }
      }
    }
  }
  fragment SerOrderCostPurchasesItemFields on SerOrderCostPurchasesItem {
    subProject
    aggregatedValues {
      ...SerOrderCostPurchasesAggregatedValuesFields
    }
    childItems {
      supplier
      aggregatedValues {
        ...SerOrderCostPurchasesAggregatedValuesFields
      }
      childItems {
        order
        aggregatedValues {
          ...SerOrderCostPurchasesAggregatedValuesFields
        }
        childItems {
          description
          purchasedQuantity
          orderDate
          deliveredQuantity
          actualReceiptDate
          plannedReceiptDate
          estimatedReceiptDate
          invoicedQuantity
          invoiceDate
          poLineAmount
          poLinePrice
          invoicePictureURL
          orderPictureURL
        }
      }
      nonPoInvoices {
        ...CostDetailsNonPoInvoicesItemFields
      }
    }
    surcharges {
      aggregatedValues {
        ...SerOrderCostPurchasesAggregatedValuesFields
      }
      childItems {
        order
        aggregatedValues {
          ...SerOrderCostPurchasesAggregatedValuesFields
        }
        childItems {
          description
          purchasedQuantity
          orderDate
          deliveredQuantity
          actualReceiptDate
          plannedReceiptDate
          estimatedReceiptDate
          invoicedQuantity
          invoiceDate
          poLineAmount
          poLinePrice
          invoicePictureURL
          orderPictureURL
        }
      }
    }
  }
  fragment ProjectCostWarehouseCostLineItemFields on ProjectCostWarehouseCostLineItem {
    itemId
    description
    orderCurrency
    fiscalYearPeriod
    costComponent
    warehouseOrder
    warehouseOrderLine
    supplier
    item
    estimatedAmount
    estimatedQuantity
    invoicedQuantity
    actualAmount
    actualQuantity
    actuals
    total
    softCommitments
    hardCommitments
    transactionDate
    receivedDate
    invoicedDate
    date
    invoicePictureUrl
    orderPictureUrl
  }
  fragment ProjectCostWarehouseAggregatedValuesFields on ProjectCostWarehouseAggregatedValues {
    orderCurrency
    actualAmount
    estimatedAmount
    actuals
    hardCommitments
    softCommitments
    total
  }
  fragment ProjectCostWarehouseSubItemFields on ProjectCostWarehouseSubItem {
    itemId
    description
    childItems {
      ...ProjectCostWarehouseCostLineItemFields
    }
    aggregatedValues {
      ...ProjectCostWarehouseAggregatedValuesFields
    }
  }
  fragment ProjectCostWarehouseOrderItemFields on ProjectCostWarehouseOrderItem {
    itemId
    order
    description
    childItems {
      ...ProjectCostWarehouseSubItemFields
    }
    aggregatedValues {
      ...ProjectCostWarehouseAggregatedValuesFields
    }
  }
  fragment ProjectCostWarehouseItemFields on ProjectCostWarehouseItem {
    itemId
    description
    childItems {
      ...ProjectCostWarehouseOrderItemFields
    }
    aggregatedValues {
      ...ProjectCostWarehouseAggregatedValuesFields
    }
  }
  fragment SerOrderCostWarehouseCostLineItemFields on SerOrderCostWarehouseCostLineItem {
    itemId
    description
    orderCurrency
    fiscalYearPeriod
    costComponent
    warehouseOrder
    warehouseOrderLine
    supplier
    item
    orderLineAmount
    estimatedQuantity
    invoicedQuantity
    actualQuantity
    transactionDate
    receivedDate
    invoicedDate
    date
    invoicePictureUrl
    orderPictureUrl
  }
  fragment SerOrderCostWarehouseAggregatedValuesFields on SerOrderCostWarehouseAggregatedValues {
    orderCurrency
    orderLineAmount
  }
  fragment SerOrderCostWarehouseSubItemFields on SerOrderCostWarehouseSubItem {
    itemId
    description
    childItems {
      ...SerOrderCostWarehouseCostLineItemFields
    }
    aggregatedValues {
      ...SerOrderCostWarehouseAggregatedValuesFields
    }
  }
  fragment SerOrderCostWarehouseOrderItemFields on SerOrderCostWarehouseOrderItem {
    itemId
    order
    description
    childItems {
      ...SerOrderCostWarehouseSubItemFields
    }
    aggregatedValues {
      ...SerOrderCostWarehouseAggregatedValuesFields
    }
  }
  fragment SerOrderCostWarehouseItemFields on SerOrderCostWarehouseItem {
    itemId
    description
    childItems {
      ...SerOrderCostWarehouseOrderItemFields
    }
    aggregatedValues {
      ...SerOrderCostWarehouseAggregatedValuesFields
    }
  }
`;

export const GET_PROJECT_COST_DETAILS = gql`
  query ProjectCostDetails(
    $projectId: ProjectId!
    $activityId: ActivityId!
    $estimatesCurrencyId: CurrencyId!
    $actualsCurrencyId: CurrencyId!
    $currencyRate: Float!
  ) {
    projectCostsDetails(
      projectId: $projectId
      activityId: $activityId
      estimatesCurrencyId: $estimatesCurrencyId
      actualsCurrencyId: $actualsCurrencyId
      currencyRate: $currencyRate
    ) {
      labor {
        subProject
        quantity
        actuals
        childItems {
          employee
          quantity
          actuals
          childItems {
            period
            quantity
            actuals
            childItems {
              costLine
              quantity
              actuals
              childItems {
                description
                quantity
                actuals
                laborRateCode
                employeeCostcenter
                transactionDate
                reportingDate
                executionDate
                task
              }
            }
          }
        }
      }
      production {
        ... on ProjectCostProductionItem {
          subProject
          aggregatedValues {
            ...ProjectCostProductionAggregatedValuesFields
          }
          childItems {
            order
            aggregatedValues {
              ...ProjectCostProductionAggregatedValuesFields
            }
            laborTotal {
              ...ProjectCostProductionAggregatedValuesFields
            }
            labor {
              ...ProjectCostProductionOrderLineLaborFields
            }
            materialsTotal {
              ...ProjectCostProductionAggregatedValuesFields
            }
            materials {
              ...ProjectCostProductionOrderLineMaterialFields
            }
            purchasesTotal {
              ...ProjectCostProductionAggregatedValuesFields
            }
            purchases {
              ...ProjectCostProductionOrderLinePurchaseFields
            }
            surchargesTotal {
              ...ProjectCostProductionAggregatedValuesFields
            }
            surcharges {
              ...ProjectCostProductionOrderLineSurchargeFields
            }
            othersTotal {
              ...ProjectCostProductionAggregatedValuesFields
            }
            others {
              ...ProjectCostProductionOrderLineOtherFields
            }
          }
        }
        ... on SerOrderCostProductionItem {
          subProject
          aggregatedValues {
            ...SerOrderCostProductionAggregatedValuesFields
          }
          childItems {
            order
            aggregatedValues {
              ...SerOrderCostProductionAggregatedValuesFields
            }
            laborTotal {
              ...SerOrderCostProductionAggregatedValuesFields
            }
            labor {
              ...SerOrderCostProductionOrderLineLaborFields
            }
            materialsTotal {
              ...SerOrderCostProductionAggregatedValuesFields
            }
            materials {
              ...SerOrderCostProductionOrderLineMaterialFields
            }
            purchasesTotal {
              ...SerOrderCostProductionAggregatedValuesFields
            }
            purchases {
              ...SerOrderCostProductionOrderLinePurchaseFields
            }
            surchargesTotal {
              ...SerOrderCostProductionAggregatedValuesFields
            }
            surcharges {
              ...SerOrderCostProductionOrderLineSurchargeFields
            }
            othersTotal {
              ...SerOrderCostProductionAggregatedValuesFields
            }
            others {
              ...SerOrderCostProductionOrderLineOtherFields
            }
          }
        }
      }
      purchases {
        ... on ProjectCostPurchasesItem {
          ...ProjectCostPurchasesItemFields
        }
        ... on SerOrderCostPurchasesItem {
          ...SerOrderCostPurchasesItemFields
        }
      }
      other {
        subProject
        actuals
        costComponentItems {
          costComponent
          actuals
          childItems {
            period
            actuals
            childItems {
              ...ProjectCostOtherPeriodDetailsItemFields
            }
          }
        }
        otherItems {
          description
          actuals
          childItems {
            period
            actuals
            childItems {
              ...ProjectCostOtherPeriodDetailsItemFields
            }
          }
        }
      }
      warehouse {
        ... on ProjectCostWarehouseItem {
          ...ProjectCostWarehouseItemFields
        }
        ... on SerOrderCostWarehouseItem {
          ...SerOrderCostWarehouseItemFields
        }
      }
    }
  }
  ${ProjectCostTransactionDetailsFields}
`;
