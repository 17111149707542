import React from "react";
import { ProjectDates, ProjectDatesVisibility } from "../../../../../common/types";
import styled from "styled-components";
import { DateValue, InformationItem, TitleItem } from "./shared-components";

export interface ViewDetailsProps {
  dates: ProjectDates;
  datesVisibility: ProjectDatesVisibility;
}

function ViewDetails(props: ViewDetailsProps): React.ReactElement {
  const { dates, datesVisibility } = props;

  const dateField = (fieldName: keyof ProjectDates & keyof ProjectDatesVisibility, desc: string) => (
    <InformationItem>
      {datesVisibility[fieldName] && (
        <>
          <TitleItem>{desc}:</TitleItem>
          <DateValue value={dates[fieldName]} />
        </>
      )}
    </InformationItem>
  );

  return (
    <DateSection>
      {dateField("recPlanStartPeriod", "Project start period")}
      {dateField("OBLPeriod", "Order backlog period")}
      {dateField("contractEffectivePeriod", "Contract effective period")}

      {dateField("asSoldPeriod", "As sold period")}
      {dateField("budgetPeriod", "Budget period")}
      {dateField("startUpPeriod", "Start-up period")}

      {dateField("warrantyStartPeriod", "Warranty start period")}
      {dateField("recCompletionPeriod", "Rec completion period")}
      {dateField("contractExpiryPeriod", "Contract expiry period")}

      {dateField("warrantyEndPeriod", "Warranty end period")}
      {dateField("projectClosingPeriodInERP", "Project closing period")}
      {dateField("recPlanEndPeriod", "Project end period")}

      {dateField("migrationPeriod", "Migration period")}
      {dateField("financeClosingPeriod", "AS BL transfer to finance")}
    </DateSection>
  );
}

export default ViewDetails;

const DateSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;
