import { TextButton, ValueButton } from "../../../../../../common/components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import * as React from "react";
import styled from "styled-components";
import { filterGreen, settingGreen, valmetGreyBorder } from "../../../../../../common/colors";

export interface DropdownItem {
  id: string;
  description: string;
  depth: number;
}

export interface SearchableDropdownProps {
  values: DropdownItem[];
  selection: string | string[];
  onValueSelected: (value: string | null, description: string | null) => void;
  maxResults?: number;
  inputWidth?: string;
  disabledValueIds?: string[];
  scrollingDisabled?: boolean;
  onLoadMore?: () => void;
  morePagesAvailable?: boolean;
  loading?: boolean;
}

function SearchableDropdown(props: SearchableDropdownProps): React.ReactElement {
  const {
    values,
    onValueSelected,
    selection,
    maxResults,
    inputWidth,
    disabledValueIds,
    scrollingDisabled,
    onLoadMore,
    morePagesAvailable,
    loading,
  } = props;

  const displayedValues =
    scrollingDisabled && maxResults !== undefined && maxResults < values.length ? values.slice(0, maxResults) : values;
  const checked = (value: string): boolean => {
    if (typeof selection === "string") {
      return value === selection;
    } else {
      return selection.includes(value);
    }
  };

  return (
    <DropdownContent>
      <ValueContainer
        minWidth={inputWidth}
        maxVisibleItems={maxResults ? maxResults + (scrollingDisabled ? 1 : 0) : maxResults}
      >
        {displayedValues.length > 0 ? (
          displayedValues.map(value => (
            <ValueButton
              key={value.id}
              disabled={disabledValueIds && disabledValueIds.includes(value.id)}
              onClick={e => {
                e.stopPropagation();
                onValueSelected(value.id, value.description);
              }}
            >
              {value.description}
              <SelectionMarker>{checked(value.id) && <FontAwesomeIcon icon={faCheck} size="sm" />}</SelectionMarker>
            </ValueButton>
          ))
        ) : loading ? (
          <TextButton>Loading...</TextButton>
        ) : (
          <TextButton>No results</TextButton>
        )}
        {scrollingDisabled && maxResults && maxResults < values.length && (
          <ResultsTextContainer>Search for more results.</ResultsTextContainer>
        )}
        {morePagesAvailable && (
          <ValueButton
            special={true}
            onClick={event => {
              event.stopPropagation();
              if (onLoadMore) onLoadMore();
            }}
          >
            Load more results...
          </ValueButton>
        )}
      </ValueContainer>
    </DropdownContent>
  );
}

export default SearchableDropdown;

const DropdownContent = styled.div<{ extraMargin?: boolean }>`
  background: white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  position: absolute;
  display: flex;
  flex-direction: column;

  border: 1px solid ${valmetGreyBorder};
  z-index: 500;
  button:hover {
    background: ${filterGreen};
  }
  margin-top: 24px;
`;

const ValueContainer = styled.div<{ minWidth?: string; maxVisibleItems?: number }>`
  overflow-y: auto;
  ${({ minWidth }) => minWidth && `min-width: ${minWidth};`}
  ${({ maxVisibleItems }) => maxVisibleItems && `max-height: ${maxVisibleItems * 29}px;`}
`;

const SelectionMarker = styled.div`
  color: ${settingGreen};
  margin-left: 6px;
`;

const ResultsTextContainer = styled.div`
  font-size: 10px;
  padding: 6px;
`;
