import * as React from "react";
import onClickOutside, { HandleClickOutside, InjectedOnClickOutProps } from "react-onclickoutside";
import styled from "styled-components";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { valmetGreyLight, valmetGreyHeader, filterGreen } from "../../../common/colors";
import { CollapseButton, ToggleSwitch } from "../../../common/components";
import {
  EntityTypeId,
  HierarchyItem,
  HierarchyItemEnum,
  NodeId,
  ProjectId,
  ProjectTypeId,
} from "../../../common/types";
import { getHierarchyItemViewColor } from "./ProjectHierarchy/HierarchyTree/HierarchyItemView";
import { Crown } from "./Comments/shared-components";
import AdditionalHeaderButtons from "./ProjectBasics/HierarchySection/AdditionalHeaderButtons";
import {
  inactiveHierarchyProjectsFilters,
  HierarchyProjectsFilters,
  isHierarchyItemVisible,
} from "../../../common/hierarchyUtils";
import ProjectTypeIcon from "../../ProjectTypeIcon/ProjectTypeIcon";
import routes from "../../../common/routes";

type Props = {
  hierarchy: HierarchyItem;
  onItemSelected: (item: HierarchyItem) => void;
  id: ProjectId | NodeId;
  itemType: HierarchyItemEnum;
};

type HierarchyDrawOptions = {
  groupAdjustments: boolean;
  hierarchyFilters: HierarchyProjectsFilters;
};

type State = {
  isOpen: boolean;
  options: HierarchyDrawOptions;
};

const drawHierarchy = (
  hierarchyItem: HierarchyItem,
  selected: { id: ProjectId | NodeId; itemType: HierarchyItemEnum },
  onItemSelected: (item: HierarchyItem) => void,
  hasSuccessor: boolean,
  parent: HierarchyItem | null,
  options: HierarchyDrawOptions
) => {
  const projects_ = hierarchyItem.childItems.filter(
    item => item.itemType === HierarchyItemEnum.Project && isHierarchyItemVisible(item, options.hierarchyFilters)
  );
  const projectsNoAdj = projects_.filter(a => a.relatingProjectId === null);
  const adjustments = projects_.filter(a => a.relatingProjectId !== null);
  const projects = options.groupAdjustments
    ? projectsNoAdj.flatMap(project => {
        const as = adjustments.filter(a => a.relatingProjectId === project.id);
        return [project].concat(as);
      })
    : projects_;
  const nodes = hierarchyItem.childItems.filter(item => item.itemType === HierarchyItemEnum.Node);
  const isRoot = hierarchyItem.childLevel === 0;
  return (
    <HierarchyContainer key={hierarchyItem.itemType + "-" + hierarchyItem.id}>
      <Item>
        {!isRoot && (
          <ItemPadding up={true} down={hasSuccessor}>
            <Padding />
            <Padding />
          </ItemPadding>
        )}
        <ItemButton
          href={routes.node(hierarchyItem.id)}
          projectTechnicalType={null}
          projectType={null}
          emphasize={hierarchyItem.itemType === selected.itemType && hierarchyItem.id === selected.id}
          onClick={ev => {
            ev.preventDefault();
            onItemSelected(hierarchyItem);
          }}
        >
          {parent?.mainProjectId === hierarchyItem.mainProjectId && <Crown />}
          <ItemTitle>{hierarchyItem.description}</ItemTitle>
          <ItemTitle>({hierarchyItem.mainProjectId || "-"})</ItemTitle>
        </ItemButton>
      </Item>
      <ChildrenRow>
        {!isRoot && <ChildrenPadding line={hasSuccessor} />}
        <ChildrenContainer>
          {projects.map((project, index) => (
            <Item key={project.itemType + "-" + project.id}>
              <ItemPadding up={true} down={nodes.length > 0 || index < projects.length - 1}>
                <Padding wide={project.relatingProjectId !== null} />
                <Padding />
              </ItemPadding>
              <ItemButton
                href={routes.project(project.id)}
                key={project.itemType + "-" + project.id}
                projectTechnicalType={project.projectTechnicalTypeId || null}
                projectType={project.projectTypeId || null}
                emphasize={project.itemType === selected.itemType && project.id === selected.id}
                onClick={ev => {
                  ev.preventDefault();
                  onItemSelected(project);
                }}
              >
                {project.id === hierarchyItem.mainProjectId && <Crown />}
                <ProjectTypeIcon
                  projectTechnicalType={project.projectTechnicalTypeId}
                  noMargin
                  adjustmentRateInfo={project.adjustmentRateInfo}
                />
                <ItemTitle>{project.id}</ItemTitle>
                <ItemTitle>{project.description}</ItemTitle>
              </ItemButton>
            </Item>
          ))}
          {nodes.map((node, index) =>
            drawHierarchy(node, selected, onItemSelected, index < nodes.length - 1, hierarchyItem, options)
          )}
        </ChildrenContainer>
      </ChildrenRow>
    </HierarchyContainer>
  );
};

const HierarchyContainer = styled.div``;
const ChildrenRow = styled.div`
  display: flex;
  flex-direction: row;
`;
const ChildrenPadding = styled.div<{ line: boolean }>`
  width: 20px;
  ${({ line }) => line && `border-left: 2px solid ${valmetGreyHeader};`}
`;
const ChildrenContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 10px;
`;

class HierarchyHeader extends React.Component<
  Props & InjectedOnClickOutProps & HandleClickOutside<React.MouseEventHandler>,
  State
> {
  state: State = {
    isOpen: false,
    options: {
      groupAdjustments: false,
      hierarchyFilters: inactiveHierarchyProjectsFilters,
    },
  };

  toggle = (): void => {
    if (this.state.isOpen) {
      this.props.disableOnClickOutside();
    } else {
      this.props.enableOnClickOutside();
    }
    this.setState(state => ({ ...state, isOpen: !state.isOpen }));
  };

  handleClickOutside = (): void => {
    this.props.disableOnClickOutside();
    this.setState(state => ({ ...state, isOpen: false }));
  };

  open = (): void => {
    this.props.enableOnClickOutside();
    this.setState(state => ({ ...state, isOpen: true }));
  };

  setGroupAdjustments = (value: boolean): void => {
    const options = {
      ...this.state.options,
      groupAdjustments: value,
    };
    this.setState(state => ({ ...state, options }));
  };

  setHierarchyFilters = (filters: HierarchyProjectsFilters): void => {
    this.setState(state => ({ ...state, options: { ...state.options, hierarchyFilters: filters } }));
  };

  render(): React.ReactElement {
    const { hierarchy, onItemSelected, id, itemType } = this.props;
    const { isOpen, options } = this.state;

    const onClickHierarchyItem = (item: HierarchyItem): void => {
      if (item.id === id && item.itemType === itemType) {
        this.toggle();
      } else {
        onItemSelected(item);
      }
    };
    return (
      <Wrapper>
        <CollapseButton onClick={this.toggle} title="Project hierarchy" fontSize={"20px"}>
          <FontAwesomeIcon icon={faChevronDown} size="1x" color={valmetGreyLight} />
        </CollapseButton>
        {isOpen && (
          <DropdownContainer>
            <DropdownHeader>
              <ToggleSwitch
                onChange={this.setGroupAdjustments}
                checked={options.groupAdjustments}
                text={"Group adjustments to related"}
              />
              <AdditionalHeaderButtons
                itemType={hierarchy.itemType}
                projectId={id}
                hierarchyProjectsFilters={options.hierarchyFilters}
                setHierarchyProjectsFilters={this.setHierarchyFilters}
                displayInactiveItems={false}
                setDisplayInactiveItems={() => undefined}
              />
            </DropdownHeader>
            <DropdownContent>
              {drawHierarchy(hierarchy, { id, itemType }, onClickHierarchyItem, false, null, options)}
            </DropdownContent>
          </DropdownContainer>
        )}
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  > button:first-child {
    min-width: 40px;
  }
`;

const DropdownContainer = styled.div`
  right: 0;
  background: white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  position: absolute;
  display: flex;
  flex-direction: column;

  border: 1px solid ${valmetGreyHeader};
  z-index: 900;
  button:hover {
    background: ${filterGreen};
  }
  max-height: 460px;
  width: max-content;
  max-width: 500px;
`;

const DropdownHeader = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px;
  box-shadow: #42261d61 0 10px 20px;
  z-index: 1;
`;

const DropdownContent = styled.div`
  padding: 5px;
  overflow-y: auto;
`;

const Item = styled.div`
  display: flex;
  flex-direction: row;
`;

const ItemPadding = styled.div<{ up: boolean; down: boolean }>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  ${({ up }) =>
    up
      ? `>div:first-child { border-left: 2px solid ${valmetGreyHeader}; border-bottom: 2px solid ${valmetGreyHeader}; }`
      : ""}
  ${({ down }) => (down ? `>div:last-child { border-left: 2px solid ${valmetGreyHeader}; }` : "")}
`;

const Padding = styled.div<{ wide?: boolean }>`
  padding: 0;
  margin: 0;
  width: ${({ wide }) => (wide ? "20px" : "10px")};
  height: 50%;
`;

const ItemButton = styled.a<{
  projectTechnicalType: EntityTypeId | null;
  projectType: ProjectTypeId | null;
  emphasize: boolean;
}>`
  color: inherit;
  text-decoration: inherit;
  background: ${({ projectTechnicalType, projectType, emphasize }) =>
    emphasize ? filterGreen : getHierarchyItemViewColor(projectTechnicalType, projectType)};
  font-size: 12px;
  padding: 6px;
  width: 100%;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: flex-start;
  margin-top: -1px;
  border: 1px solid ${valmetGreyHeader};
  ${({ emphasize }) => (emphasize ? `font-weight: bold; border: 3px solid ${valmetGreyHeader}; ;` : "")}
`;

const ItemTitle = styled.div`
  margin-right: 10px;
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default onClickOutside<any, Props>(HierarchyHeader);
