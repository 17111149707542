import React, { useCallback, useRef, useState } from "react";
import styled from "styled-components";
import { cancelRed, filterGreen, settingGreen } from "../../../../common/colors";
import { CollapseButton, ValueButton } from "../../../../common/components";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { useMount } from "../../../../hooks/useMount";
import { SAVE_USER_PREFERENCES, updateEstRecEditMode } from "../../../../actions/userPreferencesActions";
import { RecPlanEstRecEditModes } from "./recognitionsEditingSetting";
import { AppState } from "../../../../common/types";
import { RecognitionEditType, UserPreferencesResult } from "./types";
import { useMutation } from "@apollo/client/react/hooks";

export type EstRecEditMode = {
  mode: RecPlanEstRecEditModes;
  description: string;
};

const RecPlanEstRecEditModeList: EstRecEditMode[] = [
  {
    mode: RecPlanEstRecEditModes.WipCosts,
    description: "Edit EST REC Wip Costs",
  },
  {
    mode: RecPlanEstRecEditModes.Ns,
    description: "Edit EST REC NS",
  },
  {
    mode: RecPlanEstRecEditModes.CogsPercentage,
    description: "Edit EST REC% Cogs%",
  },
  {
    mode: RecPlanEstRecEditModes.NsCogsPercentage,
    description: "Edit EST REC% NS% and Cogs% separately",
  },
];

const defaultEditMode = RecPlanEstRecEditModeList[0];

const mapStateToProps = (state: AppState) => {
  const isEstimateEditable = state.recognitionEditState.options.editProjectType !== RecognitionEditType.LnServiceOrder;
  const userPreference = state.userPreferencesState.estRecEditMode;
  return {
    selectedMode: isEstimateEditable ? userPreference : RecPlanEstRecEditModes.WipCosts,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setSelectedMode: (estRecEditModes: RecPlanEstRecEditModes) => {
      dispatch(updateEstRecEditMode(estRecEditModes));
    },
  };
};

type EstRecEditModeDropdownProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

function EstRecEditModeDropdown(props: EstRecEditModeDropdownProps): React.ReactElement {
  const { selectedMode, setSelectedMode } = props;
  const [saveError, setSaveError] = useState<string | undefined>(undefined);
  // For LN service orders the estimate cost, ns or est rec ns% or cogs% are not editable, so it does
  // not make sense to show the edit mode selection.
  const findMode = RecPlanEstRecEditModeList.find(value => value.mode === selectedMode) || defaultEditMode;

  const menuRef = useRef<HTMLDivElement | null>(null);
  const [listening, setListening] = useState(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  function handleOutsideClicks(ev: DocumentEventMap[keyof DocumentEventMap]) {
    if (menuRef.current === null) {
      stopListener();
    } else if (menuRef.current.contains(ev.target as HTMLDivElement)) return;
    else setIsOpen(false);
  }

  function startListener() {
    if (listening) return;
    if (!menuRef.current) return;
    setListening(true);
    document.addEventListener(`click`, handleOutsideClicks, true);
  }

  function stopListener() {
    document.removeEventListener(`click`, handleOutsideClicks, true);
  }

  useMount(startListener, stopListener);

  const [saveUserPreferences] = useMutation<{ saveUserPreferences: UserPreferencesResult }>(SAVE_USER_PREFERENCES);
  const onSaveAndUpdateEstRecEditMode = useCallback(
    (estRecEditMode: RecPlanEstRecEditModes) => {
      setSelectedMode(estRecEditMode); // Update Redux state before saving to improve user experience.
      setSaveError(undefined);
      saveUserPreferences({
        variables: {
          userPreferencesInput: {
            estRecEditMode: estRecEditMode,
          },
        },
      })
        .then(res => {
          if (res && res.data && res.data.saveUserPreferences.userPreferencesState) {
            setSelectedMode(res.data.saveUserPreferences.userPreferencesState.estRecEditMode);
          } else if (res && res.data && res.data.saveUserPreferences.error) {
            setSaveError(res.data.saveUserPreferences.error);
          }
        })
        .catch(exception => {
          setSaveError(`Failed saving user preference. ${exception.message}`);
        });
    },
    [saveUserPreferences]
  );

  return (
    <DropdownContainer ref={menuRef} onClick={() => setIsOpen(!isOpen)}>
      <p>{findMode.description}</p>
      <CollapseButton title={"Select filter"} fontSize={"12px"}>
        <FontAwesomeIcon icon={faChevronDown} size="lg" color={settingGreen} />
      </CollapseButton>
      {saveError && <ErrorText>{saveError}</ErrorText>}
      {isOpen && (
        <DropdownContent>
          {RecPlanEstRecEditModeList.map(value => (
            <ValueButton key={value.mode} onClick={() => onSaveAndUpdateEstRecEditMode(value.mode)}>
              {value.description}
            </ValueButton>
          ))}
        </DropdownContent>
      )}
    </DropdownContainer>
  );
}

const DropdownContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;

  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
  p {
    font-size: 12px;
    color: ${settingGreen};
    margin: 0;
  }
`;

const DropdownContent = styled.div`
  background: white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  position: absolute;
  display: flex;
  flex-direction: column;
  min-width: 200px;
  z-index: 500;
  button:hover {
    background: ${filterGreen};
  }
  margin-top: 140px;
`;

const ErrorText = styled.div`
  font-size: 12px;
  color: ${cancelRed};
`;

export default connect(mapStateToProps, mapDispatchToProps)(EstRecEditModeDropdown);
