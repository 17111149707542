export type ProfitStatusValue = {
  id: ProfitStatusId;
  description: string;
};

export enum ProfitStatus {
  Draft = 1,
  Active = 2,
  InClosing = 3,
  Closed = 4,
}

export type ProfitStatusId = ProfitStatus;
export type ProfitPITProgressId = number;
