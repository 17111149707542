import React, { Dispatch, PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { useQuery } from "@apollo/client";
import {
  faSearch,
  faSortAlphaDown,
  faSortAlphaUp,
  faSortNumericDown,
  faSortNumericUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Container, ContentContainer, HeaderContainer, ProjectDisplay } from "../shared";
import routes from "../../../../common/routes";
import { notificationHeaderYellow, settingGreen } from "../../../../common/colors";
import { MyProjectsResult, ProjectInfo } from "./types";
import { MY_PROJECTS } from "./queries";
import LoadingView from "../../../LoadingView";
import ErrorBox from "../../../ErrorBox";

function ProjectView(props: { project: ProjectInfo }): React.ReactElement {
  const {
    project: { id, desc, technicalType },
  } = props;
  return (
    <ProjectContainer>
      <Link href={routes.project(id)}>
        <ProjectDisplay projectId={id} projectTechnicalType={technicalType} description={desc} />
      </Link>
    </ProjectContainer>
  );
}

const PAGE_SIZE = 20;

type PageControlFn = (() => void) | undefined;

type PagingState<T> = {
  items: T[];
  page: number;
  totalPages: number;
  controls: {
    next: PageControlFn;
    prev: PageControlFn;
    first: PageControlFn;
    last: PageControlFn;
  };
};

function usePagination<T>(allItems: T[] | null | undefined): PagingState<T> {
  const [page, setPage] = useState(0);
  const totalPages = useMemo(() => {
    if (allItems) {
      return Math.floor((allItems.length + PAGE_SIZE - 1) / PAGE_SIZE);
    } else return 0;
  }, [allItems]);
  const prev = useCallback(() => {
    if (page > 0) setPage(page - 1);
  }, [page, setPage, totalPages]);
  const next = useCallback(() => {
    if (page < totalPages - 1) setPage(page + 1);
  }, [page, setPage, totalPages]);
  const first = useCallback(() => setPage(0), [setPage]);
  const last = useCallback(() => setPage(totalPages - 1), [setPage, totalPages]);
  const items: T[] = useMemo(() => {
    if (allItems) {
      const start = page * PAGE_SIZE;
      return allItems.slice(start, start + PAGE_SIZE);
    } else return [];
  }, [allItems, page]);
  return {
    items,
    page,
    totalPages,
    controls: {
      prev: page > 0 ? prev : undefined,
      next: page < totalPages - 1 ? next : undefined,
      first: page > 0 ? first : undefined,
      last: page < totalPages - 1 ? last : undefined,
    },
  };
}

enum Sorting {
  ALPHA_DOWN,
  ALPHA_UP,
  NUM_DOWN,
  NUM_UP,
}

type FilteringState<T, F> = {
  allItems: T[];
  filteredItems: T[];
  filters: F;
  setFilters: Dispatch<F>;
};

function useFiltering<T, F>(
  items: T[],
  initialFilters: F,
  filter: (items: T[], filters: F) => T[]
): FilteringState<T, F> {
  const [filters, setFilters] = useState(initialFilters);
  const filteredItems = useMemo(() => filter(items, filters), [items, filters]);
  return {
    allItems: items,
    filteredItems,
    filters,
    setFilters,
  };
}

type Filters = {
  search: string;
  sorting: Sorting;
};

const sortingIcons = {
  [Sorting.ALPHA_DOWN]: faSortAlphaDown,
  [Sorting.ALPHA_UP]: faSortAlphaUp,
  [Sorting.NUM_DOWN]: faSortNumericDown,
  [Sorting.NUM_UP]: faSortNumericUp,
};

function Filter(props: { filters: Filters; setFilters: Dispatch<Filters> }): React.ReactElement {
  const { filters, setFilters } = props;
  const { search, sorting } = filters;
  const [searchFocused, setSearchFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  function setFilterValue<T>(produce: (value: T) => { [key: string]: T }): Dispatch<T> {
    return useCallback((value: T) => setFilters({ ...filters, ...produce(value) }), [filters, setFilters]);
  }

  const setSearch = setFilterValue((search: string) => ({ search }));
  const setSorting = setFilterValue((sorting: Sorting) => ({ sorting }));

  //const setSearch = useCallback((search: string) => setFilters({ ...filters, search }), [filters, setFilters]);
  //const setSorting = useCallback((sorting: Sorting) => setFilters({ ...filters, sorting }), [filters, setFilters]);

  useEffect(() => {
    if (searchFocused && inputRef.current) inputRef.current.focus();
  }, [searchFocused, inputRef.current]);

  const showSearch = searchFocused || search.length > 0;

  return (
    <FilterTray>
      <IconButton
        onClick={() => {
          setSearchFocused(true);
        }}
      >
        <FontAwesomeIcon icon={faSearch} />
      </IconButton>
      <InputWrapper show={showSearch}>
        <SearchInput
          show={showSearch}
          edit={searchFocused}
          ref={inputRef}
          value={search}
          onChange={ev => setSearch(ev.target.value)}
          onFocus={() => setSearchFocused(true)}
          onBlur={() => setSearchFocused(false)}
        />
        {showSearch && <ClearButton onClick={() => setSearch("")}>x</ClearButton>}
      </InputWrapper>
      <IconButton onClick={() => setSorting(sorting === Sorting.NUM_UP ? Sorting.ALPHA_DOWN : sorting + 1)}>
        <FontAwesomeIcon icon={sortingIcons[sorting]} />
      </IconButton>
    </FilterTray>
  );
}

const FilterTray = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  margin-right: 10px;
`;

const InputWrapper = styled.div<{ show: boolean }>`
  display: flex;
  width: ${({ show }) => (show ? "210px" : "0")};
  overflow: hidden;
  transition: width 0.5s;
`;

const SearchInput = styled.input<{ show: boolean; edit: boolean }>`
  display: ${({ show }) => (show ? "initial" : "none")};
  font-size: 16px;
`;

const ClearButton = styled.button`
  border: 0;
  border-radius: 0;
  background: transparent;
  color: ${settingGreen};
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  margin-left: -20px;
`;

const IconButton = styled.button`
  padding: 0
  border: 0;
  background: transparent;
  color: ${settingGreen};
  cursor: pointer;
  font-size: 20px;
`;

const stringCompare = (a: string, b: string) => a.localeCompare(b); //, "fi"); // String.prototype.localeCompare.bind(a, b, "fi")();

const filterProjects = (items: ProjectInfo[], { search, sorting }: Filters) => {
  const searchTerms = search.split(" ");
  const compare = (function () {
    switch (sorting) {
      case Sorting.ALPHA_DOWN:
        return (a: ProjectInfo, b: ProjectInfo) => stringCompare(a.desc, b.desc);
      case Sorting.ALPHA_UP:
        return (a: ProjectInfo, b: ProjectInfo) => stringCompare(b.desc, a.desc);
      case Sorting.NUM_DOWN:
        return (a: ProjectInfo, b: ProjectInfo) => b.id - a.id;
      case Sorting.NUM_UP:
        return (a: ProjectInfo, b: ProjectInfo) => a.id - b.id;
    }
  })();
  return items
    .filter(p => {
      return searchTerms.every(term => p.desc.includes(term) || p.id.toString().includes(term));
    })
    .sort(compare);
};

const PageButton = ({ children, onClick }: PropsWithChildren<{ onClick: PageControlFn }>) => {
  return (
    <PageControlButton disabled={!onClick} onClick={onClick}>
      {children}
    </PageControlButton>
  );
};

function MyProjects(): React.ReactElement {
  const { data, loading, error } = useQuery<{ myProjects: MyProjectsResult }>(MY_PROJECTS);

  const { filteredItems, filters, setFilters } = useFiltering(
    (data && data.myProjects.projects) || [],
    {
      search: "",
      sorting: Sorting.ALPHA_DOWN,
    },
    filterProjects
  );

  const { items: projectsDisplayed, page, totalPages, controls: pageControls } = usePagination(filteredItems);

  return (
    <Container>
      <HeaderContainer>
        <Header>
          <span title="Projects where you are either project manager or controller">My Projects</span>
        </Header>
        <Filter filters={filters} setFilters={setFilters} />
      </HeaderContainer>
      <ContentContainer>
        {!data && !loading ? (
          <ErrorBox caption="Error" apolloError={error} />
        ) : loading || !data ? (
          <LoadingView />
        ) : data.myProjects.projects.length === 0 ? (
          "No projects"
        ) : (
          <>
            <ProjectsContainer>
              {projectsDisplayed.map(project => (
                <ProjectView key={project.id} project={project} />
              ))}
            </ProjectsContainer>
            <PageControls>
              <PageButton onClick={pageControls.first}>&lt;&lt;</PageButton>
              <PageButton onClick={pageControls.prev}>&lt;</PageButton>
              {page + 1} of {totalPages}
              <PageButton onClick={pageControls.next}>&gt;</PageButton>
              <PageButton onClick={pageControls.last}>&gt;&gt;</PageButton>
            </PageControls>
          </>
        )}
      </ContentContainer>
    </Container>
  );
}

export default MyProjects;

const Header = styled.div`
  padding: 10px;
`;

const ProjectsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;
`;

const ProjectContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
  padding-left: 20px;
  background-color: ${notificationHeaderYellow};
`;

const Link = styled.a`
  color: inherit;
  text-decoration: none;
`;

const PageControls = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  justify-content: center;
  align-items: center;
  padding: 10px;
`;

const PageControlButton = styled.button`
  cursor: pointer;
  padding-left: 20px;
  padding-right: 20px;
`;
