import { gql } from "@apollo/client";

const ProjectTagFields = gql`
  fragment ProjectTagFields on ProjectTag {
    id
    description
  }
`;

export const GET_PROJECT_TAGS_EDIT_INFORMATION = gql`
  query GetProjectTagsEditInformation($projectId: ProjectId!) {
    projectDetailsEditInformation(projectId: $projectId) {
      tags {
        fixedTags {
          ...ProjectTagFields
        }
        editableTags {
          ...ProjectTagFields
        }
        allSelectableTags {
          ...ProjectTagFields
        }
      }
    }
  }
  ${ProjectTagFields}
`;

export const SAVE_PROJECT_TAGS = gql`
  mutation SaveProjectTags($projectId: ProjectId!, $tags: ProjectTagsInput!) {
    saveProjectTags(projectId: $projectId, tags: $tags) {
      result {
        ...ProjectTagFields
      }
      errors
      applicationModifiedDateTime
    }
  }
  ${ProjectTagFields}
`;
