import { AUTH_USER, UNAUTH_USER, AUTH_ERROR } from "../actions/authActions";
import { User, Action } from "../common/types";

export interface AuthState {
  authenticated: boolean;
  user?: User;
  error?: unknown;
}

export const initState = {
  authenticated: false,
};

export default function authReducer(state: Readonly<AuthState> = initState, action: Action): AuthState {
  switch (action.type) {
    case AUTH_USER:
      return {
        ...state,
        authenticated: true,
        user: action.data,
        error: {},
      };
    case UNAUTH_USER:
      return {
        ...state,
        user: undefined,
        authenticated: false,
      };
    case AUTH_ERROR:
      return {
        ...state,
        error: {
          auth: action.data,
        },
      };
    default:
      return state;
  }
}
