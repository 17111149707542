import React from "react";
import { ProjectCurrency, ProjectCurrencyVisibility } from "../../../../../common/types";
import styled from "styled-components";

export interface ViewDetailsProps {
  currency: ProjectCurrency;
  currencyVisibility: ProjectCurrencyVisibility;
}

function ViewDetails(props: ViewDetailsProps): React.ReactElement {
  const { currency, currencyVisibility } = props;
  return (
    <CurrencySection>
      {currencyVisibility.contractCurrency && (
        <DataCell>
          <>
            <DataTitle>Contract currency</DataTitle>
            <DataItem>{currency.contractCurrency ? currency.contractCurrency : "-"}</DataItem>
          </>
        </DataCell>
      )}
      <DataCell>
        {currencyVisibility.legalEntityCurrency && (
          <>
            <DataTitle>Legal company&apos;s currency</DataTitle>
            <DataItem>{currency.legalEntityCurrency ? currency.legalEntityCurrency : "-"}</DataItem>
          </>
        )}
      </DataCell>
      <DataCell>
        {currencyVisibility.revenueRecognitionCurrency && (
          <>
            <DataTitle>Revenue recognition currency</DataTitle>
            <DataItem>{currency.revenueRecognitionCurrency ? currency.revenueRecognitionCurrency : "-"}</DataItem>
          </>
        )}
      </DataCell>
      <DataCell>
        {currencyVisibility.externalReportingCurrency && (
          <>
            <DataTitle>External reporting currency</DataTitle>
            <DataItem>{currency.externalReportingCurrency ? currency.externalReportingCurrency : "-"}</DataItem>
          </>
        )}
      </DataCell>
      <DataCell>
        {currencyVisibility.internalReportingCurrency && (
          <>
            <DataTitle>Internal reporting currency</DataTitle>
            <DataItem>{currency.internalReportingCurrency ? currency.internalReportingCurrency : "-"}</DataItem>
          </>
        )}
      </DataCell>
    </CurrencySection>
  );
}

export default ViewDetails;

const CurrencySection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;

const DataCell = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const DataTitle = styled.div`
  font-size: 14px;
  margin-bottom: 4px;
`;

const DataItem = styled.div`
  font-weight: bold;
  font-size: 14px;
`;
