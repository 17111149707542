import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import styled from "styled-components";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { clearProjectPendingChange, setProjectPendingChange } from "../../../../../actions/projectActions";
import { warningYellow, valmetGreyLight } from "../../../../../common/colors";
import { InputIconButton } from "../../../../../common/components";

type InputSize = "smallest" | "small" | "normal" | "large" | "extra-large" | "full";

interface EditableTextProps {
  value: string;
  onValueChanged: (value: string | null) => void;
  disabled?: boolean;
  error?: boolean;
  inputSize?: InputSize;
  id: string;
  charLimit?: number;
  // maps the value and returns optionally an error, which is shown as a tooltip
  validate?: (value: string) => [string, string | null];
}
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setChangePending: (change: string) => {
      dispatch(setProjectPendingChange(change));
    },
    clearChangePending: (change: string) => {
      dispatch(clearProjectPendingChange(change));
    },
  };
};

function EditableText(props: EditableTextProps & ReturnType<typeof mapDispatchToProps>): React.ReactElement {
  const {
    value,
    onValueChanged,
    disabled,
    error,
    inputSize,
    validate,
    setChangePending,
    clearChangePending,
    id,
    charLimit,
  } = props;
  const [input, setInput] = useState("");
  const [tooltip, setTooltip] = useState<[string, boolean]>(["", false]);

  const timeout = useRef<number>();
  const tooltipTimeout = useRef<number>();

  const onNewInput = useCallback(() => {
    if (value !== input) {
      onValueChanged(input.length ? input : null);
    }
    clearChangePending(id);
  }, [input, onValueChanged, id, clearChangePending, value]);

  const handleInputTimeout = useCallback(() => {
    clearTimeout(timeout.current);
    setChangePending(id);
    timeout.current = setTimeout(() => {
      onNewInput();
    }, 1000);
  }, [id, setChangePending, onNewInput]);

  useEffect(handleInputTimeout, [input]);

  const onNewValue = useCallback(() => {
    if (value !== input) {
      setInput(value);
    }
  }, [value, input, setInput]);

  useEffect(onNewValue, [value, setInput]);
  useEffect(() => {
    clearTimeout(tooltipTimeout.current);
    if (tooltip[0] !== "" && tooltip[1]) {
      tooltipTimeout.current = setTimeout(() => {
        setTooltip([tooltip[0], false]);
      }, 2500);
    }
  }, [tooltip]);

  return (
    <Container inputSize={inputSize ? inputSize : "normal"}>
      <TextInput
        type="text"
        value={input}
        onChange={event => {
          if (validate) {
            const [mapped, error] = validate(event.target.value);
            if (error !== null) {
              setTooltip([error, true]);
            } else {
              setTooltip([tooltip[0], false]);
            }
            setInput(mapped);
          } else {
            setInput(event.target.value);
          }
        }}
        disabled={disabled}
        error={error}
        inputSize={inputSize ? inputSize : "normal"}
        maxLength={charLimit}
      />
      {!disabled && (
        <InputIconButton onClick={() => onValueChanged(null)} disabled={disabled}>
          <FontAwesomeIcon icon={faTimes} size="1x" color={valmetGreyLight} />
        </InputIconButton>
      )}
      {<Tooltip show={tooltip[1]}>{tooltip[0]}</Tooltip>}
    </Container>
  );
}

export default connect(undefined, mapDispatchToProps)(EditableText);

const Container = styled.div<{ inputSize: InputSize }>`
  display: flex;
  ${({ inputSize }) => {
    switch (inputSize) {
      case "extra-large":
      case "full":
        return "width: 100%;";
      default:
        return "width: fit-content;";
    }
  }}
  height: fit-content;
`;

const TextInput = styled.input<{ error?: boolean; inputSize: InputSize }>`
  ${({ inputSize }) => {
    switch (inputSize) {
      case "smallest":
        return "width: 75px; font-weight: bold; font-size: 14px";
      case "small":
        return "width: 100px; font-weight: bold; font-size: 14px";
      case "large":
        return "width: 260px; font-weight: bold; font-size: 22px";
      case "extra-large":
        return "width: 100%; font-weight: bold; font-size: 22px";
      case "full":
        return "width: 100%; font-weight: bold; font-size: 14px";
      case "normal":
      default:
        return "width: 160px; font-weight: bold; font-size: 14px";
    }
  }};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 12px;
  ${({ error }) => error && `border: 2px solid red; background: ${warningYellow}`};
`;

const Tooltip = styled.div<{ show: boolean }>`
  position: absolute;
  margin-top: -45px;
  margin-left: -40px;
  padding: 4px;
  border: 1px solid;
  border-radius: 4px;
  background-color: ${warningYellow};

  opacity: ${({ show }) => (show ? 1.0 : 0.0)};
  transition: opacity 250ms ease-out;
`;
