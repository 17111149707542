import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { cloneDeep, remove } from "lodash";
import { valmetGreyLight } from "../../../../../common/colors";
import { ProjectVirtualType } from "../../../../../common/types";
import { PROJECT_COST_HOURS_COLUMNS, PROJECT_COST_HOURS_COLUMNS_SER_ORDER } from "./constants";
import { ContextMenu, ContextMenuTrigger, MenuItem } from "react-contextmenu";
import { CollapseButton } from "../../../../../common/components";
import {
  ProjectCostLaborEmployeeItem,
  ProjectCostLaborItem,
  ProjectCostLaborLineDetailItem,
  ProjectCostLaborLineItem,
  ProjectCostLaborPeriodItem,
  ProjectCostLaborTableItem,
} from "./types";
import {
  DataCell,
  DataContainer,
  DataRow,
  DetailsTable,
  HeaderCell,
  HeaderRow,
  TableBody,
  TypedDataCell,
} from "./components";
import { justifyToRight } from "./column";

export interface LaborTableProps {
  laborItems: ProjectCostLaborItem[];
  projectVirtualType: ProjectVirtualType;
  popupWindow: boolean;
}

// if costLine is null in all items, this is not a LN Service Order project, so cost line layer should be removed.
const transferPeriodChildItems = (
  periodItem: ProjectCostLaborPeriodItem
): ProjectCostLaborLineItem[] | ProjectCostLaborLineDetailItem[] => {
  if (periodItem.childItems.some(item => item.costLine !== null && item.childItems.length > 0)) {
    return periodItem.childItems;
  } else {
    return periodItem.childItems.flatMap(item => item.childItems);
  }
};

const transformItem = (
  item:
    | ProjectCostLaborItem
    | ProjectCostLaborEmployeeItem
    | ProjectCostLaborPeriodItem
    | ProjectCostLaborLineItem
    | ProjectCostLaborLineDetailItem,
  parentId?: string
) => {
  const transformedItem: ProjectCostLaborTableItem = {
    id: `${
      item["subProject"]
        ? (item["subProject"] as string)
        : item["employee"]
        ? `${item["employee"] as string}_${parentId ? parentId : ""}`
        : item["period"]
        ? `${item["period"] as string}_${parentId ? parentId : ""}`
        : item["costLine"] !== undefined
        ? `${item["costLine"] as string}_${parentId ? parentId : ""}`
        : `lineDetail_${parentId ? parentId : ""}`
    }`,
    childLevel: item["subProject"]
      ? 0
      : item["employee"]
      ? 1
      : item["period"]
      ? 2
      : item["costLine"] !== undefined || item["childItems"]
      ? 3
      : 4,
    childItems: item["subProject"]
      ? (item["childItems"] as ProjectCostLaborEmployeeItem[])
      : item["employee"]
      ? (item["childItems"] as ProjectCostLaborPeriodItem[])
      : item["period"]
      ? transferPeriodChildItems(item as ProjectCostLaborPeriodItem)
      : item["costLine"] !== undefined
      ? (item["childItems"] as ProjectCostLaborLineDetailItem[])
      : undefined,
    subProject: item["subProject"] ? (item["subProject"] as string) : undefined,
    employee: item["employee"] ? (item["employee"] as string) : undefined,
    period: item["period"] ? (item["period"] as string) : undefined,
    costLine: item["costLine"] !== undefined ? (item["costLine"] as string) : undefined,
    description: item["description"] ? (item["description"] as string) : undefined,
    quantity: item["quantity"] ? (item["quantity"] as number) : undefined,
    actuals: item["actuals"] !== undefined ? (item["actuals"] as number) : undefined,
    laborRateCode: item["laborRateCode"] ? (item["laborRateCode"] as string) : undefined,
    employeeCostcenter: item["employeeCostcenter"] ? (item["employeeCostcenter"] as string) : undefined,
    transactionDate: item["transactionDate"] ? (item["transactionDate"] as string) : undefined,
    reportingDate: item["reportingDate"] ? (item["reportingDate"] as string) : undefined,
    executionDate: item["executionDate"] ? (item["executionDate"] as string) : undefined,
    task: item["task"] ? (item["task"] as string) : undefined,
  };
  return transformedItem;
};

const getChildrenItems = (item: ProjectCostLaborTableItem, list: ProjectCostLaborTableItem[], parent?: boolean) => {
  if (!parent) {
    list.push(item);
  }

  if (item.childItems && item.childItems.length > 0) {
    item.childItems.forEach(
      (
        childItem:
          | ProjectCostLaborEmployeeItem
          | ProjectCostLaborPeriodItem
          | ProjectCostLaborLineItem
          | ProjectCostLaborLineDetailItem
      ) => getChildrenItems(transformItem(childItem, item.id), list)
    );
  }
};

const setInitialChildrenVisible = (
  items: ProjectCostLaborTableItem[],
  setRenderedRows: React.Dispatch<React.SetStateAction<ProjectCostLaborTableItem[]>>,
  setOpenHourItems: React.Dispatch<React.SetStateAction<string[]>>
) => {
  const renderedRows: ProjectCostLaborTableItem[] = [];
  const newOpenHourItems: string[] = [];
  items.forEach(item => {
    renderedRows.push(item);
    newOpenHourItems.push(item.id);
    if (item.childItems) {
      item.childItems.forEach(
        (
          childItem:
            | ProjectCostLaborEmployeeItem
            | ProjectCostLaborPeriodItem
            | ProjectCostLaborLineItem
            | ProjectCostLaborLineDetailItem
        ) => {
          renderedRows.push(transformItem(childItem, item.id));
        }
      );
    }
  });
  setRenderedRows(renderedRows);
  setOpenHourItems(newOpenHourItems);
};

const closeHourItemHierarchy = (
  hourItem: ProjectCostLaborTableItem,
  setRenderedRows: React.Dispatch<React.SetStateAction<ProjectCostLaborTableItem[]>>,
  renderedRows: ProjectCostLaborTableItem[],
  openHourItems: string[],
  setOpenHourItems: React.Dispatch<React.SetStateAction<string[]>>
) => {
  const childItems: ProjectCostLaborTableItem[] = [];
  getChildrenItems(hourItem, childItems, true);

  const newItems = cloneDeep(renderedRows);
  remove(newItems, openItem => childItems.map(item => item.id).includes(openItem.id));
  setRenderedRows(newItems);

  childItems.push(hourItem);
  const newOpenHourItems = cloneDeep(openHourItems);
  remove(newOpenHourItems, openItem => childItems.map(item => item.id).includes(openItem));
  setOpenHourItems(newOpenHourItems);
};

const openHourItemHierarchy = (
  hourItem: ProjectCostLaborTableItem,
  setRenderedRows: React.Dispatch<React.SetStateAction<ProjectCostLaborTableItem[]>>,
  renderedRows: ProjectCostLaborTableItem[],
  openHourItems: string[],
  setOpenHourItems: React.Dispatch<React.SetStateAction<string[]>>,
  index: number
) => {
  const newItems = cloneDeep(renderedRows);
  const newOpenHourItems = cloneDeep(openHourItems);
  if (hourItem.childItems) {
    hourItem.childItems.forEach(
      (
        childItem:
          | ProjectCostLaborEmployeeItem
          | ProjectCostLaborPeriodItem
          | ProjectCostLaborLineItem
          | ProjectCostLaborLineDetailItem,
        i: number
      ) => {
        const transformedItem = transformItem(childItem, hourItem.id);
        newItems.splice(index + 1 + i, 0, transformedItem);
      }
    );
    newOpenHourItems.push(hourItem.id);
  }

  setOpenHourItems(newOpenHourItems);
  setRenderedRows(newItems);
};

const expandAll = (
  openHourItems: string[],
  setOpenHourItems: React.Dispatch<React.SetStateAction<string[]>>,
  item: ProjectCostLaborTableItem,
  setRenderedRows: React.Dispatch<React.SetStateAction<ProjectCostLaborTableItem[]>>,
  renderedRows: ProjectCostLaborTableItem[],
  index: number
) => {
  const newLabor = cloneDeep(renderedRows);
  const newOpenLabor = cloneDeep(openHourItems);

  const childItems: ProjectCostLaborTableItem[] = [];
  getChildrenItems(item, childItems, true);
  remove(newLabor, hourItem => childItems.map(item => item.id).includes(hourItem.id));

  childItems.push(item);
  remove(newOpenLabor, hourItem => childItems.map(item => item.id).includes(hourItem));
  childItems.pop();

  newOpenLabor.push(item.id);
  childItems.forEach(childItem => {
    if (childItem.childItems) {
      newOpenLabor.push(childItem.id);
    }
  });

  newLabor.splice(index + 1, 0, ...childItems);

  setOpenHourItems(newOpenLabor);
  setRenderedRows(newLabor);
};

function LaborTable(props: LaborTableProps): React.ReactElement {
  const { laborItems, projectVirtualType, popupWindow } = props;
  const [openHourItems, setOpenHourItems] = useState<string[]>([]);
  const [renderedRows, setRenderedRows] = useState<ProjectCostLaborTableItem[]>([]);

  console.log("renderedRows ", renderedRows);
  useEffect(() => {
    if (laborItems) {
      setInitialChildrenVisible(
        laborItems.map(item => transformItem(item)),
        setRenderedRows,
        setOpenHourItems
      );
    }
  }, [laborItems]);

  const columns =
    projectVirtualType === ProjectVirtualType.LnServiceOrder
      ? PROJECT_COST_HOURS_COLUMNS_SER_ORDER
      : PROJECT_COST_HOURS_COLUMNS;

  return (
    <DetailsTable>
      <TableBody>
        <HeaderRow>
          <HeaderCell right={false} popupWindow={popupWindow}>
            Description
          </HeaderCell>
          {columns.map((column, i) => (
            <HeaderCell key={i} right={justifyToRight(column)} title={column.tooltip} popupWindow={popupWindow}>
              {column.description}
            </HeaderCell>
          ))}
        </HeaderRow>
        {renderedRows.map((item, index) => (
          <DataRow
            key={`${item.id}_${index}`}
            childLevel={item.childLevel}
            extraPadding={!item.childItems || item.childItems.length === 0}
          >
            <DataCell>
              <DataContainer>
                {item.childItems && item.childItems.length > 0 && (
                  <ContextMenuTrigger id={`${item.id}-context-menu`}>
                    <CollapseButton
                      onClick={e => {
                        e.stopPropagation();
                        if (item.childItems && item.childItems.length > 0) {
                          if (openHourItems.includes(item.id)) {
                            closeHourItemHierarchy(
                              item,
                              setRenderedRows,
                              renderedRows,
                              openHourItems,
                              setOpenHourItems
                            );
                          } else {
                            openHourItemHierarchy(
                              item,
                              setRenderedRows,
                              renderedRows,
                              openHourItems,
                              setOpenHourItems,
                              index
                            );
                          }
                        }
                      }}
                    >
                      <FontAwesomeIcon
                        icon={openHourItems.includes(item.id) ? faChevronUp : faChevronDown}
                        size="1x"
                        color={valmetGreyLight}
                      />
                    </CollapseButton>
                  </ContextMenuTrigger>
                )}
                {item["subProject"]
                  ? item["subProject"]
                  : item["employee"]
                  ? item["employee"]
                  : item["period"]
                  ? item["period"]
                  : item["costLine"] !== undefined
                  ? "Cost line " + item["costLine"]
                  : item["description"]}
                <ContextMenu id={`${item.id}-context-menu`}>
                  <MenuItem
                    onClick={() =>
                      expandAll(openHourItems, setOpenHourItems, item, setRenderedRows, renderedRows, index)
                    }
                  >
                    Expand all
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      closeHourItemHierarchy(item, setRenderedRows, renderedRows, openHourItems, setOpenHourItems)
                    }
                  >
                    Collapse all
                  </MenuItem>
                </ContextMenu>
              </DataContainer>
            </DataCell>
            {columns.map((column, i) => (
              <TypedDataCell key={item.id + "_" + i} column={column} item={item} />
            ))}
          </DataRow>
        ))}
      </TableBody>
    </DetailsTable>
  );
}

export default LaborTable;
