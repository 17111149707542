import {
  INITIALIZE_USER_PREFERENCES_STATE,
  UPDATE_EST_REC_EDIT_MODE,
  UserPreferencesAction,
} from "../actions/userPreferencesActions";
import { UserPreferencesState } from "../components/sites/Project/ProjectRecognitions/types";
import { RecPlanEstRecEditModes } from "../components/sites/Project/ProjectRecognitions/recognitionsEditingSetting";

export const userPreferencesInitState: UserPreferencesState = {
  estRecEditMode: RecPlanEstRecEditModes.WipCosts,
};

export default function userPreferencesReducer(
  state: Readonly<UserPreferencesState> = userPreferencesInitState,
  action: UserPreferencesAction
): UserPreferencesState {
  switch (action.type) {
    case INITIALIZE_USER_PREFERENCES_STATE:
      return action.userPreferences;
    case UPDATE_EST_REC_EDIT_MODE:
      return { ...state, estRecEditMode: action.estRecEditMode };
    default:
      return state;
  }
}
