import React, { useState } from "react";
import styled from "styled-components";
import ColumnSetsDropdownSection from "./ColumnSetsDropdownSection";
import { AppState, FilterValues } from "../../../../common/types";
import { ColumnSet } from "../../../../common/columnsTypes";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { setColumnSet, addCustomColumnSet } from "../../../../actions/columnSetActions";
import ColumnSettingsModal from "./ColumnSettingsModal";
import { valmetGreyHeader } from "../../../../common/colors";
import { ALL_DEFAULT_LISTING_COLUMN_SETS, AVAILABLE_COLUMNS } from "../../../../common/columns";
import { ApolloClient, gql } from "@apollo/client";
import { useApolloClient } from "@apollo/client/react/hooks";
import FullscreenSpinner from "../../../FullscreenSpinner";

const mapStateToProps = (state: AppState) => {
  return {
    selectedColumnSet: state.columnSetState.columnSet,
    filters: state.filtersState.filters,
    isAdmin: state.authState.user ? state.authState.user.isAdmin : false,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setColumnSet: (columnSet: ColumnSet, filters: FilterValues) => {
      dispatch(setColumnSet(columnSet, filters));
    },
    addCustomColumnSet: (columnSet: ColumnSet) => {
      dispatch(addCustomColumnSet(columnSet));
    },
  };
};

export interface ColumnConfigurationProps {
  onOpenTopList: () => void;
  topListOpen: boolean;
  projectsFound: number | undefined;
}

const SAVE_VIEW = gql`
  mutation SaveView($view: ViewInput!) {
    saveView(view: $view) {
      id
      name
      columns
      isGlobal
    }
  }
`;

const DELETE_VIEW = gql`
  mutation DeleteView($id: ViewId!) {
    deleteView(id: $id)
  }
`;

const onSave = async (
  columnSet: ColumnSet,
  client: ApolloClient<Record<string, unknown>>,
  setLastMutated: React.Dispatch<React.SetStateAction<number | undefined>>,
  setColumnSet: (columnSet: ColumnSet, filters: FilterValues) => void,
  filters: FilterValues,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  isGlobal: boolean
) => {
  setLoading(true);
  const { data, errors } = await client
    .mutate({
      mutation: SAVE_VIEW,
      variables: {
        view: {
          id: columnSet.id.length ? columnSet.id : undefined,
          name: columnSet.description,
          columns: columnSet.columns.map(column => column.id),
          isGlobal,
        },
      },
    })
    .catch(e => {
      return e;
    });
  if (data && !errors) {
    setLastMutated(Date.now());
    setColumnSet(columnSet, filters);
  }
  setLoading(false);
};

const onDeleteView = async (
  id: string,
  client: ApolloClient<Record<string, unknown>>,
  setLastMutated: React.Dispatch<React.SetStateAction<number | undefined>>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setLoading(true);
  const { data, errors } = await client
    .mutate({
      mutation: DELETE_VIEW,
      variables: { id },
    })
    .then(res => {
      return res;
    })
    .catch(e => {
      console.error(e);
      return e;
    });
  if (data && !errors) {
    setLastMutated(Date.now());
  }
  setLoading(false);
};

function ColumnConfiguration(
  props: ColumnConfigurationProps & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
): React.ReactElement {
  const { selectedColumnSet, setColumnSet, projectsFound, filters, isAdmin /*onOpenTopList, topListOpen*/ } = props;

  const client = useApolloClient() as ApolloClient<Record<string, unknown>>;
  const [lastMutated, setLastMutated] = useState<number | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [editableColumnSet, setEditableColumnSet] = useState<ColumnSet | undefined>(undefined);

  return (
    <Container>
      <ProjectAmount>{`Found ${projectsFound !== undefined ? projectsFound : "..."} projects`}</ProjectAmount>
      <ColumnContentContainer>
        <ColumnSetsDropdownSection
          selectedColumnSet={selectedColumnSet}
          onColumnSetSelection={columnSet => setColumnSet(columnSet, filters)}
          defaultColumnSets={ALL_DEFAULT_LISTING_COLUMN_SETS}
          lastMutated={lastMutated}
          onDelete={id => onDeleteView(id, client, setLastMutated, setLoading)}
          onEdit={columnSet => setEditableColumnSet(columnSet)}
        />
        <ColumnSettingsModal
          onSave={(columnSet, isGlobal) =>
            onSave(columnSet, client, setLastMutated, setColumnSet, filters, setLoading, isGlobal)
          }
          availableColumns={AVAILABLE_COLUMNS}
          editableColumnSet={editableColumnSet}
          resetEditableColumnSet={() => setEditableColumnSet(undefined)}
          isAdmin={isAdmin}
        />
      </ColumnContentContainer>
      {loading && <FullscreenSpinner />}
    </Container>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ColumnConfiguration);

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-right: 100px;
`;

const ColumnContentContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;

const ProjectAmount = styled.div`
  font-size: 10px;
  color: ${valmetGreyHeader};
`;
