import React, { useState } from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import MonthDropdown from "./MonthDropdown";
import { valmetGreyLight, warningYellow } from "../../common/colors";
import { Period } from "../../common/period";
import { InputIconButton } from "../../common/components";

interface EditableMonthProps {
  value: string;
  onValueChanged: (value: string | null) => void;
  disabled?: boolean;
  error?: boolean;
  minPeriod?: Period;
  maxPeriod?: Period;
}

function EditableMonth(props: EditableMonthProps) {
  const { value, onValueChanged, disabled, error, minPeriod, maxPeriod } = props;
  const [showMonths, setShowMonths] = useState(false);

  const close = () => {
    setShowMonths(false);
  };

  let yearMonth;
  if (value.length && value.split("-").length === 2) {
    yearMonth = value.split("-");
  }

  return (
    <Container>
      <TextInput
        type="text"
        onClick={() => setShowMonths(true)}
        value={value}
        readOnly={true}
        disabled={disabled}
        error={error}
      />
      {!disabled && (
        <InputIconButton onClick={() => onValueChanged(null)} disabled={disabled}>
          <FontAwesomeIcon icon={faTimes} size="1x" color={valmetGreyLight} />
        </InputIconButton>
      )}
      {showMonths && (
        <MonthDropdown
          close={close}
          year={yearMonth ? Number.parseInt(yearMonth[0]) : undefined}
          month={yearMonth ? Number.parseInt(yearMonth[1]) : undefined}
          minPeriod={minPeriod}
          maxPeriod={maxPeriod}
          onValueChanged={newValue => onValueChanged(newValue)}
        />
      )}
    </Container>
  );
}

export default EditableMonth;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
  align-items: center;
`;

const TextInput = styled.input<{ error?: boolean }>`
  width: 75px;
  font-weight: bold;
  font-size: 14px;
  ${({ error }) => error && `border: 2px solid red; background: ${warningYellow}`};
  padding-right: 12px;
`;
