import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import styled from "styled-components";
import { useApolloClient, useQuery } from "@apollo/client/react/hooks";
import { ApolloClient, ApolloError } from "@apollo/client";
import {
  addMainProjectChange,
  resetProjectHierarchy,
  setProjectHierarchyAddedNodes,
  setProjectHierarchyNode,
  setProjectHierarchyParentNodeId,
} from "../../../../actions/hierarchyActions";
import { filterGreen, settingGreen } from "../../../../common/colors";
import {
  AppState,
  GetNodeData,
  HierarchyItem,
  HierarchyItemEnum,
  HierarchyTreeNode,
  MainProjectChangeRequest,
  NodeId,
  ProjectHierarchyInfoData,
  ProjectId,
  SearchNode,
} from "../../../../common/types";
import HierarchySearch from "./HierarchySearch";
import HierarchyTree from "./HierarchyTree";
import NewHierarchyView from "./NewHierarchyView";
import { GET_NODE, GET_PROJECT_HIERARCHY, saveProjectHierarchy } from "./queries";
import CreateProjectActionsHeader from "../../../ProjectHeader/CreateProjectActionsHeader";
import { RouteComponentProps, withRouter } from "react-router-dom";
import routes from "../../../../common/routes";
import {
  cancelNewProject,
  setNodeHierarchyApplicationModifiedDateTime,
  setProjectApplicationModifiedDateTime,
} from "../../../../actions/projectActions";
import CreateProjectErrorsBox from "../../../ProjectHeader/CreateProjectErrorsBox";
import { pollForBasicDataSaveReady } from "../queries";
import { readProjectDescription } from "../ProjectBasics/utils";

export enum Modes {
  NewNode,
  ExistingNode,
}

enum AssignedHierarchyKind {
  None,
  SystemAssigned,
  UserAssigned,
}

interface NoHierarchyAssignment {
  kind: AssignedHierarchyKind.None;
}

const noAssignment: NoHierarchyAssignment = { kind: AssignedHierarchyKind.None };

interface UserAssigned {
  kind: AssignedHierarchyKind.UserAssigned;
}

interface SystemAssigned {
  kind: AssignedHierarchyKind.SystemAssigned;
  hierarchy: HierarchyItem;
  fromRelatingProject: boolean;
}

type HierarchyAssignment = NoHierarchyAssignment | UserAssigned | SystemAssigned;

const mapStateToProps = (state: AppState) => {
  return {
    selectedParentNodeId: state.hierarchyState.parentNodeId,
    addedNodes: state.hierarchyState.addedNodes,
    mainProjectChanges: state.hierarchyState.mainProjectChanges,
    projectId: state.projectState.projectId,
    relatingProjectId: state.projectState.projectRelatingId,
    projectDescription:
      (state.projectState.projectDetailsEditInformation &&
        state.projectState.projectDetailsEditInformation.projectInformation &&
        readProjectDescription(
          state.projectState.projectDetailsEditInformation.projectInformation.projectDescription
        )) ||
      "New Project",
    projectApplicationModifiedDateTime: state.projectState.projectApplicationModifiedDateTime,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setProjectHierarchyAddedNodes: (addedNodes: HierarchyTreeNode[]) => {
      dispatch(setProjectHierarchyAddedNodes(addedNodes));
    },
    setProjectHierarchyParentNodeId: (parentNodeId: number | undefined | null, projectId?: number) => {
      dispatch(setProjectHierarchyParentNodeId(parentNodeId, projectId));
    },
    resetProjectHierarchy: () => {
      dispatch(resetProjectHierarchy());
    },
    setProjectHierarchyNode: (nodeValue: string | undefined, projectId: number | undefined) => {
      dispatch(setProjectHierarchyNode(nodeValue, projectId));
    },
    addMainProjectChange: (mainProjectChange: MainProjectChangeRequest) => {
      dispatch(addMainProjectChange(mainProjectChange));
    },
    setProjectApplicationModifiedDateTime: (projectApplicationModifiedDateTime: string | undefined) => {
      dispatch(setProjectApplicationModifiedDateTime(projectApplicationModifiedDateTime));
    },
    setNodeHierarchyApplicationModifiedDateTime: (
      projectApplicationModifiedDateTime: string | undefined,
      nodeId: NodeId | undefined
    ) => {
      dispatch(setNodeHierarchyApplicationModifiedDateTime(projectApplicationModifiedDateTime, nodeId));
    },
    cancelNewProject: () => {
      dispatch(cancelNewProject());
      dispatch(resetProjectHierarchy());
    },
  };
};

function useExistingHierarchyQuery(
  projectId: ProjectId | undefined,
  relatingProjectId: ProjectId | undefined
): {
  loading: boolean;
  assignment: HierarchyAssignment;
  error?: ApolloError;
} {
  const [result, setResult] = useState<HierarchyAssignment>(noAssignment);

  const hierarchyRetries = useRef(10);
  useEffect(() => {
    hierarchyRetries.current = 10;
  }, [projectId]);

  const {
    loading: loadingProjectHierarchy,
    error: projectHierarchyError,
    refetch: refetchHierarchy,
  } = useQuery<ProjectHierarchyInfoData>(GET_PROJECT_HIERARCHY, {
    variables: {
      id: projectId,
      itemType: HierarchyItemEnum.Project,
    },
    skip: projectId === undefined,
    fetchPolicy: "cache-and-network",
    onCompleted: data => {
      if (data && data.headerInfo) {
        if (data.headerInfo.hierarchy.id === 0) {
          setResult({
            kind: AssignedHierarchyKind.UserAssigned,
          });
        } else {
          setResult({
            kind: AssignedHierarchyKind.SystemAssigned,
            hierarchy: data.headerInfo.hierarchy,
            fromRelatingProject: false,
          });
        }
      } else {
        hierarchyRetries.current--;
      }
    },
  });

  useEffect(() => {
    if (result.kind === AssignedHierarchyKind.None) {
      const timeout = setTimeout(() => {
        try {
          console.log("Refetch", hierarchyRetries.current);
          refetchHierarchy().then();
        } catch (err) {
          console.log(err);
        }
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [projectHierarchyError, refetchHierarchy, hierarchyRetries.current, result]);

  const { loading: loadingRelatedHierarchy, error: relatedProjectHierarchyError } = useQuery<ProjectHierarchyInfoData>(
    GET_PROJECT_HIERARCHY,
    {
      variables: {
        id: relatingProjectId,
        itemType: HierarchyItemEnum.Project,
      },
      fetchPolicy: "cache-and-network",
      skip: relatingProjectId === undefined || result.kind === AssignedHierarchyKind.None,
      onCompleted: data => {
        if (!data) return;
        if (data.headerInfo) {
          setResult({
            kind: AssignedHierarchyKind.SystemAssigned,
            hierarchy: data.headerInfo.hierarchy,
            fromRelatingProject: true,
          });
        }
      },
    }
  );

  const loading =
    loadingProjectHierarchy ||
    loadingRelatedHierarchy ||
    (result.kind === AssignedHierarchyKind.None && hierarchyRetries.current > 0);

  return {
    loading,
    assignment: loading ? noAssignment : result,
    error: projectHierarchyError || relatedProjectHierarchyError,
  };
}

export interface ProjectHierarchyProps {
  onSaveSuccess: () => void;
}

function ProjectHierarchy(
  props: ProjectHierarchyProps &
    RouteComponentProps &
    ReturnType<typeof mapDispatchToProps> &
    ReturnType<typeof mapStateToProps>
): React.ReactElement {
  const {
    selectedParentNodeId,
    onSaveSuccess,
    history,
    setProjectHierarchyParentNodeId,
    addedNodes,
    mainProjectChanges,
    setProjectHierarchyAddedNodes,
    projectId,
    projectDescription,
    resetProjectHierarchy,
    setProjectHierarchyNode,
    addMainProjectChange,
    relatingProjectId,
    setProjectApplicationModifiedDateTime,
    setNodeHierarchyApplicationModifiedDateTime,
    cancelNewProject,
    projectApplicationModifiedDateTime,
  } = props;
  const [mode, setMode] = useState(Modes.ExistingNode);
  const [selectedNode, setSelectedNode] = useState<SearchNode | undefined>(undefined);
  const [visualizedHierarchy, setVisualizedHierarchy] = useState<HierarchyItem | undefined>(undefined);

  const { loading, assignment, error } = useExistingHierarchyQuery(projectId, relatingProjectId);
  useEffect(() => {
    if (assignment.kind === AssignedHierarchyKind.SystemAssigned) {
      setSelectedNode({
        id: assignment.hierarchy.id,
        description: assignment.hierarchy.description,
        mainProjectId: assignment.hierarchy.mainProjectId,
        mainProjectDescription: null,
      });
      setProjectHierarchyParentNodeId(
        assignment.hierarchy.id,
        assignment.hierarchy.mainProjectId !== null ? undefined : projectId
      );
    }
  }, [assignment]);

  const { loading: nodeDataLoading, data: nodeData, error: nodeDataError } = useQuery<GetNodeData>(GET_NODE, {
    variables: {
      nodeId: selectedNode ? selectedNode.id : 0,
    },
    fetchPolicy: "network-only",
    skip: !selectedNode,
  });

  const client = useApolloClient() as ApolloClient<Record<string, unknown>>;
  const [saving, setSaving] = useState<boolean>(false);
  const [saveResultErrors, setSaveResultErrors] = useState<string[] | undefined>(undefined);
  const onNewProjectHierarchySave = async (projectId: number, selectedParentNodeId: number) => {
    setSaveResultErrors(undefined);
    setSaving(true);
    const success = (
      await saveProjectHierarchy(
        client,
        projectId,
        selectedParentNodeId,
        addedNodes,
        mainProjectChanges,
        setSaveResultErrors,
        setNodeHierarchyApplicationModifiedDateTime
      )
    ).successful;
    if (success) {
      if (projectApplicationModifiedDateTime !== undefined) {
        pollForBasicsReady(projectId, projectApplicationModifiedDateTime);
      } else {
        onSaveSuccess();
      }
    } else {
      setSaveResultErrors(saveResultErrors !== undefined ? saveResultErrors : ["Error saving hierarchy changes"]);
    }
    setSaving(false);
  };

  const {
    pollForReady: pollForBasicsReady,
    ready: basicsReady,
    loading: pollingForBasics,
  } = pollForBasicDataSaveReady();

  useEffect(() => {
    if (basicsReady && !pollingForBasics) {
      setProjectApplicationModifiedDateTime(undefined);
      onSaveSuccess();
    }
  }, [basicsReady, pollingForBasics]);

  const cancelCreateProject = () => {
    cancelNewProject();
    history.push(routes.MAIN);
  };

  switch (assignment.kind) {
    case AssignedHierarchyKind.None:
      return (
        <Container>
          <CreateProjectActionsHeader
            hasErrors={true}
            saving={false}
            readyToSave={false}
            onClickContinue={() => undefined}
            onClickCancel={cancelCreateProject}
          />
          <LoadingContainer>
            {error ? "Error loading hierarchy: " + error.message : loading ? "Loading" : "Error loading hierarchy"}
          </LoadingContainer>
        </Container>
      );
    case AssignedHierarchyKind.SystemAssigned: {
      const displayedHierarchy = assignment.hierarchy;
      return (
        <Container>
          <CreateProjectActionsHeader
            hasErrors={false}
            saving={pollingForBasics}
            readyToSave={true}
            onClickContinue={() => {
              if (projectId !== undefined && projectApplicationModifiedDateTime !== undefined) {
                pollForBasicsReady(projectId, projectApplicationModifiedDateTime);
              } else {
                onSaveSuccess();
              }
            }}
            onClickCancel={cancelCreateProject}
          />
          <LoadingContainer>
            <span>
              {assignment.fromRelatingProject
                ? "Project adjustment added under the hierarchy of the relating project"
                : "The project was automatically added to hierarchy"}{" "}
              <b>{assignment.hierarchy.description}</b>
            </span>
          </LoadingContainer>
          {projectId && displayedHierarchy && (
            <HierarchyTree
              projectId={projectId}
              hierarchy={displayedHierarchy}
              selectedParentNodeId={selectedParentNodeId}
              setSelectedParentNodeId={() => undefined}
              addedNodes={addedNodes}
              setAddedNodes={() => undefined}
              projectName={projectDescription}
              resetProjectHierarchy={() => undefined}
              readOnly={true}
              addMainProjectChange={() => undefined}
            />
          )}
        </Container>
      );
    }
    case AssignedHierarchyKind.UserAssigned: {
      const displayedHierarchy =
        mode === Modes.ExistingNode && selectedNode && nodeData ? nodeData.getNode : visualizedHierarchy;
      return (
        <Container>
          <CreateProjectActionsHeader
            hasErrors={false}
            saving={saving || pollingForBasics}
            readyToSave={!!projectId && !!selectedParentNodeId}
            onClickContinue={() =>
              projectId && selectedParentNodeId && onNewProjectHierarchySave(projectId, selectedParentNodeId)
            }
            onClickCancel={cancelCreateProject}
          />
          {saveResultErrors !== undefined && saveResultErrors.length > 0 && (
            <CreateProjectErrorsBox errors={saveResultErrors} />
          )}
          <ModeTabs>
            <Tab
              selected={mode === Modes.ExistingNode}
              onClick={() => {
                resetProjectHierarchy();
                setVisualizedHierarchy(undefined);
                setMode(Modes.ExistingNode);
              }}
            >
              Add into a hierarchy
            </Tab>
            <Tab
              selected={mode === Modes.NewNode}
              onClick={() => {
                resetProjectHierarchy();
                setVisualizedHierarchy(undefined);
                setMode(Modes.NewNode);
              }}
            >
              Create a new hierarchy
            </Tab>
          </ModeTabs>
          {mode === Modes.ExistingNode && (
            <HierarchySearch
              selectedNode={selectedNode}
              onSelection={node => setSelectedNode(node)}
              disabled={relatingProjectId !== undefined}
            />
          )}
          {mode === Modes.NewNode && (
            <NewHierarchyView
              onNodeValueChanged={value => {
                setProjectHierarchyNode(value.length ? value : undefined, projectId);
                setVisualizedHierarchy(
                  value.length
                    ? {
                        id: 1,
                        description: value,
                        active: true,
                        itemType: HierarchyItemEnum.Node,
                        mainProjectId: projectId !== undefined ? projectId : null,
                        childItems: [],
                        projectTechnicalTypeId: null,
                        projectTypeId: null,
                        hiddenInHierarchyView: false,
                        relatingProjectId: null,
                        adjustmentRateInfo: null,
                      }
                    : undefined
                );
              }}
            />
          )}
          {displayedHierarchy && projectId && (
            <HierarchyTree
              projectId={projectId}
              hierarchy={displayedHierarchy}
              selectedParentNodeId={mode === Modes.ExistingNode ? selectedParentNodeId : 1}
              setSelectedParentNodeId={(id, projectId) => setProjectHierarchyParentNodeId(id, projectId)}
              addedNodes={addedNodes}
              setAddedNodes={addedNodes => setProjectHierarchyAddedNodes(addedNodes)}
              projectName={projectDescription}
              resetProjectHierarchy={resetProjectHierarchy}
              readOnly={mode === Modes.NewNode || relatingProjectId !== undefined}
              addMainProjectChange={addMainProjectChange}
            />
          )}
          {nodeDataLoading && <LoadingContainer>Loading...</LoadingContainer>}
          {nodeDataError && <LoadingContainer>Error loading hierarchy: {nodeDataError.message}</LoadingContainer>}
        </Container>
      );
    }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProjectHierarchy));

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 1375px;
  margin-top: 20px;
  padding-bottom: 20px;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
`;

const ModeTabs = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 35px;
  > button:first-child {
    border-right: 0;
  }
`;

const Tab = styled.button<{ selected?: boolean; disabled?: boolean }>`
  display: flex;
  border: 1px solid ${settingGreen};
  width: 190px;
  height: 40px;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: ${settingGreen};
  padding: 0;
  cursor: pointer;
  outline: none;
  background-color:  ${({ selected }) => (selected ? `${filterGreen}` : `white`)}
  font-size: 16px;
  ${({ disabled }) => disabled && "opacity: 0.5;"}
`;
