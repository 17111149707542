import styled from "styled-components";
import React, { useCallback, useEffect, useImperativeHandle, useLayoutEffect, useRef, useState } from "react";
import { cancelRed, filterGreen, valmetGreyLight } from "../../../../common/colors";
import { toNumber } from "lodash";
import { NumberRange } from "../../../../common/types";

export interface FilterNumberRangeProps {
  rangeValue: NumberRange;
  setRangeValue: (value: NumberRange) => void;
  defaultMinOnSlider: number;
  defaultMaxOnSlider: number;
  unitText?: string;
}

type Range = [number, number];

const clamp = (value: number, range: Range): number => Math.max(range[0], Math.min(value, range[1]));

const remap = (x: number, fromRange: Range, toRange: Range): number => {
  const clamped = clamp(x, fromRange);
  const D = fromRange[1] - fromRange[0];
  const toD = toRange[1] - toRange[0];
  return ((clamped - fromRange[0]) / D) * toD + toRange[0];
};

type KnobProps = {
  parent: React.RefObject<HTMLDivElement>;
  setPosition: (pos: number) => void;
  value: number;
  valueRange: { min: number; max: number };
  limits: { min: number; max: number };
  setValue: (value: number) => void;
  commitValue: (value: number) => void;
  isMin: boolean;
};

//function calc(inx: number, parentW: number) {
//  const rangePx: Range = isMin ? [0, parentW - KnobWidthPx] : [KnobWidthPx, parentW];
//
//  const limitsPx = {
//    min: remap(limits.min, [valueRange.min, valueRange.max], rangePx),
//    max: remap(limits.max, [valueRange.min, valueRange.max], rangePx),
//  };
//
//  return clamp(inx, [limitsPx.min, limitsPx.max]);
//}

function Knob(props: KnobProps): React.ReactElement {
  const { parent, setPosition: setPositionX, value, valueRange, limits, setValue, commitValue, isMin } = props;
  const ref: React.RefObject<HTMLButtonElement> = useRef(null);
  const dragging = useRef(false);
  const currentValue = useRef(clamp(value, [valueRange.min, valueRange.max]));
  const parentRect = useRef(new DOMRect(0, 0, 1, 1));

  const KnobWidthPx = 16;
  const HalfKnobWidthPx = KnobWidthPx * 0.5;

  useLayoutEffect(() => {
    if (parent.current) {
      console.log("Doodings!");
      parentRect.current = parent.current.getBoundingClientRect();
    }
  }, [parent.current]);

  const setPosition = useCallback(
    (x: number) => {
      if (ref.current) {
        const visualX = x - HalfKnobWidthPx;
        ref.current.style.left = visualX + "px";
        setPositionX(visualX);
      }
    },
    [ref.current]
  );

  const updateValue = useCallback(
    (newValue: number) => {
      currentValue.current = newValue;

      const parentW = parentRect.current.width;

      const rangePx: Range = isMin ? [0, parentW - KnobWidthPx] : [KnobWidthPx, parentW];

      const limitsPx = {
        min: remap(limits.min, [valueRange.min, valueRange.max], rangePx),
        max: remap(limits.max, [valueRange.min, valueRange.max], rangePx),
      };

      const mappedX = remap(newValue, [valueRange.min, valueRange.max], rangePx);
      const x = clamp(mappedX, [limitsPx.min, limitsPx.max]);

      setPosition(x);
    },
    [parentRect.current, setPosition]
  );

  const updatePositionFromClientX = useCallback(
    (clientX: number) => {
      const parentX = parentRect.current.x;
      const parentW = parentRect.current.width;

      const rangePx: Range = isMin ? [0, parentW - KnobWidthPx] : [KnobWidthPx, parentW];

      const limitsPx = {
        min: remap(limits.min, [valueRange.min, valueRange.max], rangePx),
        max: remap(limits.max, [valueRange.min, valueRange.max], rangePx),
      };

      const x = clamp(clientX - parentX, [limitsPx.min, limitsPx.max]);
      setPosition(x);

      const newValue = remap(x, rangePx, [valueRange.min, valueRange.max]);
      const rounded = Math.round(newValue);
      currentValue.current = rounded;
      setValue(rounded);
    },
    [parentRect.current, setPosition, setValue]
  );

  // When value from props changes, update currentValue and knob position
  useEffect(() => {
    updateValue(value);
  }, [value, updateValue]);

  // Register mouse move and up handlers to update the knob position and commit the new value
  useEffect(() => {
    console.log("Triggering register");
    const mouseMove = (ev: MouseEvent) => {
      if (dragging.current) {
        updatePositionFromClientX(ev.clientX);
      }
    };
    const mouseUp = () => {
      if (dragging.current) {
        dragging.current = false;
        commitValue(currentValue.current);
      }
    };
    document.addEventListener("mousemove", mouseMove);
    document.addEventListener("mouseup", mouseUp);
    return () => {
      document.addEventListener("mousemove", mouseMove);
      document.removeEventListener("mouseup", mouseUp);
    };
  }, [parentRect.current, isMin, setPosition, setValue, commitValue]);

  return (
    <SliderKnob
      width={KnobWidthPx}
      isMin={isMin}
      ref={ref}
      onMouseDown={ev => {
        if (ev.buttons & 1) dragging.current = true;
      }}
    />
  );
}

function FilterNumberRange(props: FilterNumberRangeProps): React.ReactElement {
  const { setRangeValue, defaultMinOnSlider, defaultMaxOnSlider, unitText } = props;
  const { min: minValue, max: maxValue } = props.rangeValue;
  const setMinValue = (newMin: number | undefined) => setRangeValue({ min: newMin, max: maxValue });
  const setMaxValue = (newMax: number | undefined) => setRangeValue({ min: minValue, max: newMax });

  const [minInput, setMinInput] = useState<number | undefined>(minValue);
  const [maxInput, setMaxInput] = useState<number | undefined>(maxValue);
  useEffect(() => setMinInput(minValue), [minValue]);
  useEffect(() => setMaxInput(maxValue), [maxValue]);

  const barRef: React.RefObject<HTMLDivElement> = useRef(null);
  const highlightRef: React.RefObject<HTMLDivElement> = useRef(null);
  const minPos = useRef(0);
  const maxPos = useRef(0);

  return (
    <Container>
      <InputsContainer>
        <NumberInput
          type={"number"}
          value={minInput || ""}
          placeholder={"Minimum value"}
          onBlur={event => {
            if (event.target.validity.valid) setMinValue(event.target.value ? toNumber(event.target.value) : undefined);
          }}
          onChange={event => {
            const newValue = event.target.value.length > 0 ? toNumber(event.target.value) : undefined;
            setMinInput(newValue);
          }}
          max={maxValue}
          min={0}
        />
        <UnitText>{unitText}</UnitText>
        <UnitText>—</UnitText>
        <NumberInput
          type={"number"}
          value={maxInput || ""}
          placeholder={"Maximum value"}
          onBlur={event => {
            if (event.target.validity.valid) setMaxValue(event.target.value ? toNumber(event.target.value) : undefined);
          }}
          onChange={event => {
            const newValue = event.target.value.length > 0 ? toNumber(event.target.value) : undefined;
            setMaxInput(newValue);
          }}
          min={minValue}
        />
        <UnitText>{unitText}</UnitText>
      </InputsContainer>

      <RangeSliderContainer>
        <SliderBar ref={barRef}>
          <SliderHighlight ref={highlightRef} />
          <Knob
            parent={barRef}
            setPosition={pos => {
              minPos.current = pos;
              if (highlightRef.current) {
                highlightRef.current.style.left = pos + "px";
                highlightRef.current.style.width = maxPos.current - minPos.current + "px";
              }
            }}
            value={minValue || 0}
            valueRange={{ min: defaultMinOnSlider, max: defaultMaxOnSlider }}
            limits={{ min: defaultMinOnSlider, max: maxValue || defaultMaxOnSlider }}
            setValue={value => setMinInput(value)}
            commitValue={value => setMinValue(value)}
            isMin={true}
          />
          <Knob
            parent={barRef}
            setPosition={pos => {
              maxPos.current = pos;
              if (highlightRef.current) {
                highlightRef.current.style.width = maxPos.current - minPos.current + "px";
              }
            }}
            value={maxValue || defaultMaxOnSlider}
            valueRange={{ min: defaultMinOnSlider, max: defaultMaxOnSlider }}
            limits={{ min: minValue || defaultMinOnSlider, max: defaultMaxOnSlider }}
            setValue={value => setMaxInput(value)}
            commitValue={value => setMaxValue(value)}
            isMin={false}
          />
        </SliderBar>
      </RangeSliderContainer>
    </Container>
  );
}

export default FilterNumberRange;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 4px;
`;

const InputsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const NumberInput = styled.input`
  width: 80px;
  margin-right: 5px;
  text-align: right;
  :invalid {
    border: 1px solid ${cancelRed};
    color: ${cancelRed};
  }
`;

const UnitText = styled.div`
  line-height: 24px;
  margin-right: 10px;
`;

const RangeSliderContainer = styled.div`
  position: relative;
  //margin-left: -90px;
`;

const SliderBar = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 90%;
  min-width: 80px;
  height: 4px;
  margin-top: 10px;
  background-color: ${valmetGreyLight};
`;

const SliderHighlight = styled.div`
  position: absolute;
  background: ${filterGreen};
  height: 5px;
`;

const SliderKnob = styled.button<{ width: number; isMin: boolean }>`
  position: absolute;
  width: ${({ width }) => width}px;
  height: 16px;
  background-color: white;
  border: 1px solid ${valmetGreyLight};
  border-radius: ${({ isMin }) => (isMin ? "16px 0px 0px 16px" : "0px 16px 16px 0px")};
  margin-top: 2px;
  cursor: pointer;
  z-index: 200;
`;
