import * as React from "react";
import onClickOutside, { HandleClickOutside, InjectedOnClickOutProps } from "react-onclickoutside";
import styled from "styled-components";
import { faChevronDown, faTimes, faEdit, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { valmetGreyLight, valmetGreyBorder, filterGreen, defaultGrey } from "../../../../../common/colors";
import { ViewListItem } from "../../../../../common/types";
import { filter } from "lodash";
import { CollapseButton, IconButton, ValueButton } from "../../../../../common/components";
import { ColumnSet } from "../../../../../common/columnsTypes";

type Props = {
  customColumnSets: ViewListItem[];
  defaultColumnSets: ColumnSet[];
  selection: ColumnSet;
  onColumnSetSelected: (column: ColumnSet) => void;
  onCustomColumnSetSelected: (view: ViewListItem) => void;
  onDelete: (id: string) => void;
  onEdit: (view: ViewListItem) => void;
};

type State = {
  isOpen: boolean;
};

class Dropdown extends React.Component<
  Props & InjectedOnClickOutProps & HandleClickOutside<React.MouseEventHandler>,
  State
> {
  state: State = { isOpen: false };

  toggle = (): void => {
    if (this.state.isOpen) {
      this.props.disableOnClickOutside();
    } else {
      this.props.enableOnClickOutside();
    }

    this.setState(state => ({ isOpen: !state.isOpen }));
  };

  handleClickOutside = (): void => {
    this.props.disableOnClickOutside();
    this.setState({ isOpen: false });
  };

  render(): React.ReactElement {
    const {
      customColumnSets,
      defaultColumnSets,
      onColumnSetSelected,
      onCustomColumnSetSelected,
      onEdit,
      selection,
    } = this.props;

    const globalViews = filter(customColumnSets, set => set.isGlobal);
    const nonGlobalViews = filter(customColumnSets, set => !set.isGlobal);

    return (
      <Wrapper onClick={this.toggle}>
        <InfoText>
          {selection
            ? selection.description
            : defaultColumnSets.length + customColumnSets.length > 0
            ? "Choose"
            : "No available views"}
        </InfoText>
        <CollapseButton title={"Select filter"} fontSize={"14px"}>
          <FontAwesomeIcon icon={this.state.isOpen ? faChevronUp : faChevronDown} size="lg" color={valmetGreyLight} />
        </CollapseButton>
        {this.state.isOpen && (
          <DropdownContent hasContent={customColumnSets.length > 0}>
            {defaultColumnSets.map(value => (
              <ValueButton key={value.id} onClick={() => onColumnSetSelected(value)}>
                {value.description}
              </ValueButton>
            ))}
            {globalViews.length > 0 && (
              <CustomSets>
                {globalViews.map(value => (
                  <RowContainer key={value.id}>
                    <ValueButton onClick={() => onCustomColumnSetSelected(value)}>{value.name}</ValueButton>
                    <IconButton onClick={() => onEdit(value)} title="Edit view">
                      <FontAwesomeIcon icon={faEdit} size="1x" color={defaultGrey} />
                    </IconButton>
                    <IconButton onClick={() => this.props.onDelete(value.id)} title="Delete view">
                      <FontAwesomeIcon icon={faTimes} size="1x" color={"red"} />
                    </IconButton>
                  </RowContainer>
                ))}
              </CustomSets>
            )}
            {nonGlobalViews.length > 0 && (
              <CustomSets>
                <hr style={{ width: "90%" }} />
                {nonGlobalViews.map(value => (
                  <RowContainer key={value.id}>
                    <ValueButton onClick={() => onCustomColumnSetSelected(value)}>{value.name}</ValueButton>
                    <IconButton onClick={() => onEdit(value)} title="Edit view">
                      <FontAwesomeIcon icon={faEdit} size="1x" color={defaultGrey} />
                    </IconButton>
                    <IconButton onClick={() => this.props.onDelete(value.id)} title="Delete view">
                      <FontAwesomeIcon icon={faTimes} size="1x" color={"red"} />
                    </IconButton>
                  </RowContainer>
                ))}
              </CustomSets>
            )}
          </DropdownContent>
        )}
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 4px;

  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const DropdownContent = styled.div<{ hasContent: boolean }>`
  background: white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  position: absolute;
  display: flex;
  flex-direction: column;
  min-width: 200px;
  ${({ hasContent }) => {
    if (hasContent) {
      return `border: 1px solid ${valmetGreyBorder}`;
    }
  }};
  z-index: 500;
  button:hover {
    background: ${filterGreen};
  }
  margin-top: 26px;
`;

const RowContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  :hover {
    background: ${filterGreen};
  }
`;

const CustomSets = styled.div`
  span {
    margin-left: 6px;
  }
`;

const InfoText = styled.div`
  display: flex;
  font-style: italic;
  align-items: flex-end;
  margin-right: 22px;
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default onClickOutside<any, Props>(Dropdown);
