import React from "react";
import styled from "styled-components";
import { includes } from "lodash";
import onClickOutside, { HandleClickOutside, InjectedOnClickOutProps } from "react-onclickoutside";
import { faCheck, faChevronDown, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  filterGreen,
  settingGreen,
  valmetGreyBorder,
  valmetGreyLight,
  warningYellow,
} from "../../../../../../common/colors";
import { DropdownItem } from "./EditableSearchableDropdown";
import { IconButton, InputIconButton, TextButton, ValueButton } from "../../../../../../common/components";

type Props = {
  values: DropdownItem[];
  selections: string | string[];
  onValueSelected: (value: string | null) => void;
  disableIcon?: boolean;
  singleSelection?: boolean;
  textValue: string;
  setTextInput: (input: string) => void;
  searchable?: boolean;
  selectedOptionDescription: string;
  disabled?: boolean;
  error?: boolean;
  maxResults?: number;
  inputWidth?: "small" | "smallest" | "full" | number;
  nonNullable?: boolean;
  disabledValueIds?: string[];
  scrollingDisabled?: boolean;
  onLoadMore?: () => void;
  morePagesAvailable?: boolean;
  dataError?: boolean;
  loading?: boolean;
};

type State = {
  isOpen: boolean;
};

class Dropdown extends React.Component<
  Props & InjectedOnClickOutProps & HandleClickOutside<React.MouseEventHandler>,
  State
> {
  state: State = { isOpen: false };
  inputRef: React.RefObject<HTMLInputElement> = React.createRef<HTMLInputElement>();

  toggle = (setTextInput: (input: string) => void, searchable: undefined | boolean): void => {
    if (this.state.isOpen) {
      this.props.disableOnClickOutside();
      setTextInput(this.props.selectedOptionDescription);
    } else {
      if (searchable) {
        setTextInput("");
      }
      this.props.enableOnClickOutside();
    }

    this.setState(state => ({ isOpen: !state.isOpen }));
  };

  handleClickOutside = (): void => {
    if (this.state.isOpen) {
      this.props.disableOnClickOutside();
      this.setState({ isOpen: false });
    }
  };

  open = (): void => {
    this.props.enableOnClickOutside();

    this.setState({ isOpen: true });
  };

  render(): React.ReactElement {
    const {
      values,
      onValueSelected,
      selections,
      disableIcon,
      singleSelection,
      textValue,
      setTextInput,
      searchable,
      selectedOptionDescription,
      disabled,
      error,
      maxResults,
      inputWidth,
      nonNullable,
      disabledValueIds,
      scrollingDisabled,
      onLoadMore,
      morePagesAvailable,
      dataError,
      loading,
    } = this.props;

    const displayedValues =
      scrollingDisabled && maxResults !== undefined && maxResults < values.length
        ? values.slice(0, maxResults)
        : values;

    const width = (() => {
      if (typeof inputWidth === "number") {
        //A bit of an hax to get Chrome work with width
        return `${inputWidth}px`;
      } else {
        switch (inputWidth) {
          case "small":
            return "100px";
          case "smallest":
            return "75px";
          case "full":
            return "100%";
          default:
            return "100%";
        }
      }
    })();

    return (
      <Wrapper>
        <TextInput
          ref={this.inputRef}
          type="text"
          value={dataError ? "Error loading data" : textValue}
          readOnly={!searchable}
          onChange={event => setTextInput(event.target.value)}
          onFocus={() => {
            if (searchable && !onLoadMore) {
              this.inputRef.current && this.inputRef.current.select();
            }
            this.open();
          }}
          onBlur={() => {
            if (!searchable) {
              setTextInput(selectedOptionDescription);
            }
          }}
          disabled={disabled || dataError}
          error={error}
          placeholder={searchable ? "Search..." : undefined}
          searchable={searchable}
          title={selectedOptionDescription}
          inputWidth={width}
        />
        {!disabled && !nonNullable && (
          <InputIconButton
            onClick={() => {
              onValueSelected(null);
              this.inputRef.current && this.inputRef.current.focus();
            }}
            disabled={disabled}
          >
            <FontAwesomeIcon icon={faTimes} size="1x" color={valmetGreyLight} />
          </InputIconButton>
        )}
        {!disableIcon && (
          <IconButton
            onClick={() => this.toggle(setTextInput, searchable)}
            title={"Select filter"}
            onBlur={() => {
              if (!searchable) {
                setTextInput(selectedOptionDescription);
              }
            }}
            disabled={disabled}
            color={valmetGreyLight}
          >
            <FontAwesomeIcon icon={faChevronDown} size="lg" />
          </IconButton>
        )}
        {this.state.isOpen && (
          <DropdownContent
            onClick={() => {
              if (singleSelection) this.handleClickOutside();
            }}
          >
            <ValueContainer
              minWidth={width}
              maxVisibleItems={maxResults ? maxResults + (scrollingDisabled ? 1 : 0) : maxResults}
            >
              {displayedValues.length > 0 ? (
                displayedValues.map(value => (
                  <ValueButtonMinWidth
                    key={value.id}
                    disabled={disabledValueIds && disabledValueIds.includes(value.id.toString())}
                    onClick={() => {
                      const v = value.id.toString();
                      if (nonNullable) {
                        if (v !== selections.toString()) onValueSelected(v);
                      } else {
                        onValueSelected(v !== selections.toString() ? v : null);
                      }
                    }}
                  >
                    {value.description}
                    <SelectionMarker>
                      {((Array.isArray(selections) && includes(selections, value.id)) ||
                        value.id.toString() === selections.toString()) && <FontAwesomeIcon icon={faCheck} size="sm" />}
                    </SelectionMarker>
                  </ValueButtonMinWidth>
                ))
              ) : loading ? (
                <TextButton>Loading...</TextButton>
              ) : textValue.length > 0 ? (
                <TextButton>No results</TextButton>
              ) : (
                <TextButton>Type to search</TextButton>
              )}
              {scrollingDisabled && maxResults && maxResults < values.length && (
                <ResultsTextContainer>Search for more results.</ResultsTextContainer>
              )}
              {morePagesAvailable && (
                <ValueButton
                  special={true}
                  onClick={event => {
                    event.stopPropagation();
                    if (onLoadMore) onLoadMore();
                  }}
                >
                  Load more results...
                </ValueButton>
              )}
            </ValueContainer>
          </DropdownContent>
        )}
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  text-align: center;
  height: 24px;
  width: 100%;
`;

const DropdownContent = styled.div<{ extraMargin?: boolean }>`
  background: white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  position: absolute;
  display: flex;
  flex-direction: column;

  border: 1px solid ${valmetGreyBorder};
  z-index: 500;
  button:hover {
    background: ${filterGreen};
  }
  margin-top: 24px;
`;

const ValueContainer = styled.div<{ minWidth?: string; maxVisibleItems?: number }>`
  overflow-y: auto;
  ${({ minWidth }) => minWidth && `min-width: ${minWidth};`}
  ${({ maxVisibleItems }) => maxVisibleItems && `max-height: ${maxVisibleItems * 29}px;`}
`;

// VPOP-1452 - Need to cast to any, as there is problem with certain TypeScript and Styled components versions.
// Could fix by updating to Styled components 5.
const ValueButtonMinWidth = styled(ValueButton as any)`
  display: flex;
  justify-content: space-between;
  min-width: 100px;
`;

const SelectionMarker = styled.div`
  color: ${settingGreen};
  margin-left: 6px;
`;

const TextInput = styled.input<{
  error?: boolean;
  searchable?: boolean;
  inputWidth?: string;
}>`
  ${({ inputWidth }) => inputWidth && `width: ${inputWidth};`}
  font-weight: bold;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${({ error }) => error && `border: 2px solid red; background: ${warningYellow}`};
  ${({ searchable }) => (searchable ? "cursor: text;" : "cursor: context-menu;")};
  padding-right: 12px;
`;

const ResultsTextContainer = styled.div`
  font-size: 10px;
  padding: 6px;
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default onClickOutside<any, Props>(Dropdown);
