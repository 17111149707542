import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { filter, find } from "lodash";
import { faChevronDown, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import {
  backgroundGrey,
  recOblHighlightGreen,
  selectedRowColor,
  selectedRowHighlight,
  selectedRowHighlightOpaque,
  settingGreen,
  valmetGreyLight,
  valmetGreyREC,
} from "../../../../common/colors";
import { Comment, CommentType } from "../../../../common/types";
import {
  Column,
  ColumnId,
  EditableColumn,
  EditableColumnId,
  Estimate,
  GeneratedColumn,
  RecognitionEdit,
  SalesCostsEdit,
} from "../../../../common/columnsTypes";
import {
  ByPeriodRecognition,
  EditableRecognitionCell,
  EditableRecognitionRow,
  EditingPredicateParams,
  FreezingDisabledResult,
  RateCurrencyPair,
  RecognitionEditOptions,
  RecognitionEditType,
  RecognitionsEditStateChange,
} from "./types";
import TableDataCell from "./../../../TableDataCell";
import { AdditionalData, TableHeaderCell } from "../../../TableDataCell/TableDataCell";
import {
  makeEditingPredicateParams,
  RECOGNITION_EDIT_COLUMNS,
  RecPlanEstRecEditModes,
} from "./recognitionsEditingSetting";
import {
  currentPeriod,
  Period,
  periodEquals,
  periodGreaterThan,
  periodToString,
  reformatPeriodString,
} from "../../../../common/period";
import { RecStickyHeaderMinimizedHeight } from "../constants";
import { useMount } from "../../../../hooks/useMount";

interface ShowProjectRecognitions {
  value: ByPeriodRecognition[];
  editType: RecognitionEditType;
}

interface EditProjectRecognitions {
  value: EditableRecognitionRow[];
  options: RecognitionEditOptions;
}

export interface ProjectRecognitionsTableProps {
  commentTypes: CommentType[];
  recognitions: ShowProjectRecognitions;
  oblPeriod: Period | null;
  completePeriod: Period | null;
  selectedColumns: (Column | GeneratedColumn)[];
  projectRecAsFlat: boolean;
  onAddComment?: (
    period: Period,
    column: string,
    field: string,
    type: string,
    value: number,
    content: string
  ) => Promise<Comment>;
  onDeleteComment?: (
    period: Period,
    column: string,
    field: string,
    type: string,
    value: number,
    content: string
  ) => Promise<void>;
  currencyPairs: RateCurrencyPair[] | undefined;
}

export interface ProjectRecognitionsTableEditProps {
  commentTypes: CommentType[];
  recognitions: EditProjectRecognitions;
  selectedColumns: EditableColumn[];
  changes: Map<string, RecognitionsEditStateChange>;
  projectRecAsFlat: boolean;
  selectedEstRecEditMode: RecPlanEstRecEditModes;
  onValueChange?: (period: Period, columnId: EditableColumnId, field: string, value: number) => void;
  onCopyCurrent?: (period: Period, columnId: EditableColumnId, field: string, value: number) => void;
  onFieldFocus?: (period: Period, columnId: EditableColumnId, field: string) => void;
  onFieldLeave?: (period: Period, columnId: EditableColumnId, field: string) => void;
  onAddFreezing?: (period: Period, columnId: EditableColumnId, field: string, value: number, comment: string) => void;
  onRemoveFreezing?: (period: Period, columnId: EditableColumnId, field: string) => void;
  onAddComment?: (
    period: Period,
    column: string,
    field: string,
    type: string,
    value: number,
    content: string
  ) => Promise<void>;
  onHasError?: (id: string, hasError: boolean) => void;
  currencyPairs: RateCurrencyPair[] | undefined;
}

const getIcon = (index: number, item: ByPeriodRecognition, openRecognitionItems: string[]) => {
  if (index === 0 && item.subGroups) {
    return openRecognitionItems.includes(item.period) ? faChevronDown : faChevronRight;
  }
};

const highlightEstField = (
  estColumnId: ColumnId | EditableColumnId,
  nsOverrun: boolean,
  costOverrun: boolean,
  warCostOverrun: boolean
) => {
  return (columnId: ColumnId | EditableColumnId, fieldId: string) => {
    if (columnId === estColumnId) {
      if (
        (fieldId === "ns" && nsOverrun) ||
        (fieldId === "cost" && costOverrun) ||
        (fieldId === "war" && warCostOverrun)
      )
        return { color: "#222", background: "#ffd8de" };
    }
    return undefined;
  };
};

const estFieldTooltip = (
  estColumnId: ColumnId | EditableColumnId,
  nsOverrun: boolean,
  costOverrun: boolean,
  warCostOverrun: boolean
) => {
  return (columnId: ColumnId | EditableColumnId, fieldId: string) => {
    if (columnId === estColumnId) {
      if (fieldId === "ns" && nsOverrun) return "Estimated net sales overrun by actual recognised net sales";
      if (fieldId === "cost" && costOverrun) return "Estimated costs overrun by actual recognised costs";
      if (fieldId === "war" && warCostOverrun)
        return "Estimate warranty costs overrun by actual recognised warranty costs";
    }
    return undefined;
  };
};

const additionalDataForColumn = (
  columnId: ColumnId | EditableColumnId,
  editType: RecognitionEditType,
  currencyPairs: RateCurrencyPair[] | undefined
): AdditionalData | undefined => {
  if (columnId === "rates" || columnId === "rates_edit") {
    switch (editType) {
      case RecognitionEditType.AutomaticAdjustmentOnNS:
      case RecognitionEditType.NonEditableAutomaticAdjustmentOnNS:
        return {
          erRate: {
            kind: "rate_data",
            currencyPairs,
          },
        };
      case RecognitionEditType.AutomaticAdjustmentOnIntNS:
      case RecognitionEditType.NonEditableAutomaticAdjustmentOnIntNS:
        return {
          irRate: {
            kind: "rate_data",
            currencyPairs,
          },
        };
      default:
        return undefined;
    }
  }
  return undefined;
};

function findCell(item: ByPeriodRecognition, columnId: ColumnId | EditableColumnId) {
  return item.columns.find(cell => cell.columnId === columnId);
}

const renderDataRow = (
  item: ByPeriodRecognition,
  editType: RecognitionEditType,
  currencyPairs: RateCurrencyPair[] | undefined,
  openRecognitionItems: string[],
  setOpenRecognitionItems: React.Dispatch<React.SetStateAction<string[]>>,
  columns: (Column | GeneratedColumn)[],
  last: boolean,
  projectRecAsFlat: boolean,
  oblPeriod: Period | null,
  completePeriod: Period | null,
  level: number,
  commentTypes: CommentType[],
  selectedPeriod: string | null,
  onSelectedPeriod: (period: string | null) => void,
  onAddComment?: (
    period: Period,
    column: string,
    field: string,
    type: string,
    value: number,
    content: string
  ) => Promise<Comment>,
  onDeleteComment?: (
    period: Period,
    column: string,
    field: string,
    type: string,
    value: number,
    content: string
  ) => Promise<void>
): JSX.Element[] => {
  // Compute expected labels for current period
  // These are later used to highlight it based on the label of the row
  const now = new Date();
  const currentYear = "" + now.getFullYear();
  const currentQuarter = "Q" + Math.ceil((now.getMonth() + 1) / 3) + "/" + currentYear;
  const currentMonth = currentYear + "-" + (now.getMonth() + 1);

  const deconstructPeriod = (period: string): [number, number, number] => {
    if (period.length === 4) {
      const year = parseInt(period);
      const quarter = 1;
      const month = 1;
      return [year, quarter, month];
    } else if (period.includes("Q")) {
      const [qpart, ypart] = period.split("/");
      const year = parseInt(ypart);
      const quarter = parseInt(qpart.substr(1));
      const month = (quarter - 1) * 3 + 1;
      return [year, quarter, month];
    } else {
      const [ypart, mpart] = period.split("-");
      const year = parseInt(ypart);
      const month = parseInt(mpart);
      const quarter = Math.ceil(month / 3);
      return [year, quarter, month];
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [year, quarter, month] = deconstructPeriod(item.period);
  const itemPeriod: Period = { year, month };

  const isHighlighted = item.period === currentYear || item.period === currentQuarter || item.period === currentMonth;
  const isFuturePeriod = year > now.getFullYear() || (year === now.getFullYear() && month > now.getMonth() + 1);

  const isMonthPeriod = item.subGroups === undefined;
  const isOpen = openRecognitionItems.includes(item.period);
  const period = !isMonthPeriod ? item.period : reformatPeriodString(item.period);

  const isOblPeriod = oblPeriod !== null && isMonthPeriod && periodEquals(oblPeriod, itemPeriod);
  const isCompletePeriod = completePeriod !== null && isMonthPeriod && periodEquals(completePeriod, itemPeriod);
  const bg = isOblPeriod || isCompletePeriod ? recOblHighlightGreen : undefined;
  const isSelected = selectedPeriod !== null && selectedPeriod === item.period;

  const estColumn = item.columns.find(c => c.columnId === "est");
  const estValue = estColumn && (estColumn.value as Estimate);
  const nsOverrun = estValue !== undefined && estValue.nsOverrun;
  const costOverrun = estValue !== undefined && estValue.costOverrun;
  const warCostOverrun = estValue !== undefined && estValue.warCostOverrun;
  const highlightField = (columnId: ColumnId) => {
    if (columnId === "est") {
      return highlightEstField("est", nsOverrun, costOverrun, warCostOverrun);
    }
    return (columnId: ColumnId | EditableColumnId, fieldId: string) => {
      const cell = findCell(item, columnId);
      const additionalInfo = cell && cell.additionalInfo;
      if (additionalInfo && additionalInfo.message) {
        const m = additionalInfo.message.find(m => m.fieldId === fieldId);
        return m && { color: "#222", background: "#ffd8de" };
      }
      return undefined;
    };
  };
  const fieldTooltip = (columnId: ColumnId) => {
    if (columnId === "est") {
      return estFieldTooltip("est", nsOverrun, costOverrun, warCostOverrun);
    }
    return (columnId: ColumnId | EditableColumnId, fieldId: string): string | undefined => {
      const cell = findCell(item, columnId);
      const additionalInfo = cell && cell.additionalInfo;
      if (additionalInfo && additionalInfo.message) {
        const m = additionalInfo.message.find(m => m.fieldId === fieldId);
        return m && m.message;
      }
      return undefined;
    };
  };

  const rowComponent = () =>
    !projectRecAsFlat || isMonthPeriod
      ? [
          <DataRow
            key={item.period}
            childLevel={projectRecAsFlat ? 0 : level}
            extraPadding={!item.subGroups}
            last={last}
            highlight={isHighlighted}
            bg={bg}
            isSelected={isSelected}
            onClick={e => {
              e.stopPropagation();
              if (!isSelected) {
                onSelectedPeriod(item.period);
              } else {
                onSelectedPeriod(null);
              }
            }}
          >
            <TableHeaderCell
              columnId="period"
              value={period}
              borders={true}
              onIconClick={() =>
                isOpen
                  ? setOpenRecognitionItems(openRecognitionItems.filter(v => v !== item.period))
                  : setOpenRecognitionItems([...openRecognitionItems, item.period])
              }
              icon={getIcon(0, item, openRecognitionItems)}
              hilight={isHighlighted}
              tags={[isOblPeriod ? "OBL" : null, isCompletePeriod ? "100%" : null]}
              tagTitles={[
                isOblPeriod ? "Order back log period" : null,
                isCompletePeriod ? "Rec completion period" : null,
              ]}
            />
            {columns.map((headerColumn, i) => {
              const data = find(item.columns, column => column.columnId === headerColumn.id);
              return data ? (
                <TableDataCell
                  borders={true}
                  key={data.columnId}
                  onIconClick={() =>
                    isOpen
                      ? setOpenRecognitionItems(openRecognitionItems.filter(v => v !== item.period))
                      : setOpenRecognitionItems([...openRecognitionItems, item.period])
                  }
                  dataEntity={data}
                  additionalData={additionalDataForColumn(data.columnId, editType, currencyPairs)}
                  editType={editType}
                  hilight={isHighlighted}
                  highlightField={highlightField(data.columnId)}
                  fieldTooltip={fieldTooltip(data.columnId)}
                  isFuturePeriod={isFuturePeriod}
                  onAddComment={
                    isMonthPeriod && onAddComment
                      ? (field, type, value, content) =>
                          onAddComment(itemPeriod, data.columnId, field, type, value, content)
                      : undefined
                  }
                  onDeleteComment={
                    isMonthPeriod && onDeleteComment
                      ? (field, type, value, content) =>
                          onDeleteComment(itemPeriod, data.columnId, field, type, value, content)
                      : undefined
                  }
                  commentTypes={commentTypes}
                  isSelectedRow={isSelected}
                />
              ) : (
                <EmptyCell key={i}>No data</EmptyCell>
              );
            })}
          </DataRow>,
        ]
      : [];

  const subRows =
    (projectRecAsFlat || isOpen) && !isMonthPeriod && item.subGroups !== undefined
      ? item.subGroups.flatMap(subItem =>
          renderDataRow(
            subItem,
            editType,
            currencyPairs,
            openRecognitionItems,
            setOpenRecognitionItems,
            columns,
            last,
            projectRecAsFlat,
            oblPeriod,
            completePeriod,
            level + 1,
            commentTypes,
            selectedPeriod,
            onSelectedPeriod,
            onAddComment,
            onDeleteComment
          )
        )
      : [];

  return [...rowComponent(), ...subRows];
};

interface EditRowProps {
  item: EditableRecognitionRow;
  last: boolean;
  options: RecognitionEditOptions;
  currencyPairs: RateCurrencyPair[] | undefined;
  selectedPeriod: Period | null;
  onSelectedPeriod: (period: Period | null) => void;
  onValueChange?: (period: Period, columnId: EditableColumnId, field: string, value: number) => void;
  onCopyCurrent?: (period: Period, columnId: EditableColumnId, field: string, value: number) => void;
  onFieldFocus?: (period: Period, columnId: EditableColumnId, field: string) => void;
  onFieldLeave?: (period: Period, columnId: EditableColumnId, field: string) => void;
  onAddFreezing?: (period: Period, columnId: EditableColumnId, field: string, value: number, comment: string) => void;
  onRemoveFreezing?: (period: Period, columnId: EditableColumnId, field: string) => void;
  onHasError?: (id: string, hasError: boolean) => void;
  changes: Map<string, RecognitionsEditStateChange>;
  commentTypes: CommentType[];
  selectedEstRecEditMode: RecPlanEstRecEditModes;
  rowNumber: number;
}

// eslint-disable-next-line react/display-name
const EditRow = React.memo(
  (props: EditRowProps): React.ReactElement => {
    const {
      item,
      last,
      options,
      options: { editProjectType, oblPeriod, recCompletionPeriod },
      currencyPairs,
      selectedPeriod,
      onSelectedPeriod,
      onValueChange,
      onCopyCurrent,
      onFieldFocus,
      onFieldLeave,
      onHasError,
      onAddFreezing,
      onRemoveFreezing,
      changes,
      commentTypes,
      selectedEstRecEditMode,
      rowNumber,
    } = props;
    const columns = RECOGNITION_EDIT_COLUMNS.allColumns;

    const changesForPeriod: RecognitionsEditStateChange[] = filter(Array.from(changes.values()), change =>
      periodEquals(change.period, item.period)
    );

    const thisPeriod = currentPeriod();
    const periodString = periodToString(item.period);
    const hilight = periodEquals(thisPeriod, item.period);
    const isOblPeriod = oblPeriod && periodEquals(oblPeriod, item.period);
    const isRecCompletePeriod = recCompletionPeriod && periodEquals(recCompletionPeriod, item.period);
    const isFuturePeriod = periodGreaterThan(item.period, thisPeriod);
    const estRecEdit = item.findCell("est_rec_edit");
    const recognisedFreezingStatus = estRecEdit ? `${estRecEdit.freezings.length}` : "";

    const isSelected = selectedPeriod !== null && periodEquals(item.period, selectedPeriod);
    const bg = isOblPeriod || isRecCompletePeriod ? recOblHighlightGreen : undefined;

    const makeParams = (cell: EditableRecognitionCell): EditingPredicateParams =>
      makeEditingPredicateParams(thisPeriod, item, cell, options, selectedEstRecEditMode);

    const recEditCell = item.findCell("rec_edit");
    const estEditCell = item.findCell("est_edit");

    const nsOverrun = !!recEditCell && !!estEditCell && estEditCell.value.ns < recEditCell.value.ns;
    const costOverrun = !!recEditCell && !!estEditCell && estEditCell.value.cost < recEditCell.value.cogs;
    const warCostOverrun = !!recEditCell && !!estEditCell && estEditCell.value.war < recEditCell.value.war;
    const highlightField = (columnId: EditableColumnId) => {
      if (columnId === "est_edit") return highlightEstField("est_edit", nsOverrun, costOverrun, warCostOverrun);
      return (columnId: ColumnId | EditableColumnId, fieldId: string) => {
        const cell = item.findCell(columnId as EditableColumnId); // HACK cast
        const additionalInfo = cell && cell.additionalInfo;
        if (additionalInfo && additionalInfo.message) {
          const m = additionalInfo.message.find(m => m.fieldId === fieldId);
          return m && { color: "#222", background: "#ffd8de" };
        }
        return undefined;
      };
    };
    const fieldTooltip = (columnId: EditableColumnId) => {
      if (columnId === "est_edit") {
        return estFieldTooltip("est_edit", nsOverrun, costOverrun, warCostOverrun);
      }
      return (columnId: ColumnId | EditableColumnId, fieldId: string): string | undefined => {
        const cell = item.findCell(columnId as EditableColumnId); // HACK cast
        const additionalInfo = cell && cell.additionalInfo;
        if (additionalInfo && additionalInfo.message) {
          console.log("Message:", additionalInfo.message);
          const m = additionalInfo.message.find(m => m.fieldId === fieldId);
          return m && m.message;
        }
        return undefined;
      };
    };

    let tabIndex = rowNumber * columns.length;
    return (
      <DataRow
        key={periodString}
        childLevel={0}
        extraPadding={false}
        last={last}
        bg={bg}
        isSelected={isSelected}
        onClick={e => {
          e.stopPropagation();
          if (!isSelected) {
            onSelectedPeriod(item.period);
          } else {
            onSelectedPeriod(null);
          }
        }}
      >
        <TableHeaderCell
          columnId="period"
          value={periodString}
          borders={true}
          hilight={hilight}
          tags={[isOblPeriod ? "OBL" : null, isRecCompletePeriod ? "100%" : null]}
          tagTitles={[
            isOblPeriod ? "Order back log period" : null,
            isRecCompletePeriod ? "Rec completion period" : null,
          ]}
        />
        {columns.map((headerColumn, i) => {
          const data = item.findCell(headerColumn.id);
          if (data) {
            tabIndex = tabIndex + 1;
            const changesForColumnId = changesForPeriod.filter(ch => ch.columnId === headerColumn.id);
            const params: EditingPredicateParams = makeParams(data);
            const details = RECOGNITION_EDIT_COLUMNS.fieldDetails[headerColumn.id];
            const options = details.options(params);
            const cellDisabled: boolean | string[] = options.disabled;
            const cellFreezingDisabled: FreezingDisabledResult = options.freezingDisabled;
            return (
              <TableDataCell
                borders={true}
                key={
                  data.columnId === "est_rec_percent_edit"
                    ? `${data.columnId}${recognisedFreezingStatus}`
                    : data.columnId
                }
                dataEntity={data}
                additionalData={additionalDataForColumn(data.columnId, editProjectType, currencyPairs)}
                editType={editProjectType}
                hilight={hilight}
                isFuturePeriod={isFuturePeriod}
                onValueChange={
                  onValueChange ? (field, value) => onValueChange(item.period, data.columnId, field, value) : undefined
                }
                onCopyCurrent={
                  onCopyCurrent ? (field, value) => onCopyCurrent(item.period, data.columnId, field, value) : undefined
                }
                onFocus={onFieldFocus ? field => onFieldFocus(item.period, data.columnId, field) : undefined}
                onBlur={onFieldLeave ? field => onFieldLeave(item.period, data.columnId, field) : undefined}
                onAddFreezing={
                  onAddFreezing
                    ? (field, value, comment) => onAddFreezing(item.period, data.columnId, field, value, comment)
                    : undefined
                }
                onRemoveFreezing={
                  onRemoveFreezing ? field => onRemoveFreezing(item.period, data.columnId, field) : undefined
                }
                freezingDisabled={cellFreezingDisabled}
                disabled={cellDisabled}
                highlightField={highlightField(headerColumn.id)}
                fieldTooltip={fieldTooltip(headerColumn.id)}
                onHasError={onHasError}
                changes={changesForColumnId}
                commentTypes={commentTypes}
                isSelectedRow={isSelected}
                tabIndex={tabIndex}
              />
            );
          } else {
            return <EmptyCell key={i}>No data</EmptyCell>;
          }
        })}
      </DataRow>
    );
  },
  (prev, next) => {
    const periodChanged = prev.selectedPeriod !== next.selectedPeriod;
    const prevIsSelected = prev.selectedPeriod !== null && periodEquals(prev.item.period, prev.selectedPeriod);
    const nextIsSelected = next.selectedPeriod !== null && periodEquals(next.item.period, next.selectedPeriod);
    const isSelectedChanged = prevIsSelected !== nextIsSelected;
    const estRecEditModeChanged = prev.selectedEstRecEditMode !== next.selectedEstRecEditMode;
    return prev.item === next.item && !(periodChanged && isSelectedChanged) && !estRecEditModeChanged;
  }
);

export function ProjectRecognitionsEditTable(props: ProjectRecognitionsTableEditProps): React.ReactElement {
  const {
    selectedColumns,
    recognitions: byPeriodRecognitions,
    changes,
    projectRecAsFlat,
    onValueChange,
    onCopyCurrent,
    onFieldLeave,
    onHasError,
    onAddFreezing,
    onRemoveFreezing,
    commentTypes,
    selectedEstRecEditMode,
    currencyPairs,
  } = props;
  const [renderedItems, setRenderedItems] = useState<EditProjectRecognitions>(byPeriodRecognitions);
  const [selectedPeriod, setSelectedPeriod] = useState<Period | null>(null);

  const tableRef = useRef<HTMLTableSectionElement | null>(null);
  const [listening, setListening] = useState(false);

  function handleOutsideClicks(ev: DocumentEventMap[keyof DocumentEventMap]) {
    if (tableRef.current === null) {
      stopListener();
    } else if (tableRef.current.contains(ev.target as HTMLDivElement)) {
      return;
    } else setSelectedPeriod(null);
  }

  function startListener() {
    if (listening) return;
    if (!tableRef.current) return;
    setListening(true);
    document.addEventListener(`click`, handleOutsideClicks, true);
  }

  function stopListener() {
    document.removeEventListener(`click`, handleOutsideClicks, true);
  }

  useMount(startListener, stopListener);

  useEffect(() => {
    if (byPeriodRecognitions) {
      setRenderedItems(byPeriodRecognitions);
    }
  }, [byPeriodRecognitions, projectRecAsFlat]);

  const rows = renderedItems.value.map((item, rowIndex) => (
    <EditRow
      key={periodToString(item.period)}
      item={item}
      last={rowIndex === renderedItems.value.length - 1}
      options={renderedItems.options}
      currencyPairs={currencyPairs}
      selectedPeriod={selectedPeriod}
      onSelectedPeriod={setSelectedPeriod}
      onValueChange={onValueChange}
      onCopyCurrent={onCopyCurrent}
      onFieldFocus={period => setSelectedPeriod(period)}
      onFieldLeave={onFieldLeave}
      onAddFreezing={onAddFreezing}
      onRemoveFreezing={onRemoveFreezing}
      onHasError={onHasError}
      changes={changes}
      commentTypes={commentTypes}
      selectedEstRecEditMode={selectedEstRecEditMode}
      rowNumber={rowIndex}
    />
  ));

  return (
    <Container>
      <TableBody ref={tableRef}>
        <HeaderRow onClick={() => setSelectedPeriod(null)}>
          <HeaderCell>Period</HeaderCell>
          {selectedColumns.map((column, i) => (
            <HeaderCell key={i + 1}>{column.name}</HeaderCell>
          ))}
        </HeaderRow>
        {rows}
      </TableBody>
    </Container>
  );
}

export function ProjectRecognitionsTable(props: ProjectRecognitionsTableProps): React.ReactElement {
  const {
    selectedColumns,
    oblPeriod,
    completePeriod,
    recognitions: byPeriodRecognitions,
    projectRecAsFlat,
    onAddComment,
    onDeleteComment,
    commentTypes,
    currencyPairs,
  } = props;
  const [openRecognitionItems, setOpenRecognitionItems] = useState<string[]>([]);
  const [renderedItems, setRenderedItems] = useState<ShowProjectRecognitions>(byPeriodRecognitions);
  const [selectedPeriod, setSelectedPeriod] = useState<string | null>(null);

  const tableRef = useRef<HTMLTableSectionElement | null>(null);
  const [listening, setListening] = useState(false);

  function handleOutsideClicks(ev: DocumentEventMap[keyof DocumentEventMap]) {
    if (tableRef.current === null) {
      stopListener();
    } else if (tableRef.current.contains(ev.target as HTMLDivElement)) {
      return;
    } else setSelectedPeriod(null);
  }

  function startListener() {
    if (listening) return;
    if (!tableRef.current) return;
    setListening(true);
    document.addEventListener(`click`, handleOutsideClicks, true);
  }

  function stopListener() {
    document.removeEventListener(`click`, handleOutsideClicks, true);
  }

  useMount(startListener, stopListener);

  useEffect(() => {
    if (byPeriodRecognitions) {
      setRenderedItems(byPeriodRecognitions);
    }
  }, [byPeriodRecognitions, projectRecAsFlat]);

  const rows = renderedItems.value.map((item: ByPeriodRecognition, rowIndex: number) =>
    renderDataRow(
      item,
      renderedItems.editType,
      currencyPairs,
      openRecognitionItems,
      setOpenRecognitionItems,
      selectedColumns,
      rowIndex === renderedItems.value.length - 1,
      projectRecAsFlat,
      oblPeriod,
      completePeriod,
      0,
      commentTypes,
      selectedPeriod,
      setSelectedPeriod,
      onAddComment,
      onDeleteComment
    )
  );

  return (
    <Container>
      <TableBody ref={tableRef}>
        <HeaderRow onClick={() => setSelectedPeriod(null)}>
          <HeaderCell>Period</HeaderCell>
          {selectedColumns.map((column, i) => (
            <HeaderCell key={i + 1}>{column.name}</HeaderCell>
          ))}
        </HeaderRow>
        {rows}
      </TableBody>
    </Container>
  );
}

const Container = styled.table`
  width: 100%;
  background: white;
  font-size: 10px;
  color: ${valmetGreyLight};
  tr:last-child {
    td {
      padding-bottom: 4px;
    }
  }
  border: solid ${settingGreen};
  border-width: 0 1px 1px 1px;
  border-collapse: separate;
  border-spacing: 0;
`;

const TableBody = styled.tbody``;

const HeaderRow = styled.tr`
  font-size: 12px;
  font-weight: 700;
  background: ${backgroundGrey};
  th:last-child {
    border-right: 0;
  }
  th:not(:last-child)::before {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    border-right: 1px solid ${valmetGreyREC};
    display: block;
    top: 1px;
  }
  th:first-child {
    z-index: 20;
  }
`;

const HeaderCell = styled.th`
  border-right: 1px solid ${valmetGreyREC};
  text-transform: uppercase;
  padding-top: 4px;
  padding-bottom: 4px;
  height: 4em;
  margin-left: auto;
  margin-right: auto;
  position: sticky;
  top: ${RecStickyHeaderMinimizedHeight};
  left: 0;
  z-index: 1;
  background: ${backgroundGrey};
  border-top: 1px solid ${settingGreen};
  border-bottom: 1px solid ${settingGreen};
`;

const DataRow = styled.tr<{
  childLevel?: number;
  extraPadding?: boolean;
  last?: boolean;
  highlight?: boolean;
  bg?: string;
  isSelected?: boolean;
}>`
  font-size: 14px;
  td {
    background-color: ${({ isSelected, bg }) => (isSelected ? selectedRowHighlight : bg ? bg : "white")};
    border: solid ${({ isSelected }) => (isSelected ? selectedRowColor : valmetGreyREC)};
    border-width: 1px 1px ${({ isSelected }) => (isSelected ? "1px" : "0")} 0;
  }
  td:first-child {
    padding-right: 10px;
    padding-left: ${({ childLevel, extraPadding }) =>
      childLevel ? `${childLevel * 30 + 10 + (extraPadding ? 36 : 0)}px` : "10px"};
    text-align: left;
    div:first-child {
      justify-content: flex-start;
    }
    position: sticky;
    left: 0;
    z-index: 10;
    background-color: ${({ isSelected, bg }) => (isSelected ? selectedRowHighlightOpaque : bg ? bg : "white")};
  }
`;

const EmptyCell = styled.td`
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
`;
