import React from "react";
import styled from "styled-components";

import { disabledGrey, valmetGreyLight } from "../../../../../common/colors";
import { ActionButton, ButtonContainer } from "../../../../../common/components";

export interface CopyDialogProps {
  onCancel: () => void;
  onCopy: () => void;
}

function CopyDialog(props: CopyDialogProps): React.ReactElement | null {
  const { onCancel, onCopy } = props;

  return (
    <Wrapper>
      <ContentContainer>
        <Title>Copy committed values</Title>
        <Info>Are you sure you want to copy committed values to current estimate?</Info>
        <ButtonContainer>
          <ActionButton onClick={() => onCopy()}>Copy</ActionButton>
          <ActionButton onClick={() => onCancel()}>Cancel</ActionButton>
        </ButtonContainer>
      </ContentContainer>
    </Wrapper>
  );
}

export default CopyDialog;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  width: 100%;
  background-color: ${disabledGrey};
  z-index: 1200;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 25px;
  padding-right: 25px;
  color: ${valmetGreyLight};
  width: 450px;
  align-items: flex-start;
`;

const Title = styled.div`
  font-size: 24px;
`;

const Info = styled.div`
  font-size: 16px;
  margin-top: 20px;
`;
