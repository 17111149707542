import { produce } from "immer";
import { filter } from "lodash";
import { HierarchyActionType, ProjectHierarchyAction } from "../actions/hierarchyActions";
import { HierarchyItemEnum, HierarchyState } from "../common/types";

export const initState: HierarchyState = {
  parentNodeId: undefined,
  addedNodes: [],
  modifiedHierarchy: undefined,
  newNodes: [],
  movedNodes: [],
  movedProjects: [],
  removedNodes: [],
  mainProjectChanges: [],
};

export default function hierarchyReducer(
  state: Readonly<HierarchyState> = initState,
  action: ProjectHierarchyAction
): HierarchyState {
  switch (action.type) {
    case HierarchyActionType.RESET_PROJECT_HIERARCHY:
      return produce(state, newState => {
        newState.parentNodeId = undefined;
        newState.addedNodes = [];
        newState.newNodes = [];
        newState.movedNodes = [];
        newState.movedProjects = [];
        newState.removedNodes = [];
        newState.mainProjectChanges = [];
      });
    case HierarchyActionType.SET_PROJECT_PARENT_NODE:
      return produce(state, newState => {
        const parentNodeId = action.parentNodeId;
        const projectId = action.projectId;
        newState.parentNodeId = parentNodeId;
        newState.mainProjectChanges = [];
        if (projectId !== undefined) {
          if (parentNodeId !== undefined && parentNodeId !== null)
            newState.mainProjectChanges.push({ mainProjectId: projectId, nodeId: parentNodeId });
        }
      });
    case HierarchyActionType.SET_PROJECT_HIERARCHY_ADDED_NODES:
      return produce(state, newState => {
        newState.addedNodes = action.addedNodes;
      });
    case HierarchyActionType.SET_PROJECT_HIERARCHY_NODE:
      return produce(state, newState => {
        if (action.nodeValue !== undefined) {
          newState.addedNodes = [
            {
              item: {
                id: -1,
                description: action.nodeValue,
                childLevel: 0,
                isProject: false,
                isActive: true,
                itemType: HierarchyItemEnum.Node,
                extraConnections: [],
                userAdded: true,
                hasChildren: false,
                projectTechnicalTypeId: null,
                projectTypeId: null,
              },
            },
          ];
          newState.parentNodeId = -1;
          if (action.projectId) {
            newState.mainProjectChanges = [{ mainProjectId: action.projectId, nodeId: -1 }];
          }
        } else {
          newState.addedNodes = [];
          newState.parentNodeId = undefined;
          newState.mainProjectChanges = [];
        }
      });
    case HierarchyActionType.SET_MODIFIED_HIERARCHY:
      return produce(state, newState => {
        newState.modifiedHierarchy = action.hierarchy;
      });
    case HierarchyActionType.ADD_NEW_NODE:
      return produce(state, newState => {
        newState.newNodes.push(action.node);
      });
    case HierarchyActionType.MOVE_PROJECT:
      return produce(state, newState => {
        const movedProject = action.project;
        newState.movedProjects = filter(
          newState.movedProjects,
          project => project.projectId !== movedProject.projectId
        );
        newState.movedProjects.push(movedProject);
      });
    case HierarchyActionType.MOVE_NODE:
      return produce(state, newState => {
        const movedNode = action.node;
        const newNodeIndex = newState.newNodes.findIndex(node => node.tempId === movedNode.nodeId);
        if (newNodeIndex >= 0) {
          newState.newNodes[newNodeIndex].parentNodeId = movedNode.parentNodeId;
        } else {
          newState.movedNodes = filter(newState.movedNodes, node => node.nodeId !== movedNode.nodeId);
          newState.movedNodes.push(movedNode);
        }
      });
    case HierarchyActionType.REMOVE_NODE:
      return produce(state, newState => {
        const removedNode = action.node;
        const doesExist = newState.newNodes.findIndex(node => node.tempId === removedNode.nodeId) === -1;
        newState.newNodes = filter(newState.newNodes, node => node.tempId !== removedNode.nodeId);
        newState.movedProjects = filter(newState.movedProjects, project => project.nodeId !== removedNode.nodeId);
        newState.movedNodes = filter(newState.movedNodes, node => node.nodeId === removedNode.nodeId);
        newState.mainProjectChanges = filter(
          newState.mainProjectChanges,
          change => change.nodeId !== removedNode.nodeId
        );
        if (doesExist) {
          newState.removedNodes.push(removedNode);
        }
      });
    case HierarchyActionType.ADD_MAIN_PROJECT_CHANGE:
      return produce(state, newState => {
        const mainProjectChange = action.mainProjectChange;
        newState.mainProjectChanges = filter(
          newState.mainProjectChanges,
          change => change.nodeId !== mainProjectChange.nodeId
        );
        newState.mainProjectChanges.push(mainProjectChange);
      });
    default:
      return state;
  }
}
