import { clone, cloneDeep, entries, keys, reduce, set, values } from "lodash";
import React, { PropsWithChildren, useState } from "react";
import styled from "styled-components";
import {
  backgroundGrey,
  disabledGrey,
  recFreezingSelectedBlue,
  valmetGreyLight,
  valmetGreyREC,
} from "../../../../../common/colors";
import { RECOGNITION_MASS_FREEZE_COLUMNS } from "../../../../../common/columns";
import { Period, periodGreaterThan, periodRange } from "../../../../../common/period";
import { ColumnFieldSpec, EditColumnSpec, EditableColumnId } from "../../../../../common/columnsTypes";
import { EditableRecognitionRow } from "../types";
import NumberInput from "../../../../TableDataCell/NumberInput";
import { PeriodSelect } from "./PeriodSelect";
import { ActionButton, ButtonContainer } from "../../../../../common/components";

interface MassFreezeDialogProps {
  rows: EditableRecognitionRow[];
  fromPeriod: Period | null;
  toPeriod: Period | null;
  onClose: () => void;
  onAddFreezing: (period: Period, columnId: EditableColumnId, fieldId: string, value: number, comment: string) => void;
}

type KiloCurrency = number;

interface FieldValue {
  fieldId: string;
  value: KiloCurrency; // must be converted to unit currency when creating the actual freezings.
  freeze: boolean;
}

interface ColumnInput {
  fieldValues: { [fieldId: string]: FieldValue };
}

type Input = {
  [ColumnId in EditableColumnId]: ColumnInput;
};

interface FreezingTableProps {
  input: Input;
  setInput: (input: Input) => void;
}

const setInputColumnField = (input: Input, columnId: EditableColumnId, fieldId: string, value: FieldValue): Input => {
  return set(clone(input), [columnId, "fieldValues", fieldId], value);
};

const toggleFieldFreeze = (input: Input, columnId: EditableColumnId, fieldId: string): Input => {
  const fieldValue = input[columnId].fieldValues[fieldId];
  return setInputColumnField(input, columnId, fieldId, { ...fieldValue, freeze: !fieldValue.freeze });
};

const toggleAllFieldsFreeze = (input: Input, columnId: EditableColumnId): Input => {
  const fvs = input[columnId].fieldValues;
  const anyToFreeze = values(fvs).some(v => v.freeze);
  const newInput = clone(input);
  keys(fvs).forEach(fieldId => set(newInput, [columnId, "fieldValues", fieldId, "freeze"], !anyToFreeze));
  return newInput;
};

const formatValue = (value: number, fieldSpec: ColumnFieldSpec): string => {
  const x = isNaN(value) ? 0 : value;
  return x.toFixed(fieldSpec.decimals);
  //return (fieldSpec.type === ColumnFieldSpecType.PERCENTAGE ||
  //fieldSpec.type === ColumnFieldSpecType.RATE
  //  ? x
  //  : x / 1000
  //).toFixed(fieldSpec.decimals);
};

function typedEntries<T, V extends T[keyof T]>(a: T extends { [K in keyof T]: V } ? T : never): Array<[keyof T, V]> {
  return entries(a).map(([key, value]) => [key as keyof T, value as V]);
}

function FreezingTable(
  props: FreezingTableProps
): React.FunctionComponentElement<PropsWithChildren<FreezingTableProps>> {
  const { input, setInput } = props;
  const columnToCell = (column: EditColumnSpec) => {
    const columnId = column.column.id;
    const columnInput: ColumnInput = input[columnId];
    return (
      <ColumnCell key={column.column.id}>
        {entries(column.fields).map(([fieldId, fieldSpec]) => {
          const fieldInput = columnInput.fieldValues[fieldId];
          const formattedValue = formatValue(fieldInput.value, fieldSpec);
          return (
            <MeasureRow key={fieldId}>
              <MeasureTitle
                freeze={fieldInput.freeze}
                onClick={() => setInput(toggleFieldFreeze(input, columnId, fieldId))}
              >
                {fieldSpec.title}
              </MeasureTitle>
              {fieldInput.freeze ? (
                <MeasureValue
                  type="float"
                  value={formattedValue}
                  decimals={fieldSpec.decimals}
                  onChange={value => setInput(setInputColumnField(input, columnId, fieldId, { ...fieldInput, value }))}
                />
              ) : (
                <div>-</div>
              )}
            </MeasureRow>
          );
        })}
      </ColumnCell>
    );
  };

  const firstColumns = RECOGNITION_MASS_FREEZE_COLUMNS.slice(0, 4);
  const restColumns = RECOGNITION_MASS_FREEZE_COLUMNS.slice(4);

  const makeRows = (columns: EditColumnSpec[]) => {
    const emptyCell = false; // columns.length === 4;
    return (
      <tbody>
        <HeaderRow>
          {columns.map(column => (
            <HeaderCell key={column.column.id} onClick={() => setInput(toggleAllFieldsFreeze(input, column.column.id))}>
              {column.column.name}
            </HeaderCell>
          ))}
          {emptyCell && <HeaderCell />}
        </HeaderRow>
        <ColumnsRow>
          {columns.map(column => columnToCell(column))}
          {emptyCell && <ColumnCell />}
        </ColumnsRow>
      </tbody>
    );
  };

  return (
    <>
      <TableContainer>
        <Table>{makeRows(firstColumns)}</Table>
      </TableContainer>
      <TableContainer>
        <Table>{makeRows(restColumns)}</Table>
      </TableContainer>
    </>
  );
}

const initialInput: Input = reduce(
  RECOGNITION_MASS_FREEZE_COLUMNS,
  (input, column) => {
    const fieldValues = reduce(
      entries(column.fields),
      (acc, [fieldId]) => ({
        ...acc,
        [fieldId]: {
          fieldId,
          value: 0.0,
          freeze: false,
        },
      }),
      {}
    );
    input[column.column.id] = { fieldValues };
    return input;
  },
  {} as Input
);

const minMaxPeriods = (rows: { period: Period }[]): [Period, Period] => {
  let minPeriod: Period = rows[0] && rows[0].period;
  let maxPeriod: Period = rows[0] && rows[0].period;
  rows.forEach(row => {
    if (periodGreaterThan(minPeriod, row.period)) minPeriod = row.period;
    if (!periodGreaterThan(maxPeriod, row.period)) maxPeriod = row.period;
  });
  return [minPeriod, maxPeriod];
};

function MassFreezeDialog(props: MassFreezeDialogProps): React.ReactElement {
  const { rows, fromPeriod: from, toPeriod: to, onClose, onAddFreezing } = props;

  const [fromPeriod, setFromPeriod] = useState<Period | null>(from);
  const [toPeriod, setToPeriod] = useState<Period | null>(to);
  const [input, setInput] = useState(cloneDeep(initialInput));
  const [comment, setComment] = useState("");

  const [minPeriod, maxPeriod] = minMaxPeriods(rows);

  const addFreezings = (from: Period, to: Period, input: Input, comment: string) => {
    const periods: Period[] = periodRange(from, to);
    typedEntries(input).forEach(([columnId, columnInput]) => {
      entries(columnInput.fieldValues).forEach(([fieldId, fieldInput]) => {
        periods.forEach(period => {
          if (fieldInput.freeze) {
            onAddFreezing(period, columnId, fieldId, fieldInput.value * 1000, comment);
          }
        });
      });
    });
  };

  const isIncomplete =
    fromPeriod === null || toPeriod === null || periodGreaterThan(fromPeriod, toPeriod) || comment.trim().length === 0;

  return (
    <Wrapper>
      <ContentContainer>
        <Title>Freeze multiple values</Title>
        <Info>
          <p>Create freezings for a span of periods for selected measures.</p>
        </Info>
        <PeriodsContainer>
          <Label>From period</Label>
          <PeriodSelect
            value={fromPeriod}
            setValue={value => {
              setFromPeriod(value);
              if (value !== null && toPeriod !== null && periodGreaterThan(value, toPeriod)) setToPeriod(value);
            }}
            minPeriod={minPeriod}
            maxPeriod={maxPeriod}
          />
          <Label>to</Label>
          <PeriodSelect
            value={toPeriod}
            setValue={value => {
              setToPeriod(value);
              if (value !== null && fromPeriod !== null && !periodGreaterThan(value, fromPeriod)) setFromPeriod(value);
            }}
            minPeriod={minPeriod}
            maxPeriod={maxPeriod}
          />
        </PeriodsContainer>
        <FreezingTable input={input} setInput={setInput} />
        <Label htmlFor="comment">Comment</Label>
        <CommentArea
          id="comment"
          rows={4}
          placeholder="Comment is required"
          value={comment}
          onChange={ev => setComment(ev.target.value)}
        />
        <ButtonContainer disabledSave={isIncomplete}>
          <ActionButton
            onClick={() => {
              if (fromPeriod !== null && toPeriod !== null) {
                addFreezings(fromPeriod, toPeriod, input, comment);
                onClose();
              }
            }}
          >
            Freeze
          </ActionButton>
          <ActionButton onClick={onClose}>Cancel</ActionButton>
        </ButtonContainer>
      </ContentContainer>
    </Wrapper>
  );
}

export default MassFreezeDialog;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  width: 100%;
  background-color: ${disabledGrey};
  z-index: 1200;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 25px;
  padding-right: 25px;
  color: ${valmetGreyLight};
  min-width: 960px;
  max-height: 90%;
  overflow: scroll;
  align-items: flex-start;
`;

const Title = styled.div`
  font-size: 24px;
`;

const Info = styled.div`
  font-size: 16px;
  margin-top: 20px;
  p {
    margin-top: 0.7em;
    margin-bottom: 0.7em;
  }
`;

const PeriodsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 20px;
`;

const TableContainer = styled.div`
  margin-right: auto;
  margin-left: auto;
  transform-origin: top left;
  margin-bottom: 20px;
  /*transform: scale(0.85);*/
`;

const Table = styled.table`
  width: 100%;
  background: white;
  border-collapse: collapse;
  border: 1px solid ${valmetGreyREC};
  font-size: 10px;
  color: ${valmetGreyLight};
  tr:last-child {
    td {
      padding-bottom: 4px;
    }
  }
`;

const HeaderRow = styled.tr`
  font-size: 12px;
  font-weight: 700;
  background: ${backgroundGrey};
  th:last-child {
    border-right: 0;
  }
`;

const HeaderCell = styled.th`
  border-right: 1px solid ${valmetGreyREC};
  text-transform: uppercase;
  /*padding-top: 4px;
  padding-bottom: 4px;*/
  height: 4em;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  z-index: 1;
  background: ${backgroundGrey};
  cursor: pointer;
  &:hover {
    background: ${recFreezingSelectedBlue};
  }
`;

const ColumnsRow = styled.tr`
  font-size: 14px;
  background-color: white;
  td {
    width: 9.5%;
    background-color: white;
  }
  td:first-child {
    /*padding-right: 10px;
    padding-left: 10px;*/
    text-align: left;
    div:first-child {
      justify-content: flex-start;
    }
  }
  td:first-child::before {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    border-right: 1px solid ${valmetGreyREC};
    border-bottom: 1px solid ${valmetGreyREC};
    display: block;
    top: 0px;
    pointer-events: none;
  }
  td:last-child {
    border-right: 0;
  }
`;

const ColumnCell = styled.td`
  color: ${valmetGreyLight};
  font-size: 10px;
  padding-left: 4px;
  padding-right: 4px;
  min-height: 48px;
  border-right: 1px solid ${valmetGreyREC};
`;

const MeasureRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1px;
`;

const MeasureTitle = styled.div<{ freeze: boolean }>`
  min-width: 66px;
  color: #6f6f6f;
  font-size: 10px;
  font-weight: ${({ freeze }) => (freeze ? "bold" : "normal")};
  align-self: center;
  padding: 5px;
  border-radius: 4px;
  cursor: pointer;
  white-space: nowrap;
  ${({ freeze }) => (freeze ? `background-color: ${recFreezingSelectedBlue};` : "")}
  &:hover {
    background-color: ${recFreezingSelectedBlue};
  }
`;

const MeasureValue = styled(NumberInput)`
  text-align: right;
  width: 8em;
`;

const Label = styled.label``;

const CommentArea = styled.textarea`
  box-sizing: border-box;
  padding: 5px;
  width: 100%;
`;
