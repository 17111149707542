import React, { useState } from "react";
import styled from "styled-components";

export interface NewHierarchyViewProps {
  onNodeValueChanged: (value: string) => void;
}

function NewHierarchyView(props: NewHierarchyViewProps): React.ReactElement {
  const { onNodeValueChanged } = props;
  const [nodeName, setNodeName] = useState<string>("");
  return (
    <Container>
      <Title>Create a new hierarchy for the project:</Title>
      <Row>
        <HierarchyInput
          type={"text"}
          value={nodeName}
          onChange={event => {
            onNodeValueChanged(event.target.value);
            setNodeName(event.target.value);
          }}
          placeholder={"New node name..."}
        />
      </Row>
      <Info>Project will be added as a child of the new node.</Info>
    </Container>
  );
}

export default NewHierarchyView;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
`;

const Title = styled.div`
  margin-bottom: 20px;
`;

const HierarchyInput = styled.input`
  width: 300px;
  font-weight: bold;
  font-size: 18px;
`;

const Info = styled.div`
  margin-top: 20px;
  font-size: 12px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;
