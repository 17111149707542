import { ApolloClient } from "@apollo/client";
import { cloneDeep, find, findIndex } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useApolloClient } from "@apollo/client/react/hooks";
import { lightGreen } from "../../../../../common/colors";
import {
  ContainedMainProject,
  EntityTypeId,
  HierarchyItem,
  HierarchyItemEnum,
  HierarchyTreeItem,
  HierarchyTreeNode,
  MainProjectChangeRequest,
  NewNodeRequest,
  NodeId,
  NodeRequest,
  ProjectId,
  ProjectRequest,
  ProjectTypeId,
  ProjectTypes,
  RemoveNodeRequest,
} from "../../../../../common/types";
import { GET_NODE } from "../queries";
import CreateNodeDialog from "./CreateNodeDialog";
import HierarchyItemView, { getHierarchyItemViewColor } from "./HierarchyItemView";
import LinkNodeDialog from "./LinkNodeDialog";
import {
  addItemIntoTree,
  buildHierarchyTree,
  findChildNode,
  findChildProject,
  findRelatedProjects,
  getChildItems,
  HierarchyDrawOptions,
  isChildNode,
} from "./utils";
import { DUMMY_HIERARCHY_ID } from "../../../../../common/constants";
import { initLogger } from "../../../../../logging";

const logger = initLogger(__filename);

export interface HierarchyTreeProps {
  projectId: ProjectId;
  hierarchy: HierarchyItem;
  selectedParentNodeId: NodeId | undefined | null;
  setSelectedParentNodeId: (parentNodeId: NodeId | undefined | null, projectId?: ProjectId) => void;
  addedNodes: HierarchyTreeNode[];
  setAddedNodes: (addedNodes: HierarchyTreeNode[]) => void;
  projectName: string;
  resetProjectHierarchy: () => void;
  displayInactiveItems?: boolean;
  drawOptions?: HierarchyDrawOptions;
  disableBorders?: boolean;
  disableEditing?: boolean;
  advancedMode?: boolean;
  setModifiedHierarchy?: (hierarchy: HierarchyItem) => void;
  readOnly?: boolean;
  addNewNode?: (node: NewNodeRequest) => void;
  moveProject?: (project: ProjectRequest) => void;
  moveNode?: (node: NodeRequest) => void;
  removeNode?: (node: RemoveNodeRequest) => void;
  addMainProjectChange: (mainProjectChange: MainProjectChangeRequest) => void;
  setHasErrors?: (hasErrors: boolean) => void;
}

const MINIMIZE_TIMEOUT = 50;

const addChildItemsToHierarchy = (
  hierarchy: HierarchyItem,
  targetId: NodeId | undefined,
  movingItem: HierarchyItem,
  addMainProjectChange: (mainProjectChange: MainProjectChangeRequest) => void
) => {
  // Remove from current parent.
  // Set main project for current parent to null.
  // Add to the new parent node (targetId).
  // - if new parent's main project is null, set the new item as main project.
  // Old parents main correction:
  // - set main project to available project or inherit from available node.

  const itemEquals = (a: HierarchyItem, b: HierarchyItem): boolean => a.itemType === b.itemType && a.id === b.id;
  const mainProjectFromItem = (item: HierarchyItem): ProjectId | null => {
    if (item.itemType === HierarchyItemEnum.Project) {
      return item.id;
    } else {
      return item.mainProjectId;
    }
  };
  const hasMainProjectFromItem = (h: HierarchyItem, item: HierarchyItem): boolean => {
    const mainProject = mainProjectFromItem(item);
    return mainProject !== null && h.mainProjectId === mainProject;
  };

  // Remove from current parent.
  const removeFromParent = (hierarchy: HierarchyItem, item: HierarchyItem): HierarchyItem | null => {
    if (hierarchy.childItems.some(child => itemEquals(child, item))) {
      hierarchy.childItems = hierarchy.childItems.filter(child => !itemEquals(child, item));
      // Is this really needed?
      if (hasMainProjectFromItem(hierarchy, item)) {
        hierarchy.mainProjectId = null;
      }
      return hierarchy;
    }
    for (let i = 0; i < hierarchy.childItems.length; i++) {
      const child = hierarchy.childItems[i];
      if (child.itemType === HierarchyItemEnum.Node) {
        const res = removeFromParent(child, item);
        if (res) {
          if (hasMainProjectFromItem(hierarchy, item)) {
            hierarchy.mainProjectId = null;
          }
          return res;
        }
      }
    }
    return null;
  };

  const addToHierarchy = (hierarchy: HierarchyItem, parentId: NodeId, item: HierarchyItem): boolean => {
    if (hierarchy.id === parentId) {
      hierarchy.childItems.push(item);
      return true;
    } else {
      for (let i = 0; i < hierarchy.childItems.length; i++) {
        const child = hierarchy.childItems[i];
        if (child.itemType === HierarchyItemEnum.Node) {
          const added = addToHierarchy(child, parentId, item);
          if (added) {
            if (child.mainProjectId === null) {
              const mainProject = findMainProjectCandidate(child);
              if (mainProject !== null) {
                child.mainProjectId = mainProject;
                addMainProjectChange({ nodeId: child.id, mainProjectId: mainProject });
              }
            }
            if (hierarchy.mainProjectId === null) {
              const mainProject = findMainProjectCandidate(child);
              if (mainProject !== null) {
                child.mainProjectId = mainProject;
                addMainProjectChange({ nodeId: hierarchy.id, mainProjectId: mainProject });
              }
            }
            return true;
          }
        }
      }
      return false;
    }
  };

  /**
   * Find candidate for main project inside the specified hierarchy.
   *
   * Valid candidate can be found only if it is unambiguous, i.e.
   * only one child project or child node with main project is found.
   */
  const findMainProjectCandidate = (h: HierarchyItem): ProjectId | null => {
    const candidates = h.childItems.filter(
      item => item.itemType === HierarchyItemEnum.Project || item.mainProjectId !== null
    );
    if (candidates.length !== 1) {
      return null;
    }
    const childProject = candidates.find(item => item.itemType === HierarchyItemEnum.Project);
    if (childProject !== undefined) {
      return childProject.id;
    }
    const node = candidates.find(item => item.mainProjectId !== null);
    if (node !== undefined) {
      return node.mainProjectId;
    }
    return null;
  };

  const correctMainProject = (hierarchy: HierarchyItem, node: HierarchyItem): ProjectId | null | undefined => {
    if (itemEquals(hierarchy, node)) {
      return findMainProjectCandidate(hierarchy);
    } else {
      for (let i = 0; i < hierarchy.childItems.length; i++) {
        const child = hierarchy.childItems[i];
        if (child.itemType === HierarchyItemEnum.Node) {
          const mainProject = correctMainProject(child, node);
          if (mainProject !== undefined) {
            if (child.mainProjectId === null) {
              if (mainProject === null) {
                const mainProjectCandidate = findMainProjectCandidate(child);
                child.mainProjectId = mainProjectCandidate;
                addMainProjectChange({ nodeId: child.id, mainProjectId: mainProjectCandidate });
                return null;
              } else {
                child.mainProjectId = mainProject;
                addMainProjectChange({ nodeId: child.id, mainProjectId: mainProject });
                return mainProject;
              }
            }
          }
        }
      }
      return undefined;
    }
  };

  const oldParent = removeFromParent(hierarchy, movingItem);
  if (targetId !== undefined) {
    addToHierarchy(hierarchy, targetId, movingItem);
  }
  if (oldParent && oldParent.mainProjectId === null) {
    correctMainProject(hierarchy, oldParent);
  }
};

const containsParentsMainProject = (
  items: HierarchyTreeItem[],
  currentItem: HierarchyTreeItem
): ContainedMainProject | undefined => {
  if (currentItem.itemType === HierarchyItemEnum.Node && currentItem.parentId !== undefined) {
    const parent = find(items, item => item.id === currentItem.parentId);
    if (parent && parent.hierarchyItem && currentItem.hierarchyItem && parent.hierarchyItem.mainProjectId !== null) {
      const contains = currentItem.hierarchyItem.childItems
        .map(childItem => childItem.id)
        .includes(parent.hierarchyItem.mainProjectId);
      if (contains) {
        return { parentDesc: parent.description, id: parent.hierarchyItem.mainProjectId };
      } else if (currentItem.hierarchyItem.childItems.length > 0) {
        const childNodes: HierarchyItem[] = [];
        getChildItems(currentItem.hierarchyItem.childItems, childNodes, HierarchyItemEnum.Node);
        for (let i = 0; i < childNodes.length; i++) {
          if (childNodes[i].mainProjectId === parent.hierarchyItem.mainProjectId)
            return { parentDesc: parent.description, id: parent.hierarchyItem.mainProjectId };
        }
        return undefined;
      } else return undefined;
    }
  }
  return undefined;
};

const drag = (
  event: React.DragEvent,
  item: HierarchyTreeItem,
  isProject: boolean,
  items: HierarchyTreeItem[],
  setDraggedItem: React.Dispatch<React.SetStateAction<DraggedItem | undefined>>
) => {
  event.dataTransfer.setData("itemId", item.id.toString());
  event.dataTransfer.setData("isProject", isProject + "");
  setTimeout(function () {
    setDraggedItem({ item: item.id, dragStarted: new Date().getTime() });
  }, MINIMIZE_TIMEOUT);
};

const colorContentParent = (target: HTMLDivElement, color: string) => {
  if (target.parentElement && target.parentElement.id === "contentContainer") {
    target.parentElement.style.background = color;
  } else if (target.parentElement) {
    colorContentParent(target.parentElement as HTMLDivElement, color);
  }
};

const allowDrop = (event: React.DragEvent, targetId: NodeId, allowDrop: boolean) => {
  event.preventDefault();
  const itemId = parseInt(event.dataTransfer.getData("itemId"));
  if (itemId !== targetId && allowDrop) {
    const target = event.target as HTMLDivElement;
    if (target.id === "contentContainer") {
      target.style.background = `${lightGreen}`;
    } else {
      colorContentParent(target, lightGreen);
    }
  }
};

const dragLeave = (
  event: React.DragEvent,
  projectTechnicalTypeId: EntityTypeId | null,
  projectTypeId: ProjectTypeId | null
) => {
  event.preventDefault();
  const target = event.target as HTMLDivElement;
  const color = getHierarchyItemViewColor(projectTechnicalTypeId, projectTypeId);
  if (target.id === "contentContainer") {
    target.style.background = `${color}`;
  } else {
    colorContentParent(target, color);
  }
};

const addItemIntoHierarchy = (
  itemId: number,
  itemType: HierarchyItemEnum,
  targetId: NodeId | undefined,
  hierarchy: HierarchyItem,
  allowDrop: boolean,
  addMainProjectChange: (mainProjectChange: MainProjectChangeRequest) => void,
  setModifiedHierarchy?: (hierarchy: HierarchyItem) => void,
  addNewNode?: (node: NewNodeRequest) => void,
  newItem?: HierarchyItem,
  moveProject?: (project: ProjectRequest) => void,
  moveNode?: (node: NodeRequest) => void,
  transferToNewHierarchy?: boolean
) => {
  if (
    setModifiedHierarchy &&
    allowDrop &&
    targetId !== itemId &&
    !(itemType === HierarchyItemEnum.Node && isChildNode(targetId, itemId, hierarchy))
  ) {
    const newHierarchy = cloneDeep(hierarchy);
    if (newItem) {
      addChildItemsToHierarchy(newHierarchy, targetId, newItem, addMainProjectChange);
      if (addNewNode) addNewNode({ tempId: newItem.id, description: newItem.description, parentNodeId: targetId });
      if (transferToNewHierarchy && targetId !== undefined && moveProject && moveNode) {
        if (newItem.itemType !== HierarchyItemEnum.Node) {
          moveProject({ projectId: newItem.id, nodeId: targetId });
        } else {
          moveNode({ nodeId: newItem.id, parentNodeId: targetId, nodeDescription: newItem.description });
        }
      }
    } else {
      //Find the item
      const foundItems: HierarchyItem[] = [];
      itemType === HierarchyItemEnum.Node
        ? findChildNode(itemId, hierarchy.childItems, foundItems)
        : findChildProject(itemId, hierarchy.childItems, foundItems);

      //Rebuild the hierarchy and place the item to the right place
      if (foundItems.length) {
        addChildItemsToHierarchy(newHierarchy, targetId, foundItems[0], addMainProjectChange);
        if (foundItems[0].itemType !== HierarchyItemEnum.Node && moveProject && targetId) {
          moveProject({ projectId: foundItems[0].id, nodeId: targetId });
        } else if (foundItems[0].itemType === HierarchyItemEnum.Node && moveNode) {
          moveNode({ nodeId: foundItems[0].id, parentNodeId: targetId, nodeDescription: foundItems[0].description });
        }

        const relatedProjects: HierarchyItem[] = [];
        if (
          foundItems[0].projectTechnicalTypeId === EntityTypeId.AutomaticAdjustment &&
          foundItems[0].relatingProjectId
        ) {
          findChildProject(foundItems[0].relatingProjectId, hierarchy.childItems, relatedProjects);
        }
      }
    }

    setModifiedHierarchy(newHierarchy);
  }
};

const drop = (
  event: React.DragEvent,
  targetId: NodeId,
  hierarchy: HierarchyItem,
  allowDrop: boolean,
  projectTechnicalTypeId: EntityTypeId | null,
  projectTypeId: ProjectTypeId | null,
  addMainProjectChange: (mainProjectChange: MainProjectChangeRequest) => void,
  setModifiedHierarchy?: (hierarchy: HierarchyItem) => void,
  moveProject?: (project: ProjectRequest) => void,
  moveNode?: (node: NodeRequest) => void
) => {
  event.stopPropagation();
  event.preventDefault();
  const target = event.target as HTMLDivElement;
  target.style.backgroundColor = getHierarchyItemViewColor(projectTechnicalTypeId, projectTypeId);

  const itemId = parseInt(event.dataTransfer.getData("itemId"));
  const itemType =
    event.dataTransfer.getData("isProject") === "true" ? HierarchyItemEnum.Project : HierarchyItemEnum.Node;
  addItemIntoHierarchy(
    itemId,
    itemType,
    targetId,
    hierarchy,
    allowDrop,
    addMainProjectChange,
    setModifiedHierarchy,
    undefined,
    undefined,
    moveProject,
    moveNode,
    undefined
  );
};

const removeAddedNode = (
  nodeId: NodeId,
  addedNodes: HierarchyTreeNode[],
  setAddedNodes: (addedNodes: HierarchyTreeNode[]) => void,
  selectedParentNodeId: NodeId | undefined | null,
  setSelectedParentNodeId: (parentNodeId: NodeId | undefined | null, projectId?: ProjectId) => void,
  projectId: ProjectId
) => {
  const nodeIndex = findIndex(addedNodes, node => node.item.id === nodeId);
  if (nodeIndex >= 0) {
    const newAddedNodes = cloneDeep(addedNodes);
    newAddedNodes.splice(nodeIndex, 1);
    setAddedNodes(newAddedNodes);

    if (selectedParentNodeId === nodeId) {
      setSelectedParentNodeId(undefined, projectId);
    }
  }
};

const removeNodeFromHierarchy = (
  hierarchy: HierarchyItem,
  removedItemId: NodeId,
  setModifiedHierarchy: (hierarchy: HierarchyItem) => void,
  addMainProjectChange: (mainProjectChange: MainProjectChangeRequest) => void,
  removeNode?: (node: RemoveNodeRequest) => void
) => {
  const newHierarchy = cloneDeep(hierarchy);

  const foundItems: HierarchyItem[] = [];
  findChildNode(removedItemId, hierarchy.childItems, foundItems);

  if (foundItems.length) {
    addChildItemsToHierarchy(newHierarchy, undefined, foundItems[0], addMainProjectChange);
    if (removeNode) removeNode({ nodeId: foundItems[0].id, description: foundItems[0].description });
  }

  setModifiedHierarchy(newHierarchy);
};

/**
 * Returns new temporary node ID.
 *
 * Temporary IDs are distinguished from actual IDs by the fact that they are negative.
 */
const newTemporaryId = () => {
  const time = new Date().getTime();
  const id = time & 0xffffffff;
  return id < 0 ? id : -id;
};

const moveItemToNewHierarchy = (
  item: HierarchyItem,
  prevHierarchy: HierarchyItem,
  newNode: string,
  currentProjectId: ProjectId,
  nodeItemContainsCurrentProject: boolean,
  addNewNode: (node: NewNodeRequest) => void,
  setModifiedHierarchy: (hierarchy: HierarchyItem) => void,
  moveProject: (project: ProjectRequest) => void,
  moveNode: (node: NodeRequest) => void,
  addMainProjectChange: (mainProjectChange: MainProjectChangeRequest) => void
) => {
  const newNodeTempId = newTemporaryId();
  addNewNode({ tempId: newNodeTempId, description: newNode });

  if (item.itemType === HierarchyItemEnum.Project) {
    moveProject({ projectId: item.id, nodeId: newNodeTempId });
    addMainProjectChange({ mainProjectId: item.id, nodeId: newNodeTempId });
  } else {
    moveNode({ nodeId: item.id, nodeDescription: item.description, parentNodeId: newNodeTempId });
    addMainProjectChange({ mainProjectId: item.mainProjectId, nodeId: newNodeTempId });
  }

  // move related projects
  const relatedProjects: HierarchyItem[] = [item];
  if (item.projectTechnicalTypeId === EntityTypeId.AutomaticAdjustment && item.relatingProjectId) {
    findChildProject(item.relatingProjectId, prevHierarchy.childItems, relatedProjects);
  }
  findRelatedProjects(item.id, prevHierarchy.childItems, relatedProjects);
  relatedProjects.forEach(project => moveProject({ projectId: project.id, nodeId: newNodeTempId }));

  relatedProjects.push(item);
  if (currentProjectId === item.id || nodeItemContainsCurrentProject)
    setModifiedHierarchy({
      id: newNodeTempId,
      description: newNode,
      active: true,
      itemType: HierarchyItemEnum.Node,
      childItems: relatedProjects,
      mainProjectId: item.itemType === HierarchyItemEnum.Project ? item.id : item.mainProjectId,
      projectTechnicalTypeId: null,
      projectTypeId: null,
      hiddenInHierarchyView: false,
      relatingProjectId: item.relatingProjectId,
      adjustmentRateInfo: null,
      __typename: "HierarchyItem",
    });
};

/* TODO: Refactor this code. There is almost duplicate code in addChildItemsToHierarchy function.
   It is maybe possible to unify the functions and just pass old hierarchy and new hierarchy as parameters.
 */
const removeFromOldParent = (
  hierarchy: HierarchyItem,
  item: HierarchyItem,
  addMainProjectChange: (req: MainProjectChangeRequest) => void
) => {
  const itemEquals = (a: HierarchyItem, b: HierarchyItem): boolean => a.itemType === b.itemType && a.id === b.id;
  const mainProjectFromItem = (item: HierarchyItem): ProjectId | null => {
    if (item.itemType === HierarchyItemEnum.Project) {
      return item.id;
    } else {
      return item.mainProjectId;
    }
  };
  const hasMainProjectFromItem = (h: HierarchyItem, item: HierarchyItem): boolean => {
    const mainProject = mainProjectFromItem(item);
    return mainProject !== null && h.mainProjectId === mainProject;
  };

  const removeFromParent = (hierarchy: HierarchyItem, item: HierarchyItem): HierarchyItem | null => {
    logger.debug("Remove from parent", hierarchy, item);
    if (hierarchy.childItems.some(child => itemEquals(child, item))) {
      hierarchy.childItems = hierarchy.childItems.filter(child => !itemEquals(child, item));
      // Is this really needed?
      if (hasMainProjectFromItem(hierarchy, item)) {
        hierarchy.mainProjectId = null;
        logger.debug("Main project to null for ", hierarchy.id);
        addMainProjectChange({ nodeId: hierarchy.id, mainProjectId: null });
      }
      return hierarchy;
    }
    for (let i = 0; i < hierarchy.childItems.length; i++) {
      const child = hierarchy.childItems[i];
      if (child.itemType === HierarchyItemEnum.Node) {
        const res = removeFromParent(child, item);
        if (res) {
          if (hasMainProjectFromItem(hierarchy, item)) {
            hierarchy.mainProjectId = null;
            logger.debug("Main project to null for ", hierarchy.id);
            addMainProjectChange({ nodeId: hierarchy.id, mainProjectId: null });
          }
          return res;
        }
      }
    }
    return null;
  };

  /**
   * Find candidate for main project inside the specified hierarchy.
   *
   * Valid candidate can be found only if it is unambiguous, i.e.
   * only one child project or child node with main project is found.
   */
  const findMainProjectCandidate = (h: HierarchyItem): ProjectId | null => {
    const candidates = h.childItems.filter(
      item => item.itemType === HierarchyItemEnum.Project || item.mainProjectId !== null
    );
    if (candidates.length !== 1) {
      return null;
    }
    const childProject = candidates.find(item => item.itemType === HierarchyItemEnum.Project);
    if (childProject !== undefined) {
      return childProject.id;
    }
    const node = candidates.find(item => item.mainProjectId !== null);
    if (node !== undefined) {
      return node.mainProjectId;
    }
    return null;
  };

  const correctMainProject = (hierarchy: HierarchyItem, node: HierarchyItem): ProjectId | null | undefined => {
    if (itemEquals(hierarchy, node)) {
      return findMainProjectCandidate(hierarchy);
    } else {
      for (let i = 0; i < hierarchy.childItems.length; i++) {
        const child = hierarchy.childItems[i];
        if (child.itemType === HierarchyItemEnum.Node) {
          const mainProject = correctMainProject(child, node);
          if (mainProject !== undefined) {
            if (child.mainProjectId === null) {
              if (mainProject === null) {
                const mainProjectCandidate = findMainProjectCandidate(child);
                child.mainProjectId = mainProjectCandidate;
                addMainProjectChange({ nodeId: child.id, mainProjectId: mainProjectCandidate });
                return null;
              } else {
                child.mainProjectId = mainProject;
                addMainProjectChange({ nodeId: child.id, mainProjectId: mainProject });
                return mainProject;
              }
            }
          }
        }
      }
      return undefined;
    }
  };

  const oldParent = removeFromParent(hierarchy, item);
  if (oldParent && oldParent.mainProjectId === null) {
    correctMainProject(hierarchy, oldParent);
  }
};

const linkItem = async (
  itemId: number,
  itemType: HierarchyItemEnum,
  hierarchy: HierarchyItem,
  targetId: NodeId | undefined,
  currentProjectId: ProjectId,
  client: ApolloClient<Record<string, unknown>>,
  newNode: string | undefined,
  addMainProjectChange: (mainProjectChange: MainProjectChangeRequest) => void,
  moveNode?: (node: NodeRequest) => void,
  moveProject?: (project: ProjectRequest) => void,
  setModifiedHierarchy?: (hierarchy: HierarchyItem) => void,
  setHasErrors?: (hasErrors: boolean) => void,
  addNewNode?: (node: NewNodeRequest) => void
) => {
  let item;
  if (itemType === HierarchyItemEnum.Node) {
    if (hierarchy.id === itemId) {
      item = hierarchy;
    } else {
      const foundItems: HierarchyItem[] = [];
      findChildNode(itemId, hierarchy.childItems, foundItems);
      item = foundItems.length ? foundItems[0] : undefined;
    }
  } else {
    const foundItems: HierarchyItem[] = [];
    findChildProject(itemId, hierarchy.childItems, foundItems);
    item = foundItems.length ? foundItems[0] : undefined;
  }

  //Check if the item contains current project item
  let nodeItemContainsCurrentProject = false;
  if (item) {
    const foundItems: HierarchyItem[] = [];
    findChildProject(currentProjectId, item.childItems, foundItems);
    if (foundItems.length) {
      nodeItemContainsCurrentProject = true;
    }
  }

  //Check if we need to do special handling for current item
  if (
    ((currentProjectId === itemId && itemType === HierarchyItemEnum.Project) || nodeItemContainsCurrentProject) &&
    targetId !== undefined
  ) {
    const foundItems: HierarchyItem[] = [];
    findChildNode(targetId, hierarchy.childItems, foundItems);
    //Target is in current hierarchy, no need for more special handling
    if (foundItems.length) {
      addItemIntoHierarchy(
        itemId,
        itemType,
        targetId,
        hierarchy,
        true,
        addMainProjectChange,
        setModifiedHierarchy,
        undefined,
        undefined,
        moveProject,
        moveNode,
        undefined
      );
    } else if (item) {
      //Check for current item and then fetch new target node hierarchy
      const { data, errors } = await client
        .query<{ getNode: HierarchyItem }>({
          query: GET_NODE,
          variables: { nodeId: targetId },
          fetchPolicy: "no-cache",
        })
        .catch(e => {
          return e;
        });
      if (data && data.getNode && setModifiedHierarchy && !errors) {
        //If everything is found, attach the current item to new hierachy
        removeFromOldParent(hierarchy, item, addMainProjectChange);
        addItemIntoHierarchy(
          itemId,
          itemType,
          targetId,
          data.getNode,
          true,
          addMainProjectChange,
          setModifiedHierarchy,
          undefined,
          item,
          moveProject,
          moveNode,
          true
        );
      } else {
        if (setHasErrors) setHasErrors(true);
      }
    }
  } else {
    addItemIntoHierarchy(
      itemId,
      itemType,
      targetId,
      hierarchy,
      true,
      addMainProjectChange,
      setModifiedHierarchy,
      undefined,
      undefined,
      moveProject,
      moveNode
    );
  }

  if (item && newNode && currentProjectId && addNewNode && setModifiedHierarchy && moveProject && moveNode) {
    moveItemToNewHierarchy(
      item,
      hierarchy,
      newNode,
      currentProjectId,
      nodeItemContainsCurrentProject,
      addNewNode,
      setModifiedHierarchy,
      moveProject,
      moveNode,
      addMainProjectChange
    );
  }
};

const editNode = (
  nodeId: NodeId,
  nodeDescription: string,
  hierarchy: HierarchyItem,
  moveNode?: (node: NodeRequest) => void,
  parentNodeId?: NodeId,
  setModifiedHierarchy?: (hierarchy: HierarchyItem) => void
) => {
  if (moveNode)
    moveNode({
      nodeId,
      parentNodeId,
      nodeDescription,
    });
  const newHierarchy = cloneDeep(hierarchy);
  if (newHierarchy.id === nodeId) {
    newHierarchy.description = nodeDescription;
  } else {
    const foundItems: HierarchyItem[] = [];
    findChildNode(nodeId, newHierarchy.childItems, foundItems);
    if (foundItems) {
      const editedItem = foundItems[0];
      editedItem.description = nodeDescription;
    }
  }
  if (setModifiedHierarchy) setModifiedHierarchy(newHierarchy);
};

const selectChildNodeMainProject = (
  node: HierarchyItem,
  projectId: ProjectId,
  parentId: NodeId,
  parents: NodeId[],
  addMainProjectChange: (mainProjectChange: MainProjectChangeRequest) => void,
  previousMainId?: ProjectId | null
) => {
  if (node.id === parentId) {
    node.mainProjectId = projectId;
    addMainProjectChange({ mainProjectId: projectId, nodeId: node.id });
  } else {
    if (parents.includes(node.id) && node.mainProjectId === previousMainId) {
      node.mainProjectId = projectId;
      addMainProjectChange({ mainProjectId: projectId, nodeId: node.id });
    }
    node.childItems.forEach(item => {
      if (item.itemType === HierarchyItemEnum.Node)
        selectChildNodeMainProject(item, projectId, parentId, parents, addMainProjectChange, previousMainId);
    });
  }
};

const selectMainProject = (
  hierarchy: HierarchyItem,
  projectId: ProjectId,
  parents: NodeId[],
  previousMainId?: ProjectId | null,
  parentId?: NodeId,
  setModifiedHierarchy?: (hierarchy: HierarchyItem) => void,
  addMainProjectChange?: (mainProjectChange: MainProjectChangeRequest) => void
) => {
  if (parentId !== undefined && setModifiedHierarchy && addMainProjectChange) {
    const newHierarchy = cloneDeep(hierarchy);
    selectChildNodeMainProject(newHierarchy, projectId, parentId, parents, addMainProjectChange, previousMainId);
    setModifiedHierarchy(newHierarchy);
  }
};

const getHierarchyParents = (items: HierarchyTreeItem[], item: HierarchyTreeItem) => {
  const parents: NodeId[] = [];
  const addParents = (parents: NodeId[], items: HierarchyTreeItem[], itemId: NodeId) => {
    const item = find(items, item => item.id === itemId);
    if (item) {
      parents.push(item.id);
      if (item.parentId !== undefined) {
        addParents(parents, items, item.parentId);
      }
    }
  };
  if (item.parentId) addParents(parents, items, item.parentId);
  return parents;
};

const getPreviousMainId = (items: HierarchyTreeItem[], nodeId?: NodeId) => {
  if (nodeId) {
    const node = find(items, item => item.id === nodeId);
    if (node && node.hierarchyItem) {
      return node.hierarchyItem.mainProjectId;
    }
  }
};

const selectChildMainNode = (
  mainProjectId: ProjectId,
  node: HierarchyItem,
  parents: NodeId[],
  addMainProjectChange: (mainProjectChange: MainProjectChangeRequest) => void,
  previousMainId?: ProjectId | null,
  parentId?: NodeId
) => {
  if (node.id === parentId) {
    node.mainProjectId = mainProjectId;
    addMainProjectChange({ mainProjectId: mainProjectId, nodeId: node.id });
  } else {
    if (parents.includes(node.id) && node.mainProjectId === previousMainId) {
      node.mainProjectId = mainProjectId;
      addMainProjectChange({ mainProjectId: mainProjectId, nodeId: node.id });
    }
    node.childItems.forEach(childNode => {
      if (childNode.itemType === HierarchyItemEnum.Node)
        selectChildMainNode(mainProjectId, childNode, parents, addMainProjectChange, previousMainId, parentId);
    });
  }
};

const selectMainNode = (
  mainProjectId: ProjectId,
  hierarchy: HierarchyItem,
  parents: NodeId[],
  previousMainId?: ProjectId | null,
  setModifiedHierarchy?: (hierarchy: HierarchyItem) => void,
  parentId?: NodeId,
  addMainProjectChange?: (mainProjectChange: MainProjectChangeRequest) => void
) => {
  if (parentId !== undefined && setModifiedHierarchy && addMainProjectChange) {
    const newHierarchy = cloneDeep(hierarchy);
    selectChildMainNode(mainProjectId, newHierarchy, parents, addMainProjectChange, previousMainId, parentId);
    setModifiedHierarchy(newHierarchy);
  }
};

interface SavedDragEvent {
  id: number;
  isProject: boolean;
}

interface DraggedItem {
  item: number;
  dragStarted: number;
}

function HierarchyTree(props: HierarchyTreeProps): React.ReactElement {
  const {
    projectId,
    hierarchy,
    selectedParentNodeId,
    setSelectedParentNodeId,
    addedNodes,
    setAddedNodes,
    projectName,
    resetProjectHierarchy,
    disableBorders,
    advancedMode,
    setModifiedHierarchy,
    readOnly,
    addNewNode,
    moveProject,
    moveNode,
    removeNode,
    setHasErrors,
    addMainProjectChange,
    displayInactiveItems,
    drawOptions,
  } = props;
  const [renderedItems, setRenderedItems] = useState<HierarchyTreeItem[]>([]);
  const [showCreateNodeDialog, setShowCreateNodeDialog] = useState(false);
  const [parentNodeId, setParentNodeId] = useState<NodeId | undefined>(undefined);
  const [adaptedHierarchyTree, setAdaptedHierarchyTree] = useState<HierarchyTreeItem[]>([]);
  const [showLinkItemDialog, setShowLinkItemDialog] = useState(false);
  const [savedDropEvent, setSavedDropEvent] = useState<SavedDragEvent | undefined>(undefined);
  const [editableItem, setEditableItem] = useState<HierarchyTreeItem | undefined>(undefined);
  const [draggedItem, setDraggedItem] = useState<DraggedItem | undefined>(undefined);
  const lastDroppedItem = useRef<DraggedItem | undefined>();
  const client = useApolloClient() as ApolloClient<Record<string, unknown>>;

  const onSelectedNodeChange = useCallback(() => {
    let newRenderedItems = cloneDeep(adaptedHierarchyTree);

    if (!advancedMode) {
      addedNodes.forEach(node => {
        node.item.connectionDown = undefined;
      });
      addedNodes.forEach(node => {
        newRenderedItems = addItemIntoTree(newRenderedItems, node.parentNodeId ? node.parentNodeId : 0, node.item);
      });
    }

    if (selectedParentNodeId !== undefined && selectedParentNodeId !== null && newRenderedItems.length) {
      const parent = find(newRenderedItems, item => item.id === selectedParentNodeId);
      const newProjectItem = {
        id: projectId,
        description: projectName,
        childLevel: 0,
        connectionUp: true,
        isProject: true,
        isActive: true,
        itemType: HierarchyItemEnum.Project,
        extraConnections: [],
        hasChildren: false,
        projectTechnicalTypeId: EntityTypeId.ProjectWithoutRelations,
        projectTypeId: ProjectTypes.Actual,
        mainToNode:
          parent && parent.hierarchyItem && parent.hierarchyItem.mainProjectId === null
            ? [{ id: parent.id, parentDesc: parent.description }]
            : [],
      };
      newRenderedItems = addItemIntoTree(newRenderedItems, selectedParentNodeId, newProjectItem);
    }
    setRenderedItems(newRenderedItems);
  }, [selectedParentNodeId, projectId, addedNodes, adaptedHierarchyTree, projectName, advancedMode]);

  const onReset = useCallback(() => {
    setAdaptedHierarchyTree([]);
    setRenderedItems([]);
    setAdaptedHierarchyTree(buildHierarchyTree(hierarchy, !displayInactiveItems, drawOptions));
    if (!advancedMode && !readOnly) resetProjectHierarchy();
  }, [hierarchy, resetProjectHierarchy, advancedMode, readOnly, displayInactiveItems, drawOptions]);

  useEffect(onReset, [hierarchy, displayInactiveItems, drawOptions]);

  useEffect(onSelectedNodeChange, [selectedParentNodeId, projectId, addedNodes, adaptedHierarchyTree]);

  useEffect(() => setDraggedItem(undefined), [readOnly]);

  useEffect(() => {
    // Return the drag state to normal if user quickly drops the item out of bounds
    if (
      lastDroppedItem.current &&
      draggedItem &&
      lastDroppedItem.current.item === draggedItem.item &&
      lastDroppedItem.current.dragStarted - draggedItem.dragStarted < MINIMIZE_TIMEOUT
    ) {
      setDraggedItem(undefined);
    }
    lastDroppedItem.current = undefined;
  }, [draggedItem]);

  const validationError = (item: HierarchyItem) => {
    if (item.mainProjectId === null) {
      const mainProjectCandidates = item.childItems.filter(
        child => child.itemType === HierarchyItemEnum.Project || child.mainProjectId !== null
      );
      if (mainProjectCandidates.length > 0) {
        return "No main project selected";
      }
    }
    return undefined;
  };

  return (
    <Container
      disableBorders={disableBorders}
      onDrop={event => {
        event.preventDefault();
        const isProject = event.dataTransfer.getData("isProject");
        const itemId = parseInt(event.dataTransfer.getData("itemId"));
        setSavedDropEvent({ id: itemId, isProject: isProject === "true" });
        setShowLinkItemDialog(true);
      }}
      onDragOver={event => event.preventDefault()}
    >
      <TreeContainer>
        {renderedItems.map((item, index) => (
          <HierarchyItemView
            key={item.description + item.id}
            id={item.id}
            item={item}
            draggable={item.id !== DUMMY_HIERARCHY_ID && !!advancedMode && !readOnly}
            onDragStart={event => {
              drag(event, item, item.isProject, renderedItems, setDraggedItem);
            }}
            onDragOver={event => allowDrop(event, item.id, !item.isProject)}
            onDragLeave={event => dragLeave(event, item.projectTechnicalTypeId, item.projectTypeId)}
            onDrop={event => {
              setDraggedItem(undefined);
              drop(
                event,
                item.id,
                hierarchy,
                !item.isProject,
                item.projectTechnicalTypeId,
                item.projectTypeId,
                addMainProjectChange,
                setModifiedHierarchy,
                moveProject,
                moveNode
              );
            }}
            onDragEnd={() => {
              lastDroppedItem.current = { item: item.id, dragStarted: new Date().getTime() };
              setDraggedItem(undefined);
            }}
            mainToNode={item.mainToNode}
            containedMainProject={containsParentsMainProject(renderedItems, item)}
            description={`${item.description}${
              item.hierarchyItem &&
              item.hierarchyItem.mainProjectId &&
              !item.description.endsWith(" (" + item.hierarchyItem.mainProjectId + ")")
                ? ` (${item.hierarchyItem.mainProjectId})`
                : ""
            }`}
            childLevel={item.childLevel}
            connectionUp={item.connectionUp}
            connectionDown={item.connectionDown}
            selected={selectedParentNodeId === item.id}
            onSelected={id => {
              if (!advancedMode) {
                setSelectedParentNodeId(
                  id,
                  item.hierarchyItem && item.hierarchyItem.mainProjectId === null ? projectId : undefined
                );
              }
            }}
            isProject={item.isProject}
            isActive={item.isActive}
            itemType={item.itemType}
            extraConnections={item.extraConnections}
            highlighted={item.id === projectId}
            onCreateChildNode={
              item.id === DUMMY_HIERARCHY_ID
                ? undefined
                : parentId => {
                    setShowCreateNodeDialog(true);
                    setParentNodeId(parentId);
                  }
            }
            onDeleteNode={
              item.userAdded || (advancedMode && index !== 0 && !item.hasChildren)
                ? id => {
                    removeAddedNode(
                      id,
                      addedNodes,
                      setAddedNodes,
                      selectedParentNodeId,
                      setSelectedParentNodeId,
                      projectId
                    );
                    if (advancedMode && setModifiedHierarchy)
                      removeNodeFromHierarchy(
                        hierarchy,
                        item.id,
                        setModifiedHierarchy,
                        addMainProjectChange,
                        removeNode
                      );
                  }
                : undefined
            }
            userAdded={item.userAdded}
            readOnly={readOnly}
            onEditNode={advancedMode && item.id !== 0 ? () => setEditableItem(item) : undefined}
            onMainSelection={projectId => {
              if (item.isProject)
                selectMainProject(
                  hierarchy,
                  projectId,
                  getHierarchyParents(renderedItems, item),
                  getPreviousMainId(renderedItems, item.parentId),
                  item.parentId,
                  setModifiedHierarchy,
                  addMainProjectChange
                );
              else if (item.hierarchyItem && item.hierarchyItem.mainProjectId !== null)
                selectMainNode(
                  item.hierarchyItem.mainProjectId,
                  hierarchy,
                  getHierarchyParents(renderedItems, item),
                  getPreviousMainId(renderedItems, item.parentId),
                  setModifiedHierarchy,
                  item.parentId,
                  addMainProjectChange
                );
            }}
            mainProjectSelectable={
              (item.itemType === HierarchyItemEnum.Node && item.parentId !== undefined && item.hasChildren) ||
              (item.itemType === HierarchyItemEnum.Project &&
                item.projectTechnicalTypeId !== EntityTypeId.AutomaticAdjustment &&
                item.projectTechnicalTypeId !== EntityTypeId.ProjectAdjustment)
            }
            projectTechnicalTypeId={item.projectTechnicalTypeId}
            projectTypeId={item.projectTypeId}
            minimize={item.isProject && !readOnly && draggedItem !== undefined && item.id !== draggedItem.item}
            error={item.hierarchyItem ? validationError(item.hierarchyItem) : undefined}
          />
        ))}
      </TreeContainer>
      {(showCreateNodeDialog || editableItem) && (
        <CreateNodeDialog
          onCreate={nodeName => {
            setShowCreateNodeDialog(false);
            if (parentNodeId !== undefined) {
              const newNode: HierarchyTreeNode = {
                parentNodeId: parentNodeId,
                item: {
                  id: newTemporaryId(),
                  description: nodeName,
                  childLevel: 0,
                  connectionUp: true,
                  isProject: false,
                  isActive: true,
                  itemType: HierarchyItemEnum.Node,
                  extraConnections: [],
                  userAdded: true,
                  hasChildren: false,
                  projectTechnicalTypeId: null,
                  projectTypeId: null,
                },
              };
              let newAddedNodes = cloneDeep(addedNodes);
              // For now, only allow one created node. Subject to changes later on.
              if (newAddedNodes.length)
                removeAddedNode(
                  newAddedNodes[0].item.id,
                  newAddedNodes,
                  addedNodes => (newAddedNodes = addedNodes),
                  selectedParentNodeId,
                  setSelectedParentNodeId,
                  projectId
                );

              newAddedNodes.push(newNode);
              setAddedNodes(newAddedNodes);
              if (advancedMode)
                addItemIntoHierarchy(
                  newNode.item.id,
                  HierarchyItemEnum.Node,
                  parentNodeId,
                  hierarchy,
                  true,
                  addMainProjectChange,
                  setModifiedHierarchy,
                  addNewNode,
                  {
                    id: newNode.item.id,
                    description: newNode.item.description,
                    active: true,
                    itemType: HierarchyItemEnum.Node,
                    childItems: [],
                    mainProjectId: null,
                    userAdded: true,
                    projectTechnicalTypeId: null,
                    projectTypeId: null,
                    hiddenInHierarchyView: false,
                    relatingProjectId: null,
                    adjustmentRateInfo: null,
                    __typename: "HierarchyItem",
                  }
                );
            }
          }}
          onCancel={() => {
            setShowCreateNodeDialog(false);
            setEditableItem(undefined);
          }}
          onEdit={nodeName => {
            if (editableItem)
              editNode(editableItem.id, nodeName, hierarchy, moveNode, editableItem.parentId, setModifiedHierarchy);
            setEditableItem(undefined);
          }}
          editableItem={editableItem}
        />
      )}
      {showLinkItemDialog && savedDropEvent && (
        <LinkNodeDialog
          onLink={(nodeId, newNode) => {
            linkItem(
              savedDropEvent.id,
              savedDropEvent.isProject ? HierarchyItemEnum.Project : HierarchyItemEnum.Node,
              hierarchy,
              nodeId,
              projectId,
              client,
              newNode,
              addMainProjectChange,
              moveNode,
              moveProject,
              setModifiedHierarchy,
              setHasErrors,
              addNewNode
            ).then();
            setShowLinkItemDialog(false);
            setSavedDropEvent(undefined);
          }}
          onCancel={() => setShowLinkItemDialog(false)}
          isProject={savedDropEvent.isProject}
        />
      )}
    </Container>
  );
}

export default HierarchyTree;

const Container = styled.div<{ disableBorders?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  padding-bottom: 30px;
  background: white;
  ${({ disableBorders }) => (!disableBorders ? "border: 1px solid;" : "")};
`;

const TreeContainer = styled.div``;
