import React, { useCallback, useEffect, useState } from "react";
import { Action } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { useMutation } from "@apollo/client/react/hooks";

import Section from "../Section";
import {
  AppState,
  ProjectId,
  ProjectRoles,
  ProjectRolesInput,
  ProjectRolesSaveResult,
  ProjectRolesSectionInput,
  ProjectRolesVisibility,
} from "../../../../../common/types";
import { setProjectRoles } from "../../../../../actions/projectActions";
import { BasicDataSectionType, getEmptyProjectRolesSectionInput } from "../../../../../common/constants";
import { SAVE_PROJECT_ROLES } from "./queries";
import { pollForBasicDataSaveReady } from "../../queries";
import ViewDetails from "./ViewDetails";
import EditDetails from "./EditDetails";
import styled from "styled-components";
import { defaultGrey } from "../../../../../common/colors";

const mapStateToProps = (state: AppState) => {
  return {
    projectRolesInput: state.projectState.projectInput
      ? state.projectState.projectInput.projectRoles
      : getEmptyProjectRolesSectionInput(),
    inputErrors: state.projectState.projectErrors.roles,
  };
};
const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, void, Action>) => {
  return {
    setProjectRolesInput: (input: ProjectRolesSectionInput) => dispatch(setProjectRoles(input)),
  };
};

interface RolesSectionProps {
  projectId: ProjectId;
  sectionDetails: ProjectRoles | undefined;
  sectionVisibility: ProjectRolesVisibility;
  sectionEditable: boolean;
  editSectionType: BasicDataSectionType | undefined;
  setEditSectionType: (type: BasicDataSectionType | undefined) => void;
  refetchDetails: () => void;
}

const TYPE: BasicDataSectionType = BasicDataSectionType.Roles;

function RolesSection(
  props: RolesSectionProps & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
) {
  const {
    projectId,
    sectionDetails,
    sectionVisibility,
    sectionEditable,
    editSectionType,
    setEditSectionType,
    refetchDetails,
    projectRolesInput,
    inputErrors,
    setProjectRolesInput,
  } = props;
  const [editMode, setEditMode] = useState(false);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  // Once input is changed, clear error messages.
  useEffect(() => {
    if (errorMessages.length > 0) {
      setErrorMessages([]);
    }
  }, [projectRolesInput]);

  const [saveMutation, { loading: saving }] = useMutation<{
    saveProjectRoles: ProjectRolesSaveResult;
  }>(SAVE_PROJECT_ROLES);

  const { pollForReady, ready, loading: polling, error: pollingError } = pollForBasicDataSaveReady();

  useEffect(() => {
    if (ready && !polling && !pollingError) {
      setEditMode(false);
      setEditSectionType(undefined);
      setProjectRolesInput(getEmptyProjectRolesSectionInput());
      refetchDetails();
    } else if (!polling && pollingError) {
      setErrorMessages(errorMessages.concat([pollingError]));
    }
  }, [ready, polling, pollingError]);

  const save = useCallback(
    (input: ProjectRolesInput) => {
      saveMutation({
        variables: {
          projectId: projectId,
          roles: input,
        },
      })
        .then(({ data }) => {
          if (data && data.saveProjectRoles && data.saveProjectRoles.applicationModifiedDateTime) {
            pollForReady(projectId, data.saveProjectRoles.applicationModifiedDateTime);
          } else if (data && data.saveProjectRoles.errors) {
            setErrorMessages(errorMessages.concat(data.saveProjectRoles.errors));
          }
        })
        .catch(apolloError => {
          setErrorMessages(errorMessages.concat([apolloError.message]));
        });
    },
    [saveMutation]
  );

  const disabled = editSectionType !== undefined && editSectionType !== TYPE;

  return (
    <Section
      header="Roles"
      disabled={disabled}
      editMode={editMode}
      editable={sectionEditable && sectionDetails !== undefined}
      saving={saving || polling}
      saveEnabled={!projectRolesInput.pristine && !inputErrors}
      errors={errorMessages.length > 0 ? errorMessages : undefined}
      onEditClicked={() => {
        setEditSectionType(TYPE);
        setEditMode(true);
      }}
      onCancelEdit={() => {
        setEditSectionType(undefined);
        setEditMode(false);
        setProjectRolesInput(getEmptyProjectRolesSectionInput());
      }}
      onSaveClicked={() => {
        save(projectRolesInput.input);
      }}
    >
      <Container>
        {!sectionDetails ? undefined : editMode ? (
          <EditDetails projectId={projectId} roles={sectionDetails} rolesVisibility={sectionVisibility} />
        ) : (
          <ViewDetails roles={sectionDetails} rolesVisibility={sectionVisibility} />
        )}
      </Container>
    </Section>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(RolesSection);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-bottom: 0px;
  color: ${defaultGrey};
`;
