import React from "react";
import { parsePeriod, Period } from "../../../../../common/period";
import EditableMonth from "../../../../EditableMonth/EditableMonth";
import styled from "styled-components";

interface PeriodSelectProps {
  value: Period | null;
  setValue: (value: Period | null) => void;
  minPeriod: Period;
  maxPeriod: Period;
}

export function PeriodSelect(props: PeriodSelectProps) {
  const { value, setValue, minPeriod, maxPeriod } = props;
  const textValue = value === null ? "" : `${value.year}-${value.month}`;
  return (
    <Container>
      <EditableMonth
        value={textValue}
        onValueChanged={newTextValue => {
          const p = newTextValue !== null ? parsePeriod(newTextValue) : null;
          setValue(p);
        }}
        minPeriod={minPeriod}
        maxPeriod={maxPeriod}
      />
    </Container>
  );
}

const Container = styled.div`
  margin-left: 8px;
  margin-right: 12px;
`;
