import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { settingGreen, valmetDarkGreyTable, valmetGreyLight, valmetGreyTable } from "../../common/colors";
import { useMount } from "../../hooks/useMount";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { CopyMode } from "../sites/Project/ProjectRecognitions/types";

interface ArithmeticInputProps {
  value: number;
  formattedValue: string;
  decimals: number;
  tabIndex: number | undefined;
  valueCommit: (value: number) => void;
  isFocused: boolean;
  onFocus: () => void;
  onBlur: () => void;
  copyMode: CopyMode;
  onCopyCurrent: (value: number) => void;
}

type OperatorSym = "+" | "-" | "*" | "%";

const isOperatorSym = (c: string): c is OperatorSym => {
  return ["+", "-", "*", "%"].includes(c);
};

type ExprNumber = {
  kind: "num";
  str: string;
  value: number;
};

type ExprBinary = {
  kind: "bin";
  left: ExprNode;
  right: ExprNode | undefined;
  op: OperatorSym;
};

type ExprNode = ExprNumber | ExprBinary;

type Operator = { precedence: number };
const operators: { [key in OperatorSym]: Operator } = {
  "+": { precedence: 0 },
  "-": { precedence: 0 },
  "*": { precedence: 1 },
  "%": { precedence: 1 },
};

class Expression {
  text: string;
  valid: boolean;
  expr: ExprNode | undefined;

  constructor(text: string) {
    this.text = text;
    this.valid = false;
    this.parse();
  }

  evaluate(): number | undefined {
    function evaluate(node: ExprNode | undefined): number {
      if (node === undefined) return 0;
      switch (node.kind) {
        case "num":
          return node.value;
        case "bin": {
          const leftValue = evaluate(node.left);
          const rightValue = evaluate(node.right);
          switch (node.op) {
            case "+":
              return leftValue + rightValue;
            case "-":
              return leftValue - rightValue;
            case "*":
              return leftValue * rightValue;
            case "%":
              return leftValue * (rightValue / 100.0);
          }
        }
      }
    }

    return this.expr ? evaluate(this.expr) : undefined;
  }

  parse() {
    const text = this.text;
    let pos = 0;

    if (text.length == 0) {
      this.expr = undefined;
      return;
    }

    const isDigit = (c: string): boolean => {
      const c0 = "0".charCodeAt(0);
      const c9 = "9".charCodeAt(0);
      return c0 <= c.charCodeAt(0) && c.charCodeAt(0) <= c9;
    };

    function skipWhitespace(): void {
      while (pos < text.length && text.charAt(pos) == " ") {
        pos++;
      }
    }

    function parseNumber(): ExprNumber | undefined {
      skipWhitespace();

      const start = pos;
      if (text.charAt(pos) == "-") {
        pos++;
      } else if (text.charAt(pos) == "+") {
        pos++;
      } else if (!isDigit(text.charAt(pos))) {
        return undefined;
      }
      while (pos < text.length) {
        if (!isDigit(text.charAt(pos))) {
          break;
        }
        pos++;
      }
      if (pos < text.length && (text.charAt(pos) == "." || text.charAt(pos) == ",")) {
        pos++;
        while (pos < text.length) {
          if (!isDigit(text.charAt(pos))) {
            break;
          }
          pos++;
        }
      }
      const end = pos;
      const str = text.substring(start, end).replaceAll(",", ".");
      const value = parseFloat(str);
      return { kind: "num", str, value };
    }

    function peekOp(): OperatorSym | undefined {
      skipWhitespace();

      if (pos >= text.length) return undefined;

      const op = text.charAt(pos);
      if (isOperatorSym(op)) {
        return op;
      }
      return undefined;
    }

    function getOp(): OperatorSym | undefined {
      const op = peekOp();
      if (op) {
        pos++;
        return op;
      }
      return undefined;
    }

    // Precedence climbing algorithm
    function parseExpr1(left: ExprNode, min_precedence: number): ExprNode {
      const peek = peekOp();
      if (!peek) return left;

      let op_precedence = operators[peek].precedence;
      while (op_precedence >= min_precedence) {
        let op = getOp();

        let right: ExprNode | undefined = parseNumber();
        if (!right) return left;

        let nextOp = peekOp();
        if (nextOp) {
          let next_precedence = operators[nextOp].precedence;
          while (nextOp && next_precedence > op_precedence) {
            right = parseExpr1(right, op_precedence + (next_precedence > op_precedence ? 1 : 0));
            nextOp = peekOp();
            if (nextOp) next_precedence = operators[nextOp].precedence;
          }
        }

        if (op === undefined) throw new Error("op cannot be undefined at this point");
        //assert(op !== undefined, "op cannot be undefined at this point");
        left = {
          kind: "bin",
          left,
          right,
          op,
        };

        if (nextOp) {
          op = nextOp;
          op_precedence = operators[op].precedence;
        } else break;
      }
      return left;
    }

    function parseExpr(): ExprNode | undefined {
      const operand = parseNumber();
      if (!operand) return undefined;
      return parseExpr1(operand, 0);
    }

    this.expr = parseExpr();
    this.valid = !!this.expr;
  }
}

export default function ArithmeticInput(props: ArithmeticInputProps): React.ReactElement {
  const {
    value: originalValue,
    formattedValue,
    decimals,
    tabIndex: tb,
    valueCommit,
    isFocused,
    onFocus,
    onBlur,
    copyMode,
    onCopyCurrent,
  } = props;

  const [value, setValue] = useState(originalValue);
  const [stringValue, setStringValue] = useState(formattedValue);
  const expressionEvaluated = useRef(true);
  const valueRef = useRef(originalValue);

  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const valueChanged = (text: string) => {
    const expr = new Expression(text);
    expressionEvaluated.current = expr.valid && expr.expr?.kind === "num";
    const newValue = expr.evaluate();
    if (newValue !== undefined && isFinite(newValue)) {
      setValue(newValue);
      valueRef.current = newValue;
    }
    setStringValue(text);
  };

  function exit() {
    valueRef.current = originalValue;
    setStringValue(formattedValue);
    stopListening();
    onBlur();
  }

  function save() {
    valueCommit(valueRef.current);
    stopListening();
    onBlur();
  }

  function handleClicks(ev: UIEvent): void {
    if (!containerRef.current) return;

    if (!containerRef.current.contains(ev.target as Node)) {
      // Clicked outside, save and lose focus
      if (inputRef.current) {
        const str = valueRef.current.toFixed(decimals);
        inputRef.current.value = str;
        setStringValue(str);
      }
      save();
    }
  }

  function addOperator(op: OperatorSym) {
    if (!inputRef.current) return;

    inputRef.current.focus();
    const value = inputRef.current.value;

    //console.log("add op ", op, value);
    if (op == "+" && valueRef.current === 0) {
      inputRef.current.value = "";
      valueChanged("");
    } else if (op == "-" && valueRef.current === 0) {
      inputRef.current.value = "-";
      valueChanged("-");
    } else {
      const newValue = value.trim() + " " + op + " ";
      valueChanged(newValue);
      inputRef.current.value = newValue;
      const end = newValue.length;
      inputRef.current.setSelectionRange(end, end);
    }
  }

  function doReturn() {
    const evaluated = expressionEvaluated.current;

    if (inputRef.current) {
      valueChanged(valueRef.current.toFixed(decimals));
    }
    if (!evaluated) return;

    if (expressionEvaluated.current) {
      save();
    }
  }

  function handleKeys(ev: KeyboardEvent): void {
    switch (ev.key) {
      case "Enter":
      case "Return":
      case "=":
        ev.preventDefault();
        doReturn();
        break;
      case "Tab":
        if (inputRef.current) {
          inputRef.current.value = valueRef.current.toFixed(decimals);
          setStringValue(inputRef.current.value);
        }

        save();
        break;
      case "Escape":
        ev.preventDefault();
        exit();
        break;
      default:
        {
          if (!isOperatorSym(ev.key)) break;
          ev.preventDefault();

          const op = ev.key;
          addOperator(op);
        }
        break;
    }
  }

  useEffect(() => {
    if (!isFocused) return;

    document.addEventListener("keydown", handleKeys);
    return () => document.removeEventListener("keydown", handleKeys);
  }, [isFocused]);

  function startListening(): void {
    onFocus();
    document.addEventListener("mousedown", handleClicks, true);

    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
        inputRef.current.selectionStart = 0;
        inputRef.current.selectionEnd = -1;
      }
    }, 100);
  }
  function stopListening(): void {
    document.removeEventListener("mousedown", handleClicks, true);
  }

  useMount(
    () => {
      //
    },
    () => {
      stopListening();
    }
  );

  const tabIndex = 0;

  return (
    <Container ref={containerRef}>
      {isFocused ? (
        <>
          <OperationsToolbar
            onEscClick={() => exit()}
            onOperationClick={op => addOperator(op)}
            onEnterClick={() => doReturn()}
            copyMode={copyMode}
            onCopyCurrent={() => {
              onCopyCurrent(valueRef.current);
            }}
          />
          <Input
            tabIndex={tabIndex}
            value={stringValue}
            ref={inputRef}
            onChange={ev => valueChanged(ev.currentTarget.value)}
            onFocus={ev => ev.currentTarget.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" })}
          />
          <ResultPreviewRoot>
            {!expressionEvaluated.current && <ResultPreview>= {valueRef.current.toFixed(decimals)}</ResultPreview>}
          </ResultPreviewRoot>
        </>
      ) : (
        <Number tabIndex={tabIndex} onClick={() => startListening()} onFocus={() => startListening()}>
          {formattedValue}
        </Number>
      )}
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: baseline;
`;

const Input = styled.input`
  text-align: right;
  border: solid 1px ${settingGreen};
  padding: 5px;
  width: 180px;
`;

const Number = styled.div`
  text-align: right;
  border: solid 1px ${settingGreen};
  padding: 5px;
  width: 180px;
`;

const ResultPreviewRoot = styled.div`
  position: absolute;
  right: 0;
`;
const ResultPreview = styled.div`
  position: absolute;
  left: 0;
  padding: 5px;
  background: white;
  border: solid #ccc 1px;
  z-index: 1;
`;

type OperationsToolbarProps = {
  onEscClick: () => void;
  onOperationClick: (op: OperatorSym) => void;
  onEnterClick: () => void;
  copyMode: CopyMode;
  onCopyCurrent: () => void;
};

const OperationsToolbar = ({
  onEscClick,
  onOperationClick,
  onEnterClick,
  copyMode,
  onCopyCurrent,
}: OperationsToolbarProps) => {
  return (
    <ToolbarContainer>
      <ButtonsContainer>
        <OperationsContainer>
          <EscButton tabIndex={-1} title={"Esc"} onClick={onEscClick}>
            Esc
          </EscButton>
          <ArithmeticContainer>
            <ArithmeticButton tabIndex={-1} title={"Plus"} onClick={() => onOperationClick("+")}>
              +
            </ArithmeticButton>
            <ArithmeticButton tabIndex={-1} title={"Minus"} onClick={() => onOperationClick("-")}>
              -
            </ArithmeticButton>
            <ArithmeticButton tabIndex={-1} title={"Percentage"} onClick={() => onOperationClick("%")}>
              %
            </ArithmeticButton>
            <EnterButton tabIndex={-1} title={"Enter"} onClick={onEnterClick}>
              Enter
            </EnterButton>
          </ArithmeticContainer>
        </OperationsContainer>
        {copyMode !== "all" && onCopyCurrent !== undefined && (
          <CopyButton tabIndex={-1} onClick={onCopyCurrent} title="Copy this value to following periods">
            <FontAwesomeIcon icon={faCopy} />
            <FontAwesomeIcon icon={faArrowDown} />
          </CopyButton>
        )}
      </ButtonsContainer>
    </ToolbarContainer>
  );
};

const button = css`
  &:hover {
    background: ${valmetDarkGreyTable};
  }
`;

const ToolbarContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  //left: 0;
  top: -32px;
  z-index: 100;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  padding-bottom: 1px;
`;

const OperationsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const EscButton = styled.button`
  border: 1px solid ${valmetGreyLight};
  background: ${valmetGreyTable};
  border-radius: 4px;
  font-size: 12px;
  ${button}
`;

const ArithmeticContainer = styled.div`
  border: 1px solid ${valmetGreyLight};
  background: ${valmetGreyTable};
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const EnterButton = styled.button`
  border: none;
  border-left: 1px solid ${valmetGreyLight};
  background: none;
  font-size: 12px;
  line-height: 22px;
  ${button}
`;

const ArithmeticButton = styled.button`
  padding: 3px;
  border: none;
  background: none;
  ${button}
`;

const CopyButton = styled.button`
  border: 1px solid ${valmetGreyLight};
  background: ${valmetGreyTable};
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  column-gap: 5px;
  align-items: center;
  ${button}
`;
