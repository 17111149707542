import React from "react";

import Tooltip from "../../../../Tooltip";
import { ProfitPITProgressValues } from "./constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons/faInfoCircle";
import styled from "styled-components";
import { ProjectIntegrations } from "../../../../../common/types";

export const PITProgressTooltip = ({ integrations }: { integrations: ProjectIntegrations }) => (
  <Tooltip
    offset={{ x: 100, y: -30 }}
    component={
      <PITProgressContainer>
        <PITProgressRow>
          <b>PIT Progress Changes:</b>
        </PITProgressRow>
        {integrations.profitPITProgressLog &&
          integrations.profitPITProgressLog.map(log => {
            const found = ProfitPITProgressValues.find(item => item.id === log.profitPITProgressId);
            return (
              <PITProgressRow key={log.profitPITProgressId}>
                {log.percentage} - {found && found.description} - {new Date(log.modifiedAt).toLocaleDateString()}
              </PITProgressRow>
            );
          })}
      </PITProgressContainer>
    }
  >
    PIT Progress: {integrations.profitPITProgressLog && <FontAwesomeIcon icon={faInfoCircle} />}
  </Tooltip>
);

const PITProgressContainer = styled.div`
  padding: 5px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const PITProgressRow = styled.div`
  display: flex;
  flex-direction: row;
`;
