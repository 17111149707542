import React, { useState } from "react";
import styled from "styled-components";

import { StatusChangeData, EstimateType } from "../../../../common/types";
import { disabledGrey, valmetGreyLight } from "../../../../common/colors";

import FullscreenSpinner from "../../../FullscreenSpinner";
import { saveEstimateCodeAndStatus, saveProposedBudgetEstimateCodeAndStatus } from "./queries";
import { useApolloClient } from "@apollo/client/react/hooks";
import { ApolloClient } from "@apollo/client";
import { ActionButton, ButtonContainer } from "../../../../common/components";

export interface StatusChangeDialogProps {
  changeData: StatusChangeData;
  close: () => void;
  onSaved: () => void;
}

const getColumnName = (estimateType: EstimateType) => {
  switch (estimateType) {
    case EstimateType.AsSold:
      return "As Sold";
    case EstimateType.Estimate:
      return "Current Estimate";
    case EstimateType.OriginalBudget:
      return "Original Budget";
    case EstimateType.RevisedBudget:
      return "Revised Budget";
    default:
      return estimateType;
  }
};

function StatusChangeDialog(props: StatusChangeDialogProps): React.ReactElement | null {
  const { changeData, close, onSaved } = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const client = useApolloClient() as ApolloClient<Record<string, unknown>>;

  return (
    <Wrapper>
      {loading ? <FullscreenSpinner text="Saving" /> : null}
      <ContentContainer>
        <Title>Change column status</Title>
        {changeData.relatedStatuses ? (
          <Info>{`Are you sure you want to add Proposed Budget Change values to${changeData.relatedStatuses.map(
            status => ` "${getColumnName(status.estimateType)}"`
          )}?`}</Info>
        ) : (
          <Info>{`Are you sure you want to change "${getColumnName(changeData.estimateType)}" column status to "${
            changeData.status
          }"?`}</Info>
        )}
        <ButtonContainer>
          <ActionButton
            onClick={() => {
              if (changeData.relatedStatuses)
                saveProposedBudgetEstimateCodeAndStatus(client, changeData, setLoading, onSaved, setError);
              else saveEstimateCodeAndStatus(client, changeData, setLoading, onSaved, setError);
            }}
            disabled={false}
          >
            Continue
          </ActionButton>
          <ActionButton onClick={() => close()}>Cancel</ActionButton>
        </ButtonContainer>
        {error && <ErrorContainer>{error}</ErrorContainer>}
      </ContentContainer>
    </Wrapper>
  );
}

export default StatusChangeDialog;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  width: 100%;
  background-color: ${disabledGrey};
  z-index: 1200;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 25px;
  padding-right: 25px;
  color: ${valmetGreyLight};
  width: 450px;
  align-items: flex-start;
`;

const Title = styled.div`
  font-size: 24px;
`;

const Info = styled.div`
  font-size: 16px;
  margin-top: 20px;
`;

const ErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
  font-weight: bold;
`;
