import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import styled from "styled-components";
import { checkLoginStatus } from "./common/restApi";
import Header from "./components/Header";
import RouteContainer from "./components/RouteContainer";
import UpdateNotificationContainer from "./components/UpdateNotificationContainer";

const App: React.FC = () => {
  checkLoginStatus();

  return (
    <AppWrapper>
      <UpdateNotificationContainer>
        <Router>
          <Header />
          <RouteContainer />
        </Router>
      </UpdateNotificationContainer>
    </AppWrapper>
  );
};

export default App;

const AppWrapper = styled.div`
  font-family: "Open Sans", sans-serif;
  input,
  select,
  textarea,
  button {
    font-family: inherit;
  }
`;
