import "./index.css";

import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  defaultDataIdFromObject,
  ServerError,
  from,
} from "@apollo/client";
import { HttpLink } from "@apollo/client/link/http";
import { RetryLink } from "@apollo/client/link/retry";
import { onError } from "@apollo/client/link/error";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import {
  OrganisationKey,
  ProjectCostComponentItem,
  ProjectCostItem,
  ProjectCostsByCurrencyCode,
  ProjectEstimateCodeCostItem,
} from "./common/types";
import { setDevCookie } from "./common/utils";
import introspectionResult from "./generated/fragment-types.json";
import store from "./init/store";
import * as serviceWorker from "./serviceWorker";
import { enableMapSet, setAutoFreeze } from "immer";
import { initLogger, initLogging } from "./logging";
import { ProjectRecognitionsResult } from "./components/sites/Project/ProjectRecognitions/types";
import { ProjectInfo } from "./components/sites/Main/MyProjects/types";
import { initializeUserPreferencesState } from "./actions/userPreferencesActions";

// https://immerjs.github.io/immer/map-set/
// ⚠ Since version 6 support for Maps and Sets has to be enabled explicitly by calling enableMapSet() once when starting your application.
enableMapSet();
setAutoFreeze(false);

// generated by Fragment Matcher plugin

const possibleTypes = introspectionResult.__schema.types.reduce((result, tpe) => {
  result[tpe.name] = tpe.possibleTypes.map(t => t.name);
  return result;
}, {} as Record<string, string[]>);

const cache = new InMemoryCache({
  possibleTypes,
  dataIdFromObject: object => {
    //Special rule for some types since many of the same ID are returned within same query
    switch (object.__typename) {
      case "ProjectCostsByCurrencyCode": {
        const currencyObj = (object as unknown) as ProjectCostsByCurrencyCode;
        return `${currencyObj.currencyId}_${currencyObj.currencyCode}`;
      }
      case "ProjectCostItem": {
        const costObj = (object as unknown) as ProjectCostItem;
        return `${costObj.id}_${costObj.estimateCodeId}_${costObj.currencyId}_${costObj.currencyCode}`;
      }
      case "ProjectEstimateCodeCostItem": {
        const estimateCodeObj = (object as unknown) as ProjectEstimateCodeCostItem;
        return `${estimateCodeObj.estimateCode}_${estimateCodeObj.currencyId}_${estimateCodeObj.currencyCode}`;
      }
      case "OrganisationKey": {
        const organisationKeyObj = (object as unknown) as OrganisationKey;
        return `${organisationKeyObj.id}_${organisationKeyObj.description}`;
      }
      case "ProjectCostComponentItem": {
        const componentObj = (object as unknown) as ProjectCostComponentItem;
        return `${componentObj.activityId}_${componentObj.id}`;
      }
      case "ProjectRecognitionsResult": {
        // The same query results are used in view and edit mode, but different columns are returned. If we do not make
        // caching treat these as different objects, the cached values for viewing columns could be used in edit mode
        // and vice versa. At the moment there is no field on the result object to differentiate between edit or viewing
        // mode, other than the columns them selves. So we check if we have any edit columns, ending with "_edit", and
        // use this information to differentiate between view and edit mode results.
        const result = (object as unknown) as ProjectRecognitionsResult;
        const isEdit = result.grouped.some(y =>
          y.subGroups.some(q =>
            q.subGroups.some(p => p.columns.some(cell => cell.columnId.toString().endsWith("_edit")))
          )
        );
        return "ProjectRecognitionsResult" + result.id + "-" + result.itemType + "_" + (isEdit ? "E" : "V");
      }
      case "ProjectInfo": {
        const project = (object as unknown) as ProjectInfo;
        return "ProjectInfo-" + project.id;
      }
      default: {
        //const id = defaultDataIdFromObject(object);
        //console.log("ID (" + object.__typename + "):", id);
        //return id;
        return defaultDataIdFromObject(object);
      }
    }
  },
});

const backendUri = process.env.REACT_APP_LOCAL_DEV
  ? "http://localhost:9090/graphql"
  : `${window.location.protocol}//${window.location.host}/graphql`;

const httpLink = new HttpLink({
  uri: `${backendUri}`,
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    );

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const logger = initLogger(__filename);

const retryLink = new RetryLink({
  delay: {
    initial: 2000, // wait for 2 seconds before the first retry.
    max: 20000, // wait for maximum 20 seconds before any retry.
    jitter: true, // delays between attempts should be randomized.
  },
  attempts: {
    max: 5,
    retryIf: (error, operation) => {
      const isMutation = operation.query.definitions.some(
        def => def.kind === "OperationDefinition" && def.operation === "mutation"
      );
      if (isMutation) return false;

      if (error instanceof Error && error.name === "ServerError") {
        const serverError = error as ServerError;

        const isBadRequest = serverError.statusCode === 400;
        if (isBadRequest) return false;
      }

      logger.debug("Retrying operation " + operation.operationName + "...");
      return true;
    },
  },
});

const client = new ApolloClient({
  cache: cache,
  link: from([errorLink, retryLink, httpLink]),
});

initLogging(client);

if (process.env.NODE_ENV === "development") {
  setDevCookie();
}

initializeUserPreferencesState(client).then(store.dispatch);

ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <App />
    </Provider>
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
