import React, { useMemo } from "react";
import styled from "styled-components";
import { filterGreen, settingGreen, valmetGreyBorder, valmetGreyLight } from "../../../../../../common/colors";
import { TextButton, ValueButton } from "../../../../../../common/components";
import { includes } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { bottomListing } from "../utils";

export interface HierarchyDropdownItem {
  id: string;
  desc: string;
  description: string;
  depth: number;
  childItems: HierarchyDropdownItem[] | undefined;
}

export interface HierarchyDropdownProps {
  selection: string | string[];
  hierarchy: HierarchyDropdownItem[];
  openItems: string[];
  openItem: (id: string) => void;
  closeItem: (id: string) => void;
  disabledValueIds?: string[];
  onValueSelected: (value: string | null, description: string | null) => void;
  inputWidth?: string;
  maxResults?: number;
  loading?: boolean;
  minSelectableDepth?: number; // Starts from which depth, items are selectable. If this is undefined, only bottom items are selectable.
}

function HierarchyDropdown(props: HierarchyDropdownProps): React.ReactElement {
  const {
    selection,
    hierarchy,
    openItems,
    openItem,
    closeItem,
    disabledValueIds,
    onValueSelected,
    inputWidth,
    maxResults,
    loading,
    minSelectableDepth,
  } = props;

  function filterDisplayValues(items: HierarchyDropdownItem[]): HierarchyDropdownItem[] {
    return items.flatMap(parentItem =>
      openItems.includes(parentItem.id) && !!parentItem.childItems
        ? [parentItem].concat(filterDisplayValues(parentItem.childItems))
        : [parentItem]
    );
  }

  const displayedValues: HierarchyDropdownItem[] = useMemo(() => filterDisplayValues(hierarchy), [
    hierarchy,
    openItems,
  ]);

  const checkedByChildren = (item: HierarchyDropdownItem, selection: string[]): string[] => {
    if (item.childItems === undefined || item.childItems.length === 0)
      return selection.includes(item.id) ? [item.id] : [];
    const checked: string[] = item.childItems.flatMap(childItem => checkedByChildren(childItem, selection));
    if (item.childItems.every(childItem => checked.includes(childItem.id))) {
      return [...checked, item.id];
    } else return checked;
  };
  const checkedValues: string[] = useMemo(() => {
    if (typeof selection === "string") return [selection];
    else {
      return hierarchy.flatMap(item => checkedByChildren(item, selection));
    }
  }, [hierarchy, selection]);

  const selectable = (value: HierarchyDropdownItem): boolean => {
    return (
      !value.childItems ||
      value.childItems.length === 0 ||
      (minSelectableDepth !== undefined && minSelectableDepth <= value.depth)
    );
  };

  return (
    <DropdownContent>
      <ValueContainer minWidth={inputWidth} maxVisibleItems={maxResults}>
        {displayedValues.length > 0 ? (
          displayedValues.map(value => (
            <ValueButton
              key={value.id}
              disabled={disabledValueIds && disabledValueIds.includes(value.id)}
              onClick={() => {
                !value.childItems || !value.childItems.length
                  ? onValueSelected(value.id, value.description)
                  : includes(openItems, value.id)
                  ? closeItem(value.id)
                  : openItem(value.id);
              }}
              childLevel={value.depth}
            >
              {value.childItems && !!value.childItems.length && (
                <FontAwesomeIcon
                  icon={includes(openItems, value.id) ? faChevronUp : faChevronDown}
                  size="1x"
                  color={valmetGreyLight}
                />
              )}
              {selectable(value) && (
                <Checkbox
                  type="checkbox"
                  checked={checkedValues.includes(value.id)}
                  onChange={event => {
                    if (value.childItems === undefined || value.childItems.length === 0) {
                      onValueSelected(value.id, value.description);
                    } else {
                      const checked = event.target.checked;
                      bottomListing(value)
                        .filter(bottomItem =>
                          checked ? !selection.includes(bottomItem.id) : selection.includes(bottomItem.id)
                        )
                        .map(bottomItem => onValueSelected(bottomItem.id, bottomItem.description));
                    }
                  }}
                  onClick={event => event.stopPropagation()}
                />
              )}
              <ValueDescription>{value.desc}</ValueDescription>
            </ValueButton>
          ))
        ) : loading ? (
          <TextButton>Loading...</TextButton>
        ) : (
          <TextButton>No results</TextButton>
        )}
      </ValueContainer>
    </DropdownContent>
  );
}

export default HierarchyDropdown;

const DropdownContent = styled.div<{ extraMargin?: boolean }>`
  background: white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  position: absolute;
  display: flex;
  flex-direction: column;

  border: 1px solid ${valmetGreyBorder};
  z-index: 500;
  button:hover {
    background: ${filterGreen};
  }
  margin-top: 24px;
`;

const ValueContainer = styled.div<{ minWidth?: string; maxVisibleItems?: number }>`
  overflow-y: auto;
  ${({ minWidth }) => minWidth && `min-width: ${minWidth};`}
  ${({ maxVisibleItems }) => maxVisibleItems && `max-height: ${maxVisibleItems * 29}px;`}
`;

const ValueDescription = styled.div`
  margin-left: 10px;
`;

const Checkbox = styled.input`
  margin-left: 10px;
`;
