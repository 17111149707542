import React from "react";
import styled from "styled-components";
import { defaultGrey, valmetGreyHeader } from "../../../../../common/colors";
import { Column } from "../../../../../common/columnsTypes";

export interface ColumnsSectionProps {
  columns: Column[];
  onColumnSelected: (columnSet: Column) => void;
}

function ColumnsSection(props: ColumnsSectionProps): React.ReactElement {
  const { columns, onColumnSelected } = props;
  return (
    <Container>
      <Title>Available</Title>
      <ContentContainer>
        {columns.map(column => (
          <ColumnRow key={column.id} onClick={() => onColumnSelected(column)}>
            {column.name}
          </ColumnRow>
        ))}
      </ContentContainer>
    </Container>
  );
}

export default ColumnsSection;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: 12px;
  color: ${defaultGrey};
`;

const ColumnRow = styled.button`
  display: flex;
  flex-direction: row;
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 0px;
`;

const Title = styled.div`
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 6px;
  color: ${valmetGreyHeader};
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 150px;
  width: 175px;
`;
