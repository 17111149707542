import { NodeId, ProjectId } from "./types";

const routes = {
  LOGIN: "/login",
  MAIN: "/",
  PROJECTS: "/projects",
  PROJECT: "/project",
  NODE: "/node",
  CREATEPROJECT: "/create-project",
  ESTIMATE_SCHEDULE: "/estimate-schedule",
  LOADING_STATUS: "/loading-status",
};

export default {
  ...routes,
  project: (projectId: ProjectId) => `${routes.PROJECT}/${projectId}`,
  node: (nodeId: NodeId) => `${routes.NODE}/${nodeId}`,
};
