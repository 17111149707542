import React, { useEffect, useRef, useState } from "react";
import { orderBy } from "lodash";
import styled from "styled-components";
import { useQuery } from "@apollo/client/react/hooks";
import { SearchProject, SearchProjectData } from "../../../../common/types";
import SearchDropdown from "../../../Header/Search/SearchDropdown";
import { gql } from "@apollo/client";

const SEARCH_PROJECTS = gql`
  query SearchRelatingProjects($searchQuery: [String!]!) {
    searchProjects(searchQuery: $searchQuery) {
      projects {
        id
        description
      }
      moreResultsAvailable
    }
  }
`;

const resetSearch = (
  setSearchInput: React.Dispatch<React.SetStateAction<string>>,
  setSearchData: React.Dispatch<React.SetStateAction<SearchProjectData | undefined>>
) => {
  //setSearchInput("");
  setSearchData(undefined);
};

interface RelatingProjectSectionProps {
  relatingProject: SearchProject | null;
  setRelatingProject: (relatingProject: SearchProject) => void;
}

function RelatingProjectSection(props: RelatingProjectSectionProps) {
  const { relatingProject, setRelatingProject } = props;
  const [searchInput, setSearchInput] = useState((relatingProject && relatingProject.description) || "");
  const [searchInputTerms, setSearchInputTerms] = useState<string[]>([]);
  const [searchData, setSearchData] = useState<SearchProjectData | undefined>(undefined);
  const timeout = useRef<number>();

  const { loading } = useQuery<SearchProjectData>(SEARCH_PROJECTS, {
    variables: {
      searchQuery: searchInputTerms,
    },
    skip: searchInputTerms.length === 0,
    onCompleted: data => {
      setSearchData(data);
    },
  });

  useEffect(() => {
    const terms = searchInput
      .trim()
      .split(" ")
      .filter(term => term.trim().length > 0);
    if (relatingProject && searchInput === `${relatingProject.id} ${relatingProject.description}`) return;
    if (terms.length !== searchInputTerms.length || !terms.every((term, index) => searchInputTerms[index] === term)) {
      clearTimeout(timeout.current);
      timeout.current = setTimeout(() => {
        setSearchInputTerms(terms);
      }, 1000);
    }
    return () => clearTimeout(timeout.current);
  }, [searchInput, searchInputTerms, setSearchInputTerms, relatingProject]);

  const onResultSelected = (searchProject: SearchProject) => {
    setRelatingProject(searchProject);
    setSearchInput(`${searchProject.id} ${searchProject.description}`);
    setSearchData(undefined);
  };

  return (
    <ContentSection>
      <SectionTitle>Search for relating project:</SectionTitle>
      <SearchInput
        placeholder="Search"
        value={searchInput}
        onChange={e => setSearchInput(e.target.value)}
        onBlur={() => {
          if (relatingProject && searchInput !== `${relatingProject.id} ${relatingProject.description}`)
            setSearchInput(`${relatingProject.id} ${relatingProject.description}`);
        }}
      />
      {(loading || searchData) && (
        <SearchDropdown
          searchProjects={searchData ? orderBy(searchData.searchProjects.projects, ["id"], ["desc"]) : []}
          resetSearch={() => resetSearch(setSearchInput, setSearchData)}
          onResultSelected={project => onResultSelected(project)}
          moreAvailable={!!searchData && searchData.searchProjects.moreResultsAvailable}
          loading={loading}
        />
      )}
    </ContentSection>
  );
}

export default RelatingProjectSection;

const ContentSection = styled.div`
  margin-top: 20px;
`;

const SectionTitle = styled.div`
  font-size: 14px;
  margin-bottom: 4px;
`;

const SearchInput = styled.input`
  display: block;
  font-size: 16px;
  min-width: 75px;
  width: 90%;
  font-size: 14px;
  font-weight: bold;
`;
