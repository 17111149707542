import React from "react";
import styled from "styled-components";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { valmetGreyBorder, valmetGreyLight, settingGreen } from "../../../../common/colors";
import routes from "../../../../common/routes";

type ProjectItemProps = RouteComponentProps & {
  id?: number;
  name?: string;
};

function ProjectItem(props: ProjectItemProps): React.ReactElement {
  const { id, name, history } = props;
  return (
    <Container>
      <ProjectCard onClick={() => history.push(`${routes.PROJECT}/${id}`)}>
        <ButtonContainer>
          <InfoContainer>{`${id} - ${name}`}</InfoContainer>
          <LinkContainer>{"View >"}</LinkContainer>
        </ButtonContainer>
      </ProjectCard>
    </Container>
  );
}

export default withRouter(ProjectItem);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-right: 20px;
  background: white;
  border-top: solid 4px ${valmetGreyBorder};
`;

const ProjectCard = styled.button`
  border: 0;
  background: transparent;
  cursor: pointer;
  color: ${valmetGreyLight};
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 70px;
  width: 250px;
  justify-content: space-between;
`;

const InfoContainer = styled.div`
  text-align: left;
  margin-top: 5px;
`;

const LinkContainer = styled.div`
  text-align: right;
  margin-bottom: 5px;
  color: ${settingGreen};
`;
