import React from "react";
import { cloneDeep, find } from "lodash";
import styled from "styled-components";
import {
  OrganisationKey,
  OrganisationKeyId,
  ProjectDescriptionEditInformation,
  ProjectInformationInput,
  ProjectType,
  ProjectVirtualType,
} from "../../../../common/types";
import { IconButton } from "../../../../common/components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboardCheck } from "@fortawesome/free-solid-svg-icons";
import { settingGreen } from "../../../../common/colors";

const DataItem = styled.div<{ emphasize?: boolean }>`
  font-weight: bold;
  font-size: ${({ emphasize }) => (emphasize ? "22px" : "14px")};
`;

export const getDataItem = (value?: string | string[] | number | null, emphasize?: boolean) => {
  return <DataItem emphasize={emphasize}>{value ? value : "-"}</DataItem>;
};

export const onOff = (value: boolean) => (value ? "On" : "Off");
export const yesNo = (value: boolean) => (value ? "Yes" : "No");

export const getProjecTypeDataItem = (
  projectTypes: ProjectType[],
  typeId: number | null,
  defaultType: string | null
) => {
  const type = find(projectTypes, type => type.id === typeId);
  if (type !== undefined) {
    return getDataItem(type.description);
  }
  return getDataItem(defaultType);
};

export const combineToString = (xs: (string | number | null | undefined)[]) => {
  const filtered = xs.filter(v => v !== undefined && v !== null && v !== "");
  if (filtered.length === 0) return undefined;
  return filtered.join(" ");
};

const isBusinessGroupAutBL = (
  selectedBusinessGroupId: OrganisationKeyId | null,
  businessGroups: OrganisationKey[]
): boolean => {
  if (selectedBusinessGroupId === null) return false;

  const businessGroup = businessGroups.find(grp => grp.id === selectedBusinessGroupId);
  return !!businessGroup && businessGroup.description.includes("AS BL"); // AUT business line was renamed to AS
};

export const visibilityCheckWithOverride = (
  defaultValue: boolean,
  sectionInput: ProjectInformationInput,
  field: string,
  businessGroups: OrganisationKey[],
  projectVirtualType: ProjectVirtualType
) => {
  const isAutBL = isBusinessGroupAutBL(sectionInput.organisation.businessGroupId, businessGroups);

  switch (field) {
    case "autSiteCodeId":
      switch (projectVirtualType) {
        case ProjectVirtualType.Manual:
        case ProjectVirtualType.LnProject:
        case ProjectVirtualType.LnServiceContract:
        case ProjectVirtualType.LnServiceOrder:
        case ProjectVirtualType.LnSalesOrder:
          return isAutBL;
        default:
          return defaultValue;
      }
    case "autReportingCountryId":
      switch (projectVirtualType) {
        case ProjectVirtualType.Manual:
        case ProjectVirtualType.LnProject:
        case ProjectVirtualType.LnServiceContract:
        case ProjectVirtualType.LnServiceOrder:
        case ProjectVirtualType.LnSalesOrder:
          return isAutBL;
        default:
          return defaultValue;
      }
    default:
      return defaultValue;
  }
};

export const checkAutReportingCountryEditable = (
  sectionInput: ProjectInformationInput,
  businessGroups: OrganisationKey[],
  projectVirtualType: ProjectVirtualType
) => {
  const isAutBL = isBusinessGroupAutBL(sectionInput.organisation.businessGroupId, businessGroups);

  switch (projectVirtualType) {
    case ProjectVirtualType.Manual:
    case ProjectVirtualType.LnProject:
    case ProjectVirtualType.LnServiceContract:
    case ProjectVirtualType.LnServiceOrder:
    case ProjectVirtualType.LnSalesOrder:
      return isAutBL;
    default:
      return false;
  }
};

export const checkAutSiteCodeEditable = (
  sectionInput: ProjectInformationInput,
  businessGroups: OrganisationKey[],
  projectVirtualType: ProjectVirtualType
) => {
  const isAutBL = isBusinessGroupAutBL(sectionInput.organisation.businessGroupId, businessGroups);

  switch (projectVirtualType) {
    case ProjectVirtualType.Manual:
      return isAutBL;
    case ProjectVirtualType.LnProject:
    case ProjectVirtualType.LnServiceContract:
    case ProjectVirtualType.LnServiceOrder:
    case ProjectVirtualType.LnSalesOrder:
    default:
      return false;
  }
};

export function editClone<T>(x: T, fn: (x: T) => void): T {
  const newX = cloneDeep(x); // deep clone to remove possible 'readonly' attributes from fields
  fn(newX);
  return newX;
}

export function inheritOrgValue(
  sourceOrgId: OrganisationKeyId | null,
  sourceOrgs: OrganisationKey[],
  targetOrgs: OrganisationKey[]
): OrganisationKeyId | null {
  if (sourceOrgId == null) return null;
  const source = sourceOrgs.find(org => org.id == sourceOrgId);
  if (!source) return null;
  const target = targetOrgs.find(org => org.code == source.code);
  return target ? target.id : null;
}

export function CopiedIcon(title: string) {
  return (
    <IconButton fontSize={"14px"}>
      <FontAwesomeIcon icon={faClipboardCheck} size="1x" color={settingGreen} title={title} />
    </IconButton>
  );
}

export function readProjectDescription(description: ProjectDescriptionEditInformation) {
  if (description.__typename === "FreetextProjectDescription") {
    return description.desc;
  } else {
    return `${description.stem} / ${description.postfix}`;
  }
}
