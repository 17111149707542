import { gql } from "@apollo/client";

export const ESTIMATION_SCHEDULE = gql`
  query GetEstimationSchedule {
    estimationSchedule {
      years {
        year
        periods {
          period
          schedule
        }
      }
    }
  }
`;

export const POLL_ESTIMATION_SCHEDULE = gql`
  query PollEstimationSchedule($year: Int!, $modifiedDate: ISOLocalDateTime!) {
    pollEstimationSchedule(year: $year, applicationModifiedDateTime: $modifiedDate) {
      ready
    }
  }
`;

export const SAVE_ESTIMATION_SCHEDULE = gql`
  mutation SaveEstimationSchedule($input: EstimationScheduleInput!) {
    saveEstimationSchedule(estimationScheduleInput: $input) {
      year
      modifiedDate
      error
    }
  }
`;
