import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";

const parseFloatRegExp = /^-?\d+(\.\d{1,6})?$/;
const parseIntRegExp = /^-?\d+$/;

interface NumberInputProps {
  id?: string;
  type: "int" | "float";
  decimals?: number;
  value: string;
  disabled?: boolean;
  onChange?: (value: number) => void;
  onError?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
  className?: string;
  onHasError?: (id: string, hasError: boolean) => void;
  tooltip?: string;
  disabledAutoSelect?: boolean;
  onKeyPress?: (ev: React.KeyboardEvent<HTMLInputElement>) => void;
  tabIndex?: number;
  autoFocus?: boolean;
  forceResetValue?: boolean;
}

function regExpForType(type: string): RegExp {
  return type === "float" ? parseFloatRegExp : parseIntRegExp;
}

export default (props: NumberInputProps) => {
  const {
    id,
    type,
    className,
    disabled,
    onChange,
    onFocus,
    onBlur,
    onError,
    onHasError,
    value: initialValue,
    decimals = 2,
    tooltip,
    disabledAutoSelect,
    onKeyPress,
    tabIndex,
    autoFocus,
    forceResetValue,
  } = props;
  const [focused, setFocused] = useState(false);

  const [{ value, error }, setState] = useState({
    value: type === "float" ? parseFloat(initialValue).toFixed(decimals) : initialValue.replace(/\s/g, ""),
    error: !regExpForType(type).test(
      type === "float" ? parseFloat(initialValue).toFixed(decimals) : initialValue.replace(/\s/g, "")
    ),
  });

  const onDataChanged = useCallback(() => {
    if (!focused || forceResetValue) {
      setState({
        value: type === "float" ? parseFloat(initialValue).toFixed(decimals) : initialValue.replace(/\s/g, ""),
        error: !regExpForType(type).test(
          type === "float" ? parseFloat(initialValue).toFixed(decimals) : initialValue.replace(/\s/g, "")
        ),
      });
    }
  }, [initialValue, type, focused, decimals, forceResetValue]);

  useEffect(onDataChanged, [initialValue, type, forceResetValue]);

  return (
    <Input
      id={id}
      className={className}
      value={value}
      disabled={disabled}
      error={error}
      title={tooltip}
      onFocus={event => {
        if (!disabledAutoSelect) event.target.select();
        setFocused(true);
        onFocus && onFocus();
      }}
      onChange={
        onChange &&
        (ev => {
          let newValue = ev.target.value.trim();
          newValue = newValue.replace(",", ".");
          newValue = newValue.replace(/\s/g, "");
          const ok = regExpForType(type).test(newValue);

          if (ok) {
            const parsed = type === "float" ? parseFloat(newValue) : parseInt(newValue);
            onChange(isNaN(parsed) ? 0 : parsed);
          } else if (onError) {
            onError();
          }

          setState({ value: newValue, error: !ok });

          if (onHasError && id) {
            onHasError(id, !ok);
          }
        })
      }
      onBlur={() => {
        setFocused(false);
        if (onBlur) {
          if (type === "float" && regExpForType(type).test(value)) {
            setState({
              error,
              value: parseFloat(value).toFixed(decimals),
            });
          }
          onBlur();
        }
      }}
      onKeyPress={ev => onKeyPress && onKeyPress(ev)}
      tabIndex={tabIndex}
      autoFocus={autoFocus}
    />
  );
};

const Input = styled.input<{ error: boolean }>`
  ${({ error }) => `color: ${error ? "red" : "inherit"} !important;`}
`;
