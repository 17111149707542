import React from "react";
import {
  CostComponentEditRow,
  CurrencyScenario,
  EstimateStatuses,
  ProjectCostEditColumnId,
  ProjectCostValues,
} from "../../../../../common/types";
import { EDITABLE_COST_COMPONENTS } from "../constants";
import { DataCell, DataRow, getNumericValue, getValue, isColumnEditable, isEditingBlocked } from "./util";
import styled from "styled-components";
import NumberInput from "../../../../TableDataCell/NumberInput";
import {
  costEditingBlockedYellow,
  costEditingBlockedYellowBorder,
  settingGreen,
  valmetGreyLight,
} from "../../../../../common/colors";
import { CostsHeader } from "../types";

export interface CostComponentRowProps {
  activityId: string;
  row: CostComponentEditRow;
  childLevel: number;
  estimateStatuses: EstimateStatuses;
  userCanEdit: boolean;
  selectedCurrencyScenario: CurrencyScenario;
  onChangeRow: (newRow: CostComponentEditRow) => void;
  errors: { [field: string]: boolean };
  setErrors: (errors: { [field: string]: boolean }) => void;
  summaryHeaders: CostsHeader[];
}

function CostComponentRow(props: CostComponentRowProps): React.ReactElement {
  const {
    activityId,
    row,
    childLevel,
    estimateStatuses,
    userCanEdit,
    selectedCurrencyScenario,
    onChangeRow,
    errors,
    setErrors,
    summaryHeaders,
  } = props;
  const values: ProjectCostValues = {
    ...row.initialItem.values,
    ...row.editValues,
  };

  const onChange = (field: string, value: number) => {
    onChangeRow({ ...row, editValues: { ...row.editValues, [field]: value } });
  };

  const subtract = (a: number | null | undefined, b: number | null | undefined): number => (a || 0) - (b || 0);

  return (
    <DataRow key={row.initialItem.id} childLevel={childLevel} isComponent={true}>
      <DataCell>{row.initialItem.description}</DataCell>
      {summaryHeaders.map((value, i) => (
        <DataCell
          key={`${row.initialItem.id}_${i}`}
          warning={
            value.id === "ETC" && getNumericValue(subtract(values["currentEstimate"], values["committed"])) < -1000
          }
        >
          {EDITABLE_COST_COMPONENTS.includes(value.id as ProjectCostEditColumnId) &&
          isColumnEditable(
            value.id === EDITABLE_COST_COMPONENTS[2]
              ? estimateStatuses[EDITABLE_COST_COMPONENTS[1]].estimateValue
              : estimateStatuses[value.id].estimateValue,
            userCanEdit,
            selectedCurrencyScenario,
            value.id,
            values[value.id]
          ) ? (
            <EditInput
              id={`${activityId}_${row.initialItem.id}_${value.id}`}
              value={getValue(values[value.id])}
              onChange={v => onChange(value.id, v)}
              //onBlur={() => updateSummaryValues(costComponentEditRows)}
              onHasError={(id, hasError) => setErrors({ ...errors, [id]: hasError })}
              type="int"
              widthLimit={value.id === "estimatedHours" || value.id === "actualHours" ? 30 : 80}
              disabled={isEditingBlocked(value.id, row)}
              editingBlocked={isEditingBlocked(value.id, row)}
              tooltip={isEditingBlocked(value.id, row) ? "Editing blocked" : undefined}
            />
          ) : value.id === "estimateChange" ? (
            <ReadOnlyValue
              value={getValue(subtract(values["currentEstimate"], values["prevEstimate"]))}
              disabled={true}
            />
          ) : value.id === "ETC" ? (
            <ReadOnlyValue value={getValue(subtract(values["currentEstimate"], values["committed"]))} disabled={true} />
          ) : (
            <ReadOnlyValue value={getValue(values[value.id])} disabled={true} />
          )}
        </DataCell>
      ))}
    </DataRow>
  );
}

export default CostComponentRow;

const ReadOnlyValue = styled.input<{ widthLimit?: number }>`
  color: ${valmetGreyLight};
  font-size: 10px;
  text-align: right;
  max-width: 80px;
  background-color: white;
  border: 0;
`;

const EditInput = styled(NumberInput)<{ widthLimit?: number; editingBlocked: boolean }>`
  color: ${valmetGreyLight};
  font-size: 10px;
  text-align: right;
  border: solid 1px ${({ editingBlocked }) => (editingBlocked ? costEditingBlockedYellowBorder : settingGreen)};
  ${({ widthLimit }) => widthLimit && `max-width:${widthLimit}px;`};
  ${({ editingBlocked }) => (editingBlocked ? `background-color: ${costEditingBlockedYellow};` : undefined)}
`;
