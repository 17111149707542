import { produce } from "immer";
import { remove, slice } from "lodash";
import { HistoryState, HistoryAction, HistoryProjectAction } from "../common/types";

import { ADD_PROJECT_HISTORY, TOGGLE_HISTORY_VIEW } from "../actions/historyActions";

export const initState = {
  projectHistory: [],
  historyViewOpen: false,
};

export default function historyReducer(state: Readonly<HistoryState> = initState, action: HistoryAction): HistoryState {
  switch (action.type) {
    case ADD_PROJECT_HISTORY:
      return produce(state, newState => {
        const historyAction = action as HistoryProjectAction;
        remove(newState.projectHistory, item => item.id === historyAction.data.id);
        newState.projectHistory.unshift(historyAction.data);
        if (newState.projectHistory.length > 20) {
          newState.projectHistory = slice(newState.projectHistory, 0, 20);
        }
      });
    case TOGGLE_HISTORY_VIEW:
      return produce(state, newState => {
        newState.historyViewOpen = !state.historyViewOpen;
      });
    default:
      return state;
  }
}
