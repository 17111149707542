import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { FilterId, FilterSetting, FilterValues } from "../../../../common/types";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { valmetGreyLight } from "../../../../common/colors";
import ResetButton from "./ResetButton";
import { IconButton } from "../../../../common/components";

interface FilterSectionProps {
  filterItem: FilterSetting;
  filterValues: FilterValues;
  onFilterSelected: (filter: FilterId, value: string | number | undefined) => void;
  onResetFilter: (id: FilterId) => void;
}

function TextFilter(props: FilterSectionProps): React.ReactElement {
  const { filterItem, filterValues, onFilterSelected, onResetFilter } = props;
  const [input, setInput] = useState("");

  useEffect(() => {
    setInput(filterValues[filterItem.id] ? (filterValues[filterItem.id] as string) : "");
  }, [filterValues, filterItem.id]);

  const buttonId = `${filterItem.id}ActionButton`;

  return (
    <FilterItem key={filterItem.id}>
      <FilterSelection extraPadding={true}>{filterItem.name}</FilterSelection>
      <FilterSelectionColumn>
        <FilterSelection>
          <ResetButton
            onButtonClick={() => onResetFilter(filterItem.id)}
            show={filterValues[filterItem.id] !== undefined}
            id={buttonId}
          />
          <FilterInput
            type="text"
            placeholder="Search"
            value={input}
            onFocus={() => {
              setInput("");
            }}
            onBlur={event => {
              if (!event.relatedTarget || (event.relatedTarget as HTMLElement).id !== buttonId) {
                setInput(filterValues[filterItem.id] ? (filterValues[filterItem.id] as string) : "");
              }
            }}
            onChange={event => {
              setInput(event.target.value);
            }}
            onKeyPress={event => {
              if (event.key === "Enter") {
                onFilterSelected(filterItem.id, input);
                event.currentTarget.blur();
              }
            }}
          />
          <IconButton onClick={() => onFilterSelected(filterItem.id, input)} title={"Set project filter"} id={buttonId}>
            <FontAwesomeIcon icon={faSearch} size="1x" color={valmetGreyLight} />
          </IconButton>
        </FilterSelection>
      </FilterSelectionColumn>
    </FilterItem>
  );
}

export default TextFilter;

const FilterItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 4px;
  padding-top: 1px;
`;

const FilterSelection = styled.div<{ extraPadding?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 3px;
  padding-left: ${({ extraPadding }) => (extraPadding ? "13" : "0")}px;
`;

const FilterSelectionColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const FilterInput = styled.input`
  border: 0;
  font-style: italic;
  font-size: 10px;
`;
