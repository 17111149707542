import { produce } from "immer";
import { CostsState, ProjectCostAction, SetEstimateCodeAction, SetRefetchPendingAction } from "../common/types";
import {
  SET_ESTIMATE_CODE,
  START_COSTS_EDITING,
  END_COSTS_EDITING,
  SET_REFETCH_PENDING,
} from "../actions/costsActions";

export const initState: CostsState = {
  estimateCode: undefined,
  estimateCodeProjectId: undefined,
  costsEditModeOn: false,
  costsEdits: [],
  refetchPending: false,
};

export default function costsReducer(state: Readonly<CostsState> = initState, action: ProjectCostAction): CostsState {
  switch (action.type) {
    case SET_ESTIMATE_CODE:
      return produce(state, newState => {
        newState.estimateCode = (action as SetEstimateCodeAction).code;
        newState.estimateCodeProjectId = (action as SetEstimateCodeAction).projectId;
      });
    case START_COSTS_EDITING:
      return produce(state, newState => {
        newState.costsEditModeOn = true;
        newState.costsEdits = [];
      });
    case END_COSTS_EDITING:
      return produce(state, newState => {
        newState.costsEditModeOn = false;
        newState.costsEdits = [];
      });
    case SET_REFETCH_PENDING:
      return produce(state, newState => {
        newState.refetchPending = (action as SetRefetchPendingAction).refetchPending;
      });

    default:
      return state;
  }
}
