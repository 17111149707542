import * as React from "react";
import onClickOutside, { HandleClickOutside, InjectedOnClickOutProps } from "react-onclickoutside";
import styled from "styled-components";
import { faChevronDown, faCircle, faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { defaultGrey, editBlue, filterGreen, valmetGreyBorder } from "../../../../../../common/colors";
import { EstimateCodeStatusId, StatusLightValue } from "../../../../../../common/types";
import { CollapseButton, IconButton, ValueButton } from "../../../../../../common/components";

type Props = {
  value?: EstimateCodeStatusId;
  onValueSelected: (statusCode: EstimateCodeStatusId) => void;
  statusLights: StatusLightValue[];
  canEditLocked: boolean;
  onCopy?: () => void;
  locked?: boolean;
  lockedReason?: string;
};

type State = {
  isOpen: boolean;
};

const COLUMN_STATUSES: [EstimateCodeStatusId, string][] = [
  [EstimateCodeStatusId.Draft, "Draft"],
  [EstimateCodeStatusId.Approved, "Approved"],
];

const createLight = (status: StatusLightValue, index: number) => {
  return (
    <IconContainer key={"status-" + index}>
      <FontAwesomeIcon icon={faCircle} size="1x" color={status.color} title={status.message} />
    </IconContainer>
  );
};

class StatusDropdown extends React.Component<
  Props & InjectedOnClickOutProps & HandleClickOutside<React.MouseEventHandler>,
  State
> {
  state: State = { isOpen: false };

  toggle = (value: EstimateCodeStatusId | undefined): void => {
    if (this.props.canEditLocked || value !== EstimateCodeStatusId.Locked) {
      if (this.state.isOpen) {
        this.props.disableOnClickOutside();
      } else {
        this.props.enableOnClickOutside();
      }

      this.setState(state => ({ isOpen: !state.isOpen }));
    }
  };

  handleClickOutside = (): void => {
    this.props.disableOnClickOutside();
    this.setState({ isOpen: false });
  };

  render(): React.ReactElement {
    const { value, onValueSelected, statusLights, canEditLocked, onCopy, locked, lockedReason } = this.props;

    const statuses = (value !== EstimateCodeStatusId.Locked ? COLUMN_STATUSES : [COLUMN_STATUSES[0]]).filter(
      ([status]) => status !== value
    );

    const canEdit = !locked && (value !== EstimateCodeStatusId.Locked || canEditLocked);

    return (
      <Wrapper>
        {value === EstimateCodeStatusId.Draft && onCopy && !locked && (
          <IconButton
            title={"Copy estimate code values"}
            onClick={() => {
              if (onCopy) onCopy();
            }}
            fontSize={"10px"}
          >
            <FontAwesomeIcon icon={faCopy} size="1x" color={editBlue} />
          </IconButton>
        )}
        <ContentContainer onClick={() => this.toggle(value)}>
          {statusLights.map((status, index) => createLight(status, index))}
          <StatusCodeTextContainer>{value !== undefined ? value : "No status selected"}</StatusCodeTextContainer>
          {canEdit ? (
            <CollapseButton title="Select status" fontSize="12px">
              <FontAwesomeIcon icon={faChevronDown} size="1x" color={defaultGrey} />
            </CollapseButton>
          ) : (
            <CollapseButton
              title={lockedReason ? "Not editable: " + lockedReason : "Not editable"}
              fontSize="12px"
              disabled={true}
            >
              <FontAwesomeIcon icon={faChevronDown} size="1x" color={valmetGreyBorder} />
            </CollapseButton>
          )}
        </ContentContainer>
        {this.state.isOpen && (
          <DropdownContent onClick={this.handleClickOutside} hasContent={true}>
            {!canEdit ? (
              <CannotEditTooltip>{lockedReason || "Can't edit this column"}</CannotEditTooltip>
            ) : (
              statuses.map(([status, statusName], i) => (
                <ValueButton key={i} onClick={() => onValueSelected(status)}>
                  {statusName}
                </ValueButton>
              ))
            )}
          </DropdownContent>
        )}
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 120px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: context-menu;
  font-size: 12px;
`;

const StatusCodeTextContainer = styled.div`
  text-align: right;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-style: italic;
  padding-right: 1px;
`;

const DropdownContent = styled.div<{ hasContent: boolean }>`
  background: white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  position: absolute;
  display: flex;
  flex-direction: column;
  min-width: 85px;
  ${({ hasContent }) => {
    if (hasContent) {
      return `border: 1px solid ${valmetGreyBorder}`;
    }
  }};
  z-index: 500;
  button:hover {
    background: ${filterGreen};
  }
  margin-top: 18px;
`;

const CannotEditTooltip = styled.div`
  padding: 4px;
  max-width: 200px;
  text-align: center;
`;

const IconContainer = styled.div`
  font-size: 8px;
  margin-right: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default onClickOutside<any, Props>(StatusDropdown);
