import {
  CommentInput,
  PollReadyResult,
  AddRecognitionPlanCommentResult,
  ISOLocalDateTime,
} from "../../../../common/types";

import { ApolloClient } from "@apollo/client";
import { GraphQLError } from "graphql";
import { gql } from "@apollo/client";
import { useCallback, useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client/react/hooks";

export const GET_PROJECT_CURRENCIES = gql`
  query GetProjectDetails($projectId: ProjectId!) {
    projectDetails(projectId: $projectId) {
      projectDetails {
        projectCurrency {
          contractCurrency
          legalEntityCurrency
          projectCurrency
          revenueRecognitionCurrency
          externalReportingCurrency
          internalReportingCurrency
        }
      }
    }
  }
`;

export const CommentFields = gql`
  fragment CommentFields on ListingProjectCellComment {
    fieldName
    comments {
      type
      value
      content
      currencyScenario
      createdBy
      createdDateTime
    }
  }
`;

export const FreezingFields = gql`
  fragment FreezingFields on ListingProjectCellFreezing {
    fieldName
    value
    createdBy
    createdDateTime
  }
`;

export const AdditionalInfoFields = gql`
  fragment AdditionalInfoFields on RecognitionCellAdditionalInfo {
    message {
      fieldId
      message
    }
  }
`;

const YearlyProjectRecognitionFields = gql`
  fragment YearlyProjectRecognitionFields on ByYearRecognition {
    period
    columns {
      columnId
      value {
        ... on StringValue {
          value
        }
        ... on RecPercent {
          nsPercent
          costPercent
          pmPercent
        }
        ... on Recognition {
          ns
          cogs
          pm
          warCost
        }
        ... on EstRecPercent {
          nsPercent
          cogsPercent
          pmPercent
        }
        ... on EstRec {
          ns
          cogs
          pm
          wipCosts
        }
        ... on YTDRecPercentage {
          actNSPercentage
          actCogsPercentage
          actPMPercentage
        }
        ... on NetSalesCostsPM {
          ns
          cost
          pm
          pmPercent
          warCost
        }
        ... on Estimate {
          ns
          cost
          pm
          pmPercent
          warCost
          nsOverrun
          costOverrun
          warCostOverrun
        }
        ... on NetSalesCogsPM {
          ns
          cogs
          pm
          pmPercent
        }
        ... on Backlog {
          obl
          cost
          pm
          pmPercent
        }
        ... on VarToPrevEst {
          nsVar
          costVar
        }
        ... on SalesPrice {
          asld
          obud
          rbud
          prevEst
          est
        }
        ... on YTDRec {
          actNS
          actCogs
          actPM
        }
        ... on AsSoldBudgetEstimated {
          asld
          obud
          rbud
          est
        }
        ... on EstimatedCommittedVariance {
          est
          etc
          totalComm
        }
        ... on OrdersReceived {
          or
          orPeriod
          orPTD
          oblCorrYTD
          adjOnOR
        }
        ... on ActualCostsCommitments {
          actualCosts
          hardComm
          softComm
          wipCosts
        }
        ... on Invoicing {
          invoicing
          paymRec
        }
        ... on InvoicingExt {
          invoicing
          receivable
          paymRec
          paymRecPercentage
          ovdRec
        }
        ... on PeriodRec {
          ns
          cogs
          pm
          pmPercent
        }
        ... on Rates {
          orRate
          hedgeRate
          avgRate
          endRate
          erRate
          irRate
        }
        ... on RecWarranty {
          periodCogs
          ytdCogs
          prevCogs
          cogs
        }
        ... on PM {
          period
          asld
          rbud
          ptd
          ytd
        }
        ... on PMCurrentYear {
          period
          ptd
          ytd
        }
        ... on WarrCostVar {
          period
          toASLD
          toRBUD
          YTD
        }
        ... on Contingency {
          asld
          obud
          rbud
          prevEst
          est
        }
        ... on CommCosts {
          commCosts
          actualCosts
          hardComm
          softComm
          wipCosts
        }
        ... on ContingencyVar {
          period
          toASLD
          YTD
        }
      }
      comments {
        ...CommentFields
      }
      freezings {
        ...FreezingFields
      }
      additionalInfo {
        ...AdditionalInfoFields
      }
    }
  }
  ${CommentFields}
  ${FreezingFields}
  ${AdditionalInfoFields}
`;

const QuarterlyProjectRecognitionFields = gql`
  fragment QuarterlyProjectRecognitionFields on ByQuarterRecognition {
    period
    columns {
      columnId
      value {
        ... on StringValue {
          value
        }
        ... on RecPercent {
          nsPercent
          costPercent
          pmPercent
        }
        ... on Recognition {
          ns
          cogs
          pm
          warCost
        }
        ... on EstRecPercent {
          nsPercent
          cogsPercent
          pmPercent
        }
        ... on EstRec {
          ns
          cogs
          pm
          wipCosts
        }
        ... on YTDRecPercentage {
          actNSPercentage
          actCogsPercentage
          actPMPercentage
        }
        ... on NetSalesCostsPM {
          ns
          cost
          pm
          pmPercent
          warCost
        }
        ... on Estimate {
          ns
          cost
          pm
          pmPercent
          warCost
          nsOverrun
          costOverrun
          warCostOverrun
        }
        ... on NetSalesCogsPM {
          ns
          cogs
          pm
          pmPercent
        }
        ... on Backlog {
          obl
          cost
          pm
          pmPercent
        }
        ... on VarToPrevEst {
          nsVar
          costVar
        }
        ... on SalesPrice {
          asld
          obud
          rbud
          prevEst
          est
        }
        ... on YTDRec {
          actNS
          actCogs
          actPM
        }
        ... on AsSoldBudgetEstimated {
          asld
          obud
          rbud
          est
        }
        ... on EstimatedCommittedVariance {
          est
          etc
          totalComm
        }
        ... on OrdersReceived {
          or
          orPeriod
          orPTD
          oblCorrYTD
          adjOnOR
        }
        ... on ActualCostsCommitments {
          actualCosts
          hardComm
          softComm
          wipCosts
        }
        ... on Invoicing {
          invoicing
          paymRec
        }
        ... on InvoicingExt {
          invoicing
          receivable
          paymRec
          paymRecPercentage
          ovdRec
        }
        ... on PeriodRec {
          ns
          cogs
          pm
          pmPercent
        }
        ... on Rates {
          orRate
          hedgeRate
          avgRate
          endRate
          erRate
          irRate
        }
        ... on RecWarranty {
          periodCogs
          ytdCogs
          prevCogs
          cogs
        }
        ... on PM {
          period
          asld
          rbud
          ptd
          ytd
        }
        ... on PMCurrentYear {
          period
          ptd
          ytd
        }
        ... on WarrCostVar {
          period
          toASLD
          toRBUD
          YTD
        }
        ... on Contingency {
          asld
          obud
          rbud
          prevEst
          est
        }
        ... on CommCosts {
          commCosts
          actualCosts
          hardComm
          softComm
          wipCosts
        }
        ... on ContingencyVar {
          period
          toASLD
          YTD
        }
      }
      comments {
        ...CommentFields
      }
      freezings {
        ...FreezingFields
      }
      additionalInfo {
        ...AdditionalInfoFields
      }
    }
  }
  ${CommentFields}
  ${FreezingFields}
  ${AdditionalInfoFields}
`;

const MonthlyProjectRecognitionFields = gql`
  fragment MonthlyProjectRecognitionFields on ByMonthRecognition {
    period
    columns {
      columnId
      value {
        ... on StringValue {
          value
        }
        ... on RecPercent {
          nsPercent
          costPercent
          pmPercent
        }
        ... on Recognition {
          ns
          cogs
          pm
          warCost
        }
        ... on EstRecPercent {
          nsPercent
          cogsPercent
          pmPercent
        }
        ... on EstRec {
          ns
          cogs
          pm
          wipCosts
        }
        ... on YTDRecPercentage {
          actNSPercentage
          actCogsPercentage
          actPMPercentage
        }
        ... on NetSalesCostsPM {
          ns
          cost
          pm
          pmPercent
          warCost
        }
        ... on Estimate {
          ns
          cost
          pm
          pmPercent
          warCost
          nsOverrun
          costOverrun
          warCostOverrun
        }
        ... on NetSalesCogsPM {
          ns
          cogs
          pm
          pmPercent
        }
        ... on Backlog {
          obl
          cost
          pm
          pmPercent
        }
        ... on VarToPrevEst {
          nsVar
          costVar
        }
        ... on SalesPrice {
          asld
          obud
          rbud
          prevEst
          est
        }
        ... on YTDRec {
          actNS
          actCogs
          actPM
        }
        ... on AsSoldBudgetEstimated {
          asld
          obud
          rbud
          est
        }
        ... on EstimatedCommittedVariance {
          est
          etc
          totalComm
        }
        ... on OrdersReceived {
          or
          orPeriod
          orPTD
          oblCorrYTD
          adjOnOR
        }
        ... on ActualCostsCommitments {
          actualCosts
          hardComm
          softComm
          wipCosts
        }
        ... on Invoicing {
          invoicing
          paymRec
        }
        ... on InvoicingExt {
          invoicing
          receivable
          paymRec
          paymRecPercentage
          ovdRec
        }
        ... on PeriodRec {
          ns
          cogs
          pm
          pmPercent
        }
        ... on Rates {
          orRate
          hedgeRate
          avgRate
          endRate
          erRate
          irRate
        }
        ... on RecWarranty {
          periodCogs
          ytdCogs
          prevCogs
          cogs
        }
        ... on PM {
          period
          asld
          rbud
          ptd
          ytd
        }
        ... on PMCurrentYear {
          period
          ptd
          ytd
        }
        ... on WarrCostVar {
          period
          toASLD
          toRBUD
          YTD
        }
        ... on Contingency {
          asld
          obud
          rbud
          prevEst
          est
        }
        ... on CommCosts {
          commCosts
          actualCosts
          hardComm
          softComm
          wipCosts
        }
        ... on ContingencyVar {
          period
          toASLD
          YTD
        }
      }
      comments {
        ...CommentFields
      }
      freezings {
        ...FreezingFields
      }
      additionalInfo {
        ...AdditionalInfoFields
      }
    }
  }
  ${CommentFields}
  ${FreezingFields}
  ${AdditionalInfoFields}
`;

const MonthlyProjectRecognitionFieldsForEditing = gql`
  fragment MonthlyProjectRecognitionFieldsForEditing on ByMonthRecognition {
    period
    columns {
      columnId
      value {
        ... on SalesCostsEdit {
          salesPrice
          ns
          cost
          cont
          war
        }
        ... on EstRecognitionEdit {
          ns
          cogs
          war
          wipCosts
        }
        ... on RecognitionEdit {
          ns
          cogs
          war
        }
        ... on InvoicingEdit {
          sent
          paid
          due
        }
        ... on CommittedCostsEdit {
          act
          hardCom
          softCom
        }
        ... on PTDVariancesEdit {
          netSalesVarianceTotal
          costVarianceTotal
        }
        ... on OrdersReceivedEdit {
          orPeriod
          adjOnOR
        }
        ... on RatesEdit {
          erRate
          irRate
        }
        ... on RelatedEstRecEdit {
          recNs
          estNs
          nsPercent
        }
      }
      comments {
        ...CommentFields
      }
      freezings {
        ...FreezingFields
      }
      additionalInfo {
        ...AdditionalInfoFields
      }
    }
  }
  ${CommentFields}
  ${FreezingFields}
  ${AdditionalInfoFields}
`;

export const GET_PROJECTS_RECOGNITIONS = gql`
  query GetProjectRecognitions(
    $id: HierarchyItemId!
    $columns: [ProjectColumn!]!
    $currencyId: CurrencyId!
    $itemType: HierarchyItemEnum!
  ) {
    projectRecognitions(id: $id, columns: $columns, currencyId: $currencyId, itemType: $itemType) {
      id
      itemType
      grouped {
        ...YearlyProjectRecognitionFields
        subGroups {
          ...QuarterlyProjectRecognitionFields
          subGroups {
            ...MonthlyProjectRecognitionFields
          }
        }
      }
      asSoldPeriod
      budgetPeriod
      oblPeriod
      completePeriod
      editType
      rateCurrencyPairs {
        from
        to
      }
    }
  }
  ${YearlyProjectRecognitionFields}
  ${QuarterlyProjectRecognitionFields}
  ${MonthlyProjectRecognitionFields}
`;

export const GET_RECOGNITION_EDIT_INFORMATION = gql`
  query GetRecognitionEditInformation($projectId: ProjectId!) {
    projectRecognitionsEditInformation(projectId: $projectId) {
      editType
      asSoldPeriod
      budgetPeriod
      oblPeriod
      recCompletionPeriod
      editablePcsProject
      migratedFromLegacySystem
      additionalAdjustments
      revenueMethodIsStraightLine
      isPTProjectWithEstRecFromRelated
    }
  }
`;

export const GET_PROJECTS_RECOGNITIONS_FOR_EDITING = gql`
  query GetProjectRecognitionsForEditing(
    $id: HierarchyItemId!
    $projectId: ProjectId!
    $columns: [ProjectColumn!]!
    $currencyId: CurrencyId!
    $itemType: HierarchyItemEnum!
  ) {
    projectRecognitions(id: $id, columns: $columns, currencyId: $currencyId, itemType: $itemType) {
      id
      itemType
      grouped {
        ...YearlyProjectRecognitionFields
        subGroups {
          ...QuarterlyProjectRecognitionFields
          subGroups {
            ...MonthlyProjectRecognitionFieldsForEditing
          }
        }
      }
      rateCurrencyPairs {
        from
        to
      }
    }
    projectRecognitionsEditInformation(projectId: $projectId) {
      editType
      asSoldPeriod
      budgetPeriod
      oblPeriod
      recCompletionPeriod
      editablePcsProject
      migratedFromLegacySystem
      additionalAdjustments
      revenueMethodIsStraightLine
      isPTProjectWithEstRecFromRelated
    }
  }
  ${YearlyProjectRecognitionFields}
  ${QuarterlyProjectRecognitionFields}
  ${MonthlyProjectRecognitionFieldsForEditing}
`;

export const SAVE_RECOGNITIONS = gql`
  mutation SaveRecognitionsPlan($projectId: ProjectId!, $changes: [RecognitionPlanChange!]!) {
    saveRecognitionPlan(projectId: $projectId, changes: $changes) {
      error
      applicationModifiedDateTime
    }
  }
`;

const POLL_RECOGNITIONS_SAVE_READY = gql`
  query PollForRecognitionsSaveReady($projectId: ProjectId!, $applicationModifiedDateTime: ISOLocalDateTime!) {
    pollForRecognitionPlanSaveReady(projectId: $projectId, applicationModifiedDateTime: $applicationModifiedDateTime) {
      ready
    }
  }
`;

export const pollForRecPlanSaveReady = (
  pollInterval = 5000, // 5 seconds
  maxPollingTime = 600000 // 10 minutes
): {
  pollForReady: (projectId: number, applicationModifiedDateTime: string) => void;
  ready: boolean | undefined;
  loading: boolean;
  error: string | undefined;
} => {
  const [error, setError] = useState<string | undefined>(undefined);
  const [modifiedTime, setModifiedTime] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [polling, setPolling] = useState<boolean>(false);

  const [queryForRecPlanSaveReady, { data, startPolling, stopPolling }] = useLazyQuery<{
    pollForRecognitionPlanSaveReady: PollReadyResult;
  }>(POLL_RECOGNITIONS_SAVE_READY, {
    notifyOnNetworkStatusChange: true,
    onCompleted(data) {
      const now = Date.now();
      if ((!data || !data.pollForRecognitionPlanSaveReady.ready) && modifiedTime + maxPollingTime >= now && !polling) {
        setPolling(true);
      } else if ((!data || !data.pollForRecognitionPlanSaveReady.ready) && modifiedTime + maxPollingTime < now) {
        setPolling(false);
        setError(
          "Calculation was not finished in time. The calculation may still have been successful, but it took too long to wait for it to finish."
        );
        setLoading(false);
      } else if (data && data.pollForRecognitionPlanSaveReady.ready) {
        setPolling(false);
        setLoading(false);
      }
    },
    onError(error) {
      const now = Date.now();
      if (modifiedTime + maxPollingTime >= now && !polling) {
        setPolling(true);
      } else if (modifiedTime + maxPollingTime < now) {
        setPolling(false);
        setLoading(false);
        setError(error.message);
      }
    },
  });

  useEffect(() => {
    if (polling) startPolling(pollInterval);
    else stopPolling();
  }, [polling, startPolling, stopPolling]);

  const pollForReady = useCallback(
    (projectId: number, applicationModifiedDateTime: string) => {
      setLoading(true);
      // Application modified date time is returned from the backend as UTC timestamp in local date time format.
      // Before parsing, it needs to be converted to UTC timestamp, by adding Z timezone.
      const toUtcDate = (localDateTime: string) => Date.parse(localDateTime + "Z");
      setModifiedTime(toUtcDate(applicationModifiedDateTime));
      queryForRecPlanSaveReady({ variables: { projectId, applicationModifiedDateTime } });
    },
    [queryForRecPlanSaveReady]
  );

  return {
    pollForReady: pollForReady,
    ready: data && data.pollForRecognitionPlanSaveReady.ready,
    loading: loading,
    error: error,
  };
};

export const GET_COMMENT_TYPES = gql`
  query GetCommentTypes {
    commentTypes
  }
`;

export const ADD_RECOGNITION_PLAN_COMMENT = gql`
  mutation AddRecognitionPlanComment($projectId: ProjectId!, $currencyId: CurrencyId!, $comment: CommentInput!) {
    addRecognitionPlanComment(projectId: $projectId, currencyId: $currencyId, comment: $comment) {
      comment {
        type
        value
        content
        createdBy
        createdDateTime
      }
      errors
    }
  }
`;

export const DELETE_RECOGNITION_PLAN_COMMENT = gql`
  mutation DeleteRecognitionPlanComment($projectId: ProjectId!, $currencyId: CurrencyId!, $comment: CommentInput!) {
    deleteRecognitionPlanComment(projectId: $projectId, currencyId: $currencyId, comment: $comment) {
      deletedTimestamp
      errors
    }
  }
`;

const queryRecognitionPlanSaveReady = async (
  client: ApolloClient<Record<string, unknown>>,
  projectId: number,
  applicationModifiedDateTime: string
): Promise<[PollReadyResult | undefined, Readonly<GraphQLError[] | undefined>]> => {
  const { data, errors } = await client
    .query<{ pollForRecognitionPlanSaveReady: PollReadyResult }>({
      query: POLL_RECOGNITIONS_SAVE_READY,
      variables: { projectId, applicationModifiedDateTime },
      fetchPolicy: "no-cache",
    })
    .catch(e => {
      return e;
    });

  return [data ? data.pollForRecognitionPlanSaveReady : undefined, errors];
};

export const deleteRecognitionPlanComment = async (
  client: ApolloClient<Record<string, unknown>>,
  projectId: number,
  currencyId: number,
  comment: CommentInput,
  pollInterval = 1000
): Promise<[ISOLocalDateTime | undefined, Readonly<GraphQLError[] | undefined>]> => {
  type DeleteRecognitionPlanCommentResult = {
    errors: string[] | null;
    deletedTimestamp: ISOLocalDateTime;
  };

  const { data, errors } = await client
    .mutate<{ deleteRecognitionPlanComment: DeleteRecognitionPlanCommentResult }>({
      mutation: DELETE_RECOGNITION_PLAN_COMMENT,
      variables: { projectId, currencyId, comment },
    })
    .then(res => {
      return res;
    })
    .catch(e => {
      console.error(e);
      return e;
    });

  if (errors) {
    console.error(`Deleting comment failed with ${errors}`);
    return [undefined, errors];
  }

  if (!data || !data.deleteRecognitionPlanComment.deletedTimestamp) {
    return [undefined, errors];
  }

  const timestamp = data.deleteRecognitionPlanComment.deletedTimestamp;

  return await new Promise(resolve => {
    const waitForReady = async () => {
      const [pollData, pollErrors] = await queryRecognitionPlanSaveReady(client, projectId, timestamp);

      if (pollErrors) {
        console.error(pollErrors);
        resolve([undefined, pollErrors]);
      } else if (pollData && pollData.ready) {
        resolve([timestamp, undefined]);
      } else {
        setTimeout(waitForReady, pollInterval);
      }
    };

    setTimeout(waitForReady, pollInterval);
  });
};

export const addRecognitionPlanComment = async (
  client: ApolloClient<Record<string, unknown>>,
  projectId: number,
  currencyId: number,
  comment: CommentInput,
  pollInterval = 1000
): Promise<[AddRecognitionPlanCommentResult | undefined, Readonly<GraphQLError[] | undefined>]> => {
  const { data, errors } = await client
    .mutate<{ addRecognitionPlanComment: AddRecognitionPlanCommentResult }>({
      mutation: ADD_RECOGNITION_PLAN_COMMENT,
      variables: { projectId, currencyId, comment },
    })
    .then(res => {
      return res;
    })
    .catch(e => {
      console.error(e);
      return e;
    });

  if (errors) {
    console.error(`Adding comment failed with ${errors}`);
    return [undefined, errors];
  }

  if (!data || !data.addRecognitionPlanComment.comment || !data.addRecognitionPlanComment.comment.createdDateTime) {
    return [undefined, errors];
  }

  const result = data.addRecognitionPlanComment;
  const createdDateTime = data.addRecognitionPlanComment.comment.createdDateTime;

  return await new Promise(resolve => {
    const waitForReady = async () => {
      const [pollData, pollErrors] = await queryRecognitionPlanSaveReady(client, projectId, createdDateTime);

      if (pollErrors) {
        console.error(pollErrors);
        resolve([undefined, pollErrors]);
      } else if (pollData && pollData.ready) {
        resolve([result, undefined]);
      } else {
        setTimeout(waitForReady, pollInterval);
      }
    };

    setTimeout(waitForReady, pollInterval);
  });
};
