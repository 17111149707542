import React from "react";
import { UpdateStatus, useUpdateCheck } from "./UpdateCheck";
import UpdateNotificationDialog from "./UpdateNotificationDialog";

interface UpdateNotificationContainerProps {
  children?: React.ReactNode;
}

function UpdateNotificationContainer(props: UpdateNotificationContainerProps): React.ReactElement {
  const [dismissed, setDismissed] = React.useState(false);
  const { status, reloadPage } = useUpdateCheck();
  const [open, setOpen] = React.useState(false);

  const check = React.useCallback(() => {
    if (status === UpdateStatus.Available && !open && !dismissed) {
      setOpen(true);
    }
    if (dismissed && open) {
      setOpen(false);
    }
  }, [status, dismissed, open]);

  React.useEffect(() => {
    check();
  }, [status, dismissed, check]);

  return (
    <>
      {open && <UpdateNotificationDialog reloadPage={reloadPage} dismiss={() => setDismissed(true)} />}
      {props.children}
    </>
  );
}

export default UpdateNotificationContainer;
