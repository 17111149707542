import React from "react";
import { ProjectId, ProjectReportingRelations, ProjectReportingRelationsVisibility } from "../../../../../common/types";
import CreateProjectSection from "../CreateProjectSection";
import EditDetails from "./EditDetails";
import styled from "styled-components";
import { defaultGrey } from "../../../../../common/colors";

interface Props {
  projectId: ProjectId;
  sectionDetails: ProjectReportingRelations;
  sectionVisibility: ProjectReportingRelationsVisibility;
}

export default function ReportingRelationsCreateProjectSection(props: Props) {
  const { projectId, sectionDetails, sectionVisibility } = props;
  return (
    <CreateProjectSection header="Order / Reporting Relations">
      <Container>
        <EditDetails projectId={projectId} reportingRelations={sectionDetails} visibility={sectionVisibility} />
      </Container>
    </CreateProjectSection>
  );
}

const Container = styled.div`
  padding: 20px;
  padding-bottom: 0px;
  color: ${defaultGrey};
  > div:last-child {
    border-bottom: 0;
    margin-bottom: 0px;
  }
`;
