import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import styled from "styled-components";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toggleHistoryView } from "../../../../actions/historyActions";
import { defaultGrey, valmetGreyTable } from "../../../../common/colors";
import { AppState, ProjectHistory } from "../../../../common/types";
import ProjectItem from "./ProjectItem";
import { IconButton } from "../../../../common/components";

export interface ProjectsHistoryProps {
  projectsHistory: ProjectHistory[];
  historyViewOpen: boolean;
}

const mapStateToProps = (state: AppState): ProjectsHistoryProps => {
  return {
    projectsHistory: state.historyState.projectHistory,
    historyViewOpen: state.historyState.historyViewOpen,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    toggleHistoryView: () => {
      dispatch(toggleHistoryView());
    },
  };
};

const calculateItems = () => {
  const itemWidth = 290;
  return Math.floor(window.innerWidth / itemWidth);
};

function ProjectsHistory(props: ProjectsHistoryProps & ReturnType<typeof mapDispatchToProps>): React.ReactElement {
  const { projectsHistory, historyViewOpen, toggleHistoryView } = props;
  calculateItems();
  return (
    <Container open={historyViewOpen}>
      <IconButton onClick={() => toggleHistoryView()} hasText={true}>
        <Info>
          <InfoText>Recently viewed projects</InfoText>
          <FontAwesomeIcon icon={historyViewOpen ? faChevronDown : faChevronUp} color={defaultGrey} />
        </Info>
      </IconButton>
      {historyViewOpen && (
        <ItemContainer>
          {projectsHistory.slice(1, calculateItems()).map(project => (
            <ProjectItem key={project.id} id={project.id} name={project.name} />
          ))}
        </ItemContainer>
      )}
    </Container>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsHistory);

const Container = styled.div<{ open: boolean }>`
  display: flex;
  z-index: 200;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: ${({ open }) => (open ? "130px" : "40px")};
  width: 100%;
  background: ${valmetGreyTable};
  position: fixed;
  bottom: 0;
  border-top: 2px solid white;
  transition: height 0.5s;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-right: 50px;
  padding-left: 50px;

  div:nth-child(1) {
    margin-left: 0px;
  }
`;

const Info = styled.div`
  padding-right: 50px;
  padding-left: 50px;
  margin-bottom: 10px;
  margin-top: 10px;
  color: ${defaultGrey};
  font-size: 14px;
  text-transform: uppercase;
`;
const InfoText = styled.span`
  margin-right: 8px;
`;
