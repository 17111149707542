import { useEffect } from "react";

export function useWindowClose(callback: () => void): void {
  useEffect(() => {
    window.addEventListener("onunload", callback);
    return () => {
      window.removeEventListener("onunload", callback);
    };
  });
}
