import { gql } from "@apollo/client";

export const LOAD_LATEST = gql`
  query LoadLatest {
    loadingStatusLog {
      projectTypes {
        name
        source
        loadings {
          pipelineId
          status
          error
          start
          end
          staging {
            start
            end
          }
          intermediate {
            start
            end
          }
          info {
            averageDurationSeconds
            estimatedEnd
          }
        }
        loadingTimeList {
          timeZoneName
          time
        }
      }
    }
  }
`;
