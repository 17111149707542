import {
  ProjectCostLaborTableItem,
  ProjectCostOtherTableItem,
  ProjectCostProductionTableItem,
  ProjectCostPurchasesTableItem,
  ProjectCostWarehouseTableItem,
} from "./types";
import { dateAsLink, DetailsColumnType as DCT, DetailsTableColumn } from "./column";

export const PROJECT_COST_PURCHASES_COLUMNS: DetailsTableColumn<ProjectCostPurchasesTableItem>[] = [
  { id: "orderedQuantity", description: "Pur. Qty", tooltip: "Purchase quantity", type: DCT.NumericString },
  dateAsLink("orderDate", "Order Date", undefined, "orderPictureUrl"),
  { id: "plannedReceiptDate", description: "Plan. Recv. Date", tooltip: "Planned receipt date", type: DCT.Date },
  { id: "estimatedReceiptDate", description: "Est. Recv. Date", tooltip: "Estimated receipt date", type: DCT.Date },
  { id: "actualReceiptDate", description: "Recv. Date", tooltip: "Actual receipt date", type: DCT.Date },
  { id: "invoicedQuantity", description: "Inv. Qty", tooltip: "Invoice quantity", type: DCT.NumericString },
  dateAsLink("invoiceDate", "Inv. Date", "Invoice date", "invoicePictureUrl"),
  { id: "actuals", description: "Actuals", type: DCT.Number },
  { id: "hardCommitments", description: "Hard Comm.", tooltip: "Hard commitments", type: DCT.Number },
  { id: "softCommitments", description: "Soft Comm.", tooltip: "Soft commitments", type: DCT.Number },
  { id: "total", description: "Total", type: DCT.Number },
];

export const PROJECT_COST_PURCHASES_COLUMNS_SER_ORDER: DetailsTableColumn<ProjectCostPurchasesTableItem>[] = [
  { id: "orderedQuantity", description: "Pur. Qty", tooltip: "Purchase quantity", type: DCT.NumericString },
  dateAsLink("orderDate", "Order Date", undefined, "orderPictureUrl"),
  { id: "plannedReceiptDate", description: "Plan. Recv. Date", tooltip: "Planned receipt date", type: DCT.Date },
  { id: "estimatedReceiptDate", description: "Est. Recv. Date", tooltip: "Estimated receipt date", type: DCT.Date },
  { id: "actualReceiptDate", description: "Recv. Date", tooltip: "Actual receipt date", type: DCT.Date },
  { id: "invoicedQuantity", description: "Inv. Qty", tooltip: "Invoice quantity", type: DCT.NumericString },
  dateAsLink("invoiceDate", "Inv. Date", "Invoice date", "invoicePictureUrl"),
  { id: "poLinePrice", description: "PO Line Price", tooltip: "Purchase order line price", type: DCT.Number },
  { id: "poLineAmount", description: "PO Line Amount", tooltip: "Purchase order line amount", type: DCT.Number },
];

export const PROJECT_COST_HOURS_COLUMNS: DetailsTableColumn<ProjectCostLaborTableItem>[] = [
  { id: "quantity", description: "Quantity", type: DCT.Number, decimals: 1 },
  { id: "actuals", description: "Actuals", type: DCT.Number },
  { id: "laborRateCode", description: "Labor Rate Code", type: DCT.General },
  { id: "employeeCostcenter", description: "Employee Cost Center", type: DCT.General },
  { id: "transactionDate", description: "Transaction Date", type: DCT.Date },
  { id: "reportingDate", description: "Reporting Date", type: DCT.Date },
  { id: "executionDate", description: "Execution Date", type: DCT.Date },
];

export const PROJECT_COST_HOURS_COLUMNS_SER_ORDER: DetailsTableColumn<ProjectCostLaborTableItem>[] = [
  { id: "quantity", description: "Quantity", type: DCT.Number, decimals: 1 },
  { id: "actuals", description: "Actuals", type: DCT.Number },
  { id: "laborRateCode", description: "Labor Rate Code", type: DCT.General },
  { id: "employeeCostcenter", description: "Employee Cost Center", type: DCT.General },
  { id: "transactionDate", description: "Transaction Date", type: DCT.Date },
  { id: "reportingDate", description: "Reporting Date", type: DCT.Date },
  { id: "executionDate", description: "Execution Date", type: DCT.Date },
  { id: "task", description: "Task", type: DCT.General },
];

export const PROJECT_COST_OTHER_COSTS_COLUMNS: DetailsTableColumn<ProjectCostOtherTableItem>[] = [
  { id: "item", description: "Item", type: DCT.General },
  { id: "supplier", description: "Supplier", type: DCT.General },
  { id: "reference", description: "Reference", type: DCT.General },
  { id: "actuals", description: "Actuals", type: DCT.Number },
  { id: "transactionDate", description: "Transaction Date", type: DCT.Date },
  {
    id: "non-invoicePictureURL", // using non-matching name here on purpose to not show the actual link url
    description: "Invoice URL",
    type: DCT.Link,
    actualType: DCT.General,
    linkId: "invoicePictureURL",
  },
];

export const PROJECT_COST_PRODUCTION_COLUMNS: DetailsTableColumn<ProjectCostProductionTableItem>[] = [
  { id: "item", description: "Item / Task", type: DCT.General },
  { id: "actualQuantity", description: "Act. / Inv. Qty", tooltip: "Actual / invoiced quantity", type: DCT.Number },
  { id: "machineQuantity", description: "Machine Qty", type: DCT.Number },
  { id: "estimateQuantity", description: "Est. / Pur. Qty", tooltip: "Estimated/purchased quantity", type: DCT.Number },
  { id: "estimateAmount", description: "Est. Amount", tooltip: "Estimated amount", type: DCT.Number },
  { id: "actualAmount", description: "Act. Amount", tooltip: "Actual amount", type: DCT.Number },
  { id: "hardCommitments", description: "Hard Comm.", tooltip: "Hard commitments", type: DCT.Number },
  { id: "softCommitments", description: "Soft Comm.", tooltip: "Soft commitments", type: DCT.Number },
  { id: "total", description: "Total", tooltip: undefined, type: DCT.Number },
  { id: "supplier", description: "Supplier", type: DCT.General },
  { id: "transactionDate", description: "Transact. Date", tooltip: "Transaction date", type: DCT.Date },
  dateAsLink("orderDate", "Date", "Order date", "orderPictureURL"),
  { id: "receiptDate", description: "Recv. Date", tooltip: "Actual receipt date", type: DCT.Date },
  dateAsLink("invoiceDate", "Inv. Date", "Invoice date", "invoicePictureURL"),
  { id: "laborRateCode", description: "Labor Rate C.", tooltip: "Labor rate code", type: DCT.General },
  { id: "employeeCostCenter", description: "Employee Cost C.", tooltip: "Employee cost center", type: DCT.General },
  { id: "reportingDate", description: "Reporting date", tooltip: undefined, type: DCT.Date },
  { id: "executionDate", description: "Execution date", tooltip: undefined, type: DCT.Date },
];

export const PROJECT_COST_PRODUCTION_COLUMNS_SER_ORDER: DetailsTableColumn<ProjectCostProductionTableItem>[] = [
  { id: "item", description: "Item / Task", type: DCT.General },
  { id: "actualQuantity", description: "Act. Qty", tooltip: "Actual quantity", type: DCT.NumericString },
  { id: "machineQuantity", description: "Machine Qty", type: DCT.NumericString },
  { id: "estimateQuantity", description: "Est. Qty", tooltip: "Estimated quantity", type: DCT.NumericString },
  { id: "estimateAmount", description: "Est. Amount", tooltip: "Estimated amount", type: DCT.Number },
  { id: "actuals", description: "Actuals", type: DCT.Number },
  { id: "poLineAmount", description: "PO Line Amount", tooltip: "Production order line amount", type: DCT.Number },
  { id: "poLinePrice", description: "PO Line Price", tooltip: "Production order line price", type: DCT.Number },
  { id: "supplier", description: "Supplier", type: DCT.General },
  { id: "transactionDate", description: "Transact. Date", tooltip: "Transaction date", type: DCT.Date },
  dateAsLink("orderDate", "Date", "Order date", "orderPictureURL"),
  { id: "receiptDate", description: "Recv. Date", tooltip: "Actual receipt date", type: DCT.Date },
  dateAsLink("invoiceDate", "Inv. Date", "Invoice date", "invoicePictureURL"),
  { id: "laborRateCode", description: "Labor Rate C.", tooltip: "Labor rate code", type: DCT.General },
  { id: "employeeCostCenter", description: "Employee Cost C.", tooltip: "Employee cost center", type: DCT.General },
  { id: "reportingDate", description: "Reporting date", tooltip: undefined, type: DCT.Date },
  { id: "executionDate", description: "Execution date", tooltip: undefined, type: DCT.Date },
];

export const PROJECT_COST_WAREHOUSE_COLUMNS: DetailsTableColumn<ProjectCostWarehouseTableItem>[] = [
  { id: "supplier", description: "Supplier", type: DCT.General },
  { id: "item", description: "Item", type: DCT.General },
  { id: "quantity", description: "Qty", type: DCT.NumericString },
  { id: "transactionDate", description: "Transaction Date", type: DCT.Date },
  { id: "actuals", description: "Actuals", type: DCT.Number },
  { id: "hardCommitments", description: "Hard Comm.", type: DCT.Number },
  { id: "softCommitments", description: "Soft Comm.", type: DCT.Number },
  { id: "total", description: "Total", type: DCT.Number },
  { id: "orderCurrency", description: "Order currency", type: DCT.General },
];

export const PROJECT_COST_WAREHOUSE_COLUMNS_SER_ORDER: DetailsTableColumn<ProjectCostWarehouseTableItem>[] = [
  { id: "supplier", description: "Supplier", type: DCT.General },
  { id: "item", description: "Item", type: DCT.General },
  { id: "quantity", description: "Qty", type: DCT.NumericString },
  { id: "orderLineAmount", description: "Order Line Amount", type: DCT.Number },
  { id: "orderCurrency", description: "Order currency", type: DCT.General },
  { id: "transactionDate", description: "Transaction Date", type: DCT.Date },
  { id: "receivedDate", description: "Received date", type: DCT.Date },
  { id: "invoicedQuantity", description: "Inv. Qty", type: DCT.NumericString },
  dateAsLink("invoicedDate", "Invoice Date", undefined, "invoicePictureUrl"),
  dateAsLink("date", "Date", undefined, "orderPictureUrl"),
];
