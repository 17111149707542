import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Action } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { useMutation } from "@apollo/client/react/hooks";

import Section from "../Section";
import {
  AppState,
  ProjectCurrency,
  ProjectCurrencyInput,
  ProjectCurrencySaveResult,
  ProjectCurrencySectionInput,
  ProjectCurrencyVisibility,
  ProjectId,
} from "../../../../../common/types";
import { setProjectCurrency } from "../../../../../actions/projectActions";
import { BasicDataSectionType, getEmptyProjectCurrencySectionInput } from "../../../../../common/constants";
import { SAVE_PROJECT_CURRENCY } from "./queries";
import { pollForBasicDataSaveReady } from "../../queries";
import ViewDetails from "./ViewDetails";
import EditDetails from "./EditDetails";
import styled from "styled-components";
import { defaultGrey } from "../../../../../common/colors";

const mapStateToProps = (state: AppState) => {
  return {
    projectCurrencyInput: state.projectState.projectInput
      ? state.projectState.projectInput.projectCurrency
      : getEmptyProjectCurrencySectionInput(),
    inputErrors: state.projectState.projectErrors.currency,
  };
};
const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, void, Action>) => {
  return {
    setProjectCurrencyInput: (input: ProjectCurrencySectionInput) => dispatch(setProjectCurrency(input)),
  };
};

interface CurrencySectionProps {
  projectId: ProjectId;
  sectionDetails: ProjectCurrency | undefined;
  sectionVisibility: ProjectCurrencyVisibility;
  sectionEditable: boolean;
  editSectionType: BasicDataSectionType | undefined;
  setEditSectionType: (type: BasicDataSectionType | undefined) => void;
  refetchDetails: () => void;
}

const TYPE: BasicDataSectionType = BasicDataSectionType.Currency;

function CurrencySection(
  props: CurrencySectionProps & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
) {
  const {
    projectId,
    sectionDetails,
    sectionVisibility,
    sectionEditable,
    editSectionType,
    setEditSectionType,
    refetchDetails,
    projectCurrencyInput,
    inputErrors,
    setProjectCurrencyInput,
  } = props;
  const [editMode, setEditMode] = useState(false);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  // Once input is changed, clear error messages.
  useEffect(() => {
    if (errorMessages.length > 0) {
      setErrorMessages([]);
    }
  }, [projectCurrencyInput]);

  const [saveMutation, { loading: saving }] = useMutation<{
    saveProjectCurrency: ProjectCurrencySaveResult;
  }>(SAVE_PROJECT_CURRENCY);

  const { pollForReady, ready, loading: polling, error: pollingError } = pollForBasicDataSaveReady();
  useEffect(() => {
    if (ready && !polling && !pollingError) {
      setEditMode(false);
      setEditSectionType(undefined);
      setProjectCurrencyInput(getEmptyProjectCurrencySectionInput());
      refetchDetails();
    } else if (!polling && pollingError) {
      setErrorMessages(errorMessages.concat([pollingError]));
    }
  }, [ready, polling, pollingError]);

  const save = useCallback(
    (input: ProjectCurrencyInput) => {
      saveMutation({
        variables: {
          projectId: projectId,
          currency: input,
        },
      })
        .then(({ data }) => {
          if (data && data.saveProjectCurrency && data.saveProjectCurrency.applicationModifiedDateTime) {
            pollForReady(projectId, data.saveProjectCurrency.applicationModifiedDateTime);
          } else if (data && data.saveProjectCurrency.errors) {
            setErrorMessages(errorMessages.concat(data.saveProjectCurrency.errors));
          }
        })
        .catch(apolloError => {
          setErrorMessages(errorMessages.concat([apolloError.message]));
        });
    },
    [saveMutation]
  );

  const disabled = editSectionType !== undefined && editSectionType !== TYPE;

  return (
    <Section
      header="Currency"
      disabled={disabled}
      editMode={editMode}
      editable={sectionEditable && sectionDetails !== undefined}
      saving={saving || polling}
      saveEnabled={!projectCurrencyInput.pristine && !inputErrors}
      errors={errorMessages.length > 0 ? errorMessages : undefined}
      onEditClicked={() => {
        setEditSectionType(TYPE);
        setEditMode(true);
      }}
      onCancelEdit={() => {
        setEditSectionType(undefined);
        setEditMode(false);
        setProjectCurrencyInput(getEmptyProjectCurrencySectionInput());
      }}
      onSaveClicked={() => {
        save(projectCurrencyInput.input);
      }}
    >
      <Container>
        {!sectionDetails ? undefined : editMode ? (
          <EditDetails projectId={projectId} currency={sectionDetails} currencyVisibility={sectionVisibility} />
        ) : (
          <ViewDetails currency={sectionDetails} currencyVisibility={sectionVisibility} />
        )}
      </Container>
    </Section>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CurrencySection);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-bottom: 0px;
  color: ${defaultGrey};
`;
