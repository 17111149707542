import React from "react";
import styled, { keyframes } from "styled-components";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { valmetGreyLight } from "../../../../common/colors";
import TopGroup from "./TopGroup";
import { IconButton } from "../../../../common/components";

// TODO: Add content

const TopLists = ["PM Impact", "PM Variance", "Changes in the contingency", "Recognised PM"];

const DUMMY_DATA = [
  { data1: "-1,2 %", data2: "lorem ipsum", data3: "124312 Nantong Oji RB", data4: "optimization GX001ARA1" },
  { data1: "-1,2 %", data2: "lorem ipsum", data3: "124312 Nantong Oji RB", data4: "optimization GX001ARA1" },
  { data1: "-1,2 %", data2: "lorem ipsum", data3: "124312 Nantong Oji RB", data4: "optimization GX001ARA1" },
  { data1: "-1,2 %", data2: "lorem ipsum", data3: "124312 Nantong Oji RB", data4: "optimization GX001ARA1" },
  { data1: "-1,2 %", data2: "lorem ipsum", data3: "124312 Nantong Oji RB", data4: "optimization GX001ARA1" },
];

export interface TopListProps {
  onCloseTopList: () => void;
}

function TopList(props: TopListProps): React.ReactElement {
  const { onCloseTopList } = props;
  return (
    <Container>
      <Margin />

      <CenterContainer>
        <SelectorContainer>
          <IconButton onClick={() => onCloseTopList()}>
            <FontAwesomeIcon icon={faChevronUp} size="lg" color={valmetGreyLight} />
          </IconButton>
        </SelectorContainer>
        <ContentContainer>
          {TopLists.map(title => (
            <TopGroup title={title} key={title} data={DUMMY_DATA} />
          ))}
        </ContentContainer>
      </CenterContainer>

      <Margin></Margin>
    </Container>
  );
}

export default TopList;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 24px;
`;

const Margin = styled.div`
  width: 100px;
`;

const SelectorContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  font-size: 12px;
  align-items: center;
  justify-content: flex-end;
  color: ${valmetGreyLight};
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex: 1;
  > div {
    margin-right: 15px;
  }
  > div:last-child {
    margin-right: 0px;
  }
  animation: 0.5s
    ${keyframes({
      from: {
        opacity: 0,
      },
      to: {
        opacity: 1,
      },
    })}
    linear;
`;

const CenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
