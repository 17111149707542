import React, { useEffect, useMemo, useRef, useState } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import styled from "styled-components";
import { useQuery } from "@apollo/client/react/hooks";
import { addProjectToHistory } from "../../../actions/historyActions";
import {
  finishNewProject,
  setProjectApplicationModifiedDateTime,
  setProjectHierarchy,
} from "../../../actions/projectActions";
import { resetHeaderInfo, setNodeHeaderInfo, setProjectHeaderInfo } from "../../../actions/projectHeaderActions";
import { startRecognitionsEditing } from "../../../actions/recognitionsEditActions";
import { backgroundGrey } from "../../../common/colors";
import { checkLoginStatus } from "../../../common/restApi";
import routes from "../../../common/routes";
import {
  AppState,
  HeaderData,
  HeaderInfoData,
  HierarchyItem,
  HierarchyItemEnum,
  ProjectAccessResult,
  UserProjectAccess,
} from "../../../common/types";
import ErrorBox from "../../ErrorBox/ErrorBox";
import LoadingView from "../../LoadingView";
import CreateProjectDialog from "./CreateProjectDialog";
import ProjectHeader from "./ProjectHeader";
import { HeaderActionsRefs, HeaderRefs, ProjectHeaderActionsContext } from "./ProjectHeaderActions";
import ProjectsHistory from "./ProjectsHistory";
import { GET_HEADER_INFO, GET_PROJECT_ACCESS } from "./queries";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import { initLogger } from "../../../logging";
import SuccessDialog from "./CreateProjectDialog/SuccessDialog";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ComponentType, readItemTypeFromPath, readProjectIdFromPath, renderTabContent } from "./utils";
import { readProjectDescription } from "./ProjectBasics/utils";

const logger = initLogger(__filename);

export enum Tabs {
  Overview,
  Basics,
  Hierarchy,
  REC,
  Costs,
  Changelog,
  Comments,
}

const useTitle = (title: string) => {
  useEffect(() => {
    const oldTitle = document.title;
    document.title = "Valmet POP - " + title;
    return () => {
      document.title = oldTitle;
    };
  });
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    addProjectToHistory: (id: number, name: string) => {
      dispatch(addProjectToHistory(id, name));
    },
    startRecognitionsEditing: () => {
      dispatch(startRecognitionsEditing);
    },
    setProjectHeaderInfo: (headerData: HeaderData) => {
      switch (headerData.itemType) {
        case HierarchyItemEnum.Project: {
          if (headerData.projectTechnicalTypeId !== undefined)
            dispatch(
              setProjectHeaderInfo(
                headerData.id,
                headerData.projectTechnicalTypeId,
                headerData.description,
                headerData.projectPhaseId,
                headerData.projectStatusId,
                headerData.projectVirtualType,
                headerData.legalEntityCurrency || undefined
              )
            );
          break;
        }
        case HierarchyItemEnum.Node: {
          dispatch(setNodeHeaderInfo(headerData.id, headerData.description, headerData.mainProjectId));
          break;
        }
      }
    },
    resetProjectHeaderInfo: () => {
      dispatch(resetHeaderInfo);
    },
    setProjectHierarchy: (hierarchy: HierarchyItem) => {
      dispatch(setProjectHierarchy(hierarchy));
    },
    setProjectApplicationModifiedDateTime: (projectApplicationModifiedDateTime: string | undefined) => {
      dispatch(setProjectApplicationModifiedDateTime(projectApplicationModifiedDateTime));
    },
    finishProjectCreationMode: () => {
      dispatch(finishNewProject());
    },
  };
};

const mapStateToProps = (state: AppState) => {
  return {
    projectsHistory: state.historyState.projectHistory,
    projectId: state.projectState.projectId,
    projectDescriptionCreationMode:
      (state.projectState.projectDetailsEditInformation &&
        state.projectState.projectDetailsEditInformation.projectInformation &&
        readProjectDescription(
          state.projectState.projectDetailsEditInformation.projectInformation.projectDescription
        )) ||
      "New Project",
    hierarchy: state.projectState.hierarchy,
    nodeHierarchyId: state.projectState.nodeHierarchyId,
  };
};

function Project(
  props: ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps> & RouteComponentProps
): React.ReactElement {
  const itemType = readItemTypeFromPath();
  const [tab, setTab] = useLocalStorage(
    itemType === HierarchyItemEnum.NewProject ? "projectCreationLastSelectedTab" : "lastProjectTab",
    Tabs.Basics
  );
  if (itemType === HierarchyItemEnum.Node && [Tabs.Overview].includes(tab)) {
    setTab(Tabs.Basics);
  }

  useEffect(() => {
    checkLoginStatus();
    // Overview is disabled -> redirect to basic data
    if (tab === Tabs.Overview) {
      setTab(Tabs.Basics);
    }
  }, [tab]);

  const [projectCreationSuccessful, setProjectCreationSuccessful] = useState(false);
  const headerRefs: HeaderRefs = {
    [HeaderActionsRefs.commentPackageEditRef]: useRef<HTMLDivElement>(null),
    [HeaderActionsRefs.createProjectActionsRef]: useRef<HTMLDivElement>(null),
    [HeaderActionsRefs.errorsBoxRef]: useRef<HTMLDivElement>(null),
  };

  const {
    addProjectToHistory,
    projectsHistory,
    setProjectHeaderInfo,
    resetProjectHeaderInfo,
    startRecognitionsEditing,
    projectId,
    projectDescriptionCreationMode,
    setProjectHierarchy,
    hierarchy,
    setProjectApplicationModifiedDateTime,
    finishProjectCreationMode,
    history,
  } = props;
  const showProjectCreationDialog = itemType === HierarchyItemEnum.NewProject && !projectId;

  const id = readProjectIdFromPath(itemType);
  const title =
    itemType === HierarchyItemEnum.Project
      ? "Project " + (projectId || "")
      : itemType === HierarchyItemEnum.Node
      ? (hierarchy && hierarchy.description) || ""
      : "New project" + (projectId !== undefined ? " " + projectId : "");
  useTitle(title);

  const { loading: headerLoading, data, error } = useQuery<HeaderInfoData>(GET_HEADER_INFO, {
    variables: {
      id: id,
      itemType: itemType,
    },
    fetchPolicy: "cache-and-network",
    skip: itemType === HierarchyItemEnum.NewProject,
    onCompleted: data => {
      if (data) {
        if (data.headerInfo != null) {
          logger.debug(
            "Header data was loaded:",
            data.headerInfo.itemType,
            data.headerInfo.id,
            data.headerInfo.description
          );
          setProjectHeaderInfo(data.headerInfo);
          setProjectHierarchy(data.headerInfo.hierarchy);
        } else {
          resetProjectHeaderInfo();
        }
      }
    },
  });
  const projectHeaderData: HeaderData | null = data ? data.headerInfo : null;

  const { data: accessData, loading: accessLoading } = useQuery<ProjectAccessResult>(GET_PROJECT_ACCESS, {
    variables: {
      projectId: projectId,
    },
    fetchPolicy: "cache-and-network",
    skip: projectId === null || projectId === undefined || itemType === HierarchyItemEnum.Node,
    onCompleted: data => {
      if (data) logger.debug("Access:", data.projectAccess);
    },
  });

  useEffect(() => {
    if (projectHeaderData) {
      if (projectHeaderData.itemType === HierarchyItemEnum.Project) {
        addProjectToHistory(projectHeaderData.id, projectHeaderData.description);
      }
    }
  }, [projectHeaderData, addProjectToHistory]);

  const userProjectAccess = useMemo(() => {
    switch (itemType) {
      case HierarchyItemEnum.NewProject:
        return accessData && accessData.projectAccess.access
          ? new UserProjectAccess(accessData.projectAccess.access)
          : null;
      case HierarchyItemEnum.Node:
        return new UserProjectAccess({
          isAdmin: false,
          isEditor: false,
          isProjectController: false,
          isProjectManager: false,
          hasCostsAccessOnly: false,
          accessRights: [],
        });
      case HierarchyItemEnum.Project:
        if (accessData && accessData.projectAccess.access) {
          return new UserProjectAccess(accessData.projectAccess.access);
        } else if (accessData && accessData.projectAccess.error) {
          return accessData.projectAccess.error;
        } else if (accessData && !accessData.projectAccess.access) {
          return "Invalid response for project access query";
        } else {
          return null;
        }
    }
  }, [accessData, itemType]);

  const renderedTab = useMemo(() => {
    const rendered =
      userProjectAccess && typeof userProjectAccess === "object"
        ? renderTabContent(tab, itemType, projectId, userProjectAccess, hierarchy, {
            createProjectBasics: applicationModifiedDateTime => {
              setProjectApplicationModifiedDateTime(applicationModifiedDateTime);
              startRecognitionsEditing();
              setTab(Tabs.Hierarchy);
            },
            createProjectHierarchy: () => setTab(Tabs.REC),
            createProjectRec: () => setProjectCreationSuccessful(true),
          })
        : null;
    if (rendered === null || rendered.componentType === ComponentType.NoProjectAccess) {
      return null;
    } else if (rendered.componentType === ComponentType.NoTabAccess) {
      setTab(Tabs.Basics);
      return null;
    } else {
      return rendered.component;
    }
  }, [tab, itemType, projectId, userProjectAccess, projectHeaderData, hierarchy]);

  switch (itemType) {
    case HierarchyItemEnum.NewProject: {
      return (
        <ProjectHeaderActionsContext.Provider value={headerRefs}>
          <Container>
            <ContentContainer>
              <HeaderContainer>
                {userProjectAccess && typeof userProjectAccess !== "string" && (
                  <ProjectHeader
                    projectKey={`${projectId !== undefined ? projectId : ""} ${projectDescriptionCreationMode}`}
                    setTab={setTab}
                    selectedTab={tab}
                    itemType={itemType}
                    userProjectAccess={userProjectAccess}
                    commentsEditActionsRef={headerRefs.commentPackageEditRef}
                    createProjectActionsRef={headerRefs.createProjectActionsRef}
                  />
                )}
              </HeaderContainer>
              <ErrorContainer ref={headerRefs.errorsBoxRef} />
              {showProjectCreationDialog ? (
                <CreateProjectDialog />
              ) : (
                <TabContent background={tab === Tabs.REC || tab === Tabs.Hierarchy ? backgroundGrey : undefined}>
                  {renderedTab}
                  {projectId && projectCreationSuccessful && (
                    <SuccessDialog
                      onClickSuccess={() => {
                        finishProjectCreationMode();
                        localStorage.setItem("lastProjectTab", Tabs.Basics.toString());
                        history.push(`${routes.PROJECT}/${projectId}`);
                      }}
                    />
                  )}
                </TabContent>
              )}
            </ContentContainer>
          </Container>
        </ProjectHeaderActionsContext.Provider>
      );
    }

    case HierarchyItemEnum.Project:
    case HierarchyItemEnum.Node: {
      if (userProjectAccess === null) {
        return <LoadingView />;
      } else if (typeof userProjectAccess === "string") {
        return <ErrorBox caption="Error opening project" errorText={userProjectAccess} />;
      } else {
        return (
          <ProjectHeaderActionsContext.Provider value={headerRefs}>
            <Container>
              {accessLoading ? (
                <LoadingView />
              ) : !error && id !== undefined ? (
                <ContentContainer extraMargin={projectsHistory.length > 1}>
                  <HeaderContainer>
                    <ProjectHeader
                      projectKey={`${itemType === HierarchyItemEnum.Project ? projectId : ""} ${
                        projectHeaderData ? projectHeaderData.description : "...."
                      }`}
                      setTab={setTab}
                      selectedTab={tab}
                      hierarchy={hierarchy}
                      id={id}
                      itemType={itemType}
                      userProjectAccess={userProjectAccess}
                      commentsEditActionsRef={headerRefs.commentPackageEditRef}
                      createProjectActionsRef={headerRefs.createProjectActionsRef}
                    />
                  </HeaderContainer>
                  <TabContent background={tab === Tabs.REC || tab === Tabs.Hierarchy ? backgroundGrey : undefined}>
                    {renderedTab}
                  </TabContent>
                </ContentContainer>
              ) : (
                <ErrorBox caption="Error opening project" apolloError={error} errorText={"Could not fetch data."} />
              )}
              <ProjectsHistoryContainer>{projectsHistory.length > 1 && <ProjectsHistory />}</ProjectsHistoryContainer>
            </Container>
          </ProjectHeaderActionsContext.Provider>
        );
      }
    }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Project));

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-bottom: 20px;
`;
const ContentContainer = styled.div<{ extraMargin?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  /*align-items: center;*/
  margin-bottom: ${({ extraMargin }) => (extraMargin ? `150` : `0`)}px;
`;

const ProjectsHistoryContainer = styled.div`
  display: flex;
  width: 100%;
`;

const HeaderContainer = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 100vw;
  z-index: 500;
  position: sticky;
  top: -${ProjectHeader.WrappedComponent.minimizedTopMargin};
  left: 0;
  height: 134px;
`;

const TabContent = styled.div<{ background?: string }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  ${({ background }) => background && `background: ${background}`};
`;

const ErrorContainer = styled.div`
  margin: 10px auto;
`;
