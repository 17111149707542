import React from "react";
import styled from "styled-components";

import { valmetGreyBorder } from "../../common/colors";

type Props = {
  message?: string | null;
  component?: React.ReactElement;
  offset?: { x: number; y: number };
};

export function Tooltip(props: React.PropsWithChildren<Props>): React.ReactElement {
  const { children, message, component, offset } = props;
  if (!message && !component) return <TooltipContainer>{children}</TooltipContainer>;
  return (
    <TooltipContainer>
      {children}
      <TooltipWrapper>
        <TooltipContent offset={offset}>
          {message}
          {component}
        </TooltipContent>
      </TooltipWrapper>
    </TooltipContainer>
  );
}

const TooltipWrapper = styled.div`
  position: relative;
  z-index: 1;
`;

const TooltipContent = styled.div<{ offset?: { x: number; y: number } }>`
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s linear 0.5s;
  position: absolute;
  top: ${({ offset }) => (offset ? offset.y + "px" : "25px")};
  left: ${({ offset }) => (offset ? offset.x + "px" : "0")};
  background: #ffffff;
  z-index: 10;
  width: max-content;
  max-width: 500px;
  border-radius: 4px;
  border: 1px solid ${valmetGreyBorder};
  padding: 4px;
`;

const TooltipContainer = styled.div`
  :hover ${TooltipContent} {
    opacity: 1;
    pointer-events: auto;
  }
`;
