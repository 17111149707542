import { gql } from "@apollo/client";

export const ESTIMATION_SCHEDULE = gql`
  query GetEstimationSchedule {
    estimationSchedule {
      years {
        year
        periods {
          period
          schedule
        }
      }
    }
  }
`;
