import { ParsedLogEvent, KeywordString, StringItem, TextItem, KeywordItem } from "./util";
import styled from "styled-components";
import { valmetGreyHeader, valmetGreyREC } from "../../../../common/colors";
import { format } from "date-fns";
import React, { useCallback, useState } from "react";
import { LogActionType } from "../../../../common/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
import { GroupContentContainer, GroupedItemContainer } from "../../../../common/components";

interface LogsListProps {
  logEvents: ParsedLogEvent[];
}

function isTextItem(x: StringItem): x is TextItem {
  return typeof x === "string";
}
function isKeywordItem(x: StringItem): x is KeywordItem {
  return Object.prototype.hasOwnProperty.bind(x)("kw");
}

const renderKeywordString = (items: KeywordString) => {
  return items.map((item, itemIndex) => {
    if (isTextItem(item)) {
      const lines = item.split("\n");
      return lines.map((line, index) => {
        if (index === 0) {
          return <LogLineText key={`i-${itemIndex}-${index}`}>{line}</LogLineText>;
        } else {
          return <LogLineTextBreak key={`i-${itemIndex}-${index}`}>{line}</LogLineTextBreak>;
        }
      });
    } else if (isKeywordItem(item)) {
      const str = item.kw === "-" || item.kw === "" ? "[none]" : item.kw;
      const tooltip = item.tooltip;
      return (
        <LogLineKeyword key={`i-${itemIndex}`} title={tooltip}>
          {str}
        </LogLineKeyword>
      );
    } else {
      return null;
    }
  });
};

const logLineContents = (logEvent: ParsedLogEvent, showMore: boolean, showMoreToggle: () => void) => {
  const { actionTypeId, message: messageItems, size: messageSize } = logEvent;

  const content = (heading?: string, trailer?: React.ReactNode | string) => {
    const isLarge = messageSize > 5; //messageItems.length > 3;
    const minimized = !showMore;
    return (
      <>
        <LogLineContent minimized={minimized}>
          {heading && (
            <LogLineText>
              <h4>{heading}</h4>
            </LogLineText>
          )}
          {messageItems.map((item, index) => {
            switch (item.type) {
              case "header":
                return (
                  <LogLineHeading key={"h-" + index}>
                    {item.header}
                    {trailer}
                  </LogLineHeading>
                );
              case "paragraph": {
                const result = renderKeywordString(item.paragraph);
                return <p key={"p-" + index}>{result}</p>;
              }
              case "list":
                return (
                  <LogLineList key={"li-" + index}>
                    {item.items.map((li, index) => {
                      const result = renderKeywordString(li);
                      return <LogLineListItem key={`item-${index}`}>{result}</LogLineListItem>;
                    })}
                  </LogLineList>
                );
            }
            return "";
          })}
        </LogLineContent>
        <LogLineContentShowMorePanel show={isLarge} minimized={minimized}>
          <ShowMoreButton onClick={showMoreToggle}>{showMore ? "Show less" : "Show more"}</ShowMoreButton>
        </LogLineContentShowMorePanel>
      </>
    );
  };

  switch (actionTypeId) {
    case LogActionType.NewProjectCreated:
      return content(
        undefined,
        <IconContainer>
          <FontAwesomeIcon icon={faCalendarAlt} />
        </IconContainer>
      );
    case LogActionType.ProjectBasicDataChange:
    case LogActionType.ProjectHierarchyChange:
    case LogActionType.ProjectRecognitionChange:
    default:
      return content();
  }
};

function LogsList(props: LogsListProps) {
  const { logEvents } = props;
  const [showMore, setShowMore] = useState<boolean[]>([]);

  const showMoreToggle = useCallback(
    (index: number) => {
      const newShowMore = showMore.concat([]);
      newShowMore[index] = !newShowMore[index];
      setShowMore(newShowMore);
    },
    [showMore, setShowMore]
  );

  return (
    <GroupContentContainer>
      {logEvents.map((log, index) => (
        <GroupedItemContainer key={"log-" + index}>
          <LogLineHeader>
            <LogDateTime>{format(new Date(log.logDateTime), "dd.MM.yyyy HH:mm")}</LogDateTime>
            <ExtraInfoItem>{log.user !== null ? `by ${log.user}` : undefined}</ExtraInfoItem>
            <ExtraInfo>
              <ExtraInfoItem>
                {log.batchRid !== null && log.batchRid !== -1 ? `Batch ID ${log.batchRid}` : undefined}
              </ExtraInfoItem>
              <ExtraInfoItem>
                {log.executionTimeSeconds !== null ? `processed in ${log.executionTimeSeconds} seconds` : undefined}
              </ExtraInfoItem>
            </ExtraInfo>
          </LogLineHeader>
          {logLineContents(log, showMore[index], () => showMoreToggle(index))}
        </GroupedItemContainer>
      ))}
    </GroupContentContainer>
  );
}

export default LogsList;

const LogLineHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 12px;
  color: ${valmetGreyHeader};
`;

const LogDateTime = styled.div`
  margin-right: 60px;
`;

const LogLineContent = styled.div<{ minimized: boolean }>`
  max-height: ${({ minimized }) => (minimized ? "200px" : "auto")};
  /*transition: height 1s;*/
  overflow: hidden;
`;

const LogLineHeading = styled.h3``;

const LogLineContentShowMorePanel = styled.div<{ show: boolean; minimized: boolean }>`
  display: ${({ show }) => (show ? "block" : "none")};
  ${({ minimized }) => (minimized ? "box-shadow: 0 -10px 10px 1px #eee;" : "box-shadow: 0 -3px 6px #eee;")}
  background-color: white;
  height: 26px;
  border-top: 1px solid #ddd;
  /*margin-bottom: 30px;*/
`;

const ShowMoreButton = styled.button`
  border: 0;
  background: transparent;
  color: #8ab;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  margin-left: 10px;
`;

const LogLineList = styled.ul``;

const LogLineListItem = styled.li``;

const ExtraInfo = styled.div`
  display: flex;
  justify-content: space-evenly;
  color: ${valmetGreyREC};
`;
const ExtraInfoItem = styled.div`
  display: flex;
  min-width: 150px;
  padding-left: 2px;
  padding-right: 2px;
`;

const IconContainer = styled.span`
  margin-left: 10px;
`;

const LogLineKeyword = styled.b`
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 13px;
`;

const LogLineText = styled.span``;
const LogLineTextBreak = styled.span`
  display: table;
`;
