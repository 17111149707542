import {
  FieldProperties,
  ProjectReportingRelationsEditInformation,
  ProjectReportingRelationsInput,
} from "../../../../../common/types";

export enum DetailsField {
  externalOrganisationBusinessGroupId = "externalOrganisation.businessGroupId",
  externalOrganisationBusinessTypeId = "externalOrganisation.businessTypeId",
  externalOrganisationLegalEntityId = "externalOrganisation.legalEntityId",
  externalOrganisationReportingId = "externalOrganisation.reportingId",
  externalOrganisationCustomerId = "externalOrganisation.customerId",
  externalReportingIndustryId = "externalReportingIndustryId",
}

export const checkInputErrors = (
  sectionInput: ProjectReportingRelationsInput | undefined,
  editInformation: ProjectReportingRelationsEditInformation | undefined
): DetailsField[] => {
  if (sectionInput === undefined || editInformation === undefined) return [];

  const errors: DetailsField[] = [];

  const validate = (field: DetailsField, valid: boolean) => {
    if (!valid) errors.push(field);
  };

  const mandatoryNotNull = (value: string | number | null, props: FieldProperties): boolean => {
    return !((value === null || value === undefined) && props.nullable !== null && !props.nullable);
  };

  validate(
    DetailsField.externalOrganisationBusinessGroupId,
    mandatoryNotNull(
      sectionInput.externalOrganisation.businessGroupId,
      editInformation.externalOrganisation.businessGroupProperties
    )
  );
  validate(
    DetailsField.externalOrganisationBusinessTypeId,
    mandatoryNotNull(
      sectionInput.externalOrganisation.businessTypeId,
      editInformation.externalOrganisation.businessTypeProperties
    )
  );
  validate(
    DetailsField.externalOrganisationLegalEntityId,
    mandatoryNotNull(
      sectionInput.externalOrganisation.legalEntityId,
      editInformation.externalOrganisation.legalEntityProperties
    )
  );
  validate(
    DetailsField.externalOrganisationReportingId,
    mandatoryNotNull(
      sectionInput.externalOrganisation.reportingId,
      editInformation.externalOrganisation.reportingProperties
    )
  );
  validate(
    DetailsField.externalOrganisationCustomerId,
    mandatoryNotNull(
      sectionInput.externalOrganisation.customerId,
      editInformation.externalOrganisation.customerProperties
    )
  );
  validate(
    DetailsField.externalReportingIndustryId,
    mandatoryNotNull(
      sectionInput.externalOrganisation.externalReportingIndustryId,
      editInformation.externalOrganisation.externalReportingIndustryProperties
    )
  );
  return errors;
};
