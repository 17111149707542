import React from "react";
import { useQuery } from "@apollo/client/react/hooks";

import { ProjectId } from "../../../../common/types";

import Comments from "./Comments";
import { ProjectCommentPackagesResult } from "./types";
import { GET_COMMENT_PACKAGES } from "./queries";

interface Props {
  projectId: ProjectId;
}

function ProjectComments(props: Props): React.ReactElement {
  const { projectId } = props;

  const { data, loading, error } = useQuery<{ projectCommentPackages: ProjectCommentPackagesResult }>(
    GET_COMMENT_PACKAGES,
    {
      variables: { projectId },
    }
  );

  const projectPackages = data && data.projectCommentPackages.packages;
  const totalPackages = data && (data.projectCommentPackages.totalPackages || []);
  const subtotalPackages = data && (data.projectCommentPackages.subtotalPackages || []);
  const totalNodeName = (data && data.projectCommentPackages.totalNodeName) || null;
  const subtotalNodeName = (data && data.projectCommentPackages.subtotalNodeName) || null;
  const isMainProject = (data && data.projectCommentPackages.isMainProject) || false;

  return (
    <Comments
      projectId={projectId}
      projectPackages={projectPackages}
      totalPackages={totalPackages}
      subtotalPackages={subtotalPackages}
      totalNodeName={totalNodeName}
      subtotalNodeName={subtotalNodeName}
      isMainProject={isMainProject}
      isTotal={false}
      loading={loading}
      error={error}
    />
  );
}

export default ProjectComments;
