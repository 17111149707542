import React, { useCallback, useEffect } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { useQuery } from "@apollo/client/react/hooks";
import { ThunkDispatch } from "redux-thunk";
import { Action } from "redux";

import {
  AppState,
  InputValue,
  ProjectId,
  ProjectIntegrations,
  ProjectIntegrationsEditInformation,
  ProjectIntegrationsSectionInput,
  ProjectIntegrationsVisibility,
} from "../../../../../common/types";
import { ToggleSwitch } from "../../../../../common/components";
import { defaultGrey, settingGreen } from "../../../../../common/colors";
import { GET_PROJECT_INTEGRATIONS_EDIT_INFORMATION } from "./queries";
import LoadingView from "../../../../LoadingView";
import { setProjectIntegrations } from "../../../../../actions/projectActions";
import { getEmptyProjectIntegrationsSectionInput } from "../../../../../common/constants";
import EditableSearchableDropdown from "../EditableComponents/EditableSearchableDropdown";
import { parseNumberOrNull } from "../../../../../common/utils";
import Tooltip from "../../../../Tooltip";
import { PITProgressTooltip } from "./PITProgressTooltip";
import { ProfitPITProgressValues, ProfitStatusValues, SelectableProfitStatuses } from "./constants";
import { ProfitPITProgressId, ProfitStatusId } from "./types";

interface EditDetailsProps {
  projectId: ProjectId;
  integrations: ProjectIntegrations;
  visibility: ProjectIntegrationsVisibility;
}

const mapStateToProps = (state: AppState) => {
  return {
    projectIntegrationsInput: state.projectState.projectInput
      ? state.projectState.projectInput.projectIntegrations
      : getEmptyProjectIntegrationsSectionInput(),
    editInformation: state.projectState.projectDetailsEditInformation
      ? state.projectState.projectDetailsEditInformation.integrations
      : undefined,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, void, Action>) => {
  return {
    setProjectIntegrationsInput: (input: ProjectIntegrationsSectionInput) => dispatch(setProjectIntegrations(input)),
  };
};

function getOrElse<T>(i: InputValue<T>, defaultValue: T): T {
  return i !== null ? i : defaultValue;
}

const yesNo = (b: boolean) => (b ? "Yes" : "No");

function EditDetails(
  props: EditDetailsProps & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
) {
  const {
    integrations,
    visibility,
    projectId,
    editInformation,
    projectIntegrationsInput,
    setProjectIntegrationsInput,
  } = props;
  const { isInRadar, radarUrl } = integrations;

  const { data, error, loading } = useQuery(GET_PROJECT_INTEGRATIONS_EDIT_INFORMATION, {
    variables: { projectId: projectId },
    fetchPolicy: "network-only",
    skip: !projectId || editInformation !== undefined,
  });

  const editInfo: ProjectIntegrationsEditInformation | undefined = editInformation
    ? editInformation
    : !loading && !error && data
    ? data.projectDetailsEditInformation.integrations
    : undefined;

  useEffect(() => {
    setProjectIntegrationsInput({
      input: {
        sendToRadar: integrations.sendToRadar,
        profitStatusId: integrations.profitStatusId,
        profitPITProgressId: integrations.profitPITProgressId,
        profitRecPercentage: null,
      },
      pristine: true,
    });
  }, [integrations, editInfo]);

  const sendToRadar = getOrElse(projectIntegrationsInput.input.sendToRadar, integrations.sendToRadar);
  const profitStatusId = getOrElse(projectIntegrationsInput.input.profitStatusId, integrations.profitStatusId);
  const profitStatus = (() => {
    const status = ProfitStatusValues.find(v => v.id === profitStatusId);
    return status ? status : ProfitStatusValues[0];
  })();
  const selectableProfitStatuses = SelectableProfitStatuses[profitStatus.id];
  const profitPITProgressId = getOrElse(
    projectIntegrationsInput.input.profitPITProgressId,
    integrations.profitPITProgressId
  );
  const profitPITProgress = (() => {
    const progress = ProfitPITProgressValues.find(v => v.id === profitPITProgressId);
    return progress ? progress : { id: -1, description: "-" };
  })();

  const toggleSendToRadar = useCallback(
    () =>
      setProjectIntegrationsInput({
        input: { ...projectIntegrationsInput.input, sendToRadar: !sendToRadar },
        pristine: false,
      }),
    [projectIntegrationsInput, setProjectIntegrationsInput]
  );

  const setProfitStatus = useCallback(
    (profitStatusId: ProfitStatusId | null) =>
      setProjectIntegrationsInput({
        input: { ...projectIntegrationsInput.input, profitStatusId },
        pristine: false,
      }),
    [projectIntegrationsInput, setProjectIntegrationsInput]
  );

  const setProfitPITProgress = useCallback(
    (profitPITProgressId: ProfitPITProgressId | null, profitRecPercentage: number | null) => {
      if (integrations.profitPITProgressId != profitPITProgressId) {
        setProjectIntegrationsInput({
          input: { ...projectIntegrationsInput.input, profitPITProgressId, profitRecPercentage },
          pristine: false,
        });
      }
    },
    [projectIntegrationsInput, setProjectIntegrationsInput]
  );

  console.log("INPUT: pristine=", projectIntegrationsInput.pristine, "input=", projectIntegrationsInput.input);
  console.log("integrations:", integrations);
  console.log("edit info:", editInfo);

  const isEditable = (fn: (e: ProjectIntegrationsEditInformation) => boolean) => editInfo && fn(editInfo);

  const sendToRadarIsEditable = isEditable(e => e.sendToRadar.editable);
  const sendToRadarTooltip = !sendToRadarIsEditable
    ? sendToRadar
      ? "Project is already sent to Radar"
      : "Project must be actual and active to enable sending project to Radar"
    : undefined;
  const profitStatusIsEditable = isEditable(e => e.profitStatusId.editable);
  const profitPITProgressIsEditable = isEditable(e => e.profitPITProgressId.editable);

  console.log("PIT progress visibility", visibility.profitPITProgressId);

  const orUndefined = (x: string | null | undefined): string | undefined => (x == null ? undefined : x);

  return (
    <Container>
      {loading ? (
        <LoadingContainer>
          <LoadingView />
        </LoadingContainer>
      ) : (
        <>
          <CodeSection>
            <InformationItem>
              <TitleItem>Send to Radar:</TitleItem>
              <Tooltip message={sendToRadarTooltip}>
                <DataItem>
                  {sendToRadarIsEditable ? (
                    <ToggleSwitch checked={sendToRadar} text={yesNo(sendToRadar)} onChange={toggleSendToRadar} />
                  ) : (
                    yesNo(sendToRadar)
                  )}
                </DataItem>
              </Tooltip>
            </InformationItem>
            {isInRadar && (
              <InformationItem>
                <TitleItem>Radar Link</TitleItem>
                <DataItem>
                  {radarUrl ? (
                    <Link href={radarUrl} target="_blank" rel="noreferrer">
                      Link
                    </Link>
                  ) : (
                    "-"
                  )}
                </DataItem>
              </InformationItem>
            )}
          </CodeSection>
          {(visibility.profitStatusId || visibility.profitPITProgressId) && (
            <CodeSection>
              {visibility.profitStatusId && (
                <InformationItem>
                  <TitleItem>Profit Status:</TitleItem>
                  <DataItem title={editInfo && orUndefined(editInfo.profitStatusId.message)}>
                    {profitStatusIsEditable ? (
                      <EditableSearchableDropdown
                        value={profitStatus.id}
                        nonNullable={true}
                        onValueChanged={v => setProfitStatus(parseNumberOrNull(v))}
                        options={ProfitStatusValues}
                        disabledValueIds={ProfitStatusValues.filter(
                          value =>
                            selectableProfitStatuses.find(sel => sel === value.id) === undefined &&
                            value.id !== integrations.profitStatusId
                        ).map(value => value.id.toString())}
                      />
                    ) : (
                      profitStatus.description
                    )}
                  </DataItem>
                </InformationItem>
              )}
              {visibility.profitPITProgressId && (
                <>
                  <InformationItem>
                    <TitleItem>
                      <PITProgressTooltip integrations={integrations} />
                    </TitleItem>
                    <DataItem title={editInfo && orUndefined(editInfo.profitPITProgressId.message)}>
                      {profitPITProgressIsEditable ? (
                        <EditableSearchableDropdown
                          value={profitPITProgress.id}
                          nonNullable={true}
                          options={ProfitPITProgressValues}
                          onValueChanged={v => {
                            const progress = parseNumberOrNull(v);
                            // set percent only if progress is set to other than Empty (1)
                            const percent =
                              editInfo && progress !== null && progress > 1
                                ? editInfo.profitPITProgressPercentage
                                : null;
                            setProfitPITProgress(progress, percent);
                          }}
                          disabledValueIds={ProfitPITProgressValues.map(value => value.id)
                            .filter(id => id < (integrations.profitPITProgressId || -1))
                            .map(id => id.toString())}
                        />
                      ) : (
                        profitPITProgress.description +
                        (integrations.profitPITProgressPercentage !== null
                          ? " - " + integrations.profitPITProgressPercentage
                          : "")
                      )}
                    </DataItem>
                  </InformationItem>
                  {profitPITProgressIsEditable && (
                    <InformationItem>
                      {(projectIntegrationsInput.input.profitPITProgressId || 0) > 1 && (
                        <>
                          <TitleItem>EST REC%:</TitleItem>
                          <DataItem>
                            {integrations.profitPITProgressPercentage !== null &&
                              integrations.profitPITProgressPercentage}
                            &#10230;
                            {editInfo && Math.round(editInfo.profitPITProgressPercentage) + " %"}
                          </DataItem>
                        </>
                      )}
                    </InformationItem>
                  )}
                </>
              )}
            </CodeSection>
          )}
        </>
      )}
    </Container>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(EditDetails);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-bottom: 0px;
  color: ${defaultGrey};
`;

const InformationItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding-right: 5px;
`;

const TitleItem = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  font-size: 14px;
  margin-bottom: 4px;
`;

const DataItem = styled.div<{ emphasize?: boolean }>`
  font-weight: bold;
  font-size: ${({ emphasize }) => (emphasize ? "22px" : "14px")};
`;

const CodeSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;

const Link = styled.a`
  color: ${settingGreen};
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;
