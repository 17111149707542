import { FilterValues } from "../common/types";
import { ColumnSetAction, ColumnSet, Column, GeneratedColumn } from "../common/columnsTypes";
export const SET_COLUMN_SET = "SET_COLUMN_SET";
export const ADD_CUSTOM_COLUMN_SET = "ADD_CUSTOM_COLUMN_SET";
export const ADD_COLUMN_TO_SET = "ADD_COLUMN_TO_SET";
export const REMOVE_COLUMN_FROM_SET = "REMOVE_COLUMN_FROM_SET";
export const SET_RECOGNITION_COLUMN_SET = "SET_RECOGNITION_COLUMN_SET";
export const ADD_CUSTOM_RECOGNITION_COLUMN_SET = "ADD_CUSTOM_RECOGNITION_COLUMN_SET";

export const setColumnSet = (columnSet: ColumnSet, filters: FilterValues): ColumnSetAction => ({
  type: SET_COLUMN_SET,
  columnSet: columnSet,
  filters,
});

export const addCustomColumnSet = (columnSet: ColumnSet): ColumnSetAction => ({
  type: ADD_CUSTOM_COLUMN_SET,
  columnSet: columnSet,
});

export const addColumnToSet = (column: Column | GeneratedColumn): ColumnSetAction => ({
  type: ADD_COLUMN_TO_SET,
  column,
});

export const removeColumnFromSet = (column: Column | GeneratedColumn): ColumnSetAction => ({
  type: REMOVE_COLUMN_FROM_SET,
  column,
});

export const setRecognitionColumnSet = (columnSet: ColumnSet): ColumnSetAction => ({
  type: SET_RECOGNITION_COLUMN_SET,
  columnSet: columnSet,
});

export const addCustomRecognitionColumnSet = (columnSet: ColumnSet): ColumnSetAction => ({
  type: ADD_CUSTOM_RECOGNITION_COLUMN_SET,
  columnSet: columnSet,
});
