import React from "react";
import CreateProjectSection from "../CreateProjectSection";
import EditDetails from "./EditDetails";
import { ProjectId, ProjectTag } from "../../../../../common/types";
import styled from "styled-components";
import { defaultGrey } from "../../../../../common/colors";

export interface TagsCreateProjectSectionProps {
  projectId: ProjectId;
  sectionDetails?: ProjectTag[];
}

function TagsCreateProjectSection(props: TagsCreateProjectSectionProps): React.ReactElement {
  const { projectId, sectionDetails } = props;

  return (
    <CreateProjectSection
      header="Project tags"
      loadingErrors={sectionDetails ? undefined : ["No data found for project tags."]}
    >
      <Container>{sectionDetails && <EditDetails projectId={projectId} tags={sectionDetails} />}</Container>
    </CreateProjectSection>
  );
}

export default TagsCreateProjectSection;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-bottom: 0px;
  color: ${defaultGrey};
`;
