import React from "react";
import styled from "styled-components";
import { CostCurrencyScenario, CurrencyScenario } from "../../../../common/types";
import { TabButton, TabButtonsContainer } from "../../../../common/components";

type CurrencyScenarioSelectionProps = {
  currencyScenarios: CurrencyScenario[];
  selectedCurrencyScenario: CurrencyScenario;
  currencyScenarioSelected: (selectedCurrencyScenario: CurrencyScenario) => void;
};

function CurrencyScenarioSelection(props: CurrencyScenarioSelectionProps): React.ReactElement {
  const { currencyScenarios, selectedCurrencyScenario, currencyScenarioSelected } = props;
  return (
    <Container>
      <TabButtonsContainer>
        {currencyScenarios.map(scenario => (
          <TabButton
            key={scenario.id + "-" + scenario.currencyCode}
            onClick={() => currencyScenarioSelected(scenario)}
            selected={
              selectedCurrencyScenario.id + "-" + selectedCurrencyScenario.currencyCode ===
              scenario.id + "-" + scenario.currencyCode
            }
          >
            {scenario.id == CostCurrencyScenario.Custom ? scenario.desc : scenario.id}
          </TabButton>
        ))}
      </TabButtonsContainer>
    </Container>
  );
}

const Container = styled.div`
  display: inline-flex;
`;

export default CurrencyScenarioSelection;
