import { ApolloClient } from "@apollo/client";
import { cloneDeep, filter, find } from "lodash";
import React, { useState } from "react";
import styled from "styled-components";
import { useApolloClient } from "@apollo/client/react/hooks";
import { disabledGrey, valmetGreyLight } from "../../../../common/colors";
import {
  ColumnCopyData,
  CurrencyScenario,
  EstimateCode,
  ProjectCostColumnId,
  ProjectCostsByCurrencyCode,
  ProjectCostsResult,
  ProjectEstimateCodeCostItem,
} from "../../../../common/types";
import FullscreenSpinner from "../../../FullscreenSpinner";
import EstimateCodeSelection from "./ColumnSettingsView/EstimateCodeSelection";
import { GET_PROJECT_COSTS, copyEstimateCodeColumn } from "./queries";
import { updateChildren } from "./utils";
import { ActionButton, ButtonContainer } from "../../../../common/components";

export interface ColumnCopyDialogProps {
  data: ColumnCopyData;
  close: () => void;
  onSaved: () => void;
}

const updateCache = (
  client: ApolloClient<Record<string, unknown>>,
  projectId: number,
  sourceEstimateCodeId: string,
  targetEstimateCodeId: string,
  column: ProjectCostColumnId,
  currencyScenario: CurrencyScenario
) => {
  const projectCostsResult = client.cache.readQuery<ProjectCostsResult>({
    query: GET_PROJECT_COSTS,
    variables: {
      projectId,
    },
  });
  if (projectCostsResult) {
    const foundItemsByCurrency = find(
      projectCostsResult.projectCosts.costItemsByCurrencyCode as ProjectCostsByCurrencyCode[],
      item => item.currencyScenario === currencyScenario.id && item.currencyCode === currencyScenario.currencyCode
    );
    if (foundItemsByCurrency) {
      const sourceEstimateCodeData = find(
        foundItemsByCurrency.costItemsByEstimateCode as ProjectEstimateCodeCostItem[],
        item => (item.estimateCode as string) === sourceEstimateCodeId
      );
      const targetEstimateCodeData = find(
        foundItemsByCurrency.costItemsByEstimateCode as ProjectEstimateCodeCostItem[],
        item => item.estimateCode === targetEstimateCodeId
      );
      if (sourceEstimateCodeData && targetEstimateCodeData) {
        const copyItems = cloneDeep(sourceEstimateCodeData.costItems);
        targetEstimateCodeData.costItems.forEach(item => {
          const updatedItem = find(copyItems, copyItem => copyItem.id === item.id);
          if (updatedItem) {
            item.values[column] = updatedItem.values[column];
            if (item.childItems && updatedItem.childItems)
              updateChildren(item.childItems, updatedItem.childItems, column);
          }
        });
      }
    }
    client.cache.writeQuery({
      query: GET_PROJECT_COSTS,
      variables: {
        projectId,
      },
      data: projectCostsResult,
    });
  }
};

const onClickSave = async (
  client: ApolloClient<Record<string, unknown>>,
  data: ColumnCopyData,
  sourceEstimateCodeId: string,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setError: React.Dispatch<React.SetStateAction<boolean>>,
  onSaved: () => void
) => {
  await copyEstimateCodeColumn(
    client,
    data.projectId,
    data.estimateType,
    sourceEstimateCodeId,
    data.targetEstimateCode,
    setLoading,
    () => {
      updateCache(
        client,
        data.projectId,
        sourceEstimateCodeId,
        data.targetEstimateCode,
        data.column,
        data.currencyScenario
      );
      onSaved();
    },
    setError
  );
};

function ColumnCopyDialog(props: ColumnCopyDialogProps): React.ReactElement | null {
  const { data, close, onSaved } = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [selectedEstimateCode, setSelectedEstimateCode] = useState<EstimateCode | undefined>(undefined);
  const client = useApolloClient() as ApolloClient<Record<string, unknown>>;

  return (
    <Wrapper>
      {loading ? <FullscreenSpinner text="Saving" /> : null}
      <ContentContainer>
        <Title>Copy column values</Title>
        <Info>Select estimate code to be copied:</Info>
        <EstimateCodeContainer>
          <EstimateCodeSelection
            estimateCodes={filter(
              data.estimateCodesAndStatuses.estimateCodes,
              item => item.id !== data.targetEstimateCode
            )}
            onValueSelected={estimateCode => setSelectedEstimateCode(estimateCode)}
            selectedValue={selectedEstimateCode}
          />
        </EstimateCodeContainer>

        <ButtonContainer disabledSave={!selectedEstimateCode}>
          <ActionButton
            onClick={() => {
              if (selectedEstimateCode) {
                onClickSave(client, data, selectedEstimateCode.id, setLoading, setError, onSaved);
              }
            }}
            disabled={!selectedEstimateCode}
          >
            Copy
          </ActionButton>
          <ActionButton onClick={() => close()}>Cancel</ActionButton>
        </ButtonContainer>
        {error && <ErrorContainer>Error copying values.</ErrorContainer>}
      </ContentContainer>
    </Wrapper>
  );
}

export default ColumnCopyDialog;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  width: 100%;
  background-color: ${disabledGrey};
  z-index: 1200;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 25px;
  padding-right: 25px;
  color: ${valmetGreyLight};
  width: 450px;
  align-items: flex-start;
`;

const Title = styled.div`
  font-size: 24px;
`;

const Info = styled.div`
  font-size: 16px;
  margin-top: 20px;
`;

const ErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
  font-weight: bold;
`;

const EstimateCodeContainer = styled.div`
  margin-top: 20px;
  border: 2px solid ${valmetGreyLight};
  padding: 5px;
  padding-right: 0px;
`;
