import React, {useEffect} from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { valmetGreyBorder } from "../../../../common/colors";
import FilterSection from "./FilterSection";
import {
  AppState,
  FilterSelections,
  FilterOption,
  FilterId,
  ProjectType,
  OrganisationKeysResult,
  PersonsResult,
  ProjectTechnicalTypesResult,
  ProjectPhasesResult,
  FinancingMethodsResult,
  CountriesResult,
  ProjectTypesResult,
  ProjectStatusesResult,
  CustomersResult,
  FilterHierarchyOption,
  OrganisationKeyHierarchyResult,
  NumberRange,
} from "../../../../common/types";
import { PROJECT_FILTERS } from "../../../../common/constants";
import { setFilter, resetFilters, resetFilter } from "../../../../actions/filterActions";
import { Dispatch } from "redux";
import { useQuery } from "@apollo/client/react/hooks";
import { find, flatten, values } from "lodash";
import { addColumnToSet, removeColumnFromSet } from "../../../../actions/columnSetActions";
import {
  GET_ORGANISATION_KEY_VALUES,
  GET_FILTER_PERSONS,
  GET_PROJECT_TECHNICAL_TYPES,
  GET_PROJECT_PHASES,
  GET_FINANCING_METHODS,
  GET_COUNTRIES,
  GET_PROJECT_TYPES,
  GET_PROJECT_STATUSES,
  GET_BUSINESS_GROUP_HIERARCHIES,
} from "./queries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { GET_CUSTOMERS } from "../../Project/ProjectBasics/InformationSection/queries";
import { IconButton } from "../../../../common/components";
import { HIERARCHY_COLUMN } from "../../../../common/columns";

const mapStateToProps = (state: AppState) => {
  return {
    filterValues: state.filtersState.filters,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setFilterValue: (id: FilterId, value: string | number | NumberRange | undefined) => {
      if (id === HIERARCHY_COLUMN.id) {
        dispatch(addColumnToSet(HIERARCHY_COLUMN));
      }
      dispatch(setFilter(id, value));
    },
    resetFilters: () => {
      dispatch(resetFilters());
      dispatch(removeColumnFromSet(HIERARCHY_COLUMN));
    },
    resetFilter: (id: FilterId) => {
      dispatch(resetFilter(id));
    },
  };
};

const setFilterData = (filters: FilterSelections, data: FilterOption[] | FilterHierarchyOption[], filterId: string) => {
  const filter = find(flatten(values(filters)), filter => filter.id === filterId);
  if (filter) {
    filter.values = data;
  }
};

type FilterProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    setTypes: React.Dispatch<React.SetStateAction<ProjectType[]>>;
  };

function Filters(props: FilterProps): React.ReactElement {
  const { filterValues, setFilterValue, resetFilters, setTypes, resetFilter } = props;
  const filters = PROJECT_FILTERS;
  const phasesData = useQuery<ProjectPhasesResult>(GET_PROJECT_PHASES, {}).data;
  const typesData = useQuery<ProjectTechnicalTypesResult>(GET_PROJECT_TECHNICAL_TYPES, {}).data;
  const projectTypes = useQuery<ProjectTypesResult>(GET_PROJECT_TYPES, {}).data;
  const projectStatuses = useQuery<ProjectStatusesResult>(GET_PROJECT_STATUSES, {}).data;
  const organisationKeyValues = useQuery<OrganisationKeysResult>(GET_ORGANISATION_KEY_VALUES, {}).data;
  const personsData = useQuery<PersonsResult>(GET_FILTER_PERSONS, {}).data;
  const financingMethods = useQuery<FinancingMethodsResult>(GET_FINANCING_METHODS, {}).data;
  const countries = useQuery<CountriesResult>(GET_COUNTRIES, {}).data;
  const customers = useQuery<CustomersResult>(GET_CUSTOMERS, {}).data;
  const businessGroupHierarchy = useQuery<OrganisationKeyHierarchyResult>(GET_BUSINESS_GROUP_HIERARCHIES, {}).data;

  useEffect(() => {if (phasesData) {
    setFilterData(filters, phasesData.projectPhases, "projectPhase");
  }}, [filters, phasesData])
  useEffect(() => {
    if (typesData) {
      setFilterData(filters, typesData.projectTechnicalTypes, "technicalType");
      setTypes(typesData.projectTechnicalTypes);
    }
  }, [filters, typesData])
  useEffect(() => {
    if (projectTypes) {
      setFilterData(filters, projectTypes.projectTypes, "projectType");
    }
  }, [filters, projectTypes])
  useEffect(() => {
    if (projectStatuses) {
      setFilterData(filters, projectStatuses.projectStatuses, "projectStatus");
    }
  }, [filters, projectStatuses])
  useEffect(() => {
    if (businessGroupHierarchy) {
      setFilterData(filters, businessGroupHierarchy.businessGroupHierarchies, "businessGroup");
    }
  }, [filters, businessGroupHierarchy])
  useEffect(() => {if (organisationKeyValues) {
    setFilterData(filters, organisationKeyValues.organisationKeys.businessTypes, "businessType");
    setFilterData(filters, organisationKeyValues.organisationKeys.legalEntities, "legalEntity");
    setFilterData(filters, organisationKeyValues.organisationKeys.externalLegalEntities, "extLegalEntity");
    setFilterData(filters, organisationKeyValues.organisationKeys.deliveryOrgs, "deliveryOrg");
  }}, [filters, organisationKeyValues])
  useEffect(() => {
    if (personsData) {
      setFilterData(
        filters,
        personsData.persons.map(person => {
          return { id: person.userId, description: `${person.firstName} ${person.lastName}` };
        }),
        "person"
      );
    }
  }, [filters, personsData])
  useEffect(() => {
    if (financingMethods) {
      const options = financingMethods.financingMethods.map(x => ({ id: x, description: x }));
      setFilterData(filters, options, "financingMethod");
    }
  }, [filters, financingMethods])
  useEffect(() => {
    if (countries) {
      const options = countries.countries.map(x => ({ id: x.id, description: x.name }));
      setFilterData(filters, options, "endDestCountry");
    }
  }, [filters, countries])
  useEffect(() => {
    if (customers) {
      const options = customers.customers.map(x => ({ id: x.id, description: x.name }));
      setFilterData(filters, options, "customer");
    }
  }, [filters, customers])

  return (
    <ColumnsContainer>
      <RowsContainer>
        <Container>
          <FilterSection
            extraLarge={true}
            filters={filters.GENERIC}
            filterValues={filterValues}
            onFilterSelected={setFilterValue}
            onResetFilter={resetFilter}
          />
          <FilterSection
            filters={PROJECT_FILTERS.TIME}
            filterValues={filterValues}
            onFilterSelected={setFilterValue}
            onResetFilter={resetFilter}
          />
        </Container>

        <Container>
          <FilterSection
            filters={PROJECT_FILTERS.ADDITIONAL}
            filterValues={filterValues}
            onFilterSelected={setFilterValue}
            onResetFilter={resetFilter}
            extraLarge={true}
          />
          <FilterSection
            filters={filters.REC}
            filterValues={filterValues}
            onFilterSelected={setFilterValue}
            onResetFilter={resetFilter}
          />
        </Container>
      </RowsContainer>
      <IconContainer>
        <IconButton onClick={resetFilters}>
          <FontAwesomeIcon icon={faTimes} size="2x" color={valmetGreyBorder} />
        </IconButton>
      </IconContainer>
    </ColumnsContainer>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Filters);

const ColumnsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const RowsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 1200px) {
    height: 76px;
  }
  height: 53px;
  border-bottom: 1px solid ${valmetGreyBorder};

  > div {
    border-right: 1px solid ${valmetGreyBorder};
  }

  > div:last-child {
    border-right: 0px;
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  border-bottom: 1px solid ${valmetGreyBorder};
  border-left: 1px solid ${valmetGreyBorder};
`;
