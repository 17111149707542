import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Dispatch } from "redux";
import styled from "styled-components";
import { resetProjectHierarchy } from "../../../actions/hierarchyActions";
import { cancelNewProject } from "../../../actions/projectActions";
import { disabledGrey, settingGreen } from "../../../common/colors";
import routes from "../../../common/routes";
import { AccessRight, AppState, HierarchyItem, HierarchyItemEnum, UserProjectAccess } from "../../../common/types";
import HierarchyHeader from "./HierarchyHeader";
import NotificationsView from "./NotificationsView";
import { Tabs } from "./Project";
import { ProjectHeaderMinimizedHeight, ProjectHeaderMinimizedTopMargin } from "./constants";

type ProjectHeaderProps = RouteComponentProps & {
  projectKey: string;
  setTab: (tab: Tabs) => void;
  selectedTab: Tabs;
  hierarchy?: HierarchyItem;
  id?: number;
  itemType: HierarchyItemEnum;
  userProjectAccess: UserProjectAccess;
  commentsEditActionsRef: React.RefObject<HTMLDivElement>;
  createProjectActionsRef: React.RefObject<HTMLDivElement>;
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    cancelNewProject: () => {
      dispatch(cancelNewProject());
      dispatch(resetProjectHierarchy());
    },
  };
};

const mapStateToProps = (state: AppState) => {
  return {
    projectCreationErrors:
      state.projectState.projectErrors.project ||
      state.projectState.projectErrors.date ||
      state.projectState.projectErrors.currency ||
      state.projectState.projectErrors.relations ||
      state.projectState.projectErrors.roles ||
      state.recognitionEditState.recognitionsErrors.length > 0,
    selectedParentNodeId: state.hierarchyState.parentNodeId,
    pendingChanges: state.projectState.pendingChanges,
  };
};

const useScrollY = (scrollThreshold: number, handler: (scrolledPastThreshold: boolean) => void) => {
  useEffect(() => {
    const eventListener = () => {
      if (window.scrollY > scrollThreshold) {
        handler(true);
      } else {
        handler(false);
      }
    };
    window.addEventListener("scroll", eventListener);
    return () => {
      window.removeEventListener("scroll", eventListener);
    };
  }, [scrollThreshold, handler]);
};

function ProjectHeader(
  props: ProjectHeaderProps & ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>
): React.ReactElement {
  const {
    projectKey,
    setTab,
    selectedTab,
    hierarchy,
    history,
    id,
    itemType,
    userProjectAccess,
    commentsEditActionsRef,
    createProjectActionsRef,
  } = props;

  const [minimized, setMinimized] = useState(false);
  useScrollY(130, shouldMinimize => setMinimized(shouldMinimize));

  const onItemSelected = (item: HierarchyItem) => {
    if (item.itemType === HierarchyItemEnum.Project) {
      history.push(`${routes.PROJECT}/${item.id}`);
    } else if (item.itemType === HierarchyItemEnum.Node) {
      history.push(`${routes.NODE}/${item.id}`);
    }
  };

  switch (itemType) {
    case HierarchyItemEnum.NewProject:
      return (
        <Container minimized={minimized}>
          <ContentContainer minimized={minimized}>
            <HeaderRow>
              <TitleContainer>
                <Title minimized={minimized}>{projectKey}</Title>
              </TitleContainer>
              <TabRow>
                <TabContainer hidden={minimized}>
                  <TabItem
                    onClick={() => {
                      setTab(Tabs.Basics);
                    }}
                    emphasize={selectedTab === Tabs.Basics}
                    disabled={selectedTab !== Tabs.Basics}
                  >
                    Basics
                  </TabItem>
                  <TabItem
                    onClick={() => {
                      setTab(Tabs.Hierarchy);
                    }}
                    emphasize={selectedTab === Tabs.Hierarchy}
                    disabled={selectedTab !== Tabs.Hierarchy}
                  >
                    Hierarchy
                  </TabItem>
                  <TabItem
                    onClick={() => setTab(Tabs.REC)}
                    emphasize={selectedTab === Tabs.REC}
                    disabled={selectedTab !== Tabs.REC}
                  >
                    REC
                  </TabItem>
                </TabContainer>
              </TabRow>
            </HeaderRow>
            <HeaderActionsContainer minimized={minimized} ref={createProjectActionsRef} />
          </ContentContainer>
        </Container>
      );
    case HierarchyItemEnum.Project:
      return (
        <Container minimized={minimized}>
          <ContentContainer minimized={minimized}>
            <HeaderRow>
              <TitleContainer>
                <Title minimized={minimized}>{projectKey}</Title>
                {hierarchy && id !== undefined && (
                  <HierarchyHeader
                    hierarchy={hierarchy}
                    onItemSelected={onItemSelected}
                    id={id}
                    itemType={HierarchyItemEnum.Project}
                  />
                )}
              </TitleContainer>
              {id !== undefined && <NotificationsView itemType={itemType} id={id} />}
              <TabRow>
                <TabContainer hidden={minimized}>
                  <TabItem emphasize={selectedTab === Tabs.Overview} disabled={true}>
                    Overview
                  </TabItem>
                  <TabItem
                    onClick={() => setTab(Tabs.Basics)}
                    emphasize={selectedTab === Tabs.Basics}
                    disabled={!userProjectAccess.has(AccessRight.ViewProjectCard)}
                  >
                    Basics
                  </TabItem>
                  <TabItem
                    onClick={() => setTab(Tabs.REC)}
                    emphasize={selectedTab === Tabs.REC}
                    disabled={!userProjectAccess.has(AccessRight.ViewRecognitions)}
                  >
                    REC
                  </TabItem>
                  <TabItem disabled={true}>Invoicing</TabItem>
                  <TabItem
                    onClick={() => setTab(Tabs.Costs)}
                    emphasize={selectedTab === Tabs.Costs}
                    disabled={!userProjectAccess.has(AccessRight.ViewCosts)}
                  >
                    Costs
                  </TabItem>
                  <TabItem
                    onClick={() => setTab(Tabs.Changelog)}
                    emphasize={selectedTab === Tabs.Changelog}
                    disabled={
                      !userProjectAccess.has(AccessRight.ViewProjectCard) || userProjectAccess.hasCostsAccessOnly()
                    }
                  >
                    Changelog
                  </TabItem>
                  <TabItem
                    onClick={() => setTab(Tabs.Comments)}
                    emphasize={selectedTab === Tabs.Comments}
                    disabled={!userProjectAccess.has(AccessRight.ViewFinancialComments)}
                  >
                    Comments
                  </TabItem>
                </TabContainer>
              </TabRow>
            </HeaderRow>
            <HeaderActionsContainer minimized={minimized} ref={commentsEditActionsRef} />
          </ContentContainer>
        </Container>
      );
    case HierarchyItemEnum.Node:
      return (
        <Container minimized={minimized}>
          <ContentContainer minimized={minimized}>
            <HeaderRow>
              <TitleContainer>
                <Title minimized={minimized}>{projectKey}</Title>
                {hierarchy && id !== undefined && (
                  <HierarchyHeader
                    hierarchy={hierarchy}
                    onItemSelected={onItemSelected}
                    id={id}
                    itemType={HierarchyItemEnum.Node}
                  />
                )}
              </TitleContainer>

              <TabRow>
                <TabContainer hidden={minimized}>
                  <TabItem
                    onClick={() => setTab(Tabs.Overview)}
                    emphasize={selectedTab === Tabs.Overview}
                    disabled={true}
                  >
                    Overview
                  </TabItem>
                  <TabItem onClick={() => setTab(Tabs.Basics)} emphasize={selectedTab === Tabs.Basics} disabled={false}>
                    Basics
                  </TabItem>
                  <TabItem onClick={() => setTab(Tabs.REC)} emphasize={selectedTab === Tabs.REC} disabled={false}>
                    REC
                  </TabItem>
                  <TabItem disabled={true}>Invoicing</TabItem>
                  <TabItem onClick={() => setTab(Tabs.Costs)} emphasize={selectedTab === Tabs.Costs} disabled={true}>
                    Costs
                  </TabItem>
                  <TabItem
                    onClick={() => setTab(Tabs.Changelog)}
                    emphasize={selectedTab === Tabs.Changelog}
                    disabled={true}
                  >
                    Changelog
                  </TabItem>
                  <TabItem
                    onClick={() => setTab(Tabs.Comments)}
                    emphasize={selectedTab === Tabs.Comments}
                    disabled={false}
                  >
                    Comments
                  </TabItem>
                </TabContainer>
              </TabRow>
            </HeaderRow>
            <HeaderActionsContainer minimized={minimized} ref={commentsEditActionsRef} />
          </ContentContainer>
        </Container>
      );
  }
}

ProjectHeader.minimizedTopMargin = ProjectHeaderMinimizedTopMargin;
ProjectHeader.minimizedHeight = ProjectHeaderMinimizedHeight;
const transition = "0.36s";

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProjectHeader));

const Container = styled.div<{ minimized: boolean }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  @media (min-width: 1600px) {
    padding-right: 250px;
    padding-left: 250px;
  }
  @media (max-width: 1600px) {
    padding-right: 25px;
    padding-left: 25px;
  }
  ${({ minimized }) => (minimized ? "box-shadow: 0 5px 5px #c8c4c4;" : "")}
  transition: ${transition};
`;

const ContentContainer = styled.div<{ minimized: boolean }>`
  display: inline-flex;
  flex-direction: row;
  /*flex-direction: ${({ minimized }) => (minimized ? "row" : "column")};*/
  justify-content: space-between;
  height: 100%;
  margin-top: ${({ minimized }) => (minimized ? ProjectHeader.minimizedTopMargin : "0")};
  transition: ${transition};
  padding-bottom: 20px;
`;

const HeaderRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`;

const Title = styled.h1<{ minimized: boolean }>`
  font-size: ${({ minimized }) => (minimized ? "16px" : "24px")};
  /*height: ${({ minimized }) => (minimized ? "auto" : "48px")};*/
  transition: ${transition};
`;

const TabContainer = styled.div<{ hidden?: boolean }>`
  position: absolute;
  ${({ hidden }) => (hidden === true ? "display: none" : "display: flex")};
  transition: opacity ${transition};
`;
const TabRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;
const HeaderActionsContainer = styled.div<{ minimized: boolean }>`
  position: relative;
  transition: ${transition};
  align-self: flex-end;
`;

const TabItem = styled.button<{ emphasize?: boolean; disabled?: boolean }>`
  background-color: transparent;
  outline: none;
  border: none;
  font-size: 14px;
  margin-right: 20px;
  padding: 0;
  text-decoration: ${({ emphasize }) => (emphasize ? "underline" : "")};
  font-weight: ${({ emphasize }) => (emphasize ? "bold" : "")};
  cursor: pointer;
  color: ${({ disabled }) => (disabled ? `${disabledGrey}` : `${settingGreen}`)};
  display: flex;
`;
