import React from "react";
import styled from "styled-components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTimes } from "@fortawesome/free-solid-svg-icons";

import { CostEstimationStatus, ProjectCostsEditing } from "../../../../common/types";
import { POPCostEstimationIndicator } from "./POPCostEstimationIndicator";
import { cancelRed, disabledGrey, valmetGreyTable } from "../../../../common/colors";

type ProjectCostEstimationStatusProps = {
  costEstimationStatus: CostEstimationStatus;
  editing: ProjectCostsEditing;
};

function ProjectCostEstimationStatus(props: ProjectCostEstimationStatusProps) {
  const { costEstimationStatus: status, editing } = props;
  return (
    <Container>
      <POPCostEstimationIndicator status={status} />
      {!editing.enabled && editing.disabledReason !== null && (
        <StatusContainer title="Editing is disabled">
          <div>
            <FontAwesomeIcon icon={faEdit} color={disabledGrey} />
            <OverlayIcon behind={true} icon={faTimes} color={valmetGreyTable} />
            <OverlayIcon behind={false} icon={faTimes} color={cancelRed} />
          </div>
          {editing.disabledReason}
        </StatusContainer>
      )}
    </Container>
  );
}

export default ProjectCostEstimationStatus;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
`;

const StatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
`;

const OverlayIcon = styled(({ behind, ...rest }) => <FontAwesomeIcon {...rest} />)<{ behind: boolean }>`
  position: absolute;
  margin-left: ${({ behind }) => (behind ? "-21px" : "-21px")};
  margin-top: ${({ behind }) => (behind ? "6px" : "8px")};
  font-size: ${({ behind }) => (behind ? "1.2em" : "1em")};
`;
