import React from "react";
import styled from "styled-components";
import { EstimateCode } from "../../../../../../common/types";
import EstimateCodeDropdown from "./EstimateCodeDropdown";

export interface EstimateCodeSelectionProps {
  estimateCodes?: EstimateCode[];
  selectedValue?: EstimateCode;
  onValueSelected: (value: EstimateCode) => void;
}

function EstimateCodeSelection(props: EstimateCodeSelectionProps): React.ReactElement {
  const { estimateCodes, selectedValue, onValueSelected } = props;

  return (
    <Container>
      <SelectionContainer>
        {estimateCodes && (
          <EstimateCodeDropdown selections={estimateCodes} onValueSelected={onValueSelected} value={selectedValue} />
        )}
        {!estimateCodes && <LoadingContainer>No estimate codes</LoadingContainer>}
      </SelectionContainer>
    </Container>
  );
}

export default EstimateCodeSelection;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  font-size: 12px;
`;

const SelectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 12px;
  margin-left: 20px;
`;

const LoadingContainer = styled.div``;
