import React from "react";
import { ButtonContainer, IconButton } from "../../../../common/components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { disabledGrey, settingGreen } from "../../../../common/colors";
import styled from "styled-components";

interface SectionEditingButtonContainerProps {
  saving: boolean;
  saveEnabled: boolean;
  onCancelEdit: () => void;
  onSave: () => void;
}

function SectionEditingButtonContainer(props: SectionEditingButtonContainerProps): React.ReactElement {
  const { saving, saveEnabled, onCancelEdit, onSave } = props;

  const disabledSave = saving || !saveEnabled;
  return (
    <ButtonContainer disabledSave={disabledSave}>
      {!saving ? (
        <IconButton disabled={disabledSave} onClick={onSave} hasText={true}>
          Save
          <FontAwesomeIcon icon={faSave} size="1x" color={settingGreen} />
        </IconButton>
      ) : (
        <SavingText>
          Saving...
          <FontAwesomeIcon icon={faSpinner} size="1x" color={disabledGrey} spin={true} />
        </SavingText>
      )}

      <IconButton onClick={onCancelEdit} hasText={true}>
        Cancel
      </IconButton>
    </ButtonContainer>
  );
}

export default SectionEditingButtonContainer;

const SavingText = styled.div`
  font-size: 14px;
  svg {
    margin-left: 5px;
  }
`;
