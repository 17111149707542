import React from "react";
import styled from "styled-components";
import { defaultGrey, projectsYellow, projectsYellowTransparent } from "../../../../common/colors";
import TopItem, { TopItemData } from "./TopItem";

interface TopGroupProps {
  title: string;
  data: TopItemData[];
}

function TopGroup(props: TopGroupProps): React.ReactElement {
  const { title, data } = props;
  return (
    <Container>
      <TitleContainer>
        {`TOP 5 - `}
        <Title>{title}</Title>
      </TitleContainer>
      <ContentContainer>
        {data.map((item, i) => (
          <TopItem data={item} key={i} />
        ))}
      </ContentContainer>
    </Container>
  );
}

export default TopGroup;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 260px;
`;

const TitleContainer = styled.div`
  font-size: 12px;
  color: ${defaultGrey};
`;

const Title = styled.span`
  font-weight: 600;
`;

const ContentContainer = styled.div`
  background: ${projectsYellowTransparent};
  border-left: 4px solid ${projectsYellow};
  margin-top: 10px;
  > div:last-child {
    border: 0;
  }
`;
