import { EntityTypeId, HierarchyItem, ProjectTypes } from "./types";

export interface HierarchyProjectsFilters {
  filterActive: boolean;
  hideProjectTypes: ProjectTypes[];
  hideProjectTechnicalTypes: EntityTypeId[];
}

export const inactiveHierarchyProjectsFilters: HierarchyProjectsFilters = {
  filterActive: false,
  hideProjectTypes: [],
  hideProjectTechnicalTypes: [],
};

export const isHierarchyItemVisible = (item: HierarchyItem, filters: HierarchyProjectsFilters) =>
  !filters.hideProjectTypes.includes(item.projectTypeId as ProjectTypes) &&
  !filters.hideProjectTechnicalTypes.includes(item.projectTechnicalTypeId as EntityTypeId);
