import React from "react";
import styled from "styled-components";

import { disabledGrey, valmetGreyLight, settingGreen, cancelRed } from "../../../../../common/colors";
import { ActionButton } from "../../../../../common/components";

export interface MessagesDialogProps {
  onOk: () => void;
  title: string;
  messages: string[];
}

function MessagesDialog(props: MessagesDialogProps): React.ReactElement | null {
  const { onOk, title, messages } = props;

  return (
    <Wrapper>
      <ContentContainer>
        <Title>{title}</Title>
        {messages.map((message, index) => (
          <Info key={"message-" + index}>{message}</Info>
        ))}
        <ButtonContainer>
          <ActionButton onClick={() => onOk()}>Ok</ActionButton>
        </ButtonContainer>
      </ContentContainer>
    </Wrapper>
  );
}

export default MessagesDialog;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  width: 100%;
  background-color: ${disabledGrey};
  z-index: 1200;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 25px;
  padding-right: 25px;
  color: ${valmetGreyLight};
  width: 450px;
  align-items: flex-start;
`;

const Title = styled.div`
  font-size: 24px;
`;

const Info = styled.div`
  font-size: 16px;
  margin-top: 20px;
`;

const ButtonContainer = styled.div<{ disabledSave?: boolean; buttonLoading?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  button {
    font-size: 16px;
    font-weight: bold;
  }
  > button:first-child {
    color: ${({ disabledSave, buttonLoading }) =>
      disabledSave || buttonLoading ? `${disabledGrey}` : `${settingGreen}`};
  }
  > button:last-child {
    color: ${cancelRed};
  }
`;
