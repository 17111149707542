import React, { useState } from "react";
import { Bar, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis } from "recharts";
import { commentBlue, disabledGrey, settingGreen } from "../../../../../common/colors";
import styled from "styled-components";
import { ProjectCostActivityDataPoint, ProjectCostSummaryRow } from "../../../../../common/types";

export interface CostActualsCommittedCharProps {
  selectedActivityId: string;
  dataPoints: ProjectCostActivityDataPoint[];
  contentWidth: number;
  activityRows: ProjectCostSummaryRow[];
}

const findChildActivityIds = (activityId: string, activityRows: ProjectCostSummaryRow[]): string[] => {
  const foundRow = activityRows.find(row => row.id === activityId);
  return foundRow ? foundRow.childItemIds || [] : [];
};

const findLeafActivityIds = (activityId: string, activityRows: ProjectCostSummaryRow[]): string[] => {
  const childActivityIds = findChildActivityIds(activityId, activityRows);
  if (childActivityIds.length > 0) {
    const leafActivityIds: string[] = [];
    childActivityIds
      .map(childId => findLeafActivityIds(childId, activityRows))
      .map(ids => ids.map(id => leafActivityIds.push(id)));
    return leafActivityIds;
  } else return [activityId];
};

const sumDataPointsByPeriods = (
  accumulator: ProjectCostActivityDataPoint[],
  currentValue: ProjectCostActivityDataPoint
): ProjectCostActivityDataPoint[] => {
  const currentPeriod = currentValue.description;
  const index = accumulator.findIndex(value => value.description === currentPeriod);
  if (index > -1) {
    accumulator[index] = {
      activityId: accumulator[index].activityId,
      description: currentPeriod,
      actuals: accumulator[index].actuals + currentValue.actuals,
      committed: accumulator[index].committed + currentValue.committed,
    };
  } else {
    accumulator.push({
      activityId: currentValue.activityId,
      description: currentPeriod,
      actuals: currentValue.actuals,
      committed: currentValue.committed,
    });
  }
  return accumulator;
};

function CostActualsCommittedChart(props: CostActualsCommittedCharProps): React.ReactElement {
  const { selectedActivityId, dataPoints, contentWidth, activityRows } = props;

  const [commitedsInChart, setCommittedsInChart] = useState(false);
  const [actualsInChart, setActualsInChart] = useState(true);

  const activityDescription = activityRows.find(row => row.id === selectedActivityId)?.description;
  const selectedLeafActivityIds = findLeafActivityIds(selectedActivityId, activityRows);
  const selectedDataPoints = dataPoints.filter(point => selectedLeafActivityIds.includes(point.activityId));
  const sumDataPoints = selectedDataPoints.reduce(sumDataPointsByPeriods, []);

  return (
    <ChartContainer>
      <ChartTitle>Actuals and committed {activityDescription ? `(${activityDescription})` : ""}</ChartTitle>
      <BarChart
        width={contentWidth}
        height={300}
        data={sumDataPoints}
        margin={{ top: 20, right: 20, left: 20, bottom: 0 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="description" tick={{ fontSize: 10 }} />
        <YAxis tick={{ fontSize: 10 }} />
        <Tooltip contentStyle={{ fontSize: "10px" }} />
        <Legend
          wrapperStyle={{ fontSize: "10px" }}
          onClick={event => {
            if (event.value.includes("Committed")) {
              setCommittedsInChart(!commitedsInChart);
            }
            if (event.value.includes("Actuals")) {
              setActualsInChart(!actualsInChart);
            }
          }}
        />
        <Bar
          dataKey={"actuals"}
          stackId="a"
          fill={actualsInChart ? settingGreen : disabledGrey}
          name={`Actuals${actualsInChart ? "" : " (hidden)"}`}
          hide={!actualsInChart}
        />
        <Bar
          dataKey={"committed"}
          stackId="b"
          fill={commitedsInChart ? commentBlue : disabledGrey}
          name={`Committed${commitedsInChart ? "" : " (hidden)"}`}
          hide={!commitedsInChart}
        />
      </BarChart>
    </ChartContainer>
  );
}

export default CostActualsCommittedChart;

const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
`;

const ChartTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
`;
