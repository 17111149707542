import { produce } from "immer";
import { parseInt, remove } from "lodash";
import { ProjectActionType, ProjectStateAction } from "../actions/projectActions";
import { getEmptyProjectInput } from "../common/constants";
import {
  HierarchyItem,
  HierarchyItemEnum,
  ProjectDescriptionEditInformation,
  ProjectDetailsEditInformation,
  ProjectId,
  ProjectState,
} from "../common/types";

/*Project creation mode: update data in local storage, so if users close and open /create-project page again or refresh page, they can continue with the same project.
 * 1. initialize project data with local storage.
 * 2. update data in both Redux state and local storage.
 * 3. clear data from both Redux state and local storage if project creation is cancelled or done.*/
const savedProjectCreationId = () => {
  return localStorage.getItem("projectCreationId");
};
const savedProjectCreationRelatingId = () => {
  return localStorage.getItem("projectCreationRelatingId");
};

const validateSavedEditInformation = (originEditInfo: ProjectDetailsEditInformation): ProjectDetailsEditInformation => {
  // Check if new field, project coordinator, exists in users' local storage.
  if (
    originEditInfo.projectRoles !== undefined &&
    originEditInfo.projectRoles.projectCoordinatorProperties === undefined
  ) {
    return {
      ...originEditInfo,
      projectRoles: undefined,
    };
  } else {
    return originEditInfo;
  }
};
const savedProjectCreationDetailsEditInformation = (): ProjectDetailsEditInformation => {
  const localStorageInfo: any = localStorage.getItem("projectCreationDetailsEditInformation");
  if (localStorageInfo === null) {
    return {
      projectInformation: undefined,
      projectDates: undefined,
      projectCurrency: undefined,
      // Under project creation mode, relations section is loaded in project creation dialog where projectId is not defined yet.
      // So we need to predefine its edit information while initializing.
      relations: { values: [], properties: { editable: true, nullable: false } },
      projectRoles: undefined,
      integrations: undefined,
      reportingRelations: undefined,
    };
  } else {
    return validateSavedEditInformation(JSON.parse(localStorageInfo));
  }
};
const savedNodeHierarchyApplicationModifiedDateTime = () => {
  return localStorage.getItem("nodeHierarchyApplicationModifiedDateTime");
};
const savedNodeHierarchyId = () => {
  return localStorage.getItem("nodeHierarchyId");
};

function removeProjectCreationLocalStorage() {
  localStorage.removeItem("projectCreationId");
  localStorage.removeItem("projectCreationRelatingId");
  localStorage.removeItem("projectCreationDetailsEditInformation");
  localStorage.removeItem("projectCreationLastSelectedTab");
  localStorage.removeItem("projectCreationPolling");
  localStorage.removeItem("nodeHierarchyApplicationModifiedDateTime");
  localStorage.removeItem("nodeHierarchyId");
}

function updateDetailsEditInformation(newState: ProjectState, editInformation: ProjectDetailsEditInformation) {
  newState.projectDetailsEditInformation = editInformation;
  if (newState.projectCreationMode) {
    localStorage.setItem("projectCreationDetailsEditInformation", JSON.stringify(editInformation));
  }
}

export const initState: ProjectState = {
  projectId: undefined,
  projectCreationMode: false,
  projectInput: undefined,
  projectTypeSelected: false,
  projectErrors: {
    project: false,
    date: false,
    currency: false,
    relations: false,
    roles: false,
  },
  projectDetailsEditInformation: undefined,
  projectRelatingId: undefined,
  pendingChanges: [],
  hierarchy: undefined,
  projectApplicationModifiedDateTime: undefined,
  nodeHierarchyApplicationModifiedDateTime: undefined,
  nodeHierarchyId: undefined,
};

export const initNewProjectAction = (initState: ProjectState): ProjectState => {
  return produce(initState, newState => {
    newState.projectInput = getEmptyProjectInput();
    newState.projectCreationMode = true;
    const savedProjectId = savedProjectCreationId();
    newState.projectId = savedProjectId ? parseInt(savedProjectId) : undefined;
    newState.projectDetailsEditInformation = savedProjectCreationDetailsEditInformation();

    const savedRelatingId = savedProjectCreationRelatingId();
    newState.projectRelatingId = savedRelatingId ? parseInt(savedRelatingId) : undefined;
    newState.nodeHierarchyApplicationModifiedDateTime = savedNodeHierarchyApplicationModifiedDateTime() || undefined;
    const savedNodeHId = savedNodeHierarchyId();
    newState.nodeHierarchyId = savedNodeHId ? parseInt(savedNodeHId) : undefined;
  });
};

export const initProjectAction = (initState: ProjectState, initProjectId: ProjectId): ProjectState => {
  return {
    ...initState,
    projectId: initProjectId,
    projectCreationMode: false,
    projectRelatingId: undefined,
    projectDetailsEditInformation: undefined,
    projectApplicationModifiedDateTime: undefined,
    nodeHierarchyApplicationModifiedDateTime: undefined,
    nodeHierarchyId: undefined,
  };
};

export default function projectReducer(
  state: Readonly<ProjectState> = initState,
  action: ProjectStateAction
): ProjectState {
  switch (action.type) {
    case ProjectActionType.INIT_NEW_PROJECT:
      return initNewProjectAction(state);
    case ProjectActionType.CANCEL_NEW_PROJECT:
      removeProjectCreationLocalStorage();
      return {
        ...initState,
        projectId: undefined,
        projectCreationMode: false,
        projectRelatingId: undefined,
        projectDetailsEditInformation: undefined,
        projectApplicationModifiedDateTime: undefined,
        nodeHierarchyApplicationModifiedDateTime: undefined,
        nodeHierarchyId: undefined,
      };
    case ProjectActionType.FINISH_NEW_PROJECT:
      removeProjectCreationLocalStorage();
      return produce(state, newState => {
        newState.projectCreationMode = false;
        newState.projectDetailsEditInformation = undefined;
      });
    case ProjectActionType.INIT_PROJECT:
      return initProjectAction(initState, action.projectId);
    case ProjectActionType.SET_PROJECT_RELATIONS:
      return produce(state, newState => {
        if (!newState.projectInput) {
          newState.projectInput = getEmptyProjectInput();
        }
        newState.projectInput.projectRelations = action.relationsInput;
      });
    case ProjectActionType.SET_PROJECT_INFORMATION:
      return produce(state, newState => {
        if (!newState.projectInput) {
          newState.projectInput = getEmptyProjectInput();
        }
        const projectType = newState.projectInput.projectInformation.input.projectTechnicalTypeId;
        newState.projectInput.projectInformation = action.informationInput;
        if (projectType !== null) {
          newState.projectInput.projectInformation.input.projectTechnicalTypeId = projectType;
        }
      });
    case ProjectActionType.SET_PROJECT_DATES:
      return produce(state, newState => {
        if (!newState.projectInput) {
          newState.projectInput = getEmptyProjectInput();
        }
        newState.projectInput.projectDates = action.datesInput;
      });
    case ProjectActionType.SET_PROJECT_CURRENCY:
      return produce(state, newState => {
        if (!newState.projectInput) {
          newState.projectInput = getEmptyProjectInput();
        }
        newState.projectInput.projectCurrency = action.currencyInput;
      });
    case ProjectActionType.SET_PROJECT_ROLES:
      return produce(state, newState => {
        if (!newState.projectInput) {
          newState.projectInput = getEmptyProjectInput();
        }
        newState.projectInput.projectRoles = action.rolesInput;
      });
    case ProjectActionType.SET_PROJECT_TAGS:
      return produce(state, newState => {
        if (!newState.projectInput) {
          newState.projectInput = getEmptyProjectInput();
        }
        newState.projectInput.projectTags = action.projectTagsInput;
      });
    case ProjectActionType.SET_PROJECT_INTEGRATIONS:
      return produce(state, newState => {
        if (!newState.projectInput) {
          newState.projectInput = getEmptyProjectInput();
        }
        newState.projectInput.projectIntegrations = action.projectIntegrationsInput;
      });
    case ProjectActionType.SET_REPORTING_RELATIONS:
      return produce(state, newState => {
        if (!newState.projectInput) {
          newState.projectInput = getEmptyProjectInput();
        }
        newState.projectInput.reportingRelations = action.reportingRelationsInput;
      });
    case ProjectActionType.SET_PROJECT_ERROR:
      return produce(state, newState => {
        newState.projectErrors[action.errorType] = action.value;
      });
    case ProjectActionType.SET_PROJECT_RELATING_ID:
      return produce(state, newState => {
        if (state.projectCreationMode) {
          localStorage.setItem("projectCreationId", action.projectId.toString());
          if (action.relatingProjectId) {
            localStorage.setItem("projectCreationRelatingId", action.relatingProjectId.toString());
          }
        }
        newState.projectId = action.projectId;
        newState.projectRelatingId = action.relatingProjectId;
      });
    case ProjectActionType.SET_PROJECT_DETAILS_EDIT_INFORMATION:
      return produce(state, newState => {
        updateDetailsEditInformation(newState, action.projectDetailsEditInformation);
      });
    case ProjectActionType.UPDATE_PROJECT_DESCRIPTION:
      return produce(state, newState => {
        if (
          newState.projectDetailsEditInformation !== undefined &&
          newState.projectDetailsEditInformation.projectInformation !== undefined
        ) {
          const newDescription: ProjectDescriptionEditInformation =
            newState.projectDetailsEditInformation.projectInformation.projectDescription.__typename ===
            "FreetextProjectDescription"
              ? {
                  ...newState.projectDetailsEditInformation.projectInformation.projectDescription,
                  desc: action.description,
                }
              : {
                  ...newState.projectDetailsEditInformation.projectInformation.projectDescription,
                  postfix: action.description,
                };
          const ediInformation = {
            ...newState.projectDetailsEditInformation,
            projectInformation: {
              ...newState.projectDetailsEditInformation.projectInformation,
              projectDescription: newDescription,
            },
          };
          updateDetailsEditInformation(newState, ediInformation);
        }
      });
    case ProjectActionType.SET_PROJECT_PENDING_CHANGE:
      return produce(state, newState => {
        const change = action.change;
        if (!newState.pendingChanges.includes(change)) newState.pendingChanges.push(change);
      });
    case ProjectActionType.CLEAR_PROJECT_PENDING_CHANGE:
      return produce(state, newState => {
        const change = action.change;
        remove(newState.pendingChanges, c => c === change);
      });
    case ProjectActionType.SET_PROJECT_HIERARCHY:
      return produce(state, newState => {
        const setChildLevel = (hierarchyItem: HierarchyItem, childLevel: number) => {
          hierarchyItem.childLevel = childLevel;
          if (hierarchyItem.childItems.length > 0) {
            hierarchyItem.childItems.forEach(childItem => setChildLevel(childItem, childLevel + 1));
          }
        };
        newState.hierarchy = produce(action.hierarchy, newHierarchy => setChildLevel(newHierarchy, 0));
      });
    case ProjectActionType.SET_PROJECT_APPLICATION_MODIFIED_DATE_TIME:
      return produce(state, newState => {
        newState.projectApplicationModifiedDateTime = action.applicationModifiedDateTime;
      });
    case ProjectActionType.UPDATE_PROJECT_HIERARCHY:
      return produce(state, newState => {
        const update = (item: HierarchyItem) => {
          if (item.itemType === HierarchyItemEnum.Project && item.id === action.projectId) {
            item.description = action.description;
            item.active = action.active;
          } else if (item.itemType === HierarchyItemEnum.Node) {
            item.childItems.forEach(update);
          }
        };
        if (newState.hierarchy !== undefined) update(newState.hierarchy);
      });
    case ProjectActionType.SET_NODE_HIERARCHY_APPLICATION_MODIFIED_DATE_TIME: {
      return produce(state, newState => {
        if (state.projectCreationMode) {
          if (action.applicationModifiedDateTime)
            localStorage.setItem("nodeHierarchyApplicationModifiedDateTime", action.applicationModifiedDateTime);
          if (action.nodeId !== undefined) localStorage.setItem("nodeHierarchyId", action.nodeId.toString());
        }
        newState.nodeHierarchyApplicationModifiedDateTime = action.applicationModifiedDateTime;
        newState.nodeHierarchyId = action.nodeId;
      });
    }
    default:
      return state;
  }
}
