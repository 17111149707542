import React from "react";
import { ProjectId, ProjectInformation, ProjectInformationVisibility } from "../../../../../common/types";
import CreateProjectSection from "../CreateProjectSection";
import EditDetails from "./EditDetails";
import styled from "styled-components";
import { defaultGrey } from "../../../../../common/colors";

export interface InformationCreateProjectSectionProps {
  projectId: ProjectId;
  sectionDetails: ProjectInformation | undefined;
  sectionVisibility: ProjectInformationVisibility;
}

function InformationCreateProjectSection(props: InformationCreateProjectSectionProps): React.ReactElement {
  const { projectId, sectionDetails, sectionVisibility } = props;

  return (
    <CreateProjectSection
      header="Project information"
      loadingErrors={sectionDetails ? undefined : ["No data found for project information."]}
    >
      <Container>
        {sectionDetails && (
          <EditDetails projectId={projectId} information={sectionDetails} informationVisibility={sectionVisibility} />
        )}
      </Container>
    </CreateProjectSection>
  );
}

export default InformationCreateProjectSection;

const Container = styled.div`
  padding: 20px;
  padding-bottom: 0px;
  color: ${defaultGrey};
  > div:last-child {
    border-bottom: 0;
    margin-bottom: 0px;
  }
`;
