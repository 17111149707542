import styled from "styled-components";
import { ApolloError } from "@apollo/client";
import React from "react";

export interface ErrorBoxProps {
  caption: string;
  apolloError?: ApolloError;
  errorText?: string;
  errorContent?: JSX.Element | JSX.Element[] | undefined;
}

const ErrorBox = (props: ErrorBoxProps) => {
  const { caption, apolloError, errorText, errorContent } = props;
  const firstGraphQLError = apolloError && apolloError.graphQLErrors[0];
  const httpError = apolloError && apolloError.networkError;

  if (firstGraphQLError !== undefined) {
    return (
      <ErrorView>
        <ErrorCaption>{caption}</ErrorCaption>
        {firstGraphQLError.message}
      </ErrorView>
    );
  } else if (httpError !== undefined && httpError !== null) {
    let error = errorText;
    if (httpError.message) {
      error = httpError.message;
    }
    return (
      <ErrorView>
        <ErrorCaption>{caption}</ErrorCaption>
        {error}
      </ErrorView>
    );
  }
  return (
    <ErrorView>
      <ErrorCaption>{caption}</ErrorCaption>
      {errorContent || <div>{errorText}</div>}
    </ErrorView>
  );
};

export default ErrorBox;

const ErrorView = styled.div`
  display: block;
  padding: 15px;
  border-radius: 10px;
  background-color: #ffe8c0;
  max-width: 60vw;
  align-self: center;
`;

const ErrorCaption = styled.div`
  font-weight: bold;
  margin-bottom: 1em;
`;
