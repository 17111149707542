import React, { useMemo, useState } from "react";
import {
  AppState,
  EntityTypeId,
  ProjectDetailsData,
  ProjectDetailsEditInformation,
  SaveNewProjectResult,
} from "../../../../common/types";
import { ThunkDispatch } from "redux-thunk";
import { Action } from "redux";
import {
  cancelNewProject,
  setProjectDetailsEditInformation,
  updateProjectDescription,
} from "../../../../actions/projectActions";
import { connect } from "react-redux";
import { useMutation, useQuery } from "@apollo/client/react/hooks";
import { GET_PROJECT_EDIT_INFORMATION } from "./queries";
import ErrorBox from "../../../ErrorBox";
import styled from "styled-components";
import { GET_PROJECT_DETAILS } from "./queries";
import LoadingView from "../../../LoadingView";
import IntegrationsCreateProjectSection from "./IntegrationsSection/IntegrationsCreateProjectSection";
import DatesCreateProjectSection from "./DatesSection/DatesCreateProjectSection";
import TagsCreateProjectSection from "./TagsSection/TagsCreateProjectSection";
import CurrencyCreateProjectSection from "./CurrencySection/CurrencyCreateProjectSection";
import RelationsCreateProjectSection from "./RelationsSection/RelationsCreateProjectSection";
import RolesCreateProjectSection from "./RolesSection/RolesCreateProjectSection";
import InformationCreateProjectSection from "./InformationSection/InformationCreateProjectSection";
import CreateProjectActionsHeader from "../../../ProjectHeader/CreateProjectActionsHeader";
import { RouteComponentProps, withRouter } from "react-router-dom";
import routes from "../../../../common/routes";
import { SAVE_NEW_PROJECT } from "../queries";
import CreateProjectErrorsBox from "../../../ProjectHeader/CreateProjectErrorsBox";
import ReportingRelationsCreateProjectSection from "./ReportingRelationsSection/ReportingRelationsCreateProjectSection";

export type CreateProjectBasicsViewProps = RouteComponentProps & {
  projectId: number;
  onSaveSuccess: (applicationModifiedDateTime: string) => void;
};

const mapStateToProps = (state: AppState) => {
  return {
    projectInput: state.projectState.projectInput,
    projectEditInformation: state.projectState.projectDetailsEditInformation,
    projectCreationErrors:
      state.projectState.projectErrors.project ||
      state.projectState.projectErrors.date ||
      state.projectState.projectErrors.currency ||
      state.projectState.projectErrors.roles,
    pendingChanges: state.projectState.pendingChanges,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, void, Action>) => {
  return {
    setEditInformation: (editInformation: ProjectDetailsEditInformation) =>
      dispatch(setProjectDetailsEditInformation(editInformation)),
    updateProjectDescription: (description: string) => dispatch(updateProjectDescription(description)),
    cancelNewProject: () => {
      dispatch(cancelNewProject());
    },
  };
};

function CreateProjectBasicsView(
  props: CreateProjectBasicsViewProps & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
): React.ReactElement {
  const {
    projectId,
    onSaveSuccess,
    projectInput,
    projectEditInformation,
    setEditInformation,
    updateProjectDescription,
    history,
    projectCreationErrors,
    pendingChanges,
    cancelNewProject,
  } = props;

  const { loading, data, error } = useQuery<ProjectDetailsData>(GET_PROJECT_DETAILS, {
    variables: {
      projectId: projectId,
    },
    fetchPolicy: "cache-and-network",
    skip: !projectId,
  });
  const projectDetailsData = data && data.projectDetails;

  const { error: editInformationError, loading: editInformationLoading } = useQuery<{
    projectDetailsEditInformation: ProjectDetailsEditInformation;
  }>(GET_PROJECT_EDIT_INFORMATION, {
    variables: { projectId: projectId || 0 },
    fetchPolicy: "network-only",
    skip: !projectId,
    onCompleted(data) {
      if (data && data.projectDetailsEditInformation) {
        const projectInformation = data.projectDetailsEditInformation.projectInformation;
        if (projectInformation) {
          setEditInformation({
            ...data.projectDetailsEditInformation,
            projectInformation: {
              ...projectInformation,
              projectTechnicalTypeProperties: { editable: false, nullable: null },
              contractOwner: {
                ...projectInformation.contractOwner,
                businessGroupProperties: {
                  ...projectInformation.contractOwner.businessGroupProperties,
                  shouldInherit: projectInformation.contractOwner.businessGroupId == null,
                },
              },
            },
          });
        } else {
          setEditInformation(data.projectDetailsEditInformation);
        }
      }
    },
  });

  const [saving, setSaving] = useState<boolean>(false);
  const [saveResultErrors, setSaveResultErrors] = useState<string[] | undefined>(undefined);
  const [saveNewProject, { error: saveNewProjectError }] = useMutation(SAVE_NEW_PROJECT, {
    async update(cache, data) {
      if (data) {
        const result = (data as SaveNewProjectResult).data.saveNewProject;
        setSaveResultErrors(result.errors || undefined);
        if (!result.errors && result.projectId) {
          onSaveSuccess(result.applicationModifiedDateTime);
          // VPOP-1067: In project creation mode, we don't poll for ready after saving basic data to reduce users' waiting time.
          // So there is no point to query the not-up-to-date project edit information in hierarchy or rec tab.
          // To get the correct project description, it can be updated directly from project input.
          if (
            projectInput &&
            !projectInput.projectInformation.pristine &&
            projectInput.projectInformation.input.projectDescription !== null
          ) {
            updateProjectDescription(projectInput.projectInformation.input.projectDescription);
          }
        }
        setSaving(false);
      }
    },
  });

  const createNewProjectErrors = useMemo(() => {
    const mutationErrors =
      saveNewProjectError === undefined
        ? []
        : saveNewProjectError.graphQLErrors
            .map(e => e.message)
            .concat([saveNewProjectError.message])
            .concat(saveNewProjectError.networkError ? [saveNewProjectError.networkError.message] : []);
    return (saveResultErrors || []).concat(mutationErrors);
  }, [saveResultErrors, saveNewProjectError]);

  // VPOP-990: In project creation mode, if project type is NOT "project with relations", relations section should be hidden.
  const isProjectWithRelations =
    projectEditInformation &&
    projectEditInformation.projectInformation &&
    projectEditInformation.projectInformation.projectTechnicalTypeId === EntityTypeId.ProjectWithRelations;

  return (
    <Container>
      <CreateProjectActionsHeader
        hasErrors={projectCreationErrors}
        saving={saving}
        readyToSave={!pendingChanges.length}
        onClickContinue={() => {
          if (projectInput !== undefined) {
            setSaveResultErrors(undefined);
            setSaving(true);
            saveNewProject({
              variables: {
                projectId: projectId,
                information: projectInput.projectInformation.input,
                dates: projectInput.projectDates.input,
                currency: projectInput.projectCurrency.input,
                roles: projectInput.projectRoles.input,
                tags: { tags: projectInput.projectTags.input.map(tag => tag.id) },
                integrations: projectInput.projectIntegrations.input,
                reportingRelations: projectInput.reportingRelations.input,
              },
            });
          }
        }}
        onClickCancel={() => {
          cancelNewProject();
          history.push(routes.MAIN);
        }}
      />
      {createNewProjectErrors.length > 0 && <CreateProjectErrorsBox errors={createNewProjectErrors} />}
      {projectDetailsData && !error && projectEditInformation && !editInformationError && (
        <ContentContainer>
          <ContainerColumn>
            {projectDetailsData.projectDetailsVisibility.projectInformation && (
              <InformationCreateProjectSection
                projectId={projectId}
                sectionDetails={projectDetailsData.projectDetails.projectInformation}
                sectionVisibility={projectDetailsData.projectDetailsVisibility.projectInformation}
              />
            )}
            {projectDetailsData.projectDetailsVisibility.reportingRelations && (
              <ReportingRelationsCreateProjectSection
                projectId={projectId}
                sectionDetails={projectDetailsData.projectDetails.reportingRelations}
                sectionVisibility={projectDetailsData.projectDetailsVisibility.reportingRelations}
              />
            )}
            {projectDetailsData.projectDetailsVisibility.projectDates && (
              <DatesCreateProjectSection
                projectId={projectId}
                sectionDetails={projectDetailsData.projectDetails.projectDates}
                sectionVisibility={projectDetailsData.projectDetailsVisibility.projectDates}
              />
            )}
            {projectDetailsData.projectDetailsVisibility.projectTags && (
              <TagsCreateProjectSection
                projectId={projectId}
                sectionDetails={projectDetailsData.projectDetails.projectTags}
              />
            )}
          </ContainerColumn>
          <ContainerColumn>
            {projectDetailsData.projectDetailsVisibility.projectCurrency && (
              <CurrencyCreateProjectSection
                projectId={projectId}
                sectionDetails={projectDetailsData.projectDetails.projectCurrency}
                sectionVisibility={projectDetailsData.projectDetailsVisibility.projectCurrency}
              />
            )}
            {isProjectWithRelations && projectDetailsData.projectDetailsVisibility.projectRelations && (
              <RelationsCreateProjectSection sectionDetails={projectDetailsData.projectDetails.relations} />
            )}
            {projectDetailsData.projectDetailsVisibility.projectRoles && (
              <RolesCreateProjectSection
                projectId={projectId}
                sectionDetails={projectDetailsData.projectDetails.projectRoles}
                sectionVisibility={projectDetailsData.projectDetailsVisibility.projectRoles}
              />
            )}
            {projectDetailsData.projectDetailsVisibility.integrations && (
              <IntegrationsCreateProjectSection
                projectId={projectId}
                sectionDetails={projectDetailsData.projectDetails.integrations}
                sectionVisibility={projectDetailsData.projectDetailsVisibility.integrations}
              />
            )}
          </ContainerColumn>
        </ContentContainer>
      )}

      {(loading || (editInformationLoading && !editInformationError)) && <LoadingView />}
      {error && <ErrorBox caption="Error" apolloError={error} />}
      {editInformationError && <ErrorBox caption="Error" apolloError={editInformationError} />}
    </Container>
  );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateProjectBasicsView));

const Container = styled.div``;

const ContentContainer = styled.div`
  width: 1480px;
  display: flex;
  column-gap: 15px;
  @media (max-width: 1480px) {
    display: flex;
    flex-direction: column;
    width: 733px;
  }
  padding-bottom: 120px;
`;

const ContainerColumn = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
`;
