import styled from "styled-components";

export const NameInput = styled.input`
  margin-top: 20px;
  padding: 5px;
  width: 100%;
  font-weight: bold;
`;

export const ContentSection = styled.div`
  display: flex;
  /*margin-top: 20px;*/
`;

export const ActionsSection = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;
