import React, { useState } from "react";
import styled from "styled-components";
import { format } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlusCircle, faTrash } from "@fortawesome/free-solid-svg-icons";

import {
  ActivityEstimateCommentTypeId,
  CostActivityCommentsChange,
  ProjectCostActivityComment,
  toEstimateCommentTypeId,
} from "../../../../../common/types";
import { ActionButton, IconButton } from "../../../../../common/components";
import { cancelRed, commentTextBackgroundBlue, defaultGrey, settingGreen } from "../../../../../common/colors";
import FullscreenSpinner from "../../../../FullscreenSpinner/FullscreenSpinner";
import { addCostComment, deleteCostComment, saveProjectCostComment } from "../queries";
import { useApolloClient } from "@apollo/client/react/hooks";
import { getUserCookie } from "../../../../../common/restApi";
import { cloneDeep } from "lodash";
import { ApolloClient } from "@apollo/client";

const CommentTypeSelect = ({
  commentType,
  commentTypes,
  setType,
}: {
  commentType: ActivityEstimateCommentTypeId | null;
  commentTypes: ActivityEstimateCommentTypeId[];
  setType: (typ: ActivityEstimateCommentTypeId) => void;
}) => {
  return (
    <select
      disabled={commentTypes.length < 1}
      value={commentType || ""}
      onChange={ev => setType(toEstimateCommentTypeId(ev.target.value))}
    >
      {commentTypes.map(v => (
        <option value={v} key={v}>
          {v}
        </option>
      ))}
    </select>
  );
};

function CommentComponent(comment: ProjectCostActivityComment, editingDisabled: boolean, editComment: () => void) {
  const { commentId, commentType, commentText, modifiedDate, modifiedBy } = comment;
  const date = () => (modifiedDate !== null ? format(new Date(modifiedDate), "dd.MM.yyyy") : null);
  return (
    <CommentContainer key={commentId}>
      <CommentSubtitle>
        <Commenter>{modifiedBy}</Commenter>
        <CommentDate>{modifiedDate && date()}</CommentDate>
      </CommentSubtitle>
      <CommentHeader>
        Type:
        <CommentTypeDesc>{commentType}</CommentTypeDesc>
      </CommentHeader>
      <CommentText>{commentText}</CommentText>
      <CommentFooter>
        <CommentActions>
          <ActionButton onClick={editComment} disabled={editingDisabled}>
            <FontAwesomeIcon icon={faEdit} />
            Edit
          </ActionButton>
        </CommentActions>
      </CommentFooter>
    </CommentContainer>
  );
}

function NewCommentEditComponent(
  commentText: string,
  commentType: ActivityEstimateCommentTypeId,
  saveComment: () => void,
  cancel: () => void,
  setComment: (commentText: string) => void,
  setCommentType: (commentType: ActivityEstimateCommentTypeId) => void,
  saving: boolean,
  savingError: boolean,
  commentTypes: ActivityEstimateCommentTypeId[]
) {
  return (
    <CommentContainer>
      {saving ? <FullscreenSpinner text="Saving" /> : null}
      <CommentSubtitle>Add new comment</CommentSubtitle>
      <CommentHeader>
        Type:
        <CommentTypeSelect commentType={commentType} commentTypes={commentTypes} setType={setCommentType} />
      </CommentHeader>
      <CommentInput
        placeholder="Write comment here"
        value={commentText}
        rows={3}
        onChange={ev => setComment(ev.target.value)}
      />
      <CommentFooter>
        <CommentActions>
          <ActionButton onClick={cancel} color={defaultGrey}>
            Cancel
          </ActionButton>
          <ActionButton onClick={saveComment}>Save</ActionButton>
          {savingError && <ErrorContainer>Error saving data.</ErrorContainer>}
        </CommentActions>
      </CommentFooter>
    </CommentContainer>
  );
}

function CommentEditComponent(
  comment: ProjectCostActivityComment,
  commentText: string,
  commentType: ActivityEstimateCommentTypeId,
  saveComment: () => void,
  cancel: () => void,
  setComment: (commentText: string) => void,
  setCommentType: (commentType: ActivityEstimateCommentTypeId) => void,
  deleteComment: () => void,
  saving: boolean,
  savingError: boolean,
  commentTypes: ActivityEstimateCommentTypeId[]
) {
  const { commentId, modifiedDate, modifiedBy } = comment;
  const date = () => (modifiedDate !== null ? format(new Date(modifiedDate), "dd.MM.yyyy") : null);
  return (
    <CommentContainer key={commentId}>
      {saving ? <FullscreenSpinner text="Saving" /> : null}
      <CommentSubtitle>
        <Commenter>{modifiedBy}</Commenter>
        <CommentDate>{modifiedDate && date()}</CommentDate>
      </CommentSubtitle>
      <CommentHeader>
        Type:
        <CommentTypeSelect commentType={commentType} commentTypes={commentTypes} setType={setCommentType} />
      </CommentHeader>
      <CommentInput
        placeholder="Write comment here"
        value={commentText}
        rows={3}
        onChange={ev => setComment(ev.target.value)}
      />
      <CommentFooter>
        <ActionButton onClick={deleteComment} color={cancelRed}>
          <FontAwesomeIcon icon={faTrash} />
          Delete
        </ActionButton>
        <CommentActions>
          <ActionButton onClick={cancel} color={defaultGrey}>
            Cancel
          </ActionButton>
          <ActionButton onClick={saveComment}>Save</ActionButton>
          {savingError && <ErrorContainer>Error saving data.</ErrorContainer>}
        </CommentActions>
      </CommentFooter>
    </CommentContainer>
  );
}

interface CostActivityCommentProps {
  projectId: number;
  activityId: string;
  estimateCodeId: string | undefined;
  setComment: (comment: ProjectCostActivityComment) => void;
  deleteComment?: () => void;
  comment?: ProjectCostActivityComment;
  commentTypes: ActivityEstimateCommentTypeId[];
}

function CostActivityComment(props: CostActivityCommentProps) {
  const defaultCommentType: ActivityEstimateCommentTypeId = toEstimateCommentTypeId("Change in EAC");

  const { projectId, activityId, estimateCodeId, setComment, deleteComment, comment, commentTypes } = props;
  const [commentText, setCommentText] = useState<string>(comment ? comment.commentText : "");
  const [commentType, setCommentType] = useState<ActivityEstimateCommentTypeId>(
    comment ? comment.commentType : defaultCommentType
  );
  const [saving, setSaving] = useState<boolean>(false);
  const [savingError, setSavingError] = useState<boolean>(false);
  const [edit, setEdit] = useState(false);

  const client = useApolloClient() as ApolloClient<Record<string, unknown>>;

  const saveChangedComment = async (comment: ProjectCostActivityComment) => {
    if (estimateCodeId && (comment.originalText ? comment.originalText.trim() : "") !== commentText.trim()) {
      setSaving(true);
      setSavingError(false);
      /* All comments for the project + estimate code will be sent to data layer to support deletes,
       * so for convenience comments changes are in this format */
      const changes: CostActivityCommentsChange = {
        projectId: projectId,
        estimateCodeId: estimateCodeId,
        costComments: [
          {
            activityId: activityId,
            commentId: comment.commentId,
            commentText: commentText,
            commentType: commentType,
          },
        ],
      };
      const applicationModifiedDateTime = await saveProjectCostComment(client, projectId, changes);
      setSaving(false);
      if (applicationModifiedDateTime) {
        const user = getUserCookie();
        const updatedComment: ProjectCostActivityComment = {
          commentId: comment.commentId,
          commentType: commentType,
          commentText: commentText,
          originalText: commentText,
          modifiedDate: applicationModifiedDateTime,
          modifiedBy: user ? user.username : "",
        };
        setComment(updatedComment);
      } else {
        setSavingError(true);
      }
    }
  };

  const addNewComment = async (commentText: string, commentType: ActivityEstimateCommentTypeId) => {
    if (estimateCodeId) {
      setSaving(true);
      setSavingError(false);
      const addCostCommentResult = await addCostComment(
        client,
        projectId,
        activityId,
        estimateCodeId,
        commentText,
        commentType
      );
      setSaving(false);
      if (addCostCommentResult && addCostCommentResult.comment) {
        const newComment = cloneDeep(addCostCommentResult.comment);
        newComment["originalText"] = newComment.commentText;
        setComment(newComment);
      } else {
        setSavingError(true);
      }
    }
  };

  const onClickSave = async () => {
    if (comment) await saveChangedComment(comment);
    else await addNewComment(commentText, commentType);
    setEdit(false);
  };

  const onClickCancel = () => {
    setCommentText(comment ? comment.commentText : "");
    setEdit(false);
  };

  const onClickDelete = async () => {
    if (comment && estimateCodeId && deleteComment) {
      setSaving(true);
      setSavingError(false);
      const deleteCostCommentResult = await deleteCostComment(
        client,
        projectId,
        activityId,
        estimateCodeId,
        comment.commentId
      );
      setSaving(false);
      if (deleteCostCommentResult && deleteCostCommentResult.deleteDateTime) {
        deleteComment();
      } else {
        setSavingError(true);
      }
    }
    setEdit(false);
  };

  if (edit && comment) {
    return CommentEditComponent(
      comment,
      commentText,
      commentType,
      onClickSave,
      onClickCancel,
      setCommentText,
      setCommentType,
      onClickDelete,
      saving,
      savingError,
      commentTypes
    );
  } else if (edit && !comment) {
    return NewCommentEditComponent(
      commentText,
      commentType,
      onClickSave,
      onClickCancel,
      setCommentText,
      setCommentType,
      saving,
      savingError,
      commentTypes
    );
  } else if (!edit && comment) {
    return CommentComponent(comment, false, () => setEdit(true));
  } else
    return (
      <IconButton onClick={() => setEdit(true)} fontSize={"18px"}>
        <FontAwesomeIcon icon={faPlusCircle} color={settingGreen} size={"1x"} />
      </IconButton>
    );
}

export default CostActivityComment;

const CommentContainer = styled.div<{ show?: boolean }>`
  display: flex;
  flex-direction: column;
  background: ${commentTextBackgroundBlue};
  font-size: 10px;
  margin: 10px;
  color: ${defaultGrey};
  ${({ show }) => (show === false ? "padding: 0" : "padding: 4px 10px")};
  ${({ show }) => (show === false ? "max-height: 0" : "max-height: 260px")};
  width: 380px;
  transition: max-height 0.5s linear;
  overflow: hidden;
`;

const CommentSubtitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 10px;
  font-weight: normal;
`;

const Commenter = styled.div`
  display: flex;
`;

const CommentDate = styled.div`
  display: flex;
`;

const CommentHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: baseline;
  font-weight: bold;
  margin-bottom: 12px;
`;

const CommentTypeDesc = styled.div`
  display: flex;
  font-size: 14px;
`;

const CommentText = styled.div`
  font-size: 12px;
  white-space: normal;
  font-weight: normal;
`;

const CommentFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 10px;
  white-space: normal;
  font-weight: normal;
  margin-top: 12px;
`;

const CommentActions = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 2;
  justify-content: flex-end;
  align-items: baseline;
`;

const CommentInput = styled.textarea`
  border: 0;
  min-width: 0px;
  max-height: 100px;
  padding: 5px;
  font-size: 12px;
  flex-grow: 1;
`;

const ErrorContainer = styled.div`
  margin-top: 20px;
  font-size: 12px;
`;
