import React from "react";
import onClickOutside, { HandleClickOutside, InjectedOnClickOutProps } from "react-onclickoutside";
import styled from "styled-components";
import { valmetGreyBorder, filterGreen, defaultGrey } from "../../../common/colors";
import { SearchProject } from "../../../common/types";
import { ValueButton } from "../../../common/components";
import LoadingView from "../../LoadingView";

interface Props {
  searchProjects: SearchProject[];
  resetSearch: () => void;
  onResultSelected: (searchProject: SearchProject) => void;
  moreAvailable: boolean;
  loading: boolean;
}

class Dropdown extends React.Component<Props & InjectedOnClickOutProps & HandleClickOutside<React.MouseEventHandler>> {
  handleClickOutside = (): void => {
    this.props.disableOnClickOutside();
    this.props.resetSearch();
  };

  render(): React.ReactElement {
    const { searchProjects, onResultSelected, moreAvailable, loading } = this.props;
    return (
      <SearchContainer>
        <DropdownContent onClick={this.handleClickOutside} hasContent={true} loading={loading}>
          {loading && (
            <LoadingContainer>
              <LoadingView />
            </LoadingContainer>
          )}
          {!loading && searchProjects.length === 0 && <IndicatorContainer>No results</IndicatorContainer>}
          {searchProjects.map((project, i) => (
            <RowContainer key={`${project.id}_${i}`}>
              <ValueButton
                onClick={() => onResultSelected(project)}
              >{`${project.id} ${project.description}`}</ValueButton>
            </RowContainer>
          ))}
          {moreAvailable && (
            <IndicatorContainer>
              More than {searchProjects.length} results found, please refine search...
            </IndicatorContainer>
          )}
        </DropdownContent>
      </SearchContainer>
    );
  }
}

const SearchContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const DropdownContent = styled.div<{ hasContent: boolean; loading: boolean }>`
  background: white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  position: absolute;
  display: flex;
  flex-direction: column;
  min-width: 100%;
  ${({ loading }) => loading && "min-height: 180px"};
  ${({ hasContent }) => {
    if (hasContent) {
      return `border: 1px solid ${valmetGreyBorder}`;
    }
  }};
  z-index: 901;
  button:hover {
    background: ${filterGreen};
  }
  color: ${defaultGrey};
`;

const LoadingContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  /*color: #fff;*/
  background-color: #eeea;
`;

const RowContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  :hover {
    background: ${filterGreen};
  }
`;

const IndicatorContainer = styled.div`
  font-size: 10px;
  margin: 6px;
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default onClickOutside<any, Props>(Dropdown);
