import React, { useMemo } from "react";
import styled from "styled-components";
import { useQuery } from "@apollo/client";

import ErrorBox from "../../../ErrorBox";
import LoadingView from "../../../LoadingView";
import { ESTIMATION_SCHEDULE } from "./queries";
import { Container, ContentContainer, HeaderContainer } from "../shared";
import { EstimateSchedule } from "../../EstimateSchedule/types";
import {
  currentPeriod,
  parsePeriod,
  Period,
  periodEquals,
  periodLessThan,
  periodToString,
} from "../../../../common/period";
import { ActionButton } from "../../../../common/components";
import routes from "../../../../common/routes";

function EstimationSchedule(): React.ReactElement {
  const { data, loading, error } = useQuery<{ estimationSchedule: EstimateSchedule }>(ESTIMATION_SCHEDULE);

  const filteredData: EstimateSchedule | null = useMemo(() => {
    if (!data || !data.estimationSchedule) return null;
    const now = new Date();
    const currentYear = now.getFullYear();
    const result = {
      years: data.estimationSchedule.years.filter(y => y.year === currentYear),
    };
    return result;
  }, [data]);

  const currentP = currentPeriod();

  const isCurrentPeriod = (p: Period | null) => (p ? periodEquals(p, currentP) : false);
  const isPastPeriod = (p: Period | null) => (p ? periodLessThan(p, currentP) : false);

  return (
    <Container>
      <HeaderContainer>
        <Header>
          <span title="Estimate approval schedule for the latest date the estimates for internal projects should be approved each period">
            Estimate approval schedule for internal projects
          </span>
        </Header>
      </HeaderContainer>
      <ContentContainer>
        {!data && !loading ? (
          <ErrorBox caption="Error" apolloError={error} />
        ) : loading || !data ? (
          <LoadingView />
        ) : !filteredData || filteredData.years.length === 0 ? (
          "No schedule"
        ) : (
          filteredData.years.map(year => {
            return (
              <YearContainer key={year.year}>
                <h2>{year.year}</h2>
                {year.periods.map(period => {
                  const p = parsePeriod(period.period);
                  return (
                    <PeriodRow key={period.period.toString()} emphasize={isCurrentPeriod(p)} past={isPastPeriod(p)}>
                      <PeriodColumn>{p ? periodToString(p) : "-"}</PeriodColumn>
                      <ScheduleColumn>
                        {period.schedule ? new Date(period.schedule).toLocaleDateString() : "-"}
                      </ScheduleColumn>
                    </PeriodRow>
                  );
                })}
              </YearContainer>
            );
          })
        )}
        <ButtonContainer>
          <a href={routes.ESTIMATE_SCHEDULE}>
            <ActionButton>Show all</ActionButton>
          </a>
        </ButtonContainer>
      </ContentContainer>
    </Container>
  );
}

export default EstimationSchedule;

const Header = styled.div`
  padding: 10px;
`;

const YearContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
`;

const PeriodRow = styled.div<{ emphasize: boolean; past: boolean }>`
  display: flex;
  flex-direction: row;
  column-gap: 120px;
  font-weight: ${({ emphasize }) => (emphasize ? "bold" : "normal")};
  color: ${({ past }) => (past ? "#808080" : "black")};
`;

const PeriodColumn = styled.div`
  min-width: 80px;
  text-align: right;
`;
const ScheduleColumn = styled.div`
  min-width: 100px;
  text-align: right;
`;

const ButtonContainer = styled.div`
  align-self: center;
  margin-top: 40px;
`;
