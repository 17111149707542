import { Period } from "../../../../common/period";
import { ActivityId, CommentId, CommentType, ISOTimestamp, ProjectId } from "../../../../common/types";
import { ProjectColumn } from "../../../../common/columnsTypes";

export enum CommentPackageType {
  Project = "Project",
  Total = "Total",
  Subtotal = "Subtotal",
}

export interface CommentPackage {
  id: string;
  period: string;
  commentType: CommentPackageType;
  content: string;
  modified: ISOTimestamp;
  modifiedBy: string;
  published: ISOTimestamp | null;
}

export interface ExistingCommentPackage {
  type: "LockedPackage" | "EditablePackage";
  id: string;
  period: Period;
  commentType: CommentPackageType;
  content: string;
  modified: ISOTimestamp;
  modifiedBy: string;
  published: ISOTimestamp | null;
}

export interface NewCommentPackage {
  type: "NewComment";
  period: Period;
  commentType: CommentPackageType;
  content: string;
}

export type EditableCommentPackage = ExistingCommentPackage | NewCommentPackage;

export interface ProjectCommentPackagesResult {
  packages: CommentPackage[];
  totalPackages: CommentPackage[] | null;
  subtotalPackages: CommentPackage[] | null;
  totalNodeName: string | null;
  subtotalNodeName: string | null;
  isMainProject: boolean;
}

export interface NodeCommentPackagesResult {
  projectId: ProjectId;
  nodeDescription: string;
  isTotal: boolean;
  totalPackages: CommentPackage[];
  subtotalPackages: CommentPackage[];
}

export interface RecognitionCommentForCompilation {
  period: string;
  column: ProjectColumn;
  field: string;

  commentType: CommentType;
  value: number | null;
  content: string;
  currencyScenario: string;
  createdBy: string;
  createdDateTime: ISOTimestamp;
}

export interface EstimationCommentForCompilation {
  period: string;
  commentId: CommentId;
  activityId: ActivityId;
  activityCode: string;
  commentType: string;
  content: string;
  createdBy: string;
  createdDateTime: ISOTimestamp;
}

export interface ProjectPeriodCommentsResult {
  recognitionPlanComments: RecognitionCommentForCompilation[];
  costEstimationComments: EstimationCommentForCompilation[];
}

export interface ProjectCommentPackage {
  projectId: ProjectId;
  description: string;
  period: string;
  content: string;
  modifiedBy: string;
  modified: ISOTimestamp;
  published: ISOTimestamp | null;
}

export interface TotalPeriodCommentsResult {
  comments: ProjectCommentPackage[];
}

export interface CommentPackageInput {
  period: Period;
  commentType: CommentPackageType;
  content: string;
}

export interface PublishCommentPackageInput {
  period: Period;
  commentType: CommentPackageType;
  content: string;
  modified: ISOTimestamp;
  published: ISOTimestamp | null;
}

export interface SaveProjectCommentPackageResult {
  success: boolean;
  commentPackage: CommentPackage | null;
  error: string | null;
  applicationModifiedDate: ISOTimestamp;
}
