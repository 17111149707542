import { format } from "date-fns";
import { useCallback, useState } from "react";

export const formatTimestamp = (s: string) => {
  const d = new Date(s);
  return format(d, "dd.MM.yyyy") + " at " + format(d, "HH:mm");
};

export function useItemToggle(
  initialSet: string[]
): [
  (itemId: string) => boolean,
  (itemId: string, set: boolean) => void,
  (itemId: string) => void,
  { unsetAll: () => void; numItemsSet: number; }
] {
  const [items, setItems] = useState(new Set<string>(initialSet));
  const setItem = useCallback(
    (itemId: string, set: boolean) => {
      const newItems = new Set(items);
      if (set) {
        newItems.add(itemId);
      } else {
        newItems.delete(itemId);
      }
      setItems(newItems);
    },
    [items, setItems]
  );
  const toggleItem = useCallback(
    (itemId: string) => {
      const newItems = new Set(items);
      if (items.has(itemId)) {
        newItems.delete(itemId);
      } else {
        newItems.add(itemId);
      }
      setItems(newItems);
    },
    [items, setItems]
  );
  const isItemSet = useCallback((itemId: string) => items.has(itemId), [items]);
  const unsetAll = () => setItems(new Set());
  return [
    isItemSet,
    setItem,
    toggleItem,
    {
      unsetAll,
      numItemsSet: items.size
    },
  ];
}
