import React from "react";
import styled from "styled-components";
import { defaultGrey, valmetGreyBorder, valmetGreyHeader } from "../../../../common/colors";

// TODO: Replace placeholder data with real items

export interface TopItemData {
  data1: string;
  data2: string;
  data3: string;
  data4: string;
}

interface TopItemProps {
  data: TopItemData;
}

function TopItem(props: TopItemProps): React.ReactElement {
  const { data } = props;
  return (
    <Container>
      <ValueContainer>
        <Value>{data.data1}</Value>
        <InfoText>{data.data2}</InfoText>
      </ValueContainer>
      <InfoContainer>
        <InfoText>{data.data3}</InfoText>
        <InfoText>{data.data4}</InfoText>
      </InfoContainer>
    </Container>
  );
}

export default TopItem;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-left: 12px;
  margin-right: 12px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 10px;
  color: ${valmetGreyHeader};
  border-bottom: 1px solid ${valmetGreyBorder};
`;

const ValueContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 15px;
`;

const Value = styled.span`
  font-size: 14px;
  font-weight: bold;
  color: ${defaultGrey};
`;

const InfoText = styled.span``;
