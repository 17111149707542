import { useEffect, useRef, useState } from "react";

/**
 * Debounced state, which is updated after debounce delay of 'delayMillis' milliseconds of no changes are
 * made to the state.
 *
 * Returns a tuple [current, debounced, setCurrent], where the current is the current state, debounced is
 * the state that will be updated with a debounce delay, and setCurrent updates the current state.
 *
 * @param delayMillis Delay in milli seconds
 * @param initialState The initial state
 */
export function useDebouncedState<T>(delayMillis: number, initialState: T): [T, T, (value: T) => void] {
  const timeout = useRef<number>();
  const [current, setCurrent] = useState(initialState);
  const [debounced, setDebounced] = useState(initialState);
  useEffect(() => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      if (debounced !== current) setDebounced(current);
    }, delayMillis);
    return () => {
      clearTimeout(timeout.current);
    };
  }, [current, setDebounced]);
  return [current, debounced, setCurrent];
}
