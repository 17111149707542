import React, { PropsWithChildren, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useQuery } from "@apollo/client";

import {
  cancelRed,
  settingGreen,
  valmetGreyBorder,
  valmetGreyId,
  valmetGreyREC,
  warningYellow,
} from "../../../common/colors";
import { TabButton, TabButtonsContainer, TabContainerX, ToggleSwitch } from "../../../common/components";
import ErrorBox from "../../ErrorBox";
import LoadingView from "../../LoadingView";
import { LOAD_LATEST } from "./queries";
import { LoadingData, LoadingStatus, LoadingStatusLog, ProjectTypeData } from "./types";

const convertDates = (loading: LoadingData): LoadingData => {
  const convertN = (dt: Date): Date => new Date(dt);
  const convert = (dt: Date | null): Date | null => (dt ? new Date(dt) : null);
  return {
    ...loading,
    start: convertN(loading.start),
    end: convert(loading.end),
    staging: {
      start: convert(loading.staging.start),
      end: convert(loading.staging.end),
    },
    intermediate: {
      start: convert(loading.intermediate.start),
      end: convert(loading.intermediate.end),
    },
    info: {
      averageDurationSeconds: loading.info.averageDurationSeconds,
      estimatedEnd: convert(loading.info.estimatedEnd),
    },
  };
};

const isSameDate = (a: Date, b: Date): boolean =>
  a.getUTCFullYear() == b.getUTCFullYear() && a.getUTCMonth() === b.getUTCMonth() && a.getUTCDate() === b.getUTCDate();

const splitByDate = (loadings: LoadingData[], results: [Date, LoadingData[]][] = []): [Date, LoadingData[]][] => {
  const head = loadings[0];
  if (head && head.start) {
    const date = head.start;
    const [matching, rest] = loadings.reduce(
      ([matching, rest]: [LoadingData[], LoadingData[]], item) => {
        if (item.start && isSameDate(item.start, date)) {
          return [matching.concat(item), rest];
        } else {
          return [matching, rest.concat(item)];
        }
      },
      [[], []]
    );
    //console.log("matching:", matching.length, "rest:", rest.length);
    const result: [Date, LoadingData[]] = [date, matching];
    results.push(result);
    return splitByDate(rest, results);
  } else {
    return results;
  }
};

enum Tabs {
  Ln = "LN",
  Baan = "Baan",
  Other = "Other",
}

const tabs = [Tabs.Ln, Tabs.Baan, Tabs.Other];

function LoadingStatusPage(): React.ReactElement {
  const { data, loading, error } = useQuery<{ loadingStatusLog: LoadingStatusLog }>(LOAD_LATEST, {
    pollInterval: 30000,
  });

  const {
    loadingStatusLog: { projectTypes },
  } = data || { loadingStatusLog: { projectTypes: [] } };

  const sortedProjectTypes: ProjectTypeData[] = useMemo(() => {
    const sorted = projectTypes.concat([]).sort((a, b) => a.source.localeCompare(b.source));
    return sorted.map(ptype => {
      const converted = ptype.loadings.map(loading => convertDates(loading));
      const loadings = converted.sort((a, b) => b.start.getTime() - a.start.getTime());
      return { ...ptype, loadings };
    });
  }, [projectTypes]);

  const tabbedProjectTypes = {
    [Tabs.Ln]: sortedProjectTypes.filter(pt => pt.source === "LN"),
    [Tabs.Baan]: sortedProjectTypes.filter(pt => pt.source === "BAAN project" || pt.source === "BAAN Pcs"),
    [Tabs.Other]: sortedProjectTypes.filter(pt => !["LN", "BAAN project", "BAAN Pcs"].includes(pt.source)),
  };

  const [now, setNow] = useState(new Date());

  useEffect(() => {
    let timerId: number | undefined = undefined;

    const loop = () => {
      timerId = setTimeout(() => {
        const newNow = new Date();
        console.log("Setting now", newNow);
        setNow(newNow);
        loop();
      }, 2000);
    };

    loop();
    return () => clearTimeout(timerId);
  }, []);

  const millisToDurationString = (millis: number): string => {
    const seconds = millis / 1000;
    const hours = Math.floor(seconds / 3600);
    const min = Math.floor(seconds / 60) - hours * 60;
    const sec = Math.floor(seconds) - hours * 3600 - min * 60;
    if (hours !== 0) {
      return `${hours} h ${min} min ${sec} s`;
    } else if (min !== 0) {
      return `${min} min ${sec} s`;
    } else {
      return `${sec} s`;
    }
  };

  const utcTimeString = (dt: Date): string => {
    const hours = dt.getUTCHours();
    const minutes = dt.getUTCMinutes();
    const seconds = dt.getUTCSeconds();
    const withPrefix0 = (n: number): string => (n >= 10 ? "" : "0") + n;
    return `${withPrefix0(hours)}:${withPrefix0(minutes)}:${withPrefix0(seconds)}`;
    //const offsetMinutes = dt.getTimezoneOffset();
    //const tempDt = new Date(dt);
    //tempDt.setMinutes(tempDt.getMinutes() + offsetMinutes);
    //return tempDt.toLocaleTimeString();
  };

  const localTimeString = (dt: Date): string => dt.toLocaleString();

  const utcDateString = (dt: Date): string => {
    // Slightly hacky
    const offsetMinutes = dt.getTimezoneOffset();
    dt.setMinutes(dt.getMinutes() + offsetMinutes);
    const utcString = dt.toDateString();
    dt.setMinutes(dt.getMinutes() - offsetMinutes);
    return utcString;
  };

  const dateCell = (dt: Date | null, estimatedEnd?: Date | null) => {
    if (dt) {
      return <Cell title={dt ? "Local time: " + localTimeString(dt) : undefined}>{dt ? utcTimeString(dt) : "-"}</Cell>;
    } else if (estimatedEnd) {
      return (
        <Cell title={localTimeString(estimatedEnd)}>
          <RunningText>Estimated: {utcTimeString(estimatedEnd)}</RunningText>
        </Cell>
      );
    } else {
      return <Cell>-</Cell>;
    }
  };

  const durationCell = (start: Date | null, end: Date | null, late?: boolean) => {
    return (
      <DurationCell>
        {start === null && end === null ? (
          <RunningText>-</RunningText>
        ) : start && end === null ? (
          <RunningText late={late}>
            Running for <Duration>{millisToDurationString(now.getTime() - start.getTime())}</Duration>
          </RunningText>
        ) : (
          start &&
          end && (
            <DurationText>
              <Duration>{millisToDurationString(end.getTime() - start.getTime())}</Duration>
            </DurationText>
          )
        )}
      </DurationCell>
    );
  };

  const isLate = (loading: LoadingData): boolean => {
    if (loading.end) return false;
    if (!loading.info.averageDurationSeconds) return true;
    const durationSeconds = (now.getTime() - loading.start.getTime()) / 1000;
    return durationSeconds > loading.info.averageDurationSeconds * 2;
  };

  const [simple, setSimple] = useState(true);
  const [currentTab, setCurrentTab] = useState(Tabs.Ln);

  const headers = () => (
    <TableBody>
      {!simple && (
        <Row>
          <>
            <HeaderCell>Staging</HeaderCell>
            <HeaderCell>Intermediate</HeaderCell>
            <StatusHeaderCell></StatusHeaderCell>
          </>
        </Row>
      )}
      <Row>
        {simple ? (
          <>
            <HeaderCell>Start</HeaderCell>
            <HeaderCell>End</HeaderCell>
            <DurationHeaderCell>Duration</DurationHeaderCell>
            <StatusHeaderCell>Status</StatusHeaderCell>
          </>
        ) : (
          <>
            <HeaderCell>Start</HeaderCell>
            <HeaderCell>End</HeaderCell>
            <DurationHeaderCell>Duration</DurationHeaderCell>
            <HeaderCell>Start</HeaderCell>
            <HeaderCell>End</HeaderCell>
            <DurationHeaderCell>Duration</DurationHeaderCell>
            <StatusHeaderCell>Status</StatusHeaderCell>
          </>
        )}
      </Row>
    </TableBody>
  );

  return (
    <Container>
      <Header>
        <Title>Loading Status</Title>
        <ToggleSwitch checked={!simple} onChange={() => setSimple(!simple)} text="Details" />
        <AdditionalInfo>Timestamps are in UTC / GMT.</AdditionalInfo>
      </Header>
      <Content>
        {loading && <LoadingView />}
        {error && <ErrorBox caption="Error loading the data" apolloError={error} />}
        {!loading && !error && (
          <>
            <TabButtonsContainer>
              {tabs.map(tab => (
                <TabButton key={tab} selected={tab === currentTab} onClick={() => setCurrentTab(tab)}>
                  {tab}
                </TabButton>
              ))}
            </TabButtonsContainer>
            <TabContainerX show={true}>
              <TabContent simple={simple}>
                {tabbedProjectTypes[currentTab].map(projectType => (
                  <ProjectTypeContainer key={projectType.name}>
                    {projectType.loadingTimeList && <>
                      <LoadingTimeTitle>Data loading started in {projectType.source} {projectType.loadingTimeList.length === 1 && <span>{projectType.loadingTimeList[0].time}</span>}</LoadingTimeTitle>
                      {projectType.loadingTimeList.length > 1 && <LoadingTimeList>
                        {projectType.loadingTimeList.map((timeValue, index) => {
                          return (<LoadingTime><span>{timeValue.timeZoneName}</span> {timeValue.time}</LoadingTime>)
                        })}
                      </LoadingTimeList>}
                    </>}
                    <Subtitle>{projectType.name}</Subtitle>
                    <ProjectTypeTable>
                      {splitByDate(projectType.loadings).map(([date, loadings], index) => {
                        return (
                          <React.Fragment key={projectType.name + index}>
                            <DateRow>
                              <DateCell>{utcDateString(date)}</DateCell>
                            </DateRow>
                            {headers()}
                            {loadings.map((loading, index) => (
                              <Row key={loading.pipelineId + "-" + index}>
                                {simple ? (
                                  <>
                                    {dateCell(loading.start)}
                                    {dateCell(loading.end, loading.info.estimatedEnd)}
                                    {durationCell(loading.start, loading.end, isLate(loading))}
                                    <StatusCell>
                                      {loading.error ? (
                                        <PopOver content={loading.error}>
                                          <StatusBadge status={loading.status}>{loading.status}</StatusBadge>
                                        </PopOver>
                                      ) : (
                                        <StatusBadge status={loading.status}>{loading.status}</StatusBadge>
                                      )}
                                    </StatusCell>
                                  </>
                                ) : (
                                  <>
                                    {dateCell(loading.staging.start)}
                                    {dateCell(loading.staging.end)}
                                    {durationCell(loading.staging.start, loading.staging.end, isLate(loading))}
                                    {dateCell(loading.intermediate.start)}
                                    {dateCell(loading.intermediate.end)}
                                    {durationCell(
                                      loading.intermediate.start,
                                      loading.intermediate.end,
                                      isLate(loading)
                                    )}
                                    <StatusCell>
                                      {loading.error ? (
                                        <PopOver content={loading.error}>
                                          <StatusBadge status={loading.status}>{loading.status}</StatusBadge>
                                        </PopOver>
                                      ) : (
                                        <StatusBadge status={loading.status}>{loading.status}</StatusBadge>
                                      )}
                                    </StatusCell>
                                  </>
                                )}
                              </Row>
                            ))}
                          </React.Fragment>
                        );
                      })}
                    </ProjectTypeTable>
                  </ProjectTypeContainer>
                ))}
              </TabContent>
            </TabContainerX>
          </>
        )}
      </Content>
    </Container>
  );
}

export default LoadingStatusPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //padding: 20px;
`;

const Header = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  padding: 20px;
  width: 100%;
  border-bottom: 1px aliceblue solid;
  box-shadow: #21508016 0 8px 22px;
  margin-bottom: 20px;
`;

const Title = styled.h1`
  margin-right: 32px;
  margin-left: 100px;
`;

const AdditionalInfo = styled.div`
  margin-left: 32px;
  color: ${valmetGreyBorder};
`;

const LoadingTimeTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
  font-weight: bold;
  span {
    font-weight: normal;
  }
`;

const LoadingTimeList = styled.div`
  margin: 0 0 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const LoadingTime = styled.div`
  margin: 0 0 10px 0;
  span {
    font-weight: bold;
  }
`;

const Subtitle = styled.h2`
  margin: 0 0 10px 0;
  /*margin-bottom: 10px;*/
`;

const Content = styled.div``;

const TabContent = styled.div<{ simple: boolean }>`
  display: grid;
  gap: 20px;
  padding: 10px;

  ${({ simple }) =>
    simple
      ? `
      grid-template-columns: 1fr 1fr;
      @media (max-width: 1080px) {
        grid-template-columns: 1fr;
      }`
      : `
      grid-template-columns: 1fr 1fr;
      @media (max-width: 1580px) {
        grid-template-columns: 1fr;
      }`}
`;

const ProjectTypeContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-width: 500px;
  max-width: 975px;
  border: solid 1px ${valmetGreyBorder};

  padding: 20px;
  box-shadow: #21508016 0 8px 22px;
  background: white;
`;

const ProjectTypeTable = styled.div`
  display: flex;
  flex-direction: column;
`;

const TableBody = styled.div`
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  border-style: solid;
  border-color: ${valmetGreyBorder};
  border-left-width: 0;
  border-right-width: 1px;
  border-top-width: 0;
  border-bottom-width: 1px;
`;

const BaseCell = styled.div`
  box-sizing: border-box;
  //border: 1px solid ${valmetGreyBorder};
  border-style: solid;
  border-color: ${valmetGreyBorder};
  border-left-width: 1px;
  border-right-width: 0;
  border-top-width: 0;
  border-bottom-width: 0;
  flex-shrink: 1;
`;

const HeaderCell = styled(BaseCell as any)`
  text-align: center;
  background: aliceblue;
  padding: 5px;
  //width: 75px;
  width: 100%;
`;
const DurationHeaderCell = styled(HeaderCell as any)`
  width: 120px;
  flex-shrink: 0;
`;
const StatusHeaderCell = styled(HeaderCell as any)`
  min-width: 100px;
  flex-shrink: 0;
  width: auto;
`;

const Cell = styled(BaseCell as any)`
  padding: 5px 10px 5px 5px;
  text-align: right;
  min-width: 75px;
  width: 100%;
`;
const DurationCell = styled(Cell as any)`
  text-align: right;
  width: 120px;
  flex-shrink: 0;
  //width: 100%;
`;
const StatusCell = styled(Cell as any)`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100px;
  width: auto;
  flex-shrink: 0;
  padding: 5px 5px 5px 5px;
`;

const RunningText = styled.span<{ late?: boolean }>`
  font-size: 0.85em;
  color: ${valmetGreyId};
  ${({ late }) => late && `color: ${cancelRed}`};
`;
const Duration = styled.span`
  display: inline-block;
  white-space: nowrap;
`;
const DurationText = styled.span`
  font-size: 0.85em;
  //color: ${valmetGreyId};
`;

const DateRow = styled(Row as any)`
  border-right-width: 0;
`;
const DateCell = styled.div`
  margin-top: 5px;
  padding: 10px;
  text-align: center;
  font-weight: bold;
  width: 100%;
`;

const StatusBadge = styled.span<{ status: LoadingStatus }>`
  padding: 2px 5px;
  border-radius: 4px;
  color: white;
  background: ${({ status }) =>
    status === "success" ? settingGreen : status === "failure" ? cancelRed : valmetGreyREC};
  font-weight: bold;
  font-size: 0.8em;
  cursor: ${({ status }) => (status === "failure" ? "pointer" : "default")};
`;

type PopOverProps = {
  content: string | React.ReactElement;
};

const PopOver = (props: PropsWithChildren<PopOverProps>) => {
  const { content, children } = props;
  const [show, setShow] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => setShow(false), 2000);
    return () => {
      clearTimeout(timeout);
    };
  }, [show]);
  return (
    <PopOverContainer onClick={() => setShow(true)}>
      {children}
      <PopOverElement show={show}>{content}</PopOverElement>
    </PopOverContainer>
  );
};

const PopOverContainer = styled.div`
  cursor: pointer;
`;

const PopOverElement = styled.div<{ show: boolean }>`
  position: absolute;
  background: ${warningYellow};
  border-radius: 10px;
  padding: 10px;

  opacity: ${({ show }) => (show ? 1 : 0)};
  pointer-events: ${({ show }) => (show ? "default" : "none")};
  transition: opacity linear 0.1s;
`;
