import React from "react";
import styled from "styled-components";
import { SingleSelectionDropdown } from "../../../../common/components";
import { valmetGreyLight } from "../../../../common/colors";
import { ProjectFiltersData } from "./FiltersConfiguration";

export interface FiltersSetsDropdownSectionProps {
  selectedFilters: ProjectFiltersData | undefined;
  setSelectedFilters: (value: ProjectFiltersData) => void;
  lists: ProjectFiltersData[];
  editFilters: (filters: ProjectFiltersData) => void;
  deleteFilters: (filters: ProjectFiltersData) => void;
}

export function FiltersSetsDropdownSection(props: FiltersSetsDropdownSectionProps): React.ReactElement {
  const { selectedFilters, setSelectedFilters, lists, editFilters, deleteFilters } = props;
  return (
    <FiltersDropdownContainer>
      <SingleSelectionDropdown
        valuesList={lists}
        selectedValue={selectedFilters}
        setSelectedValue={setSelectedFilters}
        placeholder={"-"}
        color={valmetGreyLight}
        onEdit={editFilters}
        onDelete={deleteFilters}
      />
    </FiltersDropdownContainer>
  );
}

const FiltersDropdownContainer = styled.div`
  font-size: 12px;
`;
